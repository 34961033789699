import React, { useMemo, useState } from 'react';
import { Modal, View, StyleSheet, Dimensions, useWindowDimensions, Platform } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import { IImageInfo } from 'react-native-image-zoom-viewer/built/image-viewer.type';
import { Button, IconButton, useTheme } from 'react-native-paper';
import Animated, { ZoomIn } from 'react-native-reanimated';
import { themeProp } from '../../../types/main';
import { CarouselPagination } from '../../CarouselPagination/CarouselPagination';
import { useSaveFileToDevice } from '../../../hooks/useOtcFile/useSaveFileToDevice';
import { mapExtensionToMimeType } from '../FileUpload/mapExtensionToMimeType';
import { toast } from '../../../utils/toast/toast';
import { Constants } from '../../../utils';
import { AlertDialog } from '../../Dialog/AlertDialog';
import { useIsSavingImagesAllowed } from '../../../hooks/useIsSavingImagesAllowed/useIsSavingImagesAllowed';

type ImageViewerModalPropTypes = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  imageUrls?: IImageInfo[];
  activeIndex?: number;
  hasAddButton?: boolean;
  onAddPress?: () => void;
  displaySaveButton?: boolean;
};

export const ImageViewerModal = ({
  visible,
  setVisible,
  imageUrls,
  activeIndex,
  hasAddButton,
  onAddPress,
  displaySaveButton = true,
}: ImageViewerModalPropTypes) => {
  const { width } = useWindowDimensions();
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);
  const imageUrlCount = Array.isArray(imageUrls) ? imageUrls.length : 0;
  const screenWidth = Dimensions.get('window').width;
  const { saveFileToDevice } = useSaveFileToDevice();
  const [isSaveImageClicked, setSaveImageClicked] = useState(false);
  const isSavingImagesAllowed = useIsSavingImagesAllowed();

  const onSave = async () => {
    if (Platform.OS === 'web') {
      setVisible(false);
      setSaveImageClicked(true);
      return;
    }
    if (!isSavingImagesAllowed) {
      toast(Constants.DISABLED_FEATURE_NOTICE);
      setVisible(false);
      return;
    }
    if (activeIndex === undefined) {
      return;
    }
    const shownImage = imageUrls?.[activeIndex];
    const uri = shownImage?.url;
    if (!uri) {
      return;
    }
    const mimeType = mapExtensionToMimeType(shownImage.url.split('.').pop());
    const isSupportedMimeType = mimeType === 'image/jpeg' || mimeType === 'image/png';
    if (!isSupportedMimeType) {
      throw new Error(`mimetype ${mimeType} is not supported in image viewer component`);
    }
    const supportedMimeType = mimeType as 'image/jpeg' | 'image/png';
    await saveFileToDevice({
      uri,
      mimeType: supportedMimeType,
    });
    setVisible(false);
  };

  return (
    <>
      <Modal visible={visible} transparent={true} onRequestClose={() => setVisible(!visible)}>
        <Animated.View entering={ZoomIn} style={styles.imageContainer}>
          <ImageViewer
            saveToLocalByLongPress={false}
            backgroundColor={theme.colors.background}
            imageUrls={imageUrls}
            index={activeIndex}
            enableSwipeDown={true}
            onSwipeDown={() => setVisible(!visible)}
            swipeDownThreshold={300}
            renderFooter={index =>
              imageUrlCount > 1 ? (
                <View
                  style={{
                    width: screenWidth,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 50,
                    bottom: 0,
                  }}
                >
                  <CarouselPagination
                    activeIndex={index}
                    count={imageUrlCount}
                    styles={{ width: 200, alignSelf: 'center' }}
                  />
                </View>
              ) : hasAddButton ? (
                <View style={styles.confirmFooterContainer}>
                  <Button
                    uppercase={false}
                    style={styles.button}
                    mode="outlined"
                    onPress={() => setVisible(!visible)}
                  >
                    Abbrechen
                  </Button>
                  <Button
                    uppercase={false}
                    style={[styles.button, styles.primaryButton]}
                    onPress={() => onAddPress?.()}
                    textColor={theme.customColors.pureWhite}
                  >
                    Hinzufügen
                  </Button>
                </View>
              ) : (
                <></>
              )
            }
            renderIndicator={() => (
              <>
                {!hasAddButton && (
                  <View style={styles.closeModalIconContainer}>
                    <IconButton
                      style={styles.closeModalIcon}
                      icon="close"
                      iconColor="white"
                      onPress={() => setVisible(!visible)}
                    />
                  </View>
                )}
                {displaySaveButton && (
                  <View style={styles.saveIconContainer}>
                    <IconButton
                      style={styles.saveIcon}
                      icon="download"
                      iconColor="white"
                      onPress={onSave}
                    />
                  </View>
                )}
              </>
            )}
          />
        </Animated.View>
      </Modal>
      <AlertDialog
        isOpen={isSaveImageClicked}
        title="Bilder in einem Browser speichern"
        content='Sie können Bilder speichern, indem Sie mit der rechten Maustaste auf ein Bild klicken und die Option "Bild speichern als..." wählen.'
        onDismiss={() => setSaveImageClicked(false)}
      />
    </>
  );
};

const createStyles = (theme: themeProp, width: number) =>
  StyleSheet.create({
    container: { marginVertical: 6 },
    imageContainer: { flex: 1, backgroundColor: 'transparent', position: 'relative' },
    closeModalIcon: { width: 24, height: 24 },
    closeModalIconContainer: {
      position: 'absolute',
      top: 50,
      left: 20,
      backgroundColor: theme.colors.primary,
      borderRadius: 100,
    },
    confirmFooterContainer: {
      width: width,
      justifyContent: 'space-between',
      flexDirection: 'row',
      bottom: 40,
      paddingHorizontal: 15,
    },
    button: {
      borderRadius: 5,
      height: 45,
      justifyContent: 'center',
      alignSelf: 'center',
      minWidth: 150,
    },
    primaryButton: {
      backgroundColor: theme.customColors.primary,
    },
    saveIcon: { width: 24, height: 24 },
    saveIconContainer: {
      position: 'absolute',
      top: 50,
      right: 20,
      backgroundColor: theme.colors.primary,
      borderRadius: 100,
    },
  });
