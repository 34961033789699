import React, { useMemo, useState } from 'react';
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { Appbar, Headline, Portal, Subheading, Text, TextInput } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ClientDropdown } from '../../features/inquiries/ClientDropdown/ClientDropdown';
import { RelativesDropdown } from '../../features/inquiries/RelativesDropdown/RelativesDropdown';
import { TeamDropdownPicker } from '../../features/inquiries/TeamDropdownPicker/TeamDropdownPicker';
import { PrimaryButton } from '../../components/PrimaryButton';
import { useCreateInquiryMutation, useListInquiriesQuery } from '../../../graphql/operations';
import { usePopup } from '../../hooks';
import { texts } from '../../utils';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import { useQueryClient } from '@tanstack/react-query';
import { Popup } from '../../components/Popup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Log } from '../../utils/Log';
import { AppBar } from '../../components/AppBar/AppBar';

const NewInquirySchema = yup.object({
  clientId: yup.string().required('KlientIn wird benötigt'),
  relativeId: yup.string().required('AngehörigeR wird benötigt'),
  teamId: yup.string().required('Team wird benötigt'),
  title: yup.string().required('Titel wird benötigt'),
  text: yup.string().required('Nachricht wird benötigt'),
});

export const NewInquiryScreen = () => {
  const theme = useAppTheme();
  const queryClient = useQueryClient();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const createInquiryMutation = useCreateInquiryMutation();
  const errorPopup = usePopup(texts.unknownErrorTitle, texts.unknownErrorText);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: yup.InferType<typeof NewInquirySchema>) => {
    setIsLoading(true);
    try {
      const result = await createInquiryMutation.mutateAsync({
        input: {
          relativeId: values.relativeId,
          title: values.title,
          teamId: values.teamId,
          text: values.text,
        },
      });
      if (!result.createInquiry.id) {
        throw new Error('inquiry id of created inquiry not found!');
      }
      await queryClient.invalidateQueries(useListInquiriesQuery.getKey());
      navigation.goBack();
    } catch (error) {
      Log.error(error, { message: 'Error while creating Inquiry by employee' });
      errorPopup.toggleOpen();
    } finally {
      setIsLoading(false);
    }
  };

  const { handleChange, values, submitForm, errors, setFieldValue } = useFormik({
    initialValues: {
      clientId: '',
      relativeId: '',
      teamId: '',
      title: '',
      text: '',
    },
    validationSchema: NewInquirySchema,
    onSubmit,
  });

  return (
    <>
      <AppBar
        title={'Neue Anfragen'}
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <KeyboardAwareScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback
          onPress={Platform.OS === 'web' ? () => {} : Keyboard.dismiss}
          style={styles.maxWidthContainer}
        >
          <>
            <Subheading>KlientIn auswählen</Subheading>
            <ClientDropdown
              onChangeValue={val => setFieldValue('clientId', val === null ? '' : val, false)}
            />
            {errors.clientId && <Text style={styles.error}>{errors.clientId}</Text>}
            <Subheading style={styles.marginBetweenFields}>Angehörigen auswählen</Subheading>
            <RelativesDropdown
              onChangeValue={val => setFieldValue('relativeId', val === null ? '' : val, false)}
              clientId={values.clientId}
            />
            {errors.relativeId && <Text style={styles.error}>{errors.relativeId}</Text>}
            <Subheading style={styles.marginBetweenFields}>Team auswählen</Subheading>
            <TeamDropdownPicker
              onChangeValue={val => setFieldValue('teamId', val === null ? '' : val, false)}
            />
            {errors.teamId && <Text style={styles.error}>{errors.teamId}</Text>}
            <Subheading style={styles.marginBetweenFields}>Titel</Subheading>
            <TextInput multiline={false} onChangeText={handleChange('title')} placeholder="Title" />
            {errors.title && <Text style={styles.error}>{errors.title}</Text>}
            <Subheading style={styles.marginBetweenFields}>Nachricht</Subheading>
            <TextInput
              multiline
              textAlignVertical="top"
              onChangeText={handleChange('text')}
              placeholder="Nachricht"
            />
            {errors.text && <Text style={styles.error}>{errors.text}</Text>}
            <PrimaryButton
              onPress={async () => {
                Keyboard.dismiss();
                await submitForm();
              }}
              text="Erstellen"
              buttonStyle={styles.submitButton}
            />
            <Portal>
              <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
                <Headline
                  style={{
                    color: '#fff',
                  }}
                >
                  Anfrage wird erstellt...
                </Headline>
              </LoadingBlocker>
            </Portal>
          </>
        </TouchableWithoutFeedback>
        <Popup {...errorPopup.popup} />
      </KeyboardAwareScrollView>
    </>
  );
};

const createStyles = ({
  content: { maxWidth },
  customColors: { error },
  colors: { background },
}: AppTheme) =>
  StyleSheet.create({
    maxWidthContainer: {
      maxWidth: maxWidth,
      width: '100%',
      alignItems: 'center',
    },
    container: {
      padding: 30,
      paddingTop: 0,
      flex: 1,
      backgroundColor: background,
      width: '100%',
    },
    contentContainer: {
      paddingTop: 30,
    },
    marginBetweenFields: {
      marginTop: 15,
    },
    submitButton: {
      marginTop: 20,
    },
    error: {
      color: error,
      marginTop: 3,
    },
  });
