import { ChatRoomMessage } from '../../../../graphql/operations';
import { validateRealTimeMessage } from './validateRealTimeMessage';
import { QueryClient } from '@tanstack/react-query';
import { Log } from '../../../utils/Log';

interface IProcessRealTimeChatRoomMessage {
  queryClient: QueryClient;
  chatRoomId: string;
  chatRoomMessage: ChatRoomMessage;
  onSentOrReceivedMessage?: () => void;
}

export const processRealTimeChatRoomMessage = ({
  onSentOrReceivedMessage,
  chatRoomMessage,
}: IProcessRealTimeChatRoomMessage) => {
  try {
    onSentOrReceivedMessage?.();
    return validateRealTimeMessage({ chatRoomMessage });
  } catch (e) {
    const err = e as Error;
    const message = `an error occurred while receiving create chat room message from websocket: ${err.message}`;
    Log.error(e, {
      message,
    });
    throw new Error(message);
  }
};
