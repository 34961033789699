import { useEffect } from 'react';
import { useSocketStore } from '../../stores/SocketStore';
import { NewsEvents } from '@luciapp/socket-contract';
import { useUnreadNewsStore } from '../../stores/useUnreadNewsStore';
import { useStore } from '../../stores/store';
import { News } from '../../../graphql/operations';

export const useNewsCountListener = () => {
  const socketStore = useSocketStore();
  const appUser = useStore(s => s.userProfile);
  const append = useUnreadNewsStore(s => s.append);

  useEffect(() => {
    if (socketStore.newsSocket && appUser) {
      socketStore.newsSocket.on(NewsEvents.createNews, (news: News) => {
        if (appUser.userId !== news.author?.userId) {
          append(news);
        }
      });
    }
  }, [socketStore.newsSocket]);
};
