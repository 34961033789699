import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { NewsBottomSheetItem } from './NewsBottomSheetItem';
import { BottomSheetView } from '@gorhom/bottom-sheet';
import { useIsKeyboardVisible } from '../../../../hooks/useIsKeyboardVisible/useIsKeyboardVisible';
import { IconButton } from 'react-native-paper';
import { useAppTheme } from '../../../../styles/theme';

type NewsBottomSheetContentProps = {
  openMediaGallery: () => void;
  openFormPicker: () => void;
  openInfoPagePicker: () => void;
  renderOnTop?: () => JSX.Element;
  onPressKeyboardAction: () => void;
};

export const NewsBottomSheetContent = ({
  openMediaGallery,
  openFormPicker,
  openInfoPagePicker,
  renderOnTop,
  onPressKeyboardAction,
}: NewsBottomSheetContentProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  const { isKeyboardVisible } = useIsKeyboardVisible();

  return (
    <BottomSheetView style={styles.container}>
      <View style={styles.actionsRow}>
        <IconButton
          icon="keyboard"
          iconColor={isKeyboardVisible ? theme.customColors.primary : theme.customColors.icon}
          onPress={onPressKeyboardAction}
          style={styles.keyboardIcon}
        />
        {renderOnTop?.()}
      </View>
      <View style={styles.actionsRow}>
        <NewsBottomSheetItem icon="image" title="Medien" onPress={openMediaGallery} />
        <NewsBottomSheetItem icon="format-list-checks" title="Formulare" onPress={openFormPicker} />
        <NewsBottomSheetItem icon="file-document" title="Infoseiten" onPress={openInfoPagePicker} />
      </View>
    </BottomSheetView>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      marginTop: 4,
      flexDirection: 'column',
    },
    actionsRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    keyboardIcon: { marginRight: 0 },
  });
