import { ChatRole } from '../../../../graphql/operations';

interface IGetRoleOfMessageAuthor {
  messageAuthorUserId?: string;
  userIdsOfModerators: string[];
}

export const getRoleOfMessageAuthor = ({
  messageAuthorUserId,
  userIdsOfModerators,
}: IGetRoleOfMessageAuthor) => {
  const isAuthorModerator =
    !!messageAuthorUserId && userIdsOfModerators.includes(messageAuthorUserId);
  return isAuthorModerator ? ChatRole.Moderator : ChatRole.Member;
};
