import React, { useMemo } from 'react';
import { StyleSheet, View, Image, ImageSourcePropType } from 'react-native';
import { Modal, Button } from 'react-native-paper';
import HeadlineContainer from '../../components/Containers/HeadlineContainer';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { GenericPopUpTestId } from '../../../test/testIds';

export type PopUpProps = {
  /**
   Children passed to this react component.
  */
  children: React.ReactNode;
  /**
   Toggle for visibility of the component.
  */
  visible: boolean;
  /**
    Title of the header.
  */
  headlineTitle: string;
  /**
    Title of the button.
  */
  primaryActionTitle?: string;
  /**
    URI of the image to display.
  */
  imageSource?: ImageSourcePropType | { uri: string };
  /**
   Function to call when the button is pressed.
  */
  onDismiss: () => void;
  /**
   Toggle for the disabled property of the button.
  */
  isDisabled?: boolean;
  /**
   Toggle for the dismissable property of the button.
  */
  notDismissable?: boolean;
  /**
   Margin on top of the button.
  */
  buttonMarginTop?: number;

  buttonTestId?: string;
};

export const GenericPopUp = ({
  children,
  headlineTitle,
  onDismiss,
  visible,
  buttonMarginTop,
  imageSource,
  isDisabled,
  notDismissable,
  primaryActionTitle,
  buttonTestId,
}: PopUpProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme, buttonMarginTop), [theme, buttonMarginTop]);

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      dismissable={!notDismissable}
      style={styles.modal}
      testID={GenericPopUpTestId.modal}
    >
      <View style={styles.container}>
        <HeadlineContainer title={headlineTitle}>
          {imageSource && <Image source={imageSource} style={styles.image} />}
          {children}
          <Button
            testID={buttonTestId}
            style={styles.button}
            mode="contained"
            onPress={onDismiss}
            disabled={isDisabled}
            textColor={theme.customColors.textWhite}
          >
            {primaryActionTitle || 'Schließen'}
          </Button>
        </HeadlineContainer>
      </View>
    </Modal>
  );
};

const createStyles = (themeArg: AppTheme, buttonMarginTop?: number) =>
  StyleSheet.create({
    container: {
      margin: 10,
      maxWidth: '95%',
    },
    button: {
      marginTop: buttonMarginTop ?? 24,
      color: themeArg.customColors.primary,
      borderRadius: 5,
    },
    image: {
      alignSelf: 'center',
      marginBottom: 14,
      marginVertical: 0,
      paddingVertical: 0,
      width: 300,
      height: 200,
    },
    modal: { alignItems: 'center' },
  });
