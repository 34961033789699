import React, { useMemo, useState, useTransition } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInputContentSizeChangeEventData,
} from 'react-native';
import {
  TextInput as TextInputPaper,
  TextInputProps as TextInputPropsRn,
} from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../../styles/theme';

const MIN_TEXTINPUT_HEIGHT = 64;
const MAX_TEXTINPUT_HEIGHT = 200;
const CONTENT_HEIGHT_LIMIT = 132;
const CONTENT_MULTILINE_LIMIT = 38;

export type TextInputProps = Pick<
  TextInputPropsRn,
  'value' | 'onChangeText' | 'style' | 'disabled' | 'testID'
>;

export const TextInput = ({ value, onChangeText, style, disabled, testID }: TextInputProps) => {
  const theme = useAppTheme();
  const [height, setHeight] = React.useState(MIN_TEXTINPUT_HEIGHT);
  const [isSettled, setIsSettled] = React.useState(true);
  const [isScrollEnabled, setScrollEnabled] = React.useState(true);
  const [isPending, startTransition] = useTransition();
  const internalStyles = useMemo(() => createStyles(theme), [theme]);
  const [isMultiline, setIsMultiline] = useState(false);

  const onSettled = React.useCallback(() => {
    setIsSettled(true);
  }, []);

  const determineInputSizeChange = (dimensions: { width: number; height: number }) => {
    // Support earlier versions of React Native on Android.
    if (!dimensions) {
      return;
    }
    const newHeight = Math.round(dimensions.height / MIN_TEXTINPUT_HEIGHT) * MIN_TEXTINPUT_HEIGHT;

    if (height !== newHeight && isSettled && newHeight <= MAX_TEXTINPUT_HEIGHT) {
      startTransition(() => {
        setHeight(newHeight);
        setIsSettled(false);
        setTimeout(onSettled, 500);
      });
    }
  };

  const onContentSizeChangeWeb = ({
    nativeEvent: { contentSize },
  }: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    if (!isPending) {
      determineInputSizeChange(contentSize);
    }
  };

  const onContentSizeChangeMobile = ({
    nativeEvent: { contentSize },
  }: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    setScrollEnabled(contentSize.height > CONTENT_HEIGHT_LIMIT);
    setIsMultiline(contentSize.height > CONTENT_MULTILINE_LIMIT);
  };

  return (
    <TextInputPaper
      value={value}
      disabled={disabled}
      mode="outlined"
      testID={testID}
      multiline
      style={[
        internalStyles.textInput,
        style,
        Platform.select({
          web: { height },
        }),
      ]}
      outlineStyle={internalStyles.outline}
      contentStyle={
        // on android, for text to look centered, there is a need for margin at top of content when the input text is only one line
        Platform.OS === 'android' && !isMultiline ? internalStyles.contentAndroidOneLine : undefined
      }
      onContentSizeChange={
        Platform.OS === 'web' ? onContentSizeChangeWeb : onContentSizeChangeMobile
      }
      onChangeText={onChangeText}
      placeholder="Nachricht"
      scrollEnabled={isScrollEnabled}
    />
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    textInput: {
      maxHeight: MAX_TEXTINPUT_HEIGHT,
      minHeight: MIN_TEXTINPUT_HEIGHT,
      flex: 1,
      backgroundColor: theme.customColors.textInputBackground,
      marginBottom: 4,
      marginLeft: 4,
    },
    outline: {
      borderWidth: 1,
      borderRadius: 20,
      borderColor: theme.customColors.borders,
      backgroundColor: theme.customColors.textInputBackground,
    },
    contentAndroidOneLine: { marginTop: 6 },
  });
