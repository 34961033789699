import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import PDFReader from '@luciapp/rn-pdf-reader-js';
import { PdfReaderProps } from '../../../features/chat/screens/types';
import { Log } from '../../../utils/Log';
import { useTheme } from 'react-native-paper';
import { themeProp } from '../../../types/main';
import { PdfReaderBar } from './PdfReaderBar';
import { AppTheme } from '../../../styles/theme';

/**
 * does not work in web
 */
export const PdfReader = ({
  route: {
    params: { url, fileName },
  },
  navigation,
}: PdfReaderProps) => {
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const isOnWeb = Platform.OS === 'web';

  useEffect(() => {
    if (isOnWeb) {
      navigation.goBack();
    }
  }, []);

  return (
    <>
      <PdfReaderBar downloadable={false} fileName={fileName ?? 'Dokument'} />
      <SafeAreaView style={styles.container}>
        {url && !isOnWeb && (
          <PDFReader
            source={{
              uri: url,
            }}
            withPinchZoom={true}
            withScroll={true}
            customStyle={{
              readerContainerZoomContainer: {
                display: 'none',
              },
            }}
            onError={Log.error}
          />
        )}
      </SafeAreaView>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.background,
      height: 400,
      width: '100%',
    },
  });
