import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { Text } from 'react-native-paper';
import { UserCard } from '../../../profiles/components/UserCard';
import { FormReplyStatus as FormReplyStatusType } from '../../../../../graphql/operations';
import { FormReplyStatus } from '../FormReplyStatus/FormReplyStatus';
import { useStore } from '../../../../stores/store';
import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';

type FormHeaderPropTypes = {
  title: string;
  description?: string | null;
  status?: FormReplyStatusType;
  userIdOfFormAssignee?: string | null;
  filledOutBy?: string;
  onPressUserCard?: () => void;
  onPressFormReplyStatus?: () => void;
};

export const FormHeader = ({
  title,
  description,
  status,
  userIdOfFormAssignee,
  filledOutBy,
  onPressUserCard,
  onPressFormReplyStatus,
}: FormHeaderPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const userProfile = useStore(s => s.userProfile);

  return (
    <View style={styles.container}>
      {filledOutBy && <Text style={styles.info}>Ausgefüllt von {filledOutBy}</Text>}
      <SectionTitle title={title} />
      <FormReplyStatus status={status} onPress={onPressFormReplyStatus} />
      {description && <Text style={styles.description}>{description}</Text>}
      {userIdOfFormAssignee && (
        <View style={styles.userCardContainer}>
          <UserCard
            touchable
            hasChevron
            rightAligned={false}
            descriptionOverride={
              userProfile?.userId === userIdOfFormAssignee
                ? 'Sie sind der verantwortliche Benutzer für dieses Formular'
                : 'Verantwortliche Person für dieses Formular'
            }
            userId={userIdOfFormAssignee}
            onPress={onPressUserCard}
            style={styles.userCard}
          />
        </View>
      )}
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: { paddingVertical: 30 },
    title: {
      fontWeight: '500',
      color: theme.customColors.text,
      fontSize: 20,
      paddingVertical: 10,
      textAlign: 'center',
    },
    description: {
      fontWeight: '400',
      color: theme.customColors.text,
      fontSize: 16,
      paddingVertical: 10,
    },
    info: {
      fontWeight: '500',
      color: theme.customColors.text,
      fontSize: 12,
      paddingVertical: 6,
      textAlign: 'center',
    },
    userCard: {
      paddingHorizontal: 12,
      paddingVertical: 12,
      minHeight: 0,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.18,
      shadowRadius: 1.0,
      elevation: 1,
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    userCardContainer: {
      paddingVertical: 10,
      backgroundColor: theme.colors.background,
    },
  });
