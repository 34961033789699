import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

export interface HeaderProps {
  children: React.ReactNode;
}

const HEADER_HEIGHT = 80;

export const Header = ({ children }: HeaderProps) => {
  const styles = useMemo(() => createStyles(), []);

  return <View style={styles.header}>{children}</View>;
};

const createStyles = () =>
  StyleSheet.create({
    header: {
      height: HEADER_HEIGHT,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    divider: {
      width: '100%',
    },
  });
