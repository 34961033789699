import { useState } from 'react';
import { useFileGallery } from './useFileGallery';
import uuid from 'react-native-uuid';
import { Log } from '../../../utils/Log';
import * as DocumentPicker from 'expo-document-picker';
import { getFileSizeFromBase64 } from '../../../utils/getFileSizeFromBase64';
import { getCompressedFiles } from '../../../utils/getCompressedFiles';
import { launchCameraAsync, MediaTypeOptions } from 'expo-image-picker';
import { FileOptionalParams, FileType } from '../types';
import { mapGaleryReturnToFileType } from '../utils';
import { RequestImageType } from '../../../utils/requestImages/requestImages';
import { File } from '../types';
import { toast } from '../../../utils/toast/toast';
import * as ImagePicker from 'expo-image-picker';

type UseFileGalleryPickerParams = {
  includeVideosAmongPhotos: boolean;
  existingFiles?: File<FileOptionalParams>[];
};

export const useFileGalleryPicker = ({
  includeVideosAmongPhotos,
  existingFiles,
}: UseFileGalleryPickerParams) => {
  const { appendNewFiles, removeFile, files, noSelectedFiles } = useFileGallery<FileOptionalParams>(
    { existingFiles },
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleCameraPhoto = async () => {
    setIsLoading(true);
    try {
      const images = await getCompressedFiles({
        type: RequestImageType.Camera,
        includeVideosAmongPhotos,
      });
      if (Array.isArray(images) && images.length > 0) {
        appendNewFiles(
          images.map(image => ({
            id: uuid.v4().toString(),
            type: mapGaleryReturnToFileType(image.type),
            uri: image.uri,
            optionalParams: {
              ...image,
              type: mapGaleryReturnToFileType(image.type),
              fileSize: image.base64 ? getFileSizeFromBase64(image.base64.length) : 0,
            },
          })),
        );
      }
    } catch (error) {
      const err = error as Error;
      const message = `an error occurred while handling camera photo: ${err.message}`;
      Log.error(err, { message });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * @deprecated this adds the video to files as a .mov file (in iOS) – support for this is currently limited
   * @todo We need to make sure it is .mpeg or .mp4 for compatibility purposes
   */
  const handleCameraVideo = async () => {
    try {
      const permission = await ImagePicker.requestCameraPermissionsAsync();
      if (!permission.granted) {
        toast(
          'Bitte erlauben Sie LUCI den Zugriff auf die Medienbibliothek in den Geräteeinstellungen.',
        );
      }
      setIsLoading(true);
      const video = await launchCameraAsync({
        mediaTypes: MediaTypeOptions.Videos,
      });
      const pickedVideo = video.assets?.[0];
      if (pickedVideo) {
        appendNewFiles([
          {
            id: uuid.v4().toString(),
            type: mapGaleryReturnToFileType(pickedVideo.type),
            uri: pickedVideo.uri,
            optionalParams: {
              ...video,
              fileSize: pickedVideo.base64 ? getFileSizeFromBase64(pickedVideo.base64.length) : 0,
            },
          },
        ]);
      }
    } catch (error) {
      const err = error as Error;
      const message = `an error occurred while handling camera video: ${err.message}`;
      Log.error(err, { message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentPick = async () => {
    setIsLoading(true);
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'application/pdf',
        copyToCacheDirectory: false,
        multiple: true,
      });
      if (result.canceled) {
        return;
      }
      appendNewFiles(
        result.assets.map(asset => ({
          id: uuid.v4().toString(),
          type: FileType.Document,
          name: asset.name,
          uri: asset.uri,
          optionalParams: {
            ...asset,
            fileSize: asset.size,
          },
        })),
      );
    } catch (error) {
      const err = error as Error;
      const message = `an error occurred while handling document: ${err.message}`;
      Log.error(err, { message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGalleryPick = async () => {
    setIsLoading(true);
    try {
      const pickedFiles = await getCompressedFiles({
        type: RequestImageType.Gallery,
        includeVideosAmongPhotos,
      });
      if (Array.isArray(pickedFiles) && pickedFiles.length > 0) {
        appendNewFiles(
          pickedFiles.map(file => ({
            id: uuid.v4().toString(),
            type: mapGaleryReturnToFileType(file.type),
            uri: file.uri,
            optionalParams: {
              ...file,
              type: mapGaleryReturnToFileType(file.type),
              fileSize: file.base64 ? getFileSizeFromBase64(file.base64.length) : 0,
            },
          })),
        );
      }
    } catch (error) {
      const err = error as Error;
      const message = `an error occurred while handling gallery pick: ${err.message}`;
      Log.error(err, { message });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    appendNewFiles,
    removeFile,
    files,
    noSelectedFiles,
    handleCameraPhoto,
    handleCameraVideo,
    handleDocumentPick,
    handleGalleryPick,
    isLoading,
  };
};
