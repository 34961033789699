import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import React, { useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { ChatPartner } from '../types';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { ChatRole } from '../../../../graphql/operations';

type SmallChatListItemProps = {
  id: string;
  name: string;
  imageUri?: string;
  members: ChatPartner[];
  role?: ChatRole;
};

export const SmallChatListItem = ({
  id,
  name,
  imageUri,
  members,
  role,
}: SmallChatListItemProps): JSX.Element => {
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const chatRoomImage = useMemo(() => {
    if (imageUri) {
      return {
        uri: imageUri,
        isSourceUriDefined: true,
        isGroupImage: true,
      };
    }
    let otherInitials = 'NA';
    if (name) {
      const splitName = name.split(' ');
      otherInitials =
        splitName.length === 1
          ? splitName[0].charAt(0)
          : splitName[0].charAt(0) + splitName[1].charAt(0);
    }
    return {
      initials: otherInitials,
      isSourceUriDefined: false,
      isGroupImage: false,
    };
  }, [name, imageUri]);

  const handlePress = () => {
    navigation.navigate('ChatRoom', {
      id,
      name,
      imageUri: chatRoomImage.uri,
      members,
      role,
    });
  };

  return (
    <TouchableOpacity onPress={handlePress} style={styles.container} testID="SmallChatListItem">
      <AvatarDisplay
        avatar={{ initials: chatRoomImage.initials, otcPath: chatRoomImage.uri }}
        size={30}
        type={UploadType.ProfilePicture}
      />
      <Text style={styles.name}>{name}</Text>
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: 40,
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: theme.customColors.borders,
      borderBottomWidth: 1,
    },
    name: { fontSize: 17, color: theme.customColors.text, marginLeft: 6 },
  });
