import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';

import { Keyboard, Platform } from 'react-native';
import { Portal } from 'react-native-paper';
import React, { useState } from 'react';

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { LoadingBlocker } from '../Loading/LoadingBlocker';
import { Feature } from '../../../../graphql/operations';
import { OfflineErrorModal } from '../Modals/OfflineErrorModal';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { PreviewOverlay } from '../../PreviewOverlay';
import { uploadFiles } from '../FileUpload/uploadFiles';
import { compressImage } from '../../../utils/compressImage';
import { ImageResult } from 'expo-image-manipulator';
import { useAppTheme } from '../../../styles/theme';
import { SelectLinksModal } from '../../SelectLinksModal';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { InfoPageLinkList } from '../../../features/chat/components/InfoPageLinkList';
import { useSelectLinksModal } from '../../SelectLinksModal/hooks/useSelectLinksModal';
import { FormLinkList } from '../../../features/chat/components';
import { useLinkList } from './hooks/useLinkList';
import { usePreviewOverlay } from '../../PreviewOverlay/hooks/usePreviewOverlay';
import { ISendLink } from './hooks/useSendLink';
import {
  AttachmentPickerResultItem,
  SelectedForm,
  SelectedInfoPage,
  UploadedImagesResponse,
} from './types';
import { UploadType } from '../FileUpload/types';
import { EncodingType } from 'expo-file-system';
import { AttachmentPickerFab } from '../Icon/AttachmentPickerFab';
import { getFileSizeFromBase64 } from '../../../utils/getFileSizeFromBase64';
import { uploadedDocumentToDatabase } from './utils/uploadedDocumentToDatabase';
import { uploadedImageToDatabase } from './utils/uploadedImageToDatabase';
import { RequestImageType, requestImages } from '../../../utils/requestImages/requestImages';
import { matchPickedImageWithUploadedImageResponse } from './utils/matchPickedImageWithUploadedImageResponse';
import { useActionSheetDocsImages } from './hooks/useActionSheetDocsImages';
import { Constants, waitForKeyboardDismiss } from '../../../utils';
import { ExceptionCode } from '../../Auth/types/ExceptionCode';
import { alert } from '../../../utils/alert';
import { Log } from '../../../utils/Log';

export type AttachmentPickerProps = {
  recipientName: string;
  uploadType: UploadType.ChatMessage | UploadType.InquiryMessage | UploadType.FormReply;
  onSubmit: (items: AttachmentPickerResultItem[]) => void;
  chatRoomId?: string;
  floatingActionPickerButton?: boolean;
  disabled?: boolean;
  sendLink?: ({ feature, selectedItems }: ISendLink) => Promise<void>;
};

export const AttachmentSheetPicker = ({
  onSubmit,
  uploadType,
  recipientName,
  chatRoomId,
  floatingActionPickerButton,
  disabled,
  sendLink,
}: AttachmentPickerProps) => {
  const usedInChatRoom = !!chatRoomId;
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const theme = useAppTheme();
  const [imagePickerResult, setImagePickerResult] =
    useState<Array<ImageResult | ImagePicker.ImagePickerAsset>>();
  const [documentPickerResult, setDocumentPickerResult] =
    useState<DocumentPicker.DocumentPickerResult>();

  const {
    isVisible: isPreviewOverlayVisible,
    title: previewOverlayTitle,
    description: previewOverlayDescription,
    items: previewOverlayItems,
    reset: resetPreviewOverlay,
    setPreviewOverlayForDocument,
    setPreviewOverlayForImages,
    setPreviewOverlayForFeature,
    removeItem: removeItemFromPreviewOverlay,
  } = usePreviewOverlay();

  const {
    isVisible: isSelectLinksModalVisible,
    modalTitle: selectLinksModalTitle,
    selectedFeature: selectLinksModalFeature,
    setSelectedFeature: setSelectLinksModalFeature,
    setVisible: setSelectLinksModalVisible,
  } = useSelectLinksModal();

  const {
    append: appendInfoPageToList,
    remove: removeInfoPageFromList,
    clear: clearInfoPageList,
    selections: selectedInfoPages,
  } = useLinkList<SelectedInfoPage>();

  const {
    append: appendFormToList,
    remove: removeFormFromList,
    clear: clearFormList,
    selections: selectedForms,
  } = useLinkList<SelectedForm>();

  const noItemSelected = selectedInfoPages.length === 0 && selectedForms.length === 0;

  const onPressCloseSelectLinksModal = () => {
    setSelectLinksModalVisible(false);
    clearInfoPageList();
    clearFormList();
  };

  const onPressSubmitSelectLinksModal = async () => {
    setSelectLinksModalVisible(false);
    const isFormsSelected = selectedForms.length > 0;
    if (isFormsSelected) {
      return setPreviewOverlayForFeature({
        feature: Feature.Form,
        recipientName,
        items: selectedForms,
      });
    }
    const isInfoPagesSelected = selectedInfoPages.length > 0;
    if (isInfoPagesSelected) {
      return setPreviewOverlayForFeature({
        feature: Feature.Infopage,
        recipientName,
        items: selectedInfoPages,
      });
    }
  };

  const handleFormPick = async () => {
    setSelectLinksModalFeature(Feature.Form);
    setSelectLinksModalVisible(true);
  };

  const handleInfoPagePick = async () => {
    setSelectLinksModalFeature(Feature.Infopage);
    setSelectLinksModalVisible(true);
  };

  const { showActionSheetWithOptions } = useActionSheet();

  const cleanup = () => {
    setIsLoading(false);
    setIsSending(false);
    resetPreviewOverlay();
    setDocumentPickerResult(undefined);
    setImagePickerResult(undefined);
    clearInfoPageList();
    clearFormList();
  };

  const handleCameraPhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      return alert('Diese App benötigt Kamera-Berechtigungen um ein Foto aufzunehmen.');
    }
    try {
      const pickedImages = await requestImages(RequestImageType.Camera);
      if (!pickedImages || !pickedImages.assets) {
        return;
      }
      await prepareImagePreview(pickedImages);
    } catch (e) {
      Log.error(e, { message: 'cannot launch camera' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImagePick = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      return alert(
        'Diese App benötigt Mediathek-Berechtigungen um ein Foto aus der Galerie auszuwählen.',
      );
    }
    try {
      const pickedImages = await requestImages(RequestImageType.Gallery);
      if (!pickedImages || !pickedImages.assets) {
        return;
      }
      await prepareImagePreview(pickedImages);
    } catch (e) {
      Log.error(e, { message: 'cannot launch image library' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentPick = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'application/pdf',
        copyToCacheDirectory: false,
        multiple: false,
      });
      const fileSizeIsOverLimit =
        Platform.OS === 'web' &&
        (result?.assets?.[0].size ?? 0) > Constants.FILE_UPLOAD_FIELD_LIMIT;
      if (fileSizeIsOverLimit) {
        throw new Error(ExceptionCode.FileSizeIsOverLimitException);
      }
      prepareDocumentPreview(result);
    } catch (e) {
      const error = e as Error;
      if (error.message === ExceptionCode.FileSizeIsOverLimitException) {
        return alert('Die Datei ist zu groß. (max. 10MB)');
      }
      Log.error(`an error occurred while picking document: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const prepareDocumentPreview = (result: DocumentPicker.DocumentPickerResult) => {
    if (!result || result.canceled || !result.assets[0]) {
      return;
    }
    const firstAsset = result.assets[0];
    setIsLoading(true);
    const { name: documentName, size: fileSize } = firstAsset;
    Keyboard.dismiss();
    setDocumentPickerResult(result);
    setPreviewOverlayForDocument({ recipientName, documentName, fileSize });
  };

  const prepareImagePreview = async (images: ImagePicker.ImagePickerResult) => {
    if (images.canceled) {
      return;
    }
    try {
      setIsLoading(true);
      const compressedImages = await Promise.all(
        (images.assets ?? []).map(image => compressImage(image)),
      );
      Keyboard.dismiss();
      setImagePickerResult(compressedImages);
      setPreviewOverlayForImages({
        recipientName,
        previewImages: compressedImages.map(({ uri: imageSource, height, width, base64 }) => ({
          recipientName,
          imageSource,
          height,
          width,
          fileSize: base64 ? getFileSizeFromBase64(base64.length) : 0,
        })),
      });
    } catch (e) {
      Log.error(e, { message: 'cannot compress images' });
    }
  };

  const handleDocumentPickerResult = async (result: DocumentPicker.DocumentPickerResult) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    if (result && !result.canceled) {
      try {
        try {
          const firstAsset = result.assets[0];
          const res = await uploadFiles({
            filesToUpload: [{ uri: firstAsset.uri, uploadType }],
            encoding: Platform.OS === 'web' ? EncodingType.Base64 : undefined,
          });
          onSubmit(res.map(item => uploadedDocumentToDatabase(item, firstAsset)));
        } catch (error) {
          Log.error(error);
          alert('Beim Hochladen ist ein Fehler aufgetreten.');
        } finally {
          setIsLoading(false);
        }
      } catch (error) {
        Log.error(error);
      }
    } else if (result.canceled) {
      Log.info('DocumentPicker closed by user');
      setIsLoading(false);
    } else {
      Log.error('Something went wrong');
      setIsLoading(false);
    }
  };

  const handleImagePickerResult = async (
    imagePickerResults: Array<ImageResult | ImagePicker.ImagePickerAsset>,
  ) => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      const res: UploadedImagesResponse[] = await uploadFiles({
        filesToUpload: imagePickerResults.map(({ uri }) => ({ uri, uploadType })),
        encoding: Platform.OS === 'web' ? EncodingType.Base64 : undefined,
      });
      const uploadedImages = res[0];
      if (uploadedImages.newFileNames.length !== imagePickerResults.length) {
        throw new Error(
          'image count in uploaded images response does not match the image count of picked images',
        );
      }
      const uploadedImagesToSaveToDatabase = uploadedImages.newFileNames.flatMap(
        (item: string[], index) => {
          if (item.length !== 2) {
            throw new Error(
              'uploaded images response does not include a file name and an otc path',
            );
          }
          const fileName = item[0];
          const otcPath = item[1];
          const matchedImage = matchPickedImageWithUploadedImageResponse({
            imagePickerResults,
            uploadedImageResponse: { fileName, index },
          });
          return uploadedImageToDatabase(otcPath, fileName, matchedImage);
        },
      );
      onSubmit(uploadedImagesToSaveToDatabase);
    } catch (error) {
      Log.error(error);
      alert('Beim Hochladen ist ein Fehler aufgetreten.');
    } finally {
      setIsLoading(false);
    }
  };

  const { showActionSheetDocsImages } = useActionSheetDocsImages({
    handleImagePick,
    handleDocumentPick,
    handleCameraPhoto,
  });

  const openActionSheetForInquiry = showActionSheetDocsImages;
  const openActionSheetForFormReply = showActionSheetDocsImages;

  const openActionSheetForChatRoom = () => {
    Keyboard.dismiss();
    showActionSheetWithOptions(
      {
        options: ['Aus Galerie', 'Kamera', 'Dokument', 'Formular', 'Infoseite', 'Abbrechen'],
        cancelButtonIndex: 5,
        icons: [
          <Icon name="image-outline" size={25} key="0" color={theme.customColors.text} />,
          <Icon name="camera" size={25} key="1" color={theme.customColors.text} />,
          <Icon name="file-document-outline" size={25} key="2" color={theme.customColors.text} />,
          <Icon name="format-list-checks" size={25} key="3" color={theme.customColors.text} />,
          <Icon name="file-document" size={25} key="4" color={theme.customColors.text} />,
          <Icon
            name="close"
            size={25}
            key="5"
            testID="cancelIcon"
            color={theme.customColors.text}
          />,
        ],
        containerStyle: {
          backgroundColor: theme.colors.background,
        },
        textStyle: {
          color: theme.customColors.text,
        },
      },
      async buttonIndex => {
        switch (buttonIndex) {
          case 0:
            return await handleImagePick();
          case 1:
            return await handleCameraPhoto();
          case 2:
            return await handleDocumentPick();
          case 3:
            return await handleFormPick();
          case 4:
            return await handleInfoPagePick();
          default:
            return;
        }
      },
    );
  };

  const onConfirmSend = async () => {
    setIsSending(true);
    try {
      if (imagePickerResult) {
        return await handleImagePickerResult(imagePickerResult);
      }
      if (documentPickerResult) {
        return await handleDocumentPickerResult(documentPickerResult);
      }
      if (selectedForms.length > 0 && usedInChatRoom) {
        return await sendLink?.({
          feature: Feature.Form,
          selectedItems: selectedForms,
        });
      }
      if (selectedInfoPages.length > 0 && usedInChatRoom) {
        return await sendLink?.({
          feature: Feature.Infopage,
          selectedItems: selectedInfoPages,
        });
      }
    } catch (e) {
      Log.error(e, { message: 'cannot pick' });
    } finally {
      cleanup();
    }
  };

  const onRemoveItemFromPreviewOverlay = (itemId: string) => {
    removeItemFromPreviewOverlay(itemId);
    if (selectLinksModalFeature === Feature.Infopage) {
      return removeInfoPageFromList(itemId);
    }
    if (selectLinksModalFeature === Feature.Form) {
      return removeFormFromList(itemId);
    }
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <Portal>
            <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent} />
          </Portal>
          <AttachmentPickerFab
            isFloating={floatingActionPickerButton}
            disabled={disabled}
            onPress={() =>
              onOnline(async () => {
                await waitForKeyboardDismiss();
                switch (uploadType) {
                  case UploadType.ChatMessage:
                    return openActionSheetForChatRoom();
                  case UploadType.InquiryMessage:
                    return openActionSheetForInquiry();
                  case UploadType.FormReply:
                    return openActionSheetForFormReply();
                  default:
                    return;
                }
              })
            }
          />
          <PreviewOverlay
            items={previewOverlayItems}
            show={isPreviewOverlayVisible}
            title={previewOverlayTitle}
            description={previewOverlayDescription}
            onCancel={cleanup}
            onConfirm={onConfirmSend}
            removeItem={onRemoveItemFromPreviewOverlay}
            confirmIcon={uploadType === UploadType.FormReply ? 'plus' : 'send'}
            isSending={isSending}
          />
          {selectLinksModalTitle && (
            <SelectLinksModal
              show={isSelectLinksModalVisible}
              title={selectLinksModalTitle}
              onPressCancel={onPressCloseSelectLinksModal}
              onPressSubmit={onPressSubmitSelectLinksModal}
              disableSubmitButton={noItemSelected}
            >
              <QueryClientProvider client={queryClient}>
                {selectLinksModalFeature === Feature.Infopage && (
                  <InfoPageLinkList append={appendInfoPageToList} remove={removeInfoPageFromList} />
                )}
                {selectLinksModalFeature === Feature.Form && (
                  <FormLinkList append={appendFormToList} remove={removeFormFromList} />
                )}
              </QueryClientProvider>
            </SelectLinksModal>
          )}
        </>
      )}
    </OfflineErrorModal>
  );
};
