import { useCallback, useMemo } from 'react';
import { ChatRoomMessage, ChatRoomMessageStatus } from '../../../../graphql/operations';
import { extendChatRoomMessage } from '../utils/extendChatRoomMessage';
import { getUnreadMessages } from '../utils/getUnreadMessages';
import { createSystemMessage } from '../utils/createSystemMessage';
import { groupBy } from '../../../utils/groupBy';
import { determineChatDay } from '../utils/determineChatDay';
import { getRoleOfMessageAuthor } from '../utils/getRoleOfMessageAuthor';
import { insertUnreadSystemMessage } from '../utils/insertUnreadSystemMessage';
import { determineDeletedContentText } from '../utils/determineDeletedContentText';
import moment from 'moment';

interface IUseExtendedMessages {
  queriedMessageIds: ChatRoomMessage['id'][];
  realTimeMessageIds: ChatRoomMessage['id'][];
  deletedMessageIds: ChatRoomMessage['id'][];
  queriedMessages: ChatRoomMessage[];
  realTimeMessages: ChatRoomMessage[];
  deletedMessages: ChatRoomMessage[];
  userIdsOfModerators: string[];
  sentOrReceivedMessageOnce: boolean;
  chatRoomLastReadDate?: Date;
}

export const useExtendedMessagesWithDay = ({
  deletedMessageIds,
  queriedMessageIds,
  realTimeMessageIds,
  queriedMessages,
  realTimeMessages,
  deletedMessages,
  userIdsOfModerators,
  sentOrReceivedMessageOnce,
  chatRoomLastReadDate,
}: IUseExtendedMessages) => {
  const extender = useCallback(
    (message: ChatRoomMessage) => {
      const isRead = chatRoomLastReadDate && chatRoomLastReadDate > message.createdAt;
      const asDeleted = deletedMessages.find(deletedMessage => deletedMessage.id === message.id);
      const isDeletedByAuthor = message.deletedById !== message.author?.userId;
      const hasAttachments =
        !!message.attachment ||
        !!(message.forms && message.forms.length !== 0) ||
        !!(message.infoPages && message.infoPages?.length !== 0);
      return extendChatRoomMessage({
        message: asDeleted
          ? {
              ...message,
              deletedAt: asDeleted.deletedAt,
              deletedById: asDeleted.deletedById,
              content: determineDeletedContentText(isDeletedByAuthor, hasAttachments),
              isForwarded: false,
              attachment: undefined,
              formReply: undefined,
              forms: undefined,
              infoPages: undefined,
              quote: undefined,
            }
          : {
              ...message,
              status: isRead ? ChatRoomMessageStatus.Read : message.status,
            },
        roleOfAuthorInChatRoom: getRoleOfMessageAuthor({
          userIdsOfModerators,
          messageAuthorUserId: message.author?.userId,
        }),
      });
    },
    [chatRoomLastReadDate, deletedMessageIds, userIdsOfModerators],
  );

  return useMemo(() => {
    const messages = [...realTimeMessages, ...queriedMessages];
    let extendedMessages = messages.map(extender);
    extendedMessages.sort((a, b) => {
      if (moment(a.createdAt).isBefore(b.createdAt)) {
        return 1;
      }
      return -1;
    });
    extendedMessages = extendedMessages.filter(
      (value, index, self) => index === self.findIndex(t => t._id === value._id),
    );
    let messagesWithSystemMessage = undefined;
    if (!sentOrReceivedMessageOnce) {
      const { countOfUnreadMessages, firstUnreadMessage } = getUnreadMessages(extendedMessages);
      const shouldSystemMessageBeInserted = !sentOrReceivedMessageOnce && firstUnreadMessage;
      messagesWithSystemMessage = shouldSystemMessageBeInserted
        ? insertUnreadSystemMessage({
            extendedMessages,
            systemMessageToInsert: createSystemMessage({
              createdAt: firstUnreadMessage?.createdAt as Date,
              countOfUnreadMessages: countOfUnreadMessages,
            }),
          })
        : extendedMessages;
    }
    const messagesByDay = groupBy(
      messagesWithSystemMessage ? messagesWithSystemMessage : extendedMessages,
      message => determineChatDay(message.createdAt),
    );
    return Object.entries(messagesByDay).map(([key, value]) => ({
      day: key,
      data: value,
    }));
  }, [
    queriedMessageIds,
    realTimeMessageIds,
    deletedMessageIds,
    sentOrReceivedMessageOnce,
    chatRoomLastReadDate,
    userIdsOfModerators,
  ]);
};
