import React, { useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type IndexIndicatorPropTypes = {
  currentIndex: number;
  numberOfItems: number;
};

export const IndexIndicator = ({ currentIndex, numberOfItems }: IndexIndicatorPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {currentIndex + 1}/{numberOfItems}
      </Text>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.customColors.indexIndicatorBackground,
      width: 36,
      height: 27,
      borderRadius: 13.5,
      position: 'relative',
      top: 2,
      alignSelf: 'flex-end',
      marginRight: 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: theme.colors.onSurface,
      fontWeight: '500',
      fontSize: 12,
    },
  });
