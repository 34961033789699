import React, { useEffect, useMemo } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Button, IconButton, Text, TextInput } from 'react-native-paper';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PrimaryButton } from '../../components/PrimaryButton';
import { useStore } from '../../stores/store';
import { alert } from '../../utils/alert';
import { getCognitoAuthErrorMessageForRelogin } from '../../utils/getCognitoAuthErrorMessageForRelogin';
import { useQueryClient } from '@tanstack/react-query';
import { useChatStore } from '../../features/chat/Store';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { useReloginStore } from '../../utils/ReloginStore';
import { useBiometricLogin } from '../../hooks';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import { Log } from '../../utils/Log';

export const preventClosingOfModal = (e: { preventDefault: () => void }) => {
  e.preventDefault();
};

const ReloginSchema = yup.object({
  password: yup.string().trim().required('Passwort benötigt'),
});

export const ReloginScreen = () => {
  const theme = useAppTheme();
  const queryClient = useQueryClient();
  const chatStore = useChatStore();
  const { clear: clearReloginStore } = useReloginStore();

  const onReloginSuccess = () => {
    navigation.removeListener('beforeRemove', preventClosingOfModal);
    navigation.goBack();
  };

  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Relogin'>['navigation']>();
  const styles = useMemo(() => createStyles(theme, Platform.OS !== 'web'), [theme]);
  const { userProfile, login, logout } = useStore();

  const { tryBiometricLogin, areBiometricsUsed, isLoggingIn } = useBiometricLogin({
    promptMessage: 'Bitte bestätigen Sie Ihre Identität',
    cancelLabel: 'Mit Passwort bestätigen',
    onSuccess: onReloginSuccess,
  });

  useFocusEffect(() => {
    navigation.addListener('beforeRemove', preventClosingOfModal);
    return () => {
      navigation.removeListener('beforeRemove', preventClosingOfModal);
    };
  });

  const onSubmit = async (values: yup.InferType<typeof ReloginSchema>) => {
    if (!userProfile?.user?.loginUsername) {
      alert('Ein Fehler ist aufgetreten');
      Log.error('cannot relogin user, user profile is missing');
      return;
    }
    try {
      await login({ username: userProfile.user.loginUsername, password: values.password });
      onReloginSuccess();
    } catch (e) {
      const error = e as Error;
      if (error.message) {
        return onCognitoError(error.message);
      }
      alert('Ein Fehler ist aufgetreten');
      Log.error(e, { message: 'cannot relogin user' });
    }
  };
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    isValid,
    dirty,
    isSubmitting,
    touched,
    errors,
    setFieldError,
  } = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: ReloginSchema,
    onSubmit,
  });

  const onPressPasswordForgotten = () => {
    navigation.navigate('ReloginNewPassword');
  };

  useEffect(() => {
    clearReloginStore();
  }, []);

  const onPressChangeUser = async () => {
    try {
      await logout({ chatStoreToClear: chatStore, queryClientToClearCacheOf: queryClient });
    } catch (e) {
      Log.error(e, { message: 'cannot logout user' });
      alert('Beim Abmelden ist ein Fehler aufgetreten.');
    }
  };

  const onCognitoError = async (errorMessage: string) => {
    try {
      const error = getCognitoAuthErrorMessageForRelogin(errorMessage);
      if (error.field === 'user') {
        await logout({ chatStoreToClear: chatStore, queryClientToClearCacheOf: queryClient });
      } else {
        setFieldError('password', error.message);
      }
    } catch (e) {
      await logout({ chatStoreToClear: chatStore, queryClientToClearCacheOf: queryClient });
    }
  };

  return (
    <SafeAreaView style={{ height: '100%' }}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior="position"
        contentContainerStyle={{ height: '100%' }}
        keyboardVerticalOffset={-50}
      >
        <TouchableWithoutFeedback onPress={Platform.OS === 'web' ? () => {} : Keyboard.dismiss}>
          <View style={styles.innerContainer}>
            <View style={{ alignItems: 'center' }}>
              <Icon
                name="account-reactivate-outline"
                size={80}
                color={theme.customColors.primary}
                style={styles.icon}
              />
              <View style={styles.textContainer}>
                <Text style={styles.introText}>
                  Hallo {userProfile?.firstname} {userProfile?.lastname},
                </Text>
                <Text style={styles.introText}>
                  Sie haben seit längerer Zeit Ihr Passwort nicht mehr verwendet. Bitte geben Sie
                  Ihr Passwort erneut ein und klicken Sie anschließend auf &quot;Bestätigen&quot;.
                </Text>
              </View>
              <View style={styles.formContainer}>
                <TextInput
                  onChangeText={handleChange('password')}
                  style={{ backgroundColor: theme.customColors.textInputBackground }}
                  onBlur={handleBlur('password')}
                  secureTextEntry
                  autoCapitalize="none"
                  autoCorrect={false}
                  returnKeyType="send"
                  onSubmitEditing={() => isValid && handleSubmit()}
                  label="Passwort"
                  placeholder="Passwort"
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <Text style={styles.errorText}>{errors.password}</Text>
                )}
                {Platform.OS !== 'web' && (
                  <IconButton
                    icon="fingerprint"
                    iconColor={
                      areBiometricsUsed ? theme.customColors.primary : theme.customColors.gray30
                    }
                    disabled={!areBiometricsUsed}
                    onPress={tryBiometricLogin}
                    style={styles.biometricButton}
                    size={40}
                  />
                )}
                <PrimaryButton
                  buttonProps={{ loading: isSubmitting }}
                  disabled={!dirty || !isValid || isSubmitting}
                  onPress={handleSubmit}
                  text="Bestätigen"
                  buttonStyle={styles.loginButton}
                />
                <Button
                  uppercase={false}
                  mode="outlined"
                  onPress={onPressPasswordForgotten}
                  style={styles.passwordForgottenButton}
                  textColor={theme.colors.onSurface}
                >
                  Passwort vergessen
                </Button>
              </View>
            </View>
            <View style={styles.bottomContainer}>
              <Text>
                Sie sind nicht {userProfile?.firstname} {userProfile?.lastname}?
              </Text>
              <Button
                uppercase={false}
                mode="outlined"
                onPress={onPressChangeUser}
                style={styles.changeUserButton}
                textColor={theme.colors.onSurface}
              >
                Benutzer wechseln
              </Button>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
      <LoadingBlocker visible={isLoggingIn} />
    </SafeAreaView>
  );
};

const createStyles = (
  { content: { maxWidth }, customColors: { error }, colors: { background } }: AppTheme,
  hasBiometrics: boolean,
) =>
  StyleSheet.create({
    container: { padding: 30, flex: 1, backgroundColor: background },
    innerContainer: {
      justifyContent: 'space-between',
      flex: 1,
      width: '100%',
    },
    icon: {
      marginTop: 20,
    },
    changeUserButton: {
      borderRadius: 5,
      marginTop: 10,
    },
    introText: {
      textAlign: 'left',
      fontSize: 14,
      marginTop: 10,
    },
    formContainer: {
      width: '100%',
      marginTop: 20,
      maxWidth,
    },
    textContainer: {
      justifyContent: 'flex-start',
      width: '100%',
      maxWidth,
      marginTop: 20,
    },
    biometricButton: {
      alignSelf: 'center',
      marginTop: 20,
    },
    loginButton: {
      marginTop: hasBiometrics ? 5 : 20,
    },
    passwordForgottenButton: {
      borderRadius: 5,
      marginTop: 5,
      width: 200,
      alignSelf: 'center',
    },
    bottomContainer: {
      alignItems: 'center',
      marginBottom: 20,
    },
    errorText: {
      color: error,
      marginTop: 0,
    },
  });
