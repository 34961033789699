import { useCallback, useState } from 'react';
import { autoRejectAfterTimeout } from '../../utils/autoRejectAfterTimeout';
import { Log } from '../../utils/Log';

type UseRefreshControlWithTimeoutPropTypes = {
  asyncFunctionToAwaitFor: () => Promise<any>;
  errorMessageToLog: string;
};

export const useRefreshControlWithTimeout = ({
  asyncFunctionToAwaitFor,
  errorMessageToLog,
}: UseRefreshControlWithTimeoutPropTypes) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await autoRejectAfterTimeout(asyncFunctionToAwaitFor);
    } catch (e) {
      Log.warning(e, { message: errorMessageToLog });
    } finally {
      setIsRefreshing(false);
    }
  }, [asyncFunctionToAwaitFor, errorMessageToLog]);

  return {
    isRefreshing,
    onRefresh,
  };
};
