import React, { createContext, useContext, useState } from 'react';

const useProvideLegal = () => {
  const [imprint, setImprint] = useState<string | undefined>(undefined);
  const [privacy, setPrivacy] = useState<string | undefined>(undefined);
  const [consent, setConsent] = useState<string | undefined>('Einen Moment ...');
  const [fairplay, setFairplay] = useState<string | undefined>(undefined);
  const [fetchingError, setFetchingError] = useState(false);

  const privacyOTCPath = 'clgw2vbl7009o0jy3gvjq2goa.md';
  const consentOTCPath = 'clf2npujg00in0j27c5di5wvw.md';
  const imprintOTCPath = 'cl6atshku0001rvu0bu27g5j5.md';
  const fairplayOTCPath = 'cl6aty80o0002rvu02phl24s5.md';

  const fetchPrivacy = async () => {
    const privacyURLRes = await fetch(
      process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${privacyOTCPath}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    ).catch(() => setFetchingError(true));
    if (privacyURLRes) {
      const privacyURL = await privacyURLRes.json();
      fetch(privacyURL)
        .then(res => res.text())
        .then(setPrivacy)
        .catch(() => setFetchingError(true));
    }
  };

  const fetchConsent = async () => {
    const consentURLRes = await fetch(
      process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${consentOTCPath}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    ).catch(() => setFetchingError(true));
    if (consentURLRes) {
      const consentURL = await consentURLRes.json();
      fetch(consentURL)
        .then(res => res.text())
        .then(setConsent)
        .catch(() => setFetchingError(true));
    }
  };

  const fetchImprint = async () => {
    const imprintURLRes = await fetch(
      process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${imprintOTCPath}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    ).catch(() => setFetchingError(true));
    if (imprintURLRes) {
      const imprintURL = await imprintURLRes.json();
      fetch(imprintURL)
        .then(res => res.text())
        .then(setImprint)
        .catch(() => setFetchingError(true));
    }
  };

  const fetchFairplay = async () => {
    const fairplayURLRes = await fetch(
      process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${fairplayOTCPath}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    ).catch(() => setFetchingError(true));
    if (fairplayURLRes) {
      const fairplayURL = await fairplayURLRes.json();
      fetch(fairplayURL)
        .then(res => res.text())
        .then(setFairplay)
        .catch(() => setFetchingError(true));
    }
  };

  return {
    imprint,
    privacy,
    consent,
    fairplay,
    fetchFairplay,
    fetchPrivacy,
    fetchConsent,
    fetchImprint,
    fetchingError,
  };
};

const LegalContext = createContext({});

export const LegalProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const value = useProvideLegal();

  return <LegalContext.Provider value={value}>{props.children}</LegalContext.Provider>;
};

export const useLegal = () => useContext(LegalContext) as ReturnType<typeof useProvideLegal>;
