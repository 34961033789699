import { useState } from 'react';

export const useLinkInputDialog = () => {
  const [isInsertLinkDialogOpen, setInsertLinkDialogOpen] = useState(false);
  const [label, setLabel] = useState<string>();
  const [url, setUrl] = useState<string>();

  const resetFields = () => {
    setUrl(undefined);
    setLabel(undefined);
  };

  return {
    isInsertLinkDialogOpen,
    setInsertLinkDialogOpen,
    label,
    setLabel,
    url,
    setUrl,
    resetFields,
  };
};
