import { Log } from '../../../../utils/Log';

interface IOnErrorSendLink {
  e: unknown;
  onBlocked: () => void;
}

export const onErrorSendLink = ({ e, onBlocked }: IOnErrorSendLink) => {
  const err = e as Error;
  Log.error(err, { message: err.message });
  switch (err.message) {
    case 'message was not send because either private chat room member blocked one another':
      onBlocked();
      break;
    default:
      break;
  }
};
