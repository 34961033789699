import moment from 'moment';
import { ExtendedMessage } from '../types';

interface ICreateSystemMessage {
  createdAt?: Date;
  countOfUnreadMessages?: number;
}

export const createSystemMessage = ({
  createdAt,
  countOfUnreadMessages = 0,
}: ICreateSystemMessage): ExtendedMessage => {
  return {
    system: true,
    _id: 'unreadMessagesSystemMessage',
    createdAt: moment(createdAt).subtract(1, 'second').toDate(),
    readByCurrentUser: true,
    text: `${countOfUnreadMessages} NEUE NACHRICHT${`${countOfUnreadMessages > 1 ? `EN` : ``}`}`,
    user: { _id: 'unreadMessagesSystemMessageUser' },
  };
};
