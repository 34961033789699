import React, { memo, useEffect, useMemo, useRef } from 'react';
import { TextInput as RNTextInput, StyleSheet } from 'react-native';
import { Appbar, Searchbar } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { PlaceholderAppBarAction } from '../Common/AppBar/PlaceholderAppBarAction';

type SearchBarPropTypes = {
  searchNeverCloses?: boolean;
  searchInputValue?: string;
  onChangeSearchValue: (searchValue?: string) => void;
  placeholder?: string;
  renderSearchMatcherActions?: () => JSX.Element;
  onPressCloseSearch: () => void;
};

export const SearchBar = memo(
  ({
    searchNeverCloses,
    onChangeSearchValue,
    searchInputValue,
    placeholder,
    renderSearchMatcherActions,
    onPressCloseSearch,
  }: SearchBarPropTypes) => {
    const inputRef = useRef<RNTextInput>(null);
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    useEffect(() => {
      if (!searchNeverCloses && inputRef.current) {
        inputRef.current.focus();
      }
    }, [!!searchNeverCloses]);

    return (
      <>
        <PlaceholderAppBarAction />
        <Searchbar
          testID="app-bar-searchbar"
          accessibilityRole="search"
          ref={inputRef}
          placeholder={placeholder}
          onChangeText={onChangeSearchValue}
          value={searchInputValue ?? ''}
          style={styles.searchBar}
          clearIcon="backspace-outline"
        />
        {renderSearchMatcherActions && renderSearchMatcherActions()}
        {!searchNeverCloses && (
          <Appbar.Action
            testID="app-bar-close-search"
            icon="close"
            size={26}
            color={theme.customColors.gray50}
            onPress={onPressCloseSearch}
          />
        )}
      </>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    textInput: { backgroundColor: theme.customColors.textInputBackground, width: '50%' },
    matchCountText: {
      maxWidth: 32,
      fontSize: 14,
      fontWeight: '500',
      marginRight: -6,
      marginLeft: 10,
    },
    matchCountTextNoMatches: {
      maxWidth: 32,
      fontSize: 14,
      fontWeight: '500',
      marginRight: -6,
      marginLeft: 10,
      opacity: 0.5,
    },
    searchBar: {
      flex: 1,
      borderRadius: 12,
      backgroundColor: theme.customColors.textInputBackground,
    },
  });
