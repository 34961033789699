import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Portal } from 'react-native-paper';
import Animated from 'react-native-reanimated';
import { Appbar } from 'react-native-paper';
import { Container } from './Container';
import { Footer } from './Footer';
import { Header } from './Header';
import { Content } from './Content';
import { AppBar } from '../AppBar/AppBar';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';
import { AnimationDirection, AnimationPhase, AnimationType } from '../../utils/animation/types';
import { buildAnimation } from '../../utils/animation/buildAnimation';

export type FullScreenModalProps = {
  title: string;
  children: React.ReactNode;
  show: boolean;
  onClose?(): void;
  onSubmit?(): void;
  submitIcon?: keyof typeof MaterialCommunityIcons.glyphMap;
  closeIcon?: keyof typeof MaterialCommunityIcons.glyphMap;
  isSubmitDisabled?: boolean;
  animationDirections?: {
    enterFrom: AnimationDirection;
    exitTo: AnimationDirection;
  };
  renderSearchBar?: () => JSX.Element;
  onPressSearch?: () => void;
  showSearch?: boolean;
  renderActionsRightOfSearch?: () => JSX.Element;
};

export const FullScreenModal = ({
  title,
  children,
  show,
  onClose,
  onSubmit,
  submitIcon = 'content-save-move-outline',
  closeIcon = 'close',
  isSubmitDisabled,
  animationDirections = { enterFrom: AnimationDirection.DOWN, exitTo: AnimationDirection.DOWN },
  onPressSearch,
  renderSearchBar,
  showSearch,
  renderActionsRightOfSearch,
}: FullScreenModalProps) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);
  const queryClient = useQueryClient();

  return (
    <Portal>
      <QueryClientProvider client={queryClient}>
        {show ? (
          <Animated.View
            style={styles.container}
            entering={buildAnimation({
              type: AnimationType.SLIDE,
              phase: AnimationPhase.IN,
              direction: animationDirections.enterFrom,
            })}
            exiting={buildAnimation({
              type: AnimationType.SLIDE,
              phase: AnimationPhase.OUT,
              direction: animationDirections.exitTo,
            })}
          >
            <AppBar
              renderContent={() => (
                <Appbar.Content title={title} titleStyle={styles.contentTitle} />
              )}
              renderActionsLeftOfTitle={() => (
                <Appbar.Action
                  icon={closeIcon}
                  onPress={onClose}
                  color={theme.customColors.primary}
                />
              )}
              renderActionsRightOfTitle={() => (
                <Appbar.Action
                  icon={submitIcon}
                  onPress={onSubmit}
                  color={theme.customColors.primary}
                  disabled={!!isSubmitDisabled}
                />
              )}
              renderSearchBar={renderSearchBar}
              onPressSearch={onPressSearch}
              showSearch={showSearch}
              renderActionsRightOfSearch={renderActionsRightOfSearch}
            />
            {children}
          </Animated.View>
        ) : null}
      </QueryClientProvider>
    </Portal>
  );
};

FullScreenModal.Content = Content;
FullScreenModal.Footer = Footer;
FullScreenModal.Container = Container;
FullScreenModal.Header = Header;

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    container: {
      zIndex: theme.imagePreviewOverlay.zIndex,
      backgroundColor: theme.colors.surface,
      height: '100%',
      alignSelf: 'center',
      justifyContent: 'center',
      width,
    },
    fileDocumentIcon: {
      fontSize: 200,
      opacity: 0.4,
    },
    footerControls: { flexDirection: 'row', alignItems: 'center' },
    contentTitle: { fontSize: 16, fontWeight: 'bold', textAlign: 'center' },
  });
