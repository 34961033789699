import { StockImage } from '@luciapp/stock-image-api';

export enum FileType {
  Audio = 'audio',
  Document = 'document',
  Image = 'image',
  Text = 'text',
  Video = 'video',
}

export type FileOptionalParams = {
  fileSize?: number;
  height?: number;
  width?: number;
  otcPath?: string;
  stockImageData?: StockImage;
};

export type File<P> = {
  id: string;
  type: FileType;
  uri: string;
  name?: string;
  optionalParams?: P;
};
