import { ReturnedPage } from '@luciapp/stock-image-api';
import { SetStateAction, useEffect } from 'react';
import { File, FileOptionalParams } from '../../../../components/FileGallery/types';
import { mapStockImagePageToFiles } from '../utils';

type UseStockImageSearchParams = {
  searchValue: string | undefined;
  setFiles: React.Dispatch<SetStateAction<File<FileOptionalParams>[]>>;
  getFirstPage: () => Promise<void>;
  getInitialSearchPage: (searchString: string) => Promise<ReturnedPage | undefined>;
};

export const useStockImageSearch = ({
  searchValue,
  setFiles,
  getFirstPage,
  getInitialSearchPage,
}: UseStockImageSearchParams) => {
  useEffect(() => {
    if (searchValue === '') {
      void resetSearch();
    }
    if (searchValue) {
      setFiles([]);
      void getFirstSearchPage(searchValue);
    }
  }, [searchValue]);

  const resetSearch = async () => {
    setFiles([]);
    return await getFirstPage();
  };

  const getFirstSearchPage = async (searchString: string) => {
    const firstPage = await getInitialSearchPage(searchString);
    if (firstPage) {
      setFiles(mapStockImagePageToFiles(firstPage));
    }
  };

  return {};
};
