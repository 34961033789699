import { EncodingType } from 'expo-file-system';
import { FileToUploadProps } from './types';

export interface IAppendAttachmentToFormDataForHandleFilesRequest {
  fileToUpload: FileToUploadProps;
  data: FormData;
  encoding?: EncodingType;
}

export const appendAttachmentToFormDataForHandleFilesRequest = ({
  data,
  fileToUpload,
  encoding,
}: IAppendAttachmentToFormDataForHandleFilesRequest) => {
  const { uri, name, mimeType } = fileToUpload;
  if (encoding === 'base64') {
    data.append('base64File', uri);
    return;
  }
  data.append('attachment', { type: mimeType, uri, name });
};
