import React, { useCallback, useEffect } from 'react';
import {
  FullScreenModalPicker,
  FullScreenModalPickerPropTypes,
  UserProfileAsOption,
} from './FullScreenModalPicker';
import { SearchBar } from '../SearchBar/SearchBar';
import { Appbar } from 'react-native-paper';
import { useAppBar } from '../../features/news/hooks/useAppBar';
import { OptionLabel } from './components/OptionLabel';
import { AvatarDisplay } from '../Common/AvatarDisplay/AvatarDisplay';
import { getInitials } from '../../features/profiles/utils';
import { UploadType } from '../Common/FileUpload/types';
import { AnimationDirection } from '../../utils/animation/types';
import { useAppTheme } from '../../styles/theme';
import { useContactPicker } from '../../features/chat/hooks/useContactPicker';
import { useForwardeePicker } from './hooks/useForwardeePicker';
import { Forwardee, ForwardeeType } from './types';
import { PickContactsButton } from './components/PickContactsButton';
import { getAvatarForChatRoom } from '../../features/chat/utils';
import { SectionHeader } from '../List/SectionHeader';

type ForwardeePickerPropTypes = Pick<FullScreenModalPickerPropTypes<Forwardee>, 'onClose'> &
  Omit<
    ReturnType<typeof useForwardeePicker>,
    'clearForwardeeSelections' | 'setForwardeePickerExitDir' | 'toggleSelectingForwardees'
  > &
  Omit<ReturnType<typeof useAppBar>, 'onNewsTagPress' | 'debouncedSearchValue'> & {
    chatRoomId: string;
    userIdOfAppUser?: string;
    userIdOfPrivateChatRoomPartner?: string;
    isMultipleMessagesForwarded?: boolean;
    onPressForward: () => void;
  };

export const ForwardeePicker = ({
  chatRoomId,
  userIdOfPrivateChatRoomPartner,
  onClose,
  onPressForward,
  syncContactsToForwardees,
  forwardeeSelections,
  removeFromForwardeeSelections,
  appendToForwardeeSelections,
  isSelectingForwardees,
  forwardeeOptions,
  isChatRoomsLoading,
  forwardeePickerExitDir,
  onEmptySearchPress,
  searchInputValue,
  setSearchInputValue,
  setShowSearch,
  showSearch,
  isMultipleMessagesForwarded,
}: ForwardeePickerPropTypes) => {
  const theme = useAppTheme();

  const getContactsToExclude = useCallback(() => {
    const forwardeesWithChatRoom = forwardeeOptions
      .filter(forwardee => forwardee.value.type === ForwardeeType.CHATROOM)
      .map(forwardee => forwardee.value.extra?.user?.userId ?? forwardee.value.id);

    const userIdsOfContactsToExclude = forwardeesWithChatRoom;

    if (userIdOfPrivateChatRoomPartner) {
      userIdsOfContactsToExclude.push(userIdOfPrivateChatRoomPartner);
    }
    return userIdsOfContactsToExclude;
  }, [forwardeeOptions, userIdOfPrivateChatRoomPartner]);

  const {
    onEmptySearchPress: onEmptySearchContactPress,
    searchInputValue: searchContactInputValue,
    debouncedSearchValue: debouncedSearchContactValue,
    setSearchInputValue: setSearchContactInputValue,
    setShowSearch: setShowSearchContacts,
    showSearch: showSearchContact,
  } = useAppBar();

  const {
    contactOptions,
    contactsBuffer,
    isSelectingContacts,
    isContactsLoading,
    setSelectingContacts,
    toggleSelectingContacts,
    onConfirmContactSelections,
    onCancelContactSelections,
    onContactsBufferChange,
    removeFromContactSelections,
    removeFromContactsBuffer,
    clearContactSelections,
    clearContactsBuffer,
  } = useContactPicker({
    syncContactsToForwardees,
    forwardeeSelections,
    debouncedSearchContactValue,
    userIdsOfContactsToExclude: getContactsToExclude(),
  });

  const onForwardeeSelectionChange = useCallback(
    (selection: Forwardee) => {
      if (selection.type === ForwardeeType.CONTACT) {
        removeFromContactSelections(selection.id);
        removeFromContactsBuffer(selection.id);
        removeFromForwardeeSelections(selection.id);
        return;
      }
      const isAlreadySelected = forwardeeSelections.map(item => item.id).includes(selection.id);
      if (isAlreadySelected) {
        return removeFromForwardeeSelections(selection.id);
      }
      return appendToForwardeeSelections({ id: selection.id, value: selection });
    },
    [forwardeeSelections],
  );

  const onCloseForwardeePicker = useCallback(() => {
    onClose();
    clearContactSelections();
    clearContactsBuffer();
  }, []);

  const onUnmounted = () => {
    setSelectingContacts(false);
    clearContactSelections();
    clearContactsBuffer();
  };

  useEffect(() => {
    return () => onUnmounted();
  }, [chatRoomId]);

  return (
    <>
      <FullScreenModalPicker<Forwardee>
        isVisible={isSelectingForwardees}
        title="Weiterleiten"
        description={`Bitte wählen Sie den/die letzte/n Chat/s aus, an den/die Sie die ausgewählte Nachricht${
          isMultipleMessagesForwarded ? '' : 'en'
        } weiterleiten möchten.`}
        closeIcon="arrow-left"
        onClose={onCloseForwardeePicker}
        options={forwardeeOptions}
        noOptionsText='Wenn Sie den gewünschten Kontakt nicht finden können, versuchen Sie Ihn bitte unter "weitere Kontakte anzeigen" zu finden.'
        isFooterShownOnEmptySearach={true}
        selectionsBuffer={forwardeeSelections.map(selection => selection.id)}
        onSelectionBufferChange={onForwardeeSelectionChange}
        renderOptionLabel={({ value, label }) => {
          return (
            <OptionLabel
              label={label}
              renderAvatar={() => (
                <AvatarDisplay
                  size={32}
                  avatar={{
                    otcPath: value.otcPath ?? undefined,
                    initials:
                      value.type === ForwardeeType.CHATROOM
                        ? getAvatarForChatRoom({
                            isGroupChat: true,
                            name: label,
                          }).initials
                        : getInitials({
                            firstname: value.extra?.user?.firstname,
                            lastname: value.extra?.user?.lastname,
                          }),
                  }}
                  type={
                    value?.type === ForwardeeType.CONTACT || value.extra?.user
                      ? UploadType.ProfilePicture
                      : UploadType.GroupChatImage
                  }
                />
              )}
            />
          );
        }}
        animationDirections={{
          enterFrom: AnimationDirection.RIGHT,
          exitTo: forwardeePickerExitDir,
        }}
        renderSearchBar={() => (
          <SearchBar
            onChangeSearchValue={setSearchInputValue}
            onPressCloseSearch={onEmptySearchPress}
            searchInputValue={searchInputValue}
          />
        )}
        onPressSearch={() => setShowSearch(true)}
        showSearch={showSearch}
        isOptionsLoading={isChatRoomsLoading}
        renderActionsRightOfSearch={() => (
          <Appbar.Action
            icon="share"
            onPress={onPressForward}
            color={theme.customColors.primary}
            disabled={forwardeeSelections.length === 0}
          />
        )}
        renderListHeader={() => <SectionHeader text="Letzte Chats" />}
        renderListFooter={() => (
          <>
            <SectionHeader text="Weitere Kontakte" />
            <PickContactsButton onPress={toggleSelectingContacts} />
          </>
        )}
      />
      <FullScreenModalPicker<UserProfileAsOption>
        isVisible={isSelectingContacts}
        title="Kontakte"
        closeIcon="close"
        onClose={onCancelContactSelections}
        options={contactOptions}
        selectionsBuffer={contactsBuffer.map(selection => selection.id)}
        onSelectionBufferChange={onContactsBufferChange}
        renderOptionLabel={({ value, label, id }) => (
          <OptionLabel
            key={id}
            label={label}
            renderAvatar={() => (
              <AvatarDisplay
                size={32}
                avatar={{
                  otcPath: value.pictureOTC ?? '',
                  initials: getInitials({
                    firstname: value?.firstname,
                    lastname: value?.lastname,
                  }),
                }}
                type={UploadType.ProfilePicture}
              />
            )}
          />
        )}
        renderSearchBar={() => (
          <SearchBar
            onChangeSearchValue={setSearchContactInputValue}
            onPressCloseSearch={onEmptySearchContactPress}
            searchInputValue={searchContactInputValue}
          />
        )}
        onPressSearch={() => setShowSearchContacts(true)}
        showSearch={showSearchContact}
        isOptionsLoading={isContactsLoading}
        renderActionsRightOfSearch={() => (
          <Appbar.Action
            icon="check"
            onPress={onConfirmContactSelections}
            color={theme.customColors.primary}
          />
        )}
      />
    </>
  );
};
