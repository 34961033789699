import { HomeTabNavigatorParamList } from './navigators/HomeTabNavigator';
import { NavigatorScreenParams } from '@react-navigation/native';
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ChatRole, ChatRoom, UserPreference } from '../../graphql/operations';
import { HelpCategories } from '../features/helpPage/types';

export const AppStack = createStackNavigator<AppNavigatorParamList>();

export type AppNavigatorParamList = {
  // both auth and signed in screens
  UpdateRequiredScreen: undefined;
  Maintenance: undefined;
  CheckInbox: {
    channel?: string;
    action?: string;
    username?: string;
    pass?: string;
    newPassword?: string;
  };
  ConfirmationCode: {
    channel?: string;
    action?: string;
    username?: string;
    pass?: string;
    newPassword?: string;
    type?: string; // ONLY WHEN VERIFYING A SINGLE ATTRIBUTE, either "email" or "phone_number"
  };
  // auth
  TenantScreen: undefined;
  Login: undefined;
  NewPassword: {
    channel?: string;
    action?: string;
    username?: string;
    pass?: string;
  };
  ResetPassword: {
    username?: string;
  };
  LoginHelpPage: undefined;
  // user signed in
  Tabs: NavigatorScreenParams<HomeTabNavigatorParamList>;
  Home: undefined;
  Imprint: undefined;
  ReloginFlow: undefined;
  Relogin: undefined;
  Consent: undefined;
  Privacy: undefined;
  Fairplay: undefined;
  Version: undefined;
  Legal: undefined;
  LegalFlow: undefined;
  ChatDetail: { chatRoomId: string };
  NewDirectChat: undefined;
  Profile: { profileId?: string; name?: string; pressedBlock?: boolean };
  ProfileEdit: undefined;
  Settings: undefined;
  ReportAbuse: undefined;
  Help: undefined;
  HelpHome: undefined;
  ChatRoom: {
    id: string;
    name?: string;
    type?: ChatRoom['type'];
    imageUri?: string;
    members?: ChatPartner[];
    role?: ChatRole;
  };
  ChatMessageReadReceipts: {
    message: ExtendedMessage;
  };
  NewsDetail: {
    newsId: string;
    userGroupLabels: string[];
    translationEnabled: UserPreference['translationEnabled'];
    translationLanguage: UserPreference['translationLanguage'];
  };
  NewNews:
    | {
        newsId?: string;
      }
    | undefined;
  NewsLikes: {
    newsId: string;
  };
  ReloginNewPassword: undefined;
  ReloginConfirmationCode: undefined;
  ReloginCheckInbox: undefined;
  VerifyAttribute: { type?: string } | undefined;
  ChatSearch: undefined;
  OtcPdfReader: {
    source: string;
    type: UploadType;
    downloadable: boolean;
    fileName?: string;
  };
  PdfReader: {
    url: string;
    fileName?: string;
  };
  ScheduleLoginVivendi: { endpoint: string };
  ScheduleScreenVivendi: { token: string; endpoint: string };
  InfoPagesHome: undefined;
  FormsHomeScreen: undefined;
  FormDetail: { formId: string };
  FormReplyDetail: { formReplyId: string; formTitle: string; chatId?: string };
  LanguageSelection: { language: UserPreference['translationLanguage'] };
  HelpExternal: undefined;
  HelpForm: { category: HelpCategories };
  HelpSuccess: undefined;
  NewInquiry: undefined;
  InquiryDetails: { inquiryId: string };
  ResolvedInquiries: undefined;
  InformationScreen: undefined;
  AddMediaScreen: { files?: File<FileOptionalParams>[] };
};

import { RootStackNavigator } from './navigators/RootStackNavigator';
import { UploadType } from '../components/Common/FileUpload/types';
import { ChatPartner, ExtendedMessage } from '../features/chat/types';
import { File, FileOptionalParams } from '../components/FileGallery/types';

export const AppNavigator = () => {
  return <RootStackNavigator />;
};
