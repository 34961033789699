import { stockImageApi } from '@luciapp/stock-image-api';
import { mapExtensionToMimeType } from './mapExtensionToMimeType';
import { Uri } from './types';

export interface IFileGetNameAndMimeType {
  uri: Uri;
}

export const getFileNameAndMimeType = ({ uri }: IFileGetNameAndMimeType) => {
  const cleanedUri = stockImageApi.cleanFileNameForOTCUpload(uri);
  const extension = cleanedUri.split('.').pop();
  const name = cleanedUri.split('/').pop();

  if (!name) {
    throw new Error(`error extracting file name from folowing uri: ${uri}`);
  }

  const mimeType = mapExtensionToMimeType(extension);

  if (mimeType === 'file type not supported') {
    throw new Error(mimeType);
  }
  if (mimeType === 'no file extension found') {
    throw new Error(mimeType);
  }
  return { uri, name, mimeType };
};
