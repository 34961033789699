import { useCallback, useEffect, useState } from 'react';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { useDebounce } from 'use-debounce';

type Props = {
  shouldSearchBarBeOpen?: boolean;
};

export const useAppBar = (props?: Props) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [debouncedSearchValue] = useDebounce(searchInputValue, 500);
  const { isLeftDrawerOpen } = useResponsiveDrawer({});

  const onNewsTagPress = useCallback(
    (tag: string) => {
      if (!showSearch) {
        setShowSearch(true);
      }
      setSearchInputValue(tag);
    },
    [showSearch],
  );

  const onEmptySearchPress = useCallback(() => {
    setSearchInputValue(undefined);
    setShowSearch(false);
  }, []);

  useEffect(() => {
    if (!showSearch && isLeftDrawerOpen && props?.shouldSearchBarBeOpen) {
      setShowSearch(true);
    }
  }, [isLeftDrawerOpen, props?.shouldSearchBarBeOpen]);

  return {
    searchInputValue,
    onNewsTagPress,
    onEmptySearchPress,
    showSearch,
    setShowSearch,
    debouncedSearchValue,
    setSearchInputValue,
  };
};
