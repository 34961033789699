import { useEffect } from 'react';
import { useStore } from '../stores/store';
import { useSocketStore } from '../stores/SocketStore';
import { useAppStatus } from '../hooks/common/appStatus';
import { Log } from '../utils/Log';

export const useKeepSocketConnected = () => {
  const { appMovedToView } = useAppStatus();
  const refreshToken = useStore(s => s.refreshToken);
  const getHeaders = useStore(s => s.headers);
  const tenantName = useStore(s => s.tenantName);
  const socketStore = useSocketStore();

  useEffect(() => {
    if (refreshToken && !socketStore.isConnected() && tenantName) {
      socketStore.connect(getHeaders, tenantName);
    }
    return () => {
      socketStore.clear();
    };
  }, [refreshToken, tenantName]);

  useEffect(() => {
    Log.info('try to connect sockets?');
    if (appMovedToView && !socketStore.isConnected()) {
      Log.info('try to connect sockets!');
      socketStore.connect(getHeaders, tenantName);
    }
    return () => {
      socketStore.clear();
    };
  }, [appMovedToView]);
};
