import React, { useMemo } from 'react';
import { Platform, StyleSheet, useColorScheme, ScrollView } from 'react-native';
import { Appbar, Divider, Subheading } from 'react-native-paper';
import { useGetUserPreferenceQuery } from '../../../../graphql/operations';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import { useStore } from '../../../stores/store';
import { useAppTheme } from '../../../styles/theme';
import { useThemeStore } from '../../../stores/ThemeStore';
import { themeProp } from '../../../types/main';
import { BiometricSetting } from '../components/BiometricSetting';
import { PushNotificationSettings } from '../components/PushNotificationSettings';
import { Row } from '../components/Row';
import { TranslationSettings } from '../components/TranslationSettings';
import { SwitchSetting } from '../../../components/SwitchSetting/SwitchSetting';
import { AppBar } from '../../../components/AppBar/AppBar';
import { SettingsScreenProps } from './types';

export const SettingsScreen = ({ navigation }: SettingsScreenProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const systemColorScheme = useColorScheme();
  const { getColorScheme, setColorScheme } = useThemeStore();
  const { userProfile, tenantLevelTranslationEnabled } = useStore(s => ({
    userProfile: s.userProfile,
    tenantLevelTranslationEnabled: s.tenantLevelTranslationEnabled,
  }));

  const userPreference = useGetUserPreferenceQuery(
    {
      userId: userProfile?.userId ?? '',
    },
    { enabled: !!userProfile?.userId },
  );

  if (userPreference.isLoading) {
    return (
      <NoDataRefresh
        icon="plus-circle-outline"
        text="Einstellungen werden geladen..."
        isLoading={userPreference.isLoading}
      />
    );
  }

  if (!userProfile || userPreference.isError || !userPreference.data) {
    return (
      <>
        <NoDataRefresh
          text="Die Einstellungen konnten leider nicht geladen werden"
          onPress={async () => await userPreference.refetch()}
          isLoading={userPreference.isRefetching}
        />
      </>
    );
  }

  const newsTranslationSettingsAreShown = tenantLevelTranslationEnabled;

  return (
    <>
      <AppBar
        title="Einstellungen"
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => navigation.goBack()} color={theme.customColors.icon} />
        )}
      />
      <ScrollView
        style={styles.backGroundContainer}
        contentContainerStyle={styles.settingsContainer}
      >
        {Platform.OS !== 'web' && (
          <>
            <PushNotificationSettings
              preference={userPreference.data.getUserPreference}
              userId={userProfile.userId}
            />
            <BiometricSetting />
          </>
        )}
        {newsTranslationSettingsAreShown && (
          <TranslationSettings
            preference={userPreference.data.getUserPreference}
            userId={userProfile.userId}
            disabled={userPreference.isLoading || userPreference.isError}
            isRefetching={userPreference.isRefetching}
          />
        )}

        <Row>
          <Subheading>Ansicht</Subheading>
        </Row>
        <Divider style={styles.divider} />
        <SwitchSetting
          label="Dunkel-Modus"
          value={getColorScheme(systemColorScheme) === 'dark'}
          onValueChange={() =>
            setColorScheme(getColorScheme(systemColorScheme) === 'dark' ? 'light' : 'dark')
          }
          color={theme.customColors.primary}
        />
      </ScrollView>
    </>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    backGroundContainer: {
      backgroundColor: theme.colors.background,
      minHeight: '100%',
    },
    settingsContainer: {
      width: '100%',
      justifyContent: 'space-between',
      padding: 14,
    },
    divider: {
      marginVertical: 10,
    },
  });
