import { ChatRoomMessage } from '../../../../graphql/operations';

interface IValidateRealTimeMessage {
  chatRoomMessage: ChatRoomMessage;
}

export const validateRealTimeMessage = ({
  chatRoomMessage,
}: IValidateRealTimeMessage): ChatRoomMessage => {
  if (!chatRoomMessage) {
    throw new Error('no message is received');
  }
  const messageHasNoAuthor = !chatRoomMessage.author;
  if (messageHasNoAuthor) {
    throw new Error('cannot find author in message');
  }
  return { ...chatRoomMessage, readByCurrentUser: true };
};
