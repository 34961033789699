import { TenantConfigurationQuery } from './../../../graphql/operations';
import { Log } from '../../utils/Log';

import { retrieveTenant, storeTenant } from '../../utils/tenancyUtils';
import { useEffect, useState } from 'react';

import NetInfo from '@react-native-community/netinfo';

import { useStore } from '../../stores/store';

/**
 * prefer useTenantContext if not in App.tsx
 */
export const useTenant = () => {
  const [tenant, setTenant] = useState<
    TenantConfigurationQuery['tenantConfiguration'] | undefined
  >();
  const [isRetrievingTenant, setIsRetrievingTenant] = useState(true);
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(connection => {
      if (connection.isConnected) {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    /* @todo must be awaited */
    void (async () => {
      try {
        const storedTenantResult = await retrieveTenant();
        if (storedTenantResult && storedTenantResult.tenantName) {
          // refetch
          Log.info(`refetching Tenant: ${storedTenantResult.tenantName}`);
          if (isOnline) {
            try {
              await fetchTenantAndUpdateState(storedTenantResult.tenantName);
            } catch (err) {
              Log.error(err, {
                message: `Failed to fetch tenant from cloud`,
                data: { tenantName: storedTenantResult.tenantName },
              });
            }
          } else {
            Log.info('Setting Legacy Tenant from SecureStore');
            setTenant(storedTenantResult);
          }
        }
      } catch (err) {
        Log.error(err);
      } finally {
        setIsRetrievingTenant(false);
      }
    })();
  }, [isOnline]);

  /**
   * @deprecated
   * use fetchTenant, storeTenant and useStore.setTenant()
   */
  const fetchTenantAndUpdateState = async (name: string): Promise<void> => {
    try {
      const tenantRes = await fetch(process.env.EXPO_PUBLIC_API_URL + `getTenant?name=${name}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (tenantRes.status !== 200) {
        throw new Error('Tenant not found');
      }
      const json = await tenantRes.json();
      if (!json?.tenantName) {
        throw new Error('Invalid tenant json');
      }
      /* @todo must be awaited */
      void storeTenant(json);
      setTenant(json);
      useStore.getState().setTenantName(json.tenantName);
      useStore.setState({
        tenantLevelTranslationEnabled: json.translationEnabled,
      });
    } catch (err) {
      const error = err as Error;
      Log.error(err);
      throw new Error(error.message);
    } finally {
      setIsRetrievingTenant(false);
    }
  };

  return { tenant, isRetrievingTenant, fetchTenantAndUpdateState, setTenant };
};
