import * as ImagePicker from 'expo-image-picker';
import { ImageErrors } from '../requestImages/ImageErrors';
import { Log } from '../Log';

export const requestCameraImages = async () => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();
  if (status !== 'granted') {
    throw new Error(ImageErrors.permissionDenied);
  }
  try {
    return await ImagePicker.launchCameraAsync({
      // allowsEditing: true,
      // aspect: [1, 1],
      quality: 0.85,
    });
  } catch (e) {
    Log.error(e);
    Log.error('cannot launch camera');
    throw new Error(ImageErrors.unknownError);
  }
};
