import React, { memo, useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Menu, Portal } from 'react-native-paper';
import {
  NewsToReadQuery,
  SingleNewsToReadQuery,
  useDeleteNewsMutation,
  UserPreference,
} from '../../../graphql/operations';
import { CommonUserHeader } from '../../features/profiles/components/CommonUserHeader';
import { baseTheme as theme } from '../../styles/theme';
import { themeProp } from '../../types/main';
import { NewsAttachmentList } from './NewsAttachmentList';
import { NewsBottomActionBar } from './NewsBottomActionBar';
import { NewsReadConfirmationBox } from './NewsReadConfirmationBox';
import { NewsStats } from './NewsStats';
import { NewsTags } from './NewsTags';
import { NewsText } from './NewsText';
import { PollDetail } from './PollDetail';
import { useNavigation } from '@react-navigation/native';
import { NewsDetailScreenProps } from '../../features/news/types';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../stores/store';
import { NewsLinks } from './NewsLinks/NewsLinks';
import { getFullName } from '../../features/profiles/utils';
import { NewsCreationNotAvailableModal } from '../../features/news/newsCreation/components';
import { DiscardDialog } from '../Dialog/DiscardDialog';
import { Log } from '../../utils/Log';
import { invalidateNewsCache } from '../../features/news/utils/cache';
import { toast } from '../../utils/toast/toast';
import { LoadingBlocker } from '../Common/Loading/LoadingBlocker';
import { useLikeNews } from '../../features/news/hooks/useLikeNews';

type NewsContentPropTypes = {
  news: NewsToReadQuery['newsList']['news'][number] & SingleNewsToReadQuery['news'];
  userGroupLabels: string[];
  isInDetailedView: boolean;
  setMessageToCopy: React.Dispatch<React.SetStateAction<string>>;
  onOnline: (fn: () => void) => void;
  translationEnabled: UserPreference['translationEnabled'];
  translationLanguage: UserPreference['translationLanguage'];
  setTranslationSetupPopUpShown: React.Dispatch<React.SetStateAction<boolean>>;
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
  onTagPress?(tag: string): void;
};

export const NewsContent = memo(
  ({
    news,
    userGroupLabels,
    isInDetailedView,
    setMessageToCopy,
    onOnline,
    translationEnabled,
    translationLanguage,
    setTranslationSetupPopUpShown,
    groupIdsInNewsTimelineQuery,
    searchTextInNewsTimeLineQuery,
    onTagPress,
  }: NewsContentPropTypes) => {
    const navigation = useNavigation<NewsDetailScreenProps['navigation']>();
    const styles = createStyles(theme);
    const [menuOpen, setMenuOpen] = useState(false);
    const queryClient = useQueryClient();
    const deleteNewsMutation = useDeleteNewsMutation();
    const { userProfile, permissionsByUserGroup } = useStore(s => ({
      userProfile: s.userProfile,
      tenantLevelTranslationEnabled: s.tenantLevelTranslationEnabled,
      permissionsByUserGroup: s.permissions,
      permissions: s.permissions?.filter(permissionByUserGroup =>
        news.userGroupIds.includes(permissionByUserGroup.groupId),
      ),
    }));
    const [userDidTranslateNews, setUserDidTranslateNews] = useState<boolean>(false);
    const [isNewsCreationNotAvailable, setNewsCreationNotAvailable] = useState<boolean>(false);
    const [isDeletionDialogOpen, setDeletionDialogOpen] = useState<boolean>(false);
    const [isMutating, setMutating] = useState(false);
    const { isLoading: isLikeLoading, toggleLikeNews } = useLikeNews({
      newsId: news.id,
      liked: news.likeResult?.liked,
      groupIdsInNewsTimelineQuery: groupIdsInNewsTimelineQuery,
    });

    const newsIncludesTranslation = news.newsPostTranslation;
    const newsIsTranslatedByUser = userProfile?.userId
      ? news.newsPostTranslation?.usersWhoHaveTranslated?.includes(userProfile.userId)
      : false;
    const languageKeyisPrefferedLanguage =
      news.newsPostTranslation?.languageKey === translationLanguage;

    const isNewsEditor = news.userGroupIds
      .map(userGroupId => {
        const permissionsOfUserGroup = permissionsByUserGroup?.find(
          permissionByUserGroup => permissionByUserGroup.groupId === userGroupId,
        );
        return permissionsOfUserGroup?.permissions.includes('news:update') ?? false;
      })
      .every(isPermitted => isPermitted);

    const isNewsDeletor = news.userGroupIds
      .map(userGroupId => {
        const permissionsOfUserGroup = permissionsByUserGroup?.find(
          permissionByUserGroup => permissionByUserGroup.groupId === userGroupId,
        );
        return permissionsOfUserGroup?.permissions.includes('news:delete') ?? false;
      })
      .every(isPermitted => isPermitted);

    const isEditable = isNewsEditor && !news.poll;
    const isDeletable = isNewsDeletor && !news.poll;

    const onProfilePress = (pressedBlock?: boolean) => {
      if (news.author) {
        setMenuOpen(false);
        navigation.navigate('Profile', {
          profileId: news.author.userId,
          pressedBlock: Boolean(pressedBlock),
          name: getFullName({
            firstname: news.author.firstname,
            lastname: news.author.lastname,
          }),
        });
      }
    };

    const onPressEdit = () => {
      setMenuOpen(false);
      if (Platform.OS === 'web') {
        return setNewsCreationNotAvailable(true);
      }
      navigation.navigate('NewNews', { newsId: news.id });
    };

    const onPressDelete = () => {
      setMenuOpen(false);
      setDeletionDialogOpen(true);
    };

    const onPressConfirmDeletion = useCallback(async () => {
      setMenuOpen(false);

      if (deleteNewsMutation.isLoading) {
        return;
      }
      try {
        setDeletionDialogOpen(isMutating);
        setMutating(true);
        await deleteNewsMutation.mutateAsync({ newsId: news.id });
        await invalidateNewsCache({ newsId: news.id, groupIdsInNewsTimelineQuery, queryClient });
        toast('Der Newsbeitrag wurde erfolgreich gelöscht.');
        if (isInDetailedView) {
          navigation.goBack();
        }
      } catch (error) {
        const err = error as Error;
        Log.error(error, { message: err.message });
        toast(
          'Beim Löschen des Newsbeitrags ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        );
      } finally {
        setMutating(false);
      }
    }, [
      navigation,
      news.id,
      queryClient,
      groupIdsInNewsTimelineQuery,
      deleteNewsMutation,
      isInDetailedView,
    ]);

    useEffect(() => {
      setUserDidTranslateNews(
        Boolean(
          newsIncludesTranslation && newsIsTranslatedByUser && languageKeyisPrefferedLanguage,
        ),
      );
    }, [newsIncludesTranslation, newsIsTranslatedByUser, languageKeyisPrefferedLanguage]);

    const navigateToDetailedView = useCallback(() => {
      navigation.navigate('NewsDetail', {
        newsId: news.id,
        userGroupLabels: userGroupLabels,
        translationEnabled: translationEnabled,
        translationLanguage: translationLanguage,
      });
    }, [news.id, userGroupLabels, translationEnabled, translationLanguage]);

    return (
      <>
        <View style={styles.itemHeader} testID="NewsComponent">
          <CommonUserHeader
            ripple={false}
            user={news.author}
            userGroupLabelsForNews={userGroupLabels}
            date={news.visibleAt}
            menuItems={
              <>
                {isEditable && (
                  <Menu.Item
                    leadingIcon="pencil-outline"
                    onPress={onPressEdit}
                    title="Bearbeiten"
                  />
                )}
                {news.author && news.author.userId !== userProfile?.userId && (
                  <Menu.Item
                    leadingIcon="account-details-outline"
                    onPress={() => onProfilePress(false)}
                    title="Benutzer ansehen"
                  />
                )}
                {news.settings.likesEnabled && (
                  <Menu.Item
                    leadingIcon={
                      news.likeResult?.liked ? 'heart-remove-outline' : 'thumb-up-outline'
                    }
                    onPress={() => {
                      setMessageToCopy('');
                      /* @todo must be awaited */
                      onOnline(() => void toggleLikeNews());
                    }}
                    title={news.likeResult?.liked ? 'Gefällt mir entfernen' : 'Gefällt mir'}
                  />
                )}
                {!isInDetailedView && (
                  <Menu.Item
                    leadingIcon="book-open-outline"
                    onPress={() => {
                      setMenuOpen(false);
                      navigateToDetailedView();
                    }}
                    title="Alles ansehen"
                  />
                )}
                {!isInDetailedView && news.settings.commentsEnabled && (
                  <Menu.Item
                    leadingIcon="message-outline"
                    onPress={() => {
                      setMenuOpen(false);
                      navigateToDetailedView();
                    }}
                    title="Kommentieren"
                  />
                )}
                <Menu.Item
                  leadingIcon="alert-circle-outline"
                  onPress={() => {
                    setMenuOpen(false);
                    navigation.navigate('ReportAbuse');
                  }}
                  title="Melden"
                />
                {news.author && news.author.userId !== userProfile?.userId && (
                  <Menu.Item
                    testID="block-user"
                    title="Blockieren"
                    leadingIcon="minus-circle-outline"
                    onPress={() => onProfilePress(true)}
                  />
                )}
                {isDeletable && (
                  <Menu.Item leadingIcon="delete-forever" onPress={onPressDelete} title="Löschen" />
                )}
              </>
            }
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
        </View>
        <View style={styles.wrapTextStyle}>
          <NewsText
            newsId={news.id}
            title={news.title}
            content={news.content}
            userDidTranslateNews={userDidTranslateNews}
            translationId={news.newsPostTranslation?.id}
            translatedTitle={news.newsPostTranslation?.title}
            translatedContent={news.newsPostTranslation?.content}
            startExpanded={isInDetailedView}
            translationEnabled={translationEnabled}
            translationLanguage={translationLanguage}
            setTranslationSetupPopUpShown={setTranslationSetupPopUpShown}
            groupIdsInNewsTimelineQuery={groupIdsInNewsTimelineQuery}
            searchTextInNewsTimeLineQuery={searchTextInNewsTimeLineQuery}
          />
        </View>
        <NewsLinks infoPageLinks={news.infoPages} formLinks={news.forms} navigation={navigation} />
        <NewsAttachmentList attachments={news.attachments} />
        <NewsTags tags={news.tags} onTagPress={onTagPress} />
        <NewsStats
          item={news}
          onPressCommentCount={() => {
            if (!isInDetailedView) {
              return navigateToDetailedView();
            }
          }}
        />
        <NewsBottomActionBar
          likesEnabled={news.settings.likesEnabled}
          hasLiked={news.likeResult?.liked ?? false}
          onLikePress={() => {
            setMessageToCopy('');
            /* @todo must be awaited */
            onOnline(() => void toggleLikeNews());
          }}
          disableLikeButton={isLikeLoading}
          commentsEnabled={isInDetailedView ? false : news.settings.commentsEnabled}
          onCommentPress={() => {
            navigateToDetailedView();
          }}
        />
        {news.settings.readConfirmationsEnabled && (
          <NewsReadConfirmationBox
            hasAlreadyConfirmed={news.readConfirmationResult?.readByUser ?? false}
            newsId={news.id}
            groupIdsInNewsTimelineQuery={groupIdsInNewsTimelineQuery}
            searchTextInNewsTimeLineQuery={searchTextInNewsTimeLineQuery}
          />
        )}
        {news.poll && (
          <PollDetail newsId={news.id} poll={news.poll} hasAnswered={!!news.poll.answerOfUser} />
        )}
        <NewsCreationNotAvailableModal
          visible={isNewsCreationNotAvailable}
          setVisible={setNewsCreationNotAvailable}
          newsId={news.id}
        />
        <DiscardDialog
          discardButtonLabel="Löschen"
          isOpen={isDeletionDialogOpen}
          title="Sind Sie sicher?"
          content="Der Newsbeitrag und sein gesamter Inhalt werden dauerhaft gelöscht."
          onCancel={() => setDeletionDialogOpen(false)}
          onDiscard={onPressConfirmDeletion}
        />
        <Portal>
          <LoadingBlocker visible={isMutating} spinnerColor={theme.customColors.accent} />
        </Portal>
      </>
    );
  },
);

const createStyles = (themeArgument: themeProp) =>
  StyleSheet.create({
    rowView: {
      flexDirection: 'row',
      marginHorizontal: 15,
      paddingVertical: 6,
    },
    likeTextStyle: {
      alignSelf: 'center',
      marginStart: 7,
    },
    viewLightDivider: {
      width: '100%',
      height: 0.7,
      marginVertical: 3,
      backgroundColor: themeArgument.customColors.borders,
    },
    listStyle: {
      marginHorizontal: 10,
    },
    wrapTextStyle: {
      marginTop: 10,
      marginHorizontal: 15,
    },
    dialogDescriptionStyle: {
      fontSize: 14,
      color: themeArgument.customColors.textGray,
    },
    menuImageView: {
      width: 20,
    },
    likeImageView: {
      width: 17,
      height: 17,
      alignSelf: 'center',
    },
    likeBigImageView: {
      width: 23,
      height: 23,
      alignSelf: 'center',
    },
    viewDarkDivider: {
      width: '100%',
      height: 10,
      backgroundColor: themeArgument.customColors.borders,
      marginTop: 4,
    },
    profileView: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginHorizontal: 15,
      marginTop: 8,
    },
    imageContainer: {
      alignSelf: 'center',
    },
    viewDivider: {
      width: '100%',
      height: 1,
      marginBottom: 6,
      backgroundColor: themeArgument.customColors.borders,
    },
    imageView: {
      width: 35,
      height: 35,
    },
    headerView: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginHorizontal: 10,
    },
    mainView: {
      flex: 1,
    },
    authorName: {
      fontSize: 15,
    },
    date: {
      color: themeArgument.customColors.textGray,
    },
    description: {
      color: themeArgument.customColors.text,
      textAlign: 'left',
      marginBottom: 4,
    },
    menu: {
      alignSelf: 'center',
    },
    actionButton: {
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 12,
      marginVertical: 6,
    },
    closeWrapper: {
      position: 'absolute',
      top: 30,
      left: 30,
      zIndex: 2,
    },
    closeButton: {
      fontSize: 35,
      fontWeight: '500',
    },
  });
