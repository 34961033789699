import * as yup from 'yup';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const checkIfValidPhone = async (input: string) =>
  yup
    .object()
    .shape({
      phone_number: yup
        .string()
        .trim()
        .test('isValidPhone', 'Ungültige Telefonnummer', value => {
          if (!value) {
            return true;
          }
          if (typeof value !== 'string') {
            return false;
          }
          const phone = parsePhoneNumberFromString(value);
          return !!phone && phone.isValid();
        })
        .test(
          'isValidPhone',
          'Länderkennung erforderlich (z.B. +49)',
          value => !value || value?.trim()?.substring(0, 1) === '+',
        ),
    })
    .isValid({ phone_number: input });

export const checkIfValidEmail = async (input: string) =>
  yup
    .object()
    .shape({
      email: yup.string().email(),
    })
    .isValid({ email: input });

export const userInputValidationSchema = {
  user: yup
    .string()
    .trim()
    .test(
      'user',
      'Länderkennung erforderlich (z.B. +49)',
      (val?: string) => !(val && val.trim().indexOf('0') === 0),
    )
    .test(
      'user',
      'Ungültige Telefonnummer',
      (val?: string) => !(val && val.trim().indexOf('+') === 0 && val.length < 5),
    )
    .test(
      'user',
      'Ungültige E-Mail Adresse',
      (val?: string) => !(val && val.trim().indexOf('@') >= 0 && val.indexOf('.') === -1),
    )
    .test(
      'user',
      'Dieses Feld darf keine Leerzeichen enthalten',
      (val?: string) => !(val && val.trim().includes(' ')),
    )
    .required('Anmeldename benötigt'),
};
