import React from 'react';
import { useAppTheme } from '../../styles/theme';
import { Shimmer } from '../Shimmer/Shimmer';

export const FileGalleryTileShimmer = () => {
  const theme = useAppTheme();

  return (
    <Shimmer radius={10} color={theme.customColors.imageBackground} height={150} width={150} />
  );
};
