export const getEnvironmentByApiUrl = (apiUrl: string): 'dev' | 'l' | 'prod' => {
  if (!apiUrl || typeof apiUrl !== 'string') {
    return 'l';
  }
  if (apiUrl.includes('dev')) {
    return 'dev';
  }
  if (apiUrl.includes('prod')) {
    return 'prod';
  }
  return 'l';
};
