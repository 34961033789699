import { UserProfile } from '../../../../graphql/operations';
import { ChatPartner } from '../types';

export const getChatRoomName = (
  name?: string | null,
  chatPartnerParams?: {
    chatPartners: ChatPartner[];
    userProfile: UserProfile;
  },
): string => {
  if (name) {
    return name;
  }

  if (!chatPartnerParams) {
    return 'Ehemaliger Benutzer';
  }
  const { chatPartners, userProfile } = chatPartnerParams;
  const partner = chatPartners.find(member => member.userId !== userProfile.userId);
  if (partner) {
    return `${partner?.firstname} ${partner?.lastname}`;
  }
  return 'Ehemaliger Benutzer';
};
