import { AppTheme, useAppTheme } from '../../../styles/theme';
import React, { useMemo } from 'react';
import { ExtendedMessage } from '../types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

type ChatSystemMessagePropTypes = {
  message: ExtendedMessage;
  onPress?: () => void;
};

export const ChatSystemMessage = ({ message, onPress }: ChatSystemMessagePropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
      <View style={styles.container}>
        <Text style={styles.text}>{message.text}</Text>
      </View>
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      borderRadius: 100,
      marginVertical: 8,
      flexDirection: 'row',
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.primary,
      width: 256,
      opacity: 0.8,
      marginTop: 5,
      marginBottom: 10,
    },
    text: {
      flex: 1,
      textAlign: 'center',
      marginVertical: 2,
      color: theme.customColors.pureWhite,
      fontSize: 14,
      fontWeight: '500',
    },
  });
