import { Appbar, Portal, Text } from 'react-native-paper';
import { StyleSheet, SafeAreaView } from 'react-native';
import React, { useMemo, useState } from 'react';

import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { StackScreenProps } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { HelpCard } from '../components/HelpCard';
import {
  useCreatePrivateChatRoomMutation,
  useGetGlobalSettingsQuery,
} from '../../../../graphql/operations';
import { useStore } from '../../../stores/store';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import { GenericPopUp } from '../../../stories/Modal/GenericPopUp';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { AppBar } from '../../../components/AppBar/AppBar';

export type HelpScreenProps = StackScreenProps<AppNavigatorParamList, 'Help'>;

export const HelpScreen = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<HelpScreenProps['navigation']>();
  const globalSettings = useGetGlobalSettingsQuery();
  const createChatMutation = useCreatePrivateChatRoomMutation();
  const userProfile = useStore(s => s.userProfile);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  if (globalSettings.isLoading) {
    return (
      <NoDataRefresh
        icon="plus-circle-outline"
        text="Kontaktperson wird geladen..."
        isLoading={globalSettings.isLoading}
      />
    );
  }

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <AppBar
            title="Hilfe"
            renderActionsLeftOfTitle={() => (
              <Appbar.BackAction
                onPress={() => navigation.goBack()}
                color={theme.customColors.icon}
              />
            )}
          />
          <SafeAreaView style={styles.container}>
            {userProfile?.userId !== globalSettings.data?.getGlobalSettings.supportAdminId && (
              <HelpCard
                title="Intern"
                description="Hilfe bei Login, Passwort oder geänderten Kontaktdaten"
                logoPath={globalSettings.data?.getGlobalSettings.logoS3Path}
                onPress={() => {
                  if (!globalSettings.data?.getGlobalSettings.supportAdminId) {
                    setErrorModalIsOpen(true);
                    return;
                  }
                  navigation.navigate('Profile', {
                    profileId: globalSettings.data?.getGlobalSettings.supportAdminId,
                  });
                }}
              />
            )}
            <HelpCard
              title="Extern"
              description="Melden von Funktionsfehlern oder Ideen für Verbesserungen"
              onPress={() => onOnline(() => navigation.navigate('HelpExternal'))}
            />
            <Portal>
              <GenericPopUp
                visible={errorModalIsOpen || createChatMutation.isError}
                headlineTitle="Keine Kontaktperson"
                primaryActionTitle="Schließen"
                onDismiss={() => {
                  createChatMutation.reset();
                  setErrorModalIsOpen(false);
                }}
                buttonMarginTop={16}
              >
                <Text>
                  {createChatMutation.isError
                    ? 'Beim öffnen des Chats ist ein Fehler aufgetreten'
                    : 'Ihre Organisation hat keine Kontaktperson für die Hilfeseite festegelet. Bitte wenden Sie sich an Ihren Administrator.'}
                </Text>
              </GenericPopUp>
            </Portal>
          </SafeAreaView>
        </>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 30,
    },
  });
