import React from 'react';
import {
  useDeleteTranslationsOfUserInLanguageMutation,
  useGetUserPreferenceQuery,
  useListTranslationLanguagesQuery,
  useUpdateUserPreferenceMutation,
} from '../../../../graphql/operations';
import { Appbar } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { LanguageSelectionScreenProps } from './types';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../../stores/store';
import { useAppTheme } from '../../../styles/theme';
import { Log } from '../../../utils/Log';
import {
  FullScreenModalPicker,
  FullScreenModalPickerOption,
} from '../../../components/Picker/FullScreenModalPicker';
import { OptionLabel } from '../../../components/Picker/components/OptionLabel';
import { useSelections } from '../../../hooks/useSelections/useSelections';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import { useAppBar } from '../../news/hooks/useAppBar';

type LanguageKey = string;

export const LanguageSelectionScreen = ({
  route: {
    params: { language },
  },
}: LanguageSelectionScreenProps) => {
  const queryClient = useQueryClient();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const updateUserPreferences = useUpdateUserPreferenceMutation();
  const languages = useListTranslationLanguagesQuery();
  const {
    selections,
    isEmpty: noLanguageIsSelected,
    append,
    isCountValid,
  } = useSelections<LanguageKey>({ countOfSelections: { max: 1, min: 1 } });
  const theme = useAppTheme();
  const userProfile = useStore(s => s.userProfile);
  const deleteTranslationsOfUser = useDeleteTranslationsOfUserInLanguageMutation();

  const languageOptions: FullScreenModalPickerOption<string>[] =
    languages.data?.listTranslationLanguages.map(item => ({
      id: item!.languageKey!,
      label: item!.languageName!,
      sublabel: item!.languageNameInGerman!,
      value: item!.languageKey!,
    })) ?? [];

  const onUserSwitchedLanguage = () => {
    if (noLanguageIsSelected) {
      return;
    }
    if (language) {
      deleteTranslationsOfUser.mutate(
        {
          translationLanguage: language,
        },
        {
          onSuccess: onConfirmLanguageSelection,
          onError: error =>
            Log.error(error, {
              message: 'an error occurred when handling news translation language switch',
            }),
        },
      );
    } else {
      onConfirmLanguageSelection();
    }
  };

  const onSuccessPreferenceChange = async () => {
    if (userProfile?.userId) {
      await queryClient.invalidateQueries(
        useGetUserPreferenceQuery.getKey({ userId: userProfile.userId }),
      );
    }
    navigation.goBack();
  };

  const onConfirmLanguageSelection = () => {
    if (!isCountValid) {
      return;
    }
    updateUserPreferences.mutate(
      {
        input: {
          translationLanguage: selections[0].value,
        },
      },
      {
        onSuccess: onSuccessPreferenceChange,
        onError: error =>
          Log.error(error, {
            message:
              'an error occurred when confirming the selection of a language for news translation',
          }),
      },
    );
  };

  const {
    onEmptySearchPress: onEmptySearchPress,
    searchInputValue: searchInputValue,
    debouncedSearchValue: debouncedSearchValue,
    setSearchInputValue: setSearchInputValue,
    setShowSearch: setShowSearch,
    showSearch: showSearch,
  } = useAppBar();

  const filteredLanguageOptions = languageOptions?.filter(languageOption =>
    debouncedSearchValue
      ? languageOption.label.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        languageOption.sublabel?.toLowerCase().includes(debouncedSearchValue.toLowerCase())
      : true,
  );

  return (
    <FullScreenModalPicker<LanguageKey>
      title="Sprache"
      description="Bitte wählen Sie unten eine der verfügbaren Sprachen aus."
      onClose={() => navigation.goBack()}
      isVisible={true}
      renderOptionLabel={({ label, sublabel, id }) => (
        <OptionLabel key={id} label={label} sublabel={sublabel} />
      )}
      options={filteredLanguageOptions}
      isOptionsLoading={languages.isInitialLoading}
      selectionsBuffer={selections.map(selection => selection.value)}
      showSearch={showSearch}
      renderSearchBar={() => (
        <SearchBar
          onChangeSearchValue={setSearchInputValue}
          onPressCloseSearch={onEmptySearchPress}
          searchInputValue={searchInputValue}
        />
      )}
      onPressSearch={() => setShowSearch(true)}
      onSelectionBufferChange={selection =>
        selection ? append({ id: selection, value: selection }) : () => {}
      }
      isConfirmSelectionDisabled={isCountValid}
      onPressRefreshOptions={() => languages.refetch()}
      renderActionsRightOfSearch={() => (
        <Appbar.Action
          icon="check"
          onPress={() => {
            if (isCountValid && language !== selections[0].value) {
              return onUserSwitchedLanguage();
            }
            onConfirmLanguageSelection();
          }}
          color={theme.customColors.primary}
          disabled={!isCountValid}
        />
      )}
    />
  );
};
