import { InfiniteData } from '@tanstack/react-query';
import { News, NewsToReadByUserGroupQuery } from '../../../../graphql/operations';

interface IUpdateNewsInNewsToRead {
  oldData: InfiniteData<NewsToReadByUserGroupQuery>;
  updatedNews: News;
}

export const updateNewsInNewsToRead = ({ updatedNews, oldData }: IUpdateNewsInNewsToRead) => {
  return {
    pageParams: oldData.pageParams,
    pages: oldData.pages.map(page => {
      const newsInPage = page.newsListByUserGroup.news;
      if (newsInPage.map(newsToFind => newsToFind.id).includes(updatedNews.id)) {
        return {
          newsListByUserGroup: {
            ...page.newsListByUserGroup,
            news: newsInPage.map(notUpdatedNews => {
              if (notUpdatedNews.id === updatedNews.id) {
                return updatedNews;
              }
              return notUpdatedNews;
            }),
          },
        };
      }
      return page;
    }),
  };
};
