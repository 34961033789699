import { ActivityIndicator, Appbar, useTheme } from 'react-native-paper';
import { Dimensions, FlatList, RefreshControl, View } from 'react-native';
import React, { useMemo, useState } from 'react';

import { AppNavigatorParamList } from '../../router/AppNavigator';
import { CommonUserHeader } from '../../features/profiles/components/CommonUserHeader';
import { NoDataRefresh } from '../../components/Common/Loading/NoDataRefresh';
import { StackScreenProps } from '@react-navigation/stack';
import { themeProp } from '../../types/main';
import { useInfiniteLikesQuery } from '../../../graphql/operations';
import { useStore } from '../../stores/store';
import { useRefreshControlWithTimeout } from '../../hooks/list/useRefreshControlWithTimeout';
import { getFullName } from '../../features/profiles/utils';
import { AppBar } from '../../components/AppBar/AppBar';

type LikesDetailProps = StackScreenProps<AppNavigatorParamList, 'NewsLikes'>;

export const LikesDetail = (props: LikesDetailProps) => {
  const userProfile = useStore(s => s.userProfile);
  const theme = useTheme() as themeProp;
  const likes = useInfiniteLikesQuery(
    'pageInput',
    { pageInput: { pageSize: 10 }, newsId: props.route.params.newsId },
    {
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce((count, page) => count + page.likes.likes.length, 0);
        if (alreadyFetched === lastPage.likes.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 10,
            token: lastPage.likes?.token,
          },
        };
      },
    },
  );

  const likeData = useMemo(() => {
    return likes.data?.pages.flatMap(p => p.likes.likes) ?? [];
  }, [likes.data]);

  const [menuOpen, setMenuOpen] = useState(false);

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => likes.refetch(),
    errorMessageToLog: `failed to refetch data for the likes detail screen`,
  });

  return (
    <>
      <AppBar
        title={'Gefällt mir'}
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => props.navigation.goBack()} />
        )}
      />
      <FlatList
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 16,
          paddingTop: 10,
          backgroundColor: theme.colors.background,
          height: Dimensions.get('window').height,
        }}
        data={likeData}
        keyExtractor={item => item.id}
        renderItem={({ item }) => (
          <View
            style={{
              width: '100%',
              marginBottom: 8,
              paddingBottom: 4,
              borderBottomColor: theme.customColors.borders,
              borderBottomWidth: 1,
            }}
          >
            <CommonUserHeader
              user={item.by}
              ripple={true}
              onPress={() => {
                if (item.by) {
                  if (item.by.userId === userProfile?.userId) {
                    props.navigation.navigate('ProfileEdit');
                  } else {
                    props.navigation.navigate('Profile', {
                      profileId: item.by.userId,
                      name: getFullName({
                        firstname: item.by.firstname,
                        lastname: item.by.lastname,
                      }),
                    });
                  }
                }
              }}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          </View>
        )}
        /* @todo must be awaited */
        onEndReached={() => void likes.fetchNextPage()}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing || likes.isLoading}
            /* @todo must be awaited */
            onRefresh={() => void onRefresh()}
            tintColor={theme.customColors.refreshControlSpinner}
          />
        }
        /* @todo must be awaited */
        ListEmptyComponent={
          <NoDataRefresh
            text={isRefreshing || likes.isLoading ? '' : 'Keine Benutzer gefunden'}
            onPress={() => void onRefresh()}
          />
        }
        ListFooterComponent={
          <>
            {likes.hasNextPage && (
              <ActivityIndicator animating color={theme.customColors.primary} size="large" />
            )}
          </>
        }
      />
    </>
  );
};
