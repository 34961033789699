import React, { useMemo } from 'react';
import { ImageTile } from './ImageTile';
import { TileActionProps, TileFactoryProps } from './TileFactoryProps';
import { IconButton } from 'react-native-paper';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DocumentTile } from '../DocumentTile/DocumentTile';
import { FileType } from '../FileGallery/types';
import { VideoTile } from '.';

const TileActions = ({ component, isDeletable, onDeletePress, onTilePress }: TileActionProps) => {
  const styles = useMemo(() => createStyles(), []);

  return (
    <TouchableOpacity onPress={onTilePress} activeOpacity={0.8}>
      {component}
      {!!isDeletable && (
        <IconButton
          size={14}
          iconColor="white"
          icon="close-thick"
          style={styles.fab}
          onPress={onDeletePress}
        />
      )}
    </TouchableOpacity>
  );
};

export function TileFactory<P>({
  file,
  onTilePress,
  onDeletePress,
  allowsDeletion,
  width = 150,
}: TileFactoryProps<P>) {
  if (file.type === FileType.Document) {
    return (
      <TileActions
        component={<DocumentTile file={file} width={width} />}
        onTilePress={() => onTilePress?.(file)}
        onDeletePress={() => onDeletePress?.(file)}
        isDeletable={!!allowsDeletion}
      />
    );
  }
  if (file.type === FileType.Image) {
    return (
      <TileActions
        component={<ImageTile file={file} width={width} />}
        onTilePress={() => onTilePress?.(file)}
        onDeletePress={() => onDeletePress?.(file)}
        isDeletable={!!allowsDeletion}
      />
    );
  }
  if (file.type === FileType.Video || file.type === FileType.Audio) {
    return (
      <TileActions
        component={<VideoTile file={file} width={width} />}
        onDeletePress={() => onDeletePress?.(file)}
        isDeletable={!!allowsDeletion}
      />
    );
  }
  return <></>;
}

const createStyles = () =>
  StyleSheet.create({
    fab: {
      backgroundColor: 'black',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  });
