import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

type OptionLabelPropTypes = {
  label: string;
  sublabel?: string;
  renderAvatar?: () => JSX.Element;
};

export const OptionLabel = ({ label, sublabel, renderAvatar }: OptionLabelPropTypes) => {
  const styles = createStyles();

  return (
    <View style={styles.container}>
      {renderAvatar?.()}
      <View>
        <Text variant="bodyMedium" numberOfLines={1} style={styles.label}>
          {label}
        </Text>
        {sublabel && (
          <Text variant="bodySmall" numberOfLines={1} style={styles.label}>
            {sublabel}
          </Text>
        )}
      </View>
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
    label: { marginLeft: 8 },
    sublabel: { marginLeft: 8 },
  });
