import { Appbar, Button } from 'react-native-paper';
import {
  Dimensions,
  Linking,
  ScrollView,
  useWindowDimensions,
  View,
  StyleSheet,
} from 'react-native';
import React, { useEffect, useMemo } from 'react';

import Markdown from 'react-native-markdown-display';
import { MdStyles } from './MarkdownStyles';
import { useLegal } from '../../hooks/common/legal';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { AppBar } from '../../components/AppBar/AppBar';

export const ImprintScreen = () => {
  const theme = useAppTheme();
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Imprint'>['navigation']>();
  const { height } = useWindowDimensions();
  const styles = useMemo(() => createStyles(height, theme), [height, theme]);
  const { imprint, fetchImprint } = useLegal();

  useEffect(() => {
    /* @todo must be awaited */
    void fetchImprint();
  }, []);

  return (
    <>
      <AppBar
        title="Impressum"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ScrollView style={styles.screenContainer}>
        <Markdown style={MdStyles(theme)} mergeStyle>
          {imprint}
        </Markdown>
        <View style={styles.container}>
          <Button
            style={styles.button}
            uppercase={false}
            mode="outlined"
            onPress={async () =>
              await Linking.openURL('mailto:datenschutz@luciapp.de?subject=Datenschutzanfrage')
            }
            textColor={theme.colors.onSurface}
          >
            Datenschutzanfrage
          </Button>
        </View>
        <View style={{ height: 20 }} />
      </ScrollView>
    </>
  );
};

const createStyles = (height: number, theme: AppTheme) =>
  StyleSheet.create({
    screenContainer: {
      flex: 1,
      paddingLeft: 10,
      paddingRight: 16,
      paddingTop: 10,
      backgroundColor: theme.colors.background,
      height: Dimensions.get('window').height,
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      marginVertical: 10,
    },
    button: {
      borderRadius: 5,
      marginTop: 8,
      marginHorizontal: 70,
      flex: 1,
    },
  });
