import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import {
  Comment,
  CommentNewsInput,
  CommentNewsMutation,
  Exact,
  ReplyToCommentInput,
  ReplyToNewsCommentMutation,
  SingleNewsToReadQuery,
} from '../../../../graphql/operations';
import { UserProfile } from '../../../types/main';
import { invalidateCommentsCache, invalidateNewsCache } from '../utils/cache';
import { addFleetingCommentToComments } from '../utils/addFleetingCommentToComments';
import { addFleetingReplyToComment } from '../utils/addFleetingReplyToComment';

type InitHandleAddCommentPropTypes = {
  inReplyTo: {
    commentId: string;
    parentAuthorId: string;
    name: string;
  } | null;
  setInReplyTo: React.Dispatch<
    React.SetStateAction<{
      commentId: string;
      parentAuthorId: string;
      name: string;
    } | null>
  >;
  comments: Comment[];
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
  setFleetingComment: React.Dispatch<
    React.SetStateAction<{
      displayed: boolean;
      inReplyTo: {
        commentId?: string;
      };
    }>
  >;
  replyCommentMutation: UseMutationResult<
    ReplyToNewsCommentMutation,
    unknown,
    Exact<{
      input: CommentNewsInput;
      replyToCommentInput: ReplyToCommentInput;
    }>,
    unknown
  >;
  commentNewsMutation: UseMutationResult<
    CommentNewsMutation,
    unknown,
    Exact<{
      input: CommentNewsInput;
    }>,
    unknown
  >;
  queryClient: QueryClient;
};

export const initUseHandleAddComment =
  ({
    inReplyTo,
    setInReplyTo,
    comments,
    setComments,
    setFleetingComment,
    replyCommentMutation,
    commentNewsMutation,
    queryClient,
  }: InitHandleAddCommentPropTypes) =>
  (news: SingleNewsToReadQuery['news'], sender: UserProfile, content: string) => {
    const isNewsLegacy = !news.userGroupIds || news.userGroupIds.length === 0;

    const invalidateCache = async () => {
      await invalidateNewsCache({
        newsId: news.id,
        groupIdsInNewsTimelineQuery: isNewsLegacy ? [news.userGroupId] : news.userGroupIds!,
        queryClient,
      });
      await invalidateCommentsCache({
        newsId: news.id,
        queryClient,
        commentId: inReplyTo?.commentId,
      });
    };

    const onSuccessMutation = async () => {
      setFleetingComment({ displayed: false, inReplyTo: { commentId: undefined } });
      await invalidateCache();
    };

    const useReplyCommentMutation = (inReplyToArg: {
      commentId: string;
      parentAuthorId: string;
      name: string;
    }) =>
      replyCommentMutation.mutate(
        {
          input: {
            newsId: news.id,
            content: content,
          },
          replyToCommentInput: {
            commentId: inReplyToArg.commentId,
          },
        },
        {
          onSuccess: onSuccessMutation,
        },
      );

    const useCommentNewsMutation = () =>
      commentNewsMutation.mutate(
        {
          input: {
            newsId: news.id,
            content: content,
          },
        },
        {
          onSuccess: onSuccessMutation,
        },
      );

    if (inReplyTo) {
      setFleetingComment({ displayed: true, inReplyTo: { commentId: inReplyTo.commentId } });
      addFleetingReplyToComment(inReplyTo.commentId, sender, content, setComments, comments);
      useReplyCommentMutation(inReplyTo);
      setInReplyTo(null);
    } else {
      setFleetingComment({ displayed: true, inReplyTo: { commentId: undefined } });
      addFleetingCommentToComments(sender, content, setComments, comments);
      useCommentNewsMutation();
    }
  };
