import * as ImagePicker from 'expo-image-picker';
import * as Yup from 'yup';
import { Log } from '../../../utils/Log';

import {
  Alert,
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {
  Appbar,
  Button,
  Divider,
  Headline,
  HelperText,
  Portal,
  Snackbar,
  Text,
} from 'react-native-paper';
import {
  GetUserProfileQuery,
  LoginAttribute,
  useGetUserProfileQuery,
  useSendLoginAttributeVerificationCodeMutation,
  useUpdateUserProfileV2Mutation as useUpdateUserProfileMutation,
} from '../../../../graphql/operations';
import React, { useState } from 'react';
import { getFullName, getInitials, updateProfileValidationSchema } from '../utils';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { Formik } from 'formik';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { StackScreenProps } from '@react-navigation/stack';
import { isObjectWithProperty } from '../../../utils/typeUtils';
import { themeProp } from '../../../types/main';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useStore } from '../../../stores/store';
import { useQueryClient } from '@tanstack/react-query';
import { useAppTheme } from '../../../styles/theme';
import { ProfileItem } from '../components/ProfileItem';
import { ProfileInput } from '../components/ProfileInput';
import { compressImage } from '../../../utils/compressImage';
import { uploadBase64File, uploadFile } from '../../../components/Common/FileUpload/api';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { DiscardDialog } from '../../../components/Dialog/DiscardDialog';
import { AppBar } from '../../../components/AppBar/AppBar';
import { AlertDialog } from '../../../components/Dialog/AlertDialog';

type ProfileEditProps = StackScreenProps<AppNavigatorParamList, 'ProfileEdit'>;

export const ProfileEdit = (props: ProfileEditProps) => {
  const queryClient = useQueryClient();
  const userProfile = useStore(s => s.userProfile)!;
  const updateProfile = useUpdateUserProfileMutation();
  const newUseUpdateUserProfile = useUpdateUserProfileMutation();

  const theme = useAppTheme();
  const styles = createStyles(theme);

  const [infoVisible, setInfoVisible] = useState(false);

  const [tooltipModal, setTooltipModal] = useState<null | string>(null);

  const onDismissSnackBar = () => {
    updateProfile.reset();
    newUseUpdateUserProfile.reset();
  };

  const { navigation } = props;
  const { showActionSheetWithOptions } = useActionSheet();

  const updateMyProfile = (values: Yup.InferType<typeof updateProfileSchema>) => {
    if (userProfile.user?.cognitoUsername) {
      updateProfile.mutate(
        {
          input: {
            ...values,
            userId: userProfile.userId,
            user: {
              ...values.user,
              cognitoUsername: userProfile.user.cognitoUsername,
            },
          },
        },
        {
          onError: err => {
            Log.error(JSON.stringify(err, null, 4));
            Alert.alert('Es tut uns leid, es ist ein Fehler aufgetreten.');
          },
          onSuccess: async () => {
            await useStore.getState().refresh();
            navigation.goBack();
          },
        },
      );
    }
  };

  const handleVerify = async (attribute: LoginAttribute) => {
    if (attribute === LoginAttribute.Email && !userProfile.user?.loginEmail) {
      return navigation.navigate('VerifyAttribute', { type: 'email' });
    }
    if (attribute === LoginAttribute.PhoneNumber && !userProfile.user?.loginPhoneNumber) {
      return navigation.navigate('VerifyAttribute', { type: 'phone_number' });
    }
    try {
      await useSendLoginAttributeVerificationCodeMutation.fetcher({
        input: {
          attributeName: attribute,
        },
      })();

      navigation.navigate('ConfirmationCode', {
        type: attribute === LoginAttribute.Email ? 'email' : 'phone_number',
        username: '',
      });
    } catch (err) {
      if (
        isObjectWithProperty(err, 'message') &&
        typeof err.message === 'string' &&
        err.message.includes('Attempt limit exceeded, please try after some time')
      ) {
        Alert.alert('Zu viele Versuche', 'Bitte versuchen Sie es in ein paar Minuten erneut.', [
          { text: 'Ok', onPress: () => navigation.pop(navigation.getState().index) },
        ]);
        Log.error(err.message);
        return;
      }
      Log.error(err);
      Alert.alert('Beim Bestätigen ist ein unbekannter Fehler aufgetreten.');
    }
  };

  const handleCameraPhoto = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      Alert.alert('Diese App benötigt Kamera-Berechtigungen um ein Foto aufzunehmen.');
    } else {
      await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [1, 1],
      }).then(handleResult);
    }
  };

  const handleImagePick = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      Alert.alert('Diese App benötigt Kamera-Berechtigungen um ein Foto aufzunehmen.');
    } else {
      await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.4,
      }).then(handleResult);
    }
  };

  const invalidateCache = async () => {
    await queryClient.invalidateQueries<GetUserProfileQuery>(
      useGetUserProfileQuery.getKey({ userId: userProfile.userId }),
    );
  };

  const handleResult = async (result: ImagePicker.ImagePickerResult) => {
    if (result.canceled) {
      return;
    }
    const compressedImage = await compressImage(result.assets[0], { compact: true });
    const uploadMethod = Platform.OS === 'web' ? uploadBase64File : uploadFile;
    try {
      const res = await uploadMethod({
        fileUri: compressedImage.uri,
        uploadType: 'profilePicture',
      });
      updateProfile.mutate(
        {
          input: {
            userId: userProfile.userId,
            picture: 'unusedForNow',
            pictureOTC: res.newFileNames[0][1],
          },
        },
        {
          onError: error => {
            Log.error(error, { message: 'Error updating profile with new profile picture' });
            Alert.alert('Beim Hochladen des Profilbildes ist leider ein Fehler aufgetreten');
          },
          onSuccess: async () => {
            await useStore.getState().refresh();
            await invalidateCache();
          },
        },
      );
    } catch (error) {
      Log.error(error, { message: 'Error uploading profile picture' });
      Alert.alert('Beim Hochladen des Profilbildes ist leider ein Fehler aufgetreten');
    }
  };

  const updateProfileSchema = Yup.object(updateProfileValidationSchema);

  const initialValues: Yup.InferType<typeof updateProfileSchema> = {
    profileTextAbout: userProfile.profileTextAbout ?? undefined,
    profileTextInterest: userProfile.profileTextInterest ?? undefined,
    profileTextLanguages: userProfile.profileTextLanguages ?? undefined,
    businessPhone: userProfile.businessPhone ?? undefined,
    businessEmail: userProfile.businessEmail ?? undefined,
    user: {
      loginEmail: userProfile.user?.loginEmail ?? undefined,
      emailVerified: userProfile.user?.emailVerified ?? false,
      loginPhoneNumber: userProfile.user?.loginPhoneNumber ?? undefined,
      phoneNumberVerified: userProfile.user?.phoneNumberVerified ?? false,
    },
  };

  // There seems to be a bug in the ReactNative Keyboard functionality that prevents Keyboard.dismiss being called before or after the action sheet is openend. This is a workaround. See https://github.com/wix/react-native-navigation/issues/2318
  const dismissKeyBoardForActionSheet = () => {
    return new Promise(resolve => {
      Keyboard.dismiss();
      setTimeout(() => {
        resolve(null);
      }, 5);
    });
  };

  const openProfilePictureActionSheet = async () => {
    await dismissKeyBoardForActionSheet();
    showActionSheetWithOptions(
      {
        options: ['Aus Galerie', 'Kamera', 'Abbrechen'],
        cancelButtonIndex: 2,
        textStyle: { color: theme.colors.onSurface },
        containerStyle: { backgroundColor: theme.colors.background },
        icons: [
          <Icon name="image-outline" size={25} key="0" color={theme.colors.onSurface} />,
          <Icon name="camera" size={25} key="1" color={theme.colors.onSurface} />,
          <Icon name="delete-outline" size={25} key="2" color={theme.colors.onSurface} />,
        ],
      },
      async buttonIndex => {
        if (buttonIndex === 0) {
          await handleImagePick();
        } else if (buttonIndex === 1) {
          await handleCameraPhoto();
        }
      },
    );
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <AppBar
            title="Profil bearbeiten"
            renderActionsLeftOfTitle={() => (
              <Appbar.BackAction onPress={() => navigation.goBack()} />
            )}
          />
          <View style={styles.container}>
            <Portal>
              <LoadingBlocker
                visible={updateProfile.isLoading}
                spinnerColor={theme.customColors.accent}
              >
                <Headline
                  style={{
                    color: '#fff',
                  }}
                >
                  Einen Moment bitte.
                </Headline>
              </LoadingBlocker>
            </Portal>
            <KeyboardAwareScrollView
              extraHeight={137}
              style={styles.wrapper}
              keyboardShouldPersistTaps="handled"
            >
              <TouchableWithoutFeedback
                onPress={Platform.OS === 'web' ? () => {} : Keyboard.dismiss}
              >
                <Formik
                  onSubmit={updateMyProfile}
                  validationSchema={updateProfileSchema}
                  initialValues={initialValues}
                  enableReinitialize={true}
                >
                  {({
                    resetForm,
                    errors,
                    values,
                    handleChange,
                    isSubmitting,
                    isValid,
                    handleSubmit,
                  }) => (
                    <>
                      <DiscardDialog
                        title="Änderungen verwerfen?"
                        content="Alle nicht gespeicherten Änderungen gehen verloren."
                        isOpen={infoVisible}
                        onDiscard={() => {
                          resetForm();
                          setInfoVisible(false);
                        }}
                        onCancel={() => setInfoVisible(false)}
                      />
                      <View
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <View style={{ alignSelf: 'center', alignItems: 'center' }}>
                          <AvatarDisplay
                            size={130}
                            avatar={{
                              otcPath: userProfile.pictureOTC ?? undefined,
                              initials: getInitials(userProfile),
                            }}
                            type={UploadType.ProfilePicture}
                          />
                          <Button
                            uppercase={false}
                            mode="text"
                            style={styles.textButton}
                            onPress={() => onOnline(() => openProfilePictureActionSheet())}
                            labelStyle={styles.pictureChangeButton}
                          >
                            {userProfile.pictureOTC ? 'Profilbild ändern' : 'Profilbild hochladen'}
                          </Button>
                          <Text style={styles.name}>{getFullName(userProfile)}</Text>
                          {userProfile.employeeFunction ? (
                            <Text style={styles.description}>{userProfile.employeeFunction}</Text>
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                      <ProfileItem
                        title="Über mich"
                        description="Dieser Text wird den anderen Mitarbeitern auf Ihrem Profil angezeigt."
                        error={errors.profileTextAbout}
                        maxChars={800}
                        currentChars={values.profileTextAbout?.length ?? 0}
                        onOnline={onOnline}
                      >
                        <ProfileInput
                          value={values.profileTextAbout}
                          onChangeText={handleChange('profileTextAbout')}
                          error={!!errors.profileTextAbout}
                          numberOfLines={6}
                          multiline
                          placeholder="..."
                          onFocus={() => onOnline(() => {})}
                          theme={theme}
                        />
                      </ProfileItem>
                      <ProfileItem
                        title="Meine Interessen"
                        description="Dieser Text wird den anderen Mitarbeitern auf Ihrem Profil angezeigt."
                        error={errors.profileTextInterest}
                        maxChars={250}
                        currentChars={values.profileTextInterest?.length ?? 0}
                        onOnline={onOnline}
                      >
                        <ProfileInput
                          value={values.profileTextInterest}
                          onChangeText={handleChange('profileTextInterest')}
                          error={!!errors.profileTextInterest}
                          multiline
                          placeholder="Beispiel: Sport, Musik, ..."
                          onFocus={() => onOnline(() => {})}
                          theme={theme}
                        />
                      </ProfileItem>

                      <ProfileItem
                        title="Meine Sprachen"
                        description="Dieser Text wird den anderen Mitarbeitern auf Ihrem Profil angezeigt."
                        error={errors.profileTextLanguages}
                        maxChars={100}
                        currentChars={values.profileTextLanguages?.length ?? 0}
                        onOnline={onOnline}
                      >
                        <ProfileInput
                          value={values.profileTextLanguages}
                          onChangeText={handleChange('profileTextLanguages')}
                          error={!!errors.profileTextLanguages}
                          multiline
                          placeholder="Beispiel: Deutsch, Kroatisch, ..."
                          onFocus={() => onOnline(() => {})}
                          theme={theme}
                        />
                      </ProfileItem>

                      <ProfileItem
                        title="Persönliche Login-Mobil-Telefonnummer"
                        description="Die Telefonnummer (mobil) wird auf Ihrem Profil angezeigt und kann zur Anmeldung verwendet werden."
                        error={errors.user?.loginPhoneNumber}
                        disabled={!values.user?.loginPhoneNumber}
                        openTooltip={() => {
                          Keyboard.dismiss();
                          setTooltipModal(
                            `Ihre persönliche Telefonnummer wird standardmäßig nicht öffentlich angezeigt. Sie können optional Ihre Telefonnummer Ihren Kollegen auf Ihrem Profil anzeigen. Dazu müssen Sie die Funktion jedoch aktivieren.`,
                          );
                        }}
                        onOnline={onOnline}
                      >
                        {values.user?.loginPhoneNumber ? (
                          <>
                            <ProfileInput
                              value={values.user?.loginPhoneNumber}
                              onChangeText={handleChange('user.loginPhoneNumber')}
                              error={!!errors.user?.loginPhoneNumber}
                              disabled={true}
                              theme={theme}
                            />
                            <Divider
                              style={{
                                height: 1.5,
                                backgroundColor: theme.customColors.borders,
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {values.user.loginPhoneNumber && !values.user?.phoneNumberVerified ? (
                          <HelperText type="error" visible={true} style={styles.errorText}>
                            Ihre Telefonnummer ist noch nicht bestätigt. Um sich mit Ihrer
                            Telefonnummer einloggen zu können, muss diese bestätigt sein.
                          </HelperText>
                        ) : (
                          <></>
                        )}
                        {!values.user?.loginPhoneNumber || !values.user?.phoneNumberVerified ? (
                          <Button
                            mode="outlined"
                            uppercase={false}
                            style={styles.inlineButton}
                            onPress={() =>
                              onOnline(async () => await handleVerify(LoginAttribute.PhoneNumber))
                            }
                          >
                            Telefonnummer{' '}
                            {values.user?.loginPhoneNumber ? 'bestätigen' : 'hinterlegen'}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </ProfileItem>
                      <ProfileItem
                        title="Persönliche Login-E-Mail-Adresse"
                        description="Diese E-Mail Adresse dient ausschließlich zum Kontakt mit anderen Mitarbeitern."
                        error={errors.user?.loginEmail}
                        disabled={!values.user?.loginEmail}
                        openTooltip={() => {
                          Keyboard.dismiss();
                          setTooltipModal(
                            `Ihre persönliche E-Mail-Adresse wird standardmäßig nicht öffentlich angezeigt. Sie können optional Ihre E-Mail-Adresse Ihren Kollegen auf Ihrem Profil anzeigen. Dazu müssen Sie die Funktion jedoch aktivieren.`,
                          );
                        }}
                        onOnline={onOnline}
                      >
                        {values.user?.loginEmail ? (
                          <>
                            <ProfileInput
                              error={!!errors.user?.loginEmail}
                              value={values.user?.loginEmail}
                              onChangeText={handleChange('user.loginEmail')}
                              disabled={true}
                              theme={theme}
                            />
                            <Divider
                              style={{
                                height: 1.5,
                                backgroundColor: theme.customColors.borders,
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {values.user?.loginEmail && !values.user?.emailVerified ? (
                          <HelperText type="error" visible={true} style={styles.errorText}>
                            Ihre E-Mail-Adresse ist noch nicht bestätigt. Um sich mit Ihrer
                            E-Mail-Adresse einloggen zu können, muss diese bestätigt sein.
                          </HelperText>
                        ) : (
                          <></>
                        )}
                        {!values.user?.loginEmail || !values.user?.emailVerified ? (
                          <Button
                            mode="outlined"
                            uppercase={false}
                            style={styles.inlineButton}
                            onPress={() =>
                              onOnline(async () => await handleVerify(LoginAttribute.Email))
                            }
                          >
                            E-Mail {values.user?.loginEmail ? 'bestätigen' : 'hinterlegen'}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </ProfileItem>
                      <ProfileItem
                        title="Geschäftliche Telefonnummer"
                        description="Diese Telefonnummer dient ausschließlich zum Kontakt mit anderen Mitarbeitern."
                        error={errors.businessPhone}
                        onOnline={onOnline}
                      >
                        <ProfileInput
                          value={values.businessPhone}
                          onChangeText={handleChange('businessPhone')}
                          error={!!errors.businessPhone}
                          onFocus={() => onOnline(() => {})}
                          theme={theme}
                        />
                      </ProfileItem>

                      <ProfileItem
                        title="Geschäftliche E-Mail Adresse"
                        description="Diese E-Mail Adresse dient ausschließlich zum Kontakt mit anderen Mitarbeitern."
                        error={errors.businessEmail}
                        onOnline={onOnline}
                      >
                        <ProfileInput
                          error={!!errors.businessEmail}
                          value={values.businessEmail}
                          onChangeText={handleChange('businessEmail')}
                          onFocus={() => onOnline(() => {})}
                          theme={theme}
                        />
                      </ProfileItem>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginBottom: 200,
                        }}
                      >
                        <Button
                          uppercase={false}
                          disabled={isSubmitting}
                          style={styles.button}
                          mode="outlined"
                          onPress={() => {
                            Keyboard.dismiss();
                            setInfoVisible(true);
                          }}
                        >
                          Abbruch
                        </Button>
                        <PrimaryButton
                          buttonProps={{ loading: isSubmitting }}
                          disabled={isSubmitting || !isValid}
                          onPress={() => {
                            Keyboard.dismiss();
                            onOnline(handleSubmit);
                          }}
                          text="Speichern"
                          buttonStyle={styles.button}
                        />
                      </View>
                    </>
                  )}
                </Formik>
              </TouchableWithoutFeedback>
              <AlertDialog
                title="Hilfe"
                content={tooltipModal ?? ''}
                isOpen={!!tooltipModal}
                onDismiss={() => setTooltipModal(null)}
              />
            </KeyboardAwareScrollView>
            <Snackbar visible={updateProfile.isSuccess} onDismiss={onDismissSnackBar}>
              Profil erfolgreich aktualisiert.
            </Snackbar>
          </View>
        </>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.background,
    },
    wrapper: {
      width: 900,
      maxWidth: '99%',
      paddingHorizontal: 16,
      paddingVertical: 20,
      marginBottom: 20,
      alignSelf: 'center',
    },
    row: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    flexField: {
      flex: 1,
    },
    headline: {
      alignSelf: 'flex-start',
      fontSize: 32,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    titleDescription: {
      fontSize: 14,
      marginTop: 6,
      color: theme.colors.onSurface,
    },
    error: {
      flex: 1,
      fontSize: 14,
      marginTop: 6,
      color: theme.customColors.error,
    },
    itemTitle: {
      fontSize: 14,
      color: theme.customColors.textGray,
      marginRight: 12,
    },
    button: {
      borderRadius: 5,
      flex: 1,
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center',
    },
    inlineButton: {
      marginRight: 20,
      borderRadius: 5,
    },
    errorText: { marginLeft: -12, marginTop: -4 },
    name: {
      marginTop: 10,
      fontWeight: '500',
      fontSize: 20,
    },
    description: {
      color: theme.customColors.textDark,
      fontSize: 14,
      marginBottom: 27,
      marginHorizontal: 20,
    },
    textButton: {
      alignSelf: 'center',
      marginTop: 6,
      borderRadius: 5,
    },
    pictureChangeButton: {
      fontSize: 14,
    },
  });
