export enum ExceptionCode {
  UnknownException = 'UnknownException',
  AliasExistsException = 'AliasExistsException',
  CodeDeliveryFailureException = 'CodeDeliveryFailureException',
  CodeMismatchException = 'CodeMismatchException',
  ConcurrentModificationException = 'ConcurrentModificationException',
  DuplicateProviderException = 'DuplicateProviderException',
  EnableSoftwareTokenMFAException = 'EnableSoftwareTokenMFAException',
  ExpiredCodeException = 'ExpiredCodeException',
  GroupExistsException = 'GroupExistsException',
  InternalErrorException = 'InternalErrorException',
  InvalidEmailRoleAccessPolicyException = 'InvalidEmailRoleAccessPolicyException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidOAuthFlowException = 'InvalidOAuthFlowException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  InvalidSmsRoleAccessPolicyException = 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException = 'InvalidSmsRoleTrustRelationshipException',
  InvalidUserPoolConfigurationException = 'InvalidUserPoolConfigurationException',
  LimitExceededException = 'LimitExceededException',
  MFAMethodNotFoundException = 'MFAMethodNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  PreconditionNotMetException = 'PreconditionNotMetException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  ScopeDoesNotExistException = 'ScopeDoesNotExistException',
  SoftwareTokenMFANotFoundException = 'SoftwareTokenMFANotFoundException',
  TooManyFailedAttemptsException = 'TooManyFailedAttemptsException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnauthorizedException = 'UnauthorizedException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UnsupportedIdentityProviderException = 'UnsupportedIdentityProviderException',
  UnsupportedOperationException = 'UnsupportedOperationException',
  UnsupportedTokenTypeException = 'UnsupportedTokenTypeException',
  UnsupportedUserStateException = 'UnsupportedUserStateException',
  UserImportInProgressException = 'UserImportInProgressException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
  UserPoolAddOnNotEnabledException = 'UserPoolAddOnNotEnabledException',
  UserPoolTaggingException = 'UserPoolTaggingException',
  UsernameExistsException = 'UsernameExistsException',
  RequestFailedException = 'Network request failed',
  FileSizeIsOverLimitException = 'FileSizeIsOverLimitException',
  WhiteLabelAppExistsForTenant = 'WhiteLabelAppExistsForTenant',
}
