import { Chip } from 'react-native-paper';

import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../styles/theme';

type NewsTagsProps = {
  tags?: (string | null)[] | null;
  onTagPress?(tag: string): void;
};

export const NewsTags = memo((props: NewsTagsProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (!props.tags) {
    return <></>;
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal: 10,
        marginTop: 2,
      }}
    >
      {Array.isArray(props.tags) &&
        (props.tags.filter(tag => !!tag) as string[]).map(tag => (
          <Chip
            key={tag}
            style={styles.chip}
            onPress={() => props.onTagPress && props.onTagPress(tag)}
          >
            {tag}
          </Chip>
        ))}
    </View>
  );
});

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    chip: {
      marginHorizontal: 3,
      marginTop: 3,
      backgroundColor: theme.customColors.tagsBackgroundColor,
      borderRadius: 6,
    },
  });
