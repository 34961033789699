import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import Animated, { FadeInDown, FadeOutDown } from 'react-native-reanimated';

export interface ChatQuoteBoxPropTypes {
  children: React.ReactNode;
  onCancel?: () => void;
  isInChatToolbar?: boolean;
}

export const ChatQuoteBox = memo(
  ({ children, onCancel, isInChatToolbar }: ChatQuoteBoxPropTypes) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <Animated.View
        style={isInChatToolbar ? styles.containerInChatToolbar : styles.container}
        entering={isInChatToolbar ? FadeInDown : undefined}
        exiting={isInChatToolbar ? FadeOutDown : undefined}
      >
        <View style={isInChatToolbar ? styles.contentInChatToolbar : styles.content}>
          {children}
        </View>
        {!!onCancel && isInChatToolbar && (
          <IconButton
            style={styles.closeIcon}
            icon="close-circle"
            iconColor={theme.customColors.icon}
            size={20}
            onPress={onCancel}
          />
        )}
      </Animated.View>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 6,
    },
    containerInChatToolbar: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 2,
      backgroundColor: 'transparent',
    },
    content: {
      flexShrink: 0,
      flexGrow: 1,
      borderLeftColor: theme.colors.primary,
      borderLeftWidth: 10,
      borderRadius: 5,
      backgroundColor: theme.customColors.quoteBackground,
      padding: 5,
    },
    contentInChatToolbar: {
      borderLeftColor: theme.colors.primary,
      borderLeftWidth: 10,
      borderRadius: 5,
      backgroundColor: theme.customColors.chatBubbleBackground,
      padding: 5,
      width: '100%',
    },
    closeIcon: { position: 'absolute', right: 0 },
  });
