import React from 'react';
import { ReloginCheckInbox } from '../../screens/ReloginCheckInbox';
import { ReloginConfirmationCode } from '../../screens/ReloginConfirmationCode';
import { ReloginNewPassword } from '../../screens/ReloginNewPassword';
import { ReloginScreen } from '../../screens/ReloginScreen/ReloginScreen';
import { useAppTheme } from '../../styles/theme';
import { AppStack } from '../AppNavigator';

export const ReloginNavigator = () => {
  const theme = useAppTheme();
  return (
    <AppStack.Navigator
      initialRouteName={'Relogin'}
      screenOptions={{
        headerMode: 'screen',
        headerStyle: {
          backgroundColor: theme.customColors.background3,
          borderBottomColor: theme.customColors.background3,
        },
        headerTintColor: theme.customColors.text,
      }}
    >
      <AppStack.Screen
        name="Relogin"
        component={ReloginScreen}
        options={{
          headerShown: true,
          headerLeft: () => null,
          title: 'Sicherheitsüberprüfung',
        }}
      />
      <AppStack.Screen
        name="ReloginNewPassword"
        component={ReloginNewPassword}
        options={{
          headerShown: true,
          headerBackTitle: 'Zurück',
          title: '',
        }}
      />
      <AppStack.Screen
        name="ReloginConfirmationCode"
        component={ReloginConfirmationCode}
        options={{
          headerShown: true,
          headerBackTitle: 'Zurück',
          title: '',
        }}
      />
      <AppStack.Screen
        name="ReloginCheckInbox"
        component={ReloginCheckInbox}
        options={{
          headerShown: true,
          headerBackTitle: 'Zurück',
          title: '',
        }}
      />
    </AppStack.Navigator>
  );
};
