import React, { memo, useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Portal, Modal, Text, IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { androidStoreUrl, iosStoreUrl } from '../../../../app.config';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { PrimaryButton } from '../../PrimaryButton';

type UnavailableFeatureModalPropTypes = {
  text: {
    description: string;
    button?: string;
  };
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
  includeAppStoreLinks?: boolean;
  displayButton?: boolean;
  onButtonPress?: () => void;
};

export const UnavailableFeatureModal = memo(
  ({
    text,
    icon,
    visible,
    setVisible,
    includeAppStoreLinks,
    displayButton,
    onButtonPress,
  }: UnavailableFeatureModalPropTypes) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyle(theme), [theme]);

    return (
      <Portal>
        <Modal visible={visible} dismissable={false}>
          <View style={styles.container}>
            <View style={styles.inner}>
              <TouchableOpacity
                style={styles.closeModalIconContainer}
                onPress={() => setVisible(!visible)}
              >
                <IconButton
                  style={styles.closeModalIcon}
                  size={14}
                  icon="close"
                  iconColor="white"
                  onPress={() => setVisible(!visible)}
                />
              </TouchableOpacity>
              <MaterialCommunityIcons
                name={icon}
                style={styles.icon}
                size={40}
                color={theme.customColors.gray50}
              />
              <View style={styles.textContainer}>
                <Text style={styles.text}>{text.description}</Text>
              </View>
              {!!displayButton && !includeAppStoreLinks && (
                <PrimaryButton
                  text={text.button ?? 'OK'}
                  onPress={() => {
                    if (onButtonPress) {
                      onButtonPress();
                    }
                    setVisible(!visible);
                  }}
                />
              )}
              {!!includeAppStoreLinks && (
                <View style={styles.appStoreIconContainer}>
                  <IconButton
                    icon="apple"
                    iconColor="white"
                    onPress={() => {
                      window.open(iosStoreUrl);
                    }}
                    style={styles.appleStoreButton}
                  />
                  <IconButton
                    icon="android"
                    iconColor="white"
                    onPress={() => {
                      window.open(androidStoreUrl);
                    }}
                    style={styles.googleStoreButton}
                  />
                </View>
              )}
            </View>
          </View>
        </Modal>
      </Portal>
    );
  },
);

const createStyle = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    inner: {
      padding: 30,
      backgroundColor: theme.colors.background,
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 350,
    },
    icon: { marginBottom: 12 },
    textContainer: {
      flexWrap: 'wrap',
    },
    text: {
      maxWidth: 300,
      color: theme.customColors.textGray,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
    buttonContainer: {
      flex: 1,
      height: 50,
    },
    button: {
      backgroundColor: theme.customColors.pureWhite,
    },
    buttonText: {
      color: theme.customColors.primary,
    },
    appStoreIconContainer: { flex: 1, flexDirection: 'row' },
    appleStoreButton: {
      backgroundColor: theme.customColors.ios,
      width: 40,
      height: 40,
      borderRadius: 20,
      elevation: 6,
    },
    googleStoreButton: {
      backgroundColor: theme.customColors.android,
      width: 40,
      height: 40,
      borderRadius: 20,
      elevation: 6,
    },
    closeModalIcon: { width: 15, height: 15 },
    closeModalIconContainer: {
      alignSelf: 'flex-start',
      backgroundColor: theme.customColors.primary,
      borderRadius: 100,
      marginLeft: -12,
      marginTop: -12,
    },
  });
