import { useEffect, useState } from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  ChatRoomToReadQuery,
  GetUserProfileQuery,
  UnblockPrivateChatRoomMutation,
  useBlockPrivateChatRoomMutation,
  useChatRoomToReadQuery,
  UserProfile,
  useUnblockPrivateChatRoomMutation,
} from '../../../graphql/operations';
import { useStore } from '../../stores/store';
import { Log } from '../../utils/Log';

type UseBlockUserPropTypes = {
  queryClient: QueryClient;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser?: GetUserProfileQuery['userProfile'] | null;
  viewedUser?: GetUserProfileQuery['userProfile'];
};

export const useBlockUser = ({
  queryClient,
  currentUser,
  viewedUser,
  setMenuOpen,
}: UseBlockUserPropTypes) => {
  const blockUserMutation = useBlockPrivateChatRoomMutation();
  const unblockUserMutation = useUnblockPrivateChatRoomMutation();
  const didCurrentUserBlockViewedUser = Boolean(
    currentUser && viewedUser && currentUser.blockedUserIds?.includes(viewedUser.userId),
  );
  const [isUserBlockConfirmModalVisible, setIsUserBlockConfirmModalVisible] = useState(false);
  const [isUserBlockOperationMutationLoading, setIsUserBlockOperationMutationLoading] =
    useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(didCurrentUserBlockViewedUser);

  useEffect(() => {
    setIsUserBlocked(didCurrentUserBlockViewedUser);
  }, [currentUser, viewedUser]);

  const handleMutationLoading = () => {
    setIsUserBlockOperationMutationLoading(true);
    setTimeout(() => setIsUserBlockOperationMutationLoading(false), 10 * 1000);
  };

  const onPressBlockUser = () => {
    setMenuOpen(false);
    setIsUserBlockConfirmModalVisible(true);
  };

  const onSuccessUnblockUser = (data: UnblockPrivateChatRoomMutation) => {
    const chatRoomId = data.setPrivateChatRoomMemberStatus;
    setIsUserBlocked(false);
    if (currentUser && currentUser.blockedUserIds && viewedUser) {
      useStore.setState({
        userProfile: {
          ...currentUser,
          blockedUserIds: [...currentUser.blockedUserIds.filter(id => id !== viewedUser.userId)],
        },
      });
    }
    queryClient
      .invalidateQueries<ChatRoomToReadQuery>(
        useChatRoomToReadQuery.getKey({ chatRoomId: chatRoomId }),
      )
      .catch(() => {
        Log.warning(
          'an error occurred while invalidating chatRoomToReadQuery following unblocking of a user',
        );
      });
  };

  const onErrorUnblockUser = () => {
    Log.error('an error occurred while unblocking an user');
  };

  const onSettledUnblockUser = () => {
    setIsUserBlockOperationMutationLoading(false);
  };

  const onPressUnblockUser = () => {
    setMenuOpen(false);
    handleMutationLoading();
    if (viewedUser) {
      unblockUserMutation.mutate(
        {
          partnerUserProfileId: viewedUser.userId,
        },
        {
          onSuccess: onSuccessUnblockUser,
          onError: onErrorUnblockUser,
          onSettled: onSettledUnblockUser,
        },
      );
    }
  };

  const onPressBlock = () => {
    setMenuOpen(false);
    if (isUserBlocked) {
      onPressUnblockUser();
    } else {
      onPressBlockUser();
    }
  };

  const onErrorBlockUser = () => {
    Log.error('an error occurred while blocking an user');
  };

  const onSuccessBlockUser = () => {
    setIsUserBlocked(true);
    if (currentUser && currentUser.blockedUserIds && viewedUser) {
      useStore.setState({
        userProfile: {
          ...currentUser,
          blockedUserIds: [...currentUser.blockedUserIds, viewedUser.userId],
        },
      });
    }
  };

  const onSettledBlockUser = () => {
    setIsUserBlockOperationMutationLoading(false);
  };

  const onConfirmBlockUser = (userProfileId: UserProfile['userId']) => {
    setIsUserBlockConfirmModalVisible(false);
    handleMutationLoading();
    blockUserMutation.mutate(
      {
        partnerUserProfileId: userProfileId,
      },
      {
        onSuccess: onSuccessBlockUser,
        onError: onErrorBlockUser,
        onSettled: onSettledBlockUser,
      },
    );
  };

  const onCancelBlockUser = () => {
    setIsUserBlockConfirmModalVisible(false);
  };

  return {
    isUserBlocked: isUserBlocked,
    onPressBlock: onPressBlock,
    isUserBlockOperationMutationLoading: isUserBlockOperationMutationLoading,
    isUserBlockConfirmModalVisible: isUserBlockConfirmModalVisible,
    setIsUserBlockConfirmModalVisible,
    onCancelBlockUser: onCancelBlockUser,
    onConfirmBlockUser: onConfirmBlockUser,
  };
};
