import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Text, Chip } from 'react-native-paper';
import { RoundedSquareIcon } from '../Icon/RoundedSquareIcon';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { getLocales } from 'expo-localization';
import moment from 'moment';

type DateTimeSettingPropTypes = {
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  value?: Date;
  label: string;
  unspecifiedValueText?: string;
  setValue: (value: Date | undefined) => void;
};

export const DateTimeSetting = ({
  label,
  value,
  icon,
  unspecifiedValueText = 'Datum festlegen',
  setValue,
}: DateTimeSettingPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { languageTag: locale } = getLocales()[0];

  const [isDatePickerModalVisible, setDatePickerModalVisible] = useState(false);
  const [isTimePickerModalVisible, setTimePickerModalVisible] = useState(false);
  const [bufferDate, setBufferDate] = useState<Date | undefined>();

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.innerLeftContainer}>
          {icon && <RoundedSquareIcon icon={icon} marginRight={6} />}
          <Text style={styles.label} numberOfLines={1}>
            {label}
          </Text>
        </View>
        <View>
          <Chip
            compact
            style={styles.chip}
            textStyle={styles.chipText}
            onPress={() => setDatePickerModalVisible(true)}
            onClose={value ? () => setValue(undefined) : undefined}
          >
            {value ? moment(value).format('D MMMM YYYY, HH:mm') : unspecifiedValueText}
          </Chip>
        </View>
      </View>
      <DatePickerModal
        mode="single"
        visible={isDatePickerModalVisible}
        locale={locale}
        onConfirm={calendarDate => {
          setBufferDate(calendarDate.date as Date);
          setTimePickerModalVisible(true);
          setDatePickerModalVisible(false);
        }}
        onDismiss={() => setDatePickerModalVisible(false)}
        label={label}
        dateMode="start"
        animationType="slide"
        saveLabel="Uhrzeit wählen"
        validRange={{ startDate: new Date() }}
        date={value}
        disableSafeTop
        saveLabelDisabled={!bufferDate}
        onChange={params => setBufferDate(params.date)}
      />
      <TimePickerModal
        visible={isTimePickerModalVisible}
        locale={locale}
        onDismiss={() => setTimePickerModalVisible(false)}
        onConfirm={({ hours, minutes }) => {
          const startOfDay = moment(bufferDate).startOf('day');
          setValue(moment(startOfDay).add(hours, 'hours').add(minutes, 'minutes').toDate());
          setTimePickerModalVisible(false);
        }}
        label={label}
        cancelLabel="Abbrechen"
        confirmLabel="Speichern"
        animationType="slide"
        hours={moment(value ?? new Date()).get('hours')}
        minutes={moment(value ?? new Date()).get('minutes')}
      />
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      marginTop: 8,
      marginBottom: Platform.OS === 'ios' ? 8 : 0,
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    innerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    innerLeftContainer: {
      flex: 1,
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    label: { marginTop: 4, fontSize: 14, alignSelf: 'flex-start' },
    date: { fontSize: 14, alignSelf: 'flex-end', fontWeight: 'bold' },
    chip: {
      borderRadius: 100,
      backgroundColor: theme.customColors.background3,
    },
    chipText: { color: theme.customColors.text },
    closeIcon: { position: 'absolute', right: -38, bottom: -6 },
    dateTimePicker: { flexDirection: 'row', marginTop: -4 },
  });
