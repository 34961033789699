import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateMessageSubscription } from './useCreateMessageSubscription';
import { useSendChatRoomMessages } from './useSendChatRoomMessages';
import { useSendLink } from '../../../components/Common/Media/hooks/useSendLink';
import { useSendAttachments } from './useSendAttachments';

interface IUseRealTimeMessages {
  chatRoomId: string;
}

export const useRealTimeMessages = ({ chatRoomId }: IUseRealTimeMessages) => {
  const [sentOrReceivedMessageOnce, setSentOrReceivedMessageOnce] = useState(false);

  const resetSentOrReceivedMessageOnce = useCallback(() => {
    setSentOrReceivedMessageOnce(false);
  }, [chatRoomId]);

  useEffect(() => {
    resetSentOrReceivedMessageOnce();
    return () => resetSentOrReceivedMessageOnce();
  }, []);

  const onSentOrReceivedMessage = useCallback(
    () => setSentOrReceivedMessageOnce(true),
    [chatRoomId],
  );

  const { realTimeMessages, endPendingStateOfMessage, addToMessagesAsPending } =
    useCreateMessageSubscription({
      chatRoomId,
      onSentOrReceivedMessage,
    });

  const { sendChatRoomMessages, isSending: isSendingChatRoomMessages } = useSendChatRoomMessages({
    chatRoomId,
    addToMessagesAsPending,
    endPendingStateOfMessage,
  });

  const { sendLink, isSending: isSendingLinks } = useSendLink({
    chatRoomId,
    endPendingStateOfMessage,
    addToMessagesAsPending,
  });

  const { sendAttachments, isSending: isSendingAttachments } = useSendAttachments({
    chatRoomId,
    addToMessagesAsPending,
    endPendingStateOfMessage,
  });

  const isSending = useMemo(
    () => isSendingChatRoomMessages || isSendingLinks || isSendingAttachments,
    [isSendingChatRoomMessages, isSendingLinks, isSendingAttachments],
  );

  return {
    sentOrReceivedMessageOnce,
    realTimeMessages,
    isSending,
    onSentOrReceivedMessage,
    sendChatRoomMessages,
    sendLink,
    sendAttachments,
  };
};
