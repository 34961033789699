import { useTheme } from 'react-native-paper';
import { Comment, useDeleteCommentMutation } from '../../../graphql/operations';
import React, { memo, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { Text } from 'react-native-paper';
import { getFullName, getInitials } from '../../features/profiles/utils';
import { CommentReply } from './CommentReply';
import { NewsDetailScreenProps } from '../../features/news/types';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import moment from 'moment';
import { themeProp } from '../../types/main';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../stores/store';
import Animated, { SlideInLeft } from 'react-native-reanimated';
import { FleetingComment } from '../../features/news/hooks/useFleetingComment';
import { initUseHandleRemoveComment } from '../../features/news/hooks/initUseHandleRemoveComment';
import { UploadType } from '../Common/FileUpload/types';
import { AvatarDisplay } from '../../components/Common/AvatarDisplay/AvatarDisplay';
import { DiscardDialog } from '../Dialog/DiscardDialog';

type NewsCommentProps = {
  item: Comment;
  newsId: string;
  userGroupIds: string | string[];
  viewMore: boolean;
  shouldRenderReplies: boolean;
  highlighted?: boolean;
  onPress: () => void;
  onReply?: (arg0: { commentId: string; name: string; parentAuthorId: string }) => void;
  fleetingComment?: FleetingComment;
};

export const NewsComment = memo((props: NewsCommentProps) => {
  const { permissions, userProfile } = useStore(s => ({
    permissions: s.permissions,
    userProfile: s.userProfile,
  }));
  const queryClient = useQueryClient();
  const isNewsLegacy = !(Array.isArray(props.userGroupIds) && props.userGroupIds.length !== 0);
  const deleteCommentMutation = useDeleteCommentMutation();
  const theme = useTheme() as themeProp;
  const navigation = useNavigation<NewsDetailScreenProps['navigation']>();
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  const handleProfile = () => {
    if (props.item.author) {
      navigation.navigate('Profile', {
        profileId: props.item.author.userId,
        name: getFullName({
          firstname: props.item.author.firstname,
          lastname: props.item.author.lastname,
        }),
      });
    }
  };

  const handleRemoveComment = initUseHandleRemoveComment({
    deleteCommentMutation: deleteCommentMutation,
    queryClient: queryClient,
  });

  const handleDeleteComment = async () => {
    handleRemoveComment({
      commentId: props.item.id,
      newsId: props.newsId,
      userGroupIds: props.userGroupIds,
      isReply: false,
    });
    setInfoVisible(false);
  };

  const isCommentDeletable =
    props.item.content !== 'Kommentar gelöscht' ||
    permissions?.some(p => p.permissions.includes('users:create'));

  const isFleetingComment =
    !!props.fleetingComment?.displayed &&
    !props.fleetingComment.inReplyTo.commentId &&
    props.item.id.startsWith('fleeting');

  const onPressReply = isFleetingComment
    ? undefined
    : () =>
        props.onReply?.({
          commentId: props.item.id,
          parentAuthorId: props.item.author?.userId ?? '',
          name: props.item.author ? getFullName(props.item.author) : '',
        });

  const initOnPressDelete = (onOnline: (func: () => any) => void) =>
    isFleetingComment ? undefined : () => onOnline(() => setInfoVisible(true));

  if (!props.item) {
    return <></>;
  }

  return (
    <OfflineErrorModal>
      {onOnline => (
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-end',
            backgroundColor: props.highlighted ? theme.customColors.background3 : 'transparent',
            opacity: isFleetingComment ? 0.5 : 1,
          }}
        >
          <TouchableWithoutFeedback onPress={() => handleProfile()} disabled={isFleetingComment}>
            <AvatarDisplay
              size={50}
              avatar={{
                otcPath: props.item.author?.pictureOTC ?? undefined,
                initials: getInitials(props.item?.author ?? undefined),
              }}
              type={UploadType.ProfilePicture}
            />
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback
            onPress={() => (props.shouldRenderReplies ? {} : props.onPress())}
            disabled={isFleetingComment}
          >
            <View style={{ flex: 1, marginBottom: !props.viewMore ? 14 : 4, marginLeft: 10 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {props.item.author ? getFullName(props.item.author) : 'Ehemaliger Benutzer'}
                </Text>
                <Text style={{ color: theme.customColors.textGray, fontSize: 14 }}>
                  {formatTinyDate(props.item.createdAt!)}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    marginBottom: props.viewMore ? 8 : 4,
                    fontSize: 16,
                    fontStyle: !isCommentDeletable ? 'italic' : 'normal',
                  }}
                >
                  {props.item.content}
                </Text>
                <View style={{ flexDirection: 'row' }}>
                  {props.shouldRenderReplies && isCommentDeletable && (
                    <Text
                      style={{
                        color: theme.customColors.textGray,
                        fontWeight: 'bold',
                        marginRight: 20,
                        opacity: isFleetingComment ? 0 : 1,
                      }}
                      onPress={onPressReply}
                    >
                      Anworten
                    </Text>
                  )}
                  {props.shouldRenderReplies &&
                    permissions
                      ?.filter(p =>
                        isNewsLegacy
                          ? props.userGroupIds.includes(p.groupId)
                          : p.groupId === props.userGroupIds,
                      )
                      ?.map(
                        item =>
                          item.permissions.includes('users:create') ||
                          userProfile?.userId === props.item.author?.userId,
                      ) &&
                    isCommentDeletable && (
                      <Text
                        style={{
                          color: theme.customColors.textGray,
                          fontWeight: 'bold',
                          opacity: isFleetingComment ? 0 : 1,
                        }}
                        onPress={initOnPressDelete(onOnline)}
                      >
                        Löschen
                      </Text>
                    )}
                </View>
                {props.shouldRenderReplies &&
                  'replies' in props.item &&
                  props?.item?.replies &&
                  props.item.replies.length > 0 && (
                    <View
                      style={{
                        width: '100%',
                        marginTop: 6,
                      }}
                    >
                      {props.item.replies.map(replyItem => (
                        <Animated.View
                          key={replyItem.id}
                          entering={replyItem.id.startsWith('fleeting') ? SlideInLeft : undefined}
                          style={{ opacity: replyItem.id.startsWith('fleeting') ? 0.5 : 1 }}
                        >
                          <CommentReply
                            item={replyItem}
                            newsId={props.newsId}
                            userGroupIds={props.userGroupIds}
                            isFleetingComment={replyItem.id.startsWith('fleeting')}
                          />
                        </Animated.View>
                      ))}
                    </View>
                  )}
                {!!props.viewMore && (
                  <Text style={{ color: theme.customColors.textGray, marginBottom: 10 }}>
                    Mehr Kommentare ansehen
                  </Text>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
          <DiscardDialog
            title="Sind Sie sicher?"
            content="Möchten Sie wirklich den Kommentar löschen?"
            discardButtonLabel="Löschen"
            isOpen={!!infoVisible}
            onCancel={() => setInfoVisible(false)}
            onDiscard={() => handleDeleteComment()}
          />
        </View>
      )}
    </OfflineErrorModal>
  );
});

const formatTinyDate = (date: Date) => {
  const a = moment(date);
  const dayDiff = moment().diff(a, 'days');
  if (dayDiff === 0) {
    return a.format('LT');
  } else if (dayDiff <= 7) {
    return a.format('dd[.]');
  } else {
    return a.format('l');
  }
};
