import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Caption, Text } from 'react-native-paper';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useAppTheme } from '../../../styles/theme';
import { themeProp } from '../../../types/main';

type ProfileItemProps = {
  title: string;
  description: string;
  error?: string;
  multiline?: number;
  maxChars?: number;
  currentChars?: number;
  children: React.ReactNode;
  disabled?: boolean;
  openTooltip?: () => void;
  onOnline: (fn: () => void) => void;
};

export const ProfileItem = (itemProps: ProfileItemProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return (
    <View
      style={{
        marginTop: 6,
        marginBottom: 16,
        minWidth: 110,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={styles.itemTitle}>{itemProps.title}</Text>
        {typeof itemProps.openTooltip === 'function' && (
          <Icon
            style={{ margin: 0, alignSelf: 'flex-start' }}
            name="help-circle"
            color={theme.customColors.gray60}
            size={18}
            onPress={itemProps.openTooltip}
          />
        )}
        {itemProps.maxChars && (
          <Caption
            style={{
              justifyContent: 'center',
              flex: 1,
              alignSelf: 'flex-end',
              textAlign: 'right',
              color:
                itemProps?.currentChars && itemProps?.currentChars >= itemProps.maxChars
                  ? theme.customColors.error
                  : theme.customColors.textGray,
            }}
          >
            {itemProps.currentChars || 0} / {itemProps.maxChars} Zeichen
          </Caption>
        )}
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          {itemProps.children}
          {itemProps.error && <Text style={styles.error}>{itemProps.error}</Text>}
        </View>
      </View>
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    error: {
      flex: 1,
      fontSize: 14,
      marginTop: 6,
      color: theme.customColors.error,
    },
    itemTitle: {
      fontSize: 14,
      color: theme.customColors.textGray,
      marginRight: 12,
    },
  });
