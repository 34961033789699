import { PushNotificationStatus, useUpdateUserProfileMutation } from '../../../graphql/operations';
import { Log } from '../../utils/Log';

export const updateUserNotificationStatus = async (notificationStatus: boolean, userId: string) => {
  const notificationsEnabled = notificationStatus
    ? PushNotificationStatus.Enabled
    : PushNotificationStatus.Disabled;

  try {
    await useUpdateUserProfileMutation.fetcher({
      input: {
        userId: userId,
        notificationsEnabled: notificationsEnabled,
      },
    })();
  } catch (e) {
    Log.warning(e, {
      message: `an error occurred while updating notification status of the following user to the following value: ${userId} - ${notificationsEnabled}`,
    });
  }
};
