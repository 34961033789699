import * as Yup from 'yup';
import { Log } from '../../utils/Log';

import { Platform, StyleSheet, View, TouchableWithoutFeedback, Keyboard, Text } from 'react-native';
import { Button, Paragraph, Portal, TextInput, useTheme } from 'react-native-paper';
import React, { useState, useMemo, useReducer } from 'react';

import { ChangeTemporaryPasswordInput, UserType } from '../../../graphql/operations';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { LoginScreenProps } from '../../screens/Auth/LoginScreen';
import MailPopUp from '../Common/Modals/MailPopUp';
import { themeProp } from '../../types/main';
import { useFormik } from 'formik';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../../stores/store';
import { useCognitoException } from './hooks/useCognitoException';
import { userInputValidationSchema } from '../../utils/authUtils';
import { TemporaryPasswordChangeTestId, UserLogin } from '../../../test/testIds';
import { useFocusFields } from '../../hooks';
import { GenericPopUp } from '../../stories/Modal/GenericPopUp';
import { useQueryClient } from '@tanstack/react-query';
import { PrimaryButton } from '../PrimaryButton';
import { Constants } from '../../utils';

export const CredentialLogIn = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { collectFocusRef, focusNext } = useFocusFields();
  const theme = useTheme() as themeProp;

  const [loadingAuth, setLoadingAuth] = useState(false);
  const [checkMailVisible, setCheckMailVisible] = useState(false);

  const [authCase, setAuthCase] = useState<boolean | string>(false);

  const navigation = useNavigation<LoginScreenProps['navigation']>();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false);

  const [temporaryPassUser, setTemporaryPassUser] = useState<ChangeTemporaryPasswordInput>();

  const [newPassword, setNewPassword] = useState('');
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [passwordChangeErrorPopup, togglePasswordChangeErrorPopup] = useReducer(
    previous => !previous,
    false,
  );

  const styles = useMemo(() => createStyles(theme), [theme]);

  const { logout } = useStore(s => ({ logout: s.logout }));

  const passwordContainsWhitespace = (password: string) => {
    return password.includes(' ');
  };

  const openNewPasswordAlert = (error: Error) => {
    togglePasswordChangeErrorPopup();
    Log.error(new Error('user was not able to set a new password (from temporary password)'), {
      data: error,
    });
    setLoadingAuth(false);
  };

  const dismissNewPasswordAlert = () => {
    if (isSessionExpired) {
      setPasswordChangeVisible(false);
    }
    togglePasswordChangeErrorPopup();
    void logout({ queryClientToClearCacheOf: queryClient, chatStoreToClear: undefined });
  };

  const submitNewPassword = async () => {
    if (!temporaryPassUser?.session || !temporaryPassUser.username) {
      openNewPasswordAlert(new Error('No temporaryPassUser found in submitNewPassword'));
      return;
    }
    setLoadingAuth(true);
    try {
      await useStore.getState().changeTemporaryPassword({
        ...temporaryPassUser,
        newPassword: newPassword,
      });
    } catch (err) {
      const error = err as Error;
      if (error.message.includes(Constants.SESSION_EXPIRED_ERROR)) {
        setIsSessionExpired(true);
        setAuthCase(false);
      }
      openNewPasswordAlert(error);
    }
  };

  const validationSchema = Yup.object().shape({
    ...userInputValidationSchema,
    pass: Yup.string()
      .trim()
      .test(
        'pass',
        'Ihr Passwort muss mindestens 8 Zeichen lang sein',
        (val?: string) => !!val && val.length >= 8,
      )
      .required('Passwort erforderlich'),
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      pass: '',
    },
    onSubmit: async values => {
      if (authCase === 'newPassword') {
        setLoadingAuth(true);
        try {
          if (temporaryPassUser) {
            await useStore.getState().changeTemporaryPassword(temporaryPassUser);
          }
        } catch (err) {
          togglePasswordChangeErrorPopup();
          Log.error(
            new Error('user was not able to set a new password (from temporary password)'),
            {
              data: err,
            },
          );
        } finally {
          setLoadingAuth(false);
        }
      } else {
        setLoadingAuth(true);
        try {
          const status = await useStore.getState().login({
            username: values.user.toLowerCase().trim(),
            password: values.pass.trim(),
          });
          if (status) {
            setTemporaryPassUser({
              username: values.user.toLowerCase().trim(),
              userType: UserType.Employee,
              newPassword: values.pass.trim(),
              session: status.session,
            });
            /* @todo must be awaited */
            void formik.setFieldValue('user', values.user);
            formik.setSubmitting(false);
            /* @todo must be awaited */
            void formik.setFieldValue('pass', '');
            void formik.setFieldTouched('pass', false);
            setPasswordChangeVisible(true);
            setAuthCase('newPassword');
            setLoadingAuth(false);
            formik.setSubmitting(false);
          }
        } catch (err) {
          const error = err as Error;
          setLoadingAuth(false);
          formik.setSubmitting(false);
          try {
            await handleCognitoException(error, values);
          } catch (e) {
            Log.error(e);
            Log.error('cannot handle login exception');
          }
        }
      }
    },
    validationSchema: validationSchema,
  });

  const handleCognitoException = useCognitoException(formik, navigation);

  const isNewPasswordSubmitDisabled =
    newPassword?.length < 8 || passwordContainsWhitespace(newPassword?.trim());

  return (
    <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}>
      <View>
        <TextInput
          mode="flat"
          testID={UserLogin.usernameInput}
          style={styles.textInput}
          error={!!formik.touched.user && !!formik.errors.user}
          value={formik.values.user}
          autoCapitalize="none"
          autoCorrect={false}
          autoComplete="off"
          onChangeText={formik.handleChange('user')}
          onBlur={async () => await formik.setFieldTouched('user')}
          disabled={authCase === 'newPassword' || formik.isSubmitting}
          label="Benutzername, E-Mail oder Telefonnummer"
          returnKeyType="next"
          onSubmitEditing={() => focusNext('password')}
        />
        {formik.touched.user && formik.errors.user && (
          <Text style={styles.error}>{formik.errors.user}</Text>
        )}
        {authCase !== 'forgotPassword' && (
          <TextInput
            ref={collectFocusRef('password')}
            mode="flat"
            testID={UserLogin.passwordInput}
            secureTextEntry={Platform.OS === 'ios' ? true : !passwordShown}
            style={styles.textInput}
            error={!!formik.touched.pass && !!formik.errors.pass}
            value={formik.values.pass}
            autoCapitalize="none"
            autoCorrect={false}
            autoComplete="off"
            onChangeText={formik.handleChange('pass')}
            /* @todo must be awaited */
            onBlur={() => void formik.setFieldTouched('pass')}
            disabled={formik.isSubmitting}
            label={authCase === 'newPassword' ? 'Neues Passwort' : 'Passwort'}
            onSubmitEditing={() => formik.handleSubmit()}
            returnKeyType="send"
            right={Platform.select({
              android: (
                <TextInput.Icon
                  icon={() => (
                    <Icon
                      name={passwordShown ? 'eye-off-outline' : 'eye-outline'}
                      size={20}
                      onPress={() => setPasswordShown(!passwordShown)}
                    />
                  )}
                />
              ),
            })}
          />
        )}
        {formik.touched.pass && formik.errors.pass && (
          <Text style={styles.error}>{formik.errors.pass}</Text>
        )}
        {authCase === 'newPassword' && (
          <Text style={{ marginBottom: 12 }}>
            {authCase === 'newPassword'
              ? 'Bitte geben Sie ein neues Passwort ein. Das Passwort muss mindestens 8 Zeichen lang sein.'
              : 'Sie sollten eine E-Mail erhalten haben, klicken Sie auf den Link darin um Ihren Account zu aktivieren.'}
          </Text>
        )}
        <PrimaryButton
          buttonProps={{ testID: UserLogin.submitButton, loading: !!loadingAuth }}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          onPress={formik.handleSubmit}
          text={authCase === 'forgotPassword' ? 'Passwort zurücksetzen' : 'Anmelden'}
        />
        <Button
          uppercase={false}
          mode="contained"
          style={[styles.passwordResetButton, styles.customButton]}
          onPress={() => {
            navigation.navigate('ResetPassword', {
              username: formik?.values?.user || '',
            });
          }}
          textColor={theme.customColors.textWhite}
        >
          Passwort vergessen?
        </Button>
        <Button
          uppercase={false}
          mode="text"
          onPress={() => navigation.navigate('LoginHelpPage')}
          style={styles.customButton}
          icon={() => (
            <Icon
              name="help-circle"
              color={theme.customColors.primary}
              style={{ marginLeft: -8 }}
              size={16}
            />
          )}
          contentStyle={{ flexDirection: 'row-reverse' }}
        >
          <Text style={styles.helpButtonText}>Hilfe</Text>
        </Button>
        <Portal>
          {/* @todo Unused code? */}
          <MailPopUp visible={checkMailVisible} onDismiss={() => setCheckMailVisible(false)}>
            <Paragraph>
              Wir haben Ihnen einen Bestätigungslink an Ihre E-Mail Adresse gesendet. Klicken Sie
              auf den enthaltenen Link um Ihren Account freizuschalten!
            </Paragraph>
          </MailPopUp>
        </Portal>
        <Portal>
          <GenericPopUp
            headlineTitle="Passwort Änderung erforderlich"
            buttonTestId={TemporaryPasswordChangeTestId.confirmButton}
            visible={passwordChangeVisible}
            /* @todo must be awaited */
            onDismiss={() => void submitNewPassword()}
            primaryActionTitle="Bestätigen"
            isDisabled={isNewPasswordSubmitDisabled}
            notDismissable
          >
            <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}>
              <View style={{ width: '100%' }}>
                <Paragraph testID={TemporaryPasswordChangeTestId.introduction}>
                  Aus Sicherheitsgründen müssen Sie Ihr Passwort ändern. Wir bitten um Ihr
                  Verständnis.
                </Paragraph>
                <TextInput
                  testID={TemporaryPasswordChangeTestId.passwordInput}
                  style={{ marginTop: 10 }}
                  secureTextEntry
                  label="Neues Passwort"
                  onChangeText={(input: string) => setNewPassword(input)}
                  autoCapitalize="none"
                  autoCorrect={false}
                  onSubmitEditing={async () =>
                    !isNewPasswordSubmitDisabled && (await submitNewPassword())
                  }
                  returnKeyType="send"
                />
                <View style={{ height: 32 }}>
                  <Text
                    style={
                      isNewPasswordSubmitDisabled
                        ? { fontSize: 12, color: theme.customColors.error }
                        : { display: 'none' }
                    }
                  >
                    Ihr Passwort muss mindestens 8 Zeichen lang sein und darf keine Leerzeichen
                    enthalten
                  </Text>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </GenericPopUp>
          <GenericPopUp
            visible={passwordChangeErrorPopup}
            onDismiss={dismissNewPasswordAlert}
            headlineTitle="Fehler"
          >
            <Text style={styles.text}>
              {isSessionExpired
                ? 'Die Sizung ist abgelaufen. Bitte schließen Sie diesen Dialog und versuchen Sie erneut, sich einzuloggen und Ihr Passwort zu ändern'
                : 'Beim Setzen des neuen Passworts ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Administrator.'}
            </Text>
          </GenericPopUp>
        </Portal>
      </View>
    </TouchableWithoutFeedback>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    error: {
      color: theme.customColors.error,
      marginTop: 0,
    },
    textInput: {
      backgroundColor: theme.customColors.textInputBackground,
      marginBottom: 8,
      marginTop: 8,
    },
    helpButtonText: {
      color: theme.customColors.primary,
      fontSize: 16,
    },
    customButton: {
      borderRadius: 5,
      justifyContent: 'center',
      alignSelf: 'center',
      width: 200,
    },
    loginButton: {
      marginTop: 17,
      marginBottom: 4,
      height: 45,
    },
    passwordResetButton: {
      borderRadius: 5,
      marginTop: 10,
      backgroundColor: theme.customColors.primary,
      marginBottom: 4,
      height: 45,
    },
    text: {
      color: theme.customColors.textDark,
    },
  });
