import React, { useMemo } from 'react';
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native';
import { NoDataRefresh } from '../../../../stories/Loading/NoDataRefresh';
import { useAppTheme } from '../../../../styles/theme';
import { themeProp } from '../../../../types/main';
import { InquiryAppBar, InquiryListItem } from '../../components';
import { InquiryStatus, useListInquiriesQuery } from '../../../../../graphql/operations';
import { useRefreshControlWithTimeout } from '../../../../hooks/list/useRefreshControlWithTimeout';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../../router/AppNavigator';

export const ResolvedInquiriesScreen = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const inquiries = useListInquiriesQuery({ input: { status: InquiryStatus.Resolved } });
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => inquiries.refetch(),
    errorMessageToLog: `failed to refetch data for the resolved inquiries screen`,
  });

  const handleListItemNavigation = (id: string) => {
    navigation.navigate('InquiryDetails', { inquiryId: id });
  };

  return (
    <View style={styles.screenContainer}>
      <InquiryAppBar isForResolvedInquiries />
      <FlatList
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={() => void onRefresh()}
            tintColor={theme.customColors.refreshControlSpinner}
          />
        }
        data={inquiries.data?.listInquiries}
        renderItem={({ item }) => (
          <InquiryListItem
            id={item.id}
            title={item.title}
            onPress={handleListItemNavigation}
            hasUnread={item.hasUnread}
            createdAt={item.createdAt}
            relativeOnInquiry={item.relativeOnInquiry}
          />
        )}
        style={styles.flatlistContainer}
        keyExtractor={inquiry => inquiry.id}
        ListEmptyComponent={
          <>
            {!!inquiries.data && (
              <View style={{ marginTop: 30 }}>
                <NoDataRefresh
                  text="Keine geschlossenen Anfragen gefunden."
                  onPress={() => void inquiries.refetch()}
                  isLoading={inquiries.isRefetching}
                />
              </View>
            )}
          </>
        }
      />
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    screenContainer: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    flatlistContainer: {
      backgroundColor: theme.colors.background,
      paddingTop: 1,
    },
    listFooterContainer: {
      display: 'flex',
      marginBottom: 5,
      marginTop: 10,
    },
  });
