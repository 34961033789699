import React, { memo, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { FormReplyStatus as FormReplyStatusType } from '../../../../../graphql/operations';
import { FormStatusChipFactory } from '../FormStatusChipFactory/FormStatusChipFactory';

type FormStatusPropTypes = {
  status?: FormReplyStatusType;
  onPress?: () => void;
};

export const FormReplyStatus = memo(({ status, onPress }: FormStatusPropTypes) => {
  const styles = useMemo(() => createStyles(), []);

  return (
    <View style={styles.container}>
      <FormStatusChipFactory status={status} onPress={onPress} />
    </View>
  );
});

const createStyles = () =>
  StyleSheet.create({
    container: { justifyContent: 'center', alignItems: 'center', flex: 1 },
  });
