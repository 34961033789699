import { FileType } from '../components/FileGallery/types';

export const getFileTypeFromOtcPath = (otcPath: string) => {
  switch (otcPath.split('.').pop()?.toLowerCase()) {
    case 'jpg':
      return FileType.Image;
    case 'jpeg':
      return FileType.Image;
    case 'png':
      return FileType.Image;
    case 'pdf':
      return FileType.Document;
    case 'm4a':
      return FileType.Audio;
    case 'wav':
      return FileType.Audio;
    case 'mp4':
      return FileType.Video;
    case 'mpeg':
      return FileType.Video;
    default:
      throw new Error('file type could not be figured out from file extension');
  }
};
