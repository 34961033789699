import React, { useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';
import { getQuestionLabelSuffix } from '../../utils/getQuestionLabelSuffix';
import { FormQuestion as FormQuestionType } from '../../../../../graphql/operations';
import { AppTheme, useAppTheme } from '../../../../styles/theme';

type FormQuestionPropTypes = {
  question: FormQuestionType;
};

export const FormQuestion = ({ question }: FormQuestionPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Text style={styles.question}>
      {question.label}
      {getQuestionLabelSuffix(question.type, question.required)}
    </Text>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    question: {
      fontSize: 14,
      lineHeight: 18,
      marginRight: 12,
      color: theme.customColors.text,
    },
  });
