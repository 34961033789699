import { addNotificationResponseReceivedListener, NotificationResponse } from 'expo-notifications';
import { useCallback, useEffect, useRef } from 'react';
import { Linking, Platform } from 'react-native';
import { validateLinking } from './validateLinking';
import { Log } from '../../utils/Log';
import { scheme } from '../../../app.config';

export const MAX_RETRIES = 50;

export const useNotificationResponseListener = (isNavigationReady: boolean) => {
  const isNavigationReadyRef = useRef(isNavigationReady);
  isNavigationReadyRef.current = isNavigationReady;
  const retryCountRef = useRef(0);

  const repeatedlyCheckIfReady = useCallback(
    async (linking: string) => {
      if (retryCountRef.current >= MAX_RETRIES) {
        Log.error(
          'cannot proceed with open deep linking, navigation not ready, max retries reached',
          {
            data: { linking },
          },
        );
        return;
      }
      retryCountRef.current++;
      if (isNavigationReadyRef.current) {
        await Linking.openURL(linking);
      } else {
        Log.info('retrying');
        setTimeout(() => repeatedlyCheckIfReady(linking), 50);
      }
    },
    [isNavigationReady, isNavigationReadyRef, retryCountRef],
  );
  const notificationResponseListener = useCallback(
    async (response: NotificationResponse) => {
      try {
        const linking = response?.notification?.request?.content?.data?.linking?.toString();
        if (!linking) {
          throw new Error('invalid parsed url');
        }
        await validateLinking(linking, scheme);
        void repeatedlyCheckIfReady(linking);
      } catch (e) {
        Log.error(e, { message: 'Cannot respond to notification' });
      }
    },
    [isNavigationReady],
  );
  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }
    const subscription = addNotificationResponseReceivedListener(notificationResponseListener);
    return () => {
      subscription.remove();
    };
  }, []);
};
