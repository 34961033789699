import { StyleSheet, Text, View } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { themeProp } from '../../types/main';
import { useNavigation } from '@react-navigation/native';

export type ModernMenuItemType = {
  title: string;
  description?: string;
  icon: keyof typeof MaterialCommunityIcons.glyphMap | (() => void);
  route?: string;
  onPress?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  backgroundColor?: string;
};

type ModernMenuItemComponent = {
  item: ModernMenuItemType;
};

export const ModernMenuItem = (props: ModernMenuItemComponent) => {
  const theme = useTheme() as themeProp;

  const navigation = useNavigation<any>();

  const styles = StyleSheet.create({
    container: {
      paddingVertical: 12,
      marginTop: 12,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: props.item.backgroundColor ?? theme.customColors.pureWhite,
      borderRadius: theme.roundness,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      width: '100%',
      display: props.item.hidden ? 'none' : 'flex',
    },
    iconWrapper: {
      marginLeft: 10,
      marginRight: 14,
    },
    icon: {
      padding: 8,
      color: theme.customColors.primary,
    },
    textWrapper: {
      flex: 1,
      paddingRight: 8,
    },
    text: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.colors.onSurface,
    },
    description: {
      marginTop: 2,
      fontSize: 13,
      color: theme.customColors.gray40,
    },
  });

  return (
    <TouchableRipple
      onPress={
        props.item.route
          ? () => navigation.navigate(props?.item?.route || 'Home')
          : () => (props?.item?.onPress ? props.item.onPress() : {})
      }
      style={styles.container}
      rippleColor={theme.customColors.lightRipple}
    >
      <>
        <View style={styles.iconWrapper}>
          {typeof props.item.icon === 'string' ? (
            <MaterialCommunityIcons name={props.item.icon} size={30} style={styles.icon} />
          ) : (
            <>{props.item.icon()}</>
          )}
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.text}>{props.item.title}</Text>
          {props.item.description && (
            <Text style={styles.description}>{props.item.description}</Text>
          )}
        </View>
      </>
    </TouchableRipple>
  );
};
