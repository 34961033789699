import * as SecureStorage from 'expo-secure-store';
import { Log } from './Log';

import { ITenant } from '../types/tenancy-types';
import { TenantConfigurationQuery } from '../../graphql/operations';
import { compare } from 'compare-versions';
import { Platform } from 'react-native';

const getLocalStorageAdapter = () => {
  return {
    getItemAsync: (key: string) => Promise.resolve(localStorage.getItem(key)),
    setItemAsync: (key: string, value: string) => Promise.resolve(localStorage.setItem(key, value)),
    deleteItemAsync: (key: string) => Promise.resolve(localStorage.removeItem(key)),
  };
};

const Storage = Platform.OS === 'web' ? getLocalStorageAdapter() : SecureStorage;

export function storeTenant(value: TenantConfigurationQuery['tenantConfiguration']) {
  return Storage.setItemAsync('tenant', JSON.stringify(value));
}

export function retrieveTenant(): Promise<TenantConfigurationQuery['tenantConfiguration'] | null> {
  return Storage.getItemAsync('tenant').then(result => {
    if (result) {
      return JSON.parse(result);
    } else {
      return null;
    }
  });
}

export function resetTenant() {
  return Storage.deleteItemAsync('tenant');
}

/**
 * @deprecated
 * @param path
 * @param setSubmitting
 * @returns
 */
export const fetchTenant = (path: string, setSubmitting: (val: boolean) => void) => {
  Log.info(`fetching ${path}`);
  setSubmitting(true);
  return fetch(`https://tenant-api.luciapp.de/${path}`)
    .then(response => response.json())
    .then((data: Error | Partial<ITenant>) => {
      if (!data || 'message' in data || !data.config) {
        // no success
        const err = data as Error;
        let msg = '';
        if (err.name === 'NotFound') {
          msg = 'Die angegebene Firma konnte nicht gefunden werden.';
        } else if (err.name === 'MissingSearchword') {
          msg = 'Bei der Eingabe ist etwas schief gelaufen.';
        } else if ('tenantAccountname' in data) {
          msg = `${err.name} wird in dieser Version aktuell leider nicht unterstützt.`;
        } else {
          Log.error(err);
          msg = `Es ist ein unbekannter Fehler aufgetreten. Fehlercode: ${err.name}`;
        }
        return { error: err, message: msg, requestPath: path };
      } else {
        return data;
      }
    })
    .finally(() => setSubmitting(false));
};

export const checkIfUpdateRequired = (currentVersion?: string, expectedVersion?: string) => {
  if (!currentVersion || !expectedVersion) {
    return false;
  }
  return compare(currentVersion, expectedVersion, '<');
};
