import { StyleSheet, TextInput as TextInputRn } from 'react-native';
import {
  Text,
  TextInput as TextInputPaper,
  TextInputProps as TextInputPropsPaper,
} from 'react-native-paper';
import React, { ForwardedRef, useMemo } from 'react';
import { View } from 'react-native';
import { AppTheme, useAppTheme } from '../../../../styles/theme';

interface TextInputProps {
  onChangeText?: (text: string) => void;
  placeholder?: string;
  label?: string;
  value?: string;
  onFocus?: TextInputPropsPaper['onFocus'];
  onBlur?: TextInputPropsPaper['onBlur'];
}

export const TextInput = React.forwardRef(
  (
    { onChangeText, label, placeholder, value, onFocus, onBlur }: TextInputProps,
    ref: ForwardedRef<TextInputRn>,
  ) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View>
        <Text variant="titleMedium" style={styles.label}>
          {label}
        </Text>
        <TextInputPaper
          onChangeText={onChangeText}
          placeholder={placeholder}
          ref={ref}
          value={value}
          mode="outlined"
          style={styles.input}
          contentStyle={styles.content}
          activeOutlineColor={theme.customColors.borders}
          outlineStyle={{ borderLeftWidth: 0, borderRightWidth: 0 }}
          outlineColor={theme.customColors.borders}
          cursorColor={theme.customColors.primary}
          placeholderTextColor={theme.customColors.textPlaceholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </View>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    input: {
      marginTop: 0,
      backgroundColor: theme.customColors.textInputBackground,
      color: theme.customColors.text,
      borderColor: theme.customColors.borders,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      paddingLeft: 0,
    },
    content: {
      paddingLeft: 10,
    },
    label: { padding: 10, paddingBottom: 0, marginBottom: 0 },
  });
