export const mapExtensionToMimeType = (extension: string | undefined) => {
  if (extension?.includes('document')) {
    return 'application/pdf';
  }
  if (extension?.includes('data:image/jpeg')) {
    return 'image/jpeg';
  }
  if (extension?.includes('data:application/pdf')) {
    return 'application/pdf';
  }
  if (extension) {
    switch (extension) {
      case 'jpg':
        return 'image/jpeg';
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'm4a':
        return 'audio/m4a';
      default:
        return 'file type not supported';
    }
  } else {
    return 'no file extension found';
  }
};
