import React, { useMemo } from 'react';
import { Chip } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { StyleSheet } from 'react-native';

type NewsBottomSheetItemProps = {
  icon: IconSource;
  title: string;
  onPress: () => void;
};

export const NewsBottomSheetItem = ({ icon, title, onPress }: NewsBottomSheetItemProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Chip onPress={onPress} icon={icon} compact style={styles.chip} textStyle={styles.text}>
      {title}
    </Chip>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    chip: { backgroundColor: theme.customColors.background2, marginHorizontal: 2 },
    text: {
      paddingVertical: 1,
    },
  });
