import { TaskManagerTaskBody, TaskManagerTaskExecutor } from 'expo-task-manager';
import { QueryClient } from '@tanstack/react-query';
import { useUnreadMessageCountQuery } from '../../../graphql/operations';
import { urlParse } from '../links';
import { setBadgeCount } from '../setBadgeCount';
import { Log } from '../Log';

export const setUnreadMessageBadge: (queryClient: QueryClient) => TaskManagerTaskExecutor =
  (queryClient: QueryClient) =>
  async ({ data, error }: TaskManagerTaskBody<any>) => {
    if (error) {
      Log.error(error, { message: 'cannot set unread message badge, received error' });
      return;
    }
    const body = parseNotificationBody(data);
    if (!body?.linking) {
      Log.error('cannot set unread message badge, data linking not found');
      return;
    }
    const parsedUrl = urlParse(body.linking);
    if (parsedUrl?.name !== 'ChatRoom') {
      return;
    }
    await invalidateUnreadMessageQuery(queryClient);

    const messageCount = await getUnreadMessages();
    if (messageCount === undefined || messageCount === null) {
      return;
    }
    await setBadgeCount(messageCount);
  };

const getUnreadMessages = async (): Promise<number | undefined> => {
  try {
    const response = await useUnreadMessageCountQuery.fetcher()();
    if (response?.unreadMessageCount === undefined) {
      throw new Error('unread message count could not be received');
    }
    return response.unreadMessageCount;
  } catch (e) {
    Log.error(e, { message: 'could not receive unread message count' });
  }
};

const parseNotificationBody = (data: any) => {
  const body = data?.notification?.data?.body;
  if (!body) {
    Log.error('cannot find notification body');
    return;
  }
  try {
    const parsedResults = JSON.parse(body);
    return parsedResults;
  } catch (e) {
    Log.error(e, { message: 'cannot parse notification body', data: { data } });
  }
};

const invalidateUnreadMessageQuery = async (queryClient: QueryClient) => {
  try {
    await queryClient.invalidateQueries(useUnreadMessageCountQuery.getKey());
  } catch (e) {
    Log.error(e, {
      message: 'cannot invalidate query useUnreadMessageCountQuery',
      data: { key: useUnreadMessageCountQuery.getKey() },
    });
  }
};
