export enum AnimationDirection {
  UP,
  DOWN,
  LEFT,
  RIGHT,
  NONE,
}

export enum AnimationType {
  SLIDE,
  FADE,
}

export enum AnimationPhase {
  IN,
  OUT,
}
