import { useEffect, useState } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { Platform } from 'react-native';

export const useMediaCaching = () => {
  const [canDownload, setCanDownload] = useState(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(connection => {
      if (Platform.OS === 'web' || connection.type === 'wifi') {
        setCanDownload(true);
      } else {
        setCanDownload(false);
      }
    });
    return unsubscribe;
  }, []);

  return {
    canDownload,
    setCanDownload,
  };
};
