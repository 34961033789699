import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { StyleSheet, View, Text } from 'react-native';
import { TextInput } from 'react-native-paper';
import { themeProp } from '../../../types/main';
import { useAppTheme } from '../../../styles/theme';

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    inputBox: {
      backgroundColor: theme.customColors.textInputBackground,
      fontWeight: '500',
      marginLeft: -12,
    },
    inputBoxContainer: {
      marginBottom: 15,
    },
    inputError: {
      color: theme.customColors.error,
      marginTop: 3,
    },
  });

type Props = {
  testId?: string;
  required?: boolean | null;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  isDisabled?: boolean;
  defaultValue?: string;
};

export const ValidationInput = ({
  testId,
  required,
  field: { name, onBlur, onChange, value },
  form: { errors, touched, setFieldTouched },
  isDisabled,
  defaultValue,
  ...inputProps
}: Props) => {
  const theme = useAppTheme();
  const style = createStyles(theme);
  const hasError = errors[name] && touched[name];
  const label = required === true ? 'Antwort erforderlich' : 'Antwort Optional';

  return (
    <View style={style.inputBoxContainer}>
      <TextInput
        {...inputProps}
        multiline
        defaultValue={defaultValue}
        disabled={!!isDisabled}
        testID={testId}
        label={!!isDisabled ? 'Antwort' : label}
        dense
        mode="flat"
        selectionColor={theme.customColors.primary}
        style={style.inputBox}
        value={value}
        onChangeText={text => onChange(name)(text)}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
        }}
      />
      {hasError && <Text style={style.inputError}>{errors[name] as string}</Text>}
    </View>
  );
};
