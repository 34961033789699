import { QueryClient } from '@tanstack/react-query';
import { useInfiniteChatRoomMessagesQuery } from '../../../../graphql/operations';

type InvalidateInfiniteChatRoomMessagesQueryProps = {
  queryClient: QueryClient;
  chatRoomId: string;
};

export const invalidateInfiniteChatRoomMessagesQuery = async ({
  queryClient,
  chatRoomId,
}: InvalidateInfiniteChatRoomMessagesQueryProps) => {
  await queryClient.invalidateQueries(
    useInfiniteChatRoomMessagesQuery.getKey({
      pageInput: { pageSize: 30 },
      chatRoomId: chatRoomId,
    }),
  );
};
