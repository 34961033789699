import { createPendingChatRoomMessage } from '../utils/createPendingChatRoomMessage';
import { invalidateChatRoomsQuery } from '../utils/invalidateChatRoomsQuery';
import { ChatRoomMessage, useCreateChatRoomMessageMutation } from '../../../../graphql/operations';
import { useQueryClient } from '@tanstack/react-query';
import { sendChatRoomMessageErrorHandler } from '../utils/sendChatRoomMessageErrorHandler';
import { alert } from '../../../utils/alert';
import { ExtendedMessage } from '../types';
import { useCallback, useState } from 'react';
import { useStore } from '../../../stores/store';

interface IUseSendChatRoomMessage {
  chatRoomId: string;
  endPendingStateOfMessage: (messageId: ChatRoomMessage['id']) => void;
  addToMessagesAsPending: (chatRoomMessage: ChatRoomMessage) => void;
}

export const useSendChatRoomMessages = ({
  chatRoomId,
  endPendingStateOfMessage,
  addToMessagesAsPending,
}: IUseSendChatRoomMessage) => {
  const queryClient = useQueryClient();
  const createChatRoomMessageMutation = useCreateChatRoomMessageMutation();
  const [isSending, setSending] = useState(false);
  const appUser = useStore(s => s.userProfile);

  const sendChatRoomMessages = useCallback(
    async (newMessages: ExtendedMessage[] = []) => {
      if (!appUser) {
        return;
      }
      setSending(true);
      const messagesToSend = newMessages.map(newMessage =>
        createPendingChatRoomMessage({
          content: newMessage.text,
          quote: newMessage.quote,
          appUser,
          chatRoomId,
        }),
      );
      messagesToSend.forEach(messageToSend => addToMessagesAsPending(messageToSend));
      const sendChatRoomMessagePromises = [];
      for (const messageToSend of messagesToSend) {
        const sendChatRoomMessagePromise = createChatRoomMessageMutation.mutateAsync({
          input: {
            id: messageToSend.id,
            chatRoomId,
            content: messageToSend.content,
            quoteId: messageToSend.quote?.id,
          },
        });
        sendChatRoomMessagePromises.push(sendChatRoomMessagePromise);
      }
      try {
        const sentMessages = await Promise.all(sendChatRoomMessagePromises);
        sentMessages.forEach(message => endPendingStateOfMessage(message.createChatRoomMessage.id));
        setSending(false);
        await invalidateChatRoomsQuery({ queryClient });
      } catch (e) {
        sendChatRoomMessageErrorHandler({
          e: e as Error,
          onNotSentDueToBlock: () => {
            alert(
              'Nachricht wurde nicht gesendet',
              'Sie können keine Nachrichten von diesem Benutzer empfangen oder an ihn senden.',
            );
          },
        });
      } finally {
        setSending(false);
      }
    },
    [appUser, chatRoomId, queryClient],
  );

  return { sendChatRoomMessages, isSending };
};
