import uuid from 'react-native-uuid';
import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { AudioRecorder } from '../../../components/Common/Media/AudioRecorder';
import { AttachmentSheetPicker } from '../../../components/Common/Media/AttachmentSheetPicker';
import { NewsAttachmentType } from '../../../../graphql/operations';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ISendLink } from '../../../components/Common/Media/hooks/useSendLink';
import { AttachmentPickerResultItem } from '../../../components/Common/Media/types';

export type ChatInputToolbarActionsPropTypes = {
  chatRoomId: string;
  attachmentRecipientName: string;
  disabled?: boolean;
  sendLink: ({ feature, selectedItems }: ISendLink) => Promise<void>;
  sendAttachments: (attachmentPickerResultItems: AttachmentPickerResultItem[]) => Promise<void>;
};

export const ChatInputToolbarActions = memo(
  ({
    chatRoomId,
    attachmentRecipientName,
    disabled,
    sendLink,
    sendAttachments,
  }: ChatInputToolbarActionsPropTypes) => {
    const styles = useMemo(() => createStyles(), []);

    const onSubmitAudioMessage = useCallback(
      ({ fileName, otcPath }: Pick<AttachmentPickerResultItem, 'fileName' | 'otcPath'>) =>
        sendAttachments([
          {
            id: uuid.v4().toString(),
            fallbackText: 'Sprachnachricht',
            type: NewsAttachmentType.Audio,
            otcPath,
            fileName,
          },
        ]),
      [chatRoomId],
    );

    const onSubmitAttachments = useCallback(
      async (items: AttachmentPickerResultItem[]) => await sendAttachments(items),
      [chatRoomId],
    );

    return (
      <View style={styles.actionsContainer}>
        <View style={styles.audioRecorderContainer}>
          <AudioRecorder disabled={disabled} onSubmit={onSubmitAudioMessage} />
        </View>
        <AttachmentSheetPicker
          disabled={disabled}
          onSubmit={onSubmitAttachments}
          recipientName={attachmentRecipientName}
          chatRoomId={chatRoomId}
          uploadType={UploadType.ChatMessage}
          sendLink={sendLink}
        />
      </View>
    );
  },
);

const createStyles = () =>
  StyleSheet.create({
    actionsContainer: {
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 6,
      flexDirection: 'row',
    },
    audioRecorderContainer: {
      alignSelf: 'center',
      justifyContent: 'center',
      marginRight: 6,
    },
  });
