import { useMemo } from 'react';
import { useStore } from '../../../stores/store';
import { ChatRoomToReadQuery } from '../../../../graphql/operations';
import { UseQueryResult } from '@tanstack/react-query';
import { getChatRoomName } from '../utils/getChatRoomName';
import { Constants } from '../../../utils';
import { ChatPartner } from '../types';

interface IUseGetChatRoomName {
  chatRoomQueryData: UseQueryResult<ChatRoomToReadQuery, unknown>['data'];
  chatRoomQueryIsInitialLoading: UseQueryResult<ChatRoomToReadQuery, unknown>['isInitialLoading'];
  name?: string;
  members?: ChatPartner[];
}

export const useGetChatRoomName = ({
  chatRoomQueryData,
  chatRoomQueryIsInitialLoading,
  members,
  name,
}: IUseGetChatRoomName) => {
  const userProfile = useStore(s => s.userProfile);
  const chatRoomName = useMemo<string>(() => {
    if (chatRoomQueryData && chatRoomQueryData.chatRoom.name) {
      return chatRoomQueryData.chatRoom.name;
    }
    if (!userProfile) {
      return '';
    }
    if (chatRoomQueryData) {
      return getChatRoomName(chatRoomQueryData.chatRoom.name, {
        chatPartners: chatRoomQueryData.chatRoom.members ?? [],
        userProfile,
      });
    }
    if (chatRoomQueryIsInitialLoading) {
      return Constants.IS_LOADING_TEXT;
    }
    if (name) {
      return name;
    }
    return getChatRoomName(name, { chatPartners: members ?? [], userProfile });
  }, [userProfile, name, members, chatRoomQueryData, chatRoomQueryIsInitialLoading]);

  return chatRoomName;
};
