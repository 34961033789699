import * as SecureStore from 'expo-secure-store';
import { useGetStockImageApiTokenQuery } from '../../../../../graphql/operations';
import { getOs } from '../../../../utils';
import { Log } from '../../../../utils/Log';

export const useGetStockImageApiToken = () => {
  const isOnWeb = getOs() === 'web';

  const saveTokenToSecureStore = async (token: string) => {
    return await SecureStore.setItemAsync('stockImageApiToken', token);
  };

  const getTokenFromBackend = async () => {
    try {
      const response = await useGetStockImageApiTokenQuery.fetcher()();
      const token = response.getStockImageApiToken.token;
      if (!isOnWeb) {
        await saveTokenToSecureStore(token);
      }
      return token;
    } catch (error) {
      const err = error as Error;
      const msg = `an error occurred while retrieving token for stockImage from backend: ${err.message}`;
      Log.error(err, { message: msg });
      throw new Error(msg);
    }
  };

  const getToken = async () => {
    if (isOnWeb) {
      return getTokenFromBackend();
    }
    const token = await SecureStore.getItemAsync('stockImageApiToken');
    if (!token) {
      return await getTokenFromBackend();
    }
    return token;
  };

  return {
    getToken,
  };
};
