import { View } from 'react-native';
import { FormQuestion as FormQuestionType } from '../../../../../graphql/operations';
import { FormQuestion } from '../FormQuestion/FormQuestion';
import { FormAnswerFactory } from '../FormAnswerFactory/FormAnswerFactory';
import React from 'react';
import { BinaryAnswerType } from '../../types';

type FormQuestionAnswerPairPropTypes = {
  question: FormQuestionType;
  answer?: string | null;
  questionIdFormValue?: BinaryAnswerType;
  setQuestionIdFormValue?: (yesOrNo: BinaryAnswerType) => void;
  answerIsEditable?: boolean;
};

export const FormQuestionAnswerPair = ({
  question,
  answer,
  questionIdFormValue,
  setQuestionIdFormValue,
  answerIsEditable = true,
}: FormQuestionAnswerPairPropTypes) => {
  return (
    <View key={question.id}>
      <FormQuestion question={question} />
      <FormAnswerFactory
        question={question}
        answer={answer}
        questionIdFormValue={questionIdFormValue}
        setQuestionIdFormValue={setQuestionIdFormValue}
        editable={!!answerIsEditable}
      />
    </View>
  );
};
