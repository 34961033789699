import { ActivityIndicator } from 'react-native-paper';
import { StyleSheet, Text, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { AppTheme, useAppTheme } from '../../../styles/theme';

export type NoDataRefreshPropTypes = {
  /*
   * Text underneath the icon.
   */
  text: string;
  /*
   * Color of the spinner color.
   */
  spinnerColor?: string;
  /*
   * Toggle that switches between the icon and the activity indicator.
   */
  isLoading?: boolean;
  loadingText?: boolean;
  /*
   * Icon to be displayed.
   */
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  /*
   * Function to call when the icon is pressed.
   */
  onPress?: () => void;
};

/**
 * This component can be used when the query that fetches the data is successful, but there is no data to be displayed (i.e. either undefined and [] is returned for data, and isLoading is false).
 *
 * Do not use it when the query that fetches the data hasn't had a chance to be successful yet (i.e. data is undefined and isLoading is true, usually during the initial mount of the component).
 *
 * For those cases, use either skeletons, spinners, or RefreshControl along with useRefreshControlWithTimeout hook if the data is displayed in a flatlist or a scrollview.
 */
export const NoDataRefresh = (props: NoDataRefreshPropTypes): JSX.Element => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <View style={styles.item}>
        <View style={styles.contents}>
          {props.isLoading ? (
            <ActivityIndicator animating={true} color={theme.customColors.gray80} size="large" />
          ) : (
            <MaterialCommunityIcons
              name={props.icon || 'refresh-circle'}
              color={theme.customColors.gray80}
              size={46.5}
              onPress={props.onPress}
            />
          )}
          <Text style={styles.text}>{props.isLoading ? props.loadingText : props.text}</Text>
        </View>
      </View>
      <View style={{ flex: 5 }} />
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: { height: '100%', width: '100%', alignSelf: 'center', marginTop: 24 },
    item: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contents: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: theme.customColors.textGray,
      fontSize: 16,
      marginTop: 20,
      textAlign: 'center',
      paddingHorizontal: 30,
    },
  });
