import { Comment } from '../../../../graphql/operations';

export const appendReplyToCommentReplies = (
  comments: Comment[],
  commentId: Comment['id'],
  reply: Comment,
) => {
  return [
    ...comments.map(comment =>
      comment.id === commentId
        ? {
            ...comment,
            replies: Array.isArray(comment.replies) ? [...comment.replies, reply] : [reply],
          }
        : comment,
    ),
  ];
};
