import { Log } from '../../../../utils/Log';

import { Headline, IconButton, Portal, useTheme } from 'react-native-paper';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import {
  ScheduleDataResponse,
  ScheduleScreenVivendiScreenProps,
  ShiftListViewCategory,
  UserData,
} from '../types';
import { ScheduleListView } from '../components/ScheduleListView';
import { getScheduleVivendi, loadShiftDataFromVivendi } from '../api';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { themeProp } from '../../../../types/main';
import { checkScheduleResponseVivendi, transformScheduleDataVivendi } from '../utils';
import { LoadingBlocker } from '../../../../components/Common/Loading/LoadingBlocker';

export const ScheduleScreenVivendi = ({ route: { params } }: ScheduleScreenVivendiScreenProps) => {
  const [transformedScheduleData, setTransformedScheduleData] = useState<ShiftListViewCategory[]>();
  const [responseIsError, setResponseIsError] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState('');
  const [scheduleStartDate, setScheduleStartDate] = useState(moment().startOf('month'));
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme() as themeProp;
  const style = createStyles(theme);

  useEffect(() => {
    /* @todo must be awaited */
    void loadSchedule();
  }, [scheduleStartDate]);

  const loadSchedule = async () => {
    if (responseIsError) {
      setResponseIsError(false);
      setResponseErrorMessage('');
    }
    setIsLoading(true);
    const userData: UserData = jwt_decode(params.token);
    let response: ScheduleDataResponse | undefined;
    if (userData.EmployeeId) {
      try {
        response = await getScheduleVivendi(
          params.token,
          userData.EmployeeId,
          {
            from: scheduleStartDate.format('YYYY-MM-DD'),
            to: scheduleStartDate.clone().endOf('month').format('YYYY-MM-DD'),
          },
          params.endpoint,
        );
      } catch (error) {
        Log.error(error);
      }
      try {
        if (checkScheduleResponseVivendi(response, scheduleStartDate, moment())) {
          const shiftDetailsResponse = await loadShiftDataFromVivendi(
            response!.Diensteintraege!,
            params.token,
            params.endpoint,
          );
          const newScheduleData = transformScheduleDataVivendi(
            response!.Diensteintraege!,
            scheduleStartDate,
            shiftDetailsResponse,
          );
          setTransformedScheduleData(newScheduleData);
        }
      } catch (err) {
        setResponseIsError(true);
        if (typeof err === 'string') {
          setResponseErrorMessage(err);
        } else {
          setResponseErrorMessage(
            'Beim Abfragen des Dienstplans ist ein unbekannter Fehler aufgetreten',
          );
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <SafeAreaView style={style.safeAreaView}>
      <View style={style.container}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <IconButton
            icon="chevron-left"
            size={35}
            onPress={() => setScheduleStartDate(oldDate => oldDate.clone().subtract(1, 'month'))}
          />
          <Headline>
            <Text>{scheduleStartDate.format('MMMM')}</Text>
          </Headline>
          <IconButton
            icon="chevron-right"
            size={35}
            onPress={() => setScheduleStartDate(oldDate => oldDate.clone().add(1, 'month'))}
          />
        </View>
        {responseIsError ? (
          <Headline style={style.warning}>{responseErrorMessage}</Headline>
        ) : (
          <>
            <ScheduleListView
              data={transformedScheduleData}
              token={params.token}
              /* @todo must be awaited */
              reload={() => void loadSchedule()}
            />
          </>
        )}
        <Portal>
          <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
            <Headline
              style={{
                color: '#fff',
              }}
            >
              Dienstplan wird geladen
            </Headline>
          </LoadingBlocker>
        </Portal>
      </View>
    </SafeAreaView>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    safeAreaView: {
      flex: 1,
      alignItems: 'center',
      width: '100%',
    },
    container: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: theme.colors.background,
    },
    warning: {
      textAlign: 'center',
      color: theme.customColors.error,
      marginTop: 8,
      marginBottom: 18,
      alignSelf: 'center',
    },
  });
