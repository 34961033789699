import { ActivityIndicator, TextInput, Text } from 'react-native-paper';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import { useAppTheme } from '../../styles/theme';

interface ICommentInput {
  onChange: (arg0: string) => void;
  isLoading?: boolean;
  inReplyTo?: { commentId: string; parentAuthorId: string; name: string } | null;
  onClearReplyTo: () => void;
}

export const CommentInput = (props: ICommentInput) => {
  const theme = useAppTheme();

  const submit = () => {
    if (ref && ref.current) {
      ref.current.clear();
      setText('');
    }
    if (text && text.trim()) {
      props.onChange(text);
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      ref?.current?.focus();
    }
  }, []);

  const [text, setText] = useState('');
  const ref = useRef<any>();
  return (
    <OfflineErrorModal>
      {onOnline => (
        <View style={{ width: '100%', backgroundColor: '#f00' }}>
          {props.inReplyTo && (
            <View
              style={{
                width: '100%',
                backgroundColor: theme.customColors.background3,
                paddingHorizontal: 12,
                paddingVertical: 6,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={{ color: theme.customColors.text }}>Als Antwort an </Text>
              <Text style={{ fontWeight: 'bold' }}>{props.inReplyTo.name}</Text>
              <Icon
                name="close"
                size={20}
                onPress={() => props.onClearReplyTo()}
                color={theme.customColors.gray60}
                style={{ marginLeft: 16 }}
              />
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              maxHeight: 140,
              alignItems: 'center',
              backgroundColor: theme.colors.background,
              justifyContent: 'space-between',
              borderTopWidth: 0.3,
              borderTopColor: theme.customColors.gray70,
            }}
          >
            <TextInput
              ref={ref}
              multiline={true}
              value={text}
              onChangeText={(val: string) => {
                setText(val);
              }}
              style={{
                flex: 1,
                backgroundColor: theme.customColors.textInputBackground,
                marginBottom: 0,
                paddingTop: 8,
                marginRight: 15,
              }}
              underlineColor="transparent"
              placeholder="Kommentieren..."
              dense
              onFocus={() => onOnline(() => {})}
              testID="CommentInput"
            />
            {!props.isLoading ? (
              <Icon
                name="send"
                size={25}
                color={theme.customColors.gray50}
                onPress={submit}
                style={{ marginRight: 10 }}
                testID="CommentInputSend"
              />
            ) : (
              <ActivityIndicator
                size="small"
                color={theme.customColors.gray50}
                style={{ marginRight: 10 }}
              />
            )}
          </View>
        </View>
      )}
    </OfflineErrorModal>
  );
};
