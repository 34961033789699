import { StyleSheet, View, TouchableOpacity, Platform, Keyboard } from 'react-native';
import { Text } from 'react-native-paper';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { CredentialLogIn } from '../../components/Auth/CredentialLogIn';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { NewCachedImage } from '../../features/caching/components/NewCachedImage';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StackScreenProps } from '@react-navigation/stack';
import { resetTenant } from '../../utils/tenancyUtils';
import checkVersion from 'react-native-store-version';
import { androidStoreUrl, iosStoreUrl, versionCode } from '../../../app.config';
import { UserLogin } from '../../../test/testIds';
import AppLoading from 'expo-app-loading';
import { useAppStatus } from '../../hooks/common/appStatus';
import { Log } from '../../utils/Log';
import { KeyboardAvoidingView } from '../../components/HigherOrder';
import { getEnvironmentByApiUrl } from '../../utils/getEnvironmentByApiUrl';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useTenantContext } from '../../hooks/tenant';
import { UploadType } from '../../components/Common/FileUpload/types';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { checkIsWhiteLabelApp } from '../../utils/checkIsWhiteLabelApp';

export type LoginScreenProps = StackScreenProps<AppNavigatorParamList, 'Login'>;

export const LoginScreen = (props: LoginScreenProps) => {
  const { tenant, isRetrievingTenant, fetchTenantAndUpdateState, setTenant } = useTenantContext();
  const theme = useAppTheme();
  const {
    content: { maxWidth: contentMaxWidth },
  } = theme;
  const isWhiteLabelApp = checkIsWhiteLabelApp();
  const styles = useMemo(() => createStyles(theme, isWhiteLabelApp), [theme, isWhiteLabelApp]);
  const [versionCheckResult, setVersionCheckResult] = useState('(Aktuell)');
  const environment = getEnvironmentByApiUrl(process.env.EXPO_PUBLIC_API_URL as string);
  const [tenantChangePopupVisible, toggleTenantChangePopup] = useReducer(
    previous => !previous,
    false,
  );
  const windowDimensions = useWindowDimensions();
  const { appMovedToView } = useAppStatus();

  const refreshTenantData = async (tenantNameArg: string) => {
    try {
      await fetchTenantAndUpdateState(tenantNameArg);
    } catch (e) {
      Log.warning(e, {
        message: 'something went wrong when calling fetchTenantAndUpdateState in LoginScreen',
      });
    }
  };

  useEffect(() => {
    if (appMovedToView && tenant) {
      void refreshTenantData(tenant.tenantName).finally(() => {});
    }
  }, [appMovedToView]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      /* @todo must be awaited */
      void versionCheck();
    }
  }, []);

  const versionCheck = useCallback(async () => {
    try {
      const check = await checkVersion({
        version: versionCode,
        iosStoreURL: iosStoreUrl,
        androidStoreURL: androidStoreUrl,
        country: 'de',
      });
      if (check.detail === 'remote > local') {
        setVersionCheckResult('(Veraltet)');
      }
    } catch (e) {
      Log.warning(e);
      Log.warning('cannot check version');
    }
  }, []);

  useEffect(() => {
    if (!tenant) {
      props.navigation.navigate('TenantScreen');
    }
  }, []);

  if (isRetrievingTenant) {
    return <AppLoading />;
  }

  if (!tenant) {
    // @todo?: add a component here for fatal errors
    return <AppLoading />;
  }

  return (
    <SafeAreaView style={styles.loginScreenContainer} testID={UserLogin.screenView}>
      <KeyboardAvoidingView style={styles.loginCredentialsContainer} behavior="padding">
        <View style={{ alignItems: 'center' }}>
          {tenant.logoS3Path && (
            <NewCachedImage
              source={{ uri: tenant.logoS3Path }}
              type={UploadType.Static}
              onPress={Keyboard.dismiss}
              style={{
                height: (windowDimensions.width - 40) * (500 / 800),
                width: windowDimensions.width - 40,
                maxWidth: contentMaxWidth,
                maxHeight: contentMaxWidth * (500 / 800),
                marginBottom: 0,
              }}
            />
          )}
        </View>
        <CredentialLogIn />
        <View>
          <View style={styles.termsAndPrivacyContainer}>
            <TouchableOpacity onPress={() => props.navigation.navigate('Consent')}>
              <Text style={styles.termsAndPrivacyButton}>Nutzungsbedingungen</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => props.navigation.navigate('Privacy')}>
              <Text style={styles.termsAndPrivacyButton}>Datenschutzerklärung</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.versionAndTenantContainer}>
            <Text style={styles.versionText}>
              {'Version: ' +
                versionCode +
                ' ' +
                versionCheckResult +
                (environment === 'prod' ? '' : ` (${environment})`)}
            </Text>
            {!isWhiteLabelApp && (
              <TouchableOpacity onPress={toggleTenantChangePopup} style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    color: theme.customColors.gray60,
                    fontSize: 12,
                  }}
                >
                  Firma wechseln
                </Text>
                <Icon
                  name="chevron-right"
                  color={theme.customColors.gray60}
                  size={14}
                  style={{ marginTop: 1 }}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
        <ConfirmDialog
          title="Firma wechseln?"
          content="Sind Sie sicher, dass Sie die Firma wechseln wollen?"
          confirmButtonLabel="Wechseln"
          isOpen={tenantChangePopupVisible}
          onConfirm={async () => {
            await resetTenant();
            setTenant(undefined);
            props.navigation.navigate('TenantScreen');
          }}
          onCancel={toggleTenantChangePopup}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const createStyles = (theme: AppTheme, isWhiteLabelApp: boolean) =>
  StyleSheet.create({
    loginScreenContainer: {
      flex: 1,
      paddingHorizontal: 30,
      paddingTop: 10,
      backgroundColor: theme.colors.background,
      alignItems: 'center',
    },
    termsAndPrivacyContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    termsAndPrivacyButton: {
      color: theme.customColors.primary,
      fontSize: 12,
    },
    versionAndTenantContainer: {
      flexDirection: 'row',
      justifyContent: isWhiteLabelApp ? 'center' : 'space-between',
      width: '100%',
      marginBottom: 5,
      marginTop: 12,
    },
    loginCredentialsContainer: {
      flex: 1,
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: theme.content.maxWidth,
    },
    versionText: {
      color: theme.customColors.textVersion,
      fontSize: 12,
    },
  });
