import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';

import { TouchableOpacity, View } from 'react-native';
import { Text, Button } from 'react-native-paper';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { authStyles as styles } from '../../styles/authStyles';
import { useAppTheme } from '../../styles/theme';

const CELL_COUNT = 6;

export type ConfirmationCodeAbstract = {
  onSend(code: string): Promise<any>;
  channel?: string;
  onPressResend(): Promise<void>;
  showResendMessage: boolean;
  isLoading: boolean;
};

export type RefProps = {
  setCodeValue(value: string): void;
};

const ConfirmationCodeAbstractScreen: ForwardRefRenderFunction<
  RefProps,
  ConfirmationCodeAbstract
> = (
  { channel, onSend, onPressResend, showResendMessage, isLoading }: ConfirmationCodeAbstract,
  _ref,
) => {
  const contactType = channel || 'E-Mail bzw. SMS';
  const theme = useAppTheme();
  const [value, setValue] = useState('');
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  useImperativeHandle(_ref, () => ({
    setCodeValue: setValue,
  }));

  return (
    <BaseAuthScreen
      headline="Bestätigungscode eingeben"
      description={`Bitte geben Sie nachfolgend den Code ein, den wir Ihnen soeben per ${contactType} zugesendet haben.`}
      innerViewStyles={{ flex: 1 }}
      justify
    >
      <View style={{ width: '100%', flex: 1, maxWidth: theme.content.maxWidth }}>
        <CodeField
          ref={ref}
          {...codeFieldProps}
          value={value}
          onChangeText={setValue}
          cellCount={CELL_COUNT}
          rootStyle={styles(theme).codeFieldRoot}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          renderCell={({ index, symbol, isFocused }) => (
            <Text
              key={index}
              style={[styles(theme).cell, isFocused && styles(theme).focusCell]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          )}
          textInputStyle={{
            backgroundColor: theme.customColors.textInputBackground,
          }}
        />
        <View style={styles(theme).button}>
          <Button
            style={{ borderRadius: 5 }}
            loading={isLoading}
            mode="contained"
            disabled={isLoading || !value || value.length < 6}
            onPress={async () => await onSend(value)}
            textColor={theme.customColors.textWhite}
          >
            Bestätigen
          </Button>
        </View>
        {showResendMessage && (
          <View style={styles(theme).bottomFloatingView}>
            <Text style={styles(theme).smallText}>
              Keine Nachricht erhalten? Sie können auch den
            </Text>
            {/* @todo must be awaited */}
            <TouchableOpacity onPress={() => void onPressResend()}>
              <Text style={styles(theme).smallButtonText}>Bestätigungscode erneut anfordern</Text>
            </TouchableOpacity>
            <Text style={styles(theme).smallText}>
              Wenn Sie keinen Code erhalten können, wenden Sie sich bitte an Ihren Administrator und
              vergewissern Sie sich, dass die mit Ihrem Konto verknüpfte E-Mail-Adresse oder
              Telefonnummer korrekt und verifiziert ist.
            </Text>
          </View>
        )}
      </View>
    </BaseAuthScreen>
  );
};
export const ConfirmationCodeAbstract = forwardRef(ConfirmationCodeAbstractScreen);
