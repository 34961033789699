import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { IconLabel } from '../../Common/Icon/IconLabel';
import { IconButton } from 'react-native-paper';
import { useAppTheme } from '../../../styles/theme';

type PickContactsButtonPropsTypes = {
  onPress: () => void;
};

export const PickContactsButton = ({ onPress }: PickContactsButtonPropsTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  return (
    <TouchableOpacity activeOpacity={0.8} style={styles.touchableOpacity} onPress={onPress}>
      <IconLabel
        iconProps={{
          icon: 'account-multiple-plus-outline',
          radius: 100,
          iconSize: 20,
          squareSideLength: 32,
          marginRight: 8,
        }}
        label="Weitere Kontakte anzeigen"
        marginLeft={16}
      />
      <IconButton icon="chevron-right" iconColor={theme.customColors.icon} size={32} />
    </TouchableOpacity>
  );
};

const createStyles = () =>
  StyleSheet.create({
    touchableOpacity: {
      marginTop: -6,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });
