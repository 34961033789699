import { ChatRoomMessageStatus } from '../../../../graphql/operations';

export const translateChatRoomMessageStatus = (status: ChatRoomMessageStatus) => {
  switch (status) {
    case ChatRoomMessageStatus.Read:
      return 'Gelesen von'.toUpperCase();
    case ChatRoomMessageStatus.Sent:
      return 'Gesendet an'.toUpperCase();
    default:
      return 'Unbekannt';
  }
};
