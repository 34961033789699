import * as Sharing from 'expo-sharing';

interface ISaveFileToDeviceIos {
  uri: string;
}

/**
 * Only IOS
 */
export const saveFileToDeviceIos = async ({ uri }: ISaveFileToDeviceIos) => {
  try {
    const uti = 'public.item'; // uti stands for Universal Type Identifier
    await Sharing.shareAsync(uri, { UTI: uti });
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
