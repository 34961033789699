import {
  NewsAttachmentType,
  useCreateChatRoomMessageMutation,
} from '../../../../graphql/operations';
import { AttachmentPickerResultItem } from '../../../components/Common/Media/types';

interface IAttachmentPickerToMutation {
  createChatRoomMessageMutation: ReturnType<typeof useCreateChatRoomMessageMutation>;
  chatRoomId: string;
  id: string;
}

export const attachmentPickerToMutation = ({
  createChatRoomMessageMutation,
  chatRoomId,
  otcPath,
  fileName,
  fallbackText,
  type,
  id,
}: AttachmentPickerResultItem & IAttachmentPickerToMutation) => {
  return createChatRoomMessageMutation.mutateAsync({
    input: {
      id,
      chatRoomId,
      content: fallbackText,
      attachment: {
        s3Path: 'deprecated',
        otcPath: otcPath,
        fileName: fileName,
        type: type as NewsAttachmentType,
      },
    },
  });
};
