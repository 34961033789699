import { PermissionStatus } from 'expo-image-picker';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { baseTheme } from '../../styles/theme';

export const requestPushNotificationPermissions = async (): Promise<PermissionStatus> => {
  if (Platform.OS === 'web') {
    throw new Error('cannot request push notification on web');
  }
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: baseTheme.customColors.primary,
    });
  }
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  return finalStatus;
};
