import React, { ReactNode, memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

type ChatBubbleOverlayProps = {
  containerHeight: number;
  children: ReactNode;
  childrenHeight: number;
};

export const ChatBubbleOverlay = memo(
  ({ children, containerHeight, childrenHeight }: ChatBubbleOverlayProps) => {
    const styles = useMemo(
      () => createStyles(containerHeight, childrenHeight),
      [, containerHeight, childrenHeight],
    );
    return <View style={styles.underlay}>{children}</View>;
  },
);

const createStyles = (containerHeight: number, childrenHeight: number) =>
  StyleSheet.create({
    underlay: {
      position: 'absolute',
      left: -24,
      top: (containerHeight - childrenHeight) / 2,
    },
  });
