import * as Notifications from 'expo-notifications';
import { isDevice } from 'expo-device';
import appConfig from '../../../app.config';
import { Log } from '../../utils/Log';

const getPushTokenArguments = () => {
  if (appConfig?.expo?.extra?.eas?.projectId) {
    return { projectId: appConfig?.expo?.extra?.eas?.projectId };
  }
};

export const requestPushToken = async () => {
  if (!isDevice) {
    return undefined;
  }
  const token = await Notifications.getExpoPushTokenAsync(getPushTokenArguments());
  if (!token || !token.data) {
    Log.error('cannot get push token from expo');
    return undefined;
  }
  return token;
};
