import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { memo, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { TouchableRipple, Text } from 'react-native-paper';
import { PinnedNewsListQuery, UserPreference } from '../../../../graphql/operations';
import { getDisplayedUserGroupLabels } from '../../../components/News/NewsUtils';
import { useStore } from '../../../stores/store';
import { useAppTheme } from '../../../styles/theme';
import { themeProp, UserGroupWithLabel } from '../../../types/main';
import { NewsDetailScreenProps } from '../types';
import { getFullName } from '../../profiles/utils';

type PinnedNewsItemProps = {
  news: PinnedNewsListQuery['pinnedNewsList'][number];
  userGroupsUserIsPartOf: UserGroupWithLabel[];
  translationEnabled: UserPreference['translationEnabled'];
  translationLanguage: UserPreference['translationLanguage'];
};

export const PinnedNewsItem = memo(
  ({
    news,
    userGroupsUserIsPartOf,
    translationEnabled,
    translationLanguage,
  }: PinnedNewsItemProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const navigation = useNavigation<NewsDetailScreenProps['navigation']>();
    const userGroupLabelsForNews = getDisplayedUserGroupLabels({ userGroupsUserIsPartOf, news });

    const navigateToBlockedUserProfile = useCallback(() => {
      if (news.author?.userId) {
        navigation.navigate('Profile', {
          profileId: news.author.userId,
          name: getFullName({ firstname: news.author.firstname, lastname: news.author.lastname }),
        });
      }
    }, [news.author?.userId]);

    const navigateToDetailedView = useCallback(() => {
      navigation.navigate('NewsDetail', {
        newsId: news.id,
        userGroupLabels: userGroupLabelsForNews,
        translationEnabled: translationEnabled,
        translationLanguage: translationLanguage,
      });
    }, [news.id, userGroupsUserIsPartOf, translationEnabled, translationLanguage]);

    const userProfile = useStore(s => s.userProfile);

    const isNewsContentBlocked = Boolean(
      userProfile &&
        news.author &&
        userProfile.blockedUserIds &&
        userProfile.blockedUserIds.includes(news.author.userId),
    );

    const onPressSurface = () => {
      if (!isNewsContentBlocked) {
        navigateToDetailedView();
      } else {
        navigateToBlockedUserProfile();
      }
    };

    return (
      <TouchableRipple
        onPress={onPressSurface}
        rippleColor={theme.customColors.lightRipple}
        style={styles.itemContainer}
      >
        <View style={styles.outerContainer}>
          <View style={[styles.innerContainer, styles.leftContainerFlex]}>
            <MaterialCommunityIcons
              color={theme.customColors.gray50}
              name="pin-outline"
              size={24}
              style={styles.icon}
            />
            <Text style={styles.title} numberOfLines={2}>
              {isNewsContentBlocked
                ? `Sie können diese gepinnten News nicht sehen, weil Sie ${
                    news.author?.firstname ?? 'diesen'
                  } ${news.author?.lastname ?? 'Benutzer'} blockiert haben.`
                : news.title}
            </Text>
          </View>
          {!isNewsContentBlocked && (
            <View style={styles.innerContainer}>
              <MaterialCommunityIcons name="chevron-right" size={30} style={styles.chevron} />
            </View>
          )}
        </View>
      </TouchableRipple>
    );
  },
);

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    itemContainer: {
      paddingVertical: 12,
      paddingLeft: 18,
      paddingHorizontal: 8,
      width: '100%',
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      backgroundColor: theme.customColors.listRowItemBackground,
      marginVertical: -1,
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 6,
    },
    leftContainerFlex: {
      flex: 1,
      marginRight: 2,
    },
    title: {
      color: theme.customColors.textDark,
      marginLeft: 12,
    },
    chevron: {
      color: theme.customColors.gray50,
    },
    pin: {
      color: theme.customColors.primary,
    },
    icon: {
      transform: [{ rotate: '-45deg' }],
    },
  });
