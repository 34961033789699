import { ChatMessageTyping } from '@luciapp/socket-contract';

interface IGetChatMessageBeingTypedText {
  typingEvents: ChatMessageTyping[];
  isGroupChat?: boolean;
}

export const getChatMessageBeingTypedText = ({
  typingEvents,
  isGroupChat,
}: IGetChatMessageBeingTypedText) => {
  if (typingEvents.length === 0) {
    return undefined;
  }
  if (!isGroupChat) {
    return 'schreibt…';
  }
  if (typingEvents.length === 1) {
    return `${typingEvents[0].firstname} ${typingEvents[0].lastname} schreibt…`;
  }
  if (typingEvents.length === 2) {
    return `${typingEvents[0].firstname} ${typingEvents[0].lastname} und ${typingEvents[1].firstname} ${typingEvents[1].lastname} schreiben…`;
  }
  return `${typingEvents[0].firstname} ${typingEvents[0].lastname} und 2 andere schreiben…`;
};
