import { useCallback, useEffect, useRef, useState } from 'react';
import { SwipeableItemImperativeRef, OpenDirection } from 'react-native-swipeable-item';
import { Log } from '../../../utils/Log';

interface IUseChatBubbleOverlay {
  isSelectable?: boolean;
}

export const useChatBubbleOverlay = ({ isSelectable }: IUseChatBubbleOverlay) => {
  const snapPoint = 36;
  const swipeableItemImperativeRef = useRef<SwipeableItemImperativeRef>(null);
  const snapPoints = [snapPoint];
  const [height, setHeight] = useState<number>();

  const open = useCallback(async () => {
    try {
      await swipeableItemImperativeRef.current?.open(OpenDirection.LEFT, snapPoint, {
        animated: true,
      });
    } catch (error) {
      const err = error as Error;
      const msg = `an error occurred while imperatively opening a chat bubble overlay: ${err.message}`;
      Log.error(msg);
    }
  }, [swipeableItemImperativeRef.current]);

  const close = useCallback(async () => {
    try {
      await swipeableItemImperativeRef.current?.close();
    } catch (error) {
      const err = error as Error;
      const msg = `an error occurred while imperatively closing a chat bubble overlay: ${err.message}`;
      Log.error(msg);
    }
  }, [swipeableItemImperativeRef.current]);

  useEffect(() => {
    if (!swipeableItemImperativeRef.current) {
      return;
    }
    if (isSelectable) {
      return void open();
    }
    return void close();
  }, [swipeableItemImperativeRef.current, isSelectable]);

  return { swipeableItemImperativeRef, snapPoints, height, setHeight, open, close };
};
