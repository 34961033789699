import React, { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { FAB } from 'react-native-paper';

type AttachmentPickerFabPropTypes = {
  onPress: () => void;
  isFloating?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
};

export const AttachmentPickerFab = ({
  onPress,
  isFloating,
  size = 'small',
  disabled,
}: AttachmentPickerFabPropTypes) => {
  const theme = useAppTheme();
  const isOnWeb = Platform.OS === 'web';
  const styles = useMemo(() => createStyles(theme, isOnWeb), [theme]);
  return (
    <FAB
      size={size}
      disabled={disabled}
      icon="paperclip"
      color={isFloating ? theme.customColors.pureWhite : theme.customColors.icon}
      style={isFloating ? styles.fabPositionAbsolute : styles.fabPositionRelative}
      onPress={onPress}
      testID="AttachmentPickerIcon"
    />
  );
};

const createStyles = (theme: AppTheme, isOnWeb: boolean) =>
  StyleSheet.create({
    fabPositionRelative: {
      backgroundColor: theme.customColors.chatBubbleBackground,
      borderRadius: 100,
      borderWidth: 0.5,
      borderColor: theme.customColors.borders,
    },
    fabPositionAbsolute: {
      backgroundColor: theme.customColors.primary,
      position: 'absolute',
      margin: 0,
      left: isOnWeb ? 216 : 128,
      bottom: 32,
      borderRadius: 100,
    },
  });
