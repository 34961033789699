import React, { memo, useState } from 'react';
import { Divider } from 'react-native-paper';
import { ReadConfirmation } from './ReadConfirmation';
import { View } from 'react-native';
import { invalidateNewsCache } from '../../features/news/utils/cache';
import { useConfirmReadingMutation } from '../../../graphql/operations';
import { useQueryClient } from '@tanstack/react-query';

type NewsReadConfirmationBoxPropTypes = {
  newsId: string;
  groupIdsInNewsTimelineQuery: string[];
  hasAlreadyConfirmed: boolean;
  searchTextInNewsTimeLineQuery?: string;
};

export const NewsReadConfirmationBox = memo(
  ({
    newsId,
    groupIdsInNewsTimelineQuery,
    hasAlreadyConfirmed,
    searchTextInNewsTimeLineQuery,
  }: NewsReadConfirmationBoxPropTypes) => {
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const queryClient = useQueryClient();
    const confirmNewsReadMutation = useConfirmReadingMutation({
      onSuccess: async () => {
        await invalidateNewsCache({
          newsId: newsId,
          queryClient,
          groupIdsInNewsTimelineQuery: groupIdsInNewsTimelineQuery,
          searchTextInNewsTimeLineQuery: searchTextInNewsTimeLineQuery,
        });
      },
    });

    const confirmRead = () => {
      if (hasAlreadyConfirmed) {
        return true;
      }
      confirmNewsReadMutation.mutate({
        newsId: newsId,
      });
      setHasConfirmed(true);
    };

    return (
      <View>
        <Divider style={{ height: 1 }} />
        <ReadConfirmation
          isLoading={confirmNewsReadMutation.isLoading}
          hasConfirmed={hasAlreadyConfirmed || hasConfirmed}
          onAccept={confirmRead}
        />
      </View>
    );
  },
);
