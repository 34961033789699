import React, { useState, useMemo, memo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import Icon from '@expo/vector-icons/SimpleLineIcons';
import {
  SingleNewsToReadQuery,
  useCreatePollAnswerMutation,
  NewsToReadByUserGroupQuery,
} from '../../../graphql/operations';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import { themeProp } from '../../types/main';
import { AppTheme } from '../../styles/theme';

export const PollDetail = memo(
  (props: {
    newsId: string;
    poll:
      | SingleNewsToReadQuery['news']['poll']
      | NewsToReadByUserGroupQuery['newsListByUserGroup']['news'][number]['poll'];
    hasAnswered: boolean;
  }) => {
    const theme = useTheme() as themeProp;
    const styles = useMemo(() => createStyles(theme), [theme]);
    const [pollIsAnswered, setPollIsAnswered] = useState<boolean>(props.hasAnswered);
    const [selectedOption, setSelectedOption] = useState<string | null>();

    const createPollAnswerMutation = useCreatePollAnswerMutation({
      onSuccess: () => {
        setPollIsAnswered(true);
      },
    });

    const answerPoll = (optionId: string) => {
      setSelectedOption(props.poll?.pollOptions?.find(item => item.id === optionId)?.option);
      createPollAnswerMutation.mutate({
        input: {
          optionId: optionId,
        },
      });
    };

    return (
      <OfflineErrorModal>
        {onOnline => (
          <View style={styles.container}>
            <View style={styles.inner}>
              <Icon
                name={pollIsAnswered ? 'check' : 'question'}
                style={styles.icon}
                size={40}
                color={theme.customColors.gray50}
              />
              <Text style={styles.heading}>{pollIsAnswered ? 'Danke' : 'Umfrage'}</Text>
              <View style={styles.textContainer}>
                <Text style={styles.text}>{props.poll?.question}</Text>
              </View>
              {!pollIsAnswered ? (
                props.poll?.pollOptions?.map(option => (
                  <Button
                    key={option.id}
                    onPress={() => {
                      if (!createPollAnswerMutation.isLoading) {
                        onOnline(() => answerPoll(option.id));
                      }
                    }}
                    disabled={pollIsAnswered || createPollAnswerMutation.isLoading}
                    style={styles.button}
                    uppercase={false}
                    textColor={theme.customColors.textWhite}
                    labelStyle={styles.buttonText}
                  >
                    {option.option}
                  </Button>
                ))
              ) : (
                <Text style={[styles.text, { color: theme.customColors.textDark }]}>
                  Ihre Antwort: {props.poll?.answerOfUser?.option ?? selectedOption}
                </Text>
              )}
            </View>
          </View>
        )}
      </OfflineErrorModal>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      flex: 1,
      padding: 10,
    },
    inner: {
      paddingHorizontal: '15%',
      paddingVertical: 30,
      width: '100%',
      height: '100%',
      backgroundColor: theme.customColors.downloadableBackground,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: { marginBottom: 12 },
    textContainer: {
      width: '100%',
      flex: 1,
      flexWrap: 'wrap',
    },
    buttonContainer: {
      width: '100%',
      height: 50,
    },
    button: {
      borderRadius: 5,
      width: '100%',
      backgroundColor: theme.customColors.primary,
      marginBottom: 10,
    },
    buttonText: {
      fontWeight: 'bold',
      fontSize: 15,
      margin: 0,
    },
    text: {
      width: '100%',
      color: theme.customColors.textGray,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
    heading: {
      width: '100%',
      color: theme.customColors.textDark,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 'bold',
    },
  });
