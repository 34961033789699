import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteChatRoomMessageV2Mutation,
  useForwardChatRoomMessagesMutation,
} from '../../../../graphql/operations';
import { Log } from '../../../utils/Log';
import { ExtendedMessage } from '../types';
import { alert } from '../../../utils/alert';
import { IForwardMessages } from './useForwardMessages';
import { toast } from '../../../utils/toast/toast';

export const useChatMutations = () => {
  const deleteChatMessageMutation = useDeleteChatRoomMessageV2Mutation();
  const forwardChatMessageMutation = useForwardChatRoomMessagesMutation();
  const queryClient = useQueryClient();
  const deleteMessage = async (messageToDelete: ExtendedMessage) => {
    try {
      if (!messageToDelete) {
        throw new Error('message to delete is not set');
      }
      if (!queryClient) {
        throw new Error('queryClient not there');
      }
      await deleteChatMessageMutation.mutateAsync({
        messageId: messageToDelete._id as string,
      });
    } catch (e) {
      const error = e as Error;
      Log.error(error);
      alert(
        'Es tut uns leid, es ist ein Fehler aufgetreten.',
        'Bitte versuchen Sie es später noch einmal.',
      );
    }
  };
  const forwardMessages = async ({
    chatMessageIds,
    forwardedTo: { chatRoomIds, contactIds },
    onSuccess,
  }: IForwardMessages) => {
    try {
      const { forwardChatRoomMessages } = await forwardChatMessageMutation.mutateAsync({
        input: {
          chatRoomIds,
          userIds: contactIds,
          messageIds: chatMessageIds,
        },
      });
      onSuccess?.(forwardChatRoomMessages?.numberOfFailedDeliveries ?? 0);
    } catch (e) {
      const error = e as Error;
      Log.error(error);
      toast('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.');
    }
  };

  return {
    deleteMessage,
    forwardMessages,
    isDeletingMessage: deleteChatMessageMutation.isLoading,
    isForwardingMessages: forwardChatMessageMutation.isLoading,
  };
};
