import { useState } from 'react';
import { PreviewItem, PreviewItemType } from '../types';
import { translateFeature } from '../../../utils/translateFeature';
import { Feature } from '../../../../graphql/operations';
import uuid from 'react-native-uuid';
import { SelectedForm, SelectedInfoPage } from '../../Common/Media/types';

type ISetPreviewOverlay = {
  recipientName: string;
  fileSize?: number;
};

type ISetPreviewOverlayForDocument = ISetPreviewOverlay & {
  documentName: string;
};

type PreviewImage = {
  imageSource: string;
  fileSize?: number;
  height?: number;
  width?: number;
};

type ISetPreviewOverlayForImage = ISetPreviewOverlay & {
  previewImages: PreviewImage[];
};

type ISetPreviewOverlayForFormsOrInfoPages = ISetPreviewOverlay & {
  feature: Feature.Infopage | Feature.Form;
  items: Array<SelectedForm | SelectedInfoPage>;
};

export const usePreviewOverlay = () => {
  const [isVisible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState<string>();
  const [items, setItems] = useState<PreviewItem[]>([]);
  const [actionText, setActionText] = useState('');

  const setPreviewOverlayForDocument = ({
    recipientName,
    documentName,
    fileSize,
  }: ISetPreviewOverlayForDocument) => {
    setVisible(true);
    setActionText(recipientName);
    setTitle('Dokument');
    setDescription(`Senden an: ${recipientName}`);
    setItems([
      {
        type: PreviewItemType.document,
        title: documentName,
        itemId: uuid.v4().toString(),
        fileSize,
      },
    ]);
  };

  const setPreviewOverlayForImages = ({
    recipientName,
    previewImages,
  }: ISetPreviewOverlayForImage) => {
    setVisible(true);
    setActionText(recipientName);
    const isMultiple = previewImages.length > 1;
    setTitle(`Bild${isMultiple ? 'er' : ''}`);
    setDescription(`Senden an: ${recipientName}`);
    setItems(
      previewImages.map(({ imageSource, height, width, fileSize }) => ({
        type: PreviewItemType.image,
        source: imageSource,
        title: 'Bild',
        itemId: uuid.v4().toString(),
        fileSize,
        height,
        width,
      })),
    );
  };

  const setPreviewOverlayForFeature = ({
    feature,
    recipientName,
    items: previewItems,
  }: ISetPreviewOverlayForFormsOrInfoPages) => {
    setVisible(true);
    setActionText(recipientName);
    const isMultiple = items.length > 1;
    const translatedFeature = translateFeature({ feature, plural: isMultiple });
    setTitle(translatedFeature);
    setDescription(`Senden an: ${recipientName}`);
    setItems(
      previewItems.map(({ id, title: itemTitle }) => ({
        type: feature === Feature.Infopage ? PreviewItemType.infoPage : PreviewItemType.form,
        title: itemTitle,
        itemId: id,
      })),
    );
  };

  const removeItem = (itemId: string) => {
    setItems(items.filter(item => item.itemId !== itemId));
  };

  const reset = () => {
    setVisible(false);
    setActionText('');
    setTitle('');
    setDescription(undefined);
    setItems([]);
  };

  return {
    isVisible,
    title,
    description,
    items,
    actionText,
    reset,
    removeItem,
    setPreviewOverlayForDocument,
    setPreviewOverlayForImages,
    setPreviewOverlayForFeature,
  };
};
