import { MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment';
import React, { useMemo } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { TouchableRipple, Text } from 'react-native-paper';
import { ListInquiriesQuery } from '../../../../../graphql/operations';
import { useAppTheme } from '../../../../styles/theme';
import { themeProp } from '../../../../types/main';

type ListItemProps = {
  id: string;
  title: string;
  relativeOnInquiry: ListInquiriesQuery['listInquiries'][number]['relativeOnInquiry'];
  createdAt: Date;
  hasUnread?: boolean;
  onPress: (id: string) => void;
};

export const InquiryListItem = ({
  id,
  title,
  createdAt,
  relativeOnInquiry,
  hasUnread,
  onPress,
}: ListItemProps) => {
  const theme = useAppTheme();
  const { width } = useWindowDimensions();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  return (
    <TouchableRipple
      onPress={() => onPress(id)}
      rippleColor={theme.customColors.lightRipple}
      style={styles.itemContainer}
    >
      <View style={styles.outerContainer}>
        {hasUnread ? <View style={styles.badge} /> : <View style={styles.dummyBadge} />}
        <View style={styles.textWrapper}>
          <View style={styles.rowCointainer}>
            <Text numberOfLines={1} style={styles.infoText}>
              {`${relativeOnInquiry?.firstname} ${relativeOnInquiry?.lastname}`} &#8226;{' '}
              {moment(createdAt, 'YYYY-MM-DD').format('LL')}
            </Text>
          </View>
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
        </View>
        <MaterialCommunityIcons name="chevron-right" size={30} style={styles.icon} />
      </View>
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp, width: number) =>
  StyleSheet.create({
    itemContainer: {
      paddingVertical: 12,
      paddingHorizontal: 8,
      width: width,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      backgroundColor: theme.customColors.listRowItemBackground,
      marginBottom: 1,
    },
    outerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      flex: 1,
      color: theme.customColors.text,
      fontWeight: '400',
    },
    icon: {
      color: theme.customColors.gray50,
    },
    badge: {
      marginRight: 3,
      width: 20,
      height: 20,
      backgroundColor: theme.customColors.primary,
      borderRadius: 99999,
      overflow: 'hidden',
      padding: 5,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      display: 'flex',
      alignItems: 'center',
    },
    dummyBadge: {
      marginRight: 3,
      width: 20,
      height: 20,
      padding: 5,
    },
    badgeText: {
      marginTop: -5,
      color: theme.customColors.pureWhite,
    },
    infoText: {
      fontSize: 12,
      fontWeight: '400',
      color: theme.customColors.gray40,
      padding: 1,
      marginRight: 4,
    },
    rowCointainer: {
      flexDirection: 'row',
      flexShrink: 1,
    },
    textWrapper: {
      width: '88%',
    },
  });
