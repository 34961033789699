import { useState } from 'react';
import { SelectedForm, SelectedInfoPage } from '../types';

type Selection = SelectedForm | SelectedInfoPage;

export const useLinkList = <T extends Selection>() => {
  const [selections, setSelections] = useState<Array<T>>([]);

  const append = (newSelection: T) => {
    setSelections([...selections, newSelection]);
  };

  const remove = (selectionId: string) => {
    setSelections(selections.filter(({ id }) => id !== selectionId));
  };

  const clear = () => {
    setSelections([]);
  };

  return { selections, setSelections, append, remove, clear };
};
