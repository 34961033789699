import { Log } from '../../utils/Log';
import { getOs, saveFileToDeviceIos, saveFileToDeviceAndroid, openFileUri } from '../../utils';
import { toast } from '../../utils/toast/toast';
import { saveImageToLibrary } from '../../utils/saveImageToLibrary';
import { requestLibraryPermissions } from '../../utils/requestLibraryPermissions';

export interface ISave {
  fileName?: string;
  uri: string;
  mimeType: 'application/pdf' | 'image/jpeg' | 'image/png';
}

export const useSaveFileToDevice = () => {
  const saveOnIos = async ({ uri }: ISave) => {
    try {
      await saveFileToDeviceIos({ uri });
    } catch (error) {
      const err = error as Error;
      toast('Dokument konnte nicht freigegeben werden.');
      throw new Error(err.message);
    }
  };

  const saveOnAndroid = async ({ uri, fileName, mimeType }: ISave) => {
    try {
      await saveFileToDeviceAndroid({ uri, fileName: fileName ?? 'Dokument', mimeType });
    } catch (error) {
      const err = error as Error;
      if (err.message.includes('No permission in download directory')) {
        toast('Die Erlaubnis zum Speichern des Dokuments im Gerät wurde nicht erteilt.');
        return;
      }
      toast('Das Dokument konnte nicht auf dem Gerät gespeichert werden.');
      throw new Error(err.message);
    }
  };

  const saveOnWeb = async ({ uri }: ISave) => {
    try {
      await openFileUri({
        uri,
      });
    } catch (error) {
      const err = error as Error;
      toast('Das Dokument konnte nicht auf dem Gerät gespeichert werden.');
      throw new Error(err.message);
    }
  };

  const saveFileToDevice = async ({ uri, fileName, mimeType }: ISave) => {
    try {
      const isImage = mimeType === 'image/jpeg' || mimeType === 'image/png';
      if (isImage) {
        const { isGranted } = await requestLibraryPermissions();
        if (!isGranted) {
          toast(
            'Konnte nicht gespeichert werden. Bitte erlauben Sie LUCI in den Geräteeinstellungen, Bilder in der Medienbibliothek zu speichern.',
          );
          return;
        }
        await saveImageToLibrary(uri);
        toast('Gespeichert!');
        return;
      }
      const os = getOs();
      switch (os) {
        case 'ios':
          await saveOnIos({ uri, fileName, mimeType });
          break;
        case 'android':
          await saveOnAndroid({ uri, fileName, mimeType });
          break;
        case 'web':
          await saveOnWeb({ uri, fileName, mimeType });
          break;
        default:
          throw new Error('operating system is not supported');
      }
    } catch (error) {
      const err = error as Error;
      const message = `an error occurred while saving file: ${err.message}`;
      Log.error(error, {
        message,
      });
      throw new Error(message);
    }
  };

  return {
    saveFileToDevice,
  };
};
