import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-paper';
import Icon from '@expo/vector-icons/SimpleLineIcons';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import { AppTheme, useAppTheme } from '../../styles/theme';

interface IConfirmInput {
  onAccept: () => void;
  isLoading: boolean;
  hasConfirmed: boolean;
}

export const ReadConfirmation = (props: IConfirmInput) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <OfflineErrorModal>
      {onOnline => (
        <View style={styles.container}>
          <View style={styles.inner}>
            <Icon
              name={props.hasConfirmed ? 'check' : 'envelope-letter'}
              style={styles.icon}
              size={40}
              color={theme.customColors.gray50}
            />
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                {props.hasConfirmed
                  ? 'Vielen Dank für Ihre Bestätigung.'
                  : 'Bitte bestätigen Sie, dass Sie diese Nachricht gelesen haben.'}
              </Text>
            </View>
            {!props.hasConfirmed && (
              <Button
                onPress={() => {
                  if (!props.isLoading) {
                    onOnline(props.onAccept);
                  }
                }}
                disabled={props.hasConfirmed}
                loading={props.isLoading}
                style={styles.button}
                uppercase={false}
                labelStyle={styles.buttonText}
                textColor={theme.customColors.textWhite}
              >
                Bestätigen
              </Button>
            )}
          </View>
        </View>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      flex: 1,
      padding: 20,
    },
    inner: {
      paddingHorizontal: '15%',
      paddingVertical: 30,
      width: '100%',
      height: '100%',
      backgroundColor: theme.customColors.downloadableBackground,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: { marginBottom: 12 },
    textContainer: {
      width: '100%',
      flex: 1,
      flexWrap: 'wrap',
    },
    buttonContainer: {
      width: '100%',
      height: 50,
    },
    button: {
      borderRadius: 5,
      width: '100%',
      backgroundColor: theme.customColors.primary,
    },
    buttonText: {
      fontWeight: 'bold',
      fontSize: 15,
      margin: 0,
    },
    text: {
      width: '100%',
      color: theme.customColors.textGray,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
  });
