import * as FileSystem from 'expo-file-system';

interface IDownloadFileFromUrl {
  url: string;
  fileName: string;
}

export const downloadFileFromUrl = async ({ url, fileName }: IDownloadFileFromUrl) => {
  try {
    const localPath = `${FileSystem.documentDirectory}${fileName}`.replace(/\s/g, '_');
    return await FileSystem.downloadAsync(url, localPath);
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
