import React, { useEffect, useState } from 'react';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { StyleProp, View, ViewStyle, StyleSheet, SafeAreaView } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Log } from '../../utils/Log';
import { AutoHeightImage } from '../../components/AutoHeightImage';
import { useTenantContext } from '../../hooks/tenant';

type DrawerBarPropTypes = DrawerContentComponentProps & {
  headerStyle: StyleProp<ViewStyle>;
  displayTenantLogo?: React.ReactNode;
  children?: React.ReactNode;
};

export const DrawerBar = ({
  headerStyle,
  navigation,
  children,
  displayTenantLogo,
}: DrawerBarPropTypes) => {
  const { tenant } = useTenantContext();
  const path = tenant?.logoS3Path;
  const [logo, setLogo] = useState();

  useEffect(() => {
    if (path && displayTenantLogo) {
      const downloadLogo = async (filePath: string) => {
        const staticRes = await fetch(
          process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${filePath}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          },
        );
        const result = await staticRes.json();
        setLogo(result);
      };

      downloadLogo(path).catch(() =>
        Log.warning(
          'an error occurred while downloading the tenant logo for the generic drawer bar',
        ),
      );
    }
  }, [process.env.EXPO_PUBLIC_API_URL, path, displayTenantLogo]);

  return (
    <SafeAreaView testID="drawerbar" style={headerStyle}>
      <View style={styles.container}>
        {displayTenantLogo && logo && (
          <TouchableOpacity onPress={() => navigation.navigate('News')}>
            <AutoHeightImage source={{ uri: logo }} width={125} style={styles.tenantLogo} />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.actionbarContainer}>{children}</View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  tenantLogo: {},
  actionbarContainer: {
    flex: 2,
    maxWidth: 110,
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: 5,
    marginLeft: 83,
  },
});
