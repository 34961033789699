import { TransitionPresets } from '@react-navigation/stack';
import React from 'react';
import { useReloginFlowOnExpiredRefreshToken } from '../../hooks/useReloginFlowOnExpiredRefreshToken';
import { AppStack } from '../AppNavigator';
import { RightDrawerNavigator } from './DrawerNavigator';
import { ReloginNavigator } from './ReloginNavigator';
import { usePreventScreenCapture } from '../../hooks/util/usePreventScreenCapture';
import { useStore } from '../../stores/store';
import { LoginScreen } from '../../screens/Auth/LoginScreen';
import { NewPassword } from '../../screens/Auth/NewPassword';
import { CheckInboxScreen } from '../../screens/Auth/CheckInboxScreen';
import { ResetPassword } from '../../screens/Auth/ResetPassword';
import { LoginLegalScreen } from '../../components/Auth/LoginLegalScreen';
import { LegalScreen } from '../../screens/Informational/LegalScreen';
import { ConsentScreen } from '../../screens/Informational/ConsentScreen';
import { PrivacyScreen } from '../../screens/Informational/PrivacyScreen';
import { LoginHelpPage } from '../../screens/Auth/LoginHelpPage';
import { MaintenanceScreen } from '../../components/Maintenance/MaintenanceScreen';
import { ConfirmationCode } from '../../screens/Auth/ConfirmationCode';
import { TenantInputSelectionScreen } from '../../screens/Tenancy/TenantInputSelectionScreen';
import { UpdateRequiredScreen } from '../../screens/Tenancy/UpdateRequiredScreen';
import {
  UseMaintenanceStatusOfTenantHookLocation,
  useMaintenanceStatusOfTenant,
} from '../../components/Maintenance/hooks/useMaintenanceStatusOfTenant';
import { useNewAppVersionRequired } from '../../hooks/useNewAppVersionRequired';
import { Animated as AnimatedRN, Platform } from 'react-native';
import { BlurView } from 'expo-blur';
import * as SplashScreen from 'expo-splash-screen';
import { useAnimatedBlur } from '../../hooks/useAnimatedBlur/useAnimatedBlur';

const AnimatedBlurView = AnimatedRN.createAnimatedComponent(BlurView);

export const RootStackNavigator = () => {
  const isUserLoggedIn = useStore(s => s.isUserLoggedIn());
  useReloginFlowOnExpiredRefreshToken();
  usePreventScreenCapture();
  useNewAppVersionRequired();
  useMaintenanceStatusOfTenant(UseMaintenanceStatusOfTenantHookLocation.HomeTabNavigator);
  const { blurValue } = useAnimatedBlur({
    intensity: { from: 50, to: 0 },
    whileAnimating: SplashScreen.hideAsync,
  });

  return (
    <>
      <AppStack.Navigator initialRouteName={isUserLoggedIn ? 'Home' : 'TenantScreen'}>
        {isUserLoggedIn ? (
          <>
            <AppStack.Group screenOptions={{ headerShown: false }}>
              <AppStack.Screen name="Home" component={RightDrawerNavigator} />
            </AppStack.Group>
            <AppStack.Group
              screenOptions={{ presentation: 'modal', gestureEnabled: false, headerShown: false }}
            >
              <AppStack.Screen
                name="ReloginFlow"
                component={ReloginNavigator}
                options={{
                  presentation: 'transparentModal',
                  gestureEnabled: false,
                  ...TransitionPresets.ModalPresentationIOS,
                }}
              />
            </AppStack.Group>
          </>
        ) : (
          <>
            <AppStack.Screen
              name="TenantScreen"
              options={{
                title: 'Login',
                headerShown: false,
              }}
              component={TenantInputSelectionScreen}
            />
            <AppStack.Screen
              name="Login"
              options={{
                title: 'Login',
                headerShown: false,
              }}
              component={LoginScreen}
            />
            <AppStack.Screen
              name="NewPassword"
              options={{
                title: 'Login',
              }}
              component={NewPassword}
            />
            <AppStack.Screen
              name="ResetPassword"
              options={{
                title: 'Login',
              }}
              component={ResetPassword}
            />
            <AppStack.Screen
              name="LegalFlow"
              options={{ title: 'Rechtliches' }}
              component={LoginLegalScreen}
            />
            <AppStack.Screen
              name="Legal"
              options={{ title: 'Rechtliches' }}
              component={LegalScreen}
            />
            <AppStack.Screen
              name="Consent"
              options={{
                title: 'Nutzungsbedingungen',
              }}
              component={ConsentScreen}
            />
            <AppStack.Screen
              name="Privacy"
              options={{
                title: 'Datenschutz',
              }}
              component={PrivacyScreen}
            />
            <AppStack.Screen
              name="LoginHelpPage"
              options={{
                title: 'Hilfe',
              }}
              component={LoginHelpPage}
            />
          </>
        )}
        <AppStack.Screen
          name="UpdateRequiredScreen"
          options={{
            headerShown: false,
            headerTitle: 'Maintenance',
            presentation: 'transparentModal',
            gestureEnabled: false,
            ...TransitionPresets.ModalPresentationIOS,
          }}
          component={UpdateRequiredScreen}
        />
        <AppStack.Screen
          name="Maintenance"
          options={{
            headerShown: false,
            headerTitle: 'Maintenance',
            presentation: 'transparentModal',
            gestureEnabled: false,
            ...TransitionPresets.ModalPresentationIOS,
          }}
          component={MaintenanceScreen}
        />
        <AppStack.Screen
          name="CheckInbox"
          options={{
            title: 'Login',
          }}
          component={CheckInboxScreen}
        />
        <AppStack.Screen
          name="ConfirmationCode"
          options={{
            title: 'Login',
          }}
          component={ConfirmationCode}
        />
      </AppStack.Navigator>
      {/*
        a bug causes this to not render in android, for now it's disabled:
        https://github.com/expo/expo/issues/23239#issuecomment-1625514607
      */}
      {Platform.OS !== 'web' && Platform.OS !== 'android' && (
        <AnimatedBlurView
          intensity={blurValue.current}
          style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
          pointerEvents="none"
        />
      )}
    </>
  );
};
