import React, { useMemo, useState, memo, useCallback } from 'react';
import { useWindowDimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { maxContentAreaWidth } from '../../../router/navigators/ContentNavigator';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ImageLoader } from '../../../components/ImageLoader/ImageLoader';
import { Image, ImageLoadEventData } from 'expo-image';
import { Shimmer } from '../../../components/Shimmer/Shimmer';

type ChatImageMessagePropTypes = {
  type?: UploadType;
  imagePath: string;
  onLongPress: () => void;
  onPress?: (imageUrl?: string) => void;
  isDownloadConfirmationNeeded?: boolean;
};

export const ChatImageMessage = memo(
  ({
    type = UploadType.ChatMessage,
    onLongPress,
    onPress,
    imagePath,
    isDownloadConfirmationNeeded,
  }: ChatImageMessagePropTypes) => {
    const { width: screenWidth } = useWindowDimensions();
    const width = useMemo(
      () => (screenWidth > maxContentAreaWidth ? maxContentAreaWidth / 2 : screenWidth / 2),
      [screenWidth, maxContentAreaWidth],
    );
    const [height, setHeight] = useState((width * 9) / 16);
    const styles = useMemo(
      () =>
        createStyles({
          width,
          height,
        }),
      [height, width],
    );

    const onPressImage = useCallback((uri: string) => onPress?.(uri), [onPress]);

    const onLoad = useCallback(
      (event: ImageLoadEventData) => {
        setHeight((event.source.height / event.source.width) * width);
      },
      [width],
    );

    const renderOnLoad = useCallback(
      (uri: string) => (
        <TouchableOpacity
          onPress={() => onPressImage(uri)}
          onLongPress={onLongPress}
          style={styles.container}
          activeOpacity={0.8}
        >
          <Image
            onLoad={onLoad}
            style={styles.image}
            source={uri}
            contentFit="contain"
            transition={250}
          />
        </TouchableOpacity>
      ),
      [onPress, height],
    );

    const renderOnErrorOrWhileLoading = useCallback(
      () => <Shimmer height={height} width={width} radius={6} />,
      [height, width],
    );

    return (
      <ImageLoader
        isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
        storageFilename={imagePath}
        type={type}
        renderOnLoad={renderOnLoad}
        renderOnError={renderOnErrorOrWhileLoading}
        renderWhileLoading={renderOnErrorOrWhileLoading}
      />
    );
  },
);

const createStyles = (image: { height: number; width: number }) =>
  StyleSheet.create({
    container: {
      marginVertical: 6,
    },
    image: {
      marginTop: 2,
      width: image.width,
      height: image.height,
      borderRadius: 6,
    },
  });
