import { ImageSourcePropType, ImageURISource } from 'react-native';
import { downloadFile } from '../../components/Common/FileUpload/api';
import { getUri } from './getUri';
import { Log } from '../../utils/Log';

const loadSources = (sources: ImageURISource[], uploadType: 'static' | string) => {
  const uris = sources.map(getUri);
  if (uris.includes(undefined)) {
    return undefined;
  }
  return Promise.all(
    uris.map(uri => {
      if (uri!.needsRequest) {
        if (uploadType === 'static') {
          return requestUriStaticFile(uri!.uri as string);
        } else {
          return requestDownloadFile(uri!.uri as string, uploadType);
        }
      }
      return Promise.resolve(uri);
    }),
  );
};

export const loadSource = (source: ImageSourcePropType | string, uploadType: 'static' | string) => {
  if (Array.isArray(source)) {
    return loadSources(source, uploadType);
  }
  const uri = getUri(source);
  if (!uri) {
    return undefined;
  }
  if (uri.needsRequest) {
    if (uploadType === 'static') {
      return requestUriStaticFile(uri.uri as string);
    } else {
      return requestDownloadFile(uri.uri as string, uploadType);
    }
  }
  return uri.uri;
};

const requestUriStaticFile = async (uri: string) => {
  try {
    const staticRes = await fetch(process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${uri}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return await staticRes.json();
  } catch (e) {
    Log.error(e, {
      message: 'cannot fetch image error',
      data: { uri: process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${uri}` },
    });
  }
};

const requestDownloadFile = async (uri: string, uploadType: 'static' | string) => {
  try {
    return await downloadFile({
      s3Path: uri,
      uploadType: uploadType,
    });
  } catch (e) {
    Log.error(e, { message: 'cannot download file', data: { uri, uploadType } });
  }
};
