import { Snackbar, TextInput, Text, Subheading, Appbar } from 'react-native-paper';
import {
  Keyboard,
  Platform,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  SafeAreaView,
  KeyboardAvoidingView,
} from 'react-native';
import React, { useState, useMemo } from 'react';
import * as Yup from 'yup';

import Constants from 'expo-constants';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { useSendFeedbackMutation } from '../../../../graphql/operations';
import { useStore } from '../../../stores/store';
import { useNavigation } from '@react-navigation/native';
import { themeProp } from '../../../types/main';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { Formik } from 'formik';
import { useFocusFields } from '../../../hooks';
import { useAppTheme } from '../../../styles/theme';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { Log } from '../../../utils/Log';
import { AppBar } from '../../../components/AppBar/AppBar';

export type HelpFeedbackScreenProps = StackScreenProps<AppNavigatorParamList, 'HelpForm'>;

export const HelpFeedbackScreen = (props: HelpFeedbackScreenProps) => {
  const sendFeedback = useSendFeedbackMutation();
  const userProfile = useStore(s => s.userProfile);
  const tenantName = useStore(s => s.tenantName);
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { collectFocusRef, focusNext } = useFocusFields();

  const [snackMessage, setSnackMessage] = useState<null | string>(null);
  const navigation = useNavigation<HelpFeedbackScreenProps['navigation']>();

  const sendFeedbackSchema = Yup.object({
    title: Yup.string().trim().required('Betreff benötigt'),
    content: Yup.string().trim().required('Beschreibung benötigt'),
  });

  const initialValues: Yup.InferType<typeof sendFeedbackSchema> = {
    title: props.route.params.category ?? '',
    content: '',
  };

  const handleSend = async (values: typeof initialValues) => {
    const formattedDescription = `${'# App Report' + '\n>>>\n'}${values.content}\n
tenantName: ${tenantName}
    >>>\n## UserProfile\n\`\`\`json\n${JSON.stringify(
      userProfile || { attributes: 'no user logged in' },
      null,
      2,
    )}\n\`\`\`\n## Platform\n\`\`\`json\n${JSON.stringify(
      Platform,
      null,
      2,
    )}\n\`\`\`\n## Constants\n\`\`\`json\n${JSON.stringify(Constants, null, 2)}\n\`\`\``;

    try {
      sendFeedback.mutate(
        {
          input: {
            title: values.title,
            content: formattedDescription,
          },
        },
        {
          onSuccess: () => {
            navigation.navigate('HelpSuccess');
          },
          onError: () => {
            setSnackMessage('Feedback senden fehlgeschlagen!');
          },
        },
      );
    } catch (error) {
      Log.error(error, {
        message: `Failed to send feedback from user with id: ${userProfile?.userId}`,
      });
    }
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <AppBar
            title="Externe Hilfe"
            renderActionsLeftOfTitle={() => (
              <Appbar.BackAction onPress={() => navigation.goBack()} />
            )}
          />
          <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}>
            <SafeAreaView style={styles.container}>
              <KeyboardAvoidingView
                style={styles.formContainer}
                behavior="padding"
                keyboardVerticalOffset={Platform.OS === 'ios' ? 85 : 0}
              >
                <View style={styles.card}>
                  <Formik
                    onSubmit={handleSend}
                    validationSchema={sendFeedbackSchema}
                    initialValues={initialValues}
                  >
                    {({ handleSubmit, errors, values, handleBlur, handleChange, isValid }) => (
                      <>
                        <View
                          style={{
                            width: '90%',
                          }}
                        >
                          <Subheading style={{ marginBottom: 14 }}>Betreff</Subheading>
                          <TextInput
                            mode="flat"
                            error={!!errors.title}
                            value={values.title}
                            onChangeText={handleChange('title')}
                            onBlur={handleBlur('title')}
                            testID="help-title-input"
                            blurOnSubmit
                            returnKeyType="next"
                            onSubmitEditing={() => focusNext('content')}
                            style={{ backgroundColor: theme.customColors.textInputBackground }}
                          />
                          {errors.title && <Text style={styles.error}>{errors.title}</Text>}
                        </View>
                        <View
                          style={{
                            width: '90%',
                          }}
                        >
                          <Subheading style={{ marginBottom: 14 }}>Beschreibung</Subheading>
                          <TextInput
                            ref={collectFocusRef('content')}
                            placeholder="Beschreibung"
                            mode="flat"
                            style={styles.input}
                            error={!!errors.content}
                            value={values.content}
                            onChangeText={handleChange('content')}
                            onBlur={handleBlur('content')}
                            multiline
                            numberOfLines={5}
                            testID="help-content-input"
                            blurOnSubmit
                            returnKeyType="send"
                            onSubmitEditing={() => isValid && handleSubmit()}
                          />
                          {errors.content && <Text style={styles.error}>{errors.content}</Text>}
                          <PrimaryButton
                            buttonStyle={styles.button}
                            disabled={sendFeedback.isLoading}
                            onPress={() => onOnline(handleSubmit)}
                            text="Absenden"
                          />
                        </View>
                      </>
                    )}
                  </Formik>
                </View>
              </KeyboardAvoidingView>
            </SafeAreaView>
          </TouchableWithoutFeedback>
          <Snackbar
            duration={4000}
            visible={snackMessage !== null}
            onDismiss={() => setSnackMessage(null)}
          >
            {snackMessage}
          </Snackbar>
        </>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 30,
      backgroundColor: theme.colors.background,
    },
    formContainer: {
      width: '100%',
      alignItems: 'center',
    },
    card: {
      alignItems: 'center',
      paddingVertical: 20,
      paddingHorizontal: 10,
      marginVertical: 10,
      backgroundColor: theme.customColors.background3,
      width: '84%',
      borderRadius: theme.roundness,
    },
    input: {
      maxHeight: 110,
      backgroundColor: theme.customColors.textInputBackground,
    },
    button: {
      alignSelf: 'center',
      marginTop: 20,
    },
    error: {
      color: theme.customColors.error,
      marginTop: 0,
    },
  });
