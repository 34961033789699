import { ImagePickerResult } from 'expo-image-picker';
import { requestCameraImages } from '../requestCameraImages/requestCameraImages';
import { requestGalleryImages } from '../requestGalleryImages/requestGalleryImages';

export enum RequestImageType {
  Gallery = 'gallery',
  Camera = 'camera',
}

export const requestImages = (
  type: RequestImageType,
  includeVideos?: boolean,
): Promise<ImagePickerResult | undefined> => {
  switch (type) {
    case RequestImageType.Camera:
      return requestCameraImages();
    case RequestImageType.Gallery:
      return requestGalleryImages(includeVideos);
  }
};
