import React, { useEffect, useMemo, useState } from 'react';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { NativeScrollEvent, ScrollView, StyleSheet, View } from 'react-native';
import { Searchbar } from 'react-native-paper';
import { FullScreenModal } from '../../../../components/FullScreenModal';
import { useResponsiveDrawer } from '../../../../router/drawer/hooks/useResponsiveDrawer';
import { FileGallery } from '../../../../components/FileGallery/FileGallery';
import { File, FileOptionalParams } from '../../../../components/FileGallery/types';
import { useStockImageApi, useStockImageSearch } from '../hooks';
import { mapStockImagePageToFiles } from '../utils';
import { isCloseToBottom } from '../../../../utils/isCloseToBottom';
import { useDebounce } from 'use-debounce';
import { ImageViewerModal } from '../../../../components/Common/Modals/ImageViewerModal';
import { useImageViewerModal } from '../../../../components/Common/Modals/hooks/useImageViewerModal';
import { NoDataRefresh } from '../../../../stories/Loading/NoDataRefresh';

type StockImageGalleryModalProps = {
  show: boolean;
  onPressCancel: () => void;
  appendNewFiles: (items: File<FileOptionalParams>[]) => void;
};

export const StockImageGalleryModal = ({
  show,
  onPressCancel,
  appendNewFiles,
}: StockImageGalleryModalProps) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [files, setFiles] = useState<File<FileOptionalParams>[]>([]);
  const [selectedFile, setSelectedFile] = useState<File<FileOptionalParams>>();

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const { getPage, getInitialSearchPage, isLoading, isLastPage, isError } = useStockImageApi();

  const getFirstPage = async () => {
    const firstPage = await getPage(true);
    if (firstPage) {
      setFiles(mapStockImagePageToFiles(firstPage));
    }
  };

  useStockImageSearch({
    searchValue: debouncedSearchValue,
    setFiles: setFiles,
    getFirstPage: getFirstPage,
    getInitialSearchPage: getInitialSearchPage,
  });

  useEffect(() => {
    if (show) {
      void getFirstPage();
    }
  }, [show]);

  const onGalleryScroll = async (scrollEvent: NativeScrollEvent) => {
    if (isCloseToBottom(scrollEvent, 200) && !isLastPage) {
      const newPage = await getPage();
      if (newPage) {
        setFiles([...files, ...mapStockImagePageToFiles(newPage)]);
      }
    }
  };

  const closeModal = () => {
    setSelectedFile(undefined);
    setSearchValue('');
    onPressCancel();
  };

  return (
    <FullScreenModal show={show} title="Bild aus Stockfoto-Bibliothek" onClose={closeModal}>
      <FullScreenModal.Container>
        <FullScreenModal.Content>
          {isError ? (
            <NoDataRefresh
              text="Die Bilder aus der Stockfoto-bibliothek konnten leider nicht geladen werden. Bitte versuchen Sie es erneut."
              onPress={async () => await getFirstPage()}
              isLoading={isLoading}
            />
          ) : (
            <View style={styles.screenContainer}>
              <View style={styles.searchBarContainer}>
                <Searchbar
                  accessibilityRole="search"
                  placeholder="Suche"
                  onChangeText={value => setSearchValue(value)}
                  value={searchValue}
                  style={styles.searchBar}
                  clearIcon="backspace-outline"
                  onClearIconPress={() => setSearchValue('')}
                />
              </View>
              <ScrollView
                style={styles.scrollView}
                contentContainerStyle={styles.scrollViewContainer}
                onScroll={async ({ nativeEvent }) => await onGalleryScroll(nativeEvent)}
                scrollEventThrottle={400}
              >
                <FileGallery
                  files={files}
                  onTilePress={file => {
                    setSelectedFile(file);
                    setImageViewerModalImages([{ url: file.uri }]);
                    setImageViewerModalVisible(true);
                  }}
                  allowsDeletion={false}
                  isLoadingMore={isLoading}
                />
              </ScrollView>
            </View>
          )}
          <ImageViewerModal
            visible={imageViewerModalVisible}
            setVisible={setImageViewerModalVisible}
            imageUrls={imageViewerModalImages}
            hasAddButton
            onAddPress={() => {
              appendNewFiles(selectedFile ? [selectedFile] : []);
              setImageViewerModalVisible(false);
              closeModal();
            }}
            displaySaveButton={false}
          />
        </FullScreenModal.Content>
      </FullScreenModal.Container>
    </FullScreenModal>
  );
};

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    screenContainer: {
      width: width,
      paddingVertical: 10,
      height: '100%',
    },
    searchBarContainer: {
      height: 40,
      marginBottom: 30,
      paddingHorizontal: 40,
    },
    searchBar: {
      borderRadius: 12,
      backgroundColor: theme.customColors.textInputBackground,
    },
    scrollView: {
      width: width,
    },
    scrollViewContainer: {
      width: width,
    },
  });
