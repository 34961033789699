export enum PreviewItemType {
  form = 'form',
  infoPage = 'infoPage',
  image = 'image',
  document = 'document',
}

export type PreviewItem = {
  type: PreviewItemType;
  title: string;
  itemId: string;
  source?: string;
  fileSize?: number;
  height?: number;
  width?: number;
};
