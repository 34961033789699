import React, { useMemo } from 'react';
import { TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { StockImageMetadata } from '../../../../graphql/operations';
import { Text } from 'react-native-paper';
import { useAppTheme } from '../../../styles/theme';
import { themeProp } from '../../../types/main';

type ImageAttributionLinkPorps = {
  metaData: StockImageMetadata;
};

export const ImageAttributionLink = ({ metaData }: ImageAttributionLinkPorps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const openLink = async () => {
    await Linking.openURL(metaData.data.pageUrl);
  };

  return (
    <TouchableOpacity style={styles.linkContainer} onPress={async () => await openLink()}>
      <Text style={styles.fontSize}>
        {`Foto von ${metaData.data.author} via `}
        <Text style={styles.underline}>{metaData.api + ' '}</Text>
      </Text>
    </TouchableOpacity>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    linkContainer: {
      position: 'absolute',
      backgroundColor: theme.customColors.imageBackground,
      paddingHorizontal: 3,
      paddingVertical: 1,
      borderRadius: theme.roundness,
      bottom: 5,
      alignSelf: 'center',
      maxWidth: '99%',
      zIndex: 1,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2,
    },
    fontSize: {
      fontSize: 11,
    },
    underline: {
      textDecorationLine: 'underline',
    },
  });
