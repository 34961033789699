import { useReducer, useState } from 'react';
import { PopupProps } from '../../components/Popup';
type State = {
  title: string;
  text: string;
  onDismiss(): void;
};

export type Popup = {
  isOpen: boolean;
  toggleOpen: () => void;
  popup: PopupProps;
  setPopupDetails: (newState: Partial<State>) => Popup;
};

export const usePopup = (title: string, text: string, dismiss?: () => void): Popup => {
  const [isOpen, toggleOpen] = useReducer(previous => !previous, false);
  const [popupDetails, setPopupDetails] = useState<State>({
    title,
    text,
    onDismiss: dismiss ?? toggleOpen,
  });

  const popup: Popup = {
    isOpen,
    toggleOpen,
    popup: {
      ...popupDetails,
      isOpen,
    },
    setPopupDetails: (newState: Partial<State>) => {
      setPopupDetails(prevState => ({
        ...prevState,
        ...newState,
      }));
      return popup;
    },
  };

  return popup;
};
