import { Appbar } from 'react-native-paper';
import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';
import { PlaceholderAppBarAction } from '../AppBar/PlaceholderAppBarAction';
import { useDownloadThenSaveOtcFile } from '../../../hooks/useOtcFile/useDownloadThenSaveOtcFile';
import { useSaveFileToDevice } from '../../../hooks/useOtcFile/useSaveFileToDevice';

type PdfReaderBarPropTypes = {
  fileName: string;
  downloadable: boolean;
  downloadLink?: string;
  downloadThenSaveOtcFile?: ReturnType<typeof useDownloadThenSaveOtcFile>;
};

export const PdfReaderBar = ({
  fileName,
  downloadable,
  downloadLink,
  downloadThenSaveOtcFile,
}: PdfReaderBarPropTypes) => {
  const theme = useAppTheme();
  const navigation = useNavigation();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { saveFileToDevice } = useSaveFileToDevice();
  const { downloadThenSave, isDownloading, downloadedFileUri } = useDownloadThenSaveOtcFile();

  const onPressDownload = async () => {
    if (!downloadThenSaveOtcFile) {
      return;
    }
    if (downloadedFileUri) {
      return saveFileToDevice({ uri: downloadedFileUri, fileName, mimeType: 'application/pdf' });
    }
    if (downloadLink) {
      await downloadThenSave({
        fileName: `${fileName}.pdf`,
        url: downloadLink,
        mimeType: 'application/pdf',
      });
    }
  };

  return (
    <Appbar.Header testID="pdf-reader-appbar" style={styles.header}>
      <Appbar.BackAction onPress={() => navigation.goBack()} />
      <Appbar.Content title={fileName} titleStyle={styles.title} />
      {downloadable ? (
        <Appbar.Action
          testID="appbar-download-button"
          icon={isDownloading ? 'progress-download' : 'download'}
          disabled={isDownloading}
          animated={true}
          size={26}
          color={theme.customColors.gray40}
          onPress={onPressDownload}
        />
      ) : (
        <PlaceholderAppBarAction />
      )}
    </Appbar.Header>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    title: {
      color: theme.colors.onSurface,
      textAlign: 'center',
      justifyContent: 'center',
    },
    header: {
      backgroundColor: theme.colors.background,
    },
  });
