import { Alert } from 'react-native';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { Portal } from 'react-native-paper';
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { UserList } from '../../profiles/components/UserList';
import {
  ChatRole,
  ChatRoomsQuery,
  useCreatePrivateChatRoomMutation,
  useInfiniteChatRoomsQuery,
} from '../../../../graphql/operations';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../../../stores/store';
import { useQueryClient } from '@tanstack/react-query';
import { getChatRoomName } from '../utils/getChatRoomName';

export const NewDirectChatScreen = () => {
  const queryClient = useQueryClient();
  const userProfile = useStore(s => s.userProfile);
  const createChatMutation = useCreatePrivateChatRoomMutation();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <UserList
            onPress={user =>
              onOnline(() => {
                if (userProfile?.userId && user?.userId) {
                  const name =
                    user.firstname && user.lastname && `${user.firstname} ${user.lastname}`;
                  createChatMutation.mutate(
                    {
                      input: {
                        partnerUserProfileId: user.userId,
                      },
                    },
                    {
                      onSuccess: async response => {
                        await queryClient.invalidateQueries<ChatRoomsQuery>(
                          useInfiniteChatRoomsQuery.getKey({
                            pageInput: {
                              pageSize: 20,
                            },
                          }),
                          {
                            refetchPage: () => true,
                          },
                        );
                        navigation.navigate('ChatRoom', {
                          id: response.createPrivateChatRoom.id,
                          name: getChatRoomName(name),
                          imageUri: user.pictureOTC ?? undefined,
                          members: [
                            {
                              firstname: userProfile.firstname,
                              lastname: userProfile.lastname,
                              userId: userProfile.userId,
                              picture: userProfile.picture,
                              pictureOTC: userProfile.pictureOTC,
                            },
                            {
                              firstname: user.firstname!,
                              lastname: user.lastname!,
                              userId: user.userId,
                              picture: 'deprecated',
                              pictureOTC: user.pictureOTC,
                            },
                          ],
                          role: ChatRole.Member,
                        });
                      },
                    },
                  );
                } else {
                  Alert.alert('Es ist ein unerwarteter Fehler aufgetreten!');
                }
              })
            }
            menuIcon="chevron-right"
            appBarTitle="Neuer Chat"
          />
          <Portal>
            <LoadingBlocker visible={createChatMutation.isLoading} />
          </Portal>
        </>
      )}
    </OfflineErrorModal>
  );
};
