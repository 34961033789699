import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Feature, NewsToReadQuery } from '../../../../graphql/operations';
import { LinkButton } from '../../LinkButton/LinkButton';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';

export interface NewsLinksProps {
  formLinks?: NewsToReadQuery['newsList']['news'][number]['forms'];
  infoPageLinks?: NewsToReadQuery['newsList']['news'][number]['infoPages'];
  navigation?: StackNavigationProp<AppNavigatorParamList>;
}

export const NewsLinks = memo(({ formLinks, infoPageLinks, navigation }: NewsLinksProps) => {
  const hasFormLinks = formLinks && formLinks.length > 0;
  const hasInfoPageLinks = infoPageLinks && infoPageLinks.length > 0;
  const styles = useMemo(() => createStyles(), []);

  if (!hasFormLinks && !hasInfoPageLinks) {
    return <></>;
  }

  return (
    <View style={styles.container}>
      {hasFormLinks &&
        formLinks.map(link => (
          <LinkButton
            key={link.id}
            icon="format-list-checks"
            text={link.title}
            buttonStyle={styles.link}
            feature={Feature.Form}
            id={link.id}
            navigation={navigation}
          />
        ))}
      {hasInfoPageLinks &&
        infoPageLinks.map(link => (
          <LinkButton
            key={link.id}
            icon="file-document"
            text={link.title}
            buttonStyle={styles.link}
            feature={Feature.Infopage}
            id={link.id}
            navigation={navigation}
          />
        ))}
    </View>
  );
});

const createStyles = () =>
  StyleSheet.create({
    container: { marginVertical: 10 },
    link: { marginVertical: 5, marginHorizontal: 15, borderRadius: 5 },
  });
