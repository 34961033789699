import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, ScrollView, StyleSheet, Platform } from 'react-native';
import { Divider } from 'react-native-paper';
import { MenuScreenProps } from '../../features/chat/screens/types';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { ModernMenuItem, ModernMenuItemType } from './ModernMenuItem';

export const InformationScreenContent = () => {
  const navigation = useNavigation<MenuScreenProps['navigation']>();
  const { isRightDrawerOpen } = useResponsiveDrawer({});
  const theme = useAppTheme();
  const styles = createStyles(theme);

  const items: Array<ModernMenuItemType> = [
    {
      icon: 'text-box-check-outline',
      title: 'Fairplay Regeln',
      onPress: () => navigation.navigate('Fairplay'),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
    {
      icon: 'shield-check-outline',
      title: 'Datenschutzerklärung',
      onPress: () => navigation.navigate('Privacy'),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
    {
      icon: 'file-document',
      title: 'Nutzungsbedingungen',
      onPress: () => navigation.navigate('Consent'),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
    {
      icon: 'file-document',
      title: 'Impressum',
      onPress: () => navigation.navigate('Imprint'),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
    {
      icon: 'file-code',
      title: 'Version',
      onPress: () => navigation.navigate('Version'),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
  ];

  return (
    <View style={styles.mainContainer}>
      {!isRightDrawerOpen && (
        <Divider
          style={{
            backgroundColor: theme.customColors.borders,
            height: 1,
          }}
        />
      )}
      <ScrollView style={styles.container}>
        {items.map((x, i) => (
          <ModernMenuItem item={x} key={i} />
        ))}
        <View style={{ marginBottom: 30 }} />
      </ScrollView>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    mainContainer: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    container: {
      paddingHorizontal: 16,
      paddingTop: 0,
      backgroundColor: theme.colors.background,
    },
    divider: {
      marginTop: 20,
      backgroundColor: theme.customColors.borders,
    },
  });
