import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import React, { memo, useMemo } from 'react';
import { themeProp } from '../../types/main';
import { useTheme } from 'react-native-paper';
import { NewsTestId } from '../../../test/testIds';

type NewsBottomActionBarProps =
  | {
      likesEnabled: false;
      commentsEnabled: false;
    }
  | {
      likesEnabled: false;
      commentsEnabled: true;
      onCommentPress: () => void;
    }
  | {
      likesEnabled: true;
      hasLiked: boolean;
      onLikePress: () => void;
      disableLikeButton: boolean;
      commentsEnabled: false;
    }
  | {
      likesEnabled: true;
      hasLiked: boolean;
      onLikePress: () => void;
      disableLikeButton: boolean;
      commentsEnabled: true;
      onCommentPress: () => void;
    };

export const NewsBottomActionBar = memo((props: NewsBottomActionBarProps) => {
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <>
      {(props.likesEnabled || props.commentsEnabled) && (
        <View
          style={[
            styles.viewLightDivider,
            {
              marginTop: 4,
            },
          ]}
        />
      )}
      <View style={styles.container}>
        {props.likesEnabled && (
          <TouchableOpacity
            disabled={props.disableLikeButton}
            onPress={props.onLikePress}
            style={styles.actionButton}
            testID="like-button"
          >
            <Icon
              name="thumb-up-outline"
              size={20}
              color={props.hasLiked ? theme.customColors.primary : theme.customColors.newsActions}
            />
            <Text
              style={[
                styles.text,
                {
                  color: props.hasLiked
                    ? theme.customColors.primary
                    : theme.customColors.newsActions,
                },
              ]}
            >
              Gefällt mir
            </Text>
          </TouchableOpacity>
        )}
        {props.commentsEnabled && (
          <TouchableOpacity
            onPress={props.onCommentPress}
            style={styles.actionButton}
            testID={NewsTestId.commentButton}
          >
            <Icon
              name="message-outline"
              size={20}
              color={theme.customColors.newsActions}
              style={{ paddingTop: 1 }}
            />
            <Text
              style={[
                styles.text,
                {
                  color: theme.customColors.newsActions,
                },
              ]}
            >
              Kommentieren
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </>
  );
});

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 8,
      marginBottom: 2,
    },
    actionButton: {
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 12,
      marginVertical: 6,
    },
    viewLightDivider: {
      width: '100%',
      height: 0.7,
      marginVertical: 3,
      backgroundColor: theme.customColors.borders,
    },
    text: {
      fontSize: 14,
      marginLeft: 8,
    },
  });
