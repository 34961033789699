import React from 'react';
import { Appbar } from 'react-native-paper';
import { useAppTheme } from '../../../styles/theme';

type NewChatAppBarActionPropTypes = {
  onPress: () => void;
};

export const NewChatAppBarAction = ({ onPress }: NewChatAppBarActionPropTypes) => {
  const theme = useAppTheme();
  return (
    <Appbar.Action
      icon="chat-plus-outline"
      size={26}
      testID="app-bar-new-direct-chat"
      color={theme.customColors.gray50}
      onPress={onPress}
    />
  );
};
