import { QueryClient } from '@tanstack/react-query';
import { useFormReplyDetailsQuery } from '../../../../graphql/operations';

type InvalidateFormReplyQueryProps = {
  queryClient: QueryClient;
  formReplyId: string;
};

export const invalidateFormReplyQuery = async ({
  queryClient,
  formReplyId,
}: InvalidateFormReplyQueryProps) => {
  await queryClient.invalidateQueries(useFormReplyDetailsQuery.getKey({ id: formReplyId }));
};
