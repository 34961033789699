import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import React, { useEffect, useMemo } from 'react';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../../../stores/store';
import { ChatPartner } from '../types';
import { getAvatarForChatRoom } from '../utils';
import { getChatRoomName } from '../utils/getChatRoomName';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { getFullName } from '../../profiles/utils';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { Constants } from '../../../utils';
import { AvatarDisplayShimmer } from '../../../components/Common/AvatarDisplay/AvatarDisplayShimmer';
import { ChatMessageTyping } from '@luciapp/socket-contract';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { getChatMessageBeingTypedText } from '../utils/getChatMessageBeingTypedText';

type ChatHeaderProps = {
  chatPartners: ChatPartner[];
  isGroupChat?: boolean;
  name?: string | null;
  chatId?: string;
  chatImageUri?: string;
  typingEvents: ChatMessageTyping[];
};

export const ChatAppBarContent = ({
  chatPartners,
  name,
  chatId,
  isGroupChat,
  chatImageUri,
  typingEvents,
}: ChatHeaderProps) => {
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const userProfile = useStore(s => s.userProfile);
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const chatRoomName = useMemo(() => {
    if (!userProfile) {
      return '';
    }
    return getChatRoomName(name, { chatPartners, userProfile });
  }, [name, chatPartners, userProfile]);

  const chatRoomImage = useMemo(() => {
    return getAvatarForChatRoom({
      isGroupChat: isGroupChat,
      chatPartners: chatPartners,
      userId: userProfile?.userId,
      chatImageUri: chatImageUri,
      name: name,
    });
  }, [isGroupChat, chatPartners, userProfile]);

  const handlePress = () => {
    if (chatRoomName === 'Ehemaliger Benutzer') {
      return;
    }
    if (isGroupChat && chatId) {
      return navigation.navigate('ChatDetail', { chatRoomId: chatId });
    }
    if (!isGroupChat) {
      const partner = chatPartners.find(member => member.userId !== userProfile?.userId);
      if (partner) {
        return navigation.navigate('Profile', {
          profileId: partner.userId,
          name: getFullName({ firstname: partner.firstname, lastname: partner.lastname }),
        });
      }
    }
  };

  const initialOffset = 0;
  const offset = useSharedValue(initialOffset);

  // @ts-ignore
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offset.value),
        },
      ],
    };
  });

  const isAChatRoomMemberTyping = (typingEvents?.length ?? 0) > 0;

  useEffect(() => {
    if (isAChatRoomMemberTyping) {
      offset.value = initialOffset;
    } else {
      offset.value = 8;
    }
  }, [isAChatRoomMemberTyping]);

  const typingText = getChatMessageBeingTypedText({ typingEvents, isGroupChat });

  return (
    <TouchableOpacity style={styles.userContainer} onPress={handlePress} testID="chat-profile">
      {chatRoomName === Constants.IS_LOADING_TEXT ? (
        <AvatarDisplayShimmer size={40} />
      ) : (
        <AvatarDisplay
          avatar={{ initials: chatRoomImage.initials, otcPath: chatRoomImage.uri }}
          size={32}
          type={isGroupChat ? UploadType.GroupChatImage : UploadType.ProfilePicture}
        />
      )}
      <View style={styles.textContainer}>
        <Animated.View style={animatedStyles}>
          <Text variant="titleSmall" numberOfLines={1}>
            {chatRoomName}
          </Text>
        </Animated.View>
        <Text variant="bodySmall" numberOfLines={1}>
          {/* please keep as is to not break animation */}
          {typingText ?? 'ㅤ'}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    userContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    textContainer: {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginLeft: 8,
    },
    name: {
      fontWeight: 'bold',
      fontSize: 14,
    },
    subText: {
      fontSize: 12,
    },
    avatar: {
      backgroundColor: theme.customColors.gray60,
    },
  });
