import { Alert } from 'react-native';
import { ExceptionCode } from '../types/ExceptionCode';
import { StackNavigationProp } from '@react-navigation/stack';
import NetInfo from '@react-native-community/netinfo';
import { Log } from '../../../utils/Log';
import { AppNavigatorParamList } from '../../../router/AppNavigator';

type FormikPropTypes = {
  setFieldError: (field: string, value: string | undefined) => void;
};

export const useCognitoException =
  (formik: FormikPropTypes, navigation: StackNavigationProp<AppNavigatorParamList, 'Login'>) =>
  async (
    error: Error,
    values: {
      user: string;
      pass: string;
    },
  ) => {
    const [code, message] = (error.message || '').split(';');

    switch (code) {
      case ExceptionCode.NotAuthorizedException:
        switch (message) {
          case 'User is disabled.':
            formik.setFieldError(
              'pass',
              'Ihr Account wurde deaktiviert. Bitte wenden Sie sich an den Administrator.',
            );
            break;
          case 'Incorrect username or password.':
            formik.setFieldError(
              'pass',
              'Die E-Mail Adresse und das Passwort stimmen nicht überein.',
            );
            break;
          case 'Password attempts exceeded':
            formik.setFieldError(
              'pass',
              'Sie haben mehrmals ein falsches Passwort eingegeben. Bitte setzen Sie Ihr Passwort zurück und melden Sie sich nach 15 Minuten erneut an.',
            );
            break;
          case 'Temporary password has expired and must be reset by an administrator.':
            formik.setFieldError(
              'pass',
              'Das temporäre Passwort ist abgelaufen. Bitte wenden Sie sich an einen Administrator zum neu setzen des Passworts.',
            );
            break;
          default:
            createAlert(error);
            break;
        }
        break;
      case ExceptionCode.PasswordResetRequiredException:
        navigation.navigate('NewPassword', {
          channel: values.user.indexOf('@') > 0 ? 'E-Mail' : 'E-Mail bzw. SMS',
          action: 'RESET_REQUIRED',
          username: values.user,
          pass: values.pass,
        });
        break;
      case ExceptionCode.TooManyFailedAttemptsException:
        formik.setFieldError(
          'pass',
          'Sie haben mehrmals ein falsches Passwort eingegeben. Bitte setzen Sie Ihr Passwort zurück und melden Sie sich nach 15 Minuten erneut an.',
        );
        break;
      case ExceptionCode.InvalidParameterException:
        formik.setFieldError(
          'user',
          'Dieses Feld darf keine Leerzeichen enthalten. Bitte achten Sie darauf, dass Sie anstelle von Leerzeichen den Unterstrich verwenden.',
        );
        break;
      case ExceptionCode.RequestFailedException:
        try {
          const state = await NetInfo.fetch();
          if (!state.isConnected) {
            formik.setFieldError(
              'pass',
              'Es besteht keine Internetverbindung. Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind und versuchen Sie es erneut.',
            );
          } else {
            createAlert(error);
          }
        } catch (err) {
          Log.error(err, { message: 'error while getting NetInfo and displaying login error' });
          createAlert(err);
          return;
        }
        break;
      case ExceptionCode.WhiteLabelAppExistsForTenant:
        formik.setFieldError('user', 'Bitte benutzen Sie die Whitelabel app zum Login.');
        break;
      default:
        createAlert(error);
        break;
    }
    Log.error(error, { message: 'cognito produced an exception' });
  };

const createAlert = (err: any) => {
  Alert.alert(
    `Es tut uns leid, da ist etwas schief gelaufen!\n\nFehler:\n${JSON.stringify(err, null, 4)}`,
  );
};
