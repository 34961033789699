import AsyncStorage, { AsyncStorageStatic } from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import * as SecureStore from 'expo-secure-store';
import uuid from 'react-native-uuid';
import { Log } from './Log';

export const encryptedAsyncStorage: AsyncStorageStatic = {
  ...AsyncStorage,
  getItem: async key => {
    const encryptedValue = await AsyncStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }
    try {
      const encryptionKey = await getEncryptionKey();
      const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, encryptionKey).toString(
        CryptoJS.enc.Utf8,
      );
      return decryptedValue;
    } catch (error) {
      Log.error(error, { message: 'error while decrypting Local Storage Value' });
      return null;
    }
  },
  setItem: async (key, value) => {
    try {
      const encryptionKey = await getEncryptionKey();
      const encryptedValue = CryptoJS.AES.encrypt(value, encryptionKey).toString();
      await AsyncStorage.setItem(key, encryptedValue);
    } catch (error) {
      Log.error(error, { message: 'error while encrypting and saving value to Local Storage' });
    }
  },
};

const getEncryptionKey = async () => {
  let encryptionKey = await SecureStore.getItemAsync('encryptionKey');
  if (!encryptionKey) {
    encryptionKey = await generateKeyAndClearQueryCache();
  }
  return encryptionKey;
};

const generateKeyAndClearQueryCache = async () => {
  await AsyncStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
  const encryptionKey = uuid.v4().toString();
  await SecureStore.setItemAsync('encryptionKey', encryptionKey);
  return encryptionKey;
};
