import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { Item } from './ElementDropdown';

export type Props = {
  item: Item;
  selected?: boolean;
};

export const DropdownItem = ({ item, selected }: Props) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={[styles.item, selected && styles.selectedItem]}>
      {Boolean(item.icon) && <View style={styles.iconContainer}>{item.icon}</View>}
      <Text style={styles.textItem} numberOfLines={1}>
        {item?.label}
      </Text>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    iconContainer: {
      marginRight: 10,
    },
    item: {
      padding: 17,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.customColors.background2,
    },
    selectedItem: {
      backgroundColor: theme.customColors.selectedDropdownItemBackground,
    },
    textItem: {
      fontSize: 16,
    },
    selectedTextStyle: {
      fontSize: 16,
    },
  });
