import { UploadType, Uri } from './types';
import { useStore } from '../../../stores/store';
import { constructFormDataForHandleFilesRequest } from './constructFormDataForHandleFilesRequest';
import { EncodingType } from 'expo-file-system';
import { getFileNameAndMimeType } from './getFileNameAndMimeType';

interface IUploadFilesOfUploadType {
  fileUris: Uri[];
  uploadType: UploadType;
  encoding?: EncodingType;
}

export const uploadFilesOfUploadType = async ({
  uploadType,
  fileUris,
  encoding,
}: IUploadFilesOfUploadType) => {
  const filesToUpload = fileUris.map(uri => getFileNameAndMimeType({ uri }));
  const data = constructFormDataForHandleFilesRequest({ uploadType, filesToUpload, encoding });
  const { getState } = useStore;
  const headers = (await getState().headers()) as HeadersInit;

  const route = encoding === 'base64' ? 'upload-base64-file' : 'handleFiles';

  const res = await fetch(`${process.env.EXPO_PUBLIC_API_URL}${route}`, {
    method: 'POST',
    headers,
    body: data,
  });

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  return json;
};
