type DetermineInitialImageHeightProps = {
  imageWidth?: number | null;
  imageHeight?: number | null;
  screenWidth: number;
};

export const determineInitialImageHeight = ({
  imageWidth,
  imageHeight,
  screenWidth,
}: DetermineInitialImageHeightProps) => {
  if (imageWidth && imageHeight) {
    const newRatio = imageWidth / imageHeight;
    return Math.round(screenWidth / newRatio);
  }
  return undefined;
};
