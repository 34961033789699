import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

export interface ContentProps {
  children: React.ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  const styles = useMemo(() => createStyles(), []);
  return <View style={styles.content}>{children}</View>;
};

const createStyles = () =>
  StyleSheet.create({
    content: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
    },
  });
