import React, { useMemo } from 'react';
import { ActivityIndicator, Chip } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';

type ChatLoadMoreButtonPropTypes = {
  onPress: () => void;
  isLoading?: boolean;
};

export const ChatLoadMoreButton = ({ onPress, isLoading }: ChatLoadMoreButtonPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (isLoading) {
    return (
      <ActivityIndicator
        style={styles.activityIndicator}
        size="small"
        animating={true}
        color={theme.customColors.primary}
      />
    );
  }

  return (
    <Chip style={styles.chip} textStyle={styles.text} icon="arrow-up" onPress={onPress}>
      Mehr laden
    </Chip>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    chip: {
      height: 32,
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: 25,
      backgroundColor: theme.customColors.lightRipple,
      marginVertical: 16,
    },
    text: {
      fontSize: 12,
      color: theme.customColors.text,
      textAlign: 'center',
    },
    activityIndicator: {
      height: 32,
      marginVertical: 16,
      alignSelf: 'center',
      flex: 1,
      justifyContent: 'center',
    },
  });
