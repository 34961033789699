import { useQueryClient } from '@tanstack/react-query';
import { useSocketStore } from '../../stores/SocketStore';
import { useEffect, useRef } from 'react';
import { createNewsListener } from './createNewsListener';
import { NewsEvents } from '@luciapp/socket-contract';
import { News } from '../../../graphql/operations';
import { useStore } from '../../stores/store';

export const useCreateNewsListener = () => {
  const queryClient = useQueryClient();
  const socketStore = useSocketStore();
  const appUser = useStore(s => s.userProfile);
  const listenerFunc = useRef<(news: News) => Promise<void> | undefined>();

  useEffect(() => {
    if (socketStore.newsSocket && queryClient && appUser) {
      listenerFunc.current = createNewsListener(queryClient, appUser.userId);
      socketStore.newsSocket.on(NewsEvents.createNews, listenerFunc.current);
    }
    return () => {
      if (listenerFunc.current) {
        socketStore.newsSocket?.off(NewsEvents.createNews, listenerFunc.current);
      }
    };
  }, [socketStore.newsSocket, queryClient, appUser]);
};
