import { IconButton, Menu, TouchableRipple, Text } from 'react-native-paper';
import React, { Dispatch, memo, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { getFullName, getInitials, getJob } from '../utils';
import moment from 'moment';
import { ChatRole, useGetUserProfileQuery } from '../../../../graphql/operations';
import { useNetInfo } from '@react-native-community/netinfo';
import { NewsUserGroupLabels } from '../../../components/News/NewsUserGroupLabels';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { ChatRoomModeratorIcon } from '../../chat/components/ChatRoomModeratorIcon';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type CommonUserHeaderProps =
  | {
      userId: string;
      userGroupLabelsForNews?: string[];
      date?: Date | null;
      menuItems?: React.ReactNode;
      style?: ViewStyle;
      descriptionOverride?: string;
      ripple?: boolean;
      onPress?: () => void;
      menuOpen: boolean;
      setMenuOpen: Dispatch<React.SetStateAction<boolean>>;
      role?: ChatRole;
    }
  | {
      user?: {
        firstname: string;
        lastname: string;
        pictureOTC?: string | null;
        employeeFunction?: string | null;
        department?: string | null;
        titleBefore?: string | null;
        titleAfter?: string | null;
      } | null;
      userGroupLabelsForNews?: string[];
      date?: Date | null;
      menuItems?: React.ReactNode;
      style?: ViewStyle;
      descriptionOverride?: string;
      ripple?: boolean;
      onPress?: () => void;
      menuOpen: boolean;
      setMenuOpen: Dispatch<React.SetStateAction<boolean>>;
      role?: ChatRole;
    };

export const CommonUserHeader = memo((props: CommonUserHeaderProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const netInfo = useNetInfo();
  const userItem = useGetUserProfileQuery(
    {
      userId: ('userId' in props && props.userId) || '',
    },
    {
      enabled: 'userId' in props && (netInfo.isConnected ?? false),
    },
  );

  let userProfile:
    | {
        firstname: string;
        lastname: string;
        pictureOTC?: string | null;
        employeeFunction?: string | null;
        department?: string | null;
      }
    | undefined;

  if ('userId' in props) {
    userProfile = userItem.data?.userProfile;
  } else if ('user' in props && props.user) {
    userProfile = props.user;
  } else {
    userProfile = {
      firstname: 'Ehemaliger',
      lastname: 'Benutzer',
    };
  }

  if (!userProfile) {
    return <></>;
  }

  const ViewComponent = props?.ripple ? TouchableRipple : View;

  const job = getJob(userProfile);

  return (
    <ViewComponent
      testID="CommonUserHeader"
      onPress={props.onPress}
      style={[styles.container, props.style]}
      rippleColor="transparent"
    >
      <>
        <View style={styles.userContainer}>
          <View style={styles.avatarContainer}>
            <AvatarDisplay
              size={50}
              avatar={{
                otcPath: userProfile.pictureOTC ?? undefined,
                initials: getInitials(userProfile),
              }}
              type={UploadType.ProfilePicture}
            />
          </View>
          {props.role === ChatRole.Moderator && <ChatRoomModeratorIcon />}
          <View style={styles.nameAndDescriptionContainer}>
            {userProfile.firstname && <Text style={styles.name}>{getFullName(userProfile)}</Text>}
            {props?.descriptionOverride ? (
              <Text style={styles.description}>{props.descriptionOverride}</Text>
            ) : (
              <>
                {job && <Text style={styles.description}>{job}</Text>}
                {props.date && (
                  <Text style={styles.date}>{moment(props.date).format('D MMMM YYYY, HH:mm')}</Text>
                )}
                {props.userGroupLabelsForNews && (
                  <NewsUserGroupLabels userGroupLabels={props.userGroupLabelsForNews} />
                )}
              </>
            )}
          </View>
        </View>
        <View style={styles.menuContainer}>
          {props.menuItems && (
            <Menu
              style={styles.menuContainer}
              onDismiss={() => props.setMenuOpen(false)}
              visible={props.menuOpen}
              contentStyle={styles.menuContent}
              anchor={
                <IconButton
                  style={styles.menu}
                  icon="dots-vertical"
                  size={24}
                  onPress={() => {
                    props.setMenuOpen(true);
                  }}
                  testID="CommonUserHeaderMenu"
                />
              }
            >
              {props.menuItems}
            </Menu>
          )}
        </View>
      </>
    </ViewComponent>
  );
});

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingRight: 8,
      paddingVertical: 4,
    },
    userContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    avatarContainer: { alignSelf: 'flex-start' },
    name: {
      fontWeight: 'bold',
      fontSize: 15,
    },
    description: {
      color: theme.customColors.textGray,
      marginVertical: -2,
    },
    date: {
      marginVertical: 1,
      color: theme.customColors.textGray,
    },
    menu: {
      alignSelf: 'flex-end',
      marginRight: -4,
    },
    avatar: {
      backgroundColor: theme.customColors.gray60,
      alignSelf: 'flex-start',
    },
    nameAndDescriptionContainer: {
      marginLeft: 12,
      flex: 1,
    },
    badgeContainer: {
      marginRight: 0,
      flex: 1,
    },
    menuContent: { marginTop: 42 },
    menuContainer: {
      alignSelf: 'flex-start',
    },
  });
