import React, { useMemo } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';
import { AppTheme, useAppTheme } from '../../styles/theme';

export interface FooterProps {
  children: React.ReactNode;
}
const FOOTER_HEIGHT = 80;

export const Footer = ({ children }: FooterProps) => {
  const safeAreaInsets = useSafeAreaInsets();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(safeAreaInsets, theme), [safeAreaInsets, theme]);

  return <View style={styles.footer}>{children}</View>;
};

const createStyles = (edgeInsets: EdgeInsets, theme: AppTheme) =>
  StyleSheet.create({
    footer: {
      bottom: edgeInsets.bottom,
      height: FOOTER_HEIGHT,
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginBottom: Platform.OS === 'android' ? 25 : 0,
      borderTopWidth: 0.5,
      borderTopColor: theme.customColors.borders,
    },
  });
