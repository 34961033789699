import React, { useMemo } from 'react';
import { Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { RoundedSquareIcon, RoundedSquareIconPropTypes } from '../../Common/Icon/RoundedSquareIcon';

type IconLabelPropTypes = {
  iconProps: RoundedSquareIconPropTypes;
  label: string;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
};

export const IconLabel = ({
  iconProps,
  label,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
}: IconLabelPropTypes) => {
  const {
    icon,
    iconSize,
    iconTransformRotateDegree,
    marginRight: iconMarginRight,
    squareSideLength,
    radius,
  } = iconProps;
  const theme = useAppTheme();
  const styles = useMemo(
    () => createStyles(theme, marginRight, marginLeft, marginTop, marginBottom),
    [theme, marginRight, marginLeft, marginBottom, marginLeft],
  );
  return (
    <View style={styles.container}>
      <RoundedSquareIcon
        icon={icon}
        iconTransformRotateDegree={iconTransformRotateDegree ?? 0}
        squareSideLength={squareSideLength ?? 18}
        iconSize={iconSize ?? 16}
        marginRight={iconMarginRight ?? 11}
        radius={radius}
      />
      <Text variant="bodyMedium" style={styles.text}>
        {label}
      </Text>
    </View>
  );
};

const createStyles = (
  theme: AppTheme,
  marginRight?: number,
  marginLeft?: number,
  marginBottom?: number,
  marginTop?: number,
) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      marginRight: marginRight ?? 0,
      marginLeft: marginLeft ?? 0,
      marginTop: marginBottom ?? 0,
      marginBottom: marginTop ?? 0,
    },
    text: {
      alignSelf: 'center',
    },
  });
