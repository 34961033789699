import { FormReplyAttachmentType } from '../../../../graphql/operations';
import { FileType } from '../../../components/FileGallery/types';

export const mapFormReplyFileTypeToFileType = (type: FormReplyAttachmentType) => {
  switch (type) {
    case FormReplyAttachmentType.Document:
      return FileType.Document;
    case FormReplyAttachmentType.Image:
      return FileType.Image;
  }
};
