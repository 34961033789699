import { Appbar, Headline, useTheme } from 'react-native-paper';
import { StyleSheet, SafeAreaView, View } from 'react-native';
import React, { useMemo } from 'react';

import { themeProp } from '../../../types/main';
import { HelpButton } from '../../helpPage/components/HelpButton';
import { HelpCategories } from '../types';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { useNavigation } from '@react-navigation/native';
import { AppBar } from '../../../components/AppBar/AppBar';

export const HelpScreenExternal = () => {
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'HelpExternal'>['navigation']>();
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <>
      <AppBar
        title="Externe Hilfe"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <SafeAreaView style={styles.container}>
        <View style={styles.card}>
          <Headline style={styles.headline}>Was möchten Sie melden?</Headline>
          <View style={styles.buttonBox}>
            <HelpButton category={HelpCategories.Error} icon="alert-outline" />
            <HelpButton category={HelpCategories.Idea} icon="lightbulb-outline" />
            <HelpButton category={HelpCategories.Other} icon="pencil-box-outline" />
          </View>
        </View>
      </SafeAreaView>
    </>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 30,
    },
    card: {
      alignItems: 'center',
      paddingVertical: 15,
      paddingHorizontal: 40,
      marginVertical: 10,
      backgroundColor: theme.customColors.background3,
      width: '84%',
      borderRadius: theme.roundness,
    },
    headline: {
      fontSize: 16,
      fontWeight: '600',
    },
    buttonBox: {
      flexDirection: 'row',
    },
  });
