import { Linking, StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import Markdown from 'react-native-markdown-display';
import { prependHttpsSchema } from '../../utils/links';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { TransformButton } from '../../stories/Button/TransformButton';
import { UserPreference } from '../../../graphql/operations';
import { useNewsTranslations } from '../../features/news/hooks/useNewsTranslations';
import { themeProp } from '../../types/main';
import { NewsTestId } from '../../../test/testIds';
import { AppTheme } from '../../styles/theme';
import { Log } from '../../utils/Log';

type NewsTextProps = {
  newsId: string;
  title: string;
  content: string;
  userDidTranslateNews: boolean;
  translationId?: string | null;
  translatedTitle?: string | null;
  translatedContent?: string | null;
  startExpanded?: boolean;
  translationEnabled: UserPreference['translationEnabled'];
  translationLanguage: UserPreference['translationLanguage'];
  setTranslationSetupPopUpShown: React.Dispatch<React.SetStateAction<boolean>>;
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
};

export const NewsText = memo(
  ({
    newsId,
    title,
    content,
    userDidTranslateNews,
    translationId,
    translatedTitle,
    translatedContent,
    startExpanded,
    translationEnabled,
    translationLanguage,
    setTranslationSetupPopUpShown,
    groupIdsInNewsTimelineQuery,
    searchTextInNewsTimeLineQuery,
  }: NewsTextProps) => {
    const theme = useTheme() as themeProp;
    const [isExpanded, setExpanded] = useState(!!startExpanded);

    const {
      translatableTitle,
      translatableContent,
      isTranslated,
      isTranslating,
      handleTranslation,
    } = useNewsTranslations({
      newsId: newsId,
      originalTitle: title,
      originalContent: content,
      userDidTranslateNews: userDidTranslateNews,
      translationId: translationId,
      translatedTitle: translatedTitle,
      translatedContent: translatedContent,
      setTranslationSetupPopUpShown: setTranslationSetupPopUpShown,
      setExpanded: setExpanded,
      translationEnabled: translationEnabled,
      translationLanguage: translationLanguage,
      groupIdsInNewsTimelineQuery: groupIdsInNewsTimelineQuery,
      searchTextInNewsTimeLineQuery: searchTextInNewsTimeLineQuery,
    });

    const text = `# **${translatableTitle.trim()}**\n\n${translatableContent.trim()}`;
    const cleanedText = useMemo(() => text?.replace('\\\n', '\n'), [text]);

    /* @todo this function leads to issue #391, fix needed */
    const createCompactText = useCallback(
      (textItem: string) => {
        const firstEntries = textItem.split('\n\n').slice(0, 2);
        if (firstEntries.length === 2 && firstEntries[1].length > 150) {
          firstEntries[1] = `${firstEntries[1].substring(0, 150)}...`;
        }
        return firstEntries.join('\n\n');
      },
      [text],
    );

    const openDialer = async (url: string) => {
      const canOpen = await Linking.canOpenURL(url);
      if (!canOpen) {
        Log.error(`cannot open phone number ${url}`);
        return;
      }
      try {
        await Linking.openURL(url);
      } catch (e) {
        Log.error(e, { message: `cannot open phone number ${url}` });
      } finally {
        return;
      }
    };

    const openURL = useCallback((url: string) => {
      if (url.startsWith('tel:')) {
        void openDialer(url);
        return false;
      }
      const urlWithSchema = prependHttpsSchema(url);

      void Linking.canOpenURL(urlWithSchema).then(() => Linking.openURL(urlWithSchema));
      return false;
    }, []);

    const compactText = useMemo(() => createCompactText(cleanedText), [cleanedText]);
    const isShortText = useMemo(
      () => cleanedText.length === compactText.length,
      [compactText, cleanedText],
    );
    //const isShortText = useMemo(() => cleanedText.length < 150, []);

    const styles = useMemo(
      () => createStyles(theme.customColors.primary, !!isShortText, !!isTranslating),
      [theme.customColors.primary, !!isShortText, !!isTranslating],
    );

    const markdownStyles = useMemo(() => createMarkdownStyles(theme), [theme]);

    return (
      <>
        <View style={styles.markdownContainer}>
          <Markdown
            style={markdownStyles}
            onLinkPress={url => (url.length !== 0 ? openURL(url) : false)}
            /* potential solution for issue #391 */
            //maxTopLevelChildren={isExpanded ? null : 2}
            //topLevelMaxExceededItem={false}
          >
            {/* {cleanedText} */}
            {isExpanded ? cleanedText : compactText}
          </Markdown>
        </View>
        <View style={styles.transformingButtonContainer}>
          {!isShortText && (
            <TransformButton
              containerStyle={styles.readMoreContainer}
              onPress={() => setExpanded(!isExpanded)}
              buttonTextStyle={styles.readMoreText}
              buttonText={!isExpanded ? 'weiterlesen' : 'weniger anzeigen'}
              isDisabled={isTranslating}
              testID={'expand-button'}
            />
          )}
          {translationEnabled && (
            <TransformButton
              containerStyle={styles.translateContainer}
              onPress={handleTranslation}
              buttonTextStyle={styles.translateText}
              buttonText={isTranslated ? 'Original anzeigen' : 'übersetzen'}
              isDisabled={isTranslating}
              testID={NewsTestId.translateButton}
            />
          )}
        </View>
      </>
    );
  },
);

const createStyles = (color: string, isShortText: boolean, isTranslating: boolean) =>
  StyleSheet.create({
    markdownContainer: {
      opacity: isTranslating ? 0.5 : 1,
    },
    transformingButtonContainer: {
      flexDirection: isShortText ? 'row' : 'row-reverse',
      justifyContent: 'space-between',
    },
    readMoreContainer: {
      flexDirection: 'row-reverse',
      alignSelf: 'flex-end',
    },
    readMoreText: { color: color, opacity: Boolean(isTranslating) ? 0.5 : 1 },
    translateContainer: {
      flexDirection: 'row-reverse',
      alignSelf: 'flex-start',
      marginRight: -8,
    },
    translateText: {
      opacity: Boolean(isTranslating) ? 0.5 : 1,
    },
  });

const createMarkdownStyles = (theme: AppTheme) =>
  StyleSheet.create({
    heading1: {
      color: theme.colors.onSurface,
      fontSize: 18,
      marginTop: 0,
      marginBottom: 8,
    },
    heading2: {
      color: theme.colors.onSurface,
      fontSize: 16,
      marginTop: 0,
      marginBottom: 8,
    },
    blockquote: {
      color: theme.colors.onSurface,
      borderLeftWidth: 5,
      borderLeftColor: '#cccccc',
      backgroundColor: 'transparent',
      paddingLeft: 10,
    },
    link: {
      color: theme.colors.primary,
    },
    text: {
      color: theme.colors.onSurface,
    },
    list_item: {
      color: theme.colors.onSurface,
    },
  });
