export const isCloseToBottom = (
  measureProps: {
    layoutMeasurement: { height: number };
    contentOffset: { y: number };
    contentSize: { height: number };
  },
  paddingToBottom: number,
) => {
  return (
    measureProps.layoutMeasurement.height + measureProps.contentOffset.y >=
    measureProps.contentSize.height - paddingToBottom
  );
};
