import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { useAppTheme } from '../../../../styles/theme';
import { BinaryAnswerType } from '../../types';

type BinaryAnswerButtonPropTypes = {
  label: BinaryAnswerType;
  onPress: () => void;
  isSelected: boolean;
};

export const BinaryAnswerButton = ({ label, isSelected, onPress }: BinaryAnswerButtonPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);

  return (
    <Button
      uppercase={false}
      style={styles.button}
      mode={isSelected ? 'contained' : 'outlined'}
      onPress={onPress}
      textColor={isSelected ? theme.customColors.textWhite : theme.customColors.primary}
    >
      {label}
    </Button>
  );
};

const createStyles = () =>
  StyleSheet.create({
    button: {
      borderRadius: 5,
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center',
      alignSelf: 'center',
      flex: 1,
      width: '50%',
    },
  });
