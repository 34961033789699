import React, { useState, useEffect } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { Button, Modal, Text, TextInput, useTheme } from 'react-native-paper';
import { ModalInputFieldType, themeProp } from '../../../types/main';

export const InputModal = (props: {
  visible: boolean;
  title: string;
  inputFields: Array<ModalInputFieldType>;
  iosKeyboardOffset: number;
  disabled?: boolean;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmColor?: string;
  cancelColor?: string;
  onDismiss: () => void;
  onConfirm: (inputArray: Array<ModalInputFieldType>) => void;
  onCancel: () => void;
}) => {
  const theme = useTheme() as themeProp;
  const [inputArray, setInputArray] = useState<Array<ModalInputFieldType>>();

  const handleChange = (input: string, i: number) => {
    if (inputArray) {
      const newInputArray = inputArray;
      newInputArray.splice(i, 1, {
        label: inputArray[i].label,
        placeholder: inputArray[i].placeholder,
        value: input,
      });
      setInputArray(newInputArray);
    }
  };

  useEffect(() => {
    setInputArray(props.inputFields);
  }, [props.inputFields]);

  return (
    <Modal visible={props.visible} onDismiss={props.onDismiss}>
      <KeyboardAvoidingView
        behavior={'position'}
        enabled={Platform.OS === 'ios'}
        keyboardVerticalOffset={props.iosKeyboardOffset}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View
            style={{
              alignSelf: 'center',
              width: 500,
              padding: 20,
              maxWidth: '90%',
              justifyContent: 'space-around',
              backgroundColor: theme.colors.background,
              shadowColor: '#000',
              shadowOpacity: 0.12,
              shadowRadius: 25,
              borderRadius: 12,
            }}
          >
            {props.title && (
              <Text
                style={{
                  lineHeight: 24,
                  fontSize: 18,
                  color: '#353535',
                  fontWeight: 'bold',
                  marginBottom: 8,
                }}
              >
                {props.title}
              </Text>
            )}
            {props.inputFields &&
              props.inputFields.map((inputField, i) => (
                <TextInput
                  testID="inputModalTextInput"
                  secureTextEntry={inputField.secure}
                  style={{ backgroundColor: theme.customColors.textInputBackground }}
                  key={i}
                  label={inputField.label}
                  placeholder={inputField.placeholder}
                  onChangeText={(input: string) => handleChange(input, i)}
                  disabled={props.disabled}
                  autoCapitalize={inputField.capitalize ? 'sentences' : 'none'}
                  returnKeyType={i === props.inputFields.length - 1 ? 'send' : 'default'}
                  onSubmitEditing={
                    i === props.inputFields.length - 1
                      ? () => !props.disabled && props.onConfirm(inputArray!)
                      : undefined
                  }
                />
              ))}
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginBottom: -6,
                marginTop: 10,
              }}
            >
              {typeof props.onCancel === 'function' && (
                <Button
                  mode="text"
                  uppercase={false}
                  onPress={props.onCancel}
                  disabled={props.disabled}
                  testID="inputModalCancelButton"
                >
                  <Text style={{ color: props.cancelColor || '#5f6368', borderRadius: 5 }}>
                    {props.cancelTitle || 'Abbrechen'}
                  </Text>
                </Button>
              )}
              {typeof props.onConfirm === 'function' && (
                <Button
                  mode="text"
                  uppercase={false}
                  onPress={() => props.onConfirm(inputArray!)}
                  disabled={props.disabled}
                  testID="inputModalButton"
                  style={{ borderRadius: 5 }}
                  textColor={props.confirmColor || theme.customColors.primary}
                >
                  {props.confirmTitle || 'Bestätigen'}
                </Button>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </Modal>
  );
};
