import React from 'react';
import { Appbar } from 'react-native-paper';

/* Use this component as a placeholder for a non-matched appbar action to prevent symmetrical issues */
/* examples: 
| <action> <appbartitle> <use-here> |
| <use-here> <appbartitle> <action> |
| <do-not-use-here> <appbartitle> <do-not-use-here> |
*/
export const PlaceholderAppBarAction = () => {
  return (
    <Appbar.Action
      icon="emoticon"
      size={26}
      onPress={() => {}}
      disabled={true}
      underlayColor="transparent"
      rippleColor="transparent"
      style={{ opacity: 0 }}
    />
  );
};
