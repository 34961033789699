import { ReturnedPage } from '@luciapp/stock-image-api';
import { File, FileOptionalParams, FileType } from '../../../../components/FileGallery/types';

export const mapStockImagePageToFiles = (
  stockImagePage: ReturnedPage,
): Array<File<FileOptionalParams>> => {
  return stockImagePage.photos.map(photo => ({
    id: photo.id.toString(),
    type: FileType.Image,
    uri: photo.src.large ?? photo.src.original,
    optionalParams: {
      stockImageData: photo,
    },
  }));
};
