import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { maxContentAreaWidth } from '../../../router/navigators/ContentNavigator';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type DownloadableImagePlaceholderPropTypes = {
  onPress: () => void;
};

export const DownloadableImagePlaceholder = ({
  onPress,
}: DownloadableImagePlaceholderPropTypes) => {
  const theme = useAppTheme();
  const { width: screenWidth } = useWindowDimensions();
  const width = useMemo(
    () => (screenWidth > maxContentAreaWidth ? maxContentAreaWidth / 2 : screenWidth / 2),
    [screenWidth, maxContentAreaWidth],
  );
  const height = useMemo(() => (width * 9) / 16, []);
  const styles = useMemo(() => createStyles(theme, height, width), [theme, height, width]);

  return (
    <TouchableOpacity activeOpacity={0.8} style={styles.container} onPress={onPress}>
      <MaterialCommunityIcons name="download" size={64} color={theme.customColors.gray50} />
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme, height: number, width: number) =>
  StyleSheet.create({
    container: {
      width,
      height,
      borderRadius: 6,
      backgroundColor: theme.customColors.lightRipple,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 6,
    },
  });
