import { useEffect } from 'react';
import { useGetGlobalSettingsQuery } from '../../../graphql/operations';
import { useStore } from '../../stores/store';

export const useIsSavingImagesAllowed = () => {
  const storedIsSavingImagesAllowed = useStore(state => state.isSavingImagesAllowed);

  const getGlobalSettingsQuery = useGetGlobalSettingsQuery(
    {},
    { enabled: storedIsSavingImagesAllowed === undefined },
  );

  const queriedIsSavingImagesAllowed =
    getGlobalSettingsQuery.data?.getGlobalSettings.isSavingImagesAllowed;

  useEffect(() => {
    if (queriedIsSavingImagesAllowed !== undefined) {
      useStore.setState({
        isSavingImagesAllowed: queriedIsSavingImagesAllowed,
      });
    }
  }, [queriedIsSavingImagesAllowed]);

  return storedIsSavingImagesAllowed;
};
