import { ReturnedPexelsPage } from '@luciapp/stock-image-api';
import { rest } from 'msw';
import { Chance } from 'chance';
import { image } from '../../../../testUtils/image';

const chance = new Chance();

const pexelsPhoto = {
  id: chance.integer(),
  width: 200,
  height: 200,
  url: chance.string(),
  photographer: chance.string(),
  photographer_url: chance.string(),
  photographer_id: chance.integer(),
  avg_color: '#000',
  liked: chance.bool(),
  alt: chance.sentence(),
  src: {
    original: image,
    large2x: image,
    large: image,
    medium: image,
    small: image,
    portrait: image,
    landscape: image,
    tiny: image,
  },
};

const returnedPexelsPage: ReturnedPexelsPage = {
  page: 1,
  per_page: 40,
  photos: Array.from({ length: 40 }, () => pexelsPhoto),
};

const returnedPexelsSearchPage: ReturnedPexelsPage = {
  page: 1,
  per_page: 40,
  total_results: 3,
  photos: Array.from({ length: 3 }, () => pexelsPhoto),
};

export const pexelsApiTestHandlers = () => [
  rest.get('https://api.pexels.com/v1/curated', (req, res, ctx) => {
    return res(ctx.status(200), ctx.body(JSON.stringify(returnedPexelsPage)));
  }),
  rest.get('https://api.pexels.com/v1/search', (req, res, ctx) => {
    return res(ctx.status(200), ctx.body(JSON.stringify(returnedPexelsSearchPage)));
  }),
];
