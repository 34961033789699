import { useState } from 'react';
import { DownloadImage } from './DownloadImage';
import { downloadFile } from '../../components/Common/FileUpload/api';
import { Log } from '../../utils/Log';

export const useSignedOtcUrl = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [uri, setUri] = useState<string>();

  const fetchSignedUrl = async ({ storageFilename, type }: DownloadImage) => {
    try {
      const result = await downloadFile({
        s3Path: storageFilename,
        uploadType: type,
      });
      if (!result) {
        throw new Error('no response for uri ' + storageFilename);
      }
      setUri(result);
      return result;
    } catch (e) {
      Log.error(e);
      Log.error('cannot download uri ' + uri);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchSignedUrl,
    isLoading,
    uri,
  };
};
