import React, { useMemo } from 'react';
import {
  TeamsWhereUserIsResponsibleQuery,
  useTeamsWhereUserIsResponsibleQuery,
} from '../../../../graphql/operations';
import { useThemeStore } from '../../../stores/ThemeStore';
import Icon from '@expo/vector-icons/FontAwesome5';
import { NewCachedAutoHeightImage } from '../../caching/components/NewCachedAutoHeightImage';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { ElementDropdown } from '../../../components/ElementDropdown/ElementDropdown';
import { UploadType } from '../../../components/Common/FileUpload/types';

export type TeamDropdownPickerPropTypes = {
  onChangeValue: (val: string | null) => void;
};

export const TeamDropdownPicker = ({ onChangeValue }: TeamDropdownPickerPropTypes) => {
  const { data: teams, isLoading, refetch } = useTeamsWhereUserIsResponsibleQuery();
  const { colorScheme } = useThemeStore();

  const getIcon = (
    teamOption: TeamsWhereUserIsResponsibleQuery['teamsWhereUserIsResponsible'][number],
  ) => {
    if (!teamOption.imagePath) {
      return <Icon name="users" color={colorScheme === 'dark' ? 'white' : 'black'} size={25} />;
    }
    return (
      <NewCachedAutoHeightImage
        source={teamOption.imagePath}
        type={UploadType.TeamImage}
        width={30}
      />
    );
  };

  const dropdownItems = useMemo(() => {
    return (
      teams?.teamsWhereUserIsResponsible.map(teamOption => ({
        label: teamOption.title,
        value: teamOption.id!,
        icon: getIcon(teamOption),
      })) ?? []
    );
  }, [teams?.teamsWhereUserIsResponsible]);

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => refetch(),
    errorMessageToLog: 'an error occurred while refetching teams for the new inquiry screen',
  });

  return (
    <ElementDropdown
      items={dropdownItems}
      onChangeValue={onChangeValue}
      isLoading={isRefreshing || isLoading}
      onRefresh={onRefresh}
      placeholder="Team"
      emptyPlaceholder="Keine Teams gefunden."
    />
  );
};
