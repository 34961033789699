import AsyncStorage from '@react-native-async-storage/async-storage';
import { deleteItemAsync } from 'expo-secure-store';
import { Constants } from './Constants';
import { Log } from './Log';

export const cleanupBiometricStorage = async () => {
  try {
    await AsyncStorage.removeItem(Constants.BIOMETRICS_ENABLED);
    await AsyncStorage.removeItem(Constants.BIOMETRICS_HAS_USER_RECEIVED_POPUP);
    await deleteItemAsync(Constants.BIOMETRICS_STORAGE_USERNAME_KEY);
    await deleteItemAsync(Constants.BIOMETRICS_STORAGE_PASSWORD_KEY);
  } catch (e) {
    Log.error(e, { message: 'cannot cleanup biometric storage' });
    throw e;
  }
};
