import React from 'react';
import { useAppTheme } from '../../styles/theme';
import { Shimmer } from './Shimmer';

type LineOfTextShimmerPropTypes = {
  width: number;
  fontSize?: number;
  color?: string;
};

export const LineOfTextShimmer = ({ fontSize = 11, width, color }: LineOfTextShimmerPropTypes) => {
  const theme = useAppTheme();

  return (
    <Shimmer
      height={fontSize}
      width={width}
      radius={10}
      color={color ?? theme.customColors.textGray}
    />
  );
};
