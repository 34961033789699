import { Log } from '../../utils/Log';

import { Alert, View } from 'react-native';
import { Appbar, Button, TextInput, withTheme } from 'react-native-paper';
import {
  LoginAttribute,
  useAddLoginAttributeMutation,
  useSendLoginAttributeVerificationCodeMutation,
} from '../../../graphql/operations';
import React, { useState } from 'react';
import { checkIfValidEmail, checkIfValidPhone } from '../../utils/authUtils';

import { AppNavigatorParamList } from '../../router/AppNavigator';
import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { StackScreenProps } from '@react-navigation/stack';
import { authStyles as styles } from '../../styles/authStyles';
import { themeProp } from '../../types/main';
import { useNavigation } from '@react-navigation/native';
import { AppBar } from '../../components/AppBar/AppBar';

type VerifyAttributeScreenProps = StackScreenProps<AppNavigatorParamList, 'VerifyAttribute'>;

// @todo Unused Component?
const VerifyAttributeScreen = (props: {
  route?: { params?: { type?: string } };
  theme: themeProp;
}) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  const type = props?.route?.params?.type;
  const [username, setUsername] = useState('');
  const [isLoading, setLoading] = useState(false);
  const navigation = useNavigation<VerifyAttributeScreenProps['navigation']>();

  const handleSubmit = async () => {
    if (isValid && type) {
      setLoading(true);
      try {
        await useAddLoginAttributeMutation.fetcher({
          input: {
            loginEmail: type === 'email' ? username : undefined,
            loginPhoneNumber: type === 'phone_number' ? username : undefined,
          },
        })();
        await useSendLoginAttributeVerificationCodeMutation.fetcher({
          input: {
            attributeName: type === 'email' ? LoginAttribute.Email : LoginAttribute.PhoneNumber,
          },
        })();
        navigation.navigate('ConfirmationCode', {
          type: type,
          channel: type === 'email' ? 'E-Mail' : 'SMS',
          username: username,
        });
      } catch (err) {
        Log.error(`error while updating user attributes: ${JSON.stringify(err, null, 4)}`);
        Alert.alert('Es ist ein unbekannter Fehler aufgetreten');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <AppBar
        title={'Verifizierung'}
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <BaseAuthScreen
        headline="Login Information hinterlegen"
        justify
        description={`Geben Sie Ihre ${
          type === 'email'
            ? 'E-Mail Adresse ein'
            : 'persönliche mobile Telefonnummer mit Länderkennung ein (z. B. +49 1608348859)'
        }, anschließend werden Sie aufgefordert die ${
          type === 'email' ? 'E-Mail Adresse' : 'Telefonnummer'
        } zu bestätigen.`}
      >
        <View style={{ width: '100%' }}>
          <TextInput
            style={styles(props.theme).input}
            mode="outlined"
            label={type === 'email' ? 'E-Mail' : 'Telefonnummer'}
            onChangeText={async val => {
              const infoCheckAsync = async () => {
                setIsValid(
                  type === 'email'
                    ? await checkIfValidEmail(val.trim())
                    : await checkIfValidPhone(val.split(' ').join('')),
                );
                setUsername(val.split(' ').join(''));
              };
              await infoCheckAsync();
            }}
          />
          <Button
            style={styles(props.theme).button}
            mode="contained"
            disabled={!isValid || isLoading}
            loading={isLoading}
            onPress={async () => await handleSubmit()}
            textColor={props.theme.customColors.textWhite}
          >
            Bestätigungscode anfordern
          </Button>
        </View>
      </BaseAuthScreen>
    </>
  );
};

// eslint-disable-next-line no-restricted-syntax
export default withTheme(VerifyAttributeScreen);
