import { Feature } from '../../graphql/operations';

interface ITranslateFeature {
  feature: Feature;
  plural?: boolean;
}

export const translateFeature = ({ feature, plural }: ITranslateFeature) => {
  switch (feature) {
    case Feature.News:
      return 'News';
    case Feature.Chatroom:
      if (plural) {
        return 'Chatgruppen';
      }
      return 'Chatgruppe';
    case Feature.Form:
      if (plural) {
        return 'Formulare';
      }
      return 'Formular';
    case Feature.Infopage:
      if (plural) {
        return 'Infoseiten';
      }
      return 'Infoseite';
    case Feature.Poll:
      if (plural) {
        return 'Umfragen';
      }
      return 'Umfrage';
    case Feature.User:
      return 'Benutzer';
    default:
      throw new Error('unknown feature');
  }
};
