import { NewsAttachmentType } from '../../../../../graphql/operations';
import uuid from 'react-native-uuid';
import { getFileSizeFromBase64 } from '../../../../utils/getFileSizeFromBase64';
import * as ImagePicker from 'expo-image-picker';
import { ImageResult } from 'expo-image-manipulator';

export const uploadedImageToDatabase = (
  otcPath: string,
  fileName: string,
  { uri, base64, height, width }: ImageResult | ImagePicker.ImagePickerAsset,
) => ({
  id: uuid.v4().toString(),
  otcPath,
  fileName,
  type: NewsAttachmentType.Image,
  fallbackText: 'Bild',
  uri,
  fileSize: base64 ? getFileSizeFromBase64(base64.length) : 0,
  height,
  width,
});
