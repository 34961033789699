import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  NewsToReadByUserGroupQuery,
  SingleNewsToReadQuery,
  useInfiniteNewsToReadByUserGroupQuery,
  useSingleNewsToReadQuery,
} from '../../../../graphql/operations';
import { updateInfinite, updateSingleNews } from '../utils/updateLikeInNewsCache';

export const useUpdateNewsCache = (userGroupIdsOfNews: string[]) => {
  const queryClient = useQueryClient();

  const updateNewsCache = (newsId: string, isLike: boolean) => {
    queryClient.setQueryData<InfiniteData<NewsToReadByUserGroupQuery>>(
      useInfiniteNewsToReadByUserGroupQuery.getKey({
        pageInput: { pageSize: 8 },
        userGroupIdsOfNews,
      }),
      data => updateInfinite(newsId, data, isLike),
    );

    queryClient.setQueryData<SingleNewsToReadQuery>(
      useSingleNewsToReadQuery.getKey({ newsId }),
      data => updateSingleNews(isLike, data),
    );
  };

  return { updateNewsCache };
};
