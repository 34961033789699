import {
  ChatRoomMessage,
  NewsAttachmentType,
  UserProfile,
  UserType,
} from '../../../../graphql/operations';
import uuid from 'react-native-uuid';
import { SelectedForm, SelectedInfoPage } from '../../../components/Common/Media/types';

interface ICreatePendingChatRoomMessage {
  id?: string;
  content: ChatRoomMessage['content'];
  quote?: ChatRoomMessage['quote'];
  appUser: Pick<UserProfile, 'firstname' | 'lastname' | 'userId' | 'userType'>;
  chatRoomId: string;
  isContainingInfoPageLinks?: boolean;
  isContainingFormLinks?: boolean;
  infoPagesOrForms?: SelectedForm[] | SelectedInfoPage[];
  attachment?: {
    otcPath: string;
    fileName: string;
    type: NewsAttachmentType;
  };
}

export const createPendingChatRoomMessage = ({
  id,
  content,
  quote,
  appUser,
  chatRoomId,
  isContainingFormLinks,
  isContainingInfoPageLinks,
  infoPagesOrForms,
  attachment,
}: ICreatePendingChatRoomMessage): ChatRoomMessage => {
  const messageId = id ?? uuid.v4().toString();
  return {
    id: messageId,
    userProfileId: appUser?.userId,
    content,
    pending: true,
    chatRoomId,
    updatedAt: new Date(),
    createdAt: new Date(),
    readByCurrentUser: true,
    quote,
    author: {
      firstname: appUser.firstname,
      lastname: appUser.lastname,
      userId: appUser.userId,
      userType: UserType.Employee,
    },
    forms: isContainingFormLinks ? infoPagesOrForms : undefined,
    infoPages: isContainingInfoPageLinks ? infoPagesOrForms : undefined,
    attachment: attachment
      ? {
          id: messageId,
          s3Path: 'deprecated',
          otcPath: attachment.otcPath,
          fileName: attachment.fileName,
          type: attachment.type,
        }
      : undefined,
  };
};
