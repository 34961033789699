import { News, PinnedNews, PinnedNewsListQuery } from '../../../../graphql/operations';

type PrependPinnedNewsToQueryCacheProps = {
  data?: PinnedNewsListQuery;
  news: News;
};

export const prependPinnedNewsToQueryCache = ({
  data,
  news,
}: PrependPinnedNewsToQueryCacheProps) => {
  const newsAlreadyExists = data?.pinnedNewsList
    .flatMap(newsFromData => newsFromData.id)
    .includes(news.id);
  if (newsAlreadyExists) {
    return data;
  }
  const newPinnedNews: PinnedNews = {
    id: news.id,
    title: news.title,
    userGroupId: news.userGroupId,
    userGroupIds: news.userGroupIds,
    author: news.author,
  };
  if (!data) {
    return {
      pinnedNewsList: [newPinnedNews],
    };
  }
  return {
    pinnedNewsList: [newPinnedNews, ...data?.pinnedNewsList],
  };
};
