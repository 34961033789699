import { Dimensions, Image, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import React from 'react';
import { EnrichedShiftData } from '../types';

type ScheduleListViewItemProps = {
  item: EnrichedShiftData;
  token: string;
};

export const ScheduleListViewItem = (props: ScheduleListViewItemProps) => {
  const styles = createStyles();

  return (
    <TouchableHighlight
      underlayColor={'transparent'}
      activeOpacity={0.97}
      style={props.item.isToday ? [styles.row, styles.isToday] : styles.row}
    >
      <>
        {props.item.icon?.color ? (
          <View
            style={[styles.shiftIcon, { backgroundColor: props.item.icon.color }]}
            testID="ShiftIconFromColor"
          >
            <Text style={styles.shiftIconText}>{props.item.shiftAbbreviation}</Text>
          </View>
        ) : props.item.icon?.uri ? (
          <Image
            source={props.item.icon}
            style={{ width: 32, height: 32 }}
            testID="ShiftIconFromPNG"
          />
        ) : (
          <View style={[styles.shiftIcon, { backgroundColor: '#fff' }]}>
            <Text style={styles.shiftIconText}>{props.item.shiftAbbreviation}</Text>
          </View>
        )}
        <View style={{ flex: 1 }}>
          <Text style={styles.shiftDescription}>{props.item.shiftDescription}</Text>
          {props.item.shiftDescription !== 'Kein Dienst' && (
            <Text style={{ alignSelf: 'center' }}>
              {props.item.shiftDescription === 'Urlaub'
                ? '-'
                : props.item.from + '-' + props.item.to}
            </Text>
          )}
        </View>
      </>
    </TouchableHighlight>
  );
};

const createStyles = () =>
  StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: '#DCDCDC',
      backgroundColor: '#fff',
      borderBottomWidth: 1,
      width: Dimensions.get('screen').width,
      height: 50,
      paddingLeft: 8,
      marginBottom: 8,
    },
    isToday: {
      borderColor: '#94908a',
      backgroundColor: '#c2c1c1',
      borderWidth: 2,
      borderBottomWidth: 2,
    },
    shiftIcon: {
      height: 35,
      width: 35,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: '#000',
      borderWidth: 1,
    },
    shiftIconText: {
      fontWeight: '500',
    },
    shiftDescription: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: '500',
    },
  });
