import * as Notifications from 'expo-notifications';
import { useTheme } from 'react-native-paper';
import React, { useState } from 'react';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { GetUserPreferenceQuery } from '../../../../graphql/operations';
import { themeProp } from '../../../types/main';
import { usePreferences } from '../../../hooks/preferences/preferences';
import { SwitchSetting } from '../../../components/SwitchSetting/SwitchSetting';

export const PushNotificationChannel = (props: {
  text: string;
  id: string;
  permission: boolean;
  preference: GetUserPreferenceQuery['getUserPreference'];
  userId: string;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateNotificationChannel } = usePreferences();

  const theme = useTheme() as themeProp;

  const handlePushSettings = async (channel: string, value: boolean) => {
    /* @todo must be awaited */
    void Notifications.requestPermissionsAsync();
    setIsUpdating(true);
    const newSettings = {
      notificationsForChatMessagesEnabled:
        channel === 'ChatMessage' ? value : props.preference.notificationsForChatMessagesEnabled,
      notificationsForNewsEnabled:
        channel === 'News' ? value : props.preference.notificationsForNewsEnabled,
      notificationsForInquiriesEnabled:
        channel === 'Inquiry' ? value : props.preference.notificationsForInquiriesEnabled,
    };

    await updateNotificationChannel(props.userId, newSettings).finally(() => {
      setIsUpdating(false);
    });
  };

  const determineSwitchValue = () => {
    switch (props.id) {
      case 'News':
        return props.preference.notificationsForNewsEnabled;
      case 'ChatMessage':
        return props.preference.notificationsForChatMessagesEnabled;
      case 'Inquiry':
        return props.preference.notificationsForInquiriesEnabled;
      default:
        break;
    }
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <SwitchSetting
          label={props.text}
          value={determineSwitchValue()}
          onValueChange={val => onOnline(() => handlePushSettings(props.id, val))}
          disabled={isUpdating || !props.permission}
          color={theme.customColors.primary}
        />
      )}
    </OfflineErrorModal>
  );
};
