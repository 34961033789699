import { useTheme } from 'react-native-paper';
import React, { useState } from 'react';
import {
  GetUserPreferenceQuery,
  useGetUserPreferenceQuery,
  useUpdateUserPreferenceMutation,
} from '../../../../graphql/operations';
import { themeProp } from '../../../types/main';
import { useQueryClient } from '@tanstack/react-query';
import { SwitchSetting } from '../../../components/SwitchSetting/SwitchSetting';

type TranslationSwitchPropTypes = {
  text: string;
  permission: boolean;
  preference: GetUserPreferenceQuery['getUserPreference'];
  userId: string;
  onOnline: (fn: () => void) => void;
  isDisabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TranslationSwitch = ({
  text,
  permission,
  preference,
  userId,
  onOnline,
  isDisabled,
  setDisabled,
}: TranslationSwitchPropTypes) => {
  const queryClient = useQueryClient();
  const [translationsEnabled, setTranslationsEnabled] = useState<boolean>(
    preference.translationEnabled,
  );
  const theme = useTheme() as themeProp;
  const updateUserPreferences = useUpdateUserPreferenceMutation();

  const onSuccessSetUserTranslationPreferenceMutation = (value: boolean) => {
    /* @todo must be awaited */
    void queryClient.invalidateQueries(useGetUserPreferenceQuery.getKey({ userId: userId }));
    setTranslationsEnabled(value);
  };

  const handleToggleTranslations = (value: boolean) => {
    setTranslationsEnabled(value);
    setDisabled(!isDisabled);
    updateUserPreferences.mutate(
      {
        input: {
          translationEnabled: value,
        },
      },
      {
        onSuccess: () => onSuccessSetUserTranslationPreferenceMutation(value),
      },
    );
  };

  return (
    <SwitchSetting
      label={text}
      value={translationsEnabled}
      onValueChange={value => onOnline(() => handleToggleTranslations(value))}
      disabled={!permission}
      color={theme.customColors.primary}
    />
  );
};
