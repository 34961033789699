export enum TenantLogin {
  tenantIdInput = 'tenant-id',
  submitButton = 'tenant-submit',
  errorView = 'tenant-error',
}

export enum UserLogin {
  screenView = 'user-login--screen',
  usernameInput = 'user-login-username',
  passwordInput = 'user-login-password',
  submitButton = 'user-login-submit-button',
}

export enum NewsFeed {
  newslist = 'newslist',
}

export enum SettingsTestId {
  languageSelectionConfirmationButton = 'language-selection-confirmation-button',
}

export enum NewsTestId {
  translateButton = 'translate-button',
  commentButton = 'comment-button',
}

export enum GenericPopUpTestId {
  modal = 'generic-popup-modal',
  actionButton = 'generic-popup-action-button',
}

export enum TemporaryPasswordChangeTestId {
  introduction = 'temporary-password-change-introduction',
  passwordInput = 'temporary-password-change-password-input',
  confirmButton = 'temporary-password-change-confirm-button',
}

export enum NewsTimelineTestId {
  container = 'news-timeline-container',
}

export enum TabBarTestId {
  chat = 'tab-bar-chat',
}

export enum ChatHome {
  createNewButton = 'chat-home-create-new-button',
}

export enum TermsAndServicePopup {
  button = 'terms-and-service-popup-button',
}

export enum LoginLegal {
  privacySubmitButton = 'login-legal-privacy-submit-button',
  consentSubmitButton = 'login-legal-consent-submit-button',
}

export enum ChatRoom {
  chatMessageInput = 'chat-room-chat-message-input',
  sendButton = 'chat-room-send-button',
}
