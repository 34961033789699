import React, { useEffect } from 'react';
import { Keyboard, SafeAreaView, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { useMemo } from 'react';
import { useStore } from '../../stores/store';
import { useAppStatus } from '../../hooks/common/appStatus';
import { MaintenanceCard } from './MaintenanceCard';
import { Log } from '../../utils/Log';
import {
  useMaintenanceStatusOfTenant,
  UseMaintenanceStatusOfTenantHookLocation,
} from './hooks/useMaintenanceStatusOfTenant';
import { useTenantContext } from '../../hooks/tenant';

export const MaintenanceScreen = () => {
  const { isRetrievingTenant, fetchTenantAndUpdateState } = useTenantContext();
  const styles = useMemo(() => createStyles(), []);
  const { appMovedToView } = useAppStatus();
  const tenantName = useStore.getState().tenantName;

  const refreshTenantData = async (tenantNameArg: string) => {
    try {
      await fetchTenantAndUpdateState(tenantNameArg);
    } catch (e) {
      Log.warning(e, {
        message: 'something went wrong when calling fetchTenantAndUpdateState in MaintenanceScreen',
      });
    }
  };

  useEffect(() => {
    if (appMovedToView) {
      refreshTenantData(tenantName).catch(e => {
        Log.warning(e, { message: 'an error occurred while refreshing tenant data' });
      });
    }
  }, [appMovedToView]);

  useMaintenanceStatusOfTenant(UseMaintenanceStatusOfTenantHookLocation.MaintenanceScreen);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <SafeAreaView style={styles.container}>
        <MaintenanceCard
          displayIcon
          onPress={() =>
            /* @todo must be awaited */
            void fetchTenantAndUpdateState(tenantName).catch(e => {
              Log.warning(e, { message: 'an error occurred while refreshing tenant data' });
            })
          }
          buttonLoading={isRetrievingTenant}
        />
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'space-evenly',
      backgroundColor: '#fff',
    },
  });
