import { useCallback, useEffect, useState } from 'react';

interface IUseChatRoomEntranceDate {
  chatRoomId: string;
}

export const useChatRoomEntranceDate = ({ chatRoomId }: IUseChatRoomEntranceDate) => {
  const [chatRoomEntranceDate, setChatRoomEntranceDate] = useState<Date>(new Date());

  const resetChatRoomEntranceDate = useCallback(() => setChatRoomEntranceDate(new Date()), []);

  useEffect(() => {
    resetChatRoomEntranceDate();
    return resetChatRoomEntranceDate();
  }, [chatRoomId]);

  return { chatRoomEntranceDate };
};
