import { StyleSheet } from 'react-native';
import { themeProp } from '../types/main';

export const authStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      height: '100%',
      backgroundColor: theme.customColors.background,
    },
    headline: {
      fontWeight: 'bold',
      marginBottom: 12,
    },
    description: {
      marginBottom: 32,
    },
    input: { marginBottom: 32, backgroundColor: theme.customColors.textInputBackground },
    button: { borderRadius: 5 },
    buttonText: {
      lineHeight: theme.buttons?.largeButton || 35,
      color: theme.customColors.pureWhite,
    },
    smallButtonText: {
      fontSize: 12,
      color: theme.customColors.primary,
    },
    smallText: {
      fontSize: 12,
      textAlign: 'center',
      color: theme.customColors.textDark,
    },
    codeFieldRoot: { marginVertical: 30, paddingHorizontal: 20 },
    cell: {
      width: 40,
      height: 40,
      lineHeight: 38,
      paddingLeft: 1,
      fontSize: 21,
      borderWidth: 2,
      color: theme.colors.onSurface,
      backgroundColor: theme.customColors.textInputBackground,
      borderColor: theme.customColors.borders,
      textAlign: 'center',
      borderRadius: 6,
    },
    focusCell: {
      borderColor: theme.customColors.primary,
    },
    bottomFloatingView: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 10,
      marginHorizontal: 30,
      marginTop: 20,
    },
    iconBox: {
      padding: 25,
      borderRadius: 20,
      marginBottom: 40,
      backgroundColor: theme.customColors.gray96,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2,
    },
    error: {
      color: theme.customColors.error,
      marginBottom: 10,
      marginTop: 0,
    },
    textInput: {
      backgroundColor: theme.customColors.textInputBackground,
      marginBottom: 8,
      marginTop: 8,
    },
  });
