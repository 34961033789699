import * as LocalAuthentication from 'expo-local-authentication';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useStore } from '../../stores/store';
import { getBiometricData } from '../../utils';
import { Log } from '../../utils/Log';

type Args = {
  onSuccess: () => void;
  promptMessage?: string;
  cancelLabel?: string;
  autoTryLogin?: boolean;
};

export const useBiometricLogin = ({
  promptMessage,
  onSuccess,
  cancelLabel,
  autoTryLogin = true,
}: Args) => {
  const [areBiometricsUsed, setAreBiometricsUsed] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [biometricLoginData, setBiometricLoginData] = useState<
    undefined | { isEnabled: boolean; username: string; password: string }
  >();
  const login = useStore(s => s.login);

  const determineBiometricsEnabled = async () => {
    try {
      const { isEnabled, username, password, isEnabledOnDevice } = await getBiometricData();
      if (isEnabled && username && password && isEnabledOnDevice) {
        setAreBiometricsUsed(true);
        setBiometricLoginData({
          isEnabled,
          username,
          password,
        });
      }
    } catch (e) {
      Log.error(e, { message: 'cannot determine if buimetric login is enabled or not' });
    }
  };

  const tryBiometricLogin = useCallback(async () => {
    try {
      const { isEnabled, password, username } = biometricLoginData || {};
      if (!isEnabled || !username || !password) {
        return;
      }
      const result = await LocalAuthentication.authenticateAsync({
        promptMessage,
        cancelLabel,
        disableDeviceFallback: true,
      });
      if (result.success) {
        setIsLoggingIn(true);
        await login({ username, password });

        onSuccess();
      }
    } catch (e) {
      Log.error(e, { message: 'cannot login user via biometrics' });
    } finally {
      setIsLoggingIn(false);
    }
  }, [login, biometricLoginData]);

  useEffect(() => {
    void determineBiometricsEnabled();
  }, []);

  useEffect(() => {
    if (autoTryLogin && areBiometricsUsed && biometricLoginData && Platform.OS !== 'web') {
      void tryBiometricLogin();
    }
  }, [autoTryLogin, areBiometricsUsed, biometricLoginData]);

  return { tryBiometricLogin, areBiometricsUsed, isLoggingIn };
};
