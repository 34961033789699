import React, { useMemo } from 'react';
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native';
import { NoDataRefresh } from '../../../../stories/Loading/NoDataRefresh';
import { useAppTheme } from '../../../../styles/theme';
import { themeProp } from '../../../../types/main';
import { InquiryAppBar, InquiryListItem } from '../../components';
import { FloatingActionButton } from '../../../../stories/Button/FloatingActionButton';
import { InquiryStatus, useListInquiriesQuery } from '../../../../../graphql/operations';
import { useRefreshControlWithTimeout } from '../../../../hooks/list/useRefreshControlWithTimeout';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../../router/AppNavigator';

export const InquiryOverviewScreen = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const inquiries = useListInquiriesQuery({ input: { status: InquiryStatus.Open } });
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => inquiries.refetch(),
    errorMessageToLog: `failed to refetch data for the inquiry overview screen`,
  });

  const handleListItemNavigation = (id: string) => {
    navigation.navigate('InquiryDetails', { inquiryId: id });
  };

  const handleNewInquiryNavigation = () => {
    navigation.navigate('NewInquiry');
  };

  return (
    <View style={styles.screenContainer}>
      <InquiryAppBar />
      <FlatList
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={() => void onRefresh()}
            tintColor={theme.customColors.refreshControlSpinner}
          />
        }
        data={inquiries.data?.listInquiries}
        renderItem={({ item }) => (
          <InquiryListItem
            id={item.id}
            title={item.title}
            createdAt={item.createdAt}
            relativeOnInquiry={item.relativeOnInquiry}
            onPress={handleListItemNavigation}
            hasUnread={item.hasUnread}
          />
        )}
        style={styles.flatlistContainer}
        keyExtractor={inquiry => inquiry.id}
        ListEmptyComponent={
          <>
            {!!inquiries.data && (
              <View style={{ marginTop: 30 }}>
                <NoDataRefresh
                  text="Es konnten leider keine Anfragen gefunden werden."
                  onPress={() => void inquiries.refetch()}
                  isLoading={inquiries.isRefetching}
                />
              </View>
            )}
          </>
        }
      />
      <FloatingActionButton onPress={handleNewInquiryNavigation} />
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    screenContainer: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    flatlistContainer: {
      backgroundColor: theme.colors.background,
      paddingTop: 1,
    },
    listFooterContainer: {
      display: 'flex',
      marginBottom: 5,
      marginTop: 10,
    },
  });
