import { EncodingType } from 'expo-file-system';
import { appendAttachmentToFormDataForHandleFilesRequest } from './appendAttachmentToFormDataForHandleFilesRequest';
import { UploadType, FileToUploadProps } from './types';

export interface IConstructFormDataForHandleFilesRequest {
  filesToUpload: FileToUploadProps[];
  uploadType: UploadType;
  encoding?: EncodingType;
}

export const constructFormDataForHandleFilesRequest = ({
  uploadType,
  filesToUpload,
  encoding,
}: IConstructFormDataForHandleFilesRequest) => {
  const data = new FormData();
  data.append('uploadType', uploadType);

  for (const file of filesToUpload) {
    appendAttachmentToFormDataForHandleFilesRequest({ data, fileToUpload: file, encoding });
  }
  return data;
};
