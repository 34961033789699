import React from 'react';
import { FlatList, RefreshControl } from 'react-native';
import { useAppTheme } from '../../../styles/theme';
import { LinkItem } from './LinkItem';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { SelectedForm } from '../../../components/Common/Media/types';
import { useInfiniteListFormsToReadQuery } from '../../../../graphql/operations';
import { useNetInfo } from '@react-native-community/netinfo';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';

type FormLinkListPropTypes = {
  append: (form: SelectedForm) => void;
  remove: (formId: string) => void;
  selectedFormIds?: SelectedForm['id'][];
};

export const FormLinkList = ({ append, remove, selectedFormIds }: FormLinkListPropTypes) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const netInfo = useNetInfo();
  const forms = useInfiniteListFormsToReadQuery(
    'pageInput',
    { pageInput: { pageSize: 20 } },
    {
      enabled: netInfo.isConnected ?? false,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.formList.forms.length,
          0,
        );
        if (alreadyFetched === lastPage.formList.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 20,
            token: lastPage.formList?.token,
          },
        };
      },
    },
  );

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => forms.refetch(),
    errorMessageToLog: `failed to refetch data for the form link list`,
  });

  const displayedForms =
    forms.data?.pages.flatMap(page => page.formList).flatMap(formList => formList.forms) ?? [];

  return (
    <FlatList
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          onRefresh={async () => await onRefresh()}
          tintColor={theme.customColors.refreshControlSpinner}
        />
      }
      data={displayedForms}
      renderItem={({ item }) => (
        <LinkItem
          title={item.title}
          id={item.id}
          append={append}
          remove={remove}
          isSelected={selectedFormIds?.includes(item.id)}
        />
      )}
      style={{
        backgroundColor: theme.colors.background,
        width,
        marginTop: 1,
      }}
      keyExtractor={form => form.id}
      windowSize={8}
      ListEmptyComponent={
        <>
          {!!forms.data && (
            <NoDataRefresh
              text="Es konnten keine Formulare gefunden werden."
              onPress={async () => await onRefresh()}
              isLoading={forms.isRefetching}
            />
          )}
        </>
      }
    />
  );
};
