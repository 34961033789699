import { ImageResult } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { Platform } from 'react-native';

interface IMatchPickedImageWithUploadedImageResponse {
  imagePickerResults: Array<ImageResult | ImagePicker.ImagePickerAsset>;
  uploadedImageResponse: {
    fileName: string;
    index: number;
  };
}

/**
 * This function is used for matching the picked images with uploaded image response when there are multiple image uploads.
 * Strategy differs between mobile and web because image picker returns the file location and name on mobile,
 * but only the base64 representation of the image on web.
 */
export const matchPickedImageWithUploadedImageResponse = ({
  imagePickerResults,
  uploadedImageResponse,
}: IMatchPickedImageWithUploadedImageResponse) => {
  const { index, fileName } = uploadedImageResponse;
  let matchedImage = imagePickerResults.find(image => image.uri.includes(fileName));
  if (!matchedImage) {
    if (Platform.OS !== 'web') {
      throw new Error('could not find a match of the picked image among uploaded images');
    }
    matchedImage = imagePickerResults[index];
  }
  return matchedImage;
};
