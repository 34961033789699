import React from 'react';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { Keyboard } from 'react-native';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useAppTheme } from '../../../styles/theme';
import { FormReplyStatus as FormReplyStatusType } from '../../../../graphql/operations';

interface IUseFormReplyStatusActionSheetPropTypes {
  setStatus: (status: FormReplyStatusType) => void;
}

export const useFormStatusActionSheet = ({
  setStatus,
}: IUseFormReplyStatusActionSheetPropTypes) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const theme = useAppTheme();

  const showActionSheet = () => {
    Keyboard.dismiss();
    showActionSheetWithOptions(
      {
        options: ['Bestätigen', 'Ablehnen', 'Abbrechen'],
        cancelButtonIndex: 2,
        icons: [
          <Icon name="check-circle" size={25} key="0" color={theme.customColors.text} />,
          <Icon name="close-circle" size={25} key="1" color={theme.customColors.text} />,
          <Icon
            name="close"
            size={25}
            key="5"
            testID="cancelIcon"
            color={theme.customColors.text}
          />,
        ],
        containerStyle: {
          backgroundColor: theme.colors.background,
        },
        textStyle: {
          color: theme.customColors.text,
        },
      },
      async buttonIndex => {
        switch (buttonIndex) {
          case 0:
            return setStatus(FormReplyStatusType.Approved);
          case 1:
            return setStatus(FormReplyStatusType.Rejected);
          /*           case 2:
            return setStatus(FormReplyStatusType.Resubmit); */
          default:
            return;
        }
      },
    );
  };

  return { showActionSheet };
};
