import React, { useMemo } from 'react';
import {
  useListUserConnectionsQuery,
  UserConnectionType,
  UserConnectionSubtype,
} from '../../../../graphql/operations';
import { useThemeStore } from '../../../stores/ThemeStore';
import { getName } from '../getName';
import Icon from '@expo/vector-icons/MaterialIcons';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { ElementDropdown } from '../../../components/ElementDropdown/ElementDropdown';

export interface RelativesDropdownProps {
  onChangeValue?(val: string | null): void;
  clientId: string;
}

export const RelativesDropdown = ({ onChangeValue, clientId }: RelativesDropdownProps) => {
  const { colorScheme } = useThemeStore();
  const relatives = useListUserConnectionsQuery(
    {
      input: {
        incomingId: clientId,
        type: UserConnectionType.Relative,
      },
    },
    {
      enabled: !!clientId,
    },
  );

  const dropdownItems = useMemo(() => {
    return (
      relatives.data?.listUserConnections.map(connection => ({
        label: getName({
          firstname: connection.outgoing?.firstname,
          lastname: connection.outgoing?.lastname,
        }),
        value: connection.outgoing!.userId,
        icon: (
          <Icon
            name={connection.subtype === UserConnectionSubtype.PrimaryContact ? 'star' : 'person'}
            color={colorScheme === 'dark' ? 'white' : 'black'}
            size={25}
          />
        ),
      })) ?? []
    );
  }, [relatives.data?.listUserConnections]);

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => relatives.refetch(),
    errorMessageToLog: 'an error occurred while refetching relatives for the new inquiry screen',
  });

  return (
    <ElementDropdown
      items={dropdownItems}
      isSearchable={false}
      emptyPlaceholder="Keine Angehörigen gefunden"
      isLoading={relatives.isLoading || isRefreshing}
      onRefresh={onRefresh}
      placeholder="Angehörige/r"
      onChangeValue={onChangeValue}
      disabled={clientId === ''}
    />
  );
};
