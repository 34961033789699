import React, { memo, useCallback, useMemo } from 'react';
import { ChatImageMessage } from './ChatImageMessage';
import { Text } from 'react-native-paper';
import { Form, InfoPage, NewsAttachmentType } from '../../../../graphql/operations';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { ChatAudioMessage } from './ChatAudioMessage';
import { StyleSheet } from 'react-native';
import { ChatDocumentMessage } from './ChatDocumentMessage';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ChatInfoPagesMessageList } from './ChatInfoPagesMessageList';
import { ChatFormsMessageList } from './ChatFormsMessageList';
import { ExtendedMessage } from '../types';
import { getAttachmentOfChatMessage } from '../utils/getAttachmentOfChatMessage';

type ChatQuoteFactoryPropTypes = {
  quote: ExtendedMessage;
  onPress?: (imageSource?: string) => void;
};

export const ChatQuoteFactory = memo(({ quote, onPress }: ChatQuoteFactoryPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), []);
  const quotedUserFullName = useMemo(
    () => quote.user.name ?? 'Ehemaliger Benutzer',
    [quote.user.name],
  );
  const attachment = useMemo(() => getAttachmentOfChatMessage(quote), [quote]);
  const attachmentType = useMemo(() => attachment?.type, [attachment?.type]);
  const attachmentPath = useMemo(() => attachment?.otcPath, [attachment?.otcPath]);
  const forms = useMemo(
    () =>
      quote.forms?.map(
        form => ({ id: form.id, title: form.title }) as Pick<Form, 'id' | 'title'>,
      ) ?? [],
    [quote.forms],
  );
  const infoPages = useMemo(
    () =>
      quote.infoPages?.map(
        infoPage => ({ id: infoPage.id, title: infoPage.title }) as Pick<InfoPage, 'id' | 'title'>,
      ) ?? [],
    [quote.infoPages],
  );
  const hasForms = useMemo(() => forms.length !== 0, [forms.length]);
  const hasInfoPages = useMemo(() => infoPages.length !== 0, [infoPages.length]);
  const onLongPress = useCallback(() => {}, []);

  const renderContent = useCallback(() => {
    if (hasInfoPages) {
      return <ChatInfoPagesMessageList onLongPress={onLongPress} infoPages={infoPages} />;
    }

    if (hasForms) {
      return <ChatFormsMessageList onLongPress={onLongPress} forms={forms} />;
    }

    if (attachmentType === NewsAttachmentType.Image && attachmentPath) {
      return (
        <ChatImageMessage imagePath={attachmentPath} onLongPress={onLongPress} onPress={onPress} />
      );
    }

    if (attachmentType === NewsAttachmentType.Audio && attachmentPath) {
      return <ChatAudioMessage audioPath={attachmentPath} />;
    }

    if (attachmentType === NewsAttachmentType.Document && attachmentPath) {
      return (
        <ChatDocumentMessage
          onLongPress={onLongPress}
          documentName={quote.text}
          documentPath={attachmentPath}
          downloadable={true}
          type={UploadType.ChatMessage}
        />
      );
    }

    return <Text style={styles.quote}>{quote.text}</Text>;
  }, [quote, hasForms, hasInfoPages, infoPages, forms, attachmentPath, attachmentType]);

  return (
    <>
      <Text style={styles.name}>{quotedUserFullName}</Text>
      {renderContent()}
    </>
  );
});

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    name: {
      color: theme.colors.primary,
      marginBottom: 5,
      fontWeight: 'bold',
    },
    quote: {
      fontSize: 14,
      fontStyle: 'italic',
      color: theme.customColors.text,
    },
  });
