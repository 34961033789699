import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Portal } from 'react-native-paper';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import { Popup } from '../../components/Popup';
import { usePopup } from '../../hooks';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { useReloginStore } from '../../utils/ReloginStore';
import { NewPasswordAbstract } from '../Auth/NewPasswordAbstract';
import { useRequestConfirmationCode } from './useRequestConfirmationCode';

export const ReloginNewPassword = () => {
  const { setPassword } = useReloginStore();
  const [isLoading, setIsLoading] = useState(false);
  const errorPopup = usePopup(
    'Fehler',
    'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
  );
  const { requestConfirmationCode } = useRequestConfirmationCode(errorPopup);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  const onSubmitForm = async (password: string) => {
    try {
      setIsLoading(true);
      await requestConfirmationCode();
    } catch (e) {
      return;
    } finally {
      setIsLoading(false);
    }
    setPassword(password);
    navigation.navigate('ReloginCheckInbox');
  };
  return (
    <>
      <NewPasswordAbstract onSubmitForm={onSubmitForm} />
      <Portal>
        <Popup {...errorPopup.popup} />
      </Portal>
      <LoadingBlocker visible={isLoading} />
    </>
  );
};
