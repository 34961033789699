import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { UseQueryResult } from '@tanstack/react-query';
import { UnreadMessageCountQuery } from '../../../graphql/operations';
import { setBadgeCount } from '../../utils/setBadgeCount';

export const useSetBadgeCount = (unreadMessageCount: UseQueryResult<UnreadMessageCountQuery>) => {
  const setUnreadMessageBadge = useCallback(async (messageCount: number) => {
    await setBadgeCount(messageCount);
  }, []);

  useEffect(() => {
    const messageCount = unreadMessageCount.data?.unreadMessageCount;
    if (unreadMessageCount.isFetched && Platform.OS !== 'web' && messageCount !== undefined) {
      /* @todo must be awaited */
      void setUnreadMessageBadge(messageCount);
    }
  }, [unreadMessageCount.isFetched, unreadMessageCount.data]);
};
