import React from 'react';
import { Linking, Platform, StyleSheet, Text, View } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import { IInfoPagesListItem } from '../types';
import { themeProp } from '../../../types/main';
import { useNavigation } from '@react-navigation/native';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { StackNavigationProp } from '@react-navigation/stack';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { InfoPageAttachmentType } from '../../../../graphql/operations';

export const InfoPagesListItem: React.FC<IInfoPagesListItem> = ({ item }): JSX.Element => {
  const { title, attachment, downloadable, updatedAt } = item;
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const theme = useTheme() as themeProp;
  const style = createStyles(theme);
  const showDownloadIcon =
    (downloadable || Platform.OS === 'web') &&
    attachment?.type !== InfoPageAttachmentType.ExternalUrl;

  const handlePress = async () => {
    if (attachment?.type === InfoPageAttachmentType.ExternalUrl) {
      await Linking.openURL(attachment.externalUrl!);
      return;
    }
    if (attachment?.s3Path) {
      navigation.navigate('OtcPdfReader', {
        source: attachment?.s3Path,
        type: UploadType.InfoPage,
        downloadable: downloadable ?? false,
        fileName: title,
      });
    }
  };

  if (!item) {
    return <></>;
  }

  return (
    <TouchableRipple
      onPress={handlePress}
      style={style.container}
      rippleColor={theme.customColors.lightRipple}
    >
      <>
        <View style={style.iconWrapper}>
          <Icon
            name={
              attachment?.type === InfoPageAttachmentType.ExternalUrl ? 'link' : 'file-document'
            }
            size={24}
            style={style.icon}
          />
        </View>
        <View style={style.textWrapper}>
          <Text style={style.text}>{title}</Text>
          <Text style={style.date}>{moment(updatedAt, 'YYYY-MM-DD').format('LL')}</Text>
        </View>
        {showDownloadIcon && (
          <View style={style.iconWrapper}>
            <Icon name="download" size={24} style={style.icon} />
          </View>
        )}
        {attachment?.type === InfoPageAttachmentType.ExternalUrl && (
          <View style={style.iconWrapper}>
            <Icon name="arrow-top-right-thick" size={24} style={style.icon} />
          </View>
        )}
      </>
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      paddingVertical: 12,
      marginTop: -1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.customColors.listRowItemBackground,
      borderRadius: theme.roundness,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      width: '100%',
    },
    iconWrapper: {
      marginLeft: -14,
      marginRight: 14,
    },
    icon: {
      padding: 8,
      color: theme.customColors.gray50,
      marginLeft: 28,
    },
    textWrapper: {
      flex: 1,
      paddingRight: 8,
    },
    text: {
      fontSize: 16,
      fontWeight: '400',
      color: theme.customColors.textDark,
    },
    date: {
      flex: 1,
      fontSize: 13,
      fontWeight: '400',
      color: theme.customColors.gray40,
      borderRadius: 10,
      padding: 1,
    },
    description: {
      marginTop: 2,
      fontSize: 13,
      color: theme.customColors.gray40,
    },
  });
