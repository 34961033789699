import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ChatHomeScreen } from '../../features/chat/screens/ChatHomeScreen';

const Stack = createStackNavigator<ChatNavigatorParamList>();

export type ChatNavigatorParamList = {
  ChatHomeScreen: undefined;
};

export const ChatNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName="ChatHomeScreen"
      screenOptions={{
        headerMode: 'screen',
      }}
    >
      <Stack.Screen
        name="ChatHomeScreen"
        options={{
          headerShown: false,
        }}
        component={ChatHomeScreen}
      />
    </Stack.Navigator>
  );
};
