/**
 * @deprecated: this file will be replaced by several others in the future so please don't extend in anymore
 */

import { useEffect, useState } from 'react';
import { ImageSourcePropType, ImageURISource, Platform } from 'react-native';
import shortHash from 'shorthash2';
import NetInfo from '@react-native-community/netinfo';
import * as FileSystem from 'expo-file-system';
import { downloadFile } from '../../components/Common/FileUpload/api';
import { loadSource } from './loadSource';
import { UploadType } from '../../components/Common/FileUpload/types';

type CachedImageType = {
  source?: ImageSourcePropType | string;
  type: UploadType;
};

export const useCachedImage = (prop?: CachedImageType) => {
  const [source, setSource] = useState<ImageSourcePropType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [canDownload, setCanDownload] = useState(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(connection => {
      if (connection.type === 'wifi') {
        setCanDownload(true);
      } else {
        setCanDownload(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    let handleCaching = true;
    const cacheOrLoadFromCache = async () => {
      if (prop?.source) {
        if (Platform.OS === 'web') {
          const newSource = await loadSource(prop.source, prop.type);
          setSource(newSource);
          setIsLoading(false);
          return;
        }
        const newSource = await cacheOrLoadFromS3(prop.source, canDownload, prop.type);
        if (handleCaching) {
          setSource(newSource);
        }
      }

      if (handleCaching) {
        setIsLoading(false);
        handleCaching = false;
      }
    };
    /* @todo must be awaited */
    void cacheOrLoadFromCache();

    return () => {
      handleCaching = false;
    };
  }, [canDownload, prop?.source]);

  return {
    source,
    isLoading,
    canDownload,
    setCanDownload,
  };
};

const handleNewLinkCreation = async (
  uri: string | undefined,
  canDownload: boolean,
  uploadType: string,
) => {
  if (!uri) {
    return uri;
  }

  if (uri.includes('http')) {
    //web resource
    return uri;
  }

  if (uri.includes('file://')) {
    //local resource
    return uri;
  }

  const extension = uri.split('.').pop();
  const hashedNameWithExtension = `${shortHash(uri)}.${extension}`;
  const hashedName = `${shortHash(uri)}`;
  const pathOnDevice = `${FileSystem.cacheDirectory}/${hashedName}`;
  const pathOnDeviceForHashNameWithExtension = `${FileSystem.cacheDirectory}/${hashedNameWithExtension}`;

  const fileInfo = await FileSystem.getInfoAsync(pathOnDevice);
  const fileInfoForHashNameWithExtension = await FileSystem.getInfoAsync(
    pathOnDeviceForHashNameWithExtension,
  );

  if (fileInfoForHashNameWithExtension.exists) {
    return fileInfoForHashNameWithExtension.uri;
  }

  if (fileInfo.exists) {
    return fileInfo.uri;
  }
  if (canDownload) {
    let storageResult;
    if (uploadType === 'static') {
      const staticRes = await fetch(process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${uri}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      storageResult = await staticRes.json();
    } else {
      storageResult = await downloadFile({
        s3Path: uri,
        uploadType: uploadType,
      });
    }
    const downloadResult = await FileSystem.downloadAsync(
      storageResult,
      pathOnDeviceForHashNameWithExtension,
    );
    return downloadResult.uri;
  }
  return undefined;
};

const cacheOrLoadSingleImage = async (
  source: ImageURISource | string,
  canDownload: boolean,
  uploadType: string,
) => {
  if (typeof source === 'string') {
    const uri = await handleNewLinkCreation(source, canDownload, uploadType);
    if (!uri) {
      return undefined;
    }
    return {
      uri: uri,
    };
  } else {
    const uri = await handleNewLinkCreation(source.uri, canDownload, uploadType);
    if (!uri) {
      return undefined;
    }
    return {
      ...source,
      uri: uri,
    };
  }
};

const cacheOrLoadFromS3 = async (
  source: ImageSourcePropType | string,
  canDownload: boolean,
  uploadType: string,
) => {
  if (typeof source === 'number') {
    //it's a static image file: https://reactnative.dev/docs/images#static-image-resources
    return source;
  }

  if (Array.isArray(source)) {
    const resultArray = [];
    for (const s3Source of source) {
      const uri = await cacheOrLoadSingleImage(s3Source, canDownload, uploadType);
      //When one image is not loaded the whole array is not shown
      if (!uri) {
        return undefined;
      }
      resultArray.push(uri);
    }
    return resultArray;
  }

  return cacheOrLoadSingleImage(source, canDownload, uploadType);
};
