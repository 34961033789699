import { ComponentProps } from 'react';
import { KeyboardAvoidingView as InternalKeyboardAvoidingView } from 'react-native';

import { withStyledComponent } from './withStyledComponent';

type InternalKeyboardAvoidingViewProps = ComponentProps<typeof InternalKeyboardAvoidingView>;
export const KeyboardAvoidingView = withStyledComponent<
  InternalKeyboardAvoidingViewProps['style'],
  InternalKeyboardAvoidingViewProps
>(InternalKeyboardAvoidingView);
