import { Dimensions, ScrollView, View } from 'react-native';
import { Appbar, Divider, useTheme } from 'react-native-paper';
import React, { useEffect } from 'react';

import Markdown from 'react-native-markdown-display';
import { MdStyles } from './MarkdownStyles';
import { themeProp } from '../../types/main';
import { useLegal } from '../../hooks/common/legal';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { AppBar } from '../../components/AppBar/AppBar';

export const LegalScreen = () => {
  const { imprint, privacy, fetchImprint, fetchPrivacy } = useLegal();
  const theme = useTheme() as themeProp;
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Legal'>['navigation']>();

  useEffect(() => {
    /* @todo must be awaited */
    void fetchImprint();
    void fetchPrivacy();
  }, []);

  return (
    <>
      <AppBar
        title="Rechtliches"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ScrollView
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 16,
          paddingTop: 10,
          backgroundColor: '#fff',
          height: Dimensions.get('window').height,
        }}
      >
        <Markdown style={MdStyles(theme)} mergeStyle>
          {imprint}
        </Markdown>
        <Divider
          style={{
            marginVertical: 15,
            backgroundColor: theme.customColors.borders,
          }}
        />
        <Markdown style={MdStyles(theme)} mergeStyle>
          {privacy}
        </Markdown>
        <View style={{ height: 20 }} />
      </ScrollView>
    </>
  );
};
