export const prependHttpsSchema = (link: string) => {
  if (link.includes('://')) {
    return link;
  }
  return `https://${link}`;
};

export const urlParse = (url: string) => {
  let name = '';
  const params: any = {};
  if (!url) {
    return null;
  }
  if (url?.indexOf('?') < 0) {
    name = url;
  } else {
    const parts = url.split('?');
    name = parts[0];
    parts.forEach((x, i) => {
      if (i > 0) {
        const obj = x.split('=');
        if (obj) {
          params[obj[0]] = obj.length > 1 ? obj[1] : undefined;
        }
      }
    });
  }
  return { name, params };
};
