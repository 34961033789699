import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { AddMediaFABGroup, StockImageGalleryModal } from '../components';
import { AddMediaScreenProps } from '../../types';
import { FileGallery } from '../../../../components/FileGallery/FileGallery';
import { LoadingBlocker } from '../../../../components/Common/Loading/LoadingBlocker';
import { useFileGalleryPicker } from '../../../../components/FileGallery/hooks';
import { useFileStore } from '../../../../stores/FileStore';
import { ImageViewerModal } from '../../../../components/Common/Modals/ImageViewerModal';
import { useImageViewerModal } from '../../../../components/Common/Modals/hooks/useImageViewerModal';
import { FullScreenModal } from '../../../../components/FullScreenModal';
import { File } from '../../../../components/FileGallery/types/';

export const AddMediaScreen = ({ navigation, route }: AddMediaScreenProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { setFiles } = useFileStore();
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const [fileForPreviewModal, setFileForPreviewModal] = useState<File<unknown>>();

  const { removeFile, files, handleCameraPhoto, handleGalleryPick, isLoading, appendNewFiles } =
    useFileGalleryPicker({ includeVideosAmongPhotos: false, existingFiles: route.params.files });

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const onFileGalleryTilePress = (file: File<unknown>) => {
    setFileForPreviewModal(file);
    return setImageViewerModalVisible(true);
  };

  useEffect(() => {
    setImageViewerModalImages(files.map(file => ({ url: file.uri })));
  }, [files]);

  return (
    <FullScreenModal
      title="Medien"
      show={true}
      onClose={() => navigation.goBack()}
      onSubmit={() => {
        setFiles(files);
        navigation.goBack();
      }}
      isSubmitDisabled={isLoading}
    >
      <View style={styles.container}>
        <FileGallery
          files={files}
          allowsDeletion
          onDeletePress={file => removeFile(file.id)}
          onTilePress={onFileGalleryTilePress}
        />
      </View>
      <AddMediaFABGroup
        handleCameraPhoto={handleCameraPhoto}
        handleGalleryPick={handleGalleryPick}
        handleStockImageLibrary={() => setGalleryModalOpen(true)}
      />
      <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent} />
      <ImageViewerModal
        visible={imageViewerModalVisible}
        setVisible={setImageViewerModalVisible}
        imageUrls={imageViewerModalImages}
        activeIndex={imageViewerModalImages?.findIndex(
          previewImage => previewImage.url === fileForPreviewModal?.uri,
        )}
      />
      <StockImageGalleryModal
        show={galleryModalOpen}
        onPressCancel={() => setGalleryModalOpen(false)}
        appendNewFiles={appendNewFiles}
      />
    </FullScreenModal>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    screenContainer: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    container: {
      marginTop: 15,
      flex: 1,
      alignItems: 'center',
      paddingHorizontal: 10,
    },
    header: {
      fontSize: 18,
      marginRight: -38,
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
