/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteChatRoomsQuery, useInfiniteNewsToReadQuery } from '../../../graphql/operations';
import { AppState } from 'react-native';

const useProvideAppStatus = () => {
  const [appMovedToView, setAppMovedToView] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (nextAppState === 'active') {
        setAppMovedToView(true);
        /* @todo must be awaited */
        void queryClient.invalidateQueries(
          useInfiniteNewsToReadQuery.getKey({
            pageInput: { pageSize: 8 },
          }),
        );
        /* @todo must be awaited */
        void queryClient.invalidateQueries(
          useInfiniteChatRoomsQuery.getKey({
            pageInput: { pageSize: 20 },
          }),
        );
      } else {
        setAppMovedToView(false);
      }
    });
    return () => subscription.remove();
  }, []);

  return { appMovedToView };
};

const AppStatusContext = createContext({});

export const AppStatusProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const value = useProvideAppStatus();

  return <AppStatusContext.Provider value={value}>{props.children}</AppStatusContext.Provider>;
};

export const useAppStatus = () =>
  useContext(AppStatusContext) as ReturnType<typeof useProvideAppStatus>;
