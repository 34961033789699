type WithLoadingIndicatorPropTypes = {
  on: boolean;
  render: () => JSX.Element;
  renderWhileLoading: () => JSX.Element;
};

export const WithLoadingIndicator = ({
  on,
  render,
  renderWhileLoading,
}: WithLoadingIndicatorPropTypes) => {
  if (on) {
    return render();
  }
  return renderWhileLoading();
};
