import { EncodingType } from 'expo-file-system';
import { groupBy } from '../../../utils/groupBy';
import { Log } from '../../../utils/Log';
import { UploadType, Uri } from './types';
import { uploadFilesOfUploadType } from './uploadFilesOfUploadType';

type FileToUpload = {
  uri: Uri;
  uploadType: UploadType;
};

export interface IUploadFiles {
  filesToUpload: FileToUpload[];
  encoding?: EncodingType;
}

export const uploadFiles = async ({ filesToUpload, encoding }: IUploadFiles) => {
  if (filesToUpload.length === 0) {
    throw new Error('no files to upload');
  }

  const filesGrouppedByUploadType = groupBy(filesToUpload, file => file.uploadType);

  const uploadFileRequests = [];

  for (const [uploadType, files] of Object.entries(filesGrouppedByUploadType)) {
    const request = uploadFilesOfUploadType({
      uploadType: uploadType as UploadType,
      fileUris: files.flatMap(file => file.uri),
      encoding,
    });
    uploadFileRequests.push(request);
  }

  try {
    return await Promise.all(uploadFileRequests);
  } catch (e) {
    const error = e as Error;
    const message = `an error occurred while uploading files: ${error.message}`;
    Log.error(error, { message });
    throw new Error(message);
  }
};
