import { useState } from 'react';
import { File } from '../types';

interface IUseFileGallery<P> {
  existingFiles?: File<P>[];
}

export const useFileGallery = <P>({ existingFiles = [] }: IUseFileGallery<P>) => {
  const [files, setFiles] = useState<File<P>[]>(existingFiles);

  const appendNewFiles = (items: File<P>[]) => {
    setFiles([...files, ...items]);
  };

  const removeFile = (itemId: string) => {
    setFiles(files.filter(attachment => attachment.id !== itemId));
  };

  const noSelectedFiles = files.length === 0;

  return {
    appendNewFiles,
    removeFile,
    files,
    noSelectedFiles,
  };
};
