import { Log } from '../../../utils/Log';
import {
  LoginResponse,
  MonthFromToData,
  ScheduleDataResponse,
  ShiftData,
  ShiftDetails,
  ShiftDetailsWithIcon,
} from './types';
import { hashPassword } from './utils';

export const loginToVivendi = async (
  username: string,
  password: string,
  endpoint: string,
): Promise<LoginResponse | undefined> => {
  const signature = hashPassword(password);
  try {
    const response = await fetch(`${endpoint}/api/v2/pep/User/Login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: signature,
      }),
    });
    const json = (await response.json()) as LoginResponse;
    return json;
  } catch (error) {
    Log.error(error);
  }
};

export const getScheduleVivendi = async (
  token: string,
  employeeId: string,
  monthFromTo: MonthFromToData,
  endpoint: string,
): Promise<ScheduleDataResponse | undefined> => {
  try {
    const response = await fetch(
      `${endpoint}/api/v2/pep/Dienstplan/Mitarbeiter/${employeeId}?status=Ist&von=${monthFromTo.from}&bis=${monthFromTo.to}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      },
    );
    const json = await response.json();
    return json;
  } catch (error) {
    Log.error(error);
  }
};

export const getShiftDetailsVivendi = async (
  token: string,
  shiftId: number,
  endpoint: string,
): Promise<ShiftDetails | undefined> => {
  try {
    const response = await fetch(`${endpoint}/api/v2/pep/Dienst/${shiftId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    });
    const json = await response.json();
    return json;
  } catch (error) {
    Log.error(error);
  }
};

export const loadIconFromVivendi = async (
  token: string,
  shiftId: number,
  endpoint: string,
): Promise<{ uri: string } | { color: string } | undefined> => {
  try {
    const response = await fetch(`${endpoint}/api/v2/pep/Dienst/${shiftId}/Icon`, {
      method: 'GET',
      headers: {
        Accept: '*',
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    });
    if (response.headers && response.headers.get('content-type')?.includes('png')) {
      const icon = await response.blob();
      const base64Icon = await blobToBase64(icon);
      return { uri: base64Icon };
    } else if (response.headers && response.headers.get('content-type')?.includes('svg')) {
      const svg = await response.text();
      const svgColor = svg.substr(svg.indexOf('#'), 7);
      return { color: svgColor };
    }
  } catch (error) {
    Log.error(error);
  }
};

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const loadShiftDataFromVivendi = async (
  scheduleData: ShiftData[],
  token: string,
  endpoint: string,
): Promise<Map<number, ShiftDetailsWithIcon>> => {
  const shiftDetailsMap = new Map<number, ShiftDetailsWithIcon>();
  for (const shift of scheduleData) {
    if (!shiftDetailsMap.has(shift.DienstId)) {
      const shiftDetailResponse = await getShiftDetailsVivendi(token, shift.DienstId, endpoint);
      const shiftIconRespone = await loadIconFromVivendi(token, shift.DienstId, endpoint);
      if (shiftIconRespone && shiftDetailResponse) {
        const newShiftDetails: ShiftDetailsWithIcon = {
          ...shiftDetailResponse,
          IconDetails: shiftIconRespone,
        };
        shiftDetailsMap.set(shiftDetailResponse.id, newShiftDetails);
      } else if (shiftDetailResponse) {
        shiftDetailsMap.set(shiftDetailResponse.id, shiftDetailResponse);
      }
    }
  }
  return shiftDetailsMap;
};
