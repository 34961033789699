import { StyleSheet, View } from 'react-native';
import React, { memo, useMemo } from 'react';
import { ChatFormsMessageListItem } from './ChatFormsMessageListItem';
import { SelectedForm } from '../../../components/Common/Media/types';

type ChatFormsMessageListPropTypes = {
  onLongPress?: () => void;
  forms: SelectedForm[];
};

export const ChatFormsMessageList = memo(
  ({ forms, onLongPress }: ChatFormsMessageListPropTypes) => {
    const styles = useMemo(() => createStyles(), []);

    return (
      <View style={styles.container}>
        {forms.map(form => (
          <ChatFormsMessageListItem form={form} onLongPress={onLongPress} key={form.id} />
        ))}
      </View>
    );
  },
);

const createStyles = () =>
  StyleSheet.create({
    container: {
      marginTop: 6,
    },
  });
