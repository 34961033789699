import { StyleSheet, Image } from 'react-native';
import { Headline, TouchableRipple, Text } from 'react-native-paper';
//@ts-ignore
import Logo from '../../../../assets/Luci_logo_quer_magenta_800x500.png';

import React, { useMemo } from 'react';
import { themeProp } from '../../../types/main';
import { NewCachedImage } from '../../../features/caching/components/NewCachedImage';
import { useAppTheme } from '../../../styles/theme';
import { UploadType } from '../../../components/Common/FileUpload/types';

type HelpCardProps = {
  title: string;
  description: string;
  logoPath?: string;
  onPress: () => void;
};

export const HelpCard = (props: HelpCardProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <TouchableRipple
      onPress={props.onPress}
      style={styles.container}
      rippleColor={theme.customColors.lightRipple}
    >
      <>
        {props.logoPath ? (
          <NewCachedImage
            source={props.logoPath ? { uri: props.logoPath } : Logo}
            type={UploadType.Static}
            style={styles.image}
            onPress={props.onPress}
          />
        ) : (
          <Image source={Logo} style={styles.image} />
        )}

        <Headline style={styles.title}>{props.title}</Headline>
        <Text style={styles.description}>{props.description}</Text>
      </>
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      paddingVertical: 15,
      paddingHorizontal: 40,
      marginVertical: 10,
      backgroundColor: theme.customColors.background3,
      width: '84%',
      borderRadius: theme.roundness,
    },
    title: {
      fontWeight: '600',
      marginBottom: 10,
    },
    description: {
      textAlign: 'center',
    },
    image: {
      height: 100 * (500 / 800),
      width: 100,
      marginBottom: 0,
    },
  });
