import React, { useCallback, memo, useMemo } from 'react';
import { ChatBubble, ChatBubblePropTypes } from '../../../components/ChatBubble/ChatBubble';
import { ChatDocumentMessage } from './ChatDocumentMessage';
import { ChatFormsMessageList } from './ChatFormsMessageList';
import { ChatFormReplyMessage } from './ChatFormReplyMessage';
import { ChatInfoPagesMessageList } from './ChatInfoPagesMessageList';
import { ChatTextMessage } from './ChatTextMessage';
import { ChatImageMessage } from './ChatImageMessage';
import { ChatAudioMessage } from './ChatAudioMessage';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ChatQuoteBox } from './ChatQuoteBox';
import { ChatQuoteFactory } from './ChatQuoteFactory';
import { extendChatRoomMessage } from '../utils/extendChatRoomMessage';
import { useAppTheme } from '../../../styles/theme';

export type ChatMessageFactoryPropTypes = Omit<ChatBubblePropTypes, 'children'> & {
  chatRoomId?: string;
  type?: UploadType;
};

export const ChatMessageFactory = memo(
  ({
    type = UploadType.ChatMessage,
    onPress,
    onLongPress,
    onSelect,
    message,
    chatRoomId,
    isSelectable,
    isSelected,
    isFromAppUser,
    isAvatarOrNameOfOtherUserShown,
    isReceivedTickShown = true,
    isNextMessageFromSameUser,
    isPreviousMessageFromSameUser,
    iconNextToName,
    isDownloadConfirmationNeeded,
  }: ChatMessageFactoryPropTypes) => {
    const theme = useAppTheme();
    const hasForms = useMemo(() => message.forms && message.forms.length !== 0, [message.forms]);
    const hasInfoPages = useMemo(
      () => message.infoPages && message.infoPages.length !== 0,
      [message.infoPages],
    );
    const onLongPressMessage = useCallback(() => onLongPress(message), [message]);

    const renderMessage = useCallback(() => {
      if (message.image) {
        return (
          <ChatImageMessage
            type={type}
            imagePath={message.image}
            onLongPress={onLongPressMessage}
            onPress={onPress}
            isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
          />
        );
      }

      if (message.documentUri) {
        return (
          <ChatDocumentMessage
            type={type}
            onLongPress={onLongPressMessage}
            documentPath={message.documentUri}
            documentName={message.text}
            downloadable={true}
            isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
          />
        );
      }

      if (message.audio) {
        return (
          <ChatAudioMessage
            audioPath={message.audio}
            isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
          />
        );
      }

      if (hasForms) {
        return <ChatFormsMessageList forms={message.forms!} onLongPress={onLongPressMessage} />;
      }

      if (hasInfoPages) {
        return (
          <ChatInfoPagesMessageList
            infoPages={message.infoPages!}
            onLongPress={onLongPressMessage}
          />
        );
      }
      if (message.formReply) {
        return (
          <ChatFormReplyMessage
            text={message.text}
            formReply={message.formReply}
            onLongPress={onLongPressMessage}
            chatRoomId={chatRoomId}
          />
        );
      }
      return (
        <ChatTextMessage
          currentMessage={message}
          position={isFromAppUser ? 'left' : 'right'}
          quote={
            message.quote ? (
              <ChatQuoteBox>
                <ChatQuoteFactory
                  quote={extendChatRoomMessage({ message: message.quote })}
                  onPress={onPress}
                />
              </ChatQuoteBox>
            ) : undefined
          }
          customTextStyle={{
            fontStyle: message.deletedAt ? 'italic' : 'normal',
            color: message.deletedAt ? theme.customColors.textGray : theme.customColors.text,
          }}
        />
      );
    }, [message, hasForms, hasInfoPages, isDownloadConfirmationNeeded]);

    return (
      <ChatBubble
        onLongPress={onLongPress}
        onPress={onPress}
        onSelect={onSelect}
        message={message}
        isSelectable={isSelectable}
        isSelected={isSelected}
        isFromAppUser={isFromAppUser}
        isAvatarOrNameOfOtherUserShown={isAvatarOrNameOfOtherUserShown}
        isReceivedTickShown={isReceivedTickShown}
        isNextMessageFromSameUser={isNextMessageFromSameUser}
        isPreviousMessageFromSameUser={isPreviousMessageFromSameUser}
        iconNextToName={iconNextToName}
        isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
      >
        {renderMessage()}
      </ChatBubble>
    );
  },
);
