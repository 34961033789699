import { useState } from 'react';
import { DownloadImage } from '../useSignedOtcUrl/DownloadImage';
import { Log } from '../../utils/Log';
import { getCachedImageUri } from '../util/imageCache/getCachedImageUri';
import { persistImage } from '../util/imageCache/persistImage';
import { Platform } from 'react-native';

type FetchCachedImage = DownloadImage & {
  signedImageUrlFallback(downloadImage: DownloadImage): Promise<string>;
};

export const useCachedImageV2 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [uri, setUri] = useState<string>();
  const [hasError, setHasError] = useState(false);

  const fetchCachedImage = async ({
    storageFilename,
    type,
    signedImageUrlFallback,
  }: FetchCachedImage) => {
    try {
      const cachedImageUri = await getCachedImageUri(storageFilename);
      if (cachedImageUri) {
        setUri(cachedImageUri);
        return;
      }
      const signedUrl = await signedImageUrlFallback({ storageFilename, type });
      if (Platform.OS !== 'web') {
        const persistedImage = await persistImage(storageFilename, signedUrl);
        setUri(persistedImage);
        return;
      }
      setUri(signedUrl);
    } catch (e) {
      Log.error(e);
      Log.error('cannot download uri ' + uri);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchCachedImage,
    isLoading,
    hasError,
    uri,
  };
};
