import React, { Dispatch, useState, SetStateAction } from 'react';
import DropDownPicker from 'react-native-dropdown-picker';
import { InquiryStatus } from '../../../../graphql/operations';
import { useThemeStore } from '../../../stores/ThemeStore';
import { getInquiryStatusValues } from './getInquiryStatusValues';

export interface ClientDropdownProps {
  onChangeValue?(val: string | null): void;
  value: string;
  setValue: Dispatch<SetStateAction<InquiryStatus>>;
}

export const StatusDropdown = ({ onChangeValue, value, setValue }: ClientDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorScheme } = useThemeStore();
  const values = getInquiryStatusValues();

  return (
    <DropDownPicker<string>
      onChangeSearchText={onChangeValue}
      disableLocalSearch
      multiple={false}
      value={value}
      setValue={setValue}
      open={isOpen}
      items={values}
      setOpen={setIsOpen}
      placeholder={'Status'}
      min={1}
      modalTitle="Status"
      onSelectItem={item => onChangeValue && onChangeValue(item.value || null)}
      theme={colorScheme === 'dark' ? 'DARK' : 'LIGHT'}
    />
  );
};
