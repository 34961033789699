import { createStackNavigator } from '@react-navigation/stack';
import { NewsTimelineScreen } from '../../features/news/NewsTimelineScreen';
import React from 'react';
import { AppBar } from '../../components/AppBar/AppBar';

const Stack = createStackNavigator<NewsNavigatorParamList>();

export type NewsNavigatorParamList = {
  NewsOverview: undefined;
};

// eslint-disable-next-line no-restricted-syntax
export default (): JSX.Element => {
  return (
    <Stack.Navigator
      initialRouteName="NewsOverview"
      screenOptions={{
        header: () => <AppBar />,
        headerMode: 'screen',
      }}
    >
      <Stack.Screen
        name="NewsOverview"
        options={{
          title: 'News',
          headerShown: false,
        }}
        component={NewsTimelineScreen}
      />
    </Stack.Navigator>
  );
};
