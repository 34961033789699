import React, { useMemo } from 'react';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import { Platform } from 'react-native';

export const KeyboardAvoidingViewIos = ({ children }: { children: React.ReactNode }) => {
  const styles = useMemo(() => createStyles(), []);

  if (Platform.OS === 'ios') {
    return (
      <KeyboardAvoidingView
        contentContainerStyle={styles.contentContainer}
        style={styles.keyboardAvoidingView}
        behavior="height"
      >
        {children}
      </KeyboardAvoidingView>
    );
  }
  return <>{children}</>;
};

const createStyles = () =>
  StyleSheet.create({
    contentContainer: { flex: 1 },
    keyboardAvoidingView: { flex: 1 },
  });
