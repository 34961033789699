import { ImageResult, manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { ImagePickerAsset } from 'expo-image-picker';
import { Log } from './Log';

const SMALL_IMAGE_SIZE_CONSTANT = 300;
const LARGE_IMAGE_SIZE_CONSTANT = 1500;
const IMAGE_QUALITY_CONSTANT = 0.5;

export const compressImage = async (
  image: ImagePickerAsset,
  options?: { compact: boolean },
): Promise<ImageResult | ImagePickerAsset> => {
  const newImageSize = options?.compact ? SMALL_IMAGE_SIZE_CONSTANT : LARGE_IMAGE_SIZE_CONSTANT;
  try {
    if (image.height <= newImageSize && image.width <= newImageSize) {
      return await manipulateAsync(image.uri, undefined, {
        compress: IMAGE_QUALITY_CONSTANT,
        format: image.uri.split('.').pop() === 'png' ? SaveFormat.PNG : SaveFormat.JPEG,
      });
    }
    return await manipulateAsync(
      image.uri,
      [
        {
          resize: {
            width: image.width >= image.height ? newImageSize : undefined,
            height: image.height >= image.width ? newImageSize : undefined,
          },
        },
      ],
      {
        compress: 0.5,
        format: image.uri.split('.').pop() === 'png' ? SaveFormat.PNG : SaveFormat.JPEG,
        base64: true,
      },
    );
  } catch (error) {
    Log.error(error, { message: 'error while compressing image' });
    return image;
  }
};
