import { Appbar } from 'react-native-paper';
import React, { useMemo } from 'react';
import { useAppTheme } from '../../../../styles/theme';
import { StyleSheet } from 'react-native';
import { PlaceholderAppBarAction } from '../../../../components/Common/AppBar/PlaceholderAppBarAction';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../../router/AppNavigator';

type InquiryAppBarPropTypes = {
  isForResolvedInquiries?: boolean;
};

export const InquiryAppBar = ({ isForResolvedInquiries }: InquiryAppBarPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  return (
    <Appbar.Header
      testID="appbar"
      theme={{
        colors: {
          primary: theme.colors.surface,
        },
      }}
    >
      {isForResolvedInquiries ? (
        <Appbar.BackAction onPress={() => navigation.goBack()} />
      ) : (
        <PlaceholderAppBarAction />
      )}
      <Appbar.Content
        title={isForResolvedInquiries ? 'Geschlossene Anfragen' : 'Anfragen'}
        color={theme.customColors.primary}
        titleStyle={isForResolvedInquiries ? styles.resolvedInquiriesTitle : styles.defaultTitle}
      />
      {isForResolvedInquiries ? (
        <PlaceholderAppBarAction />
      ) : (
        <Appbar.Action
          icon="archive-outline"
          size={26}
          color={theme.customColors.gray40}
          onPress={() => navigation.navigate('ResolvedInquiries')}
        />
      )}
    </Appbar.Header>
  );
};

const createStyles = () =>
  StyleSheet.create({
    defaultTitle: {
      fontSize: 25,
      fontWeight: 'bold',
    },
    resolvedInquiriesTitle: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  });
