import { useState } from 'react';
import { useDeleteInquiryMessageMutation } from '../../../../graphql/operations';
import { InquiryMessageActionOption } from '../types';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { alert } from '../../../utils/alert';

import { Log } from '../../../utils/Log';
import { useStore } from '../../../stores/store';
import { ExtendedMessage } from '../../../features/chat/types';

interface IUseInquiryMessageActions {
  onSuccessMessageDeletion: () => void;
}

interface IDisplayOptions {
  options: string[];
  optionTypes: InquiryMessageActionOption[];
  hasDeleteAction: boolean;
  cancelButtonIndex: number;
  inquiryMessage: ExtendedMessage;
}

export const useInquiryMessageActions = ({
  onSuccessMessageDeletion,
}: IUseInquiryMessageActions) => {
  const userProfile = useStore(s => s.userProfile);
  const { showActionSheetWithOptions } = useActionSheet();
  const [isDeleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false);
  const [inquiryMessageToBeDeleted, setInquiryMessageToBeDeleted] = useState<ExtendedMessage>();
  const deleteInquiryMessage = useDeleteInquiryMessageMutation();

  const handleInquiryMessageDeletion = async () => {
    try {
      if (inquiryMessageToBeDeleted) {
        await deleteInquiryMessage.mutateAsync({
          input: { inquiryMessageId: inquiryMessageToBeDeleted?._id.toString() },
        });
        onSuccessMessageDeletion();
      }
    } catch (error) {
      const e = error as Error;
      Log.error(`an error occurred while attempting to delete inquiry message: ${e.message}`);
      alert(
        'Fehler',
        'Nachricht kann nicht gelöscht werden. Bitte versuchen Sie es später noch einmal.',
      );
    }
    setDeleteConfirmModalVisible(false);
  };

  const determineOptions = (inquiryMessage: ExtendedMessage) => {
    const options: Array<string> = [];
    const optionTypes: Array<InquiryMessageActionOption> = [];

    const hasDeleteAction =
      !inquiryMessage.deletedAt && inquiryMessage.user._id === userProfile?.userId;

    if (hasDeleteAction) {
      options.push('Löschen');
      optionTypes.push(InquiryMessageActionOption.delete);
    }

    options.push('Abbrechen');
    optionTypes.push(InquiryMessageActionOption.cancel);

    const cancelButtonIndex = options.length - 1;

    return { options, optionTypes, hasDeleteAction, cancelButtonIndex };
  };

  const displayOptions = ({
    cancelButtonIndex,
    hasDeleteAction,
    optionTypes,
    options,
    inquiryMessage,
  }: IDisplayOptions) => {
    if (options.length > 1) {
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex?: number) => {
          if (buttonIndex !== undefined) {
            switch (optionTypes[buttonIndex]) {
              case InquiryMessageActionOption.delete:
                if (hasDeleteAction) {
                  setInquiryMessageToBeDeleted(inquiryMessage);
                  setDeleteConfirmModalVisible(true);
                }
                break;
              default:
                break;
            }
          }
        },
      );
    }
  };

  const showActionSheet = (inquiryMessage: ExtendedMessage) => {
    const { options, optionTypes, cancelButtonIndex, hasDeleteAction } =
      determineOptions(inquiryMessage);
    displayOptions({ cancelButtonIndex, hasDeleteAction, inquiryMessage, options, optionTypes });
  };

  return {
    isDeleteConfirmModalVisible,
    setDeleteConfirmModalVisible,
    handleInquiryMessageDeletion,
    showActionSheet,
  };
};
