import React, { useEffect, useMemo } from 'react';
import {
  TouchableOpacity,
  GestureResponderEvent,
  ImageSourcePropType,
  View,
  StyleSheet,
  ImageProps,
  Image,
  ImageURISource,
  ViewStyle,
  Platform,
} from 'react-native';
import { ActivityIndicator, IconButton, Text } from 'react-native-paper';
import { useCachedImage } from '../hooks';
// @ts-ignore
import FallbackLogo from '../../../../assets/expo/icon.png';
import { useAppTheme } from '../../../styles/theme';
import { AutoHeightImage } from '../../../components/AutoHeightImage';
import { themeProp } from '../../../types/main';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { StockImageMetadata } from '../../../../graphql/operations';
import { ImageAttributionLink } from './ImageAttributionLink';

type NewCachedAutoHeightImageProps = Omit<ImageProps, 'source' | 'resizeMode'> & {
  source?: ImageProps['source'] | string;
  width: number;
  initialHeight?: number;
  type: UploadType;
  onPress?: (event: GestureResponderEvent, source: ImageSourcePropType) => void;
  onLongPress?: () => void;
  resizeMode?: ImageProps['resizeMode'];
  onImageLoaded?: (source: ImageURISource | undefined) => void;
  containerStyles?: ViewStyle;
  imageMetaData?: StockImageMetadata;
};

export const NewCachedAutoHeightImage = (props: NewCachedAutoHeightImageProps) => {
  const theme = useAppTheme();
  const { source, isLoading, setCanDownload } = useCachedImage({
    source: props.source,
    type: props.type,
  });
  const styles = useMemo(() => createStyles(theme, props.width), [theme, props.width]);
  const isImageDownloaded = source || props.source === undefined;
  const isImageContained = Boolean(props.resizeMode === 'contain');
  const shouldAttributionBeShown = Boolean(props.imageMetaData) && Platform.OS !== 'web';

  useEffect(() => {
    if (source && isLoading === false && props.onImageLoaded) {
      if (typeof source === 'string') {
        props.onImageLoaded({ uri: source } as ImageURISource);
      } else {
        props.onImageLoaded(source as ImageURISource);
      }
    }
  }, [isLoading, source]);

  if (isLoading) {
    return <ActivityIndicator {...props} style={[props.style, { backgroundColor: 'white' }]} />;
  }

  const determineContent = () => {
    if (isImageDownloaded && isImageContained) {
      return (
        <Image
          source={source || FallbackLogo}
          style={{ height: (props.width * 9) / 16, width: props.width }}
          resizeMode="contain"
        />
      );
    }
    if (isImageDownloaded) {
      return (
        <AutoHeightImage
          {...props}
          width={props.width}
          initialHeight={props.initialHeight}
          style={props.style}
          source={source || FallbackLogo}
          resizeMode="cover"
        />
      );
    }
    return (
      <View style={[styles.imageContainer, props.containerStyles]}>
        <TouchableOpacity
          style={styles.imageIconContainer}
          onPress={() => setCanDownload(true)}
          activeOpacity={0.8}
        >
          <IconButton
            icon="download-circle"
            size={90}
            iconColor={theme.customColors.itemBorder}
            onPress={() => setCanDownload(true)}
          />
          <Text>Bild herunterladen</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <TouchableOpacity
      onPress={evt => {
        if (props.onPress) {
          props.onPress(evt, source || FallbackLogo);
        }
      }}
      onLongPress={props.onLongPress}
      style={styles.touchable}
    >
      <View>
        {shouldAttributionBeShown && <ImageAttributionLink metaData={props.imageMetaData!} />}
        {determineContent()}
      </View>
    </TouchableOpacity>
  );
};
const createStyles = (theme: themeProp, width: number) =>
  StyleSheet.create({
    imageContainer: {
      width: width,
      height: (width / 16) * 9,
      backgroundColor: 'transparent',
      marginTop: 10,
    },
    imageIconContainer: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    touchable: { backgroundColor: theme.customColors.imageBackground },
  });
