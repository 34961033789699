import React from 'react';
import { ComponentType } from 'react';
import { useBreakpoint } from '../../hooks';

type SxStyle<Style> = {
  sx?: Partial<{
    [k in ReactNativePaper.Breakpoint]: Style;
  }>;
};

export const withStyledComponent = <StyleProp, Props extends { style?: StyleProp }>(
  Component: ComponentType<Props>,
) => {
  return (props: Props & SxStyle<StyleProp>) => {
    const { style, sx } = props;
    const { breakpoint } = useBreakpoint();

    let compiledStyle = style instanceof Object ? style : {};

    if (sx && sx[breakpoint]) {
      compiledStyle = { ...compiledStyle, ...sx[breakpoint] };
    }

    return <Component {...props} style={compiledStyle} />;
  };
};
