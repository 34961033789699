import { StyleSheet, View } from 'react-native';
import { Appbar, IconButton, Menu, Text } from 'react-native-paper';
import React, { useMemo } from 'react';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { StackNavigationProp } from '@react-navigation/stack';
import { themeProp } from '../../../types/main';
import { useNavigation } from '@react-navigation/native';
import { useAppTheme } from '../../../styles/theme';

type ProfileHeaderProps = {
  name?: string | null;
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUserBlocked: boolean;
  onPressBlock: () => void;
};

export const ProfileHeader = ({
  name,
  menuOpen,
  setMenuOpen,
  isUserBlocked,
  onPressBlock,
}: ProfileHeaderProps) => {
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const theme = useAppTheme();
  const styles = createStyles(theme);
  const profileName = useMemo(() => {
    if (name) {
      return name;
    }

    return 'Ehemaliger Benutzer';
  }, [name]);

  return (
    <>
      <View style={styles.container}>
        <Appbar.BackAction onPress={() => navigation.goBack()} color={theme.colors.onSurface} />
        <View style={styles.userContainer} testID="profile-name">
          <>
            <View
              style={{
                marginLeft: 10,
              }}
            >
              <Text style={styles.name}>{profileName}</Text>
            </View>
          </>
        </View>
        <Menu
          style={styles.menuContainer}
          onDismiss={() => setMenuOpen(false)}
          visible={menuOpen}
          contentStyle={{ marginTop: 42 }}
          anchor={
            <IconButton
              testID="show-more-menu"
              style={styles.menu}
              icon="dots-vertical"
              size={24}
              onPress={() => {
                setMenuOpen(true);
              }}
            />
          }
        >
          <>
            {profileName !== 'Ehemaliger Benutzer' && (
              <Menu.Item
                testID="show-profile"
                title={'Melden'}
                leadingIcon="alert-circle-outline"
                onPress={() => {
                  setMenuOpen(false);
                  navigation.navigate('ReportAbuse');
                }}
              />
            )}
            <Menu.Item
              testID="block-user"
              title={isUserBlocked ? 'Nicht mehr blockieren' : 'Blockieren'}
              leadingIcon={isUserBlocked ? 'minus-circle-off-outline' : 'minus-circle-outline'}
              onPress={() => onPressBlock()}
            />
          </>
        </Menu>
      </View>
    </>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: 55,
      backgroundColor: theme.customColors.background3,
      borderColor: theme.customColors.borders,
      borderBottomWidth: 1.25,
    },
    userContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuContainer: {
      flex: 1,
      marginRight: 24,
    },
    name: {
      fontSize: 18,
    },
    description: {
      color: theme.customColors.textGray,
      marginVertical: -2,
    },
    date: {
      color: theme.customColors.textGray,
    },
    menu: {
      alignSelf: 'flex-end',
      marginRight: 8,
    },
    avatar: {
      marginLeft: 0,
      backgroundColor: theme.customColors.gray60,
    },
  });
