import { usePopup } from '..';

export const useShowErrorOnPromiseFalse = (
  defaultErrorTitle = 'Fehler',
  defaultErrorMessage = 'Fehler',
) => {
  const errorPopup = usePopup(defaultErrorTitle, defaultErrorMessage);

  const showErrorOnPromiseFalse = async (promise: Promise<boolean>, errorMessage: string) => {
    const success = await promise;
    if (!success) {
      errorPopup.setPopupDetails({ text: errorMessage }).toggleOpen();
    }
  };
  return { showErrorOnPromiseFalse, errorPopup };
};
