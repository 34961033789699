/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext } from 'react';
import { TenantConfigurationQuery } from '../../../graphql/operations';

const TenantContext = createContext({});

type TenantContextProps = {
  tenant?: TenantConfigurationQuery['tenantConfiguration'];
  setTenant: (tenant?: TenantConfigurationQuery['tenantConfiguration']) => void;
  isRetrievingTenant: boolean;
  fetchTenantAndUpdateState: (name: string) => Promise<void>;
};

type TenantProviderProps = TenantContextProps & {
  children: React.ReactNode;
};

export const TenantProvider = ({
  tenant,
  setTenant,
  isRetrievingTenant,
  fetchTenantAndUpdateState,
  children,
}: TenantProviderProps): JSX.Element => {
  const value = { tenant, setTenant, isRetrievingTenant, fetchTenantAndUpdateState };

  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
};

export const useTenantContext = () => useContext(TenantContext) as TenantContextProps;
