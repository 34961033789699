import AsyncStorage from '@react-native-async-storage/async-storage';
import { setItemAsync } from 'expo-secure-store';
import { Log } from './Log';
import { Constants } from './Constants';

export const storeBiometrics = async (username: string, password: string) => {
  try {
    await setItemAsync(Constants.BIOMETRICS_STORAGE_USERNAME_KEY, username);
    await setItemAsync(Constants.BIOMETRICS_STORAGE_PASSWORD_KEY, password);
    await AsyncStorage.setItem(Constants.BIOMETRICS_ENABLED, '1');
    await AsyncStorage.setItem(Constants.BIOMETRICS_HAS_USER_RECEIVED_POPUP, '1');
  } catch (e) {
    Log.error(e, { message: 'cannot store biometrics' });
    throw e;
  }
};
