import { Constants } from './Constants';
import * as FileSystem from 'expo-file-system';
import {
  createFileAsync,
  getUriForDirectoryInRoot,
  requestDirectoryPermissionsAsync,
} from './AndroidStorageAccessFramework';

interface ISaveFileToDeviceAndroid {
  uri: string;
  fileName: string;
  mimeType: string;
}

/**
 * Only Android
 */
export const saveFileToDeviceAndroid = async ({
  uri,
  mimeType,
  fileName,
}: ISaveFileToDeviceAndroid) => {
  try {
    const downloadDirectory = getUriForDirectoryInRoot(
      Constants.ANDROID_DOCUMENT_STORAGE_FOLDER_NAME,
    );
    const permissionInDownloadDirectory = await requestDirectoryPermissionsAsync(downloadDirectory);
    if (!permissionInDownloadDirectory.granted) {
      throw new Error('No permission in download directory');
    }
    const destinationFileUri = await createFileAsync(
      permissionInDownloadDirectory.directoryUri,
      fileName,
      mimeType,
    );
    const fileContentAsBase64String = await FileSystem.readAsStringAsync(uri, {
      encoding: 'base64',
    });
    await FileSystem.writeAsStringAsync(destinationFileUri, fileContentAsBase64String, {
      encoding: 'base64',
    });
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
