import { UserProfile } from '../../../../graphql/operations';

export const determineContactDetailsUnVerified = (userProfile?: UserProfile | null) => {
  if (!userProfile || !userProfile.user) {
    return false;
  }
  const { emailVerified, loginEmail, phoneNumberVerified, loginPhoneNumber } = userProfile.user;
  if (!loginEmail && !loginPhoneNumber) {
    return true;
  }
  if (
    (emailVerified === true && !Boolean(loginEmail)) ||
    (phoneNumberVerified === true && !Boolean(loginPhoneNumber))
  ) {
    return true;
  }
  return emailVerified === false && phoneNumberVerified === false;
};
