import React, { useRef, useMemo, useEffect } from 'react';
import BottomSheet from '@gorhom/bottom-sheet';
import { Platform, StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { NewsBottomSheetContent } from './NewsBottomSheetContent';
import { useIsKeyboardVisible } from '../../../../hooks/useIsKeyboardVisible/useIsKeyboardVisible';
import { Keyboard } from 'react-native';

type NewsBottomSheetPropTypes = {
  openMediaGallery: () => void;
  openInfoPagePicker: () => void;
  openFormPicker: () => void;
  renderOnTop?: () => JSX.Element;
  onPressKeyboardAction: () => void;
};

export const INITIAL_SNAP_POINT = 115;

export const NewsBottomSheet = ({
  openFormPicker,
  openInfoPagePicker,
  openMediaGallery,
  renderOnTop,
  onPressKeyboardAction,
}: NewsBottomSheetPropTypes) => {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { keyboardHeight } = useIsKeyboardVisible();
  const offset = Platform.OS === 'ios' ? keyboardHeight : 0;

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', () => {
      bottomSheetRef.current?.expand();
    });
    const keyboardDidHide = Keyboard.addListener('keyboardDidHide', () => {
      bottomSheetRef.current?.collapse();
    });
    const keyboardWillShow = Keyboard.addListener('keyboardWillShow', () => {
      bottomSheetRef.current?.expand();
    });
    const keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
      bottomSheetRef.current?.collapse();
    });
    return () => {
      keyboardDidShow.remove();
      keyboardDidHide.remove();
      keyboardWillShow.remove();
      keyboardWillHide.remove();
    };
  }, []);

  return (
    <BottomSheet
      ref={bottomSheetRef}
      snapPoints={[INITIAL_SNAP_POINT, INITIAL_SNAP_POINT + offset]}
      index={0}
      backgroundStyle={styles.backgroundContainer}
      handleStyle={styles.hidden}
      enableContentPanningGesture={false}
      style={styles.sheet}
    >
      <NewsBottomSheetContent
        renderOnTop={renderOnTop}
        openMediaGallery={openMediaGallery}
        openFormPicker={openFormPicker}
        openInfoPagePicker={openInfoPagePicker}
        onPressKeyboardAction={onPressKeyboardAction}
      />
    </BottomSheet>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    backgroundContainer: {
      backgroundColor: theme.customColors.background3,
    },
    sheet: { borderWidth: 1, borderRadius: 16, borderColor: theme.customColors.borders },
    hidden: {
      display: 'none',
    },
  });
