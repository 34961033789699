import { useState } from 'react';
import { IImageInfo } from 'react-native-image-zoom-viewer/built/image-viewer.type';

export const useImageViewerModal = () => {
  const [imageViewerModalVisible, setImageViewerModalVisible] = useState<boolean>(false);
  const [imageViewerModalImages, setImageViewerModalImages] = useState<IImageInfo[]>();

  return {
    imageViewerModalVisible,
    setImageViewerModalVisible,
    imageViewerModalImages,
    setImageViewerModalImages,
  };
};
