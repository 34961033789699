import { ComponentProps } from 'react';
import { SafeAreaView as InternalSafeAreaView } from 'react-native';

import { withStyledComponent } from './withStyledComponent';

type InternalSafeAreaViewProps = ComponentProps<typeof InternalSafeAreaView>;
export const SafeAreaView = withStyledComponent<
  InternalSafeAreaViewProps['style'],
  InternalSafeAreaViewProps
>(InternalSafeAreaView);
