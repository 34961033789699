import { Field } from 'formik';
import { FormQuestion, FormQuestionType } from '../../../../../graphql/operations';
import React, { useMemo } from 'react';
import { ValidationInput } from '../../../../components/Common/ValidationInput';
import { validateTextInput } from '../../utils/validateTextInput';
import { View, StyleSheet, Keyboard } from 'react-native';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import { BinaryAnswerButton } from '../BinaryAnswerButton/BinaryAnswerButton';
import { BinaryAnswerType } from '../../types';

type FormAnswerFactoryPropTypes = {
  question: FormQuestion;
  answer?: string | null;
  questionIdFormValue?: BinaryAnswerType;
  setQuestionIdFormValue?: (yesOrNo: BinaryAnswerType) => void;
  editable?: boolean;
};

export const FormAnswerFactory = ({
  question,
  answer,
  questionIdFormValue,
  setQuestionIdFormValue,
  editable,
}: FormAnswerFactoryPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const memoizedField = useMemo(
    () => (props: any) => (
      <ValidationInput {...props} isDisabled={!editable} defaultValue={answer} />
    ),
    [answer, editable],
  );

  if (question.type === FormQuestionType.Text || answer || !editable) {
    return (
      <Field
        component={memoizedField}
        name={question.id}
        {...question}
        validate={(value?: string) => validateTextInput(question, value)}
      />
    );
  }

  if (question.type === FormQuestionType.Yesno) {
    return (
      <View style={styles.selectButtonRow}>
        <BinaryAnswerButton
          label="Ja"
          isSelected={questionIdFormValue === 'Ja'}
          onPress={() => {
            Keyboard.dismiss();
            if (setQuestionIdFormValue) {
              setQuestionIdFormValue('Ja');
            }
          }}
        />
        <BinaryAnswerButton
          label="Nein"
          isSelected={questionIdFormValue === 'Nein'}
          onPress={() => {
            Keyboard.dismiss();
            if (setQuestionIdFormValue) {
              setQuestionIdFormValue('Nein');
            }
          }}
        />
      </View>
    );
  }

  return <></>;
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    selectButtonRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 10,
      borderBottomColor: theme.customColors.borders,
      borderBottomWidth: 1,
      paddingBottom: 10,
      marginBottom: 10,
    },
  });
