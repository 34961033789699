import { StyleSheet } from 'react-native';
import { moderateScale } from 'react-native-size-matters';
import { AppTheme } from '../../styles/theme';

export const MdStyles = (theme: AppTheme) =>
  StyleSheet.create({
    heading1: { fontSize: moderateScale(28), marginBottom: 6, color: theme.colors.onSurface },
    heading2: {
      fontSize: moderateScale(19),
      fontWeight: 'bold',
      marginBottom: 6,
      color: theme.colors.onSurface,
    },
    heading3: { fontSize: moderateScale(17), fontWeight: 'bold', color: theme.colors.onSurface },
    heading4: {
      fontSize: moderateScale(15),
      fontWeight: 'bold',
      color: theme.colors.onSurface,
    },
    heading5: { fontSize: moderateScale(13), fontWeight: 'bold', color: theme.colors.onSurface },
    paragraph: { fontSize: moderateScale(12), color: theme.colors.onSurface },
    link: { color: theme.customColors.primary, textDecorationLine: 'underline' },
    table: { borderColor: theme.colors.onSurface },
    thead: {
      color: theme.colors.onSurface,
      borderColor: theme.colors.onSurface,
    },
    tbody: {
      color: theme.colors.onSurface,
      borderColor: theme.colors.onSurface,
    },
    th: {
      color: theme.colors.onSurface,
      borderColor: theme.colors.onSurface,
      borderRightColor: theme.colors.onSurface,
      borderRightWidth: 1,
    },
    tr: {
      color: theme.colors.onSurface,
      borderColor: theme.colors.onSurface,
    },
    td: {
      color: theme.colors.onSurface,
      borderColor: theme.colors.onSurface,
      borderRightColor: theme.colors.onSurface,
      borderRightWidth: 1,
    },
  });
