import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import {
  Feature,
  Form,
  useGetFormIdIfUserHasPermissionToReadFormOrThrowQuery,
} from '../../../../graphql/operations';
import { useNavigation } from '@react-navigation/native';
import { ChatRoomScreenProps } from '../screens/types';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { LinkButton } from '../../../components/LinkButton/LinkButton';
import { useUnavailableFeatureModalStore } from '../../../hooks';
import { useQueryClient } from '@tanstack/react-query';

type ChatFormsMessageListItemPropTypes = {
  form: Pick<Form, 'title' | 'id'>;
  onLongPress?: () => void;
};

export const ChatFormsMessageListItem = ({
  form,
  onLongPress,
}: ChatFormsMessageListItemPropTypes) => {
  const queryClient = useQueryClient();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<ChatRoomScreenProps['navigation']>();
  const [isQueryEnabled, setQueryEnabled] = useState<boolean>(false);
  const [queryAttemptCount, setQueryAttemptCount] = useState(0);
  const { data, isError, error, refetch, isLoading, errorUpdateCount } =
    useGetFormIdIfUserHasPermissionToReadFormOrThrowQuery(
      { formId: form.id },
      {
        enabled: isQueryEnabled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    );
  const {
    setUnavailableFeatureModalAsNoPermissionToViewForm,
    setUnavailableFeatureModalAsUnknownError,
  } = useUnavailableFeatureModalStore();

  const queriedFormId = data?.getFormIdIfUserHasPermissionToReadFormOrThrow;

  const enableQuery = () => {
    setQueryAttemptCount(queryAttemptCount + 1);
    setQueryEnabled(true);
    void refetch();
  };

  const clearQueriedData = () =>
    queryClient.setQueryData(
      useGetFormIdIfUserHasPermissionToReadFormOrThrowQuery.getKey({ formId: form.id }),
      () => {
        return undefined;
      },
    );

  const disableQuery = () => {
    setQueryEnabled(false);
  };

  useEffect(() => {
    if (queriedFormId) {
      navigation.navigate('FormDetail', {
        formId: queriedFormId,
      });
      disableQuery();
      clearQueriedData();
    }
  }, [queriedFormId, queryAttemptCount]);

  useEffect(() => {
    if (isError && error) {
      disableQuery();
      clearQueriedData();
      const e = error as Error;
      if (e.message.includes('Not allowed')) {
        return setUnavailableFeatureModalAsNoPermissionToViewForm();
      }
      setUnavailableFeatureModalAsUnknownError();
    }
  }, [isError, error, errorUpdateCount]);

  return (
    <TouchableOpacity
      key={form.id}
      onPress={() => enableQuery()}
      onLongPress={onLongPress}
      style={styles.buttonContainer}
      disabled={isLoading}
    >
      <LinkButton
        key={form.id}
        icon="format-list-checks"
        text={form.title}
        buttonStyle={styles.link}
        feature={Feature.Form}
        id={form.id}
        navigation={navigation}
      />
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    buttonContainer: { marginBottom: 1, paddingTop: 6, paddingBottom: 4 },
    link: {
      marginVertical: 1,
      marginHorizontal: 15,
      borderColor: theme.customColors.itemBorder,
      borderWidth: 1,
      borderRadius: 5,
    },
  });
