import { Linking } from 'react-native';
import { alert } from '../../../../utils/alert';

export const openPdfForWeb = async (source: string) => {
  const canOpen = await Linking.canOpenURL(source);
  if (!canOpen) {
    return alert('Öffnen fehlgeschlagen', 'Diese PDF Datei kann leider nicht geöffnet werden');
  }
  return await Linking.openURL(source);
};
