import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { ChatRoomScreenProps } from '../screens/types';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { ActivityIndicator } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useDownloadFile } from '../../../components/Common/FileUpload/queries';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ConfirmDialog } from '../../../components/Dialog/ConfirmDialog';
import { WithLoadingIndicator } from '../../../components/WithLoadingIndicator/WithLoadingIndicator';

type ChatDocumentMessagePropTypes = {
  documentName: string;
  documentPath: string;
  onLongPress: () => void;
  downloadable?: boolean;
  type?: UploadType;
  isDownloadConfirmationNeeded?: boolean;
};

export const ChatDocumentMessage = memo(
  ({
    type = UploadType.ChatMessage,
    documentName,
    documentPath,
    onLongPress,
    downloadable = false,
    isDownloadConfirmationNeeded,
  }: ChatDocumentMessagePropTypes) => {
    const navigation = useNavigation<ChatRoomScreenProps['navigation']>();
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const [canDownload, setCanDownload] = useState(false);
    const [confirmDownloadModalVisible, setConfirmDownloadModalVisible] = useState(false);
    const downloadOTCDocument = useDownloadFile();
    const isDownloadPermissionNeeded = useMemo(
      () => isDownloadConfirmationNeeded && !canDownload,
      [isDownloadConfirmationNeeded, canDownload],
    );

    const handleNavigateToDocument = useCallback(() => {
      if (isDownloadPermissionNeeded) {
        return setConfirmDownloadModalVisible(true);
      }
      return navigation.navigate('OtcPdfReader', {
        source: documentPath,
        type,
        downloadable,
      });
    }, [isDownloadConfirmationNeeded, documentPath, type, downloadable]);

    const handleDownloadDocument = useCallback(() => {
      downloadOTCDocument.mutate(
        {
          s3Path: documentPath,
          uploadType: type,
        },
        {
          onSuccess: (result: string) => {
            window.open(result);
          },
        },
      );
    }, [documentPath, type]);

    const onPress = useCallback(
      () => (Platform.OS === 'web' ? handleDownloadDocument() : handleNavigateToDocument()),
      [],
    );

    const renderIcon = useCallback(
      () => (
        <Icon
          name={Platform.OS === 'web' ? 'file-download-outline' : 'file-document-outline'}
          size={80}
          color={theme.customColors.icon}
        />
      ),
      [],
    );

    const renderIconWhileLoading = useCallback(() => <ActivityIndicator size={80} />, []);

    const onConfirmDownload = useCallback(() => {
      setConfirmDownloadModalVisible(false);
      setCanDownload(true);
      navigation.navigate('OtcPdfReader', {
        source: documentPath,
        type,
        downloadable,
      });
    }, [documentPath, type, downloadable]);

    const onCancelDownload = useCallback(() => setConfirmDownloadModalVisible(false), []);

    return (
      <TouchableOpacity onPress={onPress} onLongPress={onLongPress}>
        <View style={styles.container}>
          <WithLoadingIndicator
            on={!downloadOTCDocument.isLoading}
            render={renderIcon}
            renderWhileLoading={renderIconWhileLoading}
          />
          <Text
            ellipsizeMode="head"
            numberOfLines={2}
            style={[
              Platform.OS === 'android' && { paddingLeft: 5 },
              {
                textDecorationLine: 'underline',
                textAlign: 'center',
                color: theme.customColors.text,
              },
            ]}
          >
            {documentName}
          </Text>
        </View>
        <ConfirmDialog
          isOpen={confirmDownloadModalVisible}
          title="Datei herunterladen?"
          onCancel={onCancelDownload}
          onConfirm={onConfirmDownload}
          confirmButtonLabel="Herunterladen"
          content="Sie nutzen momentan mobile Daten. Möchten Sie das Dokument trotzdem herunterladen?"
        />
      </TouchableOpacity>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      width: 130,
      height: 130,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: theme.customColors.itemBorder,
      borderWidth: 2,
      borderRadius: 10,
      margin: 5,
      padding: 5,
      marginTop: 12,
    },
  });
