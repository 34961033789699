import { Linking } from 'react-native';

enum AllowedDetailScreen {
  News = 'news',
  Chat = 'chat',
  Inquiry = 'inquiry',
}

export const validateLinking = async (linking: string, linkingScheme: string) => {
  const canOpen = await Linking.canOpenURL(linking);
  if (!canOpen) {
    throw new Error('cannot open linking ' + linking);
  }
  const possibleLinkings = Object.values(AllowedDetailScreen).map(
    screen => `${linkingScheme}://${screen}`,
  );
  if (!possibleLinkings.some(possibleLinking => linking.startsWith(possibleLinking, 0))) {
    throw new Error('unknown linking ' + linking);
  }
  return true;
};
