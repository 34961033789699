import { Log } from '../../../../utils/Log';

import { Headline, Portal, Text, TextInput, useTheme } from 'react-native-paper';
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native';
import React, { useState } from 'react';

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ScheduleLoginVivendiScreenProps } from '../types';
import { loginToVivendi } from '../api';
import { themeProp } from '../../../../types/main';
import { useNavigation } from '@react-navigation/native';
import { LoadingBlocker } from '../../../../components/Common/Loading/LoadingBlocker';
import { PrimaryButton } from '../../../../components/PrimaryButton';

export const ScheduleLoginVivendi = ({ route: { params } }: ScheduleLoginVivendiScreenProps) => {
  const [userNameInput, setUserNameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [userNameIsError, setUserNameIsError] = useState(false);
  const [passwordIsError, setPasswordIsError] = useState(false);
  const [responseIsError, setResponseIsError] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const navigation = useNavigation<ScheduleLoginVivendiScreenProps['navigation']>();
  const theme = useTheme() as themeProp;
  const style = createStyles(theme);

  const login = async (username: string, password: string) => {
    if (responseIsError) {
      setResponseIsError(false);
      setResponseErrorMessage('');
    }
    if (!validate(username, password)) {
      return;
    } else {
      setIsloading(true);
      const response = await loginToVivendi(username, password, params.endpoint);
      if (response && response.token) {
        navigation.navigate('ScheduleScreenVivendi', {
          token: response.token,
          endpoint: params.endpoint,
        });
      } else if (response && response.status && response.status !== 200) {
        if (response.detail) {
          setResponseErrorMessage(response.detail);
        } else if (response.title) {
          setResponseErrorMessage(response.title);
        } else {
          setResponseErrorMessage('Beim Login ist ein unbekannter Fehler aufgetreten');
          Log.error(response);
        }
        setResponseIsError(true);
      } else {
        setResponseErrorMessage('Beim Login ist ein unbekannter Fehler aufgetreten');
        setResponseIsError(true);
        Log.error(response);
      }
      setIsloading(false);
    }
  };

  const validate = (username: string, password: string) => {
    let validationSuccess = true;
    if (!username) {
      setUserNameIsError(true);
      validationSuccess = false;
    }
    if (!password) {
      setPasswordIsError(true);
      validationSuccess = false;
    }
    return validationSuccess;
  };

  return (
    <SafeAreaView style={style.safeAreaView}>
      <View style={style.container}>
        <KeyboardAvoidingView
          style={{ width: '90%' }}
          behavior="position"
          keyboardVerticalOffset={Platform.OS === 'ios' ? 85 : 0}
        >
          <Icon
            name="calendar-clock"
            size={100}
            key="0"
            color={theme.customColors.primary}
            style={style.icon}
          />
          <Text style={style.text}>
            Bitte geben Sie Ihren Vivendi Benutzernamen und Ihr Vivendi Passwort ein.
          </Text>
          <Text style={style.warning}>Hinweis: Nicht Ihre Luci Login-Daten</Text>
          <TextInput
            mode="flat"
            label="Benutzername"
            placeholder="Benutzername"
            onChangeText={value => {
              if (userNameIsError) {
                setUserNameIsError(false);
              }
              setUserNameInput(value);
            }}
            autoCapitalize="none"
            error={userNameIsError}
            testID="ScheduleLoginUsernameInput"
            style={{ backgroundColor: theme.customColors.textInputBackground }}
          />
          <Text
            style={{ color: theme.customColors.error, display: userNameIsError ? 'flex' : 'none' }}
          >
            Bitte geben Sie einen Benutzernamen ein.
          </Text>
          <TextInput
            mode="flat"
            label="Passwort"
            placeholder="Passwort"
            secureTextEntry={true}
            onChangeText={value => {
              if (passwordIsError) {
                setPasswordIsError(false);
              }
              setPasswordInput(value);
            }}
            autoCapitalize="none"
            error={passwordIsError}
            testID="ScheduleLoginPasswordInput"
            style={{ backgroundColor: theme.customColors.textInputBackground }}
          />
          <Text
            style={{
              color: theme.customColors.error,
              display: passwordIsError ? 'flex' : 'none',
            }}
          >
            Bitte geben Sie ein Passwort ein.
          </Text>
          <Text
            style={{
              color: theme.customColors.error,
              textAlign: 'center',
              display: responseIsError ? 'flex' : 'none',
              marginTop: 8,
            }}
          >
            {responseErrorMessage}
          </Text>
          <PrimaryButton
            buttonStyle={style.button}
            onPress={async () => await login(userNameInput, passwordInput)}
            text="Login"
          />
        </KeyboardAvoidingView>
        <Portal>
          <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
            <Headline
              style={{
                color: '#fff',
              }}
            >
              Bitte warten
            </Headline>
          </LoadingBlocker>
        </Portal>
      </View>
    </SafeAreaView>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.background,
      alignItems: 'center',
    },
    safeAreaView: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: theme.colors.background,
    },
    icon: {
      marginTop: -10,
      marginBottom: 12,
      alignSelf: 'center',
    },
    text: {
      textAlign: 'center',
      fontSize: 16,
    },
    warning: {
      textAlign: 'center',
      color: theme.customColors.error,
      marginTop: 8,
      marginBottom: 18,
    },
    button: {
      borderRadius: 5,
      marginTop: 24,
      marginBottom: 10,
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
