import { ChatRole, InquiryMessage, NewsAttachmentType } from '../../../../graphql/operations';
import { ExtendedMessage } from '../../chat/types';

export const extendInquiryMessage = (message: InquiryMessage): ExtendedMessage => ({
  _id: message.id,
  text: message.text ?? '',
  audio:
    message.attachment?.type === NewsAttachmentType.Audio && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  image:
    message.attachment?.type === NewsAttachmentType.Image && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  documentUri:
    message.attachment?.type === NewsAttachmentType.Document && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  pending: false,
  user: {
    _id: message.userProfile?.userId ?? '',
    name: `${message.userProfile?.firstname ?? 'Ehemaliger'} ${
      message.userProfile?.lastname ?? 'Benutzer'
    }`,
    avatar: message.userProfile?.pictureOTC ?? undefined,
    role: ChatRole.Member,
  },
  createdAt: message.createdAt,
  deletedAt: message.deletedAt ?? undefined,
  received: true,
  readByCurrentUser: true, // unimplemented in inquiry messages
  quote: undefined, // unimplemented in inquiry messages
  forms: undefined, // unimplemented in inquiry messages
  infoPages: undefined, // unimplemented in inquiry messages
  formReply: undefined, // unimplemented in inquiry messages
  isForwarded: undefined, // unimplemented in inquiry messages
});
