import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';

export type RoundedSquareIconPropTypes = {
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  marginRight?: number;
  iconSize?: number;
  squareSideLength?: number;
  iconTransformRotateDegree?: number;
  radius?: number;
};

export const RoundedSquareIcon = ({
  icon,
  marginRight,
  iconSize,
  squareSideLength,
  iconTransformRotateDegree,
  radius = 4,
}: RoundedSquareIconPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(
    () => createStyles(theme, marginRight, squareSideLength, iconTransformRotateDegree, radius),
    [theme, marginRight, squareSideLength, iconTransformRotateDegree, radius],
  );

  return (
    <View style={styles.container}>
      <Icon size={iconSize ?? 18} color={theme.colors.background} name={icon} style={styles.icon} />
    </View>
  );
};

const createStyles = (
  theme: AppTheme,
  marginRight?: number,
  squareSideLength?: number,
  iconTransformRotateDegree?: number,
  radius?: number,
) =>
  StyleSheet.create({
    container: {
      width: squareSideLength ?? 24,
      height: squareSideLength ?? 24,
      backgroundColor: theme.colors.primary,
      borderRadius: radius ?? 4,
      marginRight: marginRight ?? 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      marginTop: 1,
      transform: [{ rotate: `${(iconTransformRotateDegree ?? 0) % 360}deg` }],
    },
  });
