import { IconButton } from 'react-native-paper';
import React, { useMemo, useReducer } from 'react';
import { View, StyleSheet } from 'react-native';
import { WithLoadingIndicator } from '../WithLoadingIndicator/WithLoadingIndicator';
import { Shimmer } from '../Shimmer/Shimmer';
import { useAppTheme } from '../../styles/theme';
import { TextChip } from '../TextChip/TextChip';

type NewsUserGroupLabelsProps = {
  userGroupLabels: string[];
  isEditable?: boolean;
  onPressEdit?: () => void;
  isLoading?: boolean;
};

type CreateUserGroupLabelsPropTypes = {
  userGroupLabels: string[];
  showAllUserGroups: boolean;
  isEditable?: boolean;
  onPressEdit?: () => void;
  editIconColor?: string;
  toggleShowAllUserGroups: () => void;
  styles: any;
};

export const createUserGroupLabels = ({
  userGroupLabels,
  showAllUserGroups,
  isEditable,
  editIconColor,
  onPressEdit,
  toggleShowAllUserGroups,
  styles,
}: CreateUserGroupLabelsPropTypes) => {
  if (!Array.isArray(userGroupLabels)) {
    return <></>;
  }

  const userGroupLabelLength = userGroupLabels.reduce(
    (len, groupLabel) => len + groupLabel.length,
    0,
  );

  const userGroupLabelExpandLimit = 30; // the number of chars in group labels after which show more button will appear
  const userGroupLabelEllipsisOffset = 4;
  userGroupLabels = userGroupLabels.sort((prev, next) => prev.localeCompare(next));
  let totalUserGroupLabelsToDisplay = 2;
  if (userGroupLabels?.[0]?.length > userGroupLabelExpandLimit - userGroupLabelEllipsisOffset) {
    totalUserGroupLabelsToDisplay = 1;
  }
  const areUserGroupLabelsTooLong =
    userGroupLabelLength! > userGroupLabelExpandLimit && !isEditable;
  const areUserGroupLabelsHidden = areUserGroupLabelsTooLong && !showAllUserGroups;
  if (areUserGroupLabelsHidden) {
    /* In the event that first group name is too long, only this first group is displayed initially */
    userGroupLabels = userGroupLabels.slice(0, totalUserGroupLabelsToDisplay);
  }

  const labels = userGroupLabels.map(userGroupLabel => (
    <TextChip key={userGroupLabel} label={userGroupLabel} />
  ));

  if (areUserGroupLabelsTooLong) {
    labels.push(
      <View key={areUserGroupLabelsHidden ? 'expand' : 'contract'} style={styles.button}>
        <IconButton
          icon={areUserGroupLabelsHidden ? 'dots-horizontal' : 'chevron-left'}
          size={18}
          onPress={() => toggleShowAllUserGroups()}
          testID={
            areUserGroupLabelsHidden ? 'expand-user-group-labels' : 'contract-user-group-labels'
          }
        />
      </View>,
    );
  }

  if (isEditable) {
    labels.push(
      <View key="edit" style={styles.button}>
        {isEditable && (
          <IconButton
            icon="lead-pencil"
            iconColor={editIconColor}
            size={16}
            onPress={onPressEdit}
            testID="edit-user-groups"
          />
        )}
      </View>,
    );
  }
  return labels;
};

export const NewsUserGroupLabels = ({
  userGroupLabels,
  isEditable,
  onPressEdit,
  isLoading,
}: NewsUserGroupLabelsProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  const [showAllUserGroups, toggleShowAllUserGroups] = useReducer(previous => !previous, false);
  const userGroupLabelComponents = useMemo(
    () => (
      <WithLoadingIndicator
        on={!isLoading}
        render={() => (
          <>
            {createUserGroupLabels({
              userGroupLabels,
              showAllUserGroups,
              toggleShowAllUserGroups,
              isEditable: isEditable,
              onPressEdit,
              editIconColor: theme.customColors.primary,
              styles,
            })}
          </>
        )}
        renderWhileLoading={() => (
          <View style={styles.shimmerContainer}>
            <Shimmer
              width={64}
              height={16}
              radius={20}
              color={theme.customColors.userGroupChipBackground}
            />
          </View>
        )}
      />
    ),
    [userGroupLabels, showAllUserGroups, isLoading, isEditable],
  );

  return <View style={styles.buttonContainer}>{userGroupLabelComponents}</View>;
};

const createStyles = () =>
  StyleSheet.create({
    buttonContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginHorizontal: -4,
      marginTop: 2,
    },
    button: {
      marginVertical: -10,
      marginRight: -10,
    },
    groups: {
      alignSelf: 'flex-end',
      paddingTop: 14,
      marginRight: -12,
    },
    shimmerContainer: {
      marginHorizontal: 3,
      paddingVertical: 3,
      paddingHorizontal: 6,
      marginBottom: 2,
    },
    touchableOpacity: { flexDirection: 'row', flexWrap: 'wrap' },
  });
