import { StyleSheet, View } from 'react-native';
import { NewsToReadQuery, UserPreference } from '../../../graphql/operations';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { NewsComment } from './NewsComment';
import { NewsDetailScreenProps } from '../../features/news/types';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import { UserGroupWithLabel } from '../../types/main';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { getDisplayedUserGroupLabels } from './NewsUtils';
import { NewsContent } from './NewsContent';
import { Blocked } from '../Common/Content/Blocked';
import { useStore } from '../../stores/store';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';
import { useUnreadNewsStore } from '../../stores/useUnreadNewsStore';
import Animated, { FadeOut, SlideInUp } from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';

type NewsComponentProps = {
  news: NewsToReadQuery['newsList']['news'][number];
  userGroupsUserIsPartOf: UserGroupWithLabel[];
  translationEnabled: UserPreference['translationEnabled'];
  translationLanguage: UserPreference['translationLanguage'];
  setTranslationSetupPopUpShown: React.Dispatch<React.SetStateAction<boolean>>;
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
  onTagPress?(tag: string): void;
};

export const NewsComponent = memo(
  ({
    news,
    userGroupsUserIsPartOf,
    translationEnabled,
    translationLanguage,
    setTranslationSetupPopUpShown,
    groupIdsInNewsTimelineQuery,
    searchTextInNewsTimeLineQuery,
    onTagPress,
  }: NewsComponentProps) => {
    const navigation = useNavigation<NewsDetailScreenProps['navigation']>();
    const theme = useAppTheme();
    const { getResponsiveContentWidth } = useResponsiveDrawer({});
    const width = getResponsiveContentWidth();
    const styles = useMemo(() => createStyles(theme, width), [theme, width]);
    const [messageToCopy, setMessageToCopy] = useState('');
    const displayedUserGroupLabels = getDisplayedUserGroupLabels({
      userGroupsUserIsPartOf,
      news,
    });
    const { markNewsAsRead, unreadNews } = useUnreadNewsStore(s => ({
      markNewsAsRead: s.remove,
      unreadNews: s.news,
    }));
    const isNewsUnread = useMemo(
      () => unreadNews.map(item => item.id).includes(news.id),
      [news.id, unreadNews],
    );

    const userProfile = useStore(s => s.userProfile);

    const isNewsContentBlocked = Boolean(
      userProfile &&
        news.author &&
        userProfile.blockedUserIds &&
        userProfile.blockedUserIds.includes(news.author.userId),
    );

    const isScreenFocused = useIsFocused();
    const isNewsJustCreated = Math.abs(moment(news.createdAt).diff(new Date(), 'seconds')) < 5;

    useEffect(() => {
      if (isScreenFocused && isNewsUnread) {
        markNewsAsRead(news.id);
      }
    }, [isScreenFocused, isNewsUnread]);

    return (
      <Animated.View
        entering={isNewsJustCreated ? SlideInUp.duration(500).springify().mass(0.4) : undefined}
      >
        <OfflineErrorModal messageToCopy={messageToCopy}>
          {onOnline => (
            <>
              {isNewsContentBlocked && news.author ? (
                <Blocked userId={news.author.userId} />
              ) : (
                <>
                  {isNewsUnread && (
                    <Animated.View
                      exiting={FadeOut.duration(3000).delay(5000)}
                      style={styles.newnessIndicator}
                    >
                      <LinearGradient
                        colors={[theme.customColors.primary, theme.customColors.accent]}
                        start={[0, 1]}
                        end={[1, 1]}
                        style={styles.newnessIndicator}
                      />
                    </Animated.View>
                  )}
                  <NewsContent
                    news={news}
                    userGroupLabels={displayedUserGroupLabels}
                    isInDetailedView={false}
                    setMessageToCopy={setMessageToCopy}
                    onOnline={onOnline}
                    translationEnabled={translationEnabled}
                    translationLanguage={translationLanguage}
                    setTranslationSetupPopUpShown={setTranslationSetupPopUpShown}
                    groupIdsInNewsTimelineQuery={groupIdsInNewsTimelineQuery}
                    searchTextInNewsTimeLineQuery={searchTextInNewsTimeLineQuery}
                    onTagPress={onTagPress}
                  />
                  {news.settings.commentsEnabled && (
                    <>
                      <View style={styles.commentContainer}>
                        <View
                          style={[
                            news.commentResult?.lastComment ? styles.viewLightDivider : {},
                            {
                              marginBottom: 10,
                              marginTop: news.commentResult?.lastComment ? -4 : -10,
                            },
                          ]}
                        />
                        <View style={styles.lastCommentContainer}>
                          {news.commentResult?.lastComment && (
                            <NewsComment
                              item={news.commentResult.lastComment!}
                              userGroupIds={news.userGroupIds ?? news.userGroupId}
                              viewMore={true}
                              shouldRenderReplies={false}
                              newsId={news.id}
                              onPress={() =>
                                navigation.navigate('NewsDetail', {
                                  newsId: news.id,
                                  userGroupLabels: displayedUserGroupLabels,
                                  translationEnabled: translationEnabled,
                                  translationLanguage: translationLanguage,
                                })
                              }
                            />
                          )}
                        </View>
                      </View>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </OfflineErrorModal>
      </Animated.View>
    );
  },
);

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    viewLightDivider: {
      width: '100%',
      height: 0.7,
      marginVertical: 3,
      backgroundColor: theme.customColors.borders,
    },
    commentContainer: {
      marginTop: 4,
      paddingHorizontal: 0,
      width: width,
    },
    lastCommentContainer: { paddingHorizontal: 10 },
    newnessIndicator: {
      position: 'absolute',
      height: '100%',
      width: 8,
      zIndex: 1,
    },
  });
