import React, { useMemo } from 'react';
import { TileFactory } from '../Tiles/TileFactory';
import { StyleSheet, View } from 'react-native';
import { TileActions } from '../Tiles/TileFactoryProps';
import { File } from './types';
import { FileGalleryTileShimmer } from './FileGalleryTileShimmer';

export type FileGalleryProps<P> = {
  files: File<P>[];
  isLoadingMore?: boolean;
} & TileActions<P>;

export function FileGallery<P>({
  files,
  isLoadingMore,
  allowsDeletion = true,
  onDeletePress,
  onTilePress,
}: FileGalleryProps<P>) {
  const styles = createStyles;

  const LoadingShimmers = useMemo(() => {
    return Array.from({ length: 30 }, (_, i) => <FileGalleryTileShimmer key={i} />);
  }, []);

  return (
    <View style={styles.grid}>
      {files.map((file, i) => (
        <TileFactory
          key={i}
          file={file}
          width={150}
          onDeletePress={onDeletePress}
          onTilePress={onTilePress}
          allowsDeletion={allowsDeletion}
        />
      ))}
      {isLoadingMore && LoadingShimmers}
    </View>
  );
}

const createStyles = StyleSheet.create({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 10,
  },
});
