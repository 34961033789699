import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Headline, useTheme, Button } from 'react-native-paper';
import { themeProp } from '../../types/main';
import { useMemo } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { versionCode } from '../../../app.config';

type MaintenanceCardPropTypes = {
  onPress: () => void;
  buttonLoading?: boolean;
  displayIcon?: boolean;
};

export const MaintenanceCard = ({
  onPress,
  buttonLoading,
  displayIcon,
}: MaintenanceCardPropTypes) => {
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.items}>
      {displayIcon && (
        <View>
          <Icon name="tools" color={theme.colors.backdrop} style={styles.icon} />
        </View>
      )}
      <Headline style={styles.headline}>Vorübergehend unzugänglich</Headline>
      <View style={styles.message}>
        <Text>
          Wir haben gerade einige technische Probleme. Bitte haben Sie Geduld mit uns, wir arbeiten
          schon an einer Lösung.
        </Text>
      </View>
      <Button
        style={styles.button}
        icon="reload"
        mode="contained"
        onPress={onPress}
        loading={!!buttonLoading}
        textColor={theme.customColors.textWhite}
      >
        Nochmal versuchen
      </Button>
      <View style={styles.versionContainer}>
        <Text style={styles.versionText}>{'Version: ' + versionCode}</Text>
      </View>
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    items: {
      width: '100%',
      alignItems: 'center',
      marginBottom: 100,
      borderRadius: theme.roundness,
    },
    message: {
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 10,
      marginVertical: 30,
      backgroundColor: theme.colors.background,
      width: '80%',
      borderRadius: theme.roundness,
    },
    button: {
      borderRadius: 5,
      alignSelf: 'center',
    },
    icon: {
      fontSize: 150,
    },
    headline: {
      color: theme.colors.backdrop,
    },
    versionContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 5,
      marginTop: 50,
    },
    versionText: {
      color: theme.customColors.gray20,
      fontSize: 12,
    },
  });
