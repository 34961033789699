/**
 * Groups an array of objects by one of the keys of the object.
 * @param arr Array to group items of.
 * @param key Key to group by.
 * @returns The object that contains the group names as keys and grouped elements as arrays of values.
 */
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      (groups[key(item)] || (groups[key(item)] = [])).push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );
