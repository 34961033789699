import {
  View,
  StyleSheet,
  LayoutChangeEvent,
  ImageURISource,
  Platform,
  Dimensions,
} from 'react-native';
import { NewsAttachment, NewsAttachmentType } from '../../../graphql/operations';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AudioPlayer } from '../Common/Media/AudioPlayer';
import { IImageInfo } from 'react-native-image-zoom-viewer/built/image-viewer.type';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { VideoPlayer } from '../Common/Media/VideoPlayer';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';
import { ImageViewerModal } from '../Common/Modals/ImageViewerModal';
import { ExpandableCachedAutoHeightImage } from '../Common/Media/ExpandableCachedAutoHeightImage';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { IndexIndicator } from '../Common/Content/IndexIndicator';
import { useOrientationChange } from '../../hooks/useOrientationChange/useOrientationChange';
import { UploadType } from '../Common/FileUpload/types';
import { determineInitialImageHeight, determineSourceUriOfImage } from '../Common/Media/utils';

type NewsAttachmentListProps = {
  attachments?: Array<NewsAttachment> | null;
  isDownloadConfirmationNeeded?: boolean;
};

export const NewsAttachmentList = memo(
  ({ attachments, isDownloadConfirmationNeeded }: NewsAttachmentListProps) => {
    const theme = useAppTheme();
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [modalImage, setModalImage] = useState<IImageInfo[] | undefined>();
    const { getResponsiveContentWidth } = useResponsiveDrawer({});
    const includesMultipleAttachments = (attachments?.length ?? 0) > 1;
    const contentWidth = getResponsiveContentWidth();
    const [swiperWidth, setSwiperWidth] = useState(contentWidth);
    const styles = useMemo(() => createStyles(theme, swiperWidth), [theme, swiperWidth]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const imagesForPreview = useRef<IImageInfo[]>([]);
    const orientation = useOrientationChange();

    const onLayout = useCallback((e: LayoutChangeEvent) => {
      if (e.nativeEvent.layout.width !== 0) {
        setSwiperWidth(e.nativeEvent.layout.width);
      }
    }, []);

    const onImageLoaded = useCallback(
      (source: ImageURISource | undefined) => {
        if (
          source &&
          source.uri &&
          !imagesForPreview.current.find(image => image.url === source.uri || image.url === source)
        ) {
          imagesForPreview.current.push({ url: source.uri });
        }
      },
      [imagesForPreview],
    );

    const attachmentElements = useMemo(
      () =>
        attachments?.map(attachment => {
          switch (attachment.type) {
            case NewsAttachmentType.Video:
              return (
                <VideoPlayer
                  key={attachment.otcPath}
                  item={attachment}
                  contained={includesMultipleAttachments}
                />
              );
            case NewsAttachmentType.Image:
              return (
                <View key={attachment.otcPath} style={styles.imageContainer}>
                  <ExpandableCachedAutoHeightImage
                    source={{
                      uri: determineSourceUriOfImage(attachment),
                    }}
                    type={UploadType.NewsPost}
                    width={swiperWidth}
                    initialHeight={determineInitialImageHeight({
                      imageHeight: attachment.height,
                      imageWidth: attachment.width,
                      screenWidth: swiperWidth,
                    })}
                    setModalImage={setModalImage}
                    modalOpened={modalOpened}
                    setModalOpened={setModalOpened}
                    resizeMode={includesMultipleAttachments ? 'contain' : undefined}
                    onImageLoaded={onImageLoaded}
                    imageMetaData={attachment.metadata ?? undefined}
                  />
                </View>
              );
            case NewsAttachmentType.Audio:
              return (
                <AudioPlayer
                  uri={attachment.otcPath ?? ''}
                  key={attachment.otcPath}
                  type="newsPost"
                  isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
                />
              );
            default:
              return <View />;
          }
        }),
      [attachments, swiperWidth],
    );

    const scrollRef = React.useRef<any>(null);

    if (!attachments || attachments.length === 0) {
      return <></>;
    }

    return (
      <View style={styles.container} onLayout={onLayout}>
        <SwiperFlatList
          key={orientation}
          ref={scrollRef}
          showPagination={attachments.length !== 1}
          paginationStyle={styles.pagination}
          paginationDefaultColor={theme.customColors.pagination.inactive}
          paginationActiveColor={theme.customColors.pagination.active}
          paginationStyleItem={styles.swiperItem}
          paginationStyleItemInactive={styles.swiperItemInactive}
          scrollEnabled={Platform.OS !== 'web'}
          onChangeIndex={item => {
            setCurrentIndex(item.index);
          }}
        >
          {attachmentElements}
        </SwiperFlatList>
        <ImageViewerModal
          visible={modalOpened}
          setVisible={setModalOpened}
          imageUrls={imagesForPreview.current}
          activeIndex={imagesForPreview.current.findIndex(
            previewImage => previewImage.url === modalImage?.[0].url,
          )}
        />
        {includesMultipleAttachments && (
          <IndexIndicator currentIndex={currentIndex} numberOfItems={attachments.length} />
        )}
      </View>
    );
  },
);

const createStyles = (theme: AppTheme, swiperWidth: number) =>
  StyleSheet.create({
    container: {
      width: '100%',
    },
    pagination: {
      bottom: -6,
      height: 12,
    },
    imageContainer: {
      marginTop: 12,
      width: Platform.OS === 'web' ? Dimensions.get('screen').width : swiperWidth,
    },
    swiperItem: {
      borderWidth: 3,
      borderColor: theme.customColors.background3,
      width: 12,
      height: 12,
      marginHorizontal: 12,
    },
    swiperItemInactive: { width: 12, height: 12, opacity: 0.5 },
  });
