import { useMutation } from '@tanstack/react-query';
import { downloadFile } from './api';
import { uploadFiles } from './uploadFiles';

export const useDownloadFile = () => {
  return useMutation(downloadFile);
};

export const useUploadFiles = () => {
  return useMutation(uploadFiles);
};
