import { useLikeNewsMutation, useUnlikeNewsMutation } from '../../../../graphql/operations';
import { toast } from '../../../utils/toast/toast';
import { Log } from '../../../utils/Log';
import { useUpdateNewsCache } from './useUpdateNewsCache';

type UseLikeNewsProps = {
  newsId: string;
  liked?: boolean;
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
};

export const useLikeNews = ({ newsId, liked, groupIdsInNewsTimelineQuery }: UseLikeNewsProps) => {
  const likeNewsMutation = useLikeNewsMutation();
  const { updateNewsCache } = useUpdateNewsCache(groupIdsInNewsTimelineQuery);
  const unlikeNewsMutation = useUnlikeNewsMutation();

  const toggleLikeNews = async () => {
    if (!newsId) {
      toast('Leider ist ein Fehler aufgetreten. Bitte versuche Sie es erneut.');
      Log.error(new Error('news id not found, cannot like'));
      return;
    }
    const isLike = Boolean(liked);
    await likeNews(!isLike);
  };

  const likeNews = async (isLike: boolean) => {
    const mutation = isLike ? likeNewsMutation : unlikeNewsMutation;
    // update cache optimistically
    updateNewsCache(newsId, isLike);
    try {
      await mutation.mutateAsync({ newsId: newsId! });
    } catch (error) {
      toast('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
      Log.error(error, { message: 'error while liking newsPost' });
    }
  };

  return {
    isLoading: likeNewsMutation.isLoading || unlikeNewsMutation.isLoading,
    toggleLikeNews,
  };
};
