import React, { useMemo } from 'react';
import { Chip } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';

type ChatDayPropTypes = {
  day: string;
};

export const ChatDay = ({ day }: ChatDayPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Chip style={styles.chip} textStyle={styles.text}>
      {day}
    </Chip>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    chip: {
      height: 32,
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: 25,
      backgroundColor: theme.customColors.lightRipple,
      marginVertical: 16,
    },
    text: {
      fontSize: 12,
      color: theme.customColors.text,
      textAlign: 'center',
    },
  });
