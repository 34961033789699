import { Alert, Platform, Text, Vibration, View } from 'react-native';
import { FAB, useTheme } from 'react-native-paper';
import React, { useState } from 'react';
import { Audio } from 'expo-av';
import { OfflineErrorModal } from '../Modals/OfflineErrorModal';
import moment from 'moment';
import { themeProp } from '../../../types/main';
import { useInterval } from '../../../hooks/util/interval';
import { useNavigation } from '@react-navigation/native';
import { useUploadFiles } from '../FileUpload/queries';
import { AudioPlayer } from './AudioPlayer';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import { UnavailableFeatureModal } from '../Modals/UnavailableFeatureModal';
import { UploadType } from '../FileUpload/types';
import { AttachmentPickerResultItem } from './types';
import { Log } from '../../../utils/Log';
import { waitForKeyboardDismiss } from '../../../utils';
import { ConfirmDialog } from '../../Dialog/ConfirmDialog';

export const AudioRecorder = (props: {
  onSubmit: (item: Pick<AttachmentPickerResultItem, 'fileName' | 'otcPath'>) => void;
  disabled?: boolean;
}) => {
  const theme = useTheme() as themeProp;
  const [audioRecording, setRecording] = useState<Audio.Recording>();
  const [recordingUri, setRecordingUri] = useState<string | undefined>();
  const uploadMediaMutation = useUploadFiles();

  const [recordingSeconds, setRecordingSeconds] = useState<number>(0);
  const [recordedAudioDurationMs, setRecordedAudioRecordingMs] = useState<number>(0);

  const [confirmSendingDuration, setSendingDuration] = useState<number>(0);
  const [unsupportedFeatureModalOpen, setUnsupportedFeatureModalOpen] = useState<boolean>(false);

  const navigation = useNavigation();

  const queryClient = useQueryClient();

  useInterval(() => {
    setRecordingSeconds(recordingSeconds + 1);
  }, 1000);

  const handlePressRecordAudio = async () => {
    await waitForKeyboardDismiss();
    if (Platform.OS === 'web') {
      setUnsupportedFeatureModalOpen(true);
    } else {
      handleAudio();
    }
  };

  const handleAudio = () => {
    if (audioRecording) {
      /* @todo must be awaited */
      void stopRecording();
    } else {
      /* @todo must be awaited */
      void getAudioPermission();
    }
  };

  const vibrateAsync = async (duration = 60) => {
    Vibration.vibrate(duration);
  };

  const getAudioPermission = async () => {
    try {
      const hasPermission = await Audio.getPermissionsAsync();
      if (hasPermission.status !== 'granted') {
        try {
          const requestedPermissions = await Audio.requestPermissionsAsync();
          if (requestedPermissions.granted === true) {
            await startRecording();
          } else {
            Log.info('Permission denied');
          }
        } catch (error) {
          Log.error(error, { message: 'Failed to check permissions' });
        }
      } else {
        await startRecording();
      }
    } catch (error) {
      Log.error(error, { message: 'Failed to start recording' });
    }
  };

  const startRecording = async () => {
    try {
      Log.info('Requesting permissions..');
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      await activateKeepAwake('recording');
      Log.info('Starting recording..');
      const recording = new Audio.Recording();
      void vibrateAsync();
      setRecordingSeconds(0);
      await recording.prepareToRecordAsync({
        android: {
          extension: '.m4a',
          outputFormat: 2,
          audioEncoder: 3,
          sampleRate: 44100,
          numberOfChannels: 2,
          bitRate: 128000,
        },
        ios: {
          extension: '.m4a',
          audioQuality: 0x7f,
          outputFormat: 'aac ',
          sampleRate: 44100,
          numberOfChannels: 2,
          bitRate: 128000,
          linearPCMBitDepth: 16,
          linearPCMIsBigEndian: false,
          linearPCMIsFloat: false,
        },
        web: {
          mimeType: 'audio/webm',
          bitsPerSecond: 128000,
        },
      });
      await recording.startAsync();
      navigation.addListener('beforeRemove', async e => {
        const beforeRemoveAsync = async () => {
          e.preventDefault();
          setRecording(undefined);
          if ((await recording.getStatusAsync()).isRecording) {
            await recording.stopAndUnloadAsync();
          }
          navigation.dispatch(e.data.action);
        };
        await beforeRemoveAsync();
      });
      setRecordingSeconds(0);
      setRecording(recording);
      Log.info('Recording started');
    } catch (error) {
      Log.error(error, { message: 'Failed to start recording' });
      setRecordingSeconds(0);
    }
  };

  const stopRecording = async () => {
    try {
      Log.info('Stopping recording..');
      await deactivateKeepAwake('recording');
      if (audioRecording) {
        await audioRecording.stopAndUnloadAsync();
        const status = await audioRecording.getStatusAsync();
        setRecordedAudioRecordingMs(status.durationMillis);
        void vibrateAsync();
        const uri = audioRecording.getURI();
        Log.info(`stop: ${JSON.stringify(uri, null, 4)}`);
        setRecordingUri(uri ?? '');
      }
    } catch (error) {
      Log.error(error, { message: 'Failed to stop recording' });
      setRecordingSeconds(0);
    } finally {
      setRecording(undefined);
      setRecordingSeconds(0);
      setSendingDuration(recordingSeconds);
    }
  };

  const uploadRecording = async (uri: string) => {
    try {
      const res = await uploadMediaMutation.mutateAsync({
        filesToUpload: [{ uri, uploadType: UploadType.ChatMessage }],
      });
      props.onSubmit({ otcPath: res[0].newFileNames[0][1], fileName: res[0].newFileNames[0][0] });
    } catch (error) {
      Alert.alert('Beim Senden der Sprachnachricht ist ein Fehler aufgetreten');
    }
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          {audioRecording && (
            <View
              style={{
                height: 36,
                width: 72,
                borderRadius: 18,
                backgroundColor: theme.customColors.background3,
                borderColor: theme.customColors.borders,
                borderWidth: 1,
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.2,
                shadowRadius: 1.41,

                elevation: 2,
                position: 'absolute',
                top: -50,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  color: theme.customColors.primary,
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {moment('2015-01-01').startOf('day').seconds(recordingSeconds).format('mm:ss')}
              </Text>
            </View>
          )}
          <FAB
            size="small"
            icon={audioRecording ? 'stop' : 'microphone-outline'}
            testID="recordingIcon"
            color={audioRecording ? theme.customColors.primary : theme.customColors.icon}
            style={{
              backgroundColor: theme.customColors.chatBubbleBackground,
              borderRadius: 100,
              borderWidth: 0.5,
              borderColor: theme.customColors.borders,
            }}
            onPress={() => onOnline(handlePressRecordAudio)}
            disabled={props.disabled}
          />
          <QueryClientProvider client={queryClient}>
            <ConfirmDialog
              isOpen={!!confirmSendingDuration && !!recordingUri}
              title="Sprachnachricht versenden?"
              content={''}
              onCancel={() => setSendingDuration(0)}
              onConfirm={() => {
                if (typeof recordingUri === 'string') {
                  void uploadRecording(recordingUri);
                }
                setSendingDuration(0);
              }}
              cancelButtonLabel="Verwerfen"
            >
              <View
                style={{
                  height: 100,
                  marginTop: -30,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AudioPlayer
                  uri={recordingUri}
                  type="chatMessage"
                  recordedAudioDurationMs={recordedAudioDurationMs}
                />
              </View>
            </ConfirmDialog>
          </QueryClientProvider>
          <UnavailableFeatureModal
            text={{
              description:
                'Bitte verwenden Sie die App, um Sprachnachrichten aufzunehmen und zu versenden.',
            }}
            icon="cellphone-arrow-down"
            visible={unsupportedFeatureModalOpen}
            setVisible={setUnsupportedFeatureModalOpen}
            includeAppStoreLinks
          />
        </>
      )}
    </OfflineErrorModal>
  );
};
