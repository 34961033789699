import React, { useEffect } from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageProps,
  ImageSourcePropType,
  TouchableOpacity,
} from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useCachedImage } from '../hooks';
// @ts-ignore
import FallbackLogo from '../../../../assets/expo/icon.png';
import { useDownloadFile } from '../../../components/Common/FileUpload/queries';
import { UploadType } from '../../../components/Common/FileUpload/types';

export type NewCachedImageProps = Omit<ImageProps, 'source'> & {
  source?: ImageSourcePropType | string;
  type: UploadType;
  onPress?: (event: GestureResponderEvent, source: ImageSourcePropType) => void;
};

export const NewCachedImage = (props: NewCachedImageProps) => {
  const { source, isLoading, setCanDownload } = useCachedImage({
    source: props.source ?? '',
    type: props.type,
  });
  const downloadImage = useDownloadFile();

  useEffect(() => {
    setCanDownload(true);
  }, []);

  if (isLoading || downloadImage.isLoading) {
    return (
      <ActivityIndicator
        {...props}
        style={[props.style, { backgroundColor: 'white' }]}
        testID="loading-image"
      />
    );
  }

  return (
    <TouchableOpacity
      onPress={evt => {
        if (props.onPress) {
          props.onPress(evt, source || FallbackLogo);
        }
      }}
      style={props.style}
    >
      <Image {...props} source={source ? source : FallbackLogo} />
    </TouchableOpacity>
  );
};
