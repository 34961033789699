import { ExtendedMessage } from '../types';

interface IInsertUnreadSystemMessage {
  extendedMessages: ExtendedMessage[];
  systemMessageToInsert: ExtendedMessage;
}

export const insertUnreadSystemMessage = ({
  extendedMessages,
  systemMessageToInsert,
}: IInsertUnreadSystemMessage) => {
  return [
    ...extendedMessages.filter(msg => msg.readByCurrentUser === false),
    systemMessageToInsert,
    ...extendedMessages.filter(msg => msg.readByCurrentUser === true),
  ];
};
