import moment from 'moment';
import { ExtendedMessage } from '../types';

export const determineChatDay = (date: ExtendedMessage['createdAt']) => {
  const isToday = moment(date).isSame(new Date(), 'day');
  if (isToday) {
    return 'Heute';
  }
  const inSameYear = moment(date).isSame(new Date(), 'year');
  if (inSameYear) {
    return moment(date).format('ddd Do MMM');
  }
  return moment(date).format('ddd Do MMM, yyyy');
};
