import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { ExtendedMessage } from './types';

export interface ChatState {
  message?: ExtendedMessage;
  setMessage: (message?: ExtendedMessage) => void;
  clear: () => void;
  currentChatRoomId?: string;
  setCurrentChatRoomId: (chatRoomId: ChatState['currentChatRoomId']) => void;
}

export const useChatStore = create<ChatState>()(
  devtools(
    set => ({
      message: undefined,
      currentChatRoomId: undefined,
      setMessage: message => set(() => ({ message })),
      setCurrentChatRoomId: currentChatRoomId => set({ currentChatRoomId }),
      clear: () => set({ message: undefined }),
    }),
    { enabled: __DEV__ },
  ),
);
