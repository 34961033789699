import { Button, withTheme } from 'react-native-paper';
import { Platform, ScrollView, View } from 'react-native';
import React, { useState } from 'react';

import { AppTheme } from '../../styles/theme';
import { isCloseToBottom } from '../../utils/isCloseToBottom';

interface ConfirmScreenProps {
  onChange: (arg0: boolean) => void;
  children: React.ReactNode;
  buttonText: string;
  enableDelay: number;
  theme: AppTheme;
  isLoading?: boolean;
  submitButtonTestId?: string;
}

const ConfirmScreen = (props: ConfirmScreenProps): JSX.Element => {
  const { theme } = props;
  const { onChange, children, buttonText, enableDelay, isLoading } = props;

  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);

  const handleNext = () => {
    onChange(true);
  };

  return (
    <View
      style={{
        height: '100%',
        marginHorizontal: -14,
        backgroundColor: theme.colors.background,
      }}
    >
      <ScrollView
        style={{
          padding: 20,
        }}
        contentContainerStyle={{
          marginVertical: 10,
        }}
        onLayout={() =>
          enableDelay &&
          setTimeout(() => {
            setIsConfirmEnabled(true);
          }, enableDelay)
        }
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent, 30)) {
            setIsConfirmEnabled(true);
          }
        }}
        scrollEventThrottle={400}
      >
        {children}
      </ScrollView>
      <View
        style={{
          height: 60,
          backgroundColor: theme.colors.background,
          width: '100%',
          alignItems: 'stretch',
        }}
      >
        <Button
          style={{
            marginTop: 20,
            marginHorizontal: 50,
            borderRadius: 5,
          }}
          disabled={!(isConfirmEnabled || Platform.OS === 'web') || isLoading}
          mode="contained"
          onPress={handleNext}
          loading={isLoading}
          testID={props.submitButtonTestId}
          textColor={theme.customColors.textWhite}
        >
          {buttonText}
        </Button>
      </View>
    </View>
  );
};

// eslint-disable-next-line no-restricted-syntax
export default withTheme(ConfirmScreen);
