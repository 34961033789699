import { compare } from 'compare-versions';
import { useMemo } from 'react';
import { useStore } from '../../stores/store';
import { termsOfServiceVersion } from '../../../app.config';

export const useUserMustAgreeToTermsOfService = () => {
  const locallyStoredTOSVersion = useStore.getState().termsOfServiceVersionLastAgreedTo ?? '0.0.0';
  const { userProfile } = useStore(s => ({
    userProfile: s.userProfile,
  }));
  const userMustAgreeToTermsOfService = useMemo(() => {
    if (userProfile) {
      return compare(termsOfServiceVersion, locallyStoredTOSVersion, '>');
    } else {
      return false;
    }
  }, [termsOfServiceVersion, locallyStoredTOSVersion, userProfile]);

  return userMustAgreeToTermsOfService;
};
