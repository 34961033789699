import { Text, useTheme } from 'react-native-paper';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { NewsToReadQuery } from '../../../graphql/operations';
import React, { memo } from 'react';
import { themeProp } from '../../types/main';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import { NewsDetailScreenProps, NewsHomeScreenProps } from '../../features/news/types';

type NewsStatsProps = {
  item: NewsToReadQuery['newsList']['news'][number];
  onPressCommentCount: () => void;
};

export const NewsStats = memo(({ item, onPressCommentCount }: NewsStatsProps) => {
  const theme = useTheme() as themeProp;
  const navigation = useNavigation<
    NewsDetailScreenProps['navigation'] & NewsHomeScreenProps['navigation']
  >();

  const onPressLikeCount = () => {
    navigation.navigate('NewsLikes', {
      newsId: item.id,
    });
  };

  const styles = createStyle(theme);
  return (
    <>
      <View
        style={[
          styles.viewLightDivider,
          {
            marginVertical: 4,
          },
        ]}
      />
      <View style={styles.statsContainer}>
        {item.settings.likesEnabled && (
          <TouchableOpacity style={styles.button} onPress={onPressLikeCount}>
            <Icon name="thumb-up-outline" size={16} style={styles.icon} />
            <Text style={styles.text}>{item.likeResult?.likesCount ?? 0}</Text>
          </TouchableOpacity>
        )}
        {item.settings.commentsEnabled && (
          <TouchableOpacity style={styles.button} onPress={onPressCommentCount}>
            <Text style={styles.text}>{`${item.commentResult?.commentCount ?? 0} Kommentare`}</Text>
          </TouchableOpacity>
        )}
      </View>
    </>
  );
});

const createStyle = (theme: themeProp) =>
  StyleSheet.create({
    statsContainer: {
      flexDirection: 'row',
      marginHorizontal: 10,
      paddingVertical: 2,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    button: {
      flexDirection: 'row',
      marginHorizontal: 10,
      alignItems: 'center',
    },
    viewLightDivider: {
      width: '100%',
      height: 0.7,
      marginVertical: 3,
      backgroundColor: theme.customColors.borders,
    },
    icon: {
      marginRight: 4,
    },
    text: {
      color: theme.customColors.textGray,
      fontSize: 13,
    },
  });
