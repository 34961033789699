import { GetInquiryDetailsQuery, UserType } from '../../graphql/operations';
import { Log } from './Log';

export const getRecipientName = (inquiryDetailsData: GetInquiryDetailsQuery['inquiryDetails']) => {
  if (!inquiryDetailsData) {
    return '';
  }
  const relative = inquiryDetailsData.members.find(
    member => member.userProfile.userType === UserType.Relative,
  );
  if (!relative) {
    Log.error('cannot find relative in inquiry details');
    return '';
  }
  return `${relative.userProfile.firstname} ${relative.userProfile.lastname}`;
};
