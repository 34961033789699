import { useRef, useState } from 'react';
import { RichEditor } from 'react-native-pell-rich-editor';

export const useRichTextEditor = () => {
  const ref = useRef<RichEditor | null>(null);
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>();

  return { ref, htmlContent, setHtmlContent, isInitialized, setInitialized };
};
