// @ts-ignore
import Communications from 'react-native-communications';
import { Linking } from 'react-native';
import { GetAvatarForChatRoomInput } from './types';
import { Log } from '../../utils/Log';

const WWW_URL_PATTERN = /^www\./i;

export const onUrlPress = (url: any) => {
  // When someone sends a message that includes a website address beginning with "www." (omitting the scheme),
  // react-native-parsed-text recognizes it as a valid url, but Linking fails to open due to the missing scheme.
  if (WWW_URL_PATTERN.test(url)) {
    onUrlPress(`http://${url}`);
  } else {
    /* @todo must be awaited */
    void Linking.canOpenURL(url).then(supported => {
      if (!supported) {
        Log.error(`No handler for URL: ${url}`);
      } else {
        /* @todo must be awaited */
        void Linking.openURL(url);
      }
    });
  }
};

export const onPhonePress = (phone: any) => {
  /* @todo must be awaited */
  void Linking.openURL(`tel://${phone}`);
};

export const onEmailPress = (email: any) => Communications.email([email], null, null, null, null);

export const getAvatarForChatRoom = (input: GetAvatarForChatRoomInput) => {
  if (!input.isGroupChat) {
    const partner = input.chatPartners?.find(member => member.userId !== input.userId);

    if (partner?.pictureOTC) {
      return {
        uri: partner?.pictureOTC,
        isSourceUriDefined: true,
        isGroupImage: false,
      };
    }

    if (partner) {
      return {
        initials: partner.firstname.charAt(0) + partner.lastname.charAt(0),
        isSourceUriDefined: false,
        isGroupImage: false,
      };
    }
  }
  if (input.isGroupChat && input.chatImageUri) {
    return {
      uri: input.chatImageUri,
      isSourceUriDefined: true,
      isGroupImage: true,
    };
  }
  let otherInitials = 'EB';
  if (input.name) {
    const splitName = input.name.split(' ');
    otherInitials =
      splitName.length === 1
        ? splitName[0].charAt(0)
        : splitName[0].charAt(0) + splitName[1].charAt(0);
  }
  return {
    initials: otherInitials,
    isSourceUriDefined: false,
    isGroupImage: false,
  };
};
