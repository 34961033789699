import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { memo, useMemo } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { AvatarDisplay } from '../../components/Common/AvatarDisplay/AvatarDisplay';
import { TouchableOpacity } from 'react-native';
import { MessageReceivedTick } from '../../features/chat/components/MessageReceivedTick';
import { ExtendedMessage } from '../../features/chat/types';
import moment from 'moment';
import { determineChatRoomMessageBubbleAnimation } from '../../features/chat/utils/determineChatRoomMessageBubbleAnimation';
import Animated from 'react-native-reanimated';
import { UploadType } from '../Common/FileUpload/types';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useChatBubbleOverlay } from '../../features/chat/hooks/useChatBubbleOverlay';
import SwipeableItem from 'react-native-swipeable-item';
import { ChatBubbleOverlay } from '../../features/chat/components/ChatBubbleOverlay';
import BouncyCheckbox from 'react-native-bouncy-checkbox';

export type ChatBubblePropTypes = {
  message: ExtendedMessage;
  onLongPress: (message: ExtendedMessage, isSelected?: boolean) => void;
  onPress: (imageUrl?: string, message?: ExtendedMessage, isSelected?: boolean) => void;
  onSelect?: (id: string, isSelected: boolean) => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  children: React.ReactNode;
  isFromAppUser: boolean;
  isAvatarOrNameOfOtherUserShown: boolean;
  isReceivedTickShown?: boolean;
  isPreviousMessageFromSameUser: boolean;
  isNextMessageFromSameUser: boolean;
  isDownloadConfirmationNeeded: boolean;
  iconNextToName?: keyof typeof MaterialCommunityIcons.glyphMap;
};

export const ChatBubble = memo(
  ({
    message,
    onLongPress,
    onPress,
    onSelect,
    isSelectable,
    isSelected,
    children,
    isFromAppUser,
    isAvatarOrNameOfOtherUserShown,
    isReceivedTickShown = true,
    isPreviousMessageFromSameUser,
    isNextMessageFromSameUser,
    iconNextToName,
  }: ChatBubblePropTypes) => {
    const theme = useAppTheme();

    const userInitials = useMemo(
      () =>
        message?.user.name
          ? message?.user.name.split(' ')[0].charAt(0) + message?.user.name.split(' ')[1].charAt(0)
          : 'Ehemaliger Benutzer',
      [message],
    );

    const isJustSentOrReceived = useMemo(() => {
      return moment(new Date()).diff(message.createdAt, 'second') < 3;
    }, []);

    const enteringAnimation = useMemo(
      () =>
        determineChatRoomMessageBubbleAnimation({
          justSent: isJustSentOrReceived,
          justReceived: isJustSentOrReceived,
        }),
      [isJustSentOrReceived],
    );

    const { createdAt, user } = message;
    const horizontalAlignment = useMemo(() => (isFromAppUser ? 'right' : 'left'), [isFromAppUser]);
    const shouldHaveLeftMargin = useMemo(
      () => horizontalAlignment === 'left' && isAvatarOrNameOfOtherUserShown,
      [horizontalAlignment],
    );
    const shouldRenderName = useMemo(
      () => shouldHaveLeftMargin && !isPreviousMessageFromSameUser,
      [shouldHaveLeftMargin, isPreviousMessageFromSameUser],
    );
    const shouldRenderAvatar = useMemo(
      () => shouldHaveLeftMargin && !isNextMessageFromSameUser,
      [shouldHaveLeftMargin, isNextMessageFromSameUser],
    );
    const shouldHaveForwardedHeader = useMemo(() => !!message.isForwarded, [message.isForwarded]);

    const {
      swipeableItemImperativeRef,
      snapPoints,
      height: overlayHeight,
      setHeight: setOverlayHeight,
    } = useChatBubbleOverlay({ isSelectable });

    const shouldOverlayBeRendered = useMemo(
      () => !message.deletedAt && overlayHeight !== undefined,
      [message.deletedAt, overlayHeight],
    );

    const onPressCheckbox = useCallback(() => {
      onSelect?.(message._id.toString(), !!isSelected);
    }, [onSelect, message, isSelected]);

    const styles = useMemo(() => createStyles(theme, !!isSelectable), [theme, isSelectable]);

    return (
      <SwipeableItem
        swipeEnabled={false}
        item={message}
        snapPointsLeft={snapPoints}
        renderOverlay={
          shouldOverlayBeRendered
            ? () => (
                <ChatBubbleOverlay containerHeight={overlayHeight!} childrenHeight={12}>
                  <BouncyCheckbox
                    disableBuiltInState
                    onPress={onPressCheckbox}
                    isChecked={isSelected}
                    size={18}
                    fillColor={theme.customColors.primary}
                    unfillColor={theme.customColors.chatBubbleBackground}
                    iconStyle={styles.icon}
                    innerIconStyle={styles.innerIcon}
                  />
                </ChatBubbleOverlay>
              )
            : undefined
        }
        ref={swipeableItemImperativeRef}
      >
        <Animated.View
          entering={enteringAnimation}
          style={isFromAppUser ? styles.containerSelf : styles.containerOther}
          onLayout={event => setOverlayHeight(event.nativeEvent.layout.height)}
        >
          {shouldHaveLeftMargin && (
            <View style={styles.avatarContainer}>
              {shouldRenderAvatar ? (
                <AvatarDisplay
                  type={UploadType.ProfilePicture}
                  size={32}
                  avatar={{
                    initials: userInitials,
                    otcPath: message.user.avatar as string,
                  }}
                />
              ) : (
                <View style={styles.avatarPlaceholder}></View>
              )}
            </View>
          )}
          <TouchableOpacity
            onLongPress={() => onLongPress(message, isSelected)}
            onPress={() => onPress(undefined, message, isSelected)}
            activeOpacity={0.8}
            style={styles.touchableOpacity}
          >
            {shouldRenderName && (
              <View style={styles.nameAndBadgeContainer}>
                <Text variant="bodySmall" style={styles.name}>
                  {user.name}
                </Text>
                {iconNextToName && (
                  <Icon color={theme.colors.primary} name={iconNextToName} size={16} />
                )}
              </View>
            )}
            {shouldHaveForwardedHeader && (
              <View style={styles.forwardedIndicatorContainer}>
                <Icon
                  size={14}
                  color={theme.customColors.textGray}
                  name="share-all"
                  style={styles.icon}
                />
                <Text style={styles.forwardedIndicator}>Weitergeleitet</Text>
              </View>
            )}
            <View style={styles.contentContainer}>{children}</View>
            <View style={styles.dateAndTickContainer}>
              <Text style={styles.date}>{dayjs(createdAt).format('HH:mm')}</Text>
              {isReceivedTickShown && isFromAppUser && (
                <MessageReceivedTick
                  isPendingMessage={!!message?.pending && !message?.received}
                  isReceivedByAllChatRoomMembers={!!message?.received}
                />
              )}
            </View>
            {!isNextMessageFromSameUser &&
              (isFromAppUser ? (
                <>
                  <View style={styles.rightArrow}></View>
                  <View style={styles.rightArrowOverlap}></View>
                </>
              ) : (
                <>
                  <View style={styles.leftArrow}></View>
                  <View style={styles.leftArrowOverlap}></View>
                </>
              ))}
          </TouchableOpacity>
        </Animated.View>
      </SwipeableItem>
    );
  },
);

const createStyles = (theme: AppTheme, isSelectable: boolean) =>
  StyleSheet.create({
    touchableOpacity: {
      ...(Platform.OS === 'web' ? { pointerEvents: 'auto' } : {}),
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: 12,
      maxWidth: '75%',
      borderWidth: 1,
      borderColor: theme.customColors.background2,
      backgroundColor: theme.customColors.chatBubbleBackground,
      padding: 10,
    },
    containerOther: {
      marginTop: 2,
      marginBottom: 2,
      marginLeft: 10,
      flexDirection: 'row',
      maxWidth: '100%',
      width: '100%',
      flex: 1,
    },
    containerSelf: {
      flex: 1,
      marginTop: 2,
      marginBottom: 2,
      marginRight: isSelectable ? 46 : 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
      maxWidth: '100%',
      width: '100%',
    },
    avatar: {
      backgroundColor: theme.customColors.avatar,
      alignSelf: 'flex-end',
      zIndex: 1,
    },
    avatarContainer: { marginRight: 4, alignSelf: 'flex-end', zIndex: 1 },
    avatarPlaceholder: { marginLeft: 32 },
    contentContainer: {
      minWidth: 52,
      marginTop: -10,
      paddingVertical: 6,
    },
    dateAndTickContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 4,
      right: 4,
      zIndex: 1,
    },
    forwardedIndicatorContainer: {
      flexDirection: 'row',
      zIndex: 1,
    },
    nameAndBadgeContainer: {
      flexDirection: 'row',
      zIndex: 1,
    },
    name: {
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.colors.primary,
      marginRight: 2,
    },
    date: {
      color: theme.customColors.textGray,
      fontSize: 12,
      marginRight: 8,
    },
    forwardedIndicator: {
      color: theme.customColors.textGray,
      fontSize: 12,
      marginLeft: 4,
    },
    rightArrow: {
      position: 'absolute',
      backgroundColor: theme.customColors.chatBubbleBackground,
      width: 20,
      height: 25,
      bottom: 0,
      borderBottomLeftRadius: 25,
      right: -10,
    },
    rightArrowOverlap: {
      position: 'absolute',
      backgroundColor: theme.colors.background,
      width: 20,
      height: 35,
      bottom: -6,
      borderBottomLeftRadius: 18,
      right: -20,
    },
    leftArrow: {
      position: 'absolute',
      backgroundColor: theme.customColors.chatBubbleBackground,
      width: 20,
      height: 25,
      bottom: 0,
      borderBottomRightRadius: 25,
      left: -10,
    },
    leftArrowOverlap: {
      position: 'absolute',
      backgroundColor: theme.colors.background,
      width: 20,
      height: 35,
      bottom: -6,
      borderBottomRightRadius: 18,
      left: -20,
    },
    icon: { borderColor: theme.customColors.primary },
    innerIcon: { borderWidth: 2 },
  });
