export const useFocusFields = () => {
  const refCollection: { [key: string]: any } = {};
  const focusNext = (refName: string) => {
    refCollection[refName]?.focus?.();
  };
  const collectFocusRef = (refName: string) => (ref: any) => {
    refCollection[refName] = ref;
  };

  return { focusNext, collectFocusRef };
};
