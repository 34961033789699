interface IConstructFullName {
  titleBefore?: string | null;
  firstname: string;
  lastname: string;
  titleAfter?: string | null;
  maxLength?: number;
}

export const constructTruncatedFullName = ({
  titleBefore,
  firstname,
  lastname,
  titleAfter,
  maxLength,
}: IConstructFullName) => {
  const fullname = `${titleBefore ? `${titleBefore} ` : ''}${firstname} ${lastname}${
    titleAfter ? ` ${titleAfter}` : ''
  }`;
  const isOverLimit = Boolean(maxLength && fullname.length > maxLength);
  return `${fullname.substring(0, isOverLimit ? maxLength! - 3 : maxLength)}${
    isOverLimit ? '...' : ''
  }`;
};
