import { useForgotPasswordMutation, UserType } from '../../../graphql/operations';
import { Popup } from '../../hooks';
import { useStore } from '../../stores/store';
import { getConfirmationCodeErrorDetails } from '../../utils/getConfirmationCodeErrorDetails';
import { Log } from '../../utils/Log';

export const useRequestConfirmationCode = (errorPopup: Popup) => {
  const { userProfile, tenantName } = useStore();
  const { mutateAsync: passwordForgottenMutation } = useForgotPasswordMutation();

  const requestConfirmationCode = async () => {
    if (!userProfile?.user?.loginUsername) {
      errorPopup.setPopupDetails({
        title: 'Unbekannter Fehler',
        text: 'Leider ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal',
      });
      errorPopup.toggleOpen();
      Log.error('cannot resend password because username not found', { data: userProfile });
      return;
    }
    try {
      await passwordForgottenMutation({
        input: {
          loginUsername: userProfile.user.loginUsername,
          loginEmail: userProfile.user.loginEmail,
          loginPhoneNumber: userProfile.user.loginPhoneNumber,
          userType: UserType.Employee,
        },
        tenantName,
      });
    } catch (e) {
      const errorDetails = getConfirmationCodeErrorDetails(e as Error);
      errorPopup.setPopupDetails({
        ...errorDetails,
      });
      errorPopup.toggleOpen();
      throw new Error('request failed');
    }
  };
  return { requestConfirmationCode };
};
