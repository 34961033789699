import jwt_decode from 'jwt-decode';
import { isObjectWithProperty } from '../isObjectWithProperty';

export const isAuthTokenValid = (authToken: string) => {
  const parsed = jwt_decode(authToken);
  if (!isObjectWithProperty(parsed, 'exp') || typeof parsed.exp !== 'number') {
    return false;
  }
  if (parsed.exp * 1000 - 10 * 60 * 1000 < Date.now()) {
    return false;
  }
  return true;
};
