import { InfiniteData } from '@tanstack/react-query';
import { ChatRoomsQuery, CreateChatRoomMessageMutation } from '../../../../graphql/operations';

export const updateCountAndLastMessage =
  (currentChatRoomId: string, message: CreateChatRoomMessageMutation['createChatRoomMessage']) =>
  (data: InfiniteData<ChatRoomsQuery> | undefined) => {
    if (!data?.pages) {
      return data;
    }
    for (const foundPageIndex of Object.keys(data.pages).map(Number)) {
      const currentPage = data.pages[foundPageIndex];
      const foundRoom = currentPage.chatRoomsListForTheCurrentUser.chatRooms.find(
        room => room.id === currentChatRoomId,
      );
      if (!foundRoom) {
        continue;
      }
      return {
        ...data,
        pages: data.pages.map((page, index) => {
          if (index !== foundPageIndex) {
            return page;
          }
          return {
            ...page,
            chatRoomsListForTheCurrentUser: {
              ...page.chatRoomsListForTheCurrentUser,
              chatRooms: page.chatRoomsListForTheCurrentUser.chatRooms.map(room => {
                if (room.id !== currentChatRoomId) {
                  return room;
                }
                return {
                  ...room,
                  unreadMessageCount:
                    room.unreadMessageCount === undefined || room.unreadMessageCount === null
                      ? 1
                      : room.unreadMessageCount + 1,
                  lastMessage: message,
                };
              }),
            },
          };
        }),
      };
    }
    return data;
  };
