import {
  FadeIn,
  FadeOut,
  FadeOutUp,
  FadeOutDown,
  FadeOutLeft,
  FadeOutRight,
  FadeInUp,
  FadeInDown,
  FadeInLeft,
  FadeInRight,
  SlideInUp,
  SlideInDown,
  SlideInLeft,
  SlideInRight,
  SlideOutUp,
  SlideOutDown,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';
import { AnimationDirection, AnimationPhase, AnimationType } from './types';

interface IBuildAnimation {
  phase: AnimationPhase;
  type: AnimationType;
  direction: AnimationDirection;
}

export const buildAnimation = ({ direction, phase, type }: IBuildAnimation) => {
  switch (true) {
    case direction === AnimationDirection.RIGHT &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.OUT:
      return SlideOutRight;
    case direction === AnimationDirection.DOWN &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.IN:
      return FadeInDown;
    case direction === AnimationDirection.RIGHT &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.IN:
      return SlideInRight;
    case direction === AnimationDirection.RIGHT &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.IN:
      return FadeInRight;
    case direction === AnimationDirection.UP &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.OUT:
      return SlideOutUp;
    case direction === AnimationDirection.DOWN &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.OUT:
      return FadeOutUp;
    case direction === AnimationDirection.LEFT &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.OUT:
      return SlideOutLeft;
    case direction === AnimationDirection.LEFT &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.IN:
      return FadeInLeft;
    case direction === AnimationDirection.DOWN &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.IN:
      return SlideInDown;
    case direction === AnimationDirection.UP &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.IN:
      return FadeInUp;
    case direction === AnimationDirection.DOWN &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.OUT:
      return SlideOutDown;
    case direction === AnimationDirection.RIGHT &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.OUT:
      return FadeOutRight;
    case direction === AnimationDirection.UP &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.IN:
      return SlideInUp;
    case direction === AnimationDirection.DOWN &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.OUT:
      return FadeOutDown;
    case direction === AnimationDirection.LEFT &&
      type === AnimationType.SLIDE &&
      phase === AnimationPhase.IN:
      return SlideInLeft;
    case direction === AnimationDirection.LEFT &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.OUT:
      return FadeOutLeft;
    case direction === AnimationDirection.NONE &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.OUT:
      return FadeOut;
    case direction === AnimationDirection.NONE &&
      type === AnimationType.FADE &&
      phase === AnimationPhase.IN:
      return FadeIn;
    default:
      throw new Error(`animation does not exists: ${type}${phase}${direction}`);
  }
};
