import React, { useMemo } from 'react';
import { RichEditor, RichEditorProps } from 'react-native-pell-rich-editor';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';

type RichTextEditorPropTypes = {
  editorRef: React.MutableRefObject<RichEditor | null>;
  setHtmlContent: (html: string) => void;
  initialContentHtml?: string;
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  isFocusedInitially?: boolean;
  placeholder?: string;
  height?: number;
  autoCapitalize?: RichEditorProps['autoCapitalize'];
};

export const RichTextEditor = ({
  editorRef,
  initialContentHtml,
  setHtmlContent,
  setInitialized,
  isFocusedInitially,
  placeholder,
  height = 250,
  autoCapitalize = 'on',
}: RichTextEditorPropTypes) => {
  const theme = useAppTheme();
  const { width: screenWidth } = useWindowDimensions();
  const width = screenWidth > maxContentAreaWidth ? maxContentAreaWidth : screenWidth;
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  return (
    <RichEditor
      ref={editorRef}
      initialFocus={!!isFocusedInitially}
      initialHeight={height}
      initialContentHTML={initialContentHtml}
      autoCorrect={true}
      editorInitializedCallback={() => setInitialized(true)}
      editorStyle={{
        backgroundColor: theme.customColors.textInputBackground,
        color: theme.customColors.text,
        caretColor: theme.customColors.primary,
      }}
      containerStyle={styles.container}
      placeholder={placeholder}
      onChange={text => {
        setHtmlContent(text);
      }}
      autoCapitalize={autoCapitalize}
    />
  );
};

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    container: {
      width,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderTopColor: theme.customColors.borders,
      borderBottomColor: theme.customColors.borders,
    },
  });
