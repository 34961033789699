import React from 'react';
import { FullScreenModal } from '../FullScreenModal';

export interface SelectLinksModalProps {
  children: React.ReactNode;
  show: boolean;
  title: string;
  onPressCancel: () => void;
  onPressSubmit: () => void;
  disableSubmitButton: boolean;
}

export const SelectLinksModal = ({
  children,
  show,
  title,
  onPressCancel,
  onPressSubmit,
  disableSubmitButton,
}: SelectLinksModalProps) => {
  return (
    <FullScreenModal
      show={show}
      title={title}
      onClose={onPressCancel}
      onSubmit={onPressSubmit}
      isSubmitDisabled={disableSubmitButton}
    >
      <FullScreenModal.Container>
        <FullScreenModal.Content>{children}</FullScreenModal.Content>
      </FullScreenModal.Container>
    </FullScreenModal>
  );
};
