import React, { useMemo, useState } from 'react';
import {
  useGetUserProfileForListV2Query as useGetUserProfileForListQuery,
  UserType,
} from '../../../../graphql/operations';
import { getName } from '../getName';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { ElementDropdown } from '../../../components/ElementDropdown/ElementDropdown';

export interface ClientDropdownProps {
  onChangeValue?(val: string): void;
}

export const ClientDropdown = ({ onChangeValue }: ClientDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');
  const clients = useGetUserProfileForListQuery(
    {
      userType: UserType.Client,
      searchValue: searchValue,
    },
    { enabled: !!searchValue },
  );

  const dropdownItems = useMemo(() => {
    return (
      clients.data?.userProfilesV2.userProfiles.map(userProfile => ({
        label: getName({ firstname: userProfile.firstname, lastname: userProfile.lastname }),
        value: userProfile.userId,
      })) ?? []
    );
  }, [clients.data?.userProfilesV2.userProfiles]);

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => clients.refetch(),
    errorMessageToLog: 'an error occurred while refetching clients for the new inquiry screen',
  });

  return (
    <ElementDropdown
      items={dropdownItems}
      searchPlaceholder="KlientInnen suchen"
      emptyPlaceholder="Kein/e Klient/in gefunden. Bitte ändern Sie Ihren Suchtext."
      isLoading={isRefreshing || clients.isLoading}
      onSearchText={setSearchValue}
      isSearchable
      placeholder="KlientenInnen"
      onRefresh={onRefresh}
      onChangeValue={onChangeValue}
    />
  );
};
