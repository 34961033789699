import { Log } from '../../../utils/Log';

import { Platform, StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ResizeMode, Video, Audio, VideoReadyForDisplayEvent } from 'expo-av';
import { NewsAttachment } from '../../../../graphql/operations';
import { useMediaCaching } from '../../../hooks/useMediaCaching/useMediaCaching';
import { useDownloadFile } from '../FileUpload/queries';
import { Downloadable } from '../Content/Downloadable';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { ConfirmDialog } from '../../Dialog/ConfirmDialog';

export const VideoPlayer: React.FC<{
  item: Omit<NewsAttachment, 'createdAt' | 'updatedAt'>;
  contained?: boolean;
}> = props => {
  const video = useRef<Video | null>(null);
  const [source, setSource] = useState<string>();
  const [heightScaled, setHeightScaled] = useState(Platform.OS === 'web' ? 225 : 200);
  const { canDownload, setCanDownload } = useMediaCaching();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const downloadMedia = useDownloadFile();
  const { width } = useWindowDimensions();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const isContained = Boolean(props.contained);

  const contentWidth = getResponsiveContentWidth();

  useEffect(() => {
    if (Platform.OS === 'web') {
      setCanDownload(true);
    }
  }, []);

  useEffect(() => {
    /* @todo must be awaited */
    void getSource();
  }, [props.item]);

  const getSource = async () => {
    if (props.item.otcPath) {
      downloadMedia.mutate(
        {
          s3Path: props.item.otcPath,
          uploadType: 'newsPost',
        },
        {
          onError: err => {
            Log.error(err);
          },
          onSuccess: result => {
            setSource(result);
          },
        },
      );
    }
  };

  const onReadyForDisplay = (loadedVideo: VideoReadyForDisplayEvent) => {
    if (Platform.OS !== 'web') {
      getVideoHeight(loadedVideo);
    }
  };

  const getVideoHeight = (loadedVideo: VideoReadyForDisplayEvent) => {
    const videoWidth = loadedVideo.naturalSize.width;
    const videoHeight = loadedVideo.naturalSize.height;
    setHeightScaled(videoHeight * (width / videoWidth));
  };

  const isFileDownloaded = !!source;

  const handlePlay = () => {
    setTimeout(() => {
      if (video.current) {
        /* @todo must be awaited */
        void video.current.playAsync();
      }
    }, 500);
  };

  const styles = useMemo(
    () => createStyles(contentWidth, heightScaled, isContained),
    [contentWidth, heightScaled, isContained],
  );

  useEffect(() => {
    void Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
  }, []);

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        /* inline video styling support for expo web is broken: https://github.com/expo/expo/issues/15319 */
        // @todo check if fix is deployed and implement inline video players
        <Downloadable
          source={source}
          icon="video-box"
          description="Diese News enthält ein Video."
          buttonOnPressBeforeDownload={() => setCanDownload(true)}
          buttonOnPressAfterDownload={() => window.open(source)}
          buttonTextBeforeDownload="Laden..."
          buttonTextAfterDownload="Abspielen in separatem Fenster"
          isLoading={downloadMedia.isLoading}
        />
      ) : (
        <Video
          testID="player"
          ref={video}
          style={styles.fill}
          source={isFileDownloaded ? { uri: source } : { uri: '' }}
          useNativeControls
          resizeMode={ResizeMode.CONTAIN}
          onReadyForDisplay={onReadyForDisplay}
          shouldPlay={true}
          onLoad={async () => {
            await video.current?.pauseAsync();
          }}
        />
      )}
      {!canDownload && (
        <TouchableOpacity
          testID="clickOverlay"
          style={styles.clickContainer}
          onPress={() => {
            setConfirmModalVisible(true);
          }}
        />
      )}
      <ConfirmDialog
        isOpen={confirmModalVisible}
        title="Datei abspielen?"
        content="Videodateien können sehr groß sein. Sie nutzen momentan mobile Daten. Möchten Sie das
        Video trotzdem abspielen?"
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={() => {
          setConfirmModalVisible(false);
          setCanDownload(true);
          void handlePlay();
        }}
      />
    </View>
  );
};

const createStyles = (contentWidth: number, heightScaled: number, contained: boolean) =>
  StyleSheet.create({
    container: {
      width: contentWidth,
      height: contained ? (contentWidth * 9) / 16 : heightScaled,
      justifyContent: 'center',
      marginTop: 10,
    },
    fill: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    clickContainer: {
      width: contentWidth,
      height: heightScaled,
    },
  });
