import React, { ReactNode, useReducer } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { ConfirmDialog } from '../../Dialog/ConfirmDialog';
import { toast } from '../../../utils/toast/toast';
import * as Clipboard from 'expo-clipboard';
import { Log } from '../../../utils/Log';

export type OnlineCallback = (fn: () => void) => void;

interface OfflineErrorModalProps {
  children: (onOnline: OnlineCallback) => ReactNode;
  messageToCopy?: string;
}

export const OfflineErrorModal = (props: OfflineErrorModalProps) => {
  const [offlineWarningModal, toggleOfflineWarningModal] = useReducer(previous => !previous, false);

  return (
    <>
      {props.children(fn => {
        const fnAsync = async () => {
          if ((await NetInfo.fetch()).isConnected) {
            fn();
          } else {
            toggleOfflineWarningModal();
          }
        };
        /* @todo must be awaited */
        return void fnAsync();
      })}
      <ConfirmDialog
        isOpen={offlineWarningModal}
        title="Fehler!"
        content={`Das passiert häufig, wenn Sie offline sind oder keine gute Internetverbindung haben. Bitte versuchen Sie es erneut, wenn die Verbindung besser ist.${' '}
        ${
          props.messageToCopy && 'Sie können über den Knopf unten Ihren Nachrichtentext kopieren.'
        }`}
        onConfirm={async () => {
          if (props.messageToCopy) {
            try {
              await Clipboard.setStringAsync(props.messageToCopy);
              toggleOfflineWarningModal();
              toast('Der Text wurde erfolgreich in Ihrer Zwischenablage gespeichert.');
            } catch (e) {
              Log.error(e);
              Log.error('cannot copy message to clipboard');
            }
          } else {
            toggleOfflineWarningModal();
          }
        }}
        onCancel={props.messageToCopy ? toggleOfflineWarningModal : () => {}}
        cancelButtonLabel="Schließen"
        confirmButtonLabel={props.messageToCopy ? 'Nachricht kopieren' : 'Schließen'}
      />
    </>
  );
};
