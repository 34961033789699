import { useState } from 'react';

export type FleetingComment = {
  displayed: boolean;
  inReplyTo: {
    commentId?: string;
  };
};

export const useFleetingComment = () => {
  const [fleetingComment, setFleetingComment] = useState<FleetingComment>({
    displayed: false,
    inReplyTo: {
      commentId: undefined,
    },
  });

  return {
    fleetingComment,
    setFleetingComment,
  };
};
