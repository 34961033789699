import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { InfoPagesListItem } from '../components/InfoPagesListItem';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import {
  InfoPageLinkListAbstract,
  InfoPageLinkListAbstractProps,
} from '../../chat/components/InfoPageLinkListAbstract';
import { AppBar } from '../../../components/AppBar/AppBar';
import { Appbar } from 'react-native-paper';
import { InfoPagesHomeScreenProps } from '../../chat/screens/types';

export const InfoPagesHome = ({ navigation }: InfoPagesHomeScreenProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const renderAccordionItem: InfoPageLinkListAbstractProps['renderAccordionItem'] = item => (
    <InfoPagesListItem item={item} key={item.id} />
  );

  return (
    <>
      <AppBar
        title="Infoseiten"
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => navigation.goBack()} color={theme.customColors.icon} />
        )}
      />
      <InfoPageLinkListAbstract
        renderAccordionItem={renderAccordionItem}
        scrollViewContainerStyles={styles.scrollViewContainer}
        scrollViewStyles={styles.scrollView}
      />
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    scrollView: { backgroundColor: theme.colors.background },
    scrollViewContainer: { backgroundColor: theme.colors.background },
  });
