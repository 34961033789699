import { useMemo, useState } from 'react';
import {
  ChatRoomAsOption,
  FullScreenModalPickerOption,
  UserProfileAsOption,
} from '../../../components/Picker/FullScreenModalPicker';
import { useSelections } from '../../../hooks/useSelections/useSelections';
import { ChatRoomType, useInfiniteCompactChatRoomsQuery } from '../../../../graphql/operations';
import { AnimationDirection } from '../../../utils/animation/types';
import { Forwardee, ForwardeeType } from '../types';
import { constructTruncatedFullName } from '../../../utils';
import uuid from 'react-native-uuid';

export type IUseForwardeePicker = {
  userIdOfAppUser?: string;
  debouncedSearchChatRoomValue?: string;
  chatRoomId: string;
};

export const useForwardeePicker = ({
  userIdOfAppUser,
  debouncedSearchChatRoomValue,
  chatRoomId,
}: IUseForwardeePicker) => {
  const {
    isSelecting: isSelectingForwardees,
    setSelecting: setSelectingForwardees,
    toggleSelecting: toggleSelectingForwardees,
    append: appendToForwardeeSelections,
    remove: removeFromForwardeeSelections,
    clear: clearForwardeeSelections,
    selections: forwardeeSelections,
    setSelections: setForwardeeSelections,
  } = useSelections<Forwardee>();
  const [forwardeePickerExitDir, setForwardeePickerExitDir] = useState<AnimationDirection>(
    AnimationDirection.RIGHT,
  );

  const chatRoomsQuery = useInfiniteCompactChatRoomsQuery(
    'pageInput',
    { pageInput: { pageSize: 30 }, searchText: debouncedSearchChatRoomValue },
    {
      enabled: isSelectingForwardees,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.compactChatRoomsList.chatRooms.length,
          0,
        );
        if (alreadyFetched === lastPage.compactChatRoomsList.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 30,
            token: lastPage.compactChatRoomsList?.token,
          },
        };
      },
    },
  );

  const forwardeeOptions: FullScreenModalPickerOption<Forwardee>[] = useMemo(() => {
    const chatRoomsData =
      chatRoomsQuery.data?.pages
        .map(page => page.compactChatRoomsList)
        .flatMap(chatRoomList => chatRoomList.chatRooms)
        .filter(chatRoom => chatRoom.id !== chatRoomId) ?? [];
    const queriedChatRooms: FullScreenModalPickerOption<ChatRoomAsOption>[] = chatRoomsData?.map(
      chatRoom => ({
        id: chatRoom.id,
        label: chatRoom.name,
        value: chatRoom,
      }),
    );
    const chatRoomsAsForwardees: FullScreenModalPickerOption<Forwardee>[] = queriedChatRooms?.map(
      chatRoom => ({
        id: chatRoom.id,
        label: chatRoom.label,
        value: {
          id: chatRoom.id,
          otcPath:
            chatRoom.value.type === ChatRoomType.Group
              ? chatRoom.value.groupImagePath
              : chatRoom.value.chatPartner?.pictureOTC,
          type: ForwardeeType.CHATROOM,
          extra:
            chatRoom.value.type === ChatRoomType.Group
              ? { chatRoom: { name: chatRoom.value.name } }
              : {
                  user: {
                    userId: chatRoom.value.chatPartner?.userId ?? uuid.v4().toString(),
                    firstname: chatRoom.value.chatPartner?.firstname ?? 'Ehemaliger',
                    lastname: chatRoom.value.chatPartner?.lastname ?? 'Benutzer',
                  },
                },
        },
      }),
    );
    const selectedContactWithoutChats: FullScreenModalPickerOption<Forwardee>[] =
      forwardeeSelections
        .filter(selection => selection.value.type === ForwardeeType.CONTACT)
        .map(selection => ({
          ...selection,
          label:
            selection.value.type === ForwardeeType.CONTACT
              ? constructTruncatedFullName({
                  titleBefore: selection.value.extra?.user?.titleBefore,
                  firstname: selection.value.extra?.user?.firstname ?? 'Unbekannter',
                  lastname: selection.value.extra?.user?.lastname ?? 'Kontakt',
                  titleAfter: selection.value.extra?.user?.titleAfter,
                })
              : selection.value.extra?.chatRoom?.name ?? 'Unbekannte Chat-Gruppe',
        }));
    return [...selectedContactWithoutChats, ...chatRoomsAsForwardees];
  }, [chatRoomsQuery.data, userIdOfAppUser, forwardeeSelections]);

  const isChatRoomsLoading = chatRoomsQuery.isLoading || chatRoomsQuery.isInitialLoading;

  const syncContactsToForwardees = (
    contactsToAppend: UserProfileAsOption[],
    contactsToRemove: UserProfileAsOption[],
  ) => {
    const contactsAsForwardees = contactsToAppend.map(contact => ({
      id: contact.userId,
      value: {
        id: contact.userId,
        type: ForwardeeType.CONTACT,
        otcPath: contact.pictureOTC,
        extra: {
          user: {
            userId: contact.userId,
            firstname: contact.firstname,
            lastname: contact.lastname,
            titleBefore: contact.titleBefore ?? undefined,
            titleAfter: contact.titleAfter ?? undefined,
          },
        },
      },
    }));
    return setForwardeeSelections(
      [...contactsAsForwardees, ...forwardeeSelections].filter(
        forwardee => !contactsToRemove.map(contact => contact.userId).includes(forwardee.id),
      ),
    );
  };

  return {
    isSelectingForwardees,
    forwardeeOptions,
    forwardeeSelections,
    forwardeePickerExitDir,
    isChatRoomsLoading,
    setSelectingForwardees,
    toggleSelectingForwardees,
    appendToForwardeeSelections,
    removeFromForwardeeSelections,
    setForwardeePickerExitDir,
    clearForwardeeSelections,
    syncContactsToForwardees,
  };
};
