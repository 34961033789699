import { InfiniteData, QueryClient } from '@tanstack/react-query';
import {
  NewsToReadByUserGroupQuery,
  SingleNewsToReadQuery,
  TranslateNewsPostMutation,
  UpdateUsersWhoHaveTranslatedMutation,
  useInfiniteNewsToReadByUserGroupQuery,
  useSingleNewsToReadQuery,
} from '../../../../graphql/operations';
import { mapTranslationOntoExistingNewsList } from './mapTranslationOntoExistingNewsList';

type UpdateNewsCacheWithTranslationParams = {
  queryClient: QueryClient;
  groupIdsInNewsTimelineQuery: string[];
  translation:
    | TranslateNewsPostMutation['translateNewsPost']
    | UpdateUsersWhoHaveTranslatedMutation['updateUsersWhoHaveTranslated'];
  newsId: string;
  searchTextInNewsTimeLineQuery?: string;
};

export const updateNewsCacheWithTranslation = ({
  queryClient,
  groupIdsInNewsTimelineQuery,
  translation,
  newsId,
  searchTextInNewsTimeLineQuery,
}: UpdateNewsCacheWithTranslationParams) => {
  queryClient.setQueryData<InfiniteData<NewsToReadByUserGroupQuery>>(
    useInfiniteNewsToReadByUserGroupQuery.getKey({
      pageInput: { pageSize: 8 },
      userGroupIdsOfNews: groupIdsInNewsTimelineQuery,
      searchText: searchTextInNewsTimeLineQuery,
    }),
    data => mapTranslationOntoExistingNewsList({ data, newsId, translation }),
  );

  /* @todo The callback in setQueryData can return undefined but not set undefined in v3 of react query. This is fixed in version 4 and a check for previous query data will not be needed after an update. See: https://github.com/TanStack/query/issues/506 */
  const oldNews = queryClient.getQueryData<SingleNewsToReadQuery>(
    useSingleNewsToReadQuery.getKey({ newsId: newsId }),
  );

  if (oldNews) {
    queryClient.setQueryData<SingleNewsToReadQuery>(
      useSingleNewsToReadQuery.getKey({ newsId: newsId }),
      {
        news: {
          ...oldNews.news,
          newsPostTranslation: translation,
        },
      },
    );
  }
};
