import { InfiniteData } from '@tanstack/react-query';
import { News, NewsToReadByUserGroupQuery } from '../../../../graphql/operations';

interface PrependNewsToQueryCacheUpdaterProps {
  data?: InfiniteData<NewsToReadByUserGroupQuery>;
  news: News;
}

export const prependNewsToQueryCacheUpdater = ({
  data,
  news,
}: PrependNewsToQueryCacheUpdaterProps): InfiniteData<NewsToReadByUserGroupQuery> | undefined => {
  const page1 = data?.pages?.[0];
  if (page1 && !page1.newsListByUserGroup.news.find(existingNews => existingNews.id === news.id)) {
    const allPagesExceptPageOne = data.pages.filter((_, pageIndex) => pageIndex !== 0) ?? [];
    const newPage1: NewsToReadByUserGroupQuery = {
      newsListByUserGroup: {
        ...page1.newsListByUserGroup,
        news: [news, ...page1.newsListByUserGroup.news],
      },
    };
    return {
      pages: [newPage1, ...allPagesExceptPageOne],
      pageParams: data.pageParams,
    };
  }
  return data;
};
