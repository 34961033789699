import { StackNavigationProp } from '@react-navigation/stack';
import React, { memo, useState } from 'react';
import { Portal, Text } from 'react-native-paper';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { GenericPopUp } from '../../../stories/Modal/GenericPopUp';
import { useNavigation } from '@react-navigation/native';
import { TermsAndServicePopup } from '../../../../test/testIds';

type TermsOfServicePopUpPropTypes = {
  userMustAgreeToTermsOfService: boolean;
};

export const TermsOfServicePopUp = memo(
  ({ userMustAgreeToTermsOfService }: TermsOfServicePopUpPropTypes) => {
    const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
    const [userIsReviewingTOS, setUserIsReviewingTOS] = useState<boolean>(false);

    if (!userMustAgreeToTermsOfService) {
      return <></>;
    }

    return (
      <Portal>
        <GenericPopUp
          visible={userMustAgreeToTermsOfService && !userIsReviewingTOS}
          headlineTitle="Nutzungsbedingungen geändert"
          primaryActionTitle="Ansehen"
          buttonTestId={TermsAndServicePopup.button}
          onDismiss={() => {
            setUserIsReviewingTOS(true);
            navigation.reset({
              index: 0,
              routes: [{ name: 'LegalFlow' }],
            });
          }}
          notDismissable
          isDisabled={false}
          buttonMarginTop={16}
        >
          <Text>
            Bitte lesen Sie die neuen Nutzungsbedingungen sorgfältig durch und bestätigen Sie sie,
            bevor Sie die App weiter nutzen.
          </Text>
        </GenericPopUp>
      </Portal>
    );
  },
);
