import {
  ChatMessageReadReceipt,
  ChatMessageReadReceiptsQuery,
  ChatRoomMessageStatus,
  UserType,
} from '../../../../graphql/operations';

export const readReceiptsPlaceholderData: ChatMessageReadReceiptsQuery = {
  chatMessageReadReceipts: [
    {
      status: ChatRoomMessageStatus.Read,
      user: {
        firstname: '',
        lastname: '',
        userId: 'loading0',
        userType: UserType.Employee,
      },
    } as ChatMessageReadReceipt,
    {
      status: ChatRoomMessageStatus.Sent,
      user: {
        firstname: '',
        lastname: '',
        userId: 'loading1',
        userType: UserType.Employee,
      },
    } as ChatMessageReadReceipt,
  ],
};
