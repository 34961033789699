import { useState, useEffect, useReducer } from 'react';
import {
  GetUserGroupsWhereUserIsPartOfQuery,
  useGetUserGroupsWhereUserIsPartOfQuery,
} from '../../../../graphql/operations';
import { useStore } from '../../../stores/store';
import { usePrevious } from '../../../hooks/usePrevious/usePrevious';
import { areGroupIdsSame } from '../utils/areGroupIdsSame';

interface IUseUserGroupsOfUser {
  initialSelections: string[];
  /**
   * set to true if synching selections should also update previously saved user group ids in store.
   * this makes it possible that selected groups do not change when user navigates to other screens.
   */
  shouldStoreBeSynced?: boolean;
  /**
   * set to true if options should only include user groups where user has editor role.
   */
  shouldOptionsHaveEditorRole?: boolean;
}

export function useUserGroupsOfUser({
  shouldStoreBeSynced,
  shouldOptionsHaveEditorRole,
  initialSelections,
}: IUseUserGroupsOfUser) {
  const { data, isInitialLoading, isError, isRefetching, refetch } =
    useGetUserGroupsWhereUserIsPartOfQuery();

  const { permissionsByUserGroup } = useStore(s => ({
    permissionsByUserGroup: s.permissions ?? [],
  }));

  const userGroupsWhereUserIsEditor = permissionsByUserGroup.filter(({ permissions }) =>
    permissions.includes('news:create'),
  );

  const previousGroups = usePrevious<
    GetUserGroupsWhereUserIsPartOfQuery['userGroupsWhereUserIsPartOf'] | undefined
  >(data?.userGroupsWhereUserIsPartOf);

  const haveGroupsChanged =
    previousGroups?.length !== data?.userGroupsWhereUserIsPartOf.length ||
    !areGroupIdsSame(previousGroups, data?.userGroupsWhereUserIsPartOf);

  const allowedUserGroups =
    data?.userGroupsWhereUserIsPartOf
      .filter(group =>
        shouldOptionsHaveEditorRole
          ? userGroupsWhereUserIsEditor.map(userGroup => userGroup.groupId).includes(group.id)
          : true,
      )
      .map(group => ({
        id: group.id,
        label: group.name,
        value: group.id,
      })) ?? [];

  const [isSelecting, toggleSelecting] = useReducer(previous => !previous, false);
  const [options, setOptions] =
    useState<{ id: string; label: string; value: any }[]>(allowedUserGroups);
  const [selectionsBuffer, setSelectionsBuffer] = useState<string[]>([]);
  const [selections, setSelections] = useState<string[]>([]);

  useEffect(() => {
    if (!isInitialLoading) {
      const initialUserGroupIds =
        initialSelections.length === 0 || haveGroupsChanged
          ? allowedUserGroups.map(userGroup => userGroup.id)
          : initialSelections;
      setOptions(allowedUserGroups);
      setSelectionsBuffer(initialUserGroupIds);
      setSelections(initialUserGroupIds);
      if (shouldStoreBeSynced) {
        useStore.setState({
          UserGroupIdFiltersForNews: initialUserGroupIds,
        });
      }
    }
  }, [initialSelections, isInitialLoading, haveGroupsChanged]);

  const syncSelectionsWithBuffer = () => {
    setSelections(selectionsBuffer);
    toggleSelecting();
    if (shouldStoreBeSynced) {
      useStore.setState({
        UserGroupIdFiltersForNews: selectionsBuffer,
      });
    }
  };

  const resetBufferToSelections = () => {
    setSelectionsBuffer(selections);
    toggleSelecting();
  };

  const onSelectionsBufferChange = (selection: string) =>
    selectionsBuffer.includes(selection)
      ? setSelectionsBuffer(
          selectionsBuffer.filter(existingSelection => existingSelection !== selection),
        )
      : setSelectionsBuffer([...selectionsBuffer, selection]);

  const isSelectionsSameWithOptions =
    isError || isInitialLoading ? true : options.length === selections.length;

  const labelsOfSelectedUserGroups = options
    .filter(option => selections.includes(option.id))
    .map(option => option.label);

  return {
    isInitialLoading,
    isError,
    isRefetching,
    isSelectionsSameWithOptions,
    options,
    selections,
    selectionsBuffer,
    isSelecting,
    labelsOfSelectedUserGroups,
    refetchGroups: refetch,
    setOptions,
    setSelections,
    setSelectionsBuffer,
    toggleSelecting,
    syncSelectionsWithBuffer,
    resetBufferToSelections,
    onSelectionsBufferChange,
  };
}
