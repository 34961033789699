import React, { useMemo } from 'react';
import { WithLoadingIndicator } from '../../WithLoadingIndicator/WithLoadingIndicator';
import { Text } from 'react-native-paper';
import { ColorValue, StyleSheet, useWindowDimensions } from 'react-native';
import {
  RichEditor,
  RichToolbar,
  actions as RichTextEditorToolbarActions,
} from 'react-native-pell-rich-editor';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { maxContentAreaWidth } from '../../../router/navigators/ContentNavigator';
import { Shimmer } from '../../Shimmer/Shimmer';
import { LinkInputDialog } from '../../Dialog/LinkInputDialog';
import { useLinkInputDialog } from '../../Dialog/hooks/useLinkInputDialog';

type RichTextEditorToolbarPropTypes = {
  editorRef: React.MutableRefObject<RichEditor | null>;
  isEditorInitialized: boolean;
  actions?: RichTextEditorToolbarActions[];
};

export const RichTextEditorToolbar = ({
  editorRef,
  isEditorInitialized,
  actions = [
    RichTextEditorToolbarActions.setBold,
    RichTextEditorToolbarActions.setItalic,
    // underlined is disabled for now since its also not enabled in control center
    // RichTextEditorToolbarActions.setUnderline,
    RichTextEditorToolbarActions.blockquote,
    RichTextEditorToolbarActions.heading1,
    RichTextEditorToolbarActions.insertBulletsList,
    RichTextEditorToolbarActions.insertOrderedList,
    RichTextEditorToolbarActions.insertLink,
  ],
}: RichTextEditorToolbarPropTypes) => {
  const theme = useAppTheme();
  const { width: screenWidth } = useWindowDimensions();
  const width = screenWidth > maxContentAreaWidth ? maxContentAreaWidth : screenWidth;
  const styles = useMemo(() => createStyles(theme), [theme]);

  const handleH1 = ({ tintColor }: { tintColor: ColorValue }) => (
    <Text style={{ color: tintColor, fontSize: 18 }}>H</Text>
  );

  const {
    isInsertLinkDialogOpen,
    label,
    resetFields,
    setInsertLinkDialogOpen,
    setLabel,
    setUrl,
    url,
  } = useLinkInputDialog();

  const onConfirmLinkCreation = () => {
    setInsertLinkDialogOpen(false);
    if (label && url) {
      editorRef.current?.insertLink(label, url);
    }
    resetFields();
  };

  return (
    <WithLoadingIndicator
      on={isEditorInitialized}
      render={() => (
        <>
          <RichToolbar
            editor={editorRef}
            actions={actions}
            iconMap={{ [RichTextEditorToolbarActions.heading1]: handleH1 }}
            style={styles.toolbar}
            iconTint={theme.customColors.icon}
            selectedIconTint={theme.customColors.primary}
            onInsertLink={() => setInsertLinkDialogOpen(true)}
          />
          <LinkInputDialog
            isOpen={isInsertLinkDialogOpen}
            title="Link erstellen"
            content={label ? `${label}` : `Vorschau`}
            onCancel={() => setInsertLinkDialogOpen(false)}
            isConfirmButtonDisabled={!label || !url}
            onConfirm={onConfirmLinkCreation}
            confirmButtonLabel="Erstellen"
            urlInputProps={{
              value: url,
              onChangeText: text => setUrl(text),
              placeholder: 'E-Mail, Telefonnummer, URL',
              inputMode: 'url',
              autoCapitalize: 'none',
              autoCorrect: false,
            }}
            labelInputProps={{
              value: label,
              onChangeText: text => setLabel(text),
              placeholder: 'Label',
              inputMode: 'text',
            }}
          />
        </>
      )}
      renderWhileLoading={() => (
        <Shimmer width={width} height={42} color={theme.customColors.background3} />
      )}
    />
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    toolbar: {
      backgroundColor: theme.customColors.background3,
    },
  });
