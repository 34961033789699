import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ChatRoomMessage } from '../../../../graphql/operations';
import { useStore } from '../../../stores/store';
import { processRealTimeChatRoomMessage } from '../utils/processRealTimeChatRoomMessage';
import { useSocketStore } from '../../../stores/SocketStore';
import { produce } from 'immer';

interface IUseCreateMessageSubscription {
  chatRoomId: string;
  onSentOrReceivedMessage?: () => void;
}

export const useCreateMessageSubscription = ({
  chatRoomId,
  onSentOrReceivedMessage,
}: IUseCreateMessageSubscription) => {
  const chatSocket = useSocketStore(s => s.chatSocket);
  const appUser = useStore(s => s.userProfile);
  const queryClient = useQueryClient();
  const [realTimeMessages, setRealTimeMessages] = useState<ChatRoomMessage[]>([]);

  const resetRealTimeMessages = useCallback(() => {
    setRealTimeMessages([]);
  }, []);

  useEffect(() => {
    resetRealTimeMessages();
    return () => resetRealTimeMessages();
  }, [chatRoomId]);

  /**
   * Takes the id of a pending message and makes it non-pending.
   *
   * This function must be preceded by a call to `addToMessagesAsPending`, with
   * `messageId` being the id of the message provided to the aforementioned function.
   */
  const endPendingStateOfMessage = useCallback(
    (messageId: ChatRoomMessage['id']) => {
      setRealTimeMessages(
        produce(draft => {
          const index = draft.findIndex(message => message.id === messageId);
          if (index !== -1) {
            draft[index].pending = false;
          }
        }),
      );
    },
    [realTimeMessages],
  );

  /**
   * Adds the given message to the real time messages array.
   * Used to create pending messages; which are messages that instantly appear in the chat room,
   * even though they are not sent to the server yet.
   *
   * This function must be followed by a mutation and a call or calls to `endPendingStateOfMessage` with
   * the id of the message provided to this function.
   */
  const addToMessagesAsPending = useCallback(
    (chatRoomMessage: ChatRoomMessage) => {
      onSentOrReceivedMessage?.();
      setRealTimeMessages(
        produce(draft => {
          draft.unshift({ ...chatRoomMessage, pending: true });
        }),
      );
    },
    [realTimeMessages, onSentOrReceivedMessage],
  );

  const onObserveRealTimeMessage = useCallback(
    (chatRoomMessage: ChatRoomMessage) => {
      if (chatRoomMessage.chatRoomId !== chatRoomId) {
        return;
      }
      const realTimeMessage = processRealTimeChatRoomMessage({
        chatRoomId,
        queryClient,
        chatRoomMessage,
        onSentOrReceivedMessage,
      });
      setRealTimeMessages(
        produce(draft => {
          const isSentByAppUser = appUser?.userId === realTimeMessage.author?.userId;
          if (isSentByAppUser) {
            return;
          }
          draft.unshift(realTimeMessage);
        }),
      );
    },
    [queryClient, chatRoomId, realTimeMessages, onSentOrReceivedMessage],
  );

  useEffect(() => {
    if (!appUser || !queryClient || !chatSocket?.connected) {
      return;
    }

    chatSocket?.on('createMessage', onObserveRealTimeMessage);

    return () => {
      chatSocket?.off('createMessage', onObserveRealTimeMessage);
    };
  }, [chatSocket?.connected, queryClient, appUser, realTimeMessages]);

  return {
    realTimeMessages,
    addToMessagesAsPending,
    endPendingStateOfMessage,
  };
};
