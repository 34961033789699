import { Log } from '../../../utils/Log';
import { alert } from '../../../utils/alert';

interface ISendChatRoomMessageErrorHandler {
  e: Error;
  onNotSentDueToBlock: () => void;
}

export const sendChatRoomMessageErrorHandler = ({
  e,
  onNotSentDueToBlock,
}: ISendChatRoomMessageErrorHandler) => {
  switch (e.message) {
    case 'message was not send because either private chat room member blocked one another':
      onNotSentDueToBlock();
      break;
    default:
      Log.error(e, { message: e.message });
      alert('Nachricht wurde nicht gesendet', 'Bitte versuchen Sie es erneut.');
      break;
  }
};
