import React from 'react';
import { Shimmer } from '../../Shimmer/Shimmer';
import { useAppTheme } from '../../../styles/theme';

type AvatarDisplayShimmerPropTypes = {
  size: number;
};

export const AvatarDisplayShimmer = ({ size }: AvatarDisplayShimmerPropTypes) => {
  const theme = useAppTheme();

  return <Shimmer width={size} height={size} radius={200} color={theme.customColors.avatar} />;
};
