import * as ImagePicker from 'expo-image-picker';
import { ImageErrors } from '../requestImages/ImageErrors';
import { Log } from '../Log';
import { MediaTypeOptions } from 'expo-image-picker';

export const requestGalleryImages = async (includeVideo?: boolean) => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status !== 'granted') {
    throw new Error(ImageErrors.permissionDenied);
  }
  try {
    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: includeVideo ? MediaTypeOptions.All : MediaTypeOptions.Images,
      allowsMultipleSelection: true,
      selectionLimit: 8,
    });
  } catch (e) {
    Log.error(e);
    Log.error('cannot launch image library');
    return;
  }
};
