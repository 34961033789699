import { View, StyleSheet } from 'react-native';
import React, { memo, useMemo } from 'react';
import { ChatInfoPagesMessageListItem } from './ChatInfoPagesMessageListItem';
import { SelectedInfoPage } from '../../../components/Common/Media/types';

type ChatInfoPagesMessageListPropTypes = {
  onLongPress: () => void;
  infoPages: SelectedInfoPage[];
};

export const ChatInfoPagesMessageList = memo(
  ({ infoPages, onLongPress }: ChatInfoPagesMessageListPropTypes) => {
    const styles = useMemo(() => createStyles(), []);

    return (
      <View style={styles.container}>
        {infoPages.map(infoPage => (
          <ChatInfoPagesMessageListItem
            infoPage={infoPage}
            onLongPress={onLongPress}
            key={infoPage.id}
          />
        ))}
      </View>
    );
  },
);

const createStyles = () =>
  StyleSheet.create({
    container: {
      marginTop: 6,
    },
  });
