import React, { useMemo } from 'react';
import { Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { Feature } from '../../../graphql/operations';
import { useAppTheme } from '../../styles/theme';
import { useLinkButton } from './hooks/useLinkButton';
import { Log } from '../../utils/Log';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { StackNavigationProp } from '@react-navigation/stack';

export interface LinkButtonProps {
  id: string;
  text: string;
  icon: string;
  buttonStyle?: ViewStyle;
  feature: Feature.Form | Feature.Infopage | Feature.Formreply;
  chatId?: string;
  textStyle?: TextStyle;
  navigation?: StackNavigationProp<AppNavigatorParamList>;
}

export const LinkButton = ({
  id,
  text,
  icon,
  buttonStyle,
  feature,
  chatId,
  textStyle,
  navigation,
}: LinkButtonProps) => {
  const theme = useAppTheme();
  const { navigateToFeature } = useLinkButton({ id, feature, label: text, chatId, navigation });
  const isNavigatable = !!navigation;

  const onPress = async () => {
    try {
      if (!isNavigatable) {
        return;
      }
      await navigateToFeature();
    } catch (e) {
      Log.error(e, { message: 'cannot navigate to feature' });
    }
  };

  const styles = useMemo(() => createStyles(), []);
  return (
    <Button
      uppercase={false}
      mode="outlined"
      labelStyle={styles.label}
      contentStyle={styles.contentStyle}
      onPress={onPress}
      style={buttonStyle}
      icon={icon}
      textColor={theme.customColors.icon}
    >
      <Text style={[styles.text, textStyle]} numberOfLines={1} ellipsizeMode="tail">
        {text}
      </Text>
    </Button>
  );
};

const createStyles = () =>
  StyleSheet.create({
    label: {
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    text: {
      marginLeft: Platform.OS === 'web' ? 12 : 24,
    },
    contentStyle: { justifyContent: 'flex-start', alignItems: 'center', borderRadius: 5 },
  });
