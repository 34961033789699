import {
  useTheme,
  MD3LightTheme as DefaultTheme,
  MD3DarkTheme as DarkTheme,
} from 'react-native-paper';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNativePaper {
    type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

    interface ThemeBreakpoints {
      values: { [k in Breakpoint]: number };
    }

    interface CustomColors {
      primary: string; // luci base color for tenant selection
      accent: string;
      background: string;
      // +--- custom colors below ---+
      // text
      text: string;
      textDark: string;
      textGray: string;
      textLight: string;
      textWhite: string;
      textPlaceholder: string;
      // objects
      green1: string;
      green2: string;
      green3: string;
      avatar: string; // gray60
      // gray shades, based on HSV: 218°, 6%, XX%
      gray10: string;
      gray20: string;
      gray30: string;
      gray40: string;
      gray50: string;
      gray60: string;
      gray70: string;
      gray80: string;
      gray85: string;
      gray90: string;
      gray95: string;
      gray96: string;
      // misc @LN
      borders: string;
      error: string;
      ripple: string;
      lightRipple: string;
      selection: string;
      pureWhite: string;
      sand: string;
      ios: string;
      android: string;
      background2: string;
      background3: string;
      textInputBackground: string;
      textVersion: string;
      downloadableBackground: string;
      newsActions: string;
      snackbarBackground: string;
      snackbarIcon: string;
      tabBarTintInactive: string;
      listRowItemBackground: string;
      tagsBackgroundColor: string;
      refreshControlSpinner: string;
      selectedDropdownItemBackground: string;
      itemBorder: string;
      icon: string;
      chatBubbleBackground: string;
      quoteBackground: string;
      imageBackground: string;
      messageReceivedTick: string;
      luciGreen: string;
      luciBlue: string;
      luciOrange: string;
      luciRed: string;
      userGroupChipBackground: string;
      indexIndicatorBackground: string;
      pagination: { active: string; inactive: string };
    }

    interface Theme {
      breakpoints: ThemeBreakpoints;
      customColors: CustomColors;
      buttons: {
        largeButton: number;
      };
      imagePreviewOverlay: {
        zIndex: number;
      };
      content: {
        maxWidth: number;
      };
    }
  }
}

export const baseTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#f2f2f2',
    primary: '#A42073', // luci base color for tenant selection
    accent: '#f33387',
  },
  roundness: 4,
  customColors: {
    primary: '#A42073', // luci base color for tenant selection
    accent: '#f33387',
    background: '#fcfbfa',
    // +--- custom colors below ---+
    // text
    text: '#000000',
    textDark: '#222222',
    textGray: '#787b80',
    textLight: '#afc1ab',
    textWhite: '#f5f5f5',
    textPlaceholder: '#a9a9a9',
    // objects
    green1: '#8ea38e',
    green2: '#99b6ab',
    green3: '#c0c6ab',
    avatar: '#909499', // gray60
    // gray shades, based on HSV: 218°, 6%, XX%
    gray10: '#18181a',
    gray20: '#303133',
    gray30: '#48494d',
    gray40: '#606266',
    gray50: '#787b80',
    gray60: '#909499',
    gray70: '#bcbccc',
    gray80: '#c0c5cc',
    gray85: '#ccd1d9',
    gray90: '#d8dee6',
    gray95: '#e4eaf2',
    gray96: 'hsl(0, 0%, 96%)',
    // misc @LN
    borders: '#dcdbda',
    error: '#D95B5B',
    ripple: 'rgba(81, 94, 81, .22)',
    lightRipple: 'rgba(81, 94, 81, .09)',
    selection: 'rgba(0,0,0, .20)',
    pureWhite: '#fff',
    sand: '#F2F1EF',
    ios: '#000000',
    android: '#a4c639',
    background2: 'hsl(0, 0%, 96%)',
    background3: '#ffffff',
    textInputBackground: '#EAEAEA',
    textVersion: '#303133',
    downloadableBackground: '#E6E4E2',
    newsActions: '#48494d',
    snackbarBackground: '#303133',
    snackbarIcon: '#fff',
    tabBarTintInactive: '#919498',
    listRowItemBackground: '#fff',
    tagsBackgroundColor: '#ccd1d9',
    refreshControlSpinner: '#919498',
    selectedDropdownItemBackground: '#d8dee6',
    itemBorder: '#9da7af',
    icon: '#303133',
    chatBubbleBackground: '#fff',
    quoteBackground: '#f2f2f2',
    imageBackground: '#E6E4E2',
    messageReceivedTick: '#34B7F1',
    signalGreen: '#8cd537',
    signalBlue: '#34B7F1',
    signalOrange: '#EFA84E',
    signalRed: '#D95B5B',
    userGroupChipBackground: '#E6E4E2',
    indexIndicatorBackground: '#E6E4E2',
    pagination: { active: '#000', inactive: '#9da7af' },
  },
  buttons: {
    largeButton: 35,
  },
  imagePreviewOverlay: {
    zIndex: 100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  content: {
    maxWidth: 450,
  },
};

export const darkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    background: '#121212',
    primary: '#A42073', // luci base color for tenant selection
    accent: '#f33387',
  },
  roundness: 4,
  customColors: {
    primary: '#A42073', // luci base color for tenant selection
    accent: '#f33387',
    background: '#fcfbfa',
    // +--- custom colors below ---+
    // text
    text: '#e4eaf2',
    textDark: '#787b80',
    textGray: '#787b80',
    textLight: '#afc1ab',
    textWhite: '#f5f5f5',
    textPlaceholder: '#a9a9a9',
    // objects
    green1: '#8ea38e',
    green2: '#99b6ab',
    green3: '#c0c6ab',
    avatar: '#909499', // gray60
    // gray shades, based on HSV: 218°, 6%, XX%
    gray10: '#18181a',
    gray20: '#303133',
    gray30: '#48494d',
    gray40: '#606266',
    gray50: '#787b80',
    gray60: '#909499',
    gray70: '#bcbccc',
    gray80: '#c0c5cc',
    gray85: '#ccd1d9',
    gray90: '#d8dee6',
    gray95: '#e4eaf2',
    gray96: 'hsl(0, 0%, 96%)',
    // misc @LN
    borders: '#303133',
    error: '#D95B5B',
    ripple: 'rgba(81, 94, 81, .22)',
    lightRipple: 'rgba(81, 94, 81, .09)',
    selection: 'rgba(0,0,0, .20)',
    pureWhite: '#fff',
    sand: '#F2F1EF',
    ios: '#000000',
    android: '#a4c639',
    background2: '#161616',
    background3: '#272727',
    textInputBackground: '#161616',
    textVersion: '#909499',
    downloadableBackground: '#303133',
    newsActions: '#909499',
    snackbarBackground: '#303133',
    snackbarIcon: '#fff',
    tabBarTintInactive: '#909499',
    listRowItemBackground: '#18181a',
    tagsBackgroundColor: '#303133',
    refreshControlSpinner: '#d8dee6',
    selectedDropdownItemBackground: '#606266',
    itemBorder: '#9da7af',
    icon: '#ccd1d9',
    chatBubbleBackground: '#2a2d3d',
    quoteBackground: '#212430',
    imageBackground: '#303133',
    messageReceivedTick: '#34B7F1',
    signalGreen: '#8cd537',
    signalBlue: '#34B7F1',
    signalOrange: '#EFA84E',
    signalRed: '#D95B5B',
    userGroupChipBackground: '#303133',
    indexIndicatorBackground: '#303133',
    pagination: { active: '#fff', inactive: '#9da7af' },
  },
  buttons: {
    largeButton: 35,
  },
  imagePreviewOverlay: {
    zIndex: 100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  content: {
    maxWidth: 450,
  },
};

export type AppTheme = typeof baseTheme;

export const useAppTheme = () => useTheme<AppTheme>();
