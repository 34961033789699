import { ExpoPushToken } from 'expo-notifications';
import { useDeletePushNotificationDeviceTokenMutation } from '../../graphql/operations';
import { useStore } from '../stores/store';

export const removePushNotificationDeviceToken = async (deviceToken: ExpoPushToken) => {
  const pushTokens = useStore.getState().pushTokens;
  if (!pushTokens?.includes(deviceToken.data)) {
    return;
  }
  await useDeletePushNotificationDeviceTokenMutation.fetcher({
    deviceToken: deviceToken.data,
  })();
};
