import { ExceptionCode } from '../components/Auth/types/ExceptionCode';

type ReturnValue = {
  field: 'user' | 'pass';
  message: string;
};

export class AuthError extends Error {
  constructor(message: ExceptionCode) {
    super(message);
  }
}

export const getCognitoAuthErrorMessageForRelogin = (errorMessage: string): ReturnValue => {
  const [code, message] = (errorMessage || '').split(';');

  if (
    code === ExceptionCode.NotAuthorizedException &&
    message === 'Incorrect username or password.'
  ) {
    return {
      field: 'pass',
      message: 'Das Passwort ist falsch.',
    };
  }

  if (code === ExceptionCode.RequestFailedException) {
    return {
      field: 'pass',
      message:
        'Es besteht keine Internetverbindung. Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind und versuchen Sie es erneut.',
    };
  }

  throw new AuthError(code as ExceptionCode);
};
