import { useQueryClient } from '@tanstack/react-query';
import {
  InquiryStatus,
  NewsAttachmentType,
  useCreateInquiryMessageMutation,
  useGetInquiryDetailsQuery,
  useListInquiriesQuery,
  useMarkInquiryAsReadMutation,
  useUnreadInquiriesCountQuery,
  useUpdateInquiryMutation,
} from '../../../graphql/operations';
import { AttachmentPickerResultItem } from '../../components/Common/Media/types';
import { Log } from '../../utils/Log';

export const useInquiryDetailsMutations = (
  inquiryId: string,
  refreshInquiryDetails?: () => Promise<void>,
) => {
  const queryClient = useQueryClient();
  const markAsRead = useMarkInquiryAsReadMutation();
  const updateInquiryMutation = useUpdateInquiryMutation();
  const createInquiryMessage = useCreateInquiryMessageMutation();

  const doMarkAsRead = async () => {
    try {
      await markAsRead.mutateAsync({ input: { inquiryId } });
      await Promise.all([
        queryClient.invalidateQueries(useUnreadInquiriesCountQuery.getKey()),
        queryClient.invalidateQueries(useListInquiriesQuery.getKey()),
      ]);
    } catch (e) {
      Log.error(e, { message: 'cannot mark inquiry as read with id ' + inquiryId });
    }
  };

  const updateInquiry = async (status: InquiryStatus) => {
    try {
      await updateInquiryMutation.mutateAsync({ input: { id: inquiryId, status } });
      await Promise.all([
        queryClient.invalidateQueries(useGetInquiryDetailsQuery.getKey({ input: { inquiryId } })),
        queryClient.invalidateQueries(useListInquiriesQuery.getKey()),
      ]);
      await refreshInquiryDetails?.();
      return true;
    } catch (e) {
      Log.error(e, { message: 'cannot update inquiry status' });
      return false;
    }
  };

  const sendMessageSuccess = async () => {
    try {
      await queryClient.invalidateQueries(
        useGetInquiryDetailsQuery.getKey({ input: { inquiryId } }),
      );
      await refreshInquiryDetails?.();
      return true;
    } catch (e) {
      Log.error(e, { message: 'could not invalidate inquiry' });
      return false;
    }
  };

  const sendMessage = async (messageTexts: string[]) => {
    try {
      for (const messageText of messageTexts) {
        await createInquiryMessage.mutateAsync({ input: { inquiryId, text: messageText } });
      }
      await sendMessageSuccess();
      return true;
    } catch (e) {
      Log.error(e, { message: 'cannot send message for inquiry ' + inquiryId });
      return false;
    }
  };

  const sendMediaMessage = async (items: AttachmentPickerResultItem[]) => {
    try {
      const sendMessagePromises = items.map(({ fallbackText, fileName, otcPath, type }) => {
        return createInquiryMessage.mutateAsync({
          input: {
            inquiryId,
            text: fallbackText,
            attachment: {
              otcPath,
              type: type as NewsAttachmentType,
              fileName,
            },
          },
        });
      });
      await Promise.all(sendMessagePromises);
      await sendMessageSuccess();
      return true;
    } catch (e) {
      Log.error(e, { message: 'cannot send message for inquiry ' + inquiryId });
      return false;
    }
  };

  const isMutating =
    updateInquiryMutation.isLoading || createInquiryMessage.isLoading || markAsRead.isLoading;

  return {
    doMarkAsRead,
    updateInquiry,
    sendMessage,
    sendMediaMessage,
    isMutating,
  };
};
