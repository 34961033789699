import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { TransitionPresets } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import { LoginLegalScreen } from '../../components/Auth/LoginLegalScreen';
import { OtcPdfReader } from '../../components/Common/Media/OtcPdfReader';
import { MaintenanceScreen } from '../../components/Maintenance/MaintenanceScreen';
import { InformationScreen } from '../../components/Menu/InformationScreen';
import { ChatRoomScreen } from '../../features/chat/screens/ChatRoomScreen';
import { GroupChatDetailScreen } from '../../features/chat/screens/GroupChatDetailScreen';
import { NewDirectChatScreen } from '../../features/chat/screens/NewDirectChatScreen';
import { FormDetailScreen } from '../../features/forms/screens/FormDetailScreen';
import { FormsHomeScreen } from '../../features/forms/screens/FormsHomeScreen';
import { InfoPagesHome } from '../../features/infoPages/screens/InfoPagesHome';
import { ScheduleLoginVivendi } from '../../features/integrations/workSchedule/screens/ScheduleLoginVivendi';
import { ScheduleScreenVivendi } from '../../features/integrations/workSchedule/screens/ScheduleScreenVivendi';
import { NewsDetail } from '../../features/news/NewsDetail';
import { LanguageSelectionScreen } from '../../features/preferences/screens/LanguageSelectionScreen';
import { SettingsScreen } from '../../features/preferences/screens/SettingsScreen';
import { ProfileEdit } from '../../features/profiles/screens/ProfileEdit';
import { PublicProfileScreen } from '../../features/profiles/screens/PublicProfileScreen';
import { CheckInboxScreen } from '../../screens/Auth/CheckInboxScreen';
import { ConfirmationCode } from '../../screens/Auth/ConfirmationCode';
import VerifyAttributeScreen from '../../screens/Auth/VerifyAttributeScreen';
import { ConsentScreen } from '../../screens/Informational/ConsentScreen';
import FairplayScreen from '../../screens/Informational/FairplayScreen';
import { ImprintScreen } from '../../screens/Informational/ImprintScreen';
import { LegalScreen } from '../../screens/Informational/LegalScreen';
import { PrivacyScreen } from '../../screens/Informational/PrivacyScreen';
import VersionScreen from '../../screens/Informational/VersionScreen';
import { InquiryDetailsScreen } from '../../screens/InquiryDetailsScreen/InquiryDetailsScreen';
import { NewInquiryScreen } from '../../screens/NewInquiryScreen/NewInquiryScreen';
import { LikesDetail } from '../../screens/News/LikesDetail';
import { useAppTheme } from '../../styles/theme';
import { AppStack } from '../AppNavigator';
import { HelpModalNavigator } from './HelpModalNavigator';
import { HomeTabNavigator } from './HomeTabNavigator';
import { ResolvedInquiriesScreen } from '../../features/inquiries/screens/ResolvedInquiriesScreen';
import { FormReplyDetailScreen } from '../../features/forms/screens/FormReplyDetailScreen';
import { PdfReader } from '../../components/Common/Media/PdfReader';
import { NewNewsScreen } from '../../features/news/NewNewsScreen';
import { AddMediaScreen } from '../../features/news/newsCreation/screens';
import { ChatMessageReadReceiptsScreen } from '../../features/chat/screens/ChatMessageReadReceiptsScreen';
import { ReportAbuseScreen } from '../../screens/Informational/ReportAbuseScreen';

export const maxContentAreaWidth = 648;

export const ContentNavigator = () => {
  const theme = useAppTheme();

  return (
    <AppStack.Navigator
      initialRouteName={'Tabs'}
      screenOptions={{
        headerMode: 'screen',
        headerStyle: {
          backgroundColor: theme.customColors.background3,
          borderBottomColor: theme.customColors.background3,
        },
        headerTintColor: theme.customColors.text,
      }}
    >
      <AppStack.Screen
        name="Tabs"
        component={HomeTabNavigator}
        options={{
          title: 'Zurück',
          headerShown: false,
        }}
      />
      <AppStack.Screen
        name="Help"
        component={HelpModalNavigator}
        options={{
          headerShown: false,
          headerTitle: 'Hilfe',
          presentation: 'modal',
          ...TransitionPresets.ModalPresentationIOS,
        }}
      />
      <AppStack.Screen
        name="InformationScreen"
        options={{
          headerShown: false,
        }}
        component={InformationScreen}
      />
      <AppStack.Screen
        name="Imprint"
        options={{
          headerShown: false,
        }}
        component={ImprintScreen}
      />
      <AppStack.Screen
        name="Consent"
        options={{
          headerShown: false,
        }}
        component={ConsentScreen}
      />
      <AppStack.Screen
        name="Privacy"
        options={{
          headerShown: false,
        }}
        component={PrivacyScreen}
      />
      <AppStack.Screen
        name="Fairplay"
        options={{
          headerShown: false,
        }}
        component={FairplayScreen}
      />
      <AppStack.Screen
        name="Version"
        options={{
          headerShown: false,
        }}
        component={VersionScreen}
      />
      <AppStack.Screen
        name="Legal"
        options={{
          headerShown: false,
        }}
        component={LegalScreen}
      />
      <AppStack.Screen
        name="ChatDetail"
        options={() => ({
          //@ts-ignore
          headerShown: false,
        })}
        component={GroupChatDetailScreen}
      />
      <AppStack.Screen
        name="NewDirectChat"
        options={{
          headerShown: false,
        }}
        component={NewDirectChatScreen}
      />
      <AppStack.Screen
        name="Profile"
        options={{
          headerShown: false,
        }}
        component={PublicProfileScreen}
      />
      <AppStack.Screen
        name="ProfileEdit"
        options={{
          headerShown: false,
        }}
      >
        {properties => (
          <ActionSheetProvider>
            <ProfileEdit {...properties} />
          </ActionSheetProvider>
        )}
      </AppStack.Screen>
      <AppStack.Screen
        name="Settings"
        options={{
          headerShown: false,
        }}
        component={SettingsScreen}
      />
      <AppStack.Screen
        name="ReportAbuse"
        options={{
          headerShown: false,
        }}
        component={ReportAbuseScreen}
      />
      <AppStack.Screen
        name="ChatRoom"
        options={{
          title: 'Chat',
          headerShown: false,
          cardStyle: {
            ...(Platform.OS === 'web'
              ? {
                  flex: 1,
                }
              : {}),
          },
        }}
        component={ChatRoomScreen}
      />
      <AppStack.Screen
        name="NewsDetail"
        options={{
          headerShown: false,
        }}
        component={NewsDetail}
      />
      <AppStack.Screen
        name="NewNews"
        options={{
          headerTitle: 'News erstellen',
          headerShown: false,
        }}
        component={NewNewsScreen}
      />
      <AppStack.Screen name="NewsLikes" options={{ headerShown: false }} component={LikesDetail} />
      <AppStack.Screen
        name="CheckInbox"
        options={{
          headerShown: false,
        }}
        component={CheckInboxScreen}
      />
      <AppStack.Screen
        name="ConfirmationCode"
        options={{
          headerShown: false,
        }}
        component={ConfirmationCode}
      />
      <AppStack.Screen
        name="VerifyAttribute"
        options={{
          headerShown: false,
        }}
        component={VerifyAttributeScreen}
      />
      <AppStack.Screen
        name="OtcPdfReader"
        options={{
          headerShown: false, // OtcPdfReader reader implements its own header
        }}
        component={OtcPdfReader}
      />
      <AppStack.Screen
        name="PdfReader"
        options={{
          headerShown: false, // PdfReader reader implements its own header
        }}
        component={PdfReader}
      />
      <AppStack.Screen
        name="ScheduleLoginVivendi"
        options={{
          title: 'Vivendi Login',
        }}
        component={ScheduleLoginVivendi}
      />
      <AppStack.Screen
        name="ScheduleScreenVivendi"
        options={{
          title: 'Dienstplan',
        }}
        component={ScheduleScreenVivendi}
      />
      <AppStack.Screen
        name="FormsHomeScreen"
        options={{
          headerShown: false,
        }}
        component={FormsHomeScreen}
      />
      <AppStack.Screen
        name="FormDetail"
        options={() => ({
          headerShown: false,
        })}
        component={FormDetailScreen}
      />
      <AppStack.Screen
        name="InfoPagesHome"
        options={{
          headerShown: false,
        }}
        component={InfoPagesHome}
      />
      <AppStack.Screen
        name="LegalFlow"
        options={{ title: 'Rechtliches' }}
        component={LoginLegalScreen}
      />
      <AppStack.Screen
        name="LanguageSelection"
        options={{ headerShown: false }}
        component={LanguageSelectionScreen}
      />
      <AppStack.Screen
        name="Maintenance"
        options={{
          headerShown: false,
          headerTitle: 'Maintenance',
          presentation: 'transparentModal',
          gestureEnabled: false,
          ...TransitionPresets.ModalPresentationIOS,
        }}
        component={MaintenanceScreen}
      />
      <AppStack.Screen
        name="NewInquiry"
        options={{
          headerShown: false,
        }}
        component={NewInquiryScreen}
      />
      <AppStack.Screen
        name="InquiryDetails"
        options={{
          headerShown: false,
        }}
        component={InquiryDetailsScreen}
      />
      <AppStack.Screen
        name="ResolvedInquiries"
        options={{
          title: 'Geschlossene Anfragen',
          headerShown: false,
          headerBackTitle: 'Zurück',
        }}
        component={ResolvedInquiriesScreen}
      />
      <AppStack.Screen
        name="FormReplyDetail"
        options={{
          headerShown: false,
        }}
        component={FormReplyDetailScreen}
      />
      <AppStack.Screen
        name="AddMediaScreen"
        options={{
          title: 'Medien',
          headerShown: false,
          presentation: 'transparentModal',
          gestureEnabled: false,
          ...TransitionPresets.ModalPresentationIOS,
        }}
        component={AddMediaScreen}
      />
      <AppStack.Screen
        name="ChatMessageReadReceipts"
        options={{
          headerShown: false,
        }}
        component={ChatMessageReadReceiptsScreen}
      />
    </AppStack.Navigator>
  );
};
