import { FormQuestion } from '../../../../graphql/operations';

export const validateTextInput = (question: FormQuestion, value?: string) => {
  if (question.required === false) {
    return;
  }
  if (value === undefined || value === null || value === '' || typeof value !== 'string') {
    return 'Bitte Antwort ausfüllen';
  }
};
