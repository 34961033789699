import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';
import { Constants } from '../Constants';

export const toast = (message: string) =>
  Toast.show(message, {
    duration: Toast.durations.LONG,
    position:
      Platform.OS === 'ios' ? Constants.TOAST_POSITION_IOS : Constants.TOAST_POSITION_ANDROID,
    animation: true,
    hideOnPress: true,
  });
