import { useEffect, useRef } from 'react';
import { Animated as AnimatedRN } from 'react-native';

interface IUseAnimatedBlur {
  intensity: {
    from: number;
    to: number;
  };
  duration?: number;
  whileAnimating?: () => void;
}

export const useAnimatedBlur = ({
  intensity,
  whileAnimating,
  duration = 2000,
}: IUseAnimatedBlur) => {
  const blurValue = useRef(new AnimatedRN.Value(intensity.from));
  const midway = (intensity.to + intensity.from) / 2;

  const animate = () => {
    AnimatedRN.timing(blurValue.current, {
      toValue: midway,
      duration: duration / 2,
      useNativeDriver: false,
    }).start(() => {
      whileAnimating?.();
      AnimatedRN.timing(blurValue.current, {
        toValue: intensity.to,
        duration: duration / 2,
        useNativeDriver: false,
      }).start();
    });
  };

  useEffect(() => {
    animate();
  }, []);

  useEffect(() => {
    setTimeout(() => blurValue.current.setValue(intensity.to), duration);
  }, []);

  return { blurValue };
};
