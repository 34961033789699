import { StorageAccessFramework } from 'expo-file-system';

/**
 * Unwrapped because jest cannot spyon on a function if it is inside a namespace (StorageAccessFramework) in a module (expo-file-system)
 * @description Only works on android
 */
export const requestDirectoryPermissionsAsync: typeof StorageAccessFramework.requestDirectoryPermissionsAsync =
  async initialFileUrl => {
    return StorageAccessFramework.requestDirectoryPermissionsAsync(initialFileUrl);
  };
