import { useState } from 'react';
import { IDownload, useDownloadOtcFile } from './useDownloadOtcFile';
import { ISave, useSaveFileToDevice } from './useSaveFileToDevice';

type IUseDownloadThenSave = IDownload & Omit<ISave, 'uri'>;

export const useDownloadThenSaveOtcFile = () => {
  const [downloadedFileUri, setDownloadedFileUri] = useState<string>();
  const { download, isDownloading } = useDownloadOtcFile();
  const { saveFileToDevice } = useSaveFileToDevice();

  const downloadThenSave = async ({ fileName, url, mimeType }: IUseDownloadThenSave) => {
    const { uri } = await download({ url, fileName });
    setDownloadedFileUri(uri);
    await saveFileToDevice({ uri, fileName, mimeType });
    return;
  };

  return { downloadThenSave, isDownloading, downloadedFileUri };
};
