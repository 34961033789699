import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect } from 'react';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { Log } from '../../../utils/Log';
import { useNavigation } from '@react-navigation/native';
import { useTenantContext } from '../../../hooks/tenant';

export enum UseMaintenanceStatusOfTenantHookLocation {
  LoginScreen,
  HomeTabNavigator,
  MaintenanceScreen,
}

export const useMaintenanceStatusOfTenant = (
  hookedTo: UseMaintenanceStatusOfTenantHookLocation,
) => {
  const { tenant } = useTenantContext();
  const isTenantInMaintenance = Boolean(tenant && tenant.inMaintenance);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  useEffect(() => {
    if (!tenant) {
      return;
    }

    switch (hookedTo) {
      case UseMaintenanceStatusOfTenantHookLocation.HomeTabNavigator:
        if (isTenantInMaintenance) {
          navigation.navigate('Maintenance');
        }
        break;
      case UseMaintenanceStatusOfTenantHookLocation.MaintenanceScreen:
        if (tenant && !isTenantInMaintenance) {
          navigation.goBack();
        }
        break;
      default:
        Log.warning({
          message: 'invalid call to useMaintenanceStatusOfTenantHook',
        });
        break;
    }
  }, [tenant]);

  return { isTenantInMaintenance: isTenantInMaintenance };
};
