import React, { memo } from 'react';
import { Modal, ActivityIndicator } from 'react-native-paper';
import { View } from 'react-native';
import { useAppTheme } from '../../../styles/theme';

export const LoadingBlocker = memo(
  (props: {
    children?: React.ReactNode;
    visible: boolean;
    spinnerColor?: string;
    opacity?: string;
  }): JSX.Element => {
    const theme = useAppTheme();

    return (
      <Modal
        dismissable={false}
        visible={props.visible}
        theme={{
          colors: {
            backdrop: `rgba(0, 0, 0, ${props.opacity || '0.5'})`,
          },
        }}
      >
        <ActivityIndicator
          style={{
            alignSelf: 'center',
            flex: 1,
            justifyContent: 'center',
          }}
          size="large"
          animating={true}
          color={props.spinnerColor || theme.customColors.primary}
        />
        <View
          style={{
            flexDirection: 'row',
            marginTop: 50,
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          {props.children}
        </View>
      </Modal>
    );
  },
);
