import * as yup from 'yup';
import { NewsAttachmentType, UserType } from '../../../../graphql/operations';

export const createNewsShape = {
  title: yup.string().trim().required('News-Titel benötigt'),
  userTypes: yup
    .array(yup.mixed<UserType>().oneOf(Object.values(UserType)).required())
    .ensure()
    .required(),
  content: yup.string().trim().required('News-Inhalt benötigt'),
  tags: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required(),
  isLikesEnabled: yup.boolean().required(),
  isCommentsEnabled: yup.boolean().required(),
  isReadConfirmationsEnabled: yup.boolean().required(),
  attachments: yup
    .array(
      yup.object({
        type: yup.string().oneOf(Object.values(NewsAttachmentType)).required(),
        s3Path: yup.string(),
      }),
    )
    .ensure()
    .required(),
  userGroupIds: yup.array().of(yup.string().required()).required(),
  isTemplate: yup.boolean().required(),
  isPublished: yup.boolean().required(),
  templateName: yup.string().optional(),
  expiredAt: yup.date(),
  visibleAt: yup.date(),
  isPinned: yup.boolean().required(),
  forms: yup
    .array()
    .of(yup.object({ title: yup.string().required(), id: yup.string().required() })),
  infoPages: yup
    .array()
    .of(yup.object({ title: yup.string().required(), id: yup.string().required() })),
};
