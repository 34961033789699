import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

export const useIsKeyboardVisible = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [willKeyboardBeVisible, setWillKeyboardBeVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const didShowSubscription = Keyboard.addListener('keyboardDidShow', keyboardEvent => {
      setIsKeyboardVisible(true);
      // having this for android
      setWillKeyboardBeVisible(true);
      setKeyboardHeight(keyboardEvent.endCoordinates.height);
    });
    const didHideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardVisible(false);
      // having this for android
      setWillKeyboardBeVisible(false);
      setKeyboardHeight(0);
    });
    // this triggers on iOS only https://reactnative.dev/docs/keyboard
    const willShowSubscription = Keyboard.addListener('keyboardWillShow', keyboardEvent => {
      setWillKeyboardBeVisible(true);
      setKeyboardHeight(keyboardEvent.endCoordinates.height);
    });
    // this triggers on iOS only https://reactnative.dev/docs/keyboard
    const willHideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setWillKeyboardBeVisible(false);
      setKeyboardHeight(0);
    });

    return () => {
      didHideSubscription.remove();
      didShowSubscription.remove();
      willShowSubscription.remove();
      willHideSubscription.remove();
    };
  }, []);

  return { isKeyboardVisible, willKeyboardBeVisible, keyboardHeight };
};
