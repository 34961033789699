import React, { useMemo } from 'react';
import { useWindowDimensions, StyleSheet, View } from 'react-native';
import { AutoHeightImage } from '../AutoHeightImage';
import { LinkPreviewItem } from '../../features/chat/components/LinkPreviewItem';
import { PreviewItem, PreviewItemType } from './types';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';

type PreviewItemFactoryProps = {
  item: PreviewItem;
  isPartOfMultipleItems: boolean;
  removeItem: (itemId: string) => void;
};

export const PreviewItemFactory = ({
  item,
  isPartOfMultipleItems,
  removeItem,
}: PreviewItemFactoryProps) => {
  const { getResponsiveContentWidth } = useResponsiveDrawer();
  const width = getResponsiveContentWidth();
  const { height } = useWindowDimensions();
  const styles = useMemo(() => createStyles(width), [width]);
  const removeIfPartOfMultipleItems = isPartOfMultipleItems
    ? () => removeItem(item.itemId)
    : undefined;

  const renderContent = () => {
    switch (item.type) {
      case PreviewItemType.image:
        return (
          <AutoHeightImage
            source={{ uri: item.source }}
            width={width * 0.9}
            maxHeight={height * 0.8}
          />
        );
      case PreviewItemType.document:
        return (
          <LinkPreviewItem
            title={item.title}
            icon={'file-document'}
            remove={removeIfPartOfMultipleItems}
          />
        );
      case PreviewItemType.form:
        return (
          <LinkPreviewItem
            title={item.title}
            icon={'format-list-checks'}
            remove={removeIfPartOfMultipleItems}
          />
        );
      case PreviewItemType.infoPage:
        return (
          <LinkPreviewItem
            title={item.title}
            icon={'file-document'}
            remove={removeIfPartOfMultipleItems}
          />
        );
      default:
        return <></>;
    }
  };

  return <View style={styles.container}>{renderContent()}</View>;
};

const createStyles = (width: number) =>
  StyleSheet.create({
    container: {
      width,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
