import { InfiniteData } from '@tanstack/react-query';
import {
  NewsToReadByUserGroupQuery,
  TranslateNewsPostMutation,
  UpdateUsersWhoHaveTranslatedMutation,
} from '../../../../graphql/operations';

type MapTranslationOntoExistingNewsListParams = {
  data: InfiniteData<NewsToReadByUserGroupQuery> | undefined;
  newsId: string;
  translation:
    | TranslateNewsPostMutation['translateNewsPost']
    | UpdateUsersWhoHaveTranslatedMutation['updateUsersWhoHaveTranslated'];
};

export const mapTranslationOntoExistingNewsList = ({
  data,
  newsId,
  translation,
}: MapTranslationOntoExistingNewsListParams) => {
  const newNewsList = data?.pages?.map(page => {
    const oldPage = page.newsListByUserGroup.news;
    return {
      ...page,
      newsListByUserGroup: {
        ...page.newsListByUserGroup,
        news: oldPage.map(news => {
          if (news.id === newsId) {
            return {
              ...news,
              newsPostTranslation: translation,
            };
          }
          return news;
        }),
      },
    };
  });
  return {
    pageParams: data?.pageParams ?? [],
    pages: newNewsList ?? [],
  };
};
