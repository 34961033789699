import React, { useMemo } from 'react';
import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';
import { TileProps } from './TileFactoryProps';
import { WithLoadingIndicator } from '../WithLoadingIndicator/WithLoadingIndicator';
import { Shimmer } from '../Shimmer/Shimmer';
import { AppTheme, useAppTheme } from '../../styles/theme';

export function ImageTile<P>({ file, width }: TileProps<P>) {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  return (
    <View style={styles.container}>
      <WithLoadingIndicator
        on={!!file.uri}
        render={() => <Image style={styles.image} source={file.uri} transition={250} />}
        renderWhileLoading={() => (
          <Shimmer
            width={width}
            height={width}
            radius={10}
            color={theme.customColors.imageBackground}
          />
        )}
      />
    </View>
  );
}

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    container: {
      width: width,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
    },
    image: {
      width: width,
      height: width,
      borderRadius: 10,
      backgroundColor: theme.customColors.imageBackground,
    },
  });
