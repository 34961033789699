import { IconButton } from 'react-native-paper';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import { getFullNameContacts, getInitials, getJob } from '../utils';
import Highlighter from '@luciapp/react-native-highlight-words';
import React, { useMemo } from 'react';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';

type CommonLargeUserItemPropsItem = {
  userId: string;
  pictureOTC?: string | null;
  firstname?: string;
  lastname?: string;
  titleBefore?: string | null;
  titleAfter?: string | null;
  employeeFunction?: string | null;
  department?: string | null;
};

type CommonLargeUserItemProps = {
  item: CommonLargeUserItemPropsItem;
  ripple: boolean;
  onPress: (arg0: CommonLargeUserItemPropsItem) => void;
  onMenuPress?: (arg0: CommonLargeUserItemPropsItem) => void;
  menuIcon?: string;
  searchValue?: string;
  width: number;
};

export const CommonLargeUserItem = (props: CommonLargeUserItemProps) => {
  const theme = useAppTheme();
  const width = props.width;
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  const handleMenu = () => {
    if (typeof props.onMenuPress === 'function') {
      props.onMenuPress(props.item);
    }
  };

  const handleTouchable = () => {
    props.onPress(props.item);
  };

  if (!props.item) {
    return <></>;
  }
  const job = getJob(props.item);

  return (
    <TouchableHighlight
      testID="CommonLargeUserItem"
      underlayColor={'transparent'}
      activeOpacity={0.97}
      style={styles.row}
      onPress={handleTouchable}
    >
      <>
        <AvatarDisplay
          size={70}
          avatar={{
            otcPath: props.item.pictureOTC ?? undefined,
            initials: getInitials(props.item ?? undefined),
          }}
          type={UploadType.ProfilePicture}
        />
        <View style={styles.informationContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.nameTxt} numberOfLines={1} ellipsizeMode="tail">
              {getFullNameContacts(props.item)}
            </Text>
          </View>
          <View style={styles.msgContainer}>
            {job && (
              <Highlighter
                highlightStyle={{ fontWeight: '700', color: '#000' }}
                searchWords={[props.searchValue ?? '']}
                textToHighlight={job}
                style={styles.msgTxt}
              />
            )}
          </View>
        </View>
        <View style={styles.action}>
          {props.menuIcon && (
            <IconButton
              icon={props.menuIcon}
              iconColor={theme.customColors.gray70}
              size={34}
              style={{ alignSelf: 'flex-end' }}
              onPress={handleMenu}
            />
          )}
        </View>
      </>
    </TouchableHighlight>
  );
};

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: theme.customColors.borders,
      backgroundColor: theme.customColors.listRowItemBackground,
      borderBottomWidth: 1,
      padding: 10,
      width: width,
      paddingLeft: 22,
    },
    pic: {
      backgroundColor: theme.customColors.gray60,
      borderColor: theme.customColors.textLight,
      borderWidth: 0,
    },
    informationContainer: {
      flex: 1,
      marginLeft: 12,
    },
    nameContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    nameTxt: {
      flex: 1,
      fontWeight: '600',
      color: theme.customColors.text,
      fontSize: 18,
    },
    msgContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    msgTxt: {
      fontWeight: '400',
      color: theme.customColors.textGray,
      fontSize: 12,
    },
    highlightText: {
      fontWeight: '400',
      color: '#222',
      fontSize: 14,
      marginTop: 3,
      marginLeft: 15,
    },
    action: {
      // flex: 1
    },
  });
