import { Log } from '../../utils/Log';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext } from 'react';

import {
  useGetUserPreferenceQuery,
  useUpdateUserPreferenceMutation,
} from '../../../graphql/operations';
import { useQueryClient } from '@tanstack/react-query';

const useProvidePreferences = () => {
  const queryClient = useQueryClient();

  const updateNotificationChannel = async (
    userId: string,
    settings: {
      notificationsForChatMessagesEnabled: boolean | undefined;
      notificationsForNewsEnabled: boolean | undefined;
      notificationsForInquiriesEnabled: boolean | undefined;
    },
  ) => {
    if (!userId) {
      return Promise.reject();
    }
    try {
      await useUpdateUserPreferenceMutation.fetcher({
        input: {
          notificationsForChatMessagesEnabled: settings.notificationsForChatMessagesEnabled,
          notificationsForNewsEnabled: settings.notificationsForNewsEnabled,
          notificationsForInquiriesEnabled: settings.notificationsForInquiriesEnabled,
        },
      })();
      /* @todo must be awaited */
      void queryClient.invalidateQueries(useGetUserPreferenceQuery.getKey({ userId: userId }));
    } catch (e) {
      Log.warning(e);
    }
  };

  return { updateNotificationChannel };
};

const PreferencesContext = createContext({});

export const PreferencesProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const value = useProvidePreferences();

  return <PreferencesContext.Provider value={value}>{props.children}</PreferencesContext.Provider>;
};

export const usePreferences = () =>
  useContext(PreferencesContext) as ReturnType<typeof useProvidePreferences>;
