import { useEffect, useState } from 'react';
import {
  News,
  NewsAttachment,
  NewsAttachmentInputs,
  NewsAttachmentType,
  NewsToReadByUserGroupQuery,
  UpdateNewsMutationVariables,
  UserType,
  useCreateNewsMutation,
  useInfiniteNewsToReadByUserGroupQuery,
  useUpdateNewsMutation,
  StockImageApi,
} from '../../../../graphql/operations';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { uploadFiles } from '../../../components/Common/FileUpload/uploadFiles';
import { UploadedImagesResponse } from '../../../components/Common/Media/types';
import { FileOptionalParams, File } from '../../../components/FileGallery/types';
import { Log } from '../../../utils/Log';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useDownloadFile } from '../../../components/Common/FileUpload/queries';
import { FileSystemDownloadResult, downloadAsync, cacheDirectory } from 'expo-file-system';
import shortHash from 'shorthash2';
import { useStore } from '../../../stores/store';
import { updateNewsInNewsToRead } from '../utils/updateNewsInNewsToRead';

type ICreateNews = {
  onSuccess: () => void;
  plainTextTitle: string;
  markdownContent: string;
  files: File<FileOptionalParams>[];
  userGroupIds: string[];
  formIds?: string[];
  infoPageIds?: string[];
  visibleAt?: Date;
  expiredAt?: Date;
  isLikesEnabled: boolean;
  isCommentsEnabled: boolean;
  isReadConfirmationsEnabled: boolean;
  isPinned: boolean;
};

type IUpdateNews = ICreateNews & { id: string; attachments?: NewsAttachment[] };

type DownloadResult = FileSystemDownloadResult & { otcPath: string };

type IUseNewNewsRequests = {
  attachmentsToDownload?: NewsAttachment[];
  onAttachmentsDownloaded: (downloadedAttachments: DownloadResult[]) => void;
};

type IHandleFiles = { files: File<FileOptionalParams>[]; existingAttachments?: NewsAttachment[] };

export const useNewNewsRequests = ({
  attachmentsToDownload,
  onAttachmentsDownloaded,
}: IUseNewNewsRequests) => {
  const createNewsMutation = useCreateNewsMutation();
  const updateNewsMutation = useUpdateNewsMutation();
  const [isLoading, setLoading] = useState(false);
  const [isDownloadingAttachments, setDownloadingAttachments] = useState(false);
  const queryClient = useQueryClient();
  const download = useDownloadFile();
  const userGroupIdFiltersForNews = useStore(s => s.UserGroupIdFiltersForNews);

  useEffect(() => {
    if (attachmentsToDownload) {
      triggerAttachmentDownload(attachmentsToDownload);
    }
  }, [attachmentsToDownload]);

  const triggerAttachmentDownload = (attachments: NewsAttachment[]) => {
    const otcPaths = attachments.map(attachment => {
      if (!attachment.otcPath) {
        throw new Error('news attachments must have an otcPath');
      }
      return attachment.otcPath;
    });
    void downloadAttachedFiles(otcPaths);
  };

  const handleNewFiles = async ({ files, existingAttachments }: IHandleFiles) => {
    const filesToUpload = files
      .filter(
        // do not reupload files if attachment already has otc path
        ({ optionalParams }) =>
          !existingAttachments
            ?.map(attachment => attachment.otcPath)
            .includes(optionalParams?.otcPath),
      )
      .map(({ uri }) => ({ uri, uploadType: UploadType.NewsPost }));
    if (filesToUpload.length === 0) {
      return [];
    }
    const res: UploadedImagesResponse[] = await uploadFiles({
      filesToUpload,
    });
    const uploadedFiles = res[0];
    return uploadedFiles.newFileNames.flatMap((item: string[]) => {
      if (item.length !== 2) {
        throw new Error('uploaded images response does not include a file name and an otc path');
      }
      const fileName = item[0];
      const correspondingFile = files.find(file => file.uri.includes(fileName));
      const stockImageData = correspondingFile?.optionalParams?.stockImageData;
      const otcPath = item[1];
      return {
        type: NewsAttachmentType.Image,
        otcPath,
        fileName,
        s3Path: `unused`,
        //todo also add width and height on non stock images
        width: stockImageData?.width ?? undefined,
        height: stockImageData?.height ?? undefined,
        metadata: stockImageData
          ? {
              api: StockImageApi.Pexels,
              data: {
                author: stockImageData.photographer,
                pageUrl: stockImageData.url,
                urls: stockImageData.src,
              },
            }
          : undefined,
      } as NewsAttachmentInputs;
    });
  };

  const createNews = async ({
    onSuccess,
    userGroupIds,
    plainTextTitle,
    markdownContent,
    files,
    isLikesEnabled,
    isCommentsEnabled,
    isReadConfirmationsEnabled,
    isPinned,
    formIds,
    infoPageIds,
    visibleAt,
    expiredAt,
  }: ICreateNews) => {
    try {
      if (userGroupIds.length === 0) {
        throw new Error('news to be created requires at least one user group id');
      }
      setLoading(true);
      await createNewsMutation.mutateAsync({
        input: {
          userGroupId: userGroupIds[0],
          userGroupIds: userGroupIds,
          title: plainTextTitle,
          content: markdownContent,
          tags: undefined,
          attachments: files.length > 0 ? await handleNewFiles({ files }) : [],
          settings: {
            likesEnabled: isLikesEnabled,
            commentsEnabled: isCommentsEnabled,
            readConfirmationsEnabled: isReadConfirmationsEnabled,
          },
          pinnedAt: isPinned ? new Date() : undefined,
          userTypes: [UserType.Employee],
          formIds,
          infoPageIds,
          expiredAt,
          visibleAt,
          isTemplate: false,
          templateName: undefined,
        },
      });
      await queryClient.invalidateQueries<NewsToReadByUserGroupQuery>(
        useInfiniteNewsToReadByUserGroupQuery.getKey({
          pageInput: { pageSize: 8 },
          userGroupIdsOfNews: userGroupIds,
        }),
      );
      onSuccess();
      return true;
    } catch (error) {
      const err = error as Error;
      Log.error(err, { message: `an error occurred while creating news: ${err.message}` });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateNews = async ({
    onSuccess,
    id,
    userGroupIds,
    plainTextTitle,
    markdownContent,
    attachments,
    files,
    isLikesEnabled,
    isCommentsEnabled,
    isReadConfirmationsEnabled,
    isPinned,
    formIds,
    infoPageIds,
    visibleAt,
    expiredAt,
  }: IUpdateNews) => {
    try {
      if (userGroupIds.length === 0) {
        throw new Error('news to be updated requires at least one user group id');
      }
      setLoading(true);
      const newAttachments =
        files.length > 0 ? await handleNewFiles({ files, existingAttachments: attachments }) : [];
      const keptAttachments =
        attachments
          ?.filter(attachment =>
            files
              .map(file => file.optionalParams?.otcPath)
              .includes(attachment.otcPath ?? undefined),
          )
          .map(
            attachment =>
              ({
                fileName: attachment.fileName,
                id: attachment.id,
                otcPath: attachment.otcPath,
                s3Path: attachment.s3Path,
                type: attachment.type,
              }) as NewsAttachmentInputs,
          ) ?? [];
      const attachmentsToSave = [...keptAttachments, ...newAttachments];
      const newInput: UpdateNewsMutationVariables['input'] = {
        id,
        title: plainTextTitle,
        content: markdownContent,
        attachments: attachmentsToSave,
        settings: {
          likesEnabled: isLikesEnabled,
          commentsEnabled: isCommentsEnabled,
          readConfirmationsEnabled: isReadConfirmationsEnabled,
        },
        pinnedAt: isPinned ? new Date() : null,
        formIds,
        infoPageIds,
        expiredAt,
        visibleAt,
        userGroupIds,
        isTemplate: false,
        templateName: undefined,
        templateId: undefined,
      };
      await updateNewsMutation.mutateAsync({
        input: newInput,
      });
      const keyOfQueryToUpdate = useInfiniteNewsToReadByUserGroupQuery.getKey({
        pageInput: { pageSize: 8 },
        userGroupIdsOfNews: userGroupIdFiltersForNews ?? [],
      });
      queryClient.setQueryData(
        keyOfQueryToUpdate,
        (data?: InfiniteData<NewsToReadByUserGroupQuery> | undefined) => {
          if (!data) {
            throw new Error('old data could not be retrieved');
          }
          const news = data.pages.flatMap(page => page.newsListByUserGroup.news);
          const updatedNewsWithOldData = news.find(newsToFind => newsToFind.id === id);
          const updatedNews = {
            ...updatedNewsWithOldData,
            ...newInput,
          } as News;
          return updateNewsInNewsToRead({ oldData: data, updatedNews });
        },
      );
      onSuccess();
      return true;
    } catch (error) {
      const err = error as Error;
      Log.error(err, { message: `an error occurred while updating news: ${err.message}` });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const downloadAttachedFiles = async (otcPaths: string[]) => {
    const linkRequests: Promise<string>[] = [];
    const fileExtensions: string[] = [];
    const downloadRequests: Promise<FileSystemDownloadResult>[] = [];

    otcPaths.forEach(otcPath => {
      const fileExtension = otcPath.split('.').pop();
      if (!fileExtension) {
        throw new Error('could not figure out the file extension');
      }
      fileExtensions.push(fileExtension);
      linkRequests.push(
        download.mutateAsync({
          s3Path: otcPath,
          uploadType: UploadType.NewsPost,
        }),
      );
    });
    setDownloadingAttachments(true);

    try {
      const downloadLinks = await Promise.all(linkRequests);
      downloadLinks.forEach((link, index) =>
        downloadRequests.push(
          downloadAsync(link, `${cacheDirectory}${shortHash(link)}.${fileExtensions[index]}`),
        ),
      );
      const finishedDownloads = await Promise.all(downloadRequests);
      if (finishedDownloads.length !== downloadRequests.length) {
        throw new Error('finished downloads count does not match the download requests count');
      }
      if (finishedDownloads.length > 0) {
        onAttachmentsDownloaded(
          finishedDownloads.map((finishedDownload, finishedDownloadIndex) => ({
            ...finishedDownload,
            otcPath: otcPaths[finishedDownloadIndex],
          })),
        );
      }
    } catch (error) {
      const err = error as Error;
      Log.error(err, { message: `an error occurred while downloading images: ${err.message}` });
      throw new Error(err.message);
    } finally {
      setDownloadingAttachments(false);
    }
  };

  return {
    createNews,
    updateNews,
    isLoading,
    isDownloadingAttachments,
  };
};
