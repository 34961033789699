import React, { useCallback, useMemo } from 'react';
import { AppBar } from '../../../components/AppBar/AppBar';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { Appbar } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import {
  DefaultSectionT,
  Platform,
  ScrollView,
  SectionList,
  SectionListRenderItem,
  StyleSheet,
  View,
} from 'react-native';
import { ChatDay } from '../components/ChatDay';
import {
  ChatRole,
  ChatRoomMessageStatus,
  useChatMessageReadReceiptsQuery,
} from '../../../../graphql/operations';
import { ChatMessageFactory } from '../components/ChatMessageFactory';
import { ChatSystemMessage } from '../components/ChatSystemMessage';
import { ChatMessageReader, ExtendedMessage } from '../types';
import { useStore } from '../../../stores/store';
import { groupBy } from '../../../utils/groupBy';
import { OptionLabel } from '../../../components/Picker/components/OptionLabel';
import { constructTruncatedFullName } from '../../../utils';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { getInitials } from '../../profiles/utils';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { MessageReceivedTick } from '../components/MessageReceivedTick';
import { translateChatRoomMessageStatus } from '../utils/translateChatRoomMessageStatus';
import { determineChatDay } from '../utils/determineChatDay';
import { readReceiptsPlaceholderData } from '../utils/readReceiptsPlaceholderData';
import { WithLoadingIndicator } from '../../../components/WithLoadingIndicator/WithLoadingIndicator';
import { OptionLabelShimmer } from '../../../components/Picker/components/OptionLabelShimmer';
import { SectionHeader } from '../../../components/List/SectionHeader';
import { useMediaCaching } from '../../../hooks/useMediaCaching/useMediaCaching';

type ChatMessageReadReceiptsScreenProps = StackScreenProps<
  AppNavigatorParamList,
  'ChatMessageReadReceipts'
>;

export const ChatMessageReadReceiptsScreen = ({
  navigation,
  route,
}: ChatMessageReadReceiptsScreenProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const appUser = useStore(s => s.userProfile);
  const chatMessageWithDay = {
    day: determineChatDay(route.params.message.createdAt),
    data: [route.params.message],
  };
  const chatMessage = route.params.message;
  const chatMessageReadReceiptsQuery = useChatMessageReadReceiptsQuery(
    { chatMessageId: chatMessage._id.toString() },
    {
      placeholderData: readReceiptsPlaceholderData,
    },
  );

  const { canDownload } = useMediaCaching();
  const isDownloadConfirmationNeeded = useMemo(() => !canDownload, [canDownload]);

  const chatRoomMembersWithStatus = useMemo(() => {
    if (chatMessageReadReceiptsQuery.data) {
      const readReceipts = chatMessageReadReceiptsQuery.data?.chatMessageReadReceipts;
      const readReceiptsByStatus = groupBy(readReceipts, readReceipt => readReceipt.status);
      return Object.entries(readReceiptsByStatus)
        .map(([key, value]) => ({
          status: key,
          data: value.flatMap(item => item.user),
        }))
        .sort((first, second) => {
          void second;
          if (first.status === ChatRoomMessageStatus.Sent) {
            return 1;
          }
          return -1;
        });
    }
  }, [chatMessageReadReceiptsQuery.data]);

  const renderChatMessage: SectionListRenderItem<ExtendedMessage, DefaultSectionT> = useCallback(
    ({ item: message }) => {
      if (message.system) {
        return <ChatSystemMessage message={message} />;
      }
      return (
        <ChatMessageFactory
          key={message._id}
          message={message}
          onPress={() => {}}
          onLongPress={() => {}}
          isSelectable={false}
          isSelected={false}
          isAvatarOrNameOfOtherUserShown={true}
          isFromAppUser={message.user._id === appUser?.userId}
          isNextMessageFromSameUser={false}
          isPreviousMessageFromSameUser={false}
          iconNextToName={message.user.role === ChatRole.Moderator ? 'shield-half-full' : undefined}
          isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
        />
      );
    },
    [chatMessage, appUser?.userId, isDownloadConfirmationNeeded],
  );

  const renderChatRoomMember: SectionListRenderItem<ChatMessageReader, DefaultSectionT> =
    useCallback(
      ({ item: { titleBefore, titleAfter, firstname, lastname, pictureOTC, userId } }) => {
        const isLoading = userId.startsWith('loading');
        return (
          <WithLoadingIndicator
            on={!isLoading}
            render={() => {
              const label = constructTruncatedFullName({
                titleBefore,
                firstname,
                lastname,
                titleAfter,
              });
              return (
                <OptionLabel
                  key={userId}
                  label={label}
                  renderAvatar={() => (
                    <AvatarDisplay
                      size={32}
                      avatar={{
                        otcPath: pictureOTC ?? undefined,
                        initials: getInitials({
                          firstname,
                          lastname,
                        }),
                      }}
                      type={UploadType.ProfilePicture}
                    />
                  )}
                />
              );
            }}
            renderWhileLoading={() => <OptionLabelShimmer />}
          />
        );
      },
      [],
    );

  return (
    <View style={styles.container}>
      <AppBar
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => navigation.goBack()} color={theme.customColors.icon} />
        )}
        renderContent={() => <Appbar.Content title="Nachricht" titleStyle={styles.contentTitle} />}
      />
      <ScrollView style={styles.chatRoomMemberListContainer}>
        <View style={styles.messageListContainer}>
          <SectionList
            scrollEnabled={false}
            contentContainerStyle={styles.messageListContentContainer}
            keyboardShouldPersistTaps="handled"
            inverted
            disableVirtualization={Platform.OS === 'web'}
            sections={[chatMessageWithDay]}
            style={styles.messageList}
            renderItem={renderChatMessage}
            renderSectionFooter={({ section: { day } }) => <ChatDay day={day} />}
            keyExtractor={item => item._id.toString()}
          />
        </View>
        <SectionList
          scrollEnabled={false}
          contentContainerStyle={styles.chatRoomMemberListContent}
          keyboardShouldPersistTaps="handled"
          disableVirtualization={Platform.OS === 'web'}
          sections={chatRoomMembersWithStatus ?? []}
          style={styles.chatRoomMemberList}
          renderItem={renderChatRoomMember}
          renderSectionHeader={({ section: { status } }) => (
            <SectionHeader
              text={translateChatRoomMessageStatus(status)}
              renderToLeftOfText={() => (
                <MessageReceivedTick
                  isPendingMessage={false}
                  isReceivedByAllChatRoomMembers={status === ChatRoomMessageStatus.Read.toString()}
                />
              )}
            />
          )}
          keyExtractor={item => item.userId}
          ItemSeparatorComponent={() => <View style={styles.itemSeperator} />}
          SectionSeparatorComponent={() => <View style={styles.sectionSeperator} />}
        />
      </ScrollView>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: { flex: 1, backgroundColor: theme.customColors.background3 },
    messageListContainer: {
      flex: 1,
      borderWidth: 0.5,
      borderColor: theme.customColors.borders,
      width: '90%',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: 12,
    },
    messageList: { flex: 1, backgroundColor: theme.colors.background },
    messageListContentContainer: { paddingTop: 12 },
    chatRoomMemberList: {
      backgroundColor: theme.customColors.background3,
    },
    chatRoomMemberListContainer: { width: '100%', height: '100%', flex: 1 },
    chatRoomMemberListContent: {
      padding: 2,
      width: '90%',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'flex-start',
      marginTop: 12,
      backgroundColor: theme.customColors.background3,
    },
    contentTitle: { fontSize: 16, fontWeight: 'bold', textAlign: 'center' },
    itemSeperator: { height: 5 },
    sectionSeperator: { height: 10 },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 4,
      borderRadius: 25,
      backgroundColor: theme.customColors.lightRipple,
      paddingHorizontal: 10,
    },
  });
