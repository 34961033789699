import { useCallback, useEffect } from 'react';
import { useSetReadChatRoomMutation } from '../../../../graphql/operations';
import { useQueryClient } from '@tanstack/react-query';
import { Log } from '../../../utils/Log';

export type IUseSetChatRoomAsRead = {
  chatRoomId: string;
  lastChatRoomMessageId?: string;
};

export const useSetChatRoomAsRead = ({
  chatRoomId,
  lastChatRoomMessageId,
}: IUseSetChatRoomAsRead) => {
  const queryClient = useQueryClient();
  const setReadMutation = useSetReadChatRoomMutation();

  const setChatRoomAsRead = useCallback(async () => {
    try {
      await setReadMutation.mutateAsync({ chatRoomId, date: new Date() });
      await queryClient.invalidateQueries(['unreadMessageCount']);
    } catch (e) {
      const err = e as Error;
      Log.error(`an error occurred while setting chat room read by user: ${err.message}`);
    }
  }, [chatRoomId]);

  useEffect(() => {
    void setChatRoomAsRead();
  }, [lastChatRoomMessageId]);

  return { setChatRoomAsRead };
};
