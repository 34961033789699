import { QueryClient } from '@tanstack/react-query';
import { ChatRoomsQuery, useInfiniteChatRoomsQuery } from '../../../../graphql/operations';

interface IInvalidateChatRoomsQuery {
  queryClient: QueryClient;
}

export const invalidateChatRoomsQuery = async ({ queryClient }: IInvalidateChatRoomsQuery) => {
  await queryClient.invalidateQueries<ChatRoomsQuery>(
    useInfiniteChatRoomsQuery.getKey({
      pageInput: {
        pageSize: 20,
      },
    }),
    {
      refetchPage: () => true,
    },
  );
};
