import { TenantConfigurationQuery } from '../../graphql/operations';
import { Log } from './Log';

export const fetchTenant = async (
  tenantName: string,
): Promise<TenantConfigurationQuery['tenantConfiguration']> => {
  try {
    const tenantRes = await fetch(
      process.env.EXPO_PUBLIC_API_URL + `getTenant?name=${tenantName}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (tenantRes.status !== 200) {
      Log.error('tenant not found');
      throw new Error('Tenant not found');
    }
    const json = await tenantRes.json();
    if (!json?.tenantName) {
      Log.error('invalid tenant json');
      throw new Error('Invalid tenant json');
    }
    return json;
  } catch (err) {
    const error = err as Error;
    Log.error(err);
    throw new Error(error.message);
  }
};
