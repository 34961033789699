import { useMemo, useState } from 'react';
import { UserProfileAsOption } from '../../../components/Picker/FullScreenModalPicker';
import { constructTruncatedFullName } from '../../../utils';
import { Selection, useSelections } from '../../../hooks/useSelections/useSelections';
import { useInfiniteCompactContactListQuery } from '../../../../graphql/operations';
import { AnimationDirection } from '../../../utils/animation/types';
import { Forwardee } from '../../../components/Picker/types';

interface IUseContactPicker {
  debouncedSearchContactValue?: string;
  forwardeeSelections: Selection<Forwardee>[];
  syncContactsToForwardees: (
    contactsToAppend: UserProfileAsOption[],
    contactsToRemove: UserProfileAsOption[],
  ) => void;
  userIdsOfContactsToExclude?: string[];
}

export const useContactPicker = ({
  debouncedSearchContactValue,
  forwardeeSelections,
  syncContactsToForwardees,
  userIdsOfContactsToExclude,
}: IUseContactPicker) => {
  const {
    isSelecting: isSelectingContacts,
    setSelecting: setSelectingContacts,
    toggleSelecting: toggleSelectingContacts,
    append: appendToContactSelections,
    remove: removeFromContactSelections,
    clear: clearContactSelections,
    selections: contactSelections,
    setSelections: setContactSelections,
  } = useSelections<UserProfileAsOption>();
  const {
    append: appendToContactsBuffer,
    remove: removeFromContactsBuffer,
    clear: clearContactsBuffer,
    selections: contactsBuffer,
    setSelections: setContactsBuffer,
  } = useSelections<UserProfileAsOption>();
  const [chatRoomPickerExitDirection, setChatRoomPickerExitDirection] =
    useState<AnimationDirection>(AnimationDirection.RIGHT);

  const contactsQuery = useInfiniteCompactContactListQuery(
    'pageInput',
    { pageInput: { pageSize: 30 }, searchText: debouncedSearchContactValue },
    {
      enabled: isSelectingContacts,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.compactContactList.contactList.length,
          0,
        );
        if (alreadyFetched === lastPage.compactContactList.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 30,
            token: lastPage.compactContactList?.token,
          },
        };
      },
    },
  );

  const contactsData: UserProfileAsOption[] =
    contactsQuery.data?.pages.flatMap(page => page.compactContactList.contactList) ?? [];

  const contactOptions = useMemo(
    () =>
      contactsData
        ?.filter(contact =>
          userIdsOfContactsToExclude ? !userIdsOfContactsToExclude.includes(contact.userId) : true,
        )
        .map(contact => {
          return {
            id: contact.userId,
            value: contact,
            label: constructTruncatedFullName({
              titleBefore: contact.titleBefore,
              firstname: contact.firstname,
              lastname: contact.lastname,
              titleAfter: contact.titleBefore,
            }),
          };
        }) ?? [],
    [contactsData, userIdsOfContactsToExclude],
  );

  const isContactsLoading = contactsQuery.isLoading;

  const syncContactsBufferToSelections = () => {
    const newContacts = contactsBuffer.map(contact => contact.value);
    const previousContacts = contactSelections.map(contact => contact.value);
    const appendedContacts = newContacts.filter(contact => !previousContacts.includes(contact));
    const removedContacts = previousContacts.filter(contact => !newContacts.includes(contact));
    setContactSelections(contactsBuffer);
    syncContactsToForwardees(appendedContacts, removedContacts);
  };

  const setContactsBufferToSelections = () => {
    setContactsBuffer(contactSelections);
  };

  const onConfirmContactSelections = () => {
    syncContactsBufferToSelections();
    toggleSelectingContacts();
  };

  const onCancelContactSelections = () => {
    setContactsBufferToSelections();
    toggleSelectingContacts();
  };

  const onContactsBufferChange = (selection: UserProfileAsOption) => {
    if (contactsBuffer.map(item => item.id).includes(selection.userId)) {
      return removeFromContactsBuffer(selection.userId);
    }
    return appendToContactsBuffer({ id: selection.userId, value: selection });
  };

  return {
    isSelectingContacts,
    contactOptions,
    forwardeeSelections,
    contactSelections,
    chatRoomPickerExitDirection,
    isContactsLoading,
    contactsBuffer,
    setSelectingContacts,
    clearContactsBuffer,
    appendToContactsBuffer,
    removeFromContactsBuffer,
    toggleSelectingContacts,
    appendToContactSelections,
    removeFromContactSelections,
    setChatRoomPickerExitDirection,
    clearContactSelections,
    syncContactsBufferToSelections,
    onConfirmContactSelections,
    syncContactsToForwardees,
    setContactsBufferToSelections,
    onCancelContactSelections,
    onContactsBufferChange,
  };
};
