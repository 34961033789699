import uuid from 'react-native-uuid';
import { Comment, UserProfile } from '../../../../graphql/operations';
import { appendReplyToCommentReplies } from './appendReplyToCommentReplies';

export const addFleetingReplyToComment = (
  commentId: string,
  sender: UserProfile,
  content: string,
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>,
  comments: Comment[],
) => {
  const reply: Comment = {
    author: {
      userId: sender.userId,
      userType: sender.userType,
      firstname: sender.firstname,
      lastname: sender.lastname,
      picture: sender.picture,
      pictureOTC: sender.pictureOTC,
    },
    id: `fleeting-${uuid.v4()}`,
    content: content,
    createdAt: new Date(),
    updatedAt: new Date(),
    replies: undefined,
  };

  const commentsWithReply = appendReplyToCommentReplies(comments, commentId, reply);

  setComments(commentsWithReply);
};
