import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { useMemo } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Slider } from '@miblanchard/react-native-slider';
import SliderIOS from '@react-native-community/slider';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { useAudioPlayer } from './hooks/useAudioPlayer';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { ConfirmDialog } from '../../Dialog/ConfirmDialog';

export type AudioPlayerProps = {
  uri?: string;
  type: 'newsPost' | 'chatMessage';
  recordedAudioDurationMs?: number;
  isDownloadConfirmationNeeded?: boolean;
};

export const AudioPlayer = ({
  uri,
  type,
  recordedAudioDurationMs,
  isDownloadConfirmationNeeded,
}: AudioPlayerProps) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const getResponsiveContainerWidth = (nestedIn: 'newsPost' | 'chatMessage') => {
    const widthAdjuster = nestedIn === 'newsPost' ? 1 : 5 / 8;
    return getResponsiveContentWidth() * widthAdjuster;
  };
  const containerWidth = getResponsiveContainerWidth(type);
  const displayWaveform = type === 'newsPost';

  const {
    hasStarted,
    durationMillisecond,
    currentTime,
    setSliderWidth,
    currentSliderPosition,
    isSeeking,
    seekTime,
    isAudioDownloaded,
    handlePlay,
    onSliderChange,
    onSliderComplete,
    controlButtonIcon,
    controlButtonText,
    confirmModalVisible,
    setConfirmModalVisible,
    checkCanDownload,
  } = useAudioPlayer({
    recordedAudioDurationMs,
    uri,
    type,
    isDownloadConfirmationNeeded,
  });

  const styles = useMemo(
    () => createStyles(currentSliderPosition, isSeeking, containerWidth, isAudioDownloaded, theme),
    [currentSliderPosition, isSeeking, containerWidth, isAudioDownloaded],
  );

  return (
    <View
      style={styles.container}
      onLayout={event => setSliderWidth(event.nativeEvent.layout.width * 0.9)}
      testID="player"
    >
      {displayWaveform && (
        <View style={styles.waveformContainer}>
          <Icon name="waveform" size={140} color="#9da7af" />
        </View>
      )}
      <View style={styles.seekBox}>
        <Text>{seekTime}</Text>
      </View>
      <View style={styles.playerContainer}>
        {Platform.OS === 'ios' ? (
          <SliderIOS
            step={1}
            value={currentTime?.milliseconds}
            minimumValue={0}
            maximumValue={durationMillisecond?.milliseconds}
            minimumTrackTintColor={theme.customColors.primary}
            thumbImage={require('../../../../assets/Slider_Knob_12px.png')}
            disabled={!hasStarted}
            onValueChange={onSliderChange}
            onSlidingComplete={onSliderComplete}
          />
        ) : (
          <Slider
            step={1}
            value={currentTime?.milliseconds}
            minimumValue={0}
            maximumValue={durationMillisecond?.milliseconds}
            minimumTrackTintColor={theme.customColors.primary}
            thumbStyle={{ width: 13, height: 13 }}
            thumbTintColor={theme.customColors.gray40}
            disabled={!hasStarted}
            onValueChange={event => {
              if (Array.isArray(event)) {
                return onSliderChange(event[0]);
              }
              return onSliderChange(event);
            }}
            onSlidingComplete={event => {
              if (Array.isArray(event)) {
                return onSliderComplete(event[0]);
              }
              return onSliderComplete(event);
            }}
          />
        )}
        <View style={styles.controlButtonContainer}>
          <TouchableOpacity
            onPress={checkCanDownload}
            testID={isAudioDownloaded ? 'playButton' : ''}
            disabled={!isAudioDownloaded}
          >
            <Icon
              name={controlButtonIcon as keyof typeof MaterialCommunityIcons.glyphMap}
              size={35}
              color={theme.customColors.primary}
              style={styles.playPauseIcon}
            />
          </TouchableOpacity>
          <Text style={styles.controlButtonText}>{controlButtonText}</Text>
        </View>
      </View>
      <ConfirmDialog
        isOpen={confirmModalVisible}
        title="Datei abspielen?"
        content="Audiodateien können sehr groß sein. Sie nutzen momentan mobile Daten. Möchten Sie die
        Audiodatei trotzdem abspielen?"
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={() => {
          setConfirmModalVisible(false);
          void handlePlay();
        }}
      />
    </View>
  );
};

const createStyles = (
  currentSliderPosition: number,
  isSeeking: boolean,
  containerWidth: number,
  isAudioDownloaded: boolean,
  theme: AppTheme,
) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingVertical: 5,
      width: containerWidth,
      height: '100%',
      flexDirection: 'column',
    },
    playerContainer: {
      flex: 1,
      paddingHorizontal: 16,
    },
    controlButtonContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    controlButtonText: { color: theme.colors.onSurface },
    seekBox: {
      position: 'absolute',
      left: currentSliderPosition,
      opacity: isSeeking ? 1 : 0,
    },
    waveformContainer: {
      flex: 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    playPauseIcon: {
      marginRight: 10,
      marginLeft: -10,
      opacity: isAudioDownloaded ? 1 : 0.3,
    },
  });
