export enum UploadType {
  InfoPage = 'infoPage',
  ChatMessage = 'chatMessage',
  InquiryMessage = 'inquiryMessage',
  NewsPost = 'newsPost',
  ProfilePicture = 'profilePicture',
  GroupChatImage = 'groupChatImage',
  TeamImage = 'teamImage',
  FormReply = 'formReply',
  Static = 'static',
}

export type Uri = string;

export type FileToUploadProps = {
  uri: Uri;
  name: string;
  mimeType: string;
};
