import { FlatList, RefreshControl } from 'react-native';

import { FormListItem } from '../components/FormListItem';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import React from 'react';
import { themeProp } from '../../../types/main';
import { useInfiniteListFormsToReadQuery } from '../../../../graphql/operations';
import { useNetInfo } from '@react-native-community/netinfo';
import { Appbar, useTheme } from 'react-native-paper';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { AppBar } from '../../../components/AppBar/AppBar';
import { FormsHomeScreenProps } from '../types';

export const FormsHomeScreen = ({ navigation }: FormsHomeScreenProps) => {
  const theme = useTheme() as themeProp;
  const netInfo = useNetInfo();
  const forms = useInfiniteListFormsToReadQuery(
    'pageInput',
    { pageInput: { pageSize: 20 } },
    {
      enabled: netInfo.isConnected ?? false,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.formList.forms.length,
          0,
        );
        if (alreadyFetched === lastPage.formList.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 20,
            token: lastPage.formList?.token,
          },
        };
      },
    },
  );

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => forms.refetch(),
    errorMessageToLog: `failed to refetch data for the forms home screen`,
  });

  if (forms.isLoading || !forms.data) {
    return (
      <NoDataRefresh
        icon="plus-circle-outline"
        text="Formulare werden geladen..."
        isLoading={forms.isLoading}
      />
    );
  }

  return (
    <>
      <AppBar
        title="Formulare"
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => navigation.goBack()} color={theme.customColors.icon} />
        )}
      />
      <FlatList
        refreshControl={
          /* @todo must be awaited */
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={() => void onRefresh()}
            tintColor={theme.customColors.refreshControlSpinner}
          />
        }
        data={forms.data?.pages.flatMap(formPage => formPage.formList.forms)}
        renderItem={({ item }) => <FormListItem item={item!} />}
        ListEmptyComponent={
          <>
            {!!forms.data && (
              <NoDataRefresh
                text={
                  isRefreshing || forms.isLoading
                    ? ''
                    : 'Es konnten leider keine Formulare gefunden werden'
                }
                /* @todo must be awaited */
                onPress={() => void onRefresh()}
                isLoading={isRefreshing || forms.isLoading}
              />
            )}
          </>
        }
        style={{ backgroundColor: theme.colors.background }}
        keyExtractor={form => form.id || (Math.random() * 1000).toFixed(0)}
        /* @todo must be awaited */
        onEndReached={() => void forms.fetchNextPage()}
      />
    </>
  );
};
