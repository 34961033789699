import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { useAppTheme } from '../../styles/theme';

export const useBreakpoint = (): {
  width: number;
  breakpoint: ReactNativePaper.Breakpoint;
} => {
  const theme = useAppTheme();
  const { width } = useWindowDimensions();

  const breakpoint = useMemo(() => {
    const nearestBreakpoint = Object.entries(theme.breakpoints.values)
      .reverse()
      .find(([, value]) => value < width);
    if (nearestBreakpoint) {
      return nearestBreakpoint[0] as ReactNativePaper.Breakpoint;
    }
    return 'xs';
  }, [width, theme]);

  return {
    width,
    breakpoint,
  };
};
