const versionNumber = 166;
const versionCode = '2.8.10';
const packageId = 'com.likwidcare.luci';
const termsOfServiceVersion = '2.1.0';
const iosStoreUrl = 'https://apps.apple.com/de/app/luci/id1557046065';
const androidStoreUrl = 'https://play.google.com/store/apps/details?id=com.likwidcare.luci';
const scheme = 'luci';

/**
 * expo config for all tenants that do not use a white label app
 * @type ExpoConfig
 */
const config = {
  expo: {
    name: 'Luci',
    description: 'Die Kommunikations-App für die Pflege',
    slug: 'luci',
    scheme,
    version: versionCode,
    notification: {
      icon: './assets/expo/push_icon.png',
      iosDisplayInForeground: true,
      androidMode: 'collapse',
      androidCollapsedTitle: '#{unread_notifications} ungelesene Nachrichten',
    },
    android: {
      adaptiveIcon: {
        backgroundImage: './assets/expo/background.png',
        foregroundImage: './assets/expo/foreground.png',
      },
      versionCode: versionNumber,
      package: packageId,
      permissions: [
        'CAMERA',
        'CAMERA_ROLL',
        'WRITE_EXTERNAL_STORAGE',
        'READ_EXTERNAL_STORAGE',
        'RECORD_AUDIO',
        'USE_BIOMETRIC',
        'USE_FINGERPRINT',
      ],
      googleServicesFile: './google-services.json',
    },
    ios: {
      buildNumber: versionNumber.toString(),
      bundleIdentifier: packageId,
      infoPlist: {
        NSCameraUsageDescription: 'Auf die Kamera zugreifen, um ein Profilbild aufzunehmen.',
        NSPhotoLibraryUsageDescription: 'Auf die Kamera zugreifen, um ein Profilbild hochzuladen.',
        NSMicrophoneUsageDescription:
          'Auf das Mikrofon zugreifen, um Sprachnachrichten aufzuzeichnen.',
        NSFaceIDUsageDescription:
          'Der User kann Face ID oder Touch ID verwenden, um sich in der App anzumelden.',
        UIBackgroundModes: ['remote-notification'],
        LSApplicationQueriesSchemes: ['tel', 'telprompt'],
      },
      config: {
        usesNonExemptEncryption: false,
      },
      supportsTablet: true,
      usesIcloudStorage: true,
    },
    hooks: {
      postPublish: [
        {
          file: 'sentry-expo/upload-sourcemaps',
          config: {
            organization: 'luci-gmbh',
            project: 'luci',
            release: versionCode,
            dist: versionNumber.toString(),
          },
        },
      ],
    },
    extra: {
      appName: 'luci',
      eas: {
        projectId: '19707b80-0f3b-4f48-850c-1c24706931f7',
      },
      sentryDsn: 'https://55086fa49f5b4aaa9e951b1313441cd8@o1050914.ingest.sentry.io/6033682',
    },
    splash: {
      image: './assets/expo/splash-preview.png',
      resizeMode: 'cover',
    },
    icon: './assets/expo/icon.png',
  },
};

module.exports = {
  config,
  versionNumber,
  versionCode,
  packageId,
  termsOfServiceVersion,
  iosStoreUrl,
  androidStoreUrl,
  scheme,
};
