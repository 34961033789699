import React, { useMemo } from 'react';
import {
  TouchableWithoutFeedback,
  View,
  ViewStyle,
  Keyboard,
  StyleSheet,
  Platform,
} from 'react-native';
import { Headline, Paragraph } from 'react-native-paper';
import { SafeAreaView } from '../../components/HigherOrder';
import { KeyboardAvoidingView } from '../../components/HigherOrder';
import { authStyles as styles } from '../../styles/authStyles';
import { AppTheme, useAppTheme } from '../../styles/theme';

export const BaseAuthScreen = (props: {
  children: React.ReactNode;
  headline: string;
  description: string;
  justify?: boolean;
  paddingHorizonal?: number;
  renderTop?: () => React.ReactNode;
  containerStyle?: ViewStyle;
  keyboardAvoiding?: boolean;
  innerViewStyles?: ViewStyle;
  keyboardAvoidingViewStyles?: ViewStyle;
  keyboardAvoidingContentViewStyles?: ViewStyle;
}) => {
  const theme = useAppTheme();
  const baseAuthScreenStyles = useMemo(
    () => createStyles(theme, props.containerStyle, props.justify, props.paddingHorizonal),
    [theme, props.containerStyle, props.justify, props.paddingHorizonal],
  );

  const renderContent = () => (
    <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}>
      <View
        style={[
          {
            width: '100%',
            alignItems: props.justify ? 'center' : 'flex-start',
          },
          props.innerViewStyles,
        ]}
      >
        {props.renderTop && props.renderTop()}
        <Headline
          style={[styles(theme).headline, { textAlign: props.justify ? 'center' : 'left' }]}
        >
          {props.headline}
        </Headline>
        <Paragraph
          style={[
            styles(theme).description,
            {
              textAlign: props.justify ? 'center' : 'left',
              maxWidth: theme.content.maxWidth,
              alignSelf: 'center',
            },
          ]}
        >
          {props.description}
        </Paragraph>
        {props.children}
      </View>
    </TouchableWithoutFeedback>
  );
  return (
    <SafeAreaView style={baseAuthScreenStyles.BaseAuthContainer}>
      {props.keyboardAvoiding ? (
        <KeyboardAvoidingView
          style={[{ alignItems: 'center' }, props.keyboardAvoidingViewStyles]}
          behavior="position"
          contentContainerStyle={props.keyboardAvoidingContentViewStyles}
        >
          {renderContent()}
        </KeyboardAvoidingView>
      ) : (
        renderContent()
      )}
    </SafeAreaView>
  );
};

const createStyles = (
  theme: AppTheme,
  containerStyle?: ViewStyle,
  justify?: boolean,
  paddingHorizonal?: number,
) =>
  StyleSheet.create({
    BaseAuthContainer: {
      alignItems: justify ? 'center' : 'flex-start',
      paddingHorizontal: paddingHorizonal || 20,
      height: '100%',
      backgroundColor: theme.colors.background,
      paddingTop: Platform.OS === 'web' ? 20 : 0,
      ...containerStyle,
    },
  });
