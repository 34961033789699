import { DrawerContentComponentProps } from '@react-navigation/drawer';
import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Appbar } from 'react-native-paper';
import { ChatHomeScreenContent } from '../../features/chat/components/ChatHomeScreenContent';
import { useStore } from '../../stores/store';
import { themeProp } from '../../types/main';
import { DrawerBar } from '../bar/DrawerBar';
import { maxContentAreaWidth } from '../navigators/ContentNavigator';
import { useResponsiveDrawer } from './hooks/useResponsiveDrawer';
import { useAppTheme } from '../../styles/theme';

export const LeftDrawerContent = (props: DrawerContentComponentProps) => {
  const theme = useAppTheme();
  const { isLeftDrawerOpen } = useResponsiveDrawer({
    ...props,
    revealedAt: 'md',
    drawerPosition: 'LeftDrawer',
  });
  const { navigation } = props;
  const styles = useMemo(() => createStyles(theme, isLeftDrawerOpen), [theme, isLeftDrawerOpen]);
  const { userProfile } = useStore(s => ({
    userProfile: s.userProfile,
  }));

  if (!isLeftDrawerOpen) {
    return <></>;
  }

  return (
    <View style={styles.topContainer}>
      <View
        style={{
          flex: 1,
          maxWidth: maxContentAreaWidth / 2,
          minWidth: isLeftDrawerOpen ? maxContentAreaWidth / 2 : undefined,
        }}
      >
        <DrawerBar {...props} displayTenantLogo headerStyle={styles.drawerHeader}>
          <Appbar.Action
            icon="home-outline"
            size={26}
            testID="drawerbar-news"
            color={theme.customColors.gray40}
            onPress={() => navigation.navigate('News')}
          />
          <Appbar.Action
            icon="menu"
            size={26}
            testID="drawerbar-menu"
            color={theme.customColors.gray40}
            onPress={() => navigation.navigate('Menu')}
          />
          <Appbar.Action
            icon="card-account-details-outline"
            size={26}
            testID="drawerbar-contacts"
            color={theme.customColors.gray40}
            onPress={() => navigation.navigate('Contacts')}
          />
          {userProfile?.isUserMemberOfATeam && (
            <Appbar.Action
              icon="list-status"
              size={26}
              testID="drawerbar-inquiries"
              color={theme.customColors.gray40}
              onPress={() => navigation.navigate('Inquiries')}
            />
          )}
        </DrawerBar>
        <View style={styles.bottomContainer}>
          <ChatHomeScreenContent isRenderedInDrawer />
        </View>
      </View>
    </View>
  );
};

const createStyles = (theme: themeProp, isLeftDrawerOpen: boolean) =>
  StyleSheet.create({
    topContainer: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
      backgroundColor: theme.colors.background,
    },
    bottomContainer: {
      marginHorizontal: 6,
      flex: 64,
      backgroundColor: theme.colors.background,
    },
    topContainerItems: {
      flex: 1,
      maxWidth: maxContentAreaWidth / 2,
      minWidth: isLeftDrawerOpen ? maxContentAreaWidth / 2 : undefined,
    },
    drawerHeader: {
      minHeight: 110,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: theme.colors.background,
      borderBottomColor: theme.customColors.borders,
      borderBottomWidth: 1,
    },
  });
