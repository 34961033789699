import {
  ShiftData,
  ShiftListViewCategory,
  EnrichedShiftData,
  ShiftDetailsWithIcon,
  ScheduleDataResponse,
} from './types';
import { Log } from '../../../utils/Log';
import moment, { Moment } from 'moment';
import CryptoJS from 'crypto-js';

export const transformScheduleDataVivendi = (
  scheduleData: ShiftData[],
  scheduleStartDate: moment.Moment,
  shiftDetailsResponse: Map<number, ShiftDetailsWithIcon>,
): ShiftListViewCategory[] => {
  const enrichedScheduleDataMap = new Map<string, EnrichedShiftData[]>();

  scheduleData.forEach(shift => {
    const shiftDetails = shiftDetailsResponse.get(shift.DienstId);
    let shiftStartTime = '';
    let shiftEndTime = '';
    if (shift.Zeiten.length > 0) {
      shiftStartTime = shift.Zeiten[0].AbZeit;
      shiftEndTime = shift.Zeiten[shift.Zeiten.length - 1].BisZeit;
    }
    const newScheduleEntry: EnrichedShiftData = {
      date: moment(shift.Datum).format('dd L'),
      from: shiftStartTime === '' ? shiftStartTime : moment(shiftStartTime).format('HH:mm'),
      to: shiftEndTime === '' ? shiftEndTime : moment(shiftEndTime).format('HH:mm'),
      shiftId: shift.DienstId,
      isToday: moment(shift.Datum).isSame(new Date(), 'day'),
      shiftDescription: 'Unbekannter Dienst',
      shiftAbbreviation: 'X',
    };
    if (shiftDetails) {
      newScheduleEntry.shiftDescription = shiftDetails.Bezeichnung;
      newScheduleEntry.shiftAbbreviation = shiftDetails.Kuerzel;
      newScheduleEntry.icon = shiftDetails.IconDetails;
    }
    const alreadyExistingShifts = enrichedScheduleDataMap.get(newScheduleEntry.date);
    if (alreadyExistingShifts) {
      enrichedScheduleDataMap.set(newScheduleEntry.date, [
        ...alreadyExistingShifts,
        newScheduleEntry,
      ]);
      return;
    }
    enrichedScheduleDataMap.set(newScheduleEntry.date, [newScheduleEntry]);
  });

  const daysInMonth = getDaysInMonthUTC(scheduleStartDate.month(), scheduleStartDate.year());

  const newShiftArray: ShiftListViewCategory[] = daysInMonth.map(day => {
    const newTitle = moment(day).format('dd L');
    const shift = enrichedScheduleDataMap.get(newTitle);
    if (shift) {
      return {
        title: newTitle,
        data: shift,
      };
    } else {
      return {
        title: newTitle,
        data: [
          {
            date: newTitle,
            shiftAbbreviation: 'X',
            shiftDescription: 'Kein Dienst',
            from: '',
            to: '',
            isToday: moment(day).isSame(new Date(), 'day'),
          },
        ],
      };
    }
  });

  return newShiftArray;
};

export const getDaysInMonthUTC = (month: number, year: number): Date[] => {
  const date = new Date(Date.UTC(year, month, 1));
  const days = [];
  while (date.getUTCMonth() === month) {
    days.push(new Date(date));
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return days;
};

export const hashPassword = (password: string) => {
  const prehash = CryptoJS.enc.Utf8.parse(
    'HABh2b3czM4jhBXN3rfrMmWMXJVCMnLQTPYFmmdanKEFUgd6RzzvBXDWfyqgBVvq',
  );
  const key = CryptoJS.enc.Utf8.parse(password);
  const hash = CryptoJS.HmacSHA512(key, prehash);
  const signature = hash.toString(CryptoJS.enc.Base64);
  return signature;
};

export const checkScheduleResponseVivendi = (
  response: ScheduleDataResponse | undefined,
  scheduleStartDate: Moment,
  currentDate: Moment,
): boolean => {
  if (
    response &&
    response.Diensteintraege &&
    (scheduleStartDate.month() === currentDate.month() ||
      (checkIfScheduleIsForNextMonth(scheduleStartDate, currentDate) && currentDate.date() > 15))
  ) {
    return true;
  } else if (
    checkIfScheduleIsForNextMonth(scheduleStartDate, currentDate) &&
    currentDate.date() <= 15
  ) {
    throw new Error('Der Dienstplan wurde noch nicht freigegeben');
  } else if (response && response.status && response.status !== 200) {
    if (response.detail) {
      throw new Error(response.detail);
    } else if (response.title) {
      throw new Error(response.title);
    } else {
      Log.error(response);
      throw new Error('Beim Abfragen des Dienstplans ist ein unbekannter Fehler aufgetreten');
    }
  } else {
    Log.error(response);
    throw new Error('Beim Abfragen des Dienstplans ist ein unbekannter Fehler aufgetreten');
  }
};

const checkIfScheduleIsForNextMonth = (scheduleStartDate: Moment, currentDate: Moment) => {
  if (scheduleStartDate.month() === 0 && currentDate.month() === 11) {
    return true;
  }
  if (scheduleStartDate.month() - 1 === currentDate.month()) {
    return true;
  }
  return false;
};
