import React from 'react';
import { Text } from 'react-native-paper';
import { GenericPopUp } from '../../stories/Modal/GenericPopUp';

export interface PopupProps {
  isOpen: boolean;
  title: string;
  text: string;
  onDismiss(): void;
}

/**
 *
 * component optimized for use with usePopup hook
 *
 * const errorPopup = usePopup(error title, error text)
 *
 * <Popup {...errorPopup.popup} />
 *
 *
 */

export const Popup = ({ isOpen, title, text, onDismiss }: PopupProps) => {
  return (
    <GenericPopUp headlineTitle={title} visible={isOpen} onDismiss={onDismiss}>
      <Text>{text}</Text>
    </GenericPopUp>
  );
};
