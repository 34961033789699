import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { AvatarDisplayShimmer } from '../../Common/AvatarDisplay/AvatarDisplayShimmer';
import { useAppTheme } from '../../../styles/theme';
import { Shimmer } from '../../Shimmer/Shimmer';

type OptionLabelShimmerPropTypes = {
  avatarSize?: number;
};

export const OptionLabelShimmer = ({ avatarSize = 32 }: OptionLabelShimmerPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  return (
    <View style={styles.container}>
      <AvatarDisplayShimmer size={avatarSize} />
      <View style={styles.labelContainer}>
        <Shimmer height={12} width={120} radius={10} color={theme.customColors.gray80} />
      </View>
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 8,
    },
    labelContainer: { marginLeft: 8 },
  });
