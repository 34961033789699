import { useEffect, useRef, useState } from 'react';
import { AVPlaybackStatus, Audio } from 'expo-av';
import { useDownloadFile } from '../../FileUpload/queries';
import { Platform } from 'react-native';
import { AudioPlayerProps } from '../AudioPlayer';
import { getFormattedDuration } from '../utils/getFormattedDuration';
import { alert } from '../../../../utils/alert';
import { Log } from '../../../../utils/Log';

interface CurrentTime {
  milliseconds: number;
  formatted: string;
}

interface DurationMillisecond {
  milliseconds: number;
  sliderConstant: number;
}

type UseAudioPlayerProps = AudioPlayerProps;

export const useAudioPlayer = ({
  recordedAudioDurationMs,
  uri,
  type,
  isDownloadConfirmationNeeded,
}: UseAudioPlayerProps) => {
  const sound = useRef(new Audio.Sound());
  const [source, setSource] = useState<string>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [durationMillisecond, setDurationMillisecond] = useState<DurationMillisecond>();
  const [duration, setDuration] = useState('');
  const [currentTime, setCurrentTime] = useState<CurrentTime>();
  const [sliderWidth, setSliderWidth] = useState(0);
  const [currentSliderPosition, setCurrentSliderPosition] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [seekTime, setSeekTime] = useState('00:00');
  const downloadMedia = useDownloadFile();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const endProbablyReached = Boolean(
    Math.abs((currentTime?.milliseconds ?? 0) - (recordedAudioDurationMs ?? Infinity)) < 100,
  );
  const endReached = durationMillisecond?.milliseconds === currentTime?.milliseconds;
  const isAudioDownloaded = Boolean(source);

  const isSourceLocal =
    uri?.includes('file://') || (uri?.startsWith('blob') && Platform.OS === 'web');

  const fetchSource = async (audioUri: string) => {
    downloadMedia.mutate(
      {
        s3Path: audioUri,
        uploadType: type,
      },
      {
        onError: err => {
          Log.error(err);
        },
        onSuccess: result => {
          setSource(result);
        },
      },
    );
  };

  if (!durationMillisecond) {
    sound.current
      .getStatusAsync()
      .then(status => {
        if (status.isLoaded && status.durationMillis && !recordedAudioDurationMs) {
          setDurationMillisecond({
            milliseconds: status.durationMillis,
            sliderConstant: status.durationMillis / sliderWidth,
          });
        }
      })
      .catch(e =>
        Log.warning(e, { message: 'an error occurred while getting the status of the recording' }),
      );
  }

  useEffect(() => {
    if (recordedAudioDurationMs) {
      setDurationMillisecond({
        milliseconds: recordedAudioDurationMs,
        sliderConstant: recordedAudioDurationMs / sliderWidth,
      } as DurationMillisecond);
    }
  }, [recordedAudioDurationMs]);

  useEffect(() => {
    if (!uri) {
      return;
    }
    if (isSourceLocal) {
      setSource(uri);
    } else {
      void fetchSource(uri);
    }
  }, [isSourceLocal, uri]);

  useEffect(() => {
    if (recordedAudioDurationMs) {
      setDuration(getFormattedDuration(recordedAudioDurationMs));
      return;
    }
    if (durationMillisecond) {
      setDuration(getFormattedDuration(durationMillisecond.milliseconds));
    }
  }, [durationMillisecond?.milliseconds, recordedAudioDurationMs]);

  useEffect(() => {
    if (endReached || endProbablyReached) {
      setIsPlaying(false);
    }
  }, [endReached, endProbablyReached]);

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded && status.durationMillis) {
      setCurrentTime({
        milliseconds: status.positionMillis,
        formatted: getFormattedDuration(status.positionMillis),
      });
    }
  };

  const handleReplay = async () => {
    setCurrentTime({
      milliseconds: 0,
      formatted: getFormattedDuration(0),
    });
    await sound.current.replayAsync();
    const status: AVPlaybackStatus = await sound.current.getStatusAsync();
    if (status.isLoaded && status.durationMillis && !recordedAudioDurationMs) {
      setDurationMillisecond({
        milliseconds: status.durationMillis,
        sliderConstant: status.durationMillis / sliderWidth,
      });
    }
    sound.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
    await sound.current.setStatusAsync({ progressUpdateIntervalMillis: 200 });
  };

  const handlePlay = async () => {
    if (!source) {
      alert(
        'Die Datei konnte nicht geladen werden',
        'Bitte laden Sie den Chat neu und versuchen Sie es erneut abzuspielen.',
      );
      Log.error('audio source is missing');
      return;
    }
    if (!isPlaying && !hasStarted) {
      const result = await sound.current.loadAsync({ uri: source }, {}, false);
      if (result.isLoaded === false) {
        Log.error('Error in Loading Audio');
      } else {
        await sound.current.playAsync();
        setIsPlaying(true);
        setHasStarted(true);
        const status: AVPlaybackStatus = await sound.current.getStatusAsync();
        if (status.isLoaded && status.durationMillis && !recordedAudioDurationMs) {
          setDurationMillisecond({
            milliseconds: status.durationMillis,
            sliderConstant: status.durationMillis / sliderWidth,
          });
        }
        sound.current.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
        await sound.current.setStatusAsync({ progressUpdateIntervalMillis: 200 });
      }
    } else if (isPlaying && hasStarted) {
      await sound.current.pauseAsync();
      setIsPlaying(false);
    } else if (!isPlaying && hasStarted) {
      if (endReached) {
        await handleReplay();
      }
      await sound.current.playAsync();
      setIsPlaying(true);
    }
  };

  const onSliderChange = (event: number) => {
    void sound.current.pauseAsync();
    setIsPlaying(false);
    setIsSeeking(true);
    if (durationMillisecond) {
      setCurrentSliderPosition(Math.round(event / durationMillisecond.sliderConstant));
    }
    setSeekTime(getFormattedDuration(event));
  };

  const onSliderComplete = (event: number) => {
    void sound.current.playFromPositionAsync(event);
    setIsPlaying(true);
    setIsSeeking(false);
  };

  const determineControlButtonIcon = () => {
    if (!isAudioDownloaded) {
      return 'download';
    }
    if (isPlaying) {
      return 'pause';
    }
    return 'play';
  };

  const determineControlButtonText = () => {
    if (!isAudioDownloaded) {
      return 'Herunterladen und abspielen';
    }
    if (currentTime && duration) {
      return `${currentTime?.formatted}/${duration}`;
    }
    return 'Abspielen';
  };

  const controlButtonIcon = determineControlButtonIcon();
  const controlButtonText = determineControlButtonText();

  const checkCanDownload = () => {
    if (isDownloadConfirmationNeeded && !source) {
      return setConfirmModalVisible(true);
    }
    void handlePlay();
  };

  return {
    hasStarted,
    durationMillisecond,
    currentTime,
    setSliderWidth,
    currentSliderPosition,
    isSeeking,
    seekTime,
    isAudioDownloaded,
    handlePlay,
    onSliderChange,
    onSliderComplete,
    controlButtonIcon,
    controlButtonText,
    confirmModalVisible,
    setConfirmModalVisible,
    checkCanDownload,
  };
};
