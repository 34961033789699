import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useAppTheme } from '../../styles/theme';

export const PaginationItem: React.FC<{
  index: number;
  active: boolean;
  onPress(): void;
}> = ({ active, onPress }) => {
  const width = 12;
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(width), [width]);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={[
          styles.item,
          { backgroundColor: active ? theme.customColors.pureWhite : theme.customColors.gray20 },
        ]}
      />
    </TouchableWithoutFeedback>
  );
};

const createStyles = (width: number) =>
  StyleSheet.create({
    item: {
      width,
      borderRadius: 50,
      height: width,
    },
  });
