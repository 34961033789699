import { ExtendedMessage } from '../types';

export const getUnreadMessages = (messages: ExtendedMessage[]) => {
  const unreadMessages = messages.filter(message => message.readByCurrentUser === false);
  const countOfUnreadMessages = unreadMessages.length;
  let firstUnreadMessage = undefined;
  if (countOfUnreadMessages > 0) {
    firstUnreadMessage = unreadMessages[unreadMessages.length - 1];
  }
  return { firstUnreadMessage, countOfUnreadMessages };
};
