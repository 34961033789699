import { NewsAttachment } from '../../../../../graphql/operations';

export const determineSourceUriOfImage = (attachment: NewsAttachment) => {
  if (attachment.metadata?.data.urls.large2x) {
    return attachment.metadata?.data.urls.large2x;
  }
  if (attachment.otcPath) {
    return attachment.otcPath;
  }
  return '';
};
