import {
  CreateChatRoomMessageMutation,
  useCreateChatRoomMessageMutation,
} from '../../../../graphql/operations';
import { AttachmentPickerResultItem } from '../../../components/Common/Media/types';
import { attachmentPickerToMutation } from './attachmentPickerToMutation';

interface ISendPickedAttachments {
  chatRoomId: string;
  attachmentPickerResultItems: AttachmentPickerResultItem[];
  createChatRoomMessageMutation: ReturnType<typeof useCreateChatRoomMessageMutation>;
  onError: (e: unknown) => void;
}

export const sendPickedAttachments = async ({
  attachmentPickerResultItems,
  chatRoomId,
  createChatRoomMessageMutation,
  onError,
}: ISendPickedAttachments) => {
  try {
    const createdMessages: CreateChatRoomMessageMutation[] = [];
    for (const attachment of attachmentPickerResultItems) {
      const createdMessage = await attachmentPickerToMutation({
        ...attachment,
        chatRoomId,
        createChatRoomMessageMutation,
      });
      createdMessages.push(createdMessage);
    }
    return createdMessages;
  } catch (e) {
    const error = e as Error;
    onError(e);
    throw new Error(error.message);
  }
};
