import React, { useMemo } from 'react';
import { Shimmer } from '../../../components/Shimmer/Shimmer';
import { useAppTheme } from '../../../styles/theme';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';

type ChatBubbleShimmerPropTypes = {
  isFromAppUser?: boolean;
  shouldHaveLeftMargin?: boolean;
  shouldRenderAvatar?: boolean;
  shouldRenderName?: boolean;
  height?: number;
  width?: number;
};

export const ChatBubbleShimmer = ({
  height = 48,
  width = 128,
  isFromAppUser,
  shouldHaveLeftMargin,
  shouldRenderAvatar,
  shouldRenderName,
}: ChatBubbleShimmerPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(
    () =>
      createStyles({
        isFromAppUser,
        shouldHaveLeftMargin,
        shouldRenderAvatar,
        shouldRenderName,
        height,
        width,
      }),
    [
      height,
      width,
      isFromAppUser,
      shouldHaveLeftMargin,
      shouldRenderAvatar,
      shouldRenderName,
      theme,
    ],
  );

  const backgroundColor = theme.customColors.chatBubbleBackground;
  const contentShimmerColor = theme.customColors.textGray;
  const usernameShimmerColor = theme.customColors.accent;
  const avatarShimmerColor = theme.customColors.avatar;

  return (
    <View style={styles.container}>
      <Shimmer
        radius={12}
        color={backgroundColor}
        height={height + (shouldRenderName ? 12 : 0)}
        width={width}
      />
      <View style={styles.text}>
        <Shimmer radius={12} color={contentShimmerColor} height={12} width={(width * 4) / 5} />
      </View>
      {shouldRenderAvatar && shouldHaveLeftMargin && (
        <View style={styles.avatar}>
          <Shimmer radius={100} color={avatarShimmerColor} height={32} width={32} />
        </View>
      )}
      {!!shouldRenderName && !isFromAppUser && (
        <View style={styles.name}>
          <Shimmer radius={2} color={usernameShimmerColor} height={8} width={48} />
        </View>
      )}
    </View>
  );
};

const createStyles = ({ shouldHaveLeftMargin, isFromAppUser, width }: ChatBubbleShimmerPropTypes) =>
  StyleSheet.create({
    container: {
      marginRight: isFromAppUser ? 10 : 0,
      marginLeft: shouldHaveLeftMargin ? 46 : 10,
      justifyContent: isFromAppUser ? 'flex-end' : 'flex-start',
      alignSelf: isFromAppUser ? 'flex-end' : 'flex-start',
      zIndex: 0,
      marginVertical: 2,
    },
    text: {
      position: 'absolute',
      right: isFromAppUser ? 10 : undefined,
      left: !isFromAppUser ? 10 : undefined,
      bottom: 20,
      justifyContent: isFromAppUser ? 'flex-end' : 'flex-start',
      alignSelf: isFromAppUser ? 'flex-end' : 'flex-start',
      zIndex: 0,
    },
    name: {
      position: 'absolute',
      right: isFromAppUser ? (width ?? 0) - 14 : undefined,
      left: !isFromAppUser ? 10 : undefined,
      top: 12,
      zIndex: 2,
    },
    avatar: {
      position: 'absolute',
      right: isFromAppUser ? undefined : (width ?? 0) - 14,
      left: !isFromAppUser ? -36 : undefined,
      bottom: 2,
      zIndex: 2,
    },
  });
