import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import { DeleteCommentMutation, Exact } from '../../../../graphql/operations';
import { invalidateNewsCache, invalidateCommentsCache } from '../utils/cache';

type InitHandleRemoveCommentHookPropTypes = {
  deleteCommentMutation: UseMutationResult<
    DeleteCommentMutation,
    unknown,
    Exact<{
      commentId: string;
    }>,
    unknown
  >;
  queryClient: QueryClient;
};

type HandleRemoveCommentPropTypes = {
  commentId: string;
  newsId: string;
  userGroupIds: string | string[];
  isReply: boolean;
};

export const initUseHandleRemoveComment =
  ({ deleteCommentMutation, queryClient }: InitHandleRemoveCommentHookPropTypes) =>
  ({ commentId, newsId, userGroupIds, isReply }: HandleRemoveCommentPropTypes) => {
    const isNewsLegacy = !(Array.isArray(userGroupIds) && userGroupIds.length !== 0);

    const invalidateCache = async () => {
      await invalidateNewsCache({
        newsId: newsId,
        groupIdsInNewsTimelineQuery: isNewsLegacy
          ? [userGroupIds as string]
          : (userGroupIds as string[]),
        queryClient,
      });
      await invalidateCommentsCache({
        newsId: newsId,
        queryClient,
        commentId: isReply ? undefined : commentId,
      });
    };

    deleteCommentMutation.mutate(
      {
        commentId: commentId,
      },
      {
        onSuccess: async () => await invalidateCache(),
      },
    );
  };
