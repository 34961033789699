import React, { memo, useMemo } from 'react';
import { FullScreenModal, FullScreenModalProps } from '../FullScreenModal';
import { Portal, Text } from 'react-native-paper';
import { LoadingBlocker } from '../Common/Loading/LoadingBlocker';
import { StyleSheet, RefreshControl, TouchableOpacity } from 'react-native';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { NoDataRefresh } from '../Common/Loading/NoDataRefresh';
import Animated, { FadeInLeft, FadeOut } from 'react-native-reanimated';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { CompactChatRoom, UserProfile } from '../../../graphql/operations';
import { Forwardee } from './types';

export type ChatRoomAsOption = CompactChatRoom;
export type UserProfileAsOption = Pick<
  UserProfile,
  'userId' | 'titleBefore' | 'firstname' | 'lastname' | 'titleAfter' | 'pictureOTC'
>;

type OptionValue = string | UserProfileAsOption | ChatRoomAsOption | Forwardee;

export type FullScreenModalPickerOption<T extends OptionValue> = {
  id: string;
  label: string;
  sublabel?: string;
  value: T;
};

export type FullScreenModalPickerPropTypes<T extends OptionValue> = Omit<
  FullScreenModalProps,
  'children' | 'show' | 'onSubmit' | 'isSubmitDisabled'
> & {
  isVisible: boolean;
  description?: string;
  onClose: () => void;
  isLoading?: boolean;
  noOptionsText?: string;
  isFooterShownOnEmptySearach?: boolean;
  onPressRefreshOptions?: () => void;
  isOptionsLoading?: boolean;
  options?: FullScreenModalPickerOption<T>[];
  selectionsBuffer?: FullScreenModalPickerOption<T>['id'][];
  onSelectionBufferChange?: (selection: FullScreenModalPickerOption<T>['value']) => void;
  isConfirmSelectionDisabled?: boolean;
  onPressConfirmSelection?: () => void;
  renderOptionLabel: (option: FullScreenModalPickerOption<T>) => JSX.Element;
  renderActionsRightOfSearch?: () => JSX.Element;
  renderFooterContent?: () => JSX.Element;
  renderListHeader?: () => JSX.Element;
  renderListFooter?: () => JSX.Element;
};

const FullScreenModalPickerComponent = <T extends OptionValue>({
  isVisible,
  title,
  description,
  onClose,
  isLoading,
  noOptionsText = 'Es konnten leider keine Optionen gefunden werden.',
  isFooterShownOnEmptySearach = false,
  onPressRefreshOptions,
  isOptionsLoading,
  options,
  onSelectionBufferChange,
  selectionsBuffer,
  isConfirmSelectionDisabled,
  onPressConfirmSelection,
  submitIcon = 'content-save-check-outline',
  closeIcon = 'close',
  renderOptionLabel,
  animationDirections,
  onPressSearch,
  renderSearchBar,
  showSearch,
  renderActionsRightOfSearch,
  renderFooterContent,
  renderListHeader,
  renderListFooter,
}: FullScreenModalPickerPropTypes<T>) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <FullScreenModal
      show={isVisible}
      title={title}
      onClose={onClose}
      isSubmitDisabled={isConfirmSelectionDisabled}
      onSubmit={() => onPressConfirmSelection?.()}
      submitIcon={submitIcon}
      closeIcon={closeIcon}
      animationDirections={animationDirections}
      onPressSearch={onPressSearch}
      renderSearchBar={renderSearchBar}
      showSearch={showSearch}
      renderActionsRightOfSearch={renderActionsRightOfSearch}
    >
      <FullScreenModal.Container>
        {description && (
          <FullScreenModal.Header>
            <Text variant="bodySmall" numberOfLines={3} style={styles.description}>
              {description}
            </Text>
          </FullScreenModal.Header>
        )}
        <FullScreenModal.Content>
          <Animated.ScrollView
            style={styles.optionListContainer}
            contentContainerStyle={styles.optionListContentContainer}
            keyboardShouldPersistTaps="handled"
            refreshControl={
              <RefreshControl
                refreshing={!!isLoading}
                onRefresh={() => onPressRefreshOptions?.()}
                tintColor={theme.customColors.refreshControlSpinner}
              />
            }
          >
            {!options || options.length === 0 ? (
              <>
                <NoDataRefresh
                  text={noOptionsText}
                  onPress={onPressRefreshOptions}
                  isLoading={isOptionsLoading}
                  icon="checkbox-blank-off-outline"
                />
                {isFooterShownOnEmptySearach && renderListFooter?.()}
              </>
            ) : (
              <>
                {renderListHeader?.()}
                {options.map(option => {
                  const isInBuffer = selectionsBuffer?.includes(option.id) ?? false;
                  return (
                    <Animated.View key={option.id} entering={FadeInLeft} exiting={FadeOut}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        onPress={() => onSelectionBufferChange?.(option.value)}
                        style={styles.optionListItem}
                      >
                        {renderOptionLabel(option)}
                        <BouncyCheckbox
                          disableText
                          disableBuiltInState
                          isChecked={isInBuffer}
                          onPress={() => onSelectionBufferChange?.(option.value)}
                          size={32}
                          fillColor={theme.customColors.primary}
                          unfillColor={theme.customColors.chatBubbleBackground}
                          iconStyle={styles.icon}
                          innerIconStyle={styles.innerIcon}
                        />
                      </TouchableOpacity>
                    </Animated.View>
                  );
                })}
              </>
            )}
            {renderListFooter?.()}
          </Animated.ScrollView>
        </FullScreenModal.Content>
        {renderFooterContent && (
          <FullScreenModal.Footer>{renderFooterContent()}</FullScreenModal.Footer>
        )}
      </FullScreenModal.Container>
      <Portal>
        <LoadingBlocker visible={!!isLoading} />
      </Portal>
    </FullScreenModal>
  );
};

export const FullScreenModalPicker = memo(
  FullScreenModalPickerComponent,
) as typeof FullScreenModalPickerComponent;

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    scrollViewContainer: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: theme.customColors.borders,
      backgroundColor: theme.customColors.borders,
      flex: 1,
    },
    optionListContainer: {
      height: '100%',
      width: '100%',
    },
    optionListContentContainer: {
      paddingBottom: '50%',
    },
    optionListItem: {
      padding: 8,
      marginBottom: 1,
      borderRadius: 4,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    optionListItemLabel: {
      color: theme.customColors.text,
      fontWeight: 'normal',
    },
    confirmButton: { borderRadius: 5 },
    searchBar: { shadowOpacity: 0 },
    description: { paddingHorizontal: 10 },
    icon: { borderColor: theme.customColors.primary, marginRight: 8 },
    innerIcon: { borderWidth: 2 },
  });
