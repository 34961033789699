import { useActionSheet } from '@expo/react-native-action-sheet';
import { ExtendedMessage, OptionType } from '../types';
import { UserProfile } from '../../../types/main';

type Callbacks = {
  onCopyText(message: ExtendedMessage): void;
  onDelete(message: ExtendedMessage): void;
  onReportAbuse(message: ExtendedMessage): void;
  onQuote(message: ExtendedMessage): void;
  onModerativeDelete(message: ExtendedMessage): void;
  onForward(message: ExtendedMessage): void;
  onSeeReadReceipts(message: ExtendedMessage): void;
};

interface IShowActionSheet {
  message: ExtendedMessage;
  isModerator?: boolean;
  isForwardable?: boolean;
}

export const useChatActionSheet = (
  userProfile: UserProfile,
  {
    onCopyText,
    onReportAbuse,
    onQuote,
    onDelete,
    onModerativeDelete,
    onForward,
    onSeeReadReceipts,
  }: Callbacks,
) => {
  const showActionSheet = ({ message, isModerator, isForwardable }: IShowActionSheet) => {
    const options: Array<string> = [];
    const optionTypes: Array<OptionType> = [];

    if (!message?.image && !message?.audio && !message?.documentUri) {
      options.push('Text kopieren');
      optionTypes.push(OptionType.copyTexts);
    }

    const hasDeleteOption = message.user._id === userProfile?.userId && !message.deletedAt;

    const hasModerativeDeleteOption = !hasDeleteOption && !!isModerator && !message.deletedAt;

    options.push('Zitieren');
    optionTypes.push(OptionType.quote);

    if (isForwardable) {
      options.push('Weiterleiten');
      optionTypes.push(OptionType.forward);
    }
    options.push('Information');
    optionTypes.push(OptionType.seeReadReceipts);
    if (hasDeleteOption) {
      options.push('Löschen');
      optionTypes.push(OptionType.delete);
    }
    if (hasModerativeDeleteOption) {
      options.push('Fremde Nachricht löschen');
      optionTypes.push(OptionType.moderativeDelete);
    }
    options.push('Melden');
    optionTypes.push(OptionType.reportAbuse);
    options.push('Abbrechen');
    optionTypes.push(OptionType.cancel);

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        autoFocus: true,
      },
      (buttonIndex: any) => {
        switch (optionTypes[buttonIndex]) {
          case OptionType.copyTexts:
            return onCopyText(message);
          case OptionType.delete:
            return onDelete(message);
          case OptionType.moderativeDelete:
            return onModerativeDelete(message);
          case OptionType.reportAbuse:
            return onReportAbuse(message);
          case OptionType.quote:
            return onQuote(message);
          case OptionType.forward:
            return onForward(message);
          case OptionType.seeReadReceipts:
            return onSeeReadReceipts(message);
        }
      },
    );
  };
  const { showActionSheetWithOptions } = useActionSheet();

  return { showActionSheet };
};
