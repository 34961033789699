import { useEffect, useState } from 'react';
import { Feature } from '../../../../graphql/operations';
import { translateFeature } from '../../../utils/translateFeature';

export const useSelectLinksModal = () => {
  const [isVisible, setVisible] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<Feature | undefined>(undefined);
  const [modalTitle, setModalTitle] = useState<string>();

  useEffect(() => {
    if (selectedFeature) {
      setModalTitle(translateFeature({ feature: selectedFeature, plural: true }));
    }
  }, [selectedFeature]);

  return { isVisible, modalTitle, selectedFeature, setSelectedFeature, setVisible };
};
