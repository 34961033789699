import React, { useMemo, useState } from 'react';
import { TouchableOpacity, StyleSheet, Linking } from 'react-native';
import {
  Feature,
  InfoPage,
  InfoPageAttachmentType,
  useGetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery,
} from '../../../../graphql/operations';
import { useNavigation } from '@react-navigation/native';
import { ChatRoomScreenProps } from '../screens/types';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { LinkButton } from '../../../components/LinkButton/LinkButton';
import { useUnavailableFeatureModalStore } from '../../../hooks';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { Log } from '../../../utils/Log';

type ChatInfoPagesMessageListItemPropTypes = {
  infoPage: Pick<InfoPage, 'title' | 'id'>;
  onLongPress?: () => void;
};

export const ChatInfoPagesMessageListItem = ({
  infoPage,
  onLongPress,
}: ChatInfoPagesMessageListItemPropTypes) => {
  const theme = useAppTheme();
  const [isLoading, setIsLoading] = useState(false);
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<ChatRoomScreenProps['navigation']>();
  const {
    setUnavailableFeatureModalAsNoPermissionToViewInfoPage,
    setUnavailableFeatureModalAsUnknownError,
  } = useUnavailableFeatureModalStore();

  const onButtonPress = async () => {
    setIsLoading(true);
    try {
      const response = await useGetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery.fetcher({
        infoPageId: infoPage.id,
      })();
      const data = response.getInfoPageIfUserHasPermissionToReadInfoPageOrThrow;
      if (data.attachment?.type === InfoPageAttachmentType.Document) {
        return navigation.navigate('OtcPdfReader', {
          downloadable: data.downloadable,
          source: data.attachment.s3Path!,
          fileName: data.attachment.fileName!,
          type: UploadType.InfoPage,
        });
      }
      if (data.attachment?.type === InfoPageAttachmentType.ExternalUrl) {
        return await Linking.openURL(data.attachment!.externalUrl!);
      }
      throw new Error(`Unknown attachment type ${data.attachment?.type}`);
    } catch (error) {
      const e = error as Error;
      if (e.message.includes('Not allowed')) {
        return setUnavailableFeatureModalAsNoPermissionToViewInfoPage();
      }
      Log.error(error);
      setUnavailableFeatureModalAsUnknownError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TouchableOpacity
      key={infoPage.id}
      onPress={onButtonPress}
      onLongPress={onLongPress}
      style={styles.buttonContainer}
      disabled={isLoading}
    >
      <LinkButton
        key={infoPage.id}
        icon="file-document"
        text={infoPage.title}
        buttonStyle={styles.link}
        feature={Feature.Infopage}
        id={infoPage.id}
        navigation={navigation}
      />
    </TouchableOpacity>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    buttonContainer: { marginBottom: 1, paddingTop: 6, paddingBottom: 4 },
    link: {
      marginVertical: 1,
      marginHorizontal: 15,
      borderColor: theme.customColors.itemBorder,
      borderWidth: 1,
      borderRadius: 5,
    },
  });
