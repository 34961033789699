/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useContext, useState } from 'react';
import { ISupportItem } from '../../types/main';
import { retrieveTenant } from '../../utils/tenancyUtils';

const useProvideSupport = () => {
  const [supportElements, setSupportElements] = useState<Array<ISupportItem>>();

  const aureliusTempStaticSupport: Array<ISupportItem> = [
    {
      title: 'Chat',
      description: 'Haben Sie Fragen zum Chat oder den Chatgruppen?',
      content: 'Dann kontaktieren Sie bitte',
      icon: 'message-outline',
      dedicatedSupportPersons: [
        {
          userId: 'ecb7698f-4590-4009-9276-b7d15ea2ef9a',
          user: {
            name: 'Inga Saroukh',
          },
        },
        {
          userId: 'b7d0eede-3cfb-4454-99dc-e18e9b92fe0b',
          user: {
            name: 'Katarina Predehl',
          },
        },
      ],
    },
    {
      title: 'News',
      description: 'Benötigen Sie Hilfe rund um das Thema „News“?',
      content: 'Dann kontaktieren Sie bitte',
      icon: 'newspaper-variant-outline',
      dedicatedSupportPersons: [
        {
          userId: 'c1ffc56d-f6db-43ac-911e-e0df9f1ce8f9',
          user: {
            name: 'Tina Leube',
          },
        },
        {
          userId: 'e0861217-e4a2-4a4d-a598-dc3b746762bc',
          user: {
            name: 'Stefanie Herden',
          },
        },
      ],
    },
    {
      title: 'Gemeinschaft',
      description: 'Haben Sie eine Verletzung der Fairplay-Regeln bemerkt?',
      content: 'Dann kontaktieren Sie bitte',
      icon: 'account-group-outline',
      dedicatedSupportPersons: [
        {
          userId: '6953794a-db72-4bdf-aed3-d1bd071330f4',
          user: {
            name: 'Anne Haas',
          },
        },
      ],
    },
    {
      title: 'Anmeldung',
      description: 'Haben Sie Probleme bei der Anmeldung?',
      content: 'Dann kontaktieren Sie bitte',
      icon: 'account-lock-outline',
      dedicatedSupportPersons: [
        {
          userId: 'ecb7698f-4590-4009-9276-b7d15ea2ef9a',
          user: {
            name: 'Inga Saroukh',
          },
        },
        {
          userId: 'b7d0eede-3cfb-4454-99dc-e18e9b92fe0b',
          user: {
            name: 'Katarina Predehl',
          },
        },
      ],
    },
  ];

  const fetchSupport = async () => {
    const tenant = await retrieveTenant();
    if (tenant?.tenantName === 'aurelius-hof') {
      setSupportElements(aureliusTempStaticSupport);
    } else {
      setSupportElements([]);
    }
  };

  return { supportElements, fetchSupport };
};

const SupportContext = createContext({});

export const SupportProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const value = useProvideSupport();

  return <SupportContext.Provider value={value}>{props.children}</SupportContext.Provider>;
};

export const useSupport = () => useContext(SupportContext) as ReturnType<typeof useProvideSupport>;
