import { useCallback, useEffect, useState } from 'react';
import { useSocketStore } from '../../../stores/SocketStore';
import { useStore } from '../../../stores/store';
import { ChatEvents, ChatRoomReader } from '@luciapp/socket-contract';

interface IUseChatRoomReadSubscription {
  chatRoomId: string;
}

export const useChatRoomReadSubscription = ({ chatRoomId }: IUseChatRoomReadSubscription) => {
  const chatSocket = useSocketStore(s => s.chatSocket);
  const appUser = useStore(s => s.userProfile);
  const [chatRoomLastReadDate, setChatRoomLastReadDate] = useState<Date>();

  const resetChatRoomLastReadDate = useCallback(() => {
    setChatRoomLastReadDate(undefined);
  }, []);

  useEffect(() => {
    resetChatRoomLastReadDate();
    return () => resetChatRoomLastReadDate();
  }, [chatRoomId]);

  const onObserveChatRoomRead = useCallback(
    (reader: ChatRoomReader) => {
      if (reader.chatRoomMemberId === appUser?.userId || reader.chatRoomId !== chatRoomId) {
        return;
      }
      setChatRoomLastReadDate(new Date());
    },
    [appUser?.userId, chatRoomId],
  );

  useEffect(() => {
    if (chatSocket?.connected) {
      chatSocket?.on(ChatEvents.chatRoomRead, onObserveChatRoomRead);
    }
    return () => {
      chatSocket?.off(ChatEvents.chatRoomRead, onObserveChatRoomRead);
    };
  }, [chatSocket?.connected, chatRoomId]);

  return { chatRoomLastReadDate };
};
