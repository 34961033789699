import { GetUserGroupsWhereUserIsPartOfQuery } from '../../../../graphql/operations';

export const areGroupIdsSame = (
  group1: GetUserGroupsWhereUserIsPartOfQuery['userGroupsWhereUserIsPartOf'] | undefined = [],
  group2: GetUserGroupsWhereUserIsPartOfQuery['userGroupsWhereUserIsPartOf'] | undefined = [],
) => {
  return group1.every(
    group1Item => group2.findIndex(group2Item => group2Item.id === group1Item.id) !== -1,
  );
};
