import React from 'react';
import { Text, Dimensions, View, Linking } from 'react-native';
import { Appbar, IconButton, withTheme } from 'react-native-paper';
import { versionCode, versionNumber } from '../../../app.config';
import { useTenantContext } from '../../hooks/tenant';
import { themeProp } from '../../types/main';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { useNavigation } from '@react-navigation/native';
import { AppBar } from '../../components/AppBar/AppBar';

const VersionScreen = (props: { theme: themeProp }) => {
  const { tenant } = useTenantContext();
  const { theme } = props;
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Version'>['navigation']>();
  const listItems = [
    { name: 'Version-Code', value: versionCode },
    { name: 'Versions-Nummer', value: versionNumber },
    { name: 'Firmen-Name', value: tenant?.tenantName },
    { name: 'Firmen-Minimal Version', value: tenant?.minAppVersion },
    { name: 'Firmen-Kennung', value: tenant?.tenantName },
    {
      name: 'Technischer Kontakt',
      value: 'support@luciapp.de',
      mail: true,
    },
  ];

  const renderItem = (
    idx: number,
    name: string,
    value?: string | number | null,
    mail?: boolean,
  ) => {
    if (!value) {
      return null;
    }
    return (
      <View
        key={idx}
        style={{
          height: 80,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: '5%',
          paddingVertical: 4,
          borderColor: theme.customColors.borders,
          borderTopWidth: 2,
        }}
      >
        <View style={{ justifyContent: 'center' }}>
          <Text style={{ fontSize: 14, color: theme.customColors.textGray }}>{name}</Text>
          <Text
            style={{
              fontSize: 17,
              fontWeight: 'bold',
              color: theme.customColors.text,
            }}
          >
            {typeof value === 'number' ? value.toFixed(0) : value}
          </Text>
        </View>
        <View />
        {mail && (
          <IconButton
            icon="email-outline"
            iconColor="#fff"
            style={{ backgroundColor: theme.customColors.gray70 }}
            onPress={() => {
              /* @todo must be awaited */
              void Linking.openURL(`mailto:${value}`);
            }}
          />
        )}
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        paddingLeft: 10,
        paddingRight: 16,
        paddingTop: 10,
        backgroundColor: theme.colors.background,
        height: Dimensions.get('window').height,
      }}
    >
      <AppBar
        title="Version"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <View style={{ marginTop: 24 }} />
      {listItems.map((item, i) => renderItem(i, item.name, item.value, item.mail))}
    </View>
  );
};

// eslint-disable-next-line no-restricted-syntax
export default withTheme(VersionScreen);
