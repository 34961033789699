import AsyncStorage from '@react-native-async-storage/async-storage';
import { ColorSchemeName } from 'react-native';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface ThemeStore {
  colorScheme?: ColorSchemeName;
  setColorScheme: (colorScheme: ColorSchemeName) => void;
  getColorScheme: (systemColorScheme: ColorSchemeName) => ColorSchemeName;
  clear: () => void;
}

export const useThemeStore = create<ThemeStore>()(
  devtools(
    persist(
      (set, get) => ({
        colorScheme: undefined,
        setColorScheme: (colorScheme: ColorSchemeName) => set({ colorScheme }),
        getColorScheme: (systemColorScheme: ColorSchemeName) => {
          const colorScheme = get().colorScheme;
          if (colorScheme) {
            return colorScheme;
          }
          get().setColorScheme(systemColorScheme);
          return systemColorScheme;
        },
        clear: () => set({ colorScheme: undefined }),
      }),
      {
        name: 'luci-theme-store',
        getStorage: () => AsyncStorage,
      },
    ),
    { enabled: __DEV__ },
  ),
);
