import * as Sentry from 'sentry-expo';

import { PersistQueryClientOptions, PersistedClient } from '@tanstack/react-query-persist-client';
import { QueryClient, dehydrate, hydrate, onlineManager } from '@tanstack/react-query';
import { versionCode, versionNumber, default as config } from '../../app.config';

import { LogBox, Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import moment from 'moment';
import { encryptedAsyncStorage } from './encryptedStorage';

export const startUp = () => {
  if (!process.env.EXPO_PUBLIC_API_URL) {
    throw new Error('api url not found');
  }
  if (!config.expo.extra?.sentryDsn) {
    throw new Error('sentry dsn not found');
  }
  Sentry.init({
    dsn: config.expo.extra?.sentryDsn,
    enableInExpoDevelopment: false,
    debug: __DEV__,
    release: versionCode,
    dist: versionNumber.toString(),
    enableNative: !__DEV__,
    environment: process.env.EXPO_PUBLIC_API_URL.includes('.prod.') ? 'production' : 'development',
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24 * 7, //7 days
        staleTime: 1000 * 20, //20 seconds
      },
    },
  });
  LogBox.ignoreAllLogs(true);
  moment.locale('de');

  NetInfo.addEventListener(state => {
    onlineManager.setOnline(state.isConnected ?? undefined);
  });

  if (Platform.OS !== 'web') {
    const asyncStoragePersistor = createAsyncStoragePersister({
      storage: encryptedAsyncStorage,
    });

    /* @todo must be awaited */
    void customPersistQueryClient({
      queryClient,
      persister: asyncStoragePersistor,
      buster: versionCode,
      //only save to async storage when not in dev mode -> To circumvent errors that could be caused by cached data
      dehydrateOptions: {
        dehydrateMutations: !__DEV__,
        dehydrateQueries: !__DEV__,
      },
    });
  }

  return { queryClient };
};

//copied from https://github.dev/tannerlinsley/react-query
const customPersistQueryClient = async ({
  queryClient,
  persister,
  maxAge = 1000 * 60 * 60 * 24,
  buster = '',
  hydrateOptions,
  dehydrateOptions,
}: PersistQueryClientOptions) => {
  const saveClient = () => {
    const persistClient: PersistedClient = {
      buster,
      timestamp: Date.now(),
      clientState: dehydrate(queryClient, dehydrateOptions),
    };

    /* @todo must be awaited */
    void persister.persistClient(persistClient);
  };

  // Attempt restore
  try {
    const persistedClient = await persister.restoreClient();

    if (persistedClient) {
      if (persistedClient.timestamp) {
        const expired = Date.now() - persistedClient.timestamp > maxAge;
        const busted = persistedClient.buster !== buster;
        if (expired || busted) {
          /* @todo must be awaited */
          void persister.removeClient();
        } else {
          hydrate(queryClient, persistedClient.clientState, hydrateOptions);
        }
      } else {
        /* @todo must be awaited */
        void persister.removeClient();
      }
    }
  } catch (err) {
    Sentry.Native.captureException(err);
    /* @todo must be awaited */
    void persister.removeClient();
  }

  // Subscribe to changes in the query/mutation cache to trigger the save
  /* @todo must be awaited */
  queryClient.getQueryCache().subscribe(saveClient);
  queryClient.getMutationCache().subscribe(saveClient);
};
