import React, { useMemo } from 'react';
import { Avatar } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';

export const ChatRoomModeratorIcon = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Avatar.Icon
      size={24}
      icon="shield-half-full"
      color={theme.customColors.pureWhite}
      style={styles.icon}
    />
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    icon: {
      position: 'absolute',
      left: 36,
      top: 0,
      zIndex: 2,
      backgroundColor: theme.colors.primary,
      color: theme.customColors.pureWhite,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
    },
  });
