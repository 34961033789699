import React, { useMemo, useReducer } from 'react';
import { TouchableRipple, Text } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { themeProp } from '../../../types/main';
import { useAppTheme } from '../../../styles/theme';
import { SelectedInfoPage } from '../../../components/Common/Media/types';
import BouncyCheckbox from 'react-native-bouncy-checkbox';

type LinkItemProps = {
  id: string;
  title: string;
  append: (infoPage: SelectedInfoPage) => void;
  remove: (infoPageId: string) => void;
  isSelected?: boolean;
};

export const LinkItem = ({ id, title, isSelected, remove, append }: LinkItemProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [isChecked, toggleIsChecked] = useReducer(previous => !previous, !!isSelected);

  const onPressItem = () => {
    if (isChecked) {
      remove(id);
    } else {
      append({ id, title });
    }
    toggleIsChecked();
  };

  return (
    <TouchableRipple
      onPress={onPressItem}
      style={styles.container}
      rippleColor={theme.customColors.lightRipple}
    >
      <BouncyCheckbox
        disableBuiltInState
        isChecked={isChecked}
        onPress={onPressItem}
        textComponent={
          <Text style={styles.text} numberOfLines={1}>
            {title}
          </Text>
        }
        size={24}
        fillColor={theme.customColors.primary}
        unfillColor={theme.customColors.chatBubbleBackground}
        iconStyle={styles.icon}
        innerIconStyle={styles.innerIcon}
      />
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      paddingVertical: 8,
      paddingHorizontal: 12,
      marginTop: -1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.customColors.listRowItemBackground,
      borderRadius: theme.roundness,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      width: '100%',
    },
    text: {
      fontSize: 16,
      fontWeight: '400',
      color: theme.customColors.textDark,
    },
    checkboxContainerIos: {
      backgroundColor: theme.customColors.background2,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      marginRight: 16,
      marginLeft: 4,
    },
    icon: { borderColor: theme.customColors.primary, marginRight: 8 },
    innerIcon: { borderWidth: 2 },
  });
