import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { CheckInboxScreenAbstract } from '../Auth/CheckInboxScreenAbstract';

export const ReloginCheckInbox = () => {
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  return (
    <CheckInboxScreenAbstract
      onPressContinue={() => navigation.navigate('ReloginConfirmationCode')}
    />
  );
};
