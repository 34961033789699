import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';

type LinkPreviewItemProps = {
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  title: string;
  remove?: () => void;
};

export const LinkPreviewItem = ({ title, icon, remove }: LinkPreviewItemProps) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  return (
    <View style={styles.item}>
      <Icon name={icon} size={100} color={theme.customColors.gray50} />
      <Text style={styles.text} numberOfLines={1}>
        {title}
      </Text>
      {remove && (
        <IconButton
          icon="close"
          style={styles.fab}
          onPress={remove}
          iconColor={theme.customColors.icon}
        />
      )}
    </View>
  );
};

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    item: {
      width: width * 0.65,
      height: width * 0.65,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      borderColor: theme.customColors.borders,
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: theme.customColors.background2,
    },
    text: {
      textDecorationLine: 'underline',
      textAlign: 'center',
    },
    fab: {
      backgroundColor: theme.customColors.lightRipple,
      position: 'absolute',
      right: 8,
      bottom: 8,
    },
  });
