import { FormReply } from '../../../../graphql/operations';
import { getFullName } from '../../profiles/utils';

export const determineWhoFilledOutFormReply = (author: FormReply['author'], userId?: string) => {
  if (author?.userId === userId) {
    return 'Ihnen';
  }
  if (author) {
    return getFullName({
      firstname: author.firstname,
      lastname: author.lastname,
    });
  }
  return getFullName();
};
