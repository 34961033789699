import { Headline, Text, Button } from 'react-native-paper';
import { StyleSheet, SafeAreaView, Image } from 'react-native';
import React, { useMemo } from 'react';

import { AppNavigatorParamList } from '../../../router/AppNavigator';
//@ts-ignore
import SuccessImage from '../../../../assets/Thank_you_500x500.png';
import { StackScreenProps } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { useAppTheme } from '../../../styles/theme';
import { themeProp } from '../../../types/main';
import { AppBar } from '../../../components/AppBar/AppBar';

export type HelpSuccessScreenProps = StackScreenProps<AppNavigatorParamList, 'HelpSuccess'>;

export const HelpSuccessScreen = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<HelpSuccessScreenProps['navigation']>();

  return (
    <>
      <AppBar title="Externe Hilfe" />
      <SafeAreaView style={styles.container}>
        <Image source={SuccessImage} style={styles.image} />
        <Headline style={styles.headline}>Danke!</Headline>
        <Text style={styles.text}>Vielen Dank für Ihr Feedback.</Text>
        <Button
          mode="contained"
          onPress={() =>
            navigation.reset({
              index: 0,
              routes: [{ name: 'Home' }],
            })
          }
          style={styles.button}
          textColor={theme.customColors.textWhite}
        >
          <Text>Zurück</Text>
        </Button>
      </SafeAreaView>
    </>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 30,
    },
    image: {
      height: 150,
      width: 150,
      marginBottom: 50,
    },
    headline: {
      fontSize: 34,
      fontWeight: '800',
      marginBottom: 15,
    },
    text: {
      fontSize: 20,
      fontWeight: '600',
      marginBottom: 25,
      width: '60%',
      textAlign: 'center',
    },
    button: {
      borderRadius: 5,
      alignSelf: 'center',
      marginTop: 20,
    },
  });
