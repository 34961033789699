import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  AttachmentPickerProps,
  AttachmentSheetPicker,
} from '../../../components/Common/Media/AttachmentSheetPicker';
import { UploadType } from '../../../components/Common/FileUpload/types';

export type InquiryMessageActionsProps = AttachmentPickerProps;

export const InquiryMessageActions = ({ recipientName, onSubmit }: InquiryMessageActionsProps) => {
  const styles = useMemo(() => createStyles(), []);
  return (
    <View style={styles.container}>
      <AttachmentSheetPicker
        onSubmit={onSubmit}
        recipientName={recipientName}
        uploadType={UploadType.InquiryMessage}
      />
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 6,
      flexDirection: 'row',
    },
  });
