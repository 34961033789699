import React, { useMemo } from 'react';
import { ChatBubbleShimmer } from './ChatBubbleShimmer';
import { ChatRoomType } from '../../../../graphql/operations';

type ChatRoomShimmerPropTypes = {
  chatRoomType?: ChatRoomType;
};

export const ChatRoomShimmer = ({
  chatRoomType = ChatRoomType.Private,
}: ChatRoomShimmerPropTypes) => {
  const isGroupChatRoom = chatRoomType === ChatRoomType.Group;

  const bubbleShimmers = useMemo(() => {
    return [
      <ChatBubbleShimmer
        key={0}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={144}
        shouldRenderName={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={1} shouldHaveLeftMargin={isGroupChatRoom} height={64} width={96} />,
      <ChatBubbleShimmer key={2} shouldHaveLeftMargin={isGroupChatRoom} height={48} width={144} />,
      <ChatBubbleShimmer key={3} shouldHaveLeftMargin={isGroupChatRoom} height={256} width={196} />,
      <ChatBubbleShimmer
        key={4}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={128}
        shouldRenderAvatar={isGroupChatRoom}
      />,
      <ChatBubbleShimmer
        key={5}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={128}
        shouldRenderName={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={6} shouldHaveLeftMargin={isGroupChatRoom} height={128} width={196} />,
      <ChatBubbleShimmer key={7} shouldHaveLeftMargin={isGroupChatRoom} height={84} width={256} />,

      <ChatBubbleShimmer key={8} isFromAppUser height={48} width={128} />,
      <ChatBubbleShimmer key={9} isFromAppUser height={48} width={256} />,
      <ChatBubbleShimmer key={10} isFromAppUser height={196} width={196} />,
      <ChatBubbleShimmer key={11} isFromAppUser height={64} width={128} />,
      <ChatBubbleShimmer
        key={12}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={128}
        shouldRenderName={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={13} shouldHaveLeftMargin={isGroupChatRoom} height={48} width={96} />,
      <ChatBubbleShimmer key={14} shouldHaveLeftMargin={isGroupChatRoom} height={48} width={144} />,
      <ChatBubbleShimmer
        key={15}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={256}
        width={196}
      />,
      <ChatBubbleShimmer
        key={16}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={128}
        shouldRenderAvatar={isGroupChatRoom}
      />,
      <ChatBubbleShimmer
        key={17}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={48}
        width={128}
        shouldRenderName={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={18} shouldHaveLeftMargin={isGroupChatRoom} height={48} width={144} />,
      <ChatBubbleShimmer key={19} shouldHaveLeftMargin={isGroupChatRoom} height={48} width={256} />,
      <ChatBubbleShimmer key={20} isFromAppUser height={196} width={196} />,
      <ChatBubbleShimmer
        key={21}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={64}
        width={128}
        shouldRenderAvatar={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={22} isFromAppUser height={48} width={256} />,
      <ChatBubbleShimmer
        key={23}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={64}
        width={128}
        shouldRenderAvatar={isGroupChatRoom}
        shouldRenderName={isGroupChatRoom}
      />,
      <ChatBubbleShimmer key={24} isFromAppUser height={64} width={128} />,
      <ChatBubbleShimmer
        key={25}
        shouldHaveLeftMargin={isGroupChatRoom}
        height={64}
        width={128}
        shouldRenderAvatar={isGroupChatRoom}
      />,
    ]
      .map(value => ({ value, sort: Math.random() }))
      .sort((bubbleA, bubbleB) => bubbleA.sort - bubbleB.sort)
      .map(({ value }) => value);
  }, []);

  return <>{bubbleShimmers}</>;
};
