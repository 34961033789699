import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import React, { useMemo } from 'react';
import { useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';

type MessageReceivedTickPropTypes = {
  isPendingMessage: boolean;
  isReceivedByAllChatRoomMembers: boolean;
};

export const MessageReceivedTick = ({
  isPendingMessage,
  isReceivedByAllChatRoomMembers,
}: MessageReceivedTickPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);

  return (
    <Icon
      size={14}
      name={isPendingMessage ? 'clock' : 'check'}
      color={
        isReceivedByAllChatRoomMembers
          ? theme.customColors.messageReceivedTick
          : theme.customColors.text
      }
      style={styles.icon}
    />
  );
};

const createStyles = () => StyleSheet.create({ icon: { marginLeft: -6, marginRight: 4 } });
