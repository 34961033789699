import React from 'react';
import {
  GestureResponderEvent,
  ImageProps,
  ImageSourcePropType,
  ImageURISource,
  ViewStyle,
} from 'react-native';
import { IImageInfo } from 'react-native-image-zoom-viewer/built/image-viewer.type';
import { StockImageMetadata } from '../../../../graphql/operations';
import { NewCachedAutoHeightImage } from '../../../features/caching/components/NewCachedAutoHeightImage';
import { UploadType } from '../FileUpload/types';

type ExpandableCachedAutoHeightImagePropTypes = {
  source: ImageProps['source'] | string;
  type: UploadType;
  width: number;
  initialHeight?: number;
  setModalImage: React.Dispatch<React.SetStateAction<IImageInfo[] | undefined>>;
  onLongPressImage?: () => void;
  modalOpened: boolean;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  resizeMode?: ImageProps['resizeMode'];
  onImageLoaded?: (source: ImageURISource | undefined) => void;
  containerStyles?: ViewStyle;
  imageMetaData?: StockImageMetadata;
};

/* use this component with ImageViewerModal to open images in a modal that covers the entire screen. */
export const ExpandableCachedAutoHeightImage = ({
  source,
  type,
  width,
  initialHeight,
  onLongPressImage,
  setModalImage,
  modalOpened,
  setModalOpened,
  resizeMode,
  onImageLoaded,
  containerStyles,
  imageMetaData,
}: ExpandableCachedAutoHeightImagePropTypes) => {
  const onPressImage:
    | ((event: GestureResponderEvent, source: ImageSourcePropType) => void)
    | undefined = (_, sourceArg) => {
    if (typeof sourceArg === 'number') {
      return;
    }
    if (Array.isArray(sourceArg)) {
      return;
    }
    setModalOpened(!modalOpened);
    if (typeof sourceArg === 'string') {
      setModalImage([
        {
          url: sourceArg,
          props: {},
        } as IImageInfo,
      ]);
      return;
    } else {
      setModalImage([
        {
          url: sourceArg.uri!,
          props: {},
        } as IImageInfo,
      ]);
    }
  };

  return (
    <NewCachedAutoHeightImage
      onLongPress={onLongPressImage}
      source={source}
      type={type}
      width={width}
      initialHeight={initialHeight}
      onPress={onPressImage}
      resizeMode={resizeMode}
      onImageLoaded={onImageLoaded}
      containerStyles={containerStyles}
      imageMetaData={imageMetaData}
    />
  );
};
