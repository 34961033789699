import { Appbar, Portal } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import {
  ChatRole,
  ChatRoomType,
  useChatRoomToReadQuery,
  useInfiniteChatRoomMessagesQuery,
} from '../../../../graphql/operations';
import uuid from 'react-native-uuid';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  DefaultSectionT,
  Platform,
  ScrollView,
  SectionList,
  SectionListRenderItem,
  StyleSheet,
} from 'react-native';
import { ChatAppBarContent } from '../components/ChatAppBarContent';
import { ChatRoomScreenProps } from './types';
import {
  OfflineErrorModal,
  OnlineCallback,
} from '../../../components/Common/Modals/OfflineErrorModal';
import { useStore } from '../../../stores/store';
import { ChatQuoteBox } from '../components/ChatQuoteBox';
import { useSetChatRoomAsRead } from '../hooks/useSetChatRoomAsRead';
import { AppBar } from '../../../components/AppBar/AppBar';
import { useCopiedMessage } from '../hooks/useCopiedMessage';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { useQuotedMessage } from '../hooks/useQuotedMessage';
import { ChatInputToolbarActions } from '../components/ChatInputToolbarActions';
import { ExtendedMessage } from '../types';
import { ChatMessageFactory } from '../components/ChatMessageFactory';
import { ChatToolbar } from '../components/ChatToolbar/ChatToolbar';
import { useImageViewerModal } from '../../../components/Common/Modals/hooks/useImageViewerModal';
import { ImageViewerModal } from '../../../components/Common/Modals/ImageViewerModal';
import { useDeleteMessageSubscription } from '../hooks/useDeleteMessageSubscription';
import { useChatActionSheet } from '../hooks/useChatActionSheet';
import { useDeleteMessage } from '../hooks/useDeleteMessage';
import { ChatDay } from '../components/ChatDay';
import { ChatSystemMessage } from '../components/ChatSystemMessage';
import { ChatQuoteFactory } from '../components/ChatQuoteFactory';
import { ChatRoomShimmer } from '../components/ChatRoomShimmer';
import { Constants, waitForKeyboardDismiss } from '../../../utils';
import { useChatRoomModeration } from '../hooks/useChatRoomModeration';
import { useExtendedMessagesWithDay } from '../hooks/useExtendedMessagesWithDay';
import { useGetChatRoomName } from '../hooks/useGetChatRoomName';
import { useChatMessageTyping } from '../hooks/useChatMessageTyping';
import { ChatRoom as ChatRoomTestIds } from '../../../../test/testIds';
import { DiscardDialog } from '../../../components/Dialog/DiscardDialog';
import { toast } from '../../../utils/toast/toast';
import { Log } from '../../../utils/Log';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ConfirmDialog } from '../../../components/Dialog/ConfirmDialog';
import { useForwardMessages } from '../hooks/useForwardMessages';
import { useSelections } from '../../../hooks/useSelections/useSelections';
import { AlertDialog } from '../../../components/Dialog/AlertDialog';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { AnimationDirection } from '../../../utils/animation/types';
import { useForwardeePicker } from '../../../components/Picker/hooks/useForwardeePicker';
import { ForwardeePicker } from '../../../components/Picker/ForwardeePicker';
import { useAppBar } from '../../news/hooks/useAppBar';
import { ForwardeeType } from '../../../components/Picker/types';
import { invalidateInfiniteChatRoomMessagesQuery } from '../utils/invalidateInfiniteChatRoomMessagesQuery';
import { PlaceholderAppBarAction } from '../../../components/Common/AppBar/PlaceholderAppBarAction';
import { useChatRoomReadSubscription } from '../hooks/useChatRoomReadSubscription';
import { useChatRoomEntranceDate } from '../hooks/useChatRoomEntranceDate';
import { ChatLoadMoreButton } from '../components/ChatLoadMoreButton';
import { KeyboardAvoidingViewIos } from '../../../components/Containers/KeyboardAvoidingViewIos';
import { useCurrentChatRoomId } from '../hooks/useCurrentChatRoomId';
import { useChatRoomMessageIds } from '../hooks/useChatRoomMessageIds';
import { useMediaCaching } from '../../../hooks/useMediaCaching/useMediaCaching';
import { useRealTimeMessages } from '../hooks/useRealTimeMessages';
import { useAppStatus } from '../../../hooks/common/appStatus';

export const ChatRoomScreen = ({
  route: {
    params: { id: chatRoomId, name, imageUri, members, role, type },
  },
  navigation,
}: ChatRoomScreenProps) => {
  const { bottom } = useSafeAreaInsets();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const queryClient = useQueryClient();
  const appUser = useStore(s => s.userProfile);
  const userIdOfAppUser = useMemo(() => appUser?.userId, [appUser]);
  const { messageToCopy, setMessageToCopy } = useCopiedMessage();
  const { quotedMessage, setQuotedMessage } = useQuotedMessage();
  const scrollRef = useRef<ScrollView>(null);
  const sectionListRef = useRef<SectionList>(null);
  const { appMovedToView } = useAppStatus();
  const enableFallbackQuery = useMemo(() => {
    return !name || !role || !members;
  }, [name, role, members]);

  const fallbackChatRoomToReadQuery = useChatRoomToReadQuery(
    { chatRoomId },
    { enabled: enableFallbackQuery, staleTime: Infinity },
  );

  const chatRoomMembers = useMemo(
    () => members ?? fallbackChatRoomToReadQuery.data?.chatRoom.members ?? [],
    [members, fallbackChatRoomToReadQuery.data?.chatRoom.members],
  );

  const chatRoomType = useMemo(() => {
    return type ?? fallbackChatRoomToReadQuery?.data?.chatRoom.type ?? ChatRoomType.Private;
  }, [type, fallbackChatRoomToReadQuery?.data?.chatRoom.type]);

  const chatRoomName = useGetChatRoomName({
    chatRoomQueryData: fallbackChatRoomToReadQuery.data,
    chatRoomQueryIsInitialLoading: fallbackChatRoomToReadQuery.isInitialLoading,
    members: chatRoomMembers,
    name: name ?? fallbackChatRoomToReadQuery.data?.chatRoom.name ?? undefined,
  });

  const { typingEvents, onEmitTypingChatMessage } = useChatMessageTyping({
    chatRoomId,
    chatRoomType,
  });

  const chatRoomImageSource = useMemo(() => {
    return imageUri ?? fallbackChatRoomToReadQuery.data?.chatRoom.groupImagePath ?? undefined;
  }, [imageUri, fallbackChatRoomToReadQuery.data?.chatRoom.groupImagePath]);

  const { isUserModerator, userIdsOfModerators, isGroupChat } = useChatRoomModeration({
    chatRoomMembers,
    chatRoomType,
  });

  const userIdOfPrivateChatRoomPartner = useMemo(
    () =>
      isGroupChat
        ? undefined
        : chatRoomMembers.find(member => member.userId !== userIdOfAppUser)?.userId,
    [isGroupChat, chatRoomMembers],
  );

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const { canDownload } = useMediaCaching();
  const isDownloadConfirmationNeeded = useMemo(() => !canDownload, [canDownload]);

  const { chatRoomEntranceDate } = useChatRoomEntranceDate({ chatRoomId });

  const messagesQuery = useInfiniteChatRoomMessagesQuery(
    'pageInput',
    { pageInput: { pageSize: 30 }, chatRoomId, chatRoomEntranceDate },
    {
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages?.reduce(
          (count, page) => count + page.messageList?.messages?.length,
          0,
        );
        if (alreadyFetched === lastPage?.messageList?.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 30,
            token: lastPage?.messageList?.token,
          },
        };
      },
      staleTime: Infinity,
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const loadMessages = async () => {
    try {
      await queryClient.resetQueries(
        useInfiniteChatRoomMessagesQuery.getKey({
          chatRoomId,
          pageInput: { pageSize: 30 },
          chatRoomEntranceDate,
        }),
      );
      await messagesQuery.refetch({
        refetchPage: (_lastPage, index) => {
          return index === 0;
        },
      });
    } catch (e) {
      Log.error(e);
    }
  };

  useEffect(() => {
    // void invalidateInfiniteChatRoomMessagesQuery({ chatRoomId, queryClient });
    void loadMessages();
    // void messagesQuery.refetch();
  }, [chatRoomId, chatRoomEntranceDate]);

  const queriedMessages = useMemo(() => {
    return messagesQuery.data?.pages.flatMap(page => page.messageList.messages) ?? [];
  }, [messagesQuery.data?.pages.length, messagesQuery.data?.pages, chatRoomId]);

  useCurrentChatRoomId({ chatRoomId });

  const { chatRoomLastReadDate } = useChatRoomReadSubscription({ chatRoomId });

  const { deletedMessages } = useDeleteMessageSubscription(chatRoomId);

  const {
    realTimeMessages,
    isSending,
    sentOrReceivedMessageOnce,
    sendChatRoomMessages,
    onSentOrReceivedMessage,
    sendLink,
    sendAttachments,
  } = useRealTimeMessages({ chatRoomId });

  const { deletedMessageIds, queriedMessageIds, realTimeMessageIds } = useChatRoomMessageIds({
    chatRoomId,
    deletedMessages,
    queriedMessages,
    realTimeMessages,
  });

  const messages = useExtendedMessagesWithDay({
    deletedMessageIds,
    queriedMessageIds,
    realTimeMessageIds,
    queriedMessages,
    realTimeMessages,
    deletedMessages,
    sentOrReceivedMessageOnce,
    userIdsOfModerators,
    chatRoomLastReadDate,
  });

  const getLastChatRoomMessageId = useCallback(() => {
    if (realTimeMessageIds.length > 0) {
      return realTimeMessageIds[0];
    }
    if (queriedMessageIds.length > 0) {
      return queriedMessageIds[0];
    }
    return undefined;
  }, [realTimeMessageIds, queriedMessageIds]);

  const { setChatRoomAsRead } = useSetChatRoomAsRead({
    chatRoomId,
    lastChatRoomMessageId: getLastChatRoomMessageId(),
  });

  useEffect(() => {
    if (appMovedToView && !messagesQuery.isFetching) {
      void messagesQuery.refetch({ refetchPage: (_lastPage, index) => index === 0 });
      // void messagesQuery.refetch();
      void setChatRoomAsRead();
    }
  }, [appMovedToView]);

  const {
    confirmModalProps: confirmMessageDeletionProps,
    showConfirmModal: showMessageDeletionModal,
  } = useDeleteMessage();

  const {
    confirmModalProps: confirmMessageForwardProps,
    showConfirmModal: showMessageForwardingModal,
    isForwardingMessages,
    isLimitAlertVisible,
    setLimitAlertVisible,
  } = useForwardMessages();

  const findQuotedChatMessage = useCallback(
    (message?: ExtendedMessage) => {
      if (!message) {
        return undefined;
      }
      return (
        realTimeMessages.find(msg => msg.id === message._id) ??
        queriedMessages.find(msg => msg.id === message._id) ??
        undefined
      );
    },
    [queriedMessages, realTimeMessages],
  );

  const onSendChatRoomMessage = useCallback(
    (onOnline: OnlineCallback, messageText: string) => {
      if (!userIdOfAppUser) {
        return;
      }
      setMessageToCopy(messageText);
      onSentOrReceivedMessage();
      onOnline(async () => {
        sectionListRef.current?.getScrollResponder()?.scrollTo(0);
        await sendChatRoomMessages([
          {
            _id: uuid.v4().toString(),
            createdAt: new Date(),
            text: messageText,
            user: {
              _id: userIdOfAppUser!,
            },
            readByCurrentUser: true,
            quote: findQuotedChatMessage(quotedMessage),
          },
        ]);
      });
      setQuotedMessage(undefined);
    },
    [userIdOfAppUser, quotedMessage, sendChatRoomMessages],
  );

  useEffect(() => {
    setTimeout(() => scrollRef.current?.scrollToEnd({ animated: false }));
  }, []);

  const {
    isSelecting: isSelectingMessages,
    setSelecting: setSelectingMessages,
    selections: messageSelections,
    countOfSelections: countOfMessageSelections,
    isEmpty: isMessageSelectionsEmpty,
    isMultiple: isMessageSelectionsMultiple,
    onSelect: onSelectMessage,
    onCancelSelection: onCancelMessageSelection,
    toggleSelecting: toggleSelectingMessages,
    clear: clearSelectedMessages,
  } = useSelections<string>();

  const onReportAbuse = useCallback(() => {
    navigation.navigate('ReportAbuse');
  }, [theme]);

  const onDelete = useCallback((message: ExtendedMessage) => {
    showMessageDeletionModal(message);
  }, []);

  const onModerativeDelete = useCallback((message: ExtendedMessage) => {
    showMessageDeletionModal(message);
  }, []);

  const onCopyText = useCallback(async (message: ExtendedMessage) => {
    try {
      await Clipboard.setStringAsync(message.text);
      toast('Der Text wurde erfolgreich in Ihrer Zwischenablage gespeichert.');
    } catch (e) {
      Log.error(e, { message: 'cannot copy message to clipboard' });
    }
  }, []);

  const onQuote = useCallback((messageToQuote: ExtendedMessage) => {
    setQuotedMessage(messageToQuote);
  }, []);

  const onForward = useCallback(
    (messageToForward: ExtendedMessage) => {
      onSelectMessage(
        {
          id: messageToForward._id.toString(),
          value: messageToForward._id.toString(),
        },
        false,
      );
      toggleSelectingMessages();
    },
    [isSelectingMessages, onSelectMessage],
  );

  const onSeeReadReceipts = useCallback((messageToReadReceiptsOf: ExtendedMessage) => {
    navigation.navigate('ChatMessageReadReceipts', {
      message: messageToReadReceiptsOf,
    });
  }, []);

  const { showActionSheet } = useChatActionSheet(appUser, {
    onReportAbuse,
    onDelete,
    onModerativeDelete,
    onCopyText,
    onQuote,
    onForward,
    onSeeReadReceipts,
  });

  const onUnmounted = useCallback(() => {
    setSelectingMessages(false);
    setSelectingForwardees(false);
    clearSelectedMessages();
    clearForwardeeSelections();
  }, []);

  useEffect(() => {
    return () => onUnmounted();
  }, [chatRoomId]);

  const onLongPressMessage = useCallback(
    async (message: ExtendedMessage, isSelected?: boolean) => {
      if (message.deletedAt) {
        return;
      }

      if (isSelectingMessages && message) {
        return onSelectMessage(
          { id: message._id.toString(), value: message._id.toString() },
          !!isSelected,
        );
      }

      await waitForKeyboardDismiss();
      showActionSheet({
        message,
        isModerator: isUserModerator,
        isForwardable: !message.formReply,
      });
    },
    [isSelectingMessages, isUserModerator],
  );

  const onPressChatMessage = useCallback(
    (imageSource?: string, message?: ExtendedMessage, isSelected?: boolean) => {
      if (message?.deletedAt) {
        return;
      }
      if (isSelectingMessages && message) {
        return onSelectMessage(
          { id: message._id.toString(), value: message._id.toString() },
          !!isSelected,
        );
      }
      if (imageSource) {
        setImageViewerModalImages([{ url: imageSource }]);
        setImageViewerModalVisible(true);
      }
    },
    [isSelectingMessages],
  );

  const onSelect = useCallback(
    (id: string, isOptionSelected: boolean) => onSelectMessage({ id, value: id }, isOptionSelected),
    [onSelectMessage],
  );

  const messageSelectionIds = useMemo(
    () => messageSelections.map(selection => selection.id),
    [messageSelections],
  );

  const renderItem: SectionListRenderItem<ExtendedMessage, DefaultSectionT> = useCallback(
    ({ item: message, index, section: { data: messagesOfDay } }) => {
      if (message.system) {
        return <ChatSystemMessage message={message} />;
      }
      const nextMessage = index === 0 ? undefined : messagesOfDay[index - 1];
      const previousMessage = index === messagesOfDay.length ? undefined : messagesOfDay[index + 1];
      const isSelected = messageSelections
        .map(selection => selection.id)
        .includes(message._id.toString());
      const isMessageSelectable = isSelectingMessages && !message.formReply;
      return (
        <ChatMessageFactory
          key={message._id}
          chatRoomId={chatRoomId}
          message={message}
          onPress={onPressChatMessage}
          onLongPress={onLongPressMessage}
          onSelect={onSelect}
          isSelectable={isMessageSelectable}
          isSelected={isSelected}
          isAvatarOrNameOfOtherUserShown={isGroupChat}
          isFromAppUser={message.user._id === userIdOfAppUser}
          isNextMessageFromSameUser={nextMessage?.user._id === message.user._id}
          isPreviousMessageFromSameUser={previousMessage?.user._id === message.user._id}
          iconNextToName={message.user.role === ChatRole.Moderator ? 'shield-half-full' : undefined}
          isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
        />
      );
    },
    [
      chatRoomId,
      userIdOfAppUser,
      messages.length,
      isSelectingMessages,
      messageSelectionIds,
      isDownloadConfirmationNeeded,
    ],
  );

  const {
    onEmptySearchPress: onEmptySearchChatRoomPress,
    searchInputValue: searchChatRoomInputValue,
    debouncedSearchValue: debouncedSearchChatRoomValue,
    setSearchInputValue: setSearchChatRoomInputValue,
    setShowSearch: setShowSearchChatRoom,
    showSearch: showSearchChatRoom,
  } = useAppBar();

  const {
    forwardeeOptions,
    forwardeeSelections,
    isSelectingForwardees,
    forwardeePickerExitDir,
    isChatRoomsLoading,
    setSelectingForwardees,
    toggleSelectingForwardees,
    clearForwardeeSelections,
    setForwardeePickerExitDir,
    syncContactsToForwardees,
    appendToForwardeeSelections,
    removeFromForwardeeSelections,
  } = useForwardeePicker({
    userIdOfAppUser,
    chatRoomId,
    debouncedSearchChatRoomValue,
  });

  const onSuccessForward = useCallback(
    (numberOfFailedDeliveries: number) => {
      const message =
        numberOfFailedDeliveries > 0
          ? `${numberOfFailedDeliveries} von ${
              messageSelections.length * forwardeeSelections.length
            } Nachricht${
              isMessageSelectionsMultiple ? 'en' : ''
            } konnten nicht weitergeleitet werden.`
          : isMessageSelectionsMultiple
          ? `Die Nachrichten wurden erfolgreich weitergeleitet.`
          : `Die Nachricht wurde erfolgreich weitergeleitet.`;
      toast(message);
      setForwardeePickerExitDir(AnimationDirection.RIGHT);
      toggleSelectingForwardees();
      const isChatRoomUnspecified =
        forwardeeSelections.length !== 1 ||
        forwardeeSelections[0].value.type === ForwardeeType.CONTACT;
      if (isChatRoomUnspecified) {
        if (navigation.canGoBack()) {
          navigation.goBack();
        }
      } else {
        const { id } = forwardeeSelections[0].value;
        void invalidateInfiniteChatRoomMessagesQuery({
          chatRoomId: id,
          queryClient,
        });
        navigation.navigate('ChatRoom', {
          id,
        });
      }
      onCancelMessageSelection();
      clearForwardeeSelections();
    },
    [forwardeeSelections],
  );

  const onCancelForward = useCallback(() => {
    setForwardeePickerExitDir(AnimationDirection.RIGHT);
    setSelectingForwardees(false);
    clearForwardeeSelections();
  }, [isSelectingForwardees]);

  const onConfirmForward = useCallback(async () => {
    const forwardees = forwardeeSelections.map(forwardee => forwardee.value);
    const forwardeesAsContact = forwardees
      .filter(forwardee => forwardee.type === ForwardeeType.CONTACT)
      .map(forwardee => forwardee.id);
    const forwardeesAsChatRoom = forwardees
      .filter(forwardee => forwardee.type === ForwardeeType.CHATROOM)
      .map(forwardee => forwardee.id);
    await confirmMessageForwardProps.forwardMessages({
      chatMessageIds: messageSelectionIds,
      forwardedTo: {
        chatRoomIds: forwardeesAsChatRoom,
        contactIds: forwardeesAsContact,
      },
      onSuccess: onSuccessForward,
    });
  }, [forwardeeSelections, messageSelectionIds]);

  return (
    <OfflineErrorModal messageToCopy={messageToCopy}>
      {onOnline => (
        <>
          <AppBar
            renderContent={() =>
              isSelectingMessages ? (
                <Appbar.Content
                  title={`${countOfMessageSelections} Auswahl${
                    isMessageSelectionsMultiple ? `en` : ``
                  } weiterleiten`}
                  titleStyle={styles.contentTitle}
                />
              ) : (
                <ChatAppBarContent
                  chatPartners={chatRoomMembers}
                  isGroupChat={isGroupChat}
                  chatId={chatRoomId}
                  name={chatRoomName}
                  chatImageUri={chatRoomImageSource}
                  typingEvents={typingEvents.map(event => event.value)}
                />
              )
            }
            renderActionsLeftOfTitle={() =>
              isSelectingMessages ? (
                <Appbar.Action
                  icon="close"
                  onPress={onCancelMessageSelection}
                  color={theme.customColors.primary}
                />
              ) : (
                <Appbar.BackAction
                  onPress={() => navigation.goBack()}
                  color={theme.customColors.icon}
                />
              )
            }
            renderActionsRightOfTitle={
              isSelectingMessages
                ? () => (
                    <Appbar.Action
                      icon="share"
                      onPress={toggleSelectingForwardees}
                      color={theme.customColors.primary}
                      disabled={isMessageSelectionsEmpty}
                    />
                  )
                : () => <PlaceholderAppBarAction />
            }
          />
          <KeyboardAvoidingViewIos>
            {messagesQuery.isInitialLoading ? (
              <ScrollView ref={scrollRef}>
                <ChatRoomShimmer
                  chatRoomType={isGroupChat ? ChatRoomType.Group : ChatRoomType.Private}
                />
              </ScrollView>
            ) : (
              <SectionList
                contentContainerStyle={{
                  paddingTop: isSelectingMessages ? bottom : 4,
                }}
                keyboardShouldPersistTaps="handled"
                inverted
                ref={sectionListRef}
                disableVirtualization={Platform.OS === 'web'}
                sections={messages}
                style={styles.sectionList}
                renderItem={renderItem}
                renderSectionFooter={({ section: { day } }) => <ChatDay day={day} />}
                ListFooterComponent={
                  messagesQuery.hasNextPage ? (
                    <ChatLoadMoreButton
                      onPress={
                        !messagesQuery.isFetchingNextPage && messagesQuery.hasNextPage
                          ? () => messagesQuery.fetchNextPage()
                          : () => {}
                      }
                      isLoading={messagesQuery.isFetchingNextPage || messagesQuery.isInitialLoading}
                    />
                  ) : (
                    <></>
                  )
                }
                keyExtractor={item => item._id.toString()}
              />
            )}
            {!isSelectingMessages && (
              <ChatToolbar
                onTypingStart={onEmitTypingChatMessage}
                textInputTestId={ChatRoomTestIds.chatMessageInput}
                submitButtonTestId={ChatRoomTestIds.sendButton}
                disabled={isSending || messagesQuery.isInitialLoading}
                onSend={messageText => onSendChatRoomMessage(onOnline, messageText)}
                renderActions={() => (
                  <ChatInputToolbarActions
                    chatRoomId={chatRoomId}
                    attachmentRecipientName={chatRoomName}
                    disabled={isSending || messagesQuery.isLoading}
                    sendAttachments={sendAttachments}
                    sendLink={sendLink}
                  />
                )}
                renderOnTopOfToolbar={
                  quotedMessage
                    ? () => (
                        <ChatQuoteBox isInChatToolbar onCancel={() => setQuotedMessage(undefined)}>
                          <ChatQuoteFactory quote={quotedMessage!} />
                        </ChatQuoteBox>
                      )
                    : undefined
                }
              />
            )}
          </KeyboardAvoidingViewIos>
          <ImageViewerModal
            visible={imageViewerModalVisible}
            setVisible={setImageViewerModalVisible}
            activeIndex={0}
            imageUrls={imageViewerModalImages}
          />
          <ForwardeePicker
            chatRoomId={chatRoomId}
            syncContactsToForwardees={syncContactsToForwardees}
            appendToForwardeeSelections={appendToForwardeeSelections}
            forwardeeOptions={forwardeeOptions}
            forwardeePickerExitDir={forwardeePickerExitDir}
            onClose={onCancelForward}
            onPressForward={() => showMessageForwardingModal()}
            forwardeeSelections={forwardeeSelections}
            isChatRoomsLoading={isChatRoomsLoading}
            isSelectingForwardees={isSelectingForwardees}
            setSelectingForwardees={setSelectingForwardees}
            removeFromForwardeeSelections={removeFromForwardeeSelections}
            userIdOfPrivateChatRoomPartner={userIdOfPrivateChatRoomPartner}
            onEmptySearchPress={onEmptySearchChatRoomPress}
            searchInputValue={searchChatRoomInputValue}
            setSearchInputValue={setSearchChatRoomInputValue}
            setShowSearch={setShowSearchChatRoom}
            showSearch={showSearchChatRoom}
          />
          <Portal>
            <LoadingBlocker visible={isForwardingMessages} />
            <DiscardDialog
              title="Sind Sie sicher?"
              content="Möchten Sie die Nachricht wirklich löschen?"
              discardButtonLabel="Löschen"
              isOpen={confirmMessageDeletionProps.isVisible}
              onDiscard={confirmMessageDeletionProps.onConfirm}
              onCancel={confirmMessageDeletionProps.onDismiss}
            />
            <ConfirmDialog
              confirmButtonLabel="Weiterleiten"
              title="Sind Sie sicher?"
              content="Möchten Sie diese Nachrichten weiterleiten?"
              isOpen={confirmMessageForwardProps.isVisible}
              onConfirm={onConfirmForward}
              onCancel={confirmMessageForwardProps.onDismiss}
            />
            <AlertDialog
              isOpen={isLimitAlertVisible}
              title="Zu viele Nachrichten"
              content={`Bitte reduzieren Sie entweder die Anzahl der Nachrichten oder der Empfänger von Nachrichten auf, so dass ihr Multiplikationsprodukt ${Constants.FORWARDED_MESSAGE_COUNT_LIMIT} nicht überschreitet. `}
              onDismiss={() => setLimitAlertVisible(false)}
            />
          </Portal>
        </>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    sectionList: {
      flex: 1,
      backgroundColor: theme.colors.background,
      ...(Platform.OS === 'web' ? { pointerEvents: 'none' } : {}),
    },
    quotedMessageContainer: {
      flex: 1,
      backgroundColor: theme.customColors.background3,
      borderTopColor: theme.customColors.borders,
      borderTopWidth: 0.5,
    },
    avatar: {
      backgroundColor: theme.customColors.avatar,
    },
    activityIndicator: {
      flex: 1,
      alignSelf: 'center',
      justifyContent: 'center',
      paddingTop: 10,
    },
    inputToolbarContainer: {
      backgroundColor: theme.colors.background,
    },
    textInput: {
      color: theme.colors.onSurface,
      backgroundColor: theme.customColors.textInputBackground,
      borderBottomStartRadius: 20,
      borderBottomEndRadius: 20,
      borderTopStartRadius: 20,
      borderTopEndRadius: 20,
      paddingHorizontal: 16,
      paddingVertical: 4,
    },
    link: {
      color: theme.customColors.primary,
    },
    contentTitle: { fontSize: 16, fontWeight: 'bold', textAlign: 'center' },
    optionLabel: { marginLeft: 8 },
    chatRoomPickerFooterButton: { alignSelf: 'center' },
  });
