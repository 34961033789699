import { useCallback, useEffect, useRef } from 'react';
import { Log } from '../../../utils/Log';
import { useSocketStore } from '../../../stores/SocketStore';
import { useStore } from '../../../stores/store';
import { ChatEvents, ChatMessageTyping } from '@luciapp/socket-contract';
import throttle from 'lodash.throttle';
import { ChatRoomType } from '../../../../graphql/operations';
import { useSelections } from '../../../hooks/useSelections/useSelections';

const TIMEOUT_TIL_USER_STOPS_TYPING = 2000;

interface IUseChatMessageTyping {
  chatRoomId: string;
  chatRoomType: ChatRoomType;
}

export const useChatMessageTyping = ({ chatRoomId, chatRoomType }: IUseChatMessageTyping) => {
  const chatSocket = useSocketStore(s => s.chatSocket);
  const appUser = useStore(s => s.userProfile);
  const tenantName = useStore(s => s.tenantName);
  const timeoutUntilUserStopsTypingRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const { append, remove, selections: typingEvents } = useSelections<ChatMessageTyping>();

  const onEmitTypingChatMessage = useCallback(
    throttle(() => {
      if (!chatSocket || !appUser) {
        Log.error('socket or user not there, cannot send typings');
        return;
      }
      const typingEventToEmit: ChatMessageTyping = {
        chatRoomId,
        firstname: appUser.firstname,
        lastname: appUser.lastname,
        userProfileId: appUser.userId,
        chatRoomType,
        tenantName,
      };
      chatSocket.emit(ChatEvents.chatMessageTyping, typingEventToEmit);
    }, TIMEOUT_TIL_USER_STOPS_TYPING),
    [chatSocket, appUser, chatRoomId],
  );

  const onObserveChatMessageBeingTyped = useCallback(
    (typingEventToAppend: ChatMessageTyping) => {
      if (
        typingEventToAppend.userProfileId === appUser?.userId ||
        typingEventToAppend.chatRoomId !== chatRoomId
      ) {
        return;
      }
      if (timeoutUntilUserStopsTypingRef.current) {
        clearTimeout(timeoutUntilUserStopsTypingRef.current);
      }
      append({
        id: `${typingEventToAppend.chatRoomId}-${typingEventToAppend.userProfileId}`,
        value: typingEventToAppend,
      });
      timeoutUntilUserStopsTypingRef.current = setTimeout(() => {
        remove(`${typingEventToAppend.chatRoomId}-${typingEventToAppend.userProfileId}`);
      }, TIMEOUT_TIL_USER_STOPS_TYPING);
    },
    [appUser?.userId, timeoutUntilUserStopsTypingRef.current, chatRoomId],
  );

  useEffect(() => {
    if (chatSocket?.connected) {
      chatSocket?.on(ChatEvents.chatMessageTyped, onObserveChatMessageBeingTyped);
    }
    return () => {
      chatSocket?.off(ChatEvents.chatMessageTyped, onObserveChatMessageBeingTyped);
    };
  }, [chatSocket?.connected, chatRoomId]);

  return {
    onEmitTypingChatMessage,
    typingEvents,
  };
};
