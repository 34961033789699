import { StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import { FormReplyStatus as FormReplyStatusType } from '../../../../../graphql/operations';
import { AppTheme, useAppTheme } from '../../../../styles/theme';
import React, { useMemo } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';

type FormStatusChipFactoryPropTypes = {
  status?: FormReplyStatusType;
  onPress?: () => void;
};

export const FormStatusChipFactory = ({ status, onPress }: FormStatusChipFactoryPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (status === FormReplyStatusType.Approved) {
    return (
      <Chip
        compact
        style={[styles.chip, styles.approved]}
        textStyle={styles.chipText}
        icon={() => <Icon name="check-circle" size={20} color={theme.customColors.background3} />}
        onPress={onPress}
      >
        Bestätigt
      </Chip>
    );
  }

  if (status === FormReplyStatusType.Resubmit) {
    return (
      <Chip
        compact
        style={[styles.chip, styles.resubmit]}
        textStyle={styles.chipText}
        icon={() => <Icon name="alert-circle" size={20} color={theme.customColors.background3} />}
        onPress={onPress}
      >
        Wiedervorlage nötig
      </Chip>
    );
  }

  if (status === FormReplyStatusType.Pending) {
    return (
      <Chip
        compact
        style={[styles.chip, styles.pending]}
        textStyle={styles.chipText}
        icon={() => <Icon name="clock-time-ten" size={20} color={theme.customColors.background3} />}
        onPress={onPress}
      >
        Unbearbeitet
      </Chip>
    );
  }

  if (status === FormReplyStatusType.Rejected) {
    return (
      <Chip
        compact
        style={[styles.chip, styles.rejected]}
        textStyle={styles.chipText}
        icon={() => <Icon name="close-thick" size={20} color={theme.customColors.background3} />}
        onPress={onPress}
      >
        Abgelehnt
      </Chip>
    );
  }

  return <></>;
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    chip: { borderRadius: 100, marginBottom: 6, flex: 1 },
    chipText: { color: theme.customColors.background3 },
    approved: { backgroundColor: theme.customColors.signalGreen },
    rejected: { backgroundColor: theme.customColors.signalRed },
    resubmit: { backgroundColor: theme.customColors.signalOrange },
    pending: { backgroundColor: theme.customColors.signalBlue },
  });
