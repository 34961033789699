import { useState } from 'react';

export type Selection<ValueType> = {
  id: string;
  value: ValueType;
};

interface IUseSelections {
  countOfSelections?: { max?: number; min?: number };
}

export const useSelections = <ValueType, T extends Selection<ValueType> = Selection<ValueType>>(
  props?: IUseSelections,
) => {
  const [isSelecting, setSelecting] = useState(false);
  const toggleSelecting = () => setSelecting(!isSelecting);
  const [selections, setSelections] = useState<Array<T>>([]);

  const append = (item: T) => {
    if ((props?.countOfSelections?.max ?? Infinity) < selections.length + 1) {
      return setSelections([...selections.slice(1), item]);
    }
    return setSelections([...selections, item]);
  };

  const remove = (id: T['id']) => {
    return setSelections(selections.filter(selection => selection.id !== id));
  };

  const clear = () => {
    return setSelections([]);
  };

  const onSelect = (item: T, isSelected: boolean) => {
    if (isSelected) {
      return remove(item.id);
    }
    return append(item);
  };

  const onCancelSelection = () => {
    toggleSelecting();
    clear();
  };

  const countOfSelections = selections.length;
  const isMultiple = countOfSelections > 1;
  const isEmpty = countOfSelections === 0;
  const isCountValid =
    selections.length >= (props?.countOfSelections?.min ?? -Infinity) &&
    selections.length <= (props?.countOfSelections?.max ?? Infinity);

  return {
    selections,
    isSelecting,
    setSelecting,
    countOfSelections,
    isMultiple,
    isEmpty,
    isCountValid,
    onSelect,
    setSelections,
    append,
    remove,
    clear,
    toggleSelecting,
    onCancelSelection,
  };
};
