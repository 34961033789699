import { Button, TextInput } from 'react-native-paper';
import { Image, Platform, SafeAreaView, StyleSheet, Text, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
// @ts-ignore
import Logo from '../../../assets/Logo_LUCI_M_400x314_w.png';
import { authStyles } from '../../styles/authStyles';
import { TenantLogin } from '../../../test/testIds';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { KeyboardAvoidingView } from '../../components/HigherOrder';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { checkIsWhiteLabelApp } from '../../utils/checkIsWhiteLabelApp';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import appConfig from '../../../app.config';
import { fetchTenant } from '../../utils/fetchTenant';
import { storeTenant } from '../../utils/tenancyUtils';
import { useStore } from '../../stores/store';
import { Log } from '../../utils/Log';
import { NoDataRefresh } from '../../components/Common/Loading/NoDataRefresh';
import { useTenantContext } from '../../hooks/tenant';

export const TenantInputSelectionScreen = () => {
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'TenantScreen'>['navigation']>();
  const { setTenant: setTenantDeprecated } = useTenantContext();
  const setTenant = useStore(s => s.setTenant);
  const [tenantAccountname, setTenantAccountname] = useState('');
  const [hasLoadTenantError, setHasLoadTenantError] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const [isSubmitting, setSubmitting] = useState(false);
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const isWhiteLabelApp = checkIsWhiteLabelApp();

  const dispatchWhitelabelApp = useCallback(async () => {
    setHasLoadTenantError(false);
    try {
      if (!appConfig.expo.extra?.tenantName) {
        throw new Error('tenant name not found in config');
      }
      const tenant = await fetchTenant(appConfig.expo.extra?.tenantName);
      if (!tenant) {
        throw new Error('tenant not retrieved');
      }
      await storeTenant(tenant);
      setTenant(tenant);
      setTenantDeprecated(tenant);
      navigation.navigate('Login');
    } catch (e) {
      Log.error(e);
      setHasLoadTenantError(true);
    }
  }, []);

  useEffect(() => {
    if (isWhiteLabelApp) {
      void dispatchWhitelabelApp();
    }
  }, [isWhiteLabelApp]);

  const submitTenant = useCallback(async () => {
    setError(undefined);
    try {
      setSubmitting(true);
      const tenant = await fetchTenant(tenantAccountname.toLowerCase().trim());
      await storeTenant(tenant);
      setTenantDeprecated(tenant);
      setTenant(tenant);
      navigation.navigate('Login');
    } catch (err) {
      Log.error(err);
      setError(
        'Diese Firmen-ID existiert nicht. Bitte vergewissern Sie sich, dass die eingegebene Firmen-ID korrekt ist.',
      );
    } finally {
      setSubmitting(false);
    }
  }, [tenantAccountname]);

  const renderWhitelabelControls = () => {
    if (hasLoadTenantError) {
      return (
        <NoDataRefresh
          text="Beim Laden des Tenants ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
          onPress={() => dispatchWhitelabelApp()}
          isLoading={false}
        />
      );
    }
    return <LoadingBlocker visible={true} spinnerColor={theme.customColors.accent} />;
  };

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingViewStyles}
      contentContainerStyle={styles.keyboardAvoidingContentStyles}
    >
      {isWhiteLabelApp ? (
        <SafeAreaView>{renderWhitelabelControls()}</SafeAreaView>
      ) : (
        <BaseAuthScreen
          headline="Mit Firmen-ID anmelden"
          justify
          renderTop={() => <Image source={Logo} style={styles.image} />}
          description={`Geben Sie Ihre Firmen-ID ein, um sich bei Ihrem Konto anzumelden.`}
          containerStyle={styles.baseAuthScreenContainer}
          innerViewStyles={styles.baseAuthInnerView}
        >
          <View style={styles.formContainer}>
            <Text style={styles.companyIdText}>Firmen-ID</Text>
            <TextInput
              dense
              style={{
                maxWidth: theme.content.maxWidth,
                // width: '100%',
                // flex: 1,
                width: '100%',
                backgroundColor: theme.customColors.textInputBackground,
              }}
              mode="outlined"
              activeOutlineColor={theme.customColors.primary}
              onChangeText={setTenantAccountname}
              autoCapitalize="none"
              placeholder="Firmen-ID"
              testID={TenantLogin.tenantIdInput}
              autoCorrect={false}
              autoComplete="off"
              returnKeyType="send"
              /* @todo must be awaited */
              onSubmitEditing={() => void submitTenant()}
              value={tenantAccountname}
            />
            <View style={{ minHeight: 24 }} testID={TenantLogin.errorView}>
              {error && <Text style={styles.errorText}>{error}</Text>}
            </View>
            <Button
              testID={TenantLogin.submitButton}
              loading={isSubmitting}
              style={[authStyles(theme).button, styles.submitButton]}
              mode="contained"
              disabled={isSubmitting || !tenantAccountname || tenantAccountname.trim().length < 2}
              /* @todo must be awaited */
              onPress={() => void submitTenant()}
              textColor={theme.customColors.textWhite}
            >
              Weiter
            </Button>
          </View>
        </BaseAuthScreen>
      )}
    </KeyboardAvoidingView>
  );
};

export const createStyles = ({ content: { maxWidth }, colors }: AppTheme) =>
  StyleSheet.create({
    image: {
      height: 88 * 1.3,
      width: 112 * 1.3,
      alignSelf: 'center',
      marginTop: 40,
      marginBottom: 60,
    },
    errorText: { color: '#d00', maxWidth },
    submitButton: { marginVertical: 10, width: '100%', maxWidth, alignSelf: 'center' },
    baseAuthScreenContainer: {
      flexDirection: 'column',
      justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
      paddingTop: 50,
      height: '100%',
      width: '100%',
    },
    baseAuthInnerView: {
      alignItems: 'stretch',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    keyboardAvoidingViewStyles: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingBottom: 0,
      width: '100%',
      height: '100%',
    },
    keyboardAvoidingContentStyles: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    formContainer: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '70%',
    },
    companyIdText: { textAlign: 'left', width: '100%', maxWidth, color: colors.onSurface },
  });
