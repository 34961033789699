import { LinkingOptions } from '@react-navigation/native';
import * as ExpoLinking from 'expo-linking';
import { AppNavigatorParamList } from '../router/AppNavigator';

export const createLinkingConfiguration = ():
  | LinkingOptions<AppNavigatorParamList>
  | undefined => ({
  prefixes: [ExpoLinking.createURL('')],
  config: {
    screens: {
      Home: {
        screens: {
          App: {
            screens: {
              Main: {
                screens: {
                  InquiryDetails: 'inquiry/:inquiryId',
                  NewsDetail: 'news/:newsId',
                  ChatRoom: 'chat/:id',
                  Tabs: {
                    screens: {
                      Chats: {
                        screens: {
                          ChatHomeScreen: 'chats',
                        },
                      },
                      Menu: {
                        screens: {
                          MenuScreen: 'menu',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
