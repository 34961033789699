import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Platform,
  StyleSheet,
  KeyboardAvoidingView,
  SectionList,
  ScrollView,
  View,
} from 'react-native';
import { Appbar, Divider } from 'react-native-paper';
import {
  ChatRoomType,
  InquiryStatus,
  useGetInquiryDetailsQuery,
} from '../../../graphql/operations';
import { InquiryDetails } from '../../features/inquiries/InquiryDetails/InquiryDetails';
import { StatusDropdown } from '../../features/inquiries/StatusDropdown/StatusDropdown';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { NoDataRefresh } from '../../components/Common/Loading/NoDataRefresh';
import Animated, {
  AnimatedStyleProp,
  SlideInUp,
  SlideOutDown,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import { useIsKeyboardVisible } from '../../hooks/useIsKeyboardVisible/useIsKeyboardVisible';
import { InquiryMessageActions } from '../../features/inquiries/InquiryMessageActions/InquiryMessageActions';
import { useInquiryDetailsMutations } from './useInquiryDetailsMutations';
import { Popup } from '../../components/Popup';
import { useShowErrorOnPromiseFalse } from '../../hooks';
import { ChatMessageFactory } from '../../features/chat/components/ChatMessageFactory';
import { useRefreshControlWithTimeout } from '../../hooks/list/useRefreshControlWithTimeout';
import { getRecipientName } from '../../utils/getRecipientName';
import { useInquiryMessageActions } from './hooks/useInquiryMessageActions';
import { UploadType } from '../../components/Common/FileUpload/types';
import { AttachmentPickerResultItem } from '../../components/Common/Media/types';
import { useStore } from '../../stores/store';
import { extendInquiryMessage } from '../../features/inquiries/utils/extendInquiryMessage';
import { groupBy } from '../../utils/groupBy';
import { determineChatDay } from '../../features/chat/utils/determineChatDay';
import { ChatSystemMessage } from '../../features/chat/components/ChatSystemMessage';
import { ChatRoomShimmer } from '../../features/chat/components/ChatRoomShimmer';
import { useImageViewerModal } from '../../components/Common/Modals/hooks/useImageViewerModal';
import { ChatDay } from '../../features/chat/components/ChatDay';
import { ImageViewerModal } from '../../components/Common/Modals/ImageViewerModal';
import { ChatToolbar } from '../../features/chat/components/ChatToolbar/ChatToolbar';
import { AppBar } from '../../components/AppBar/AppBar';
import { DiscardDialog } from '../../components/Dialog/DiscardDialog';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { useMediaCaching } from '../../hooks/useMediaCaching/useMediaCaching';

const KeyboardAvoidingViewIos = ({ children }: { children: React.ReactNode }) =>
  Platform.OS === 'ios' ? (
    <KeyboardAvoidingView contentContainerStyle={{ flex: 1 }} style={{ flex: 1 }} behavior="height">
      {children}
    </KeyboardAvoidingView>
  ) : (
    <>{children}</>
  );

type InquiryDetailScreenProps = StackScreenProps<AppNavigatorParamList, 'InquiryDetails'>;

export const InquiryDetailsScreen = ({ navigation, route }: InquiryDetailScreenProps) => {
  const theme = useAppTheme();
  const { isKeyboardVisible } = useIsKeyboardVisible();
  const inquiryId = route.params.inquiryId;
  const userProfile = useStore(s => s.userProfile);

  const { isRefreshing: isRefreshingInquiryDetails, onRefresh: refreshInquiryDetails } =
    useRefreshControlWithTimeout({
      asyncFunctionToAwaitFor: () => inquiryDetails.refetch(),
      errorMessageToLog: 'cannot fetch inquiry details, timeout',
    });

  const { doMarkAsRead, isMutating, sendMediaMessage, sendMessage, updateInquiry } =
    useInquiryDetailsMutations(inquiryId, refreshInquiryDetails);
  const inquiryDetails = useGetInquiryDetailsQuery({ input: { inquiryId } }, { enabled: false });
  const inquiryDetailsData = inquiryDetails.data?.inquiryDetails;
  const isInquiryResolved = inquiryDetailsData?.status === InquiryStatus.Resolved;
  const [isConfirmStatusChangeVisible, setConfirmStatusChangeVisible] = useState(false);
  const [status, setStatus] = useState<InquiryStatus>(InquiryStatus.Open);

  const styles = useMemo(
    () => createStyles({ ...theme, isKeyboardVisible, isInquiryResolved }),
    [theme, isKeyboardVisible, isInquiryResolved],
  );

  const { showErrorOnPromiseFalse, errorPopup } = useShowErrorOnPromiseFalse();
  const {
    handleInquiryMessageDeletion,
    showActionSheet,
    isDeleteConfirmModalVisible,
    setDeleteConfirmModalVisible,
  } = useInquiryMessageActions({
    onSuccessMessageDeletion: () => void refreshInquiryDetails(),
  });

  const rawMessages = inquiryDetailsData?.messages;

  const messages = useMemo(() => {
    const extendedMessages = rawMessages?.map(extendInquiryMessage) ?? [];
    const messagesByDay = groupBy(extendedMessages, message => determineChatDay(message.createdAt));
    return Object.entries(messagesByDay).map(([key, value]) => ({
      day: key,
      data: value,
    }));
  }, [rawMessages]);

  useEffect(() => {
    if (inquiryDetailsData && inquiryDetailsData.status !== status) {
      setStatus(inquiryDetailsData.status);
    }
  }, [inquiryDetailsData?.status]);

  useEffect(() => {
    if (inquiryDetailsData?.hasUnreadContent) {
      void doMarkAsRead();
    }
  }, [inquiryDetailsData?.hasUnreadContent]);

  const onStatusChangeCancel = () => {
    setConfirmStatusChangeVisible(false);
    if (inquiryDetailsData) {
      setStatus(inquiryDetailsData.status);
    }
  };

  const onSend = (messageTexts: string[]) => {
    void showErrorOnPromiseFalse(
      sendMessage(messageTexts),
      'Leider konnte die Nachricht nicht versendet werden',
    );
  };

  const onRequestStatusChange = (newStatus: string) => {
    if (newStatus !== inquiryDetailsData?.status) {
      setConfirmStatusChangeVisible(true);
    }
  };

  const onSubmitMediaMessage = async (items: AttachmentPickerResultItem[]) => {
    await showErrorOnPromiseFalse(
      sendMediaMessage(items),
      'Leider konnte die Nachricht nicht versendet werden',
    );
  };

  const onStatusChangeConfirm = async () => {
    setConfirmStatusChangeVisible(false);
    await showErrorOnPromiseFalse(
      updateInquiry(status),
      'Leider konnte die Nachricht nicht versendet werden',
    );
  };

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const onPressChatMessage = useCallback((imageSource?: string) => {
    if (imageSource) {
      setImageViewerModalImages([{ url: imageSource }]);
      setImageViewerModalVisible(true);
    }
  }, []);

  useEffect(() => {
    void refreshInquiryDetails();
  }, []);

  const scrollRef = useRef<ScrollView>(null);

  useEffect(() => {
    setTimeout(() => scrollRef.current?.scrollToEnd({ animated: false }));
  }, []);

  const animatedStyles = useAnimatedStyle<AnimatedStyleProp<any>>(() => {
    return {
      transform: [
        {
          translateY: withSpring(isKeyboardVisible ? -120 : 0),
        },
      ],
    };
  }, [isKeyboardVisible]);

  const { canDownload } = useMediaCaching();
  const isDownloadConfirmationNeeded = useMemo(() => !canDownload, [canDownload]);

  if (!inquiryDetailsData) {
    return (
      <NoDataRefresh
        isLoading={isRefreshingInquiryDetails}
        onPress={refreshInquiryDetails}
        text="Die Details konnten nicht geladen weren. Bitte versuchen Sie es erneut."
      />
    );
  }

  return (
    <>
      <View style={styles.appBarContainer}>
        <AppBar
          title="Anfrage"
          renderActionsLeftOfTitle={() => (
            <Appbar.BackAction
              onPress={() =>
                navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Home')
              }
              color={theme.customColors.icon}
            />
          )}
        />
      </View>
      <KeyboardAvoidingViewIos>
        <Animated.View
          entering={SlideInUp}
          exiting={SlideOutDown}
          style={[animatedStyles, styles.inquiryDetailsContainer]}
        >
          <InquiryDetails inquiry={inquiryDetailsData} />
          {inquiryDetailsData.status === InquiryStatus.Open && (
            <>
              <Divider />
              <StatusDropdown
                value={status}
                setValue={setStatus}
                onChangeValue={onRequestStatusChange}
              />
            </>
          )}
        </Animated.View>
        {inquiryDetails.isLoading || !userProfile ? (
          <ScrollView ref={scrollRef}>
            <ChatRoomShimmer chatRoomType={ChatRoomType.Group} />
          </ScrollView>
        ) : (
          <SectionList
            inverted
            disableVirtualization={Platform.OS === 'web'}
            sections={messages}
            //contentContainerStyle={styles.sectionListContentContainer}
            style={styles.sectionList}
            renderItem={({ item: message, index, section: { data: messagesOfDay } }) => {
              if (message.system) {
                return <ChatSystemMessage message={message} />;
              }
              const nextMessage = index === 0 ? undefined : messagesOfDay[index - 1];
              const previousMessage =
                index === messages.length - 1 ? undefined : messagesOfDay[index + 1];
              return (
                <ChatMessageFactory
                  type={UploadType.InquiryMessage}
                  key={message._id}
                  message={message}
                  onPress={onPressChatMessage}
                  onLongPress={showActionSheet}
                  isAvatarOrNameOfOtherUserShown={true}
                  isReceivedTickShown={false}
                  isFromAppUser={message.user._id === userProfile.userId}
                  isNextMessageFromSameUser={nextMessage?.user._id === message.user._id}
                  isPreviousMessageFromSameUser={previousMessage?.user._id === message.user._id}
                  isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
                />
              );
            }}
            renderSectionFooter={({ section: { day } }) => <ChatDay day={day} />}
            keyExtractor={item => item._id.toString()}
          />
        )}
        {!isInquiryResolved && (
          <ChatToolbar
            disabled={isMutating || inquiryDetails.isLoading}
            onSend={messageText => onSend([messageText])}
            renderActions={() => (
              <InquiryMessageActions
                onSubmit={items => onSubmitMediaMessage(items)}
                recipientName={getRecipientName(inquiryDetailsData)}
                uploadType={UploadType.InquiryMessage}
                disabled={inquiryDetails.isLoading}
              />
            )}
          />
        )}
        <ImageViewerModal
          visible={imageViewerModalVisible}
          setVisible={setImageViewerModalVisible}
          activeIndex={0}
          imageUrls={imageViewerModalImages}
        />
        <DiscardDialog
          isOpen={isDeleteConfirmModalVisible}
          title="Sind Sie sicher?"
          content="Möchten Sie die Nachricht wirklich löschen?"
          onCancel={() => setDeleteConfirmModalVisible(false)}
          onDiscard={handleInquiryMessageDeletion}
          discardButtonLabel="Löschen"
        />
        <ConfirmDialog
          title="Status ändern"
          content="Wollen Sie den Status der Anfrage ändern?"
          onCancel={onStatusChangeCancel}
          onConfirm={onStatusChangeConfirm}
          confirmButtonLabel="Ändern"
          isOpen={isConfirmStatusChangeVisible}
        />
        <Popup {...errorPopup.popup} />
      </KeyboardAvoidingViewIos>
    </>
  );
};

const getDetailsContainerHeight = (isKeyboardVisible: boolean, isInquiryResolved: boolean) => {
  if (isKeyboardVisible) {
    return 0;
  }
  if (isInquiryResolved) {
    return 240;
  }
  return 270;
};

const createStyles = ({
  isKeyboardVisible,
  isInquiryResolved,
  customColors: { error, borders },
  colors: { background },
}: AppTheme & {
  isKeyboardVisible: boolean;
  isInquiryResolved: boolean;
}) =>
  StyleSheet.create({
    sectionList: { flex: 1, backgroundColor: background },
    sectionListContentContainer: { paddingTop: isInquiryResolved ? 36 : 0 },
    inquiryDetailsContainer: {
      height: getDetailsContainerHeight(isKeyboardVisible, isInquiryResolved),
      backgroundColor: background,
      paddingHorizontal: 15,
      zIndex: 99,
      borderBottomColor: borders,
      borderBottomWidth: 1,
    },
    error: {
      color: error,
      marginTop: 3,
    },
    appBarContainer: { zIndex: 1 },
  });
