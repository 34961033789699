import { Dimensions, ScrollView, View } from 'react-native';
import React, { useEffect } from 'react';

import Markdown from 'react-native-markdown-display';
import { MdStyles } from './MarkdownStyles';
import { themeProp } from '../../types/main';
import { useLegal } from '../../hooks/common/legal';
import { Appbar, useTheme } from 'react-native-paper';
import { AppBar } from '../../components/AppBar/AppBar';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';

export const ConsentScreen = () => {
  const { consent = '', fetchConsent } = useLegal();
  const theme = useTheme() as themeProp;
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Consent'>['navigation']>();

  useEffect(() => {
    /* @todo must be awaited */
    void fetchConsent();
  }, []);

  return (
    <>
      <AppBar
        title="Nutzungsbedingungen"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ScrollView
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 16,
          paddingTop: 10,
          backgroundColor: theme.colors.background,
          height: Dimensions.get('window').height,
        }}
      >
        <Markdown style={MdStyles(theme)} mergeStyle>
          {consent}
        </Markdown>
        <View style={{ height: 20 }} />
      </ScrollView>
    </>
  );
};
