import * as Linking from 'expo-linking';

interface IOpenFileUri {
  uri: string;
}

export const openFileUri = async ({ uri }: IOpenFileUri) => {
  try {
    await Linking.openURL(uri);
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
