import { StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableRipple, useTheme, Text } from 'react-native-paper';

import React, { useMemo } from 'react';
import { themeProp } from '../../../types/main';
import { HelpCategories } from '../types';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';

type HelpButtonProps = {
  category: HelpCategories;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
};

export const HelpButton = (props: HelpButtonProps) => {
  const theme = useTheme() as themeProp;
  const styles = useMemo(() => createStyles(theme), [theme]);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  return (
    <TouchableRipple
      onPress={() => navigation.navigate('HelpForm', { category: props.category })}
      style={styles.container}
      rippleColor={theme.customColors.lightRipple}
    >
      <>
        <MaterialCommunityIcons name={props.icon} size={45} color={theme.colors.primary} />
        <Text style={styles.category}>{props.category}</Text>
      </>
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      paddingVertical: 15,
      paddingHorizontal: 10,
      marginHorizontal: 5,
      marginVertical: 10,
      backgroundColor: theme.colors.background,
      borderRadius: theme.roundness,
      width: 86,
      borderColor: '#000',
      borderWidth: 1,
    },
    category: {
      textAlign: 'center',
      marginTop: 10,
    },
  });
