import uuid from 'react-native-uuid';
import { Comment, UserProfile } from '../../../../graphql/operations';

export const addFleetingCommentToComments = (
  sender: UserProfile,
  content: string,
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>,
  comments: Comment[],
) => {
  const comment: Comment = {
    author: {
      userId: sender.userId,
      userType: sender.userType,
      firstname: sender.firstname,
      lastname: sender.lastname,
      picture: sender.picture,
      pictureOTC: sender.pictureOTC,
    },
    id: `fleeting-${uuid.v4()}`,
    content: content,
    createdAt: new Date(),
    updatedAt: new Date(),
    replies: [],
  };
  setComments([comment, ...comments]);
};
