import { Log } from '../../utils/Log';
import appConfig from '../../../app.config';

import {
  MobileOperatingSystem,
  useCreatePushNotificationDeviceTokenMutation,
} from '../../../graphql/operations';

export const uploadPushToken = async (
  deviceToken: string,
  mobileOperatingSystem: MobileOperatingSystem,
) => {
  try {
    await useCreatePushNotificationDeviceTokenMutation.fetcher({
      input: {
        deviceToken,
        mobileOperatingSystem,
        appName: appConfig.expo.extra?.appName,
      },
    })();
  } catch (e) {
    Log.error(e, {
      message: 'an error occurred while uploading push notification device token',
    });
    throw new Error('cannot upload push notification token');
  }
};
