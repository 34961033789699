import React from 'react';
import { Image, ImageProps, Platform } from 'react-native';

export const AutoHeightImage = React.memo(
  ({
    width,
    maxHeight,
    initialHeight,
    style = {},
    ...props
  }: ImageProps & { width: any; maxHeight?: number; initialHeight?: number }) => {
    const imgElement = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = React.useState(initialHeight ?? 1);
    const heightLimit = maxHeight ?? Infinity;
    const isOnWeb = Platform.OS === 'web';
    return (
      <Image
        {...props}
        style={[{ height, width }, style]}
        // @ts-ignore
        ref={imgElement}
        onLoad={event => {
          if (isOnWeb && imgElement) {
            const img = imgElement.current?.getElementsByTagName('img')[0];
            if (!img) {
              return;
            }
            const naturalHeight = img.naturalHeight;
            const naturalWidth = img.naturalWidth;
            const desiredHeight = (naturalHeight / naturalWidth) * width;
            setHeight(desiredHeight > heightLimit ? heightLimit : desiredHeight);
          }
          if (!isOnWeb && !initialHeight) {
            const desiredHeight =
              (event.nativeEvent.source.height / event.nativeEvent.source.width) * width;
            setHeight(desiredHeight > heightLimit ? heightLimit : desiredHeight);
          }
        }}
      />
    );
  },
);
