import React, { useEffect, useState } from 'react';
import { RefreshControl, SectionList, StyleSheet, Text } from 'react-native';
import { ShiftListViewCategory, EnrichedShiftData } from '../types';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { NoDataRefresh } from '../../../../components/Common/Loading/NoDataRefresh';
import { ScheduleListViewItem } from './ScheduleListViewItem';
import { themeProp } from '../../../../types/main';
import { useTheme } from 'react-native-paper';
import uuid from 'react-native-uuid';

type ScheduleListViewProps = {
  data: ShiftListViewCategory[] | undefined;
  token: string;
  reload: () => void;
};

export const ScheduleListView = (props: ScheduleListViewProps | undefined) => {
  const [refreshing, setRefreshing] = useState(true);

  const theme = useTheme() as themeProp;
  const styles = createStyles(theme);

  useEffect(() => {
    /* @todo must be awaited */
    void onRefresh();
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    props?.reload();
    setRefreshing(false);
  };

  if (!props?.data || props.data.length <= 0) {
    return (
      <NoDataRefresh
        text={'Es konnte leider kein Dienstplan gefunden werden'}
        onPress={() => {
          /* @todo must be awaited */
          void onRefresh();
        }}
        isLoading={refreshing}
      />
    );
  }

  const renderItem = (renderProps: { item: EnrichedShiftData }) => {
    return <ScheduleListViewItem item={renderProps.item} token={props.token} />;
  };

  return (
    <>
      <KeyboardAwareScrollView style={{ width: '100%' }}>
        <SectionList
          sections={props.data}
          renderItem={renderItem}
          keyExtractor={() => uuid.v4().toString()}
          renderSectionHeader={({ section: { title } }) => (
            <Text style={styles.sectionHeader}>{title}</Text>
          )}
          /* @todo must be awaited */
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => void onRefresh()}
              tintColor={theme.customColors.refreshControlSpinner}
            />
          }
        />
      </KeyboardAwareScrollView>
    </>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    sectionHeader: {
      flex: 1,
      fontWeight: '500',
      color: theme.customColors.text,
      fontSize: 18,
      textAlign: 'left',
      marginVertical: 5,
      paddingLeft: 8,
    },
  });
