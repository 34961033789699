import { preventScreenCaptureAsync } from 'expo-screen-capture';
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { Log } from '../../utils/Log';
import { useStore } from '../../stores/store';

const ALLOWED_TENANT_NAMES = ['dev', 'demo'];

export const usePreventScreenCapture = (userId?: string) => {
  const tenantName = useStore(s => s.tenantName);

  useEffect(() => {
    if (!tenantName) {
      return;
    }
    if (Platform.OS === 'web') {
      return;
    }
    if (ALLOWED_TENANT_NAMES.includes(tenantName)) {
      return;
    }
    /* @todo must be awaited */
    void (async function prevent() {
      try {
        await preventScreenCaptureAsync();
      } catch (e) {
        Log.error(e, { data: { userId }, message: 'cannot prevent screen capture' });
      }
    })();
  }, [userId]);
};
