import { Button } from 'react-native-paper';
import { StyleSheet, Text, View } from 'react-native';

import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { UserProfile } from '../../../../graphql/operations';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type BlockedPropTypes = {
  userId: UserProfile['userId'];
};

export const Blocked = ({ userId }: BlockedPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyle(theme), [theme]);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <Icon
          name={'eye-off-outline'}
          style={styles.icon}
          size={40}
          color={theme.customColors.gray50}
        />
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            Sie können diesen Inhalt nicht sehen, weil Sie diesen Benutzer blockiert haben.
          </Text>
        </View>
        <Button
          onPress={() => {
            navigation.navigate('Profile', { profileId: userId });
          }}
          style={styles.button}
          uppercase={false}
          labelStyle={styles.buttonText}
          textColor={theme.customColors.textWhite}
        >
          Benutzer Ansehen
        </Button>
      </View>
    </View>
  );
};

const createStyle = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      flex: 1,
      padding: 20,
    },
    inner: {
      paddingHorizontal: '15%',
      paddingVertical: 30,
      width: '100%',
      height: '100%',
      backgroundColor: theme.customColors.background2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: { marginBottom: 12 },
    textContainer: {
      width: '100%',
      flex: 1,
      flexWrap: 'wrap',
    },
    text: {
      width: '100%',
      color: theme.customColors.text,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
    buttonContainer: {
      width: '100%',
      height: 50,
    },
    button: {
      borderRadius: 5,
      width: '100%',
      backgroundColor: theme.customColors.primary,
    },
    buttonText: {
      fontWeight: 'bold',
      fontSize: 15,
      margin: 0,
    },
  });
