import { QueryClient } from '@tanstack/react-query';
import { News } from '../../../graphql/operations';
import { prependNewsToQueryCache } from '../../features/news/utils/prependNewsToQueryCache';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import { Log } from '../../utils/Log';
import { Constants } from '../../utils';

export const createNewsListener =
  (queryClient: QueryClient, appUserId: string) => async (news: News) => {
    prependNewsToQueryCache({ news, queryClient });
    if (Platform.OS === 'web' || news.author?.userId === appUserId) {
      return;
    }
    try {
      await Notifications.scheduleNotificationAsync({
        content: {
          title: `Neuer News-Beitrag 🗞️`,
          body: `${news.title}${
            news.readConfirmationResult && !news.readConfirmationResult.readByUser
              ? ` – Bitte um Lesebestätigung`
              : ``
          }`,
          data: {
            experienceId: '@likwidcare-org/luci',
            linking: 'luci://news/',
          },
        },
        trigger: null,
        identifier: news.id,
      });
      setTimeout(async () => {
        await Notifications.dismissNotificationAsync(news.id);
      }, Constants.DISMISS_NOTIFICATIONS_TIMEOUT);
    } catch (e) {
      const err = e as Error;
      Log.error(e, {
        message: `an error occurred while sending local notification: ${err.message} `,
      });
    }
  };
