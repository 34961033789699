import React from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { CheckInboxScreenAbstract } from './CheckInboxScreenAbstract';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { AppBar } from '../../components/AppBar/AppBar';
import { Appbar } from 'react-native-paper';

type CheckInboxScreenProps = StackScreenProps<AppNavigatorParamList, 'CheckInbox'>;

export const CheckInboxScreen = (props: CheckInboxScreenProps) => {
  const action = props.route.params.action;

  const handleContinue = () => {
    if (action === 'RESET_REQUIRED') {
      props.navigation.navigate('ConfirmationCode', props.route.params);
    }
    if (action === 'FORCE_CHANGE_PASSWORD') {
      props.navigation.navigate('ConfirmationCode', props.route.params);
    }
  };

  return (
    <>
      <AppBar
        title={'Bestätigungscode'}
        renderActionsLeftOfTitle={() => (
          <Appbar.BackAction onPress={() => props.navigation.goBack()} />
        )}
      />
      <CheckInboxScreenAbstract
        onPressContinue={handleContinue}
        channel={props.route.params.channel}
      />
    </>
  );
};
