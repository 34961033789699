import { ExceptionCode } from '../../components/Auth/types/ExceptionCode';
import { Log } from '../Log';

export const getCognitoError = (error: string) => {
  switch (error) {
    case ExceptionCode.LimitExceededException:
      return {
        title: 'Versuchslimit überschritten',
        text: 'Bitte versuchen Sie es nach einiger Zeit erneut.',
      };
    case ExceptionCode.CodeDeliveryFailureException:
      return {
        title: 'Code nicht zugestellt',
        text: 'Der Bestätigungscode konnte nicht zugestellt werden.',
      };
    default:
      Log.error(`unknown forgot password error: ${error}`);
      return {
        title: 'Fehler',
        text: 'Es ist ein unbekannter Fehler aufgetreten',
      };
  }
};
