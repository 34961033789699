import { useQueryClient } from '@tanstack/react-query';
import {
  ChatRoomMessage,
  Feature,
  useCreateChatRoomMessageMutation,
} from '../../../../../graphql/operations';
import { SelectedForm, SelectedInfoPage } from '../types';
import { invalidateChatRoomsQuery } from '../../../../features/chat/utils/invalidateChatRoomsQuery';
import { getSelectedFormOrInfoPageId } from '../utils/getSelectedFormOrInfoPageId';
import { getSelectedFormOrInfoPageTitle } from '../utils/getSelectedFormOrInfoPageTitle';
import { onErrorSendLink } from '../errorHandlers/onErrorSendLink';
import { alert } from '../../../../utils/alert';
import { useStore } from '../../../../stores/store';
import { createPendingChatRoomMessage } from '../../../../features/chat/utils/createPendingChatRoomMessage';
import { useCallback, useState } from 'react';

interface IUseSendLink {
  chatRoomId: string;
  endPendingStateOfMessage?: (messageId: ChatRoomMessage['id']) => void;
  addToMessagesAsPending?: (chatRoomMessage: ChatRoomMessage) => void;
}

export interface ISendLink {
  feature: Feature;
  selectedItems: SelectedForm[] | SelectedInfoPage[];
}

export const useSendLink = ({
  chatRoomId,
  endPendingStateOfMessage,
  addToMessagesAsPending,
}: IUseSendLink) => {
  const sendLinkMutation = useCreateChatRoomMessageMutation();
  const queryClient = useQueryClient();
  const appUser = useStore(s => s.userProfile);
  const [isSending, setSending] = useState(false);

  const sendLink = useCallback(
    async ({ feature, selectedItems }: ISendLink) => {
      if (!appUser) {
        return;
      }
      const content = `Anhänge: ${selectedItems.map(getSelectedFormOrInfoPageTitle).join(', ')}`;
      const formIds =
        feature === Feature.Form ? selectedItems.map(getSelectedFormOrInfoPageId) : undefined;
      const infoPageIds =
        feature === Feature.Infopage ? selectedItems.map(getSelectedFormOrInfoPageId) : undefined;

      let pendingMessage = undefined;

      if (addToMessagesAsPending) {
        pendingMessage = createPendingChatRoomMessage({
          appUser,
          chatRoomId,
          isContainingFormLinks: feature === Feature.Form,
          isContainingInfoPageLinks: feature === Feature.Infopage,
          content,
          infoPagesOrForms: selectedItems,
        });
        addToMessagesAsPending?.(pendingMessage);
      }

      try {
        setSending(true);
        await sendLinkMutation.mutateAsync({
          input: {
            id: pendingMessage?.id,
            chatRoomId,
            content,
            formIds,
            infoPageIds,
          },
        });
        setSending(false);
        if (pendingMessage) {
          endPendingStateOfMessage?.(pendingMessage.id);
        }
        await invalidateChatRoomsQuery({ queryClient });
      } catch (e) {
        onErrorSendLink({
          e,
          onBlocked: () => {
            alert(
              'Nachricht wurde nicht gesendet',
              'Sie können keine Nachrichten von diesem Benutzer empfangen oder an ihn senden.',
            );
          },
        });
      } finally {
        setSending(false);
      }
    },
    [chatRoomId, appUser, addToMessagesAsPending, endPendingStateOfMessage],
  );

  return { sendLink, isSending };
};
