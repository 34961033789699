import React, { memo } from 'react';
import { Linking } from 'react-native';
import { UnavailableFeatureModal } from '../../../../components/Common/Modals/UnavailableFeatureModal';
import { Constants } from '../../../../utils';

type NewsCreationNotAvailableModalProps = {
  visible: boolean;
  setVisible: (isVisible: boolean) => void;
  newsId?: string;
};

export const NewsCreationNotAvailableModal = memo(
  ({ visible, setVisible, newsId }: NewsCreationNotAvailableModalProps) => {
    return (
      <UnavailableFeatureModal
        visible={visible}
        setVisible={setVisible}
        icon="open-in-app"
        text={{
          description:
            'Diese Funktion ist in der Webversion nicht verfügbar. Bitten klicken Sie auf den Knopf unten, um zum Control Center weitergeleitet zu werden.',
          button: 'Control Center',
        }}
        displayButton
        onButtonPress={async () =>
          await Linking.openURL(`${Constants.CC_CREATE_NEWS_LINK}${newsId ? newsId : 'new'}`)
        }
      />
    );
  },
);
