import React, { useEffect } from 'react';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  TranslateNewsPostMutation,
  UserPreference,
  useTranslateNewsPostMutation,
  useUpdateUsersWhoHaveTranslatedMutation,
} from '../../../../graphql/operations';
import { alert } from '../../../utils/alert';
import { updateNewsCacheWithTranslation } from '../utils/updateNewsCacheWithTranslation';
import { Log } from '../../../utils/Log';

type UseNewsTranslationsPropTypes = {
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
  newsId: string;
  originalTitle: string;
  originalContent: string;
  userDidTranslateNews: boolean;
  translationId?: string | null;
  translatedTitle?: string | null;
  translatedContent?: string | null;
  setTranslationSetupPopUpShown: React.Dispatch<React.SetStateAction<boolean>>;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  translationEnabled: UserPreference['translationEnabled'];
  translationLanguage: UserPreference['translationLanguage'];
};

export const useNewsTranslations = ({
  groupIdsInNewsTimelineQuery,
  searchTextInNewsTimeLineQuery,
  newsId,
  originalTitle,
  originalContent,
  userDidTranslateNews,
  translationId,
  translatedTitle,
  translatedContent,
  setTranslationSetupPopUpShown,
  setExpanded,
  translationEnabled,
  translationLanguage,
}: UseNewsTranslationsPropTypes) => {
  const queryClient = useQueryClient();
  const translateNewsMutation = useTranslateNewsPostMutation();
  const updateUsersWhoTranslated = useUpdateUsersWhoHaveTranslatedMutation();
  const [translatableTitle, setTranslatableTitle] = useState<string>(originalTitle);
  const [translatableContent, setTranslatableContent] = useState<string>(originalContent);
  const [isTranslated, setTranslated] = useState(false);
  const [isTranslating, setTranslating] = useState(false);
  const userMustPickALanguage = translationEnabled && !translationLanguage;
  const cachedTranslationExists = translationId && translatedTitle && translatedContent;

  useEffect(() => {
    setTranslatableTitle(originalTitle);
    setTranslatableContent(originalContent);
  }, [originalTitle, originalContent]);

  useEffect(() => {
    if (userDidTranslateNews) {
      setTranslatableTitle(translatedTitle!);
      setTranslatableContent(translatedContent!);
      setTranslated(true);
    } else {
      setTranslatableTitle(originalTitle);
      setTranslatableContent(originalContent);
      setTranslated(false);
    }
  }, [userDidTranslateNews]);

  const onUpdateUsersWhoHaveTranslated = (
    newTitle: string,
    newContent: string,
    translation: TranslateNewsPostMutation['translateNewsPost'],
  ) => {
    updateNewsCacheWithTranslation({
      queryClient,
      groupIdsInNewsTimelineQuery,
      translation,
      newsId,
      searchTextInNewsTimeLineQuery,
    });
    if (isTranslated) {
      setTranslatableTitle(originalTitle);
      setTranslatableContent(originalContent);
    } else {
      setTranslatableTitle(newTitle);
      setTranslatableContent(newContent);
    }
    setTranslated(!isTranslated);
    setTranslating(false);
  };

  const updateUsersWhoHaveTranslated = async (id: string, newTitle: string, newContent: string) => {
    try {
      const updatedTranslation = await updateUsersWhoTranslated.mutateAsync({
        translationId: id,
      });
      onUpdateUsersWhoHaveTranslated(
        newTitle,
        newContent,
        updatedTranslation.updateUsersWhoHaveTranslated,
      );
    } catch (error) {
      Log.error(error, {
        message: 'an error occurred when updating users who have translated the news',
      });
      alert('Übersetzen fehlgeschlagen', 'Bitte versuchen Sie es erneut.');
    }
  };

  const onSuccessTranslateNews = (translation: TranslateNewsPostMutation['translateNewsPost']) => {
    updateNewsCacheWithTranslation({
      queryClient,
      groupIdsInNewsTimelineQuery,
      translation,
      newsId,
      searchTextInNewsTimeLineQuery,
    });
    setTranslated(true);
    setTranslatableTitle(translation.title ?? originalTitle);
    setTranslatableContent(translation.content ?? originalContent);
    setTranslating(false);
  };

  const translateNews = async () => {
    try {
      const translationResult = await translateNewsMutation.mutateAsync({
        newsId: newsId,
      });
      onSuccessTranslateNews(translationResult.translateNewsPost);
    } catch (error) {
      Log.error(error, { message: 'an error occurred when translating the news' });
      alert('Übersetzen fehlgeschlagen', 'Bitte versuchen Sie es erneut.');
    }
  };

  const handleTranslation = async () => {
    if (userMustPickALanguage) {
      setTranslationSetupPopUpShown(true);
    } else {
      setExpanded(true);
      setTranslating(true);
      if (cachedTranslationExists) {
        await updateUsersWhoHaveTranslated(translationId, translatedTitle, translatedContent);
      } else {
        await translateNews();
      }
    }
  };

  return {
    translatableTitle,
    translatableContent,
    isTranslated,
    isTranslating,
    handleTranslation,
  };
};
