import React from 'react';
import { Portal, Text } from 'react-native-paper';
import { FullScreenModal } from '../FullScreenModal';
import { PreviewSwiperFlatlist } from '.';
import { PreviewItem } from './types';
import { LoadingBlocker } from '../Common/Loading/LoadingBlocker';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

export type PreviewOverlayProps = {
  title: string;
  description?: string;
  items: PreviewItem[];
  removeItem: (itemId: string) => void;
  show: boolean;
  onConfirm?(): void;
  onCancel?(): void;
  confirmIcon?: keyof typeof MaterialCommunityIcons.glyphMap;
  isSending?: boolean;
};

export const PreviewOverlay = ({
  title,
  description,
  items,
  removeItem,
  show,
  onCancel,
  onConfirm,
  isSending,
  confirmIcon = 'send',
}: PreviewOverlayProps) => {
  return (
    <FullScreenModal
      show={show}
      title={title}
      onClose={onCancel}
      onSubmit={onConfirm}
      submitIcon={confirmIcon}
    >
      <FullScreenModal.Container>
        {description && (
          <FullScreenModal.Header>
            <Text variant="bodyLarge" numberOfLines={1}>
              {description}
            </Text>
          </FullScreenModal.Header>
        )}
        <FullScreenModal.Content>
          <PreviewSwiperFlatlist items={items} removeItem={removeItem} />
        </FullScreenModal.Content>
      </FullScreenModal.Container>
      <Portal>
        <LoadingBlocker visible={!!isSending} />
      </Portal>
    </FullScreenModal>
  );
};
