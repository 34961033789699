import { ActivityIndicator } from 'react-native-paper';
import { Text, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
//import { themeProp } from '../../types/main';
import { baseTheme as theme } from '../../styles/theme';
import { Provider as PaperProvider } from 'react-native-paper';

export type NoDataRefreshPropTypes = {
  /*
   * Text underneath the icon.
   */
  text: string;
  /*
   * Color of the spinner color.
   */
  spinnerColor?: string;
  /*
   * Toggle that switches between the icon and the activity indicator.
   */
  isLoading?: boolean;
  /*
   * Icon to be displayed.
   */
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  /*
   * Function to call when the icon is pressed.
   */
  onPress?: () => void;
};

/**
 * This component can be used when the query that fetches the data is successful, but there is no data to be displayed (i.e. either undefined and [] is returned for data, and isLoading is false).
 *
 * Do not use it when the query that fetches the data hasn't had a chance to be successful yet (i.e. data is undefined and isLoading is true, usually during the initial mount of the component).
 *
 * For those cases, use either skeletons, spinners, or RefreshControl along with useRefreshControlWithTimeout hook if the data is displayed in a flatlist or a scrollview.
 */
export const NoDataRefresh = (props: NoDataRefreshPropTypes): JSX.Element => {
  return (
    <PaperProvider theme={theme}>
      <View style={{ height: '100%', width: '100%', alignSelf: 'center' }}>
        <View
          style={{
            flex: 10,
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.isLoading ? (
              <ActivityIndicator
                animating={true}
                color={props.spinnerColor ? props.spinnerColor : theme.customColors.green2}
                size="large"
              />
            ) : (
              <MaterialCommunityIcons
                name={props.icon || 'refresh-circle'}
                color={props.spinnerColor ? props.spinnerColor : theme.customColors.green2}
                size={46.5}
                onPress={props.onPress}
              />
            )}
            <Text
              style={{
                color: theme.customColors.textGray,
                fontSize: 16,
                marginTop: 20,
                textAlign: 'center',
                paddingHorizontal: 30,
              }}
            >
              {props.text}
            </Text>
          </View>
        </View>
        <View style={{ flex: 5 }} />
      </View>
    </PaperProvider>
  );
};
