import { ImageURISource } from 'react-native';

export const getUri = (
  source: ImageURISource | string | number,
): { needsRequest: boolean; uri: string | number } | undefined => {
  if (!source) {
    return undefined;
  }
  if (typeof source === 'string') {
    return { uri: source, needsRequest: true };
  }
  if (typeof source === 'number') {
    //it's a static image file: https://reactnative.dev/docs/images#static-image-resources
    return { uri: source, needsRequest: false };
  }
  const uri = source.uri;

  if (!uri) {
    return undefined;
  }
  if (typeof uri === 'string') {
    if (uri.includes('http')) {
      //web resource
      return { uri, needsRequest: false };
    }

    if (uri.includes('file://')) {
      //local resource
      return { uri, needsRequest: false };
    }
    return { uri, needsRequest: true };
  }
  if (typeof uri === 'number') {
    return { uri, needsRequest: false };
  }

  return undefined;
};
