import { isObjectWithProperty } from '../isObjectWithProperty';

export enum ErrorType {
  VerificationCode,
  AttemptLimitExceeded,
  Unknown,
}

type ReturnValue = {
  title: string;
  text: string;
  errorType: ErrorType;
};

export const getConfirmationCodeErrorDetails = (error: Error): ReturnValue => {
  if (
    isObjectWithProperty(error, 'message') &&
    typeof error.message === 'string' &&
    error.message.includes('Invalid verification code provided, please try again')
  ) {
    return {
      title: 'Bestätigungscode ungültig',
      text: 'Der von Ihnen eingegebene Bestätigungscode ist ungültig.',
      errorType: ErrorType.VerificationCode,
    };
  }
  if (
    isObjectWithProperty(error, 'message') &&
    typeof error.message === 'string' &&
    error.message.includes('Attempt limit exceeded, please try after some time')
  ) {
    return {
      title: 'Zu viele Versuche',
      text: 'Bitte versuchen Sie es in ein paar Minuten erneut.',
      errorType: ErrorType.AttemptLimitExceeded,
    };
  }
  return {
    title: 'Leider ist ein unbekannter Fehler aufgetreten',
    text: 'Bitte versuchen Sie es später noch einmal',
    errorType: ErrorType.Unknown,
  };
};
