import { useStore } from '../../../stores/store';
import { mapExtensionToMimeType } from './mapExtensionToMimeType';

type uploadFileType = {
  fileUri: string;
  uploadType: string;
};

type downloadFileType = {
  s3Path: string;
  uploadType: string;
};

export const uploadFile = async ({ fileUri, uploadType }: uploadFileType) => {
  const fileExtension = fileUri.split('.').pop();
  const fileName = fileUri.split('/').pop();
  const fileType = mapExtensionToMimeType(fileExtension);
  if (fileType === 'file type not supported') {
    throw new Error(fileType);
  }
  if (fileType === 'no file extension found') {
    throw new Error(fileType);
  }
  const data = new FormData();
  data.append('uploadType', uploadType);
  data.append('attachment', {
    uri: fileUri,
    type: fileType,
    name: fileName,
  });
  const res = await fetch(process.env.EXPO_PUBLIC_API_URL + 'handleFiles', {
    method: 'POST',
    headers: (await useStore.getState().headers()) as HeadersInit,
    body: data,
  });

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  return json;
};

export const uploadBase64File = async ({ fileUri, uploadType }: uploadFileType) => {
  const data = new FormData();
  data.append('uploadType', uploadType);
  data.append('base64File', fileUri);
  const res = await fetch(process.env.EXPO_PUBLIC_API_URL + 'upload-base64-file', {
    method: 'POST',
    headers: (await useStore.getState().headers()) as HeadersInit,
    body: data,
  });

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  return json;
};

export const downloadFile = async ({ s3Path, uploadType }: downloadFileType) => {
  const res = await fetch(
    process.env.EXPO_PUBLIC_API_URL + `handleFiles?name=${s3Path}&uploadType=${uploadType}`,
    {
      method: 'GET',
      ...{
        headers: { ...(await useStore.getState().headers()), 'Content-Type': 'application/json' },
      },
    },
  );

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json;
};
