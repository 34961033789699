import { useEffect } from 'react';
import { useSocketStore } from '../../stores/SocketStore';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { ChatEvents, ChatMessageCreated } from '@luciapp/socket-contract';
import { useChatStore } from '../../features/chat/Store';
import {
  ChatRoomsQuery,
  CreateChatRoomMessageMutation,
  UnreadMessageCountQuery,
  useInfiniteChatRoomsQuery,
  useUnreadMessageCountQuery,
} from '../../../graphql/operations';
import { updateCountAndLastMessage } from '../../features/chat/utils/updateCountAndLastMessage';
import { useStore } from '../../stores/store';

export const useChatMessagesListener = () => {
  const queryClient = useQueryClient();
  const socketStore = useSocketStore();
  const appUser = useStore(s => s.userProfile);
  const currentChatRoomId = useChatStore(s => s.currentChatRoomId);

  const onCreateMessage = async (message: ChatMessageCreated) => {
    if (message.chatRoomId === currentChatRoomId || message.author?.userId === appUser?.userId) {
      return;
    }
    // @todo: send local push (without remote push)
    const gqlMessage: CreateChatRoomMessageMutation['createChatRoomMessage'] = {
      ...message,
      readByCurrentUser: false,
    } as CreateChatRoomMessageMutation['createChatRoomMessage'];

    queryClient.setQueryData<UnreadMessageCountQuery>(useUnreadMessageCountQuery.getKey(), data => {
      if (data) {
        return {
          unreadMessageCount: data?.unreadMessageCount + 1,
        };
      }
      return data;
    });

    queryClient.setQueryData<InfiniteData<ChatRoomsQuery>>(
      useInfiniteChatRoomsQuery.getKey({ pageInput: { pageSize: 20 } }),
      updateCountAndLastMessage(message.chatRoomId, gqlMessage),
    );
  };

  useEffect(() => {
    if (socketStore.chatSocket && queryClient) {
      socketStore.chatSocket.on(ChatEvents.createMessage, onCreateMessage);
    }

    return () => {
      socketStore.chatSocket?.off(ChatEvents.createMessage, onCreateMessage);
    };
  }, [socketStore.chatSocket, queryClient, onCreateMessage]);
};
