import { List } from 'react-native-paper';
import { ScrollView, StyleSheet, ViewStyle } from 'react-native';
import React, { useMemo } from 'react';
import {
  ListInfoPagesToReadQuery,
  useInfiniteListInfoPagesToReadQuery,
} from '../../../../graphql/operations';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import { useInfoPageCategories } from '../../../hooks/util/useInfoPageCategories';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { Constants } from '../../../utils';

export type InfoPageLinkListAbstractProps = {
  scrollViewContainerStyles?: ViewStyle;
  scrollViewStyles?: ViewStyle;
  renderAccordionItem(
    infoPage: ListInfoPagesToReadQuery['infoPageList']['infoPages'][0],
  ): React.ReactNode;
};

export const InfoPageLinkListAbstract = ({
  scrollViewContainerStyles,
  scrollViewStyles,
  renderAccordionItem,
}: InfoPageLinkListAbstractProps) => {
  const theme = useAppTheme();
  const infoPages = useInfiniteListInfoPagesToReadQuery('pageInput', {
    pageInput: { pageSize: 2000 },
  });
  const styles = useMemo(() => createStyles(theme), [theme]);

  const {
    handleCollapseAccordion,
    isCollapsed,
    categoryCount,
    categories,
    infoPagesGroupedByCategory,
  } = useInfoPageCategories({ infoPages });

  if (infoPages.isLoading || categoryCount === 0 || !infoPages.data) {
    return <NoDataRefresh text="Infoseiten werden geladen..." isLoading={infoPages.isLoading} />;
  }

  if (
    !infoPages.data?.pages[0].infoPageList.infoPages.length ||
    !infoPagesGroupedByCategory ||
    !categories
  ) {
    return (
      <NoDataRefresh
        text="Es konnten leider keine Infoseiten gefunden werden"
        onPress={async () => await infoPages.refetch()}
        isLoading={infoPages.isRefetching}
      />
    );
  }

  return (
    <ScrollView contentContainerStyle={scrollViewContainerStyles} style={scrollViewStyles}>
      <List.Section key={'root'}>
        {categories.map((category, categoryIndex) => {
          return (
            category &&
            Object.keys(infoPagesGroupedByCategory).includes(category) &&
            infoPagesGroupedByCategory[category].length !== 0 && (
              <List.Accordion
                key={category}
                title={
                  category.length > Constants.INFOPAGE_CATEGORY_CHAR_LIMIT
                    ? `${category.slice(0, Constants.INFOPAGE_CATEGORY_CHAR_LIMIT)}...`
                    : category
                }
                titleNumberOfLines={2}
                left={props => (
                  <List.Icon
                    {...props}
                    icon={isCollapsed[categoryIndex] ? 'folder-open' : 'folder'}
                  />
                )}
                expanded={isCollapsed[categoryIndex]}
                onPress={() => handleCollapseAccordion(categoryIndex)}
                testID={`infoPageCategory-${categoryIndex}`}
                style={styles.categoryContainer}
              >
                {infoPagesGroupedByCategory[category].map(renderAccordionItem)}
              </List.Accordion>
            )
          );
        })}
      </List.Section>
    </ScrollView>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    categoryContainer: {
      backgroundColor: theme.customColors.listRowItemBackground,
      paddingRight: 16,
    },
  });
