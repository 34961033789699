import React, { useMemo, useRef } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { themeProp } from '../../types/main';
import { useOrientationChange } from '../../hooks/useOrientationChange/useOrientationChange';
import { PreviewItemFactory } from './PreviewItemFactory';
import { useAppTheme } from '../../styles/theme';
import { PreviewItem } from './types';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';

type PreviewSwiperFlatlistProps = {
  items: PreviewItem[];
  removeItem: (itemId: string) => void;
};

export const PreviewSwiperFlatlist = ({ items, removeItem }: PreviewSwiperFlatlistProps) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);
  const orientation = useOrientationChange();
  const hasMultiplePreviews = items.length > 1;
  const scrollRef = useRef<any>(null);

  return (
    <View style={styles.container}>
      <SwiperFlatList
        key={orientation}
        ref={scrollRef}
        showPagination={hasMultiplePreviews}
        paginationStyle={styles.pagination}
        paginationDefaultColor={theme.customColors.gray20}
        paginationActiveColor={theme.customColors.pureWhite}
        paginationStyleItem={styles.swiperItem}
        paginationStyleItemInactive={styles.swiperItemInactive}
        scrollEnabled={Platform.OS !== 'web'}
        data={items}
        renderItem={({ item }) => (
          <PreviewItemFactory
            item={item}
            isPartOfMultipleItems={items.length > 1}
            removeItem={removeItem}
          />
        )}
      />
    </View>
  );
};

const createStyles = (theme: themeProp, width: number) =>
  StyleSheet.create({
    container: {
      width,
    },
    pagination: {
      bottom: -45,
      height: 12,
    },
    swiperItem: {
      borderWidth: 3,
      borderColor: theme.customColors.gray10,
      width: 12,
      height: 12,
      marginHorizontal: 12,
    },
    swiperItemInactive: {
      width: 12,
      height: 12,
      opacity: 0.5,
    },
  });
