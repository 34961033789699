import { useEffect } from 'react';
import { FormikValues } from 'formik';
import { FormikConfig } from 'formik/dist/types';
import { useDebouncedCallback } from 'use-debounce';

/**
 * copied mostly from here: https://github.com/jaredpalmer/formik/issues/342#issuecomment-1186496555
 */
export function useDebouncedValidate<T extends FormikValues>({
  values,
  validate,
  debounceTime = 200,
}: {
  values: T;
  validate: FormikConfig<T>['validate'];
  debounceTime?: number;
}) {
  const debounceValidate = useDebouncedCallback(validate!, debounceTime);

  useEffect(() => {
    void debounceValidate(values);
  }, [values]);

  useEffect(() => {
    return () => {
      debounceValidate.cancel();
    };
  }, []);
}
