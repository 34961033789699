import { Dimensions, ScrollView, View } from 'react-native';
import React, { useEffect } from 'react';

import AppLoading from 'expo-app-loading';
import Markdown from 'react-native-markdown-display';
import { MdStyles } from './MarkdownStyles';
import { themeProp } from '../../types/main';
import { useLegal } from '../../hooks/common/legal';
import { Appbar, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { AppBar } from '../../components/AppBar/AppBar';

export const PrivacyScreen = () => {
  const { privacy, fetchPrivacy } = useLegal();
  const theme = useTheme() as themeProp;
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Privacy'>['navigation']>();

  useEffect(() => {
    /* @todo must be awaited */
    void fetchPrivacy();
  }, []);

  if (!privacy) {
    return <AppLoading />;
  }

  return (
    <>
      <AppBar
        title="Datenschutz"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ScrollView
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 16,
          paddingTop: 10,
          backgroundColor: theme.colors.background,
          height: Dimensions.get('window').height,
        }}
      >
        <Markdown style={MdStyles(theme)} mergeStyle>
          {privacy}
        </Markdown>
        <View style={{ height: 20 }} />
      </ScrollView>
    </>
  );
};
