import { InfiniteData, QueryClient } from '@tanstack/react-query';
import {
  News,
  NewsToReadByUserGroupQuery,
  PinnedNewsListQuery,
  useInfiniteNewsToReadByUserGroupQuery,
  usePinnedNewsListQuery,
} from '../../../../graphql/operations';
import { prependNewsToQueryCacheUpdater } from './prependNewsToQueryCacheUpdater';
import { useStore } from '../../../stores/store';
import { prependPinnedNewsToQueryCache } from './prependPinnedNewsToQueryCache';

interface IUpdateChatMessagesWithNewMessageWithoutRefetch {
  queryClient: QueryClient;
  news: News;
}

export const prependNewsToQueryCache = ({
  news,
  queryClient,
}: IUpdateChatMessagesWithNewMessageWithoutRefetch) => {
  const userGroupIds = useStore.getState().UserGroupIdFiltersForNews;
  queryClient.setQueryData<InfiniteData<NewsToReadByUserGroupQuery>>(
    useInfiniteNewsToReadByUserGroupQuery.getKey({
      pageInput: { pageSize: 8 },
      userGroupIdsOfNews: news.userGroupIds,
    }),
    data => prependNewsToQueryCacheUpdater({ data, news }),
  );
  if (userGroupIds && userGroupIds.length > 0) {
    queryClient.setQueryData<InfiniteData<NewsToReadByUserGroupQuery>>(
      useInfiniteNewsToReadByUserGroupQuery.getKey({
        pageInput: { pageSize: 8 },
        searchText: undefined,
        userGroupIdsOfNews: userGroupIds,
      }),
      data => prependNewsToQueryCacheUpdater({ data, news }),
    );
  }
  if (news.pinnedAt && userGroupIds) {
    queryClient.setQueryData<PinnedNewsListQuery>(
      usePinnedNewsListQuery.getKey({
        userGroupIds: userGroupIds,
      }),
      data => prependPinnedNewsToQueryCache({ data, news }),
    );
  }
};
