import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Log } from '../../utils/Log';
import { getBiometricData } from '../../utils';
import { useStore } from '../../stores/store';

export const useShouldShowBiometricsPopup = (userMustAgreeToTermsOfService: boolean) => {
  const [hasUserReceivedPopup, setHasUserReceivedPopup] = useState(true);
  const [isEnabledOnDevice, setIsEnabledOnDevice] = useState(false);
  const [isBiometricsUsedForLuciAlready, setIsBiometricsUsedForLuciAlready] = useState(false);
  const userPassword = useStore().getUserPassword();
  const refreshToken = useStore(s => s.refreshToken);

  const determineBiometricsAreAlreadyInUse = async () => {
    try {
      const {
        isEnabled,
        password,
        username,
        hasUserReceivedPopup: hasUserReceivedPopupStoredFlag,
        isEnabledOnDevice: isEnabledOnDeviceStoredFlag,
      } = await getBiometricData();
      setIsBiometricsUsedForLuciAlready(Boolean(username) && Boolean(password) && isEnabled);
      setHasUserReceivedPopup(hasUserReceivedPopupStoredFlag);
      setIsEnabledOnDevice(isEnabledOnDeviceStoredFlag);
    } catch (e) {
      Log.error(e, { message: 'error accessing secure storage to get biometrics data' });
    }
  };

  useEffect(() => {
    void determineBiometricsAreAlreadyInUse();
  }, [userMustAgreeToTermsOfService, refreshToken]);

  return (
    userPassword !== undefined &&
    !hasUserReceivedPopup &&
    isEnabledOnDevice &&
    !userMustAgreeToTermsOfService &&
    !isBiometricsUsedForLuciAlready &&
    Platform.OS !== 'web'
  );
};
