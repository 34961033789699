import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Divider, Text, withTheme } from 'react-native-paper';
import { themeProp } from '../../types/main';

interface HeadlineContainerProps {
  title: string;
  containerStyle?: ViewStyle;
  children: React.ReactNode;
  theme: themeProp;
}

const styles = StyleSheet.create({
  headline: {
    fontSize: 22,
    marginBottom: 4,
  },
  divider: {
    marginHorizontal: 4,
    marginVertical: 8,
  },
  content: {
    marginVertical: 8,
  },
});

const HeadlineContainer = (props: HeadlineContainerProps) => {
  const { theme } = props;

  return (
    <View
      style={[
        {
          margin: 10,
          paddingVertical: 30,
          paddingHorizontal: 30,
          borderRadius: theme.roundness,
          backgroundColor: theme.colors.background,
        },
        props.containerStyle,
      ]}
    >
      <Text style={styles.headline}>{props.title}</Text>
      <Divider style={styles.divider} />
      <View style={styles.content}>{props.children}</View>
    </View>
  );
};

// eslint-disable-next-line no-restricted-syntax
export default withTheme(HeadlineContainer);
