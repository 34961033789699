import React from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton, TouchableRipple, useTheme, Text } from 'react-native-paper';
import { IFormListItem } from '../types';
import { themeProp } from '../../../types/main';
import { useNavigation } from '@react-navigation/native';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { StackNavigationProp } from '@react-navigation/stack';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import moment from 'moment';

export const FormListItem: React.FC<IFormListItem> = (props): JSX.Element => {
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const theme = useTheme() as themeProp;
  const style = createStyles(theme);

  const handlePress = () => {
    if (props.item.id) {
      navigation.navigate('FormDetail', { formId: props.item.id });
    }
  };

  if (!props.item) {
    return <></>;
  }

  return (
    <TouchableRipple
      onPress={handlePress}
      style={style.container}
      rippleColor={theme.customColors.listRowItemBackground}
    >
      <>
        <View style={style.iconWrapper}>
          <Icon name="format-list-checks" size={30} style={style.icon} />
        </View>
        <View style={style.textWrapper}>
          <Text style={style.text}>{props.item.title}</Text>
          <Text>{moment(props.item.updatedAt, 'YYYY-MM-DD').format('LL')}</Text>
        </View>
        <IconButton
          style={{ margin: 0, padding: 0, marginRight: 10 }}
          icon="chevron-right"
          size={30}
          iconColor={theme.customColors.gray50}
        />
      </>
    </TouchableRipple>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      paddingVertical: 12,
      marginTop: 12,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.customColors.listRowItemBackground,
      borderRadius: theme.roundness,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      width: '100%',
    },
    iconWrapper: {
      marginLeft: 10,
      marginRight: 14,
    },
    icon: {
      padding: 8,
      color: theme.customColors.primary,
    },
    textWrapper: {
      flex: 1,
      paddingRight: 8,
    },
    text: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.customColors.textDark,
    },
    description: {
      marginTop: 2,
      fontSize: 13,
      color: theme.customColors.gray40,
    },
  });
