import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { Headline, Text } from 'react-native-paper';

import { useMemo } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type EmptyCardPropTypes = {
  iconName: keyof typeof MaterialCommunityIcons.glyphMap;
  headlineText: string;
  messageText: string;
};

export const EmptyCard = ({ iconName, headlineText, messageText }: EmptyCardPropTypes) => {
  const theme = useAppTheme();
  const { height } = useWindowDimensions();
  const styles = useMemo(() => createStyles(theme, height), [theme, height]);

  return (
    <View style={styles.container}>
      <View style={styles.items}>
        <View>
          <MaterialCommunityIcons
            name={iconName}
            color={theme.colors.onSurface}
            style={styles.icon}
          />
        </View>
        <Headline style={styles.headline}>{headlineText}</Headline>
        <View style={styles.message}>
          <Text>{messageText}</Text>
        </View>
      </View>
    </View>
  );
};

const createStyles = (theme: AppTheme, height: number) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: height,
      backgroundColor: theme.colors.background,
    },
    items: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      borderRadius: theme.roundness,
    },
    message: {
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 10,
      marginVertical: 30,
      backgroundColor: theme.colors.background,
      width: '80%',
      borderRadius: theme.roundness,
    },
    icon: {
      fontSize: 150,
    },
    headline: {
      color: theme.colors.onSurface,
    },
  });
