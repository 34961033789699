import { IconButton } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { themeProp } from '../../types/main';
import { useAppTheme } from '../../styles/theme';

type floatingActionButtonProps = {
  onPress: () => void;
  testId?: string;
};

export const FloatingActionButton = ({ onPress, testId }: floatingActionButtonProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <IconButton
      icon="plus"
      iconColor="white"
      testID={testId}
      onPress={onPress}
      style={styles.floatingActionButton}
    />
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    floatingActionButton: {
      position: 'absolute',
      right: 16,
      backgroundColor: theme.customColors.primary,
      bottom: 16,
      width: 40,
      height: 40,
      borderRadius: 20,
      elevation: 6,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.5,
      shadowRadius: 3,
    },
  });
