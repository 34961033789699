import { stockImageApi } from '@luciapp/stock-image-api';
import { useState } from 'react';
import { Log } from '../../../../utils/Log';
import { useGetStockImageApiToken } from './useGetStockImageApiToken';

export const useStockImageApi = () => {
  const [nextPageString, setNextPageString] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isError, setIsError] = useState(false);
  const { getToken } = useGetStockImageApiToken();

  const getPage = async (isInitialPage?: boolean) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const token = await getToken();
      const page = await stockImageApi.getPageForList({
        apiToken: token,
        nextPageUrl: !isInitialPage ? nextPageString : undefined,
      });
      if ('status' in page) {
        setIsError(true);
        throw new Error(`${page.status}: ${page.statusText}`);
      }
      if ('nextPage' in page && page.nextPage) {
        setNextPageString(page.nextPage);
        setIsLastPage(false);
      } else {
        setIsLastPage(true);
      }
      return page;
    } catch (error) {
      Log.error(error);
      Log.error('could not retrieve page of stockImages');
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialSearchPage = async (searchString: string) => {
    setIsError(false);
    setIsLoading(true);
    try {
      setNextPageString(undefined);
      const token = await getToken();
      const page = await stockImageApi.getPageForSearch({
        apiToken: token,
        searchValue: searchString,
      });
      if ('status' in page) {
        setIsError(true);
        throw new Error(`${page.status}: ${page.statusText}`);
      }
      if ('nextPage' in page && page.nextPage) {
        setNextPageString(page.nextPage);
        setIsLastPage(false);
      } else {
        setIsLastPage(true);
      }
      return page;
    } catch (error) {
      Log.error(error);
      Log.error('could not retríeve page of stockImages for search');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getPage,
    getInitialSearchPage,
    isLoading,
    isLastPage,
    isError,
  };
};
