import React from 'react';
import { TextInput, TextInputProps } from 'react-native-paper';
import { useAppTheme } from '../../../styles/theme';

export const ProfileInput = (itemProps: TextInputProps) => {
  const theme = useAppTheme();

  return (
    <TextInput
      {...itemProps}
      dense
      value={itemProps.value || ''}
      mode="flat"
      error={!!itemProps.error}
      selectionColor={theme.customColors.selection}
      multiline={!!itemProps.multiline}
      numberOfLines={itemProps.numberOfLines || 1}
      placeholder={itemProps.placeholder || ''}
      style={{
        fontWeight: '500',
        marginLeft: -12,
        backgroundColor: theme.customColors.textInputBackground,
        color: theme.colors.onSurface,
      }}
    />
  );
};
