import {
  CommentsQuery,
  NewsToReadByUserGroupQuery,
  PinnedNewsListQuery,
  SingleNewsToReadQuery,
  useInfiniteCommentsQuery,
  useInfiniteLikesQuery,
  useInfiniteNewsToReadByUserGroupQuery,
  usePinnedNewsListQuery,
  useSingleNewsToReadQuery,
} from '../../../../graphql/operations';

import { QueryClient } from '@tanstack/react-query';

export const invalidateNewsCache = async ({
  newsId,
  queryClient,
  groupIdsInNewsTimelineQuery,
  searchTextInNewsTimeLineQuery,
}: {
  newsId: string;
  queryClient: QueryClient;
  groupIdsInNewsTimelineQuery: string[];
  searchTextInNewsTimeLineQuery?: string;
}) => {
  await queryClient.invalidateQueries<NewsToReadByUserGroupQuery>(
    useInfiniteNewsToReadByUserGroupQuery.getKey({
      pageInput: { pageSize: 8 },
      userGroupIdsOfNews: groupIdsInNewsTimelineQuery,
      searchText: searchTextInNewsTimeLineQuery,
    }),
  );
  await queryClient.invalidateQueries<SingleNewsToReadQuery>(
    useSingleNewsToReadQuery.getKey({ newsId: newsId }),
  );
  await queryClient.invalidateQueries<PinnedNewsListQuery>(
    usePinnedNewsListQuery.getKey({
      userGroupIds: groupIdsInNewsTimelineQuery,
    }),
  );
};

export const invalidateLikesCache = async ({
  newsId,
  queryClient,
}: {
  newsId: string;
  queryClient: QueryClient;
}) => {
  await queryClient.invalidateQueries(
    useInfiniteLikesQuery.getKey({ pageInput: { pageSize: 10 }, newsId: newsId }),
    {
      type: 'inactive',
    },
  );
};

export const invalidateCommentsCache = async ({
  newsId,
  queryClient,
  commentId,
}: {
  newsId: string;
  queryClient: QueryClient;
  commentId?: string;
}) => {
  await queryClient.invalidateQueries<CommentsQuery>(
    useInfiniteCommentsQuery.getKey({
      pageInput: { pageSize: 8 },
      newsId: newsId,
    }),
    {
      refetchPage: commentId
        ? page => !!page.comments.comments.find(comment => comment.id === commentId)
        : undefined,
    },
  );
};
