import { useState } from 'react';
import { Log } from '../../utils/Log';
import { downloadFileFromUrl } from '../../utils';
import { toast } from '../../utils/toast/toast';

export interface IDownload {
  fileName: string;
  url: string;
}

export const useDownloadOtcFile = () => {
  const [isDownloading, setDownloading] = useState<boolean>();

  const download = async ({ fileName, url }: IDownload) => {
    setDownloading(true);
    try {
      return await downloadFileFromUrl({ fileName, url });
    } catch (error) {
      toast('Die Datei kann nicht heruntergeladen werden.');
      const message = 'an error occurred while downloading a remote file to local path';
      Log.error(error, {
        message,
      });
      throw new Error(message);
    } finally {
      setDownloading(false);
    }
  };

  return {
    isDownloading,
    download,
  };
};
