import { ContactsHomeScreenProps } from './types';
import React from 'react';
import { UserList } from '../components/UserList';
import { useNavigation } from '@react-navigation/native';

export const ContactsScreen: React.FC = (): JSX.Element => {
  const navigation = useNavigation<ContactsHomeScreenProps['navigation']>();
  return (
    <UserList
      onPress={val => navigation.navigate('Profile', { profileId: val.userId })}
      appBarTitle="Kontakte"
    />
  );
};
