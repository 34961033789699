import { StackNavigationProp } from '@react-navigation/stack';
import React, { memo } from 'react';
import { Portal, Text } from 'react-native-paper';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { GenericPopUp } from '../../../stories/Modal/GenericPopUp';
import { useNavigation } from '@react-navigation/native';

type TranslationSetupPopUpPropTypes = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TranslationSetupPopUp = memo(
  ({ visible, setVisible }: TranslationSetupPopUpPropTypes) => {
    const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
    return (
      <Portal>
        <GenericPopUp
          visible={visible}
          headlineTitle="Übersetzung einrichten"
          primaryActionTitle="Einstellungen"
          onDismiss={() => {
            navigation.navigate('Settings');
            setVisible(false);
          }}
          notDismissable
          isDisabled={false}
        >
          <Text>
            Bitte wählen Sie in den Einstellungen eine Sprache aus, um die Übersetzungsfunktionen
            für News zu nutzen.
          </Text>
        </GenericPopUp>
      </Portal>
    );
  },
);
