import { ActivityIndicator, Appbar, Divider } from 'react-native-paper';
import { ScrollView, StyleSheet, Text, View } from 'react-native';

import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { CommonUserHeader } from '../../profiles/components/CommonUserHeader';
import { GroupChatDetailScreenProps } from './types';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useChatRoomToReadQuery } from '../../../../graphql/operations';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../../../stores/store';
import { getAvatarForChatRoom } from '../utils';
import { getFullName } from '../../profiles/utils';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AppBar } from '../../../components/AppBar/AppBar';

export const GroupChatDetailScreen = (props: GroupChatDetailScreenProps) => {
  const theme = useAppTheme();
  const userProfile = useStore(s => s.userProfile);
  const navigation = useNavigation<GroupChatDetailScreenProps['navigation']>();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const chat = useChatRoomToReadQuery({ chatRoomId: props.route.params.chatRoomId });

  const chatRoomImage = useMemo(() => {
    return getAvatarForChatRoom({
      isGroupChat: true,
      chatPartners: undefined,
      userId: userProfile?.userId,
      chatImageUri: chat.data?.chatRoom.groupImagePath,
      name: chat.data?.chatRoom.name,
    });
  }, [chat.data]);

  const [menuOpen, setMenuOpen] = useState(false);

  if (chat.isLoading) {
    return (
      <ActivityIndicator
        size="large"
        animating={true}
        color={theme.customColors.primary}
        style={styles.activityIndicator}
      />
    );
  }

  if (chat.error) {
    return (
      <NoDataRefresh
        text="Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        onPress={() => chat.refetch()}
        isLoading={false}
      />
    );
  }

  return (
    <>
      <AppBar
        // @ts-ignore
        title={props.route.params?.chatRoom?.name ?? 'Chat'}
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ScrollView testID="GroupChatDetailScreen" contentContainerStyle={styles.container}>
        <AvatarDisplay
          avatar={{ initials: chatRoomImage.initials, otcPath: chatRoomImage.uri }}
          size={70}
          type={UploadType.GroupChatImage}
        />
        <Text style={styles.name}>{chat.data?.chatRoom.name}</Text>
        <Text style={styles.description}>
          erstellt am {moment(chat.data?.chatRoom.createdAt, 'YYYY-MM-DD').format('LL')}{' '}
        </Text>
        <Divider style={styles.divider} />
        <Text style={styles.memberSection}>Mitglieder</Text>
        {chat.data?.chatRoom.members?.map(member => (
          <View key={member.userId} style={styles.commonUserHeader}>
            <CommonUserHeader
              userId={member.userId}
              role={
                chat.data.chatRoom.members?.find(user => user.userId === member.userId)?.role ??
                undefined
              }
              ripple={true}
              onPress={() => {
                if (member.userId === userProfile?.userId) {
                  navigation.navigate('ProfileEdit');
                } else {
                  navigation.navigate('Profile', {
                    profileId: member.userId,
                    name: getFullName({ firstname: member.firstname, lastname: member.lastname }),
                  });
                }
              }}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          </View>
        ))}
        <Divider style={{ marginVertical: 20 }} />
      </ScrollView>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      paddingTop: 24,
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      paddingHorizontal: 30,
    },
    avatar: {
      marginTop: 20,
      backgroundColor: theme.customColors.avatar,
    },
    name: {
      marginTop: 10,
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
      color: theme.customColors.text,
    },
    description: {
      color: theme.customColors.textGray,
      marginBottom: 10,
    },
    memberSection: {
      color: theme.customColors.textGray,
      marginBottom: 20,
      marginTop: 16,
      fontSize: 16,
      alignSelf: 'center',
    },
    divider: {
      width: '100%',
      height: 2,
      color: theme.customColors.borders,
    },
    commonUserHeader: {
      width: '100%',
      marginBottom: 8,
      paddingBottom: 4,
      borderBottomColor: theme.customColors.borders,
      borderBottomWidth: 1,
    },
    activityIndicator: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
    },
  });
