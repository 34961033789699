import React, { useMemo } from 'react';
import Dialog from 'react-native-dialog';
import { useAppTheme } from '../../styles/theme';
import { Button, Dialog as DialogPaper, Portal, Text } from 'react-native-paper';
import { Platform, StyleSheet } from 'react-native';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';

type AlertDialogPropTypes = {
  isOpen: boolean;
  title: string;
  content: string;
  dismissButtonLabel?: string;
  onDismiss: () => void;
  children?: React.ReactNode;
};

export const AlertDialog = ({
  isOpen,
  title,
  content,
  onDismiss,
  dismissButtonLabel = 'OK',
  children,
}: AlertDialogPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);

  if (Platform.OS === 'web') {
    return (
      <Portal>
        <DialogPaper visible={isOpen} onDismiss={onDismiss} style={styles.dialog}>
          <DialogPaper.Title>{title}</DialogPaper.Title>
          <DialogPaper.Content>
            <Text variant="bodyMedium">{content}</Text>
            {children}
          </DialogPaper.Content>
          <DialogPaper.Actions style={styles.actions}>
            <Button onPress={onDismiss} textColor={theme.customColors.signalBlue}>
              {dismissButtonLabel}
            </Button>
          </DialogPaper.Actions>
        </DialogPaper>
      </Portal>
    );
  }

  return (
    <Dialog.Container visible={isOpen}>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Description>{content}</Dialog.Description>
      {children}
      <Dialog.Button
        label={dismissButtonLabel}
        onPress={onDismiss}
        color={theme.customColors.signalBlue}
      />
    </Dialog.Container>
  );
};

const createStyles = () =>
  StyleSheet.create({
    dialog: {
      width: (maxContentAreaWidth / 4) * 3,
      alignSelf: 'center',
    },
    actions: { alignItems: 'center', justifyContent: 'center' },
  });
