import * as FileSystem from 'expo-file-system';
import { getUriPathOnDevice } from './getUriPathOnDevice';
import { Log } from '../../../utils/Log';

export const persistImage = async (storageFilename: string, signedUrl: string) => {
  try {
    const uriPath = getUriPathOnDevice(storageFilename);
    const downloadResult = await FileSystem.downloadAsync(signedUrl, uriPath);

    if (downloadResult.status !== 200) {
      await FileSystem.deleteAsync(uriPath);
      throw new Error('cannot download image, status: ' + downloadResult.status);
    }
    return downloadResult.uri;
  } catch (e) {
    Log.error(e, { message: 'cannot persist image' });
    throw new Error('cannot persist image');
  }
};
