import { useCallback, useMemo } from 'react';
import { ChatRoomMessage } from '../../../../graphql/operations';

interface IUseChatRoomMessageIds {
  chatRoomId: string;
  queriedMessages: ChatRoomMessage[];
  realTimeMessages: ChatRoomMessage[];
  deletedMessages: ChatRoomMessage[];
}

export const useChatRoomMessageIds = ({
  chatRoomId,
  deletedMessages,
  queriedMessages,
  realTimeMessages,
}: IUseChatRoomMessageIds) => {
  const getMessageIds = useCallback((messages: ChatRoomMessage[]) => {
    return messages.map(message => message.id);
  }, []);

  const queriedMessageIds = useMemo(() => {
    return getMessageIds(queriedMessages);
  }, [queriedMessages, chatRoomId]);
  const realTimeMessageIds = useMemo(() => {
    return getMessageIds(realTimeMessages);
  }, [realTimeMessages, chatRoomId]);
  const deletedMessageIds = useMemo(() => {
    return getMessageIds(deletedMessages);
  }, [deletedMessages, chatRoomId]);

  return { queriedMessageIds, realTimeMessageIds, deletedMessageIds };
};
