import { useMemo } from 'react';
import { ChatRole, ChatRoomType } from '../../../../graphql/operations';
import { ChatPartner } from '../types';
import { groupBy } from '../../../utils/groupBy';
import { useStore } from '../../../stores/store';

interface IUseChatRoomModeration {
  chatRoomMembers: ChatPartner[];
  chatRoomType: ChatRoomType;
}

export const useChatRoomModeration = ({
  chatRoomMembers,
  chatRoomType,
}: IUseChatRoomModeration) => {
  const user = useStore(s => s.userProfile);
  const chatRoomMembersByRole = useMemo(
    () => groupBy(chatRoomMembers, i => i.role ?? ChatRole.Member),
    [chatRoomMembers],
  );

  const isGroupChat = useMemo(() => chatRoomType === ChatRoomType.Group, [chatRoomType]);

  const chatRoomHasModerators = useMemo(() => {
    return isGroupChat && Object.keys(chatRoomMembersByRole).includes(ChatRole.Moderator);
  }, [isGroupChat, chatRoomMembersByRole]);

  const userIdsOfModerators = useMemo(() => {
    return chatRoomHasModerators
      ? chatRoomMembersByRole[ChatRole.Moderator].map(member => member.userId)
      : [];
  }, [chatRoomHasModerators, chatRoomMembersByRole]);

  const isUserModerator = useMemo(() => {
    return isGroupChat && !!user?.userId && userIdsOfModerators.includes(user.userId);
  }, [isGroupChat, user?.userId, userIdsOfModerators]);

  return { isUserModerator, userIdsOfModerators, isGroupChat };
};
