import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { useStore } from '../../stores/store';
import { useUserMustAgreeToTermsOfService } from '../useUserMustAgreeToTermsOfService/useUserMustAgreeToTermsOfService';

export const useReloginFlowOnExpiredRefreshToken = () => {
  const isRefreshTokenExpired = useStore(s => s.isRefreshTokenExpired);
  const queryClient = useQueryClient();
  const logout = useStore(s => s.logout);
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'Relogin'>['navigation']>();
  const userMustAgreeToTermsOfService = useUserMustAgreeToTermsOfService();

  useEffect(() => {
    if (isRefreshTokenExpired && !!navigation) {
      if (userMustAgreeToTermsOfService) {
        void logout({ queryClientToClearCacheOf: queryClient, chatStoreToClear: undefined });
        return;
      }
      navigation.navigate('ReloginFlow');
    }
  }, [isRefreshTokenExpired, userMustAgreeToTermsOfService]);
};
