import { NewsToReadQuery, PinnedNewsListQuery } from '../../../graphql/operations';
import { UserGroupWithLabel } from '../../types/main';

type GetDisplayedUserGroupLabelsPropTypes = {
  news: NewsToReadQuery['newsList']['news'][number] | PinnedNewsListQuery['pinnedNewsList'][number];
  userGroupsUserIsPartOf: UserGroupWithLabel[];
};

export const getDisplayedUserGroupLabels = ({
  userGroupsUserIsPartOf,
  news,
}: GetDisplayedUserGroupLabelsPropTypes) => {
  return userGroupsUserIsPartOf
    .filter(userGroup => news.userGroupIds?.includes(userGroup.id))
    .map(userGroup => userGroup.label);
};
