import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

interface RowProps {
  children: React.ReactNode;
}

export const Row = ({ children }: RowProps) => {
  const styles = useMemo(() => createStyles(), []);
  return <View style={styles.row}>{children}</View>;
};

const createStyles = () =>
  StyleSheet.create({
    row: {
      marginVertical: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  });
