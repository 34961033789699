import { Button } from 'react-native-paper';
import { StyleSheet, Text, View } from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo } from 'react';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type DownloadablePropTypes = {
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  description: string;
  source?: string;
  buttonOnPressBeforeDownload: () => void;
  buttonOnPressAfterDownload: () => void;
  buttonTextBeforeDownload: string;
  buttonTextAfterDownload: string;
  isLoading: boolean;
};

export const Downloadable = ({
  icon,
  source,
  description,
  buttonOnPressAfterDownload,
  buttonOnPressBeforeDownload,
  buttonTextAfterDownload,
  buttonTextBeforeDownload,
  isLoading,
}: DownloadablePropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyle(theme, isLoading), [theme, isLoading]);
  const isFileDownloaded = !!source;

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <MaterialCommunityIcons
          name={icon}
          style={styles.icon}
          size={40}
          color={theme.customColors.gray50}
        />
        <View style={styles.textContainer}>
          <Text style={styles.text}>{description}</Text>
        </View>
        <Button
          onPress={isFileDownloaded ? buttonOnPressAfterDownload : buttonOnPressBeforeDownload}
          style={styles.button}
          uppercase={false}
          loading={isLoading}
          disabled={isLoading}
          textColor={isLoading ? theme.customColors.sand : theme.customColors.textWhite}
          labelStyle={styles.buttonText}
        >
          {isFileDownloaded ? buttonTextAfterDownload : buttonTextBeforeDownload}
        </Button>
      </View>
    </View>
  );
};

const createStyle = (theme: AppTheme, isLoading: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
    },
    inner: {
      paddingHorizontal: '15%',
      paddingVertical: 30,
      backgroundColor: theme.customColors.downloadableBackground,
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: { marginBottom: 12 },
    textContainer: {
      flex: 1,
      flexWrap: 'wrap',
    },
    text: {
      color: theme.customColors.textGray,
      marginBottom: 18,
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 'bold',
    },
    buttonContainer: {
      height: 50,
    },
    button: {
      backgroundColor: isLoading ? theme.customColors.ripple : theme.customColors.primary,
      borderRadius: 5,
    },
    buttonText: {
      fontWeight: 'bold',
      fontSize: 15,
    },
  });
