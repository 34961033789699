import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useAppTheme } from '../../styles/theme';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Text } from 'react-native-paper';
import { SnackbarContentProps } from './SnackbarContentProps';

export const SnackbarContent = ({ text, icon }: SnackbarContentProps) => {
  const theme = useAppTheme();
  return (
    <View style={styles.container}>
      <Icon name={icon} size={18} onPress={() => {}} color={theme.customColors.snackbarIcon} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: 'white',
    fontSize: 14,
  },
  container: { flexDirection: 'row', alignItems: 'center', columnGap: 5 },
});
