import { InfiniteData } from '@tanstack/react-query';
import { NewsToReadByUserGroupQuery, SingleNewsToReadQuery } from '../../../../graphql/operations';
import produce from 'immer';

type LikeResult = NewsToReadByUserGroupQuery['newsListByUserGroup']['news'][number]['likeResult'];

const createLike = (isLike: boolean, likeResult?: LikeResult): LikeResult => {
  if (likeResult) {
    likeResult.liked = isLike;
    likeResult.likesCount = likeResult.likesCount + (isLike ? 1 : -1);
    return likeResult;
  }
  return { liked: isLike, likesCount: isLike ? 1 : 0 };
};

export const updateInfinite = (
  newsId: string,
  news: InfiniteData<NewsToReadByUserGroupQuery> | undefined,
  isLike: boolean,
) => {
  return produce(news, draft => {
    draft?.pages.forEach(page => {
      const foundNews = page.newsListByUserGroup.news.find(
        desiredNews => desiredNews.id === newsId,
      );
      if (foundNews) {
        foundNews.likeResult = createLike(isLike, foundNews.likeResult);
      }
    });
  });
};

export const updateSingleNews = (isLike: boolean, news?: SingleNewsToReadQuery) => {
  return produce(news, draft => {
    if (draft?.news) {
      draft.news.likeResult = createLike(isLike, draft?.news.likeResult);
    }
  });
};
