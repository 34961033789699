import { RefreshTokenDocument, RefreshTokenMutation } from '../../graphql/operations';
import { autoRejectAfterTimeout } from '../utils/autoRejectAfterTimeout';
const NETWORK_TIMEOUT_MILLISECONDS = 10000;

export const refetchToken = async (
  refreshToken: string,
  tenantName: string,
): Promise<RefreshTokenMutation> => {
  const fetcher = () =>
    fetch(process.env.EXPO_PUBLIC_API_URL as string, {
      method: 'POST',
      ...{ headers: { 'Content-Type': 'application/json' } },
      body: JSON.stringify({
        query: RefreshTokenDocument,
        variables: { refreshToken: refreshToken, tenantName: tenantName },
      }),
    });

  const res = await autoRejectAfterTimeout(fetcher, NETWORK_TIMEOUT_MILLISECONDS);

  const response = res as Response;
  const json = await response.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};
