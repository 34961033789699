import { Text, useTheme } from 'react-native-paper';
import { Comment, useDeleteCommentMutation } from '../../../graphql/operations';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { getFullName, getInitials } from '../../features/profiles/utils';
import { NewsDetailScreenProps } from '../../features/news/types';
import { OfflineErrorModal } from '../Common/Modals/OfflineErrorModal';
import moment from 'moment';
import { themeProp } from '../../types/main';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../stores/store';
import { initUseHandleRemoveComment } from '../../features/news/hooks/initUseHandleRemoveComment';
import { UploadType } from '../Common/FileUpload/types';
import { AvatarDisplay } from '../../components/Common/AvatarDisplay/AvatarDisplay';
import { DiscardDialog } from '../Dialog/DiscardDialog';

type CommentReplyProps = {
  item: Comment;
  newsId: string;
  userGroupIds: string | string[];
  isFleetingComment: boolean;
};

export const CommentReply = memo((props: CommentReplyProps) => {
  const { permissions, userProfile } = useStore(s => ({
    permissions: s.permissions,
    userProfile: s.userProfile,
  }));
  const queryClient = useQueryClient();
  const isNewsLegacy = !(Array.isArray(props.userGroupIds) && props.userGroupIds.length !== 0);
  const deleteReplyMutation = useDeleteCommentMutation();
  const theme = useTheme() as themeProp;
  const navigation = useNavigation<NewsDetailScreenProps['navigation']>();
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  const handleProfile = () => {
    if (props.item.author) {
      navigation.navigate('Profile', {
        profileId: props.item.author.userId,
        name: getFullName({
          firstname: props.item.author.firstname,
          lastname: props.item.author.lastname,
        }),
      });
    }
  };

  const handleRemoveComment = initUseHandleRemoveComment({
    deleteCommentMutation: deleteReplyMutation,
    queryClient: queryClient,
  });

  const handleDeleteReply = async () => {
    handleRemoveComment({
      commentId: props.item.id,
      newsId: props.newsId,
      userGroupIds: props.userGroupIds,
      isReply: true,
    });
    setInfoVisible(false);
  };

  const styles = useMemo(
    () => createStyles(theme, props.isFleetingComment),
    [theme, props.isFleetingComment],
  );

  const isCommentDeletable = useMemo(
    () =>
      permissions
        ?.filter(p =>
          isNewsLegacy
            ? p.groupId === (props.userGroupIds as string)
            : (props.userGroupIds as string[]).includes(p.groupId),
        )
        ?.map(
          item =>
            item.permissions.includes('users:create') ||
            userProfile?.userId === props.item.author?.userId,
        ),
    [permissions, isNewsLegacy, props.userGroupIds, props.item, userProfile],
  );

  const handleNavigateToProfile = useCallback(
    () => (props.isFleetingComment ? undefined : handleProfile),
    [props.isFleetingComment],
  );

  const handleOpenCommentDeletionModal = useCallback(
    (onOnline: (fn: () => void) => void) =>
      props.isFleetingComment ? undefined : () => onOnline(() => setInfoVisible(true)),
    [props.isFleetingComment, setInfoVisible],
  );

  return (
    <OfflineErrorModal>
      {onOnline => (
        <View style={styles.container}>
          <TouchableWithoutFeedback onPress={handleNavigateToProfile}>
            <AvatarDisplay
              size={40}
              avatar={{
                otcPath: props.item.author?.pictureOTC ?? undefined,
                initials: getInitials(props.item?.author ?? undefined),
              }}
              type={UploadType.ProfilePicture}
            />
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={handleNavigateToProfile}>
            <View style={styles.commentContainer}>
              <View style={styles.commentContainerTop}>
                <Text style={styles.author}>
                  {props.item.author ? getFullName(props.item.author) : ''}
                </Text>
                <Text style={styles.date}>{formatTinyDate(props.item.createdAt!)}</Text>
              </View>
              <View>
                <Text style={styles.content}>{props?.item?.content || ' '}</Text>
                <View style={styles.commentContainerBottom}>
                  {isCommentDeletable && (
                    <Text
                      style={styles.deleteOption}
                      onPress={handleOpenCommentDeletionModal(onOnline)}
                    >
                      Löschen
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
          <DiscardDialog
            title="Sind Sie sicher?"
            content="Möchten Sie wirklich die Antwort löschen?"
            discardButtonLabel="Löschen"
            cancelButtonLabel="Abbrechen"
            isOpen={!!infoVisible}
            onDiscard={() => void handleDeleteReply()}
            onCancel={() => setInfoVisible(false)}
          />
        </View>
      )}
    </OfflineErrorModal>
  );
});

const createStyles = (theme: themeProp, isFleetingComment: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
    },
    avatar: { marginRight: 12, backgroundColor: theme.customColors.avatar },
    commentContainer: { flex: 1, marginBottom: 14, marginLeft: 10 },
    commentContainerTop: { flexDirection: 'row', justifyContent: 'space-between' },
    commentContainerBottom: { flexDirection: 'row' },
    author: { fontWeight: 'bold', fontSize: 16 },
    date: { color: theme.customColors.textGray, fontSize: 14 },
    content: {
      marginBottom: 4,
      fontSize: 16,
    },
    deleteOption: {
      color: theme.customColors.textGray,
      fontWeight: 'bold',
      opacity: isFleetingComment ? 0 : 1,
    },
  });

const formatTinyDate = (date: Date) => {
  const a = moment(date);
  const dayDiff = moment().diff(a, 'days');
  if (dayDiff === 0) {
    return a.format('LT');
  } else if (dayDiff <= 7) {
    return a.format('dd[.]');
  } else {
    return a.format('l');
  }
};
