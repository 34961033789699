import { setBadgeCountAsync } from 'expo-notifications';
import { Log } from './Log';

export const setBadgeCount = async (messageCount: number) => {
  try {
    await setBadgeCountAsync(messageCount);
  } catch (e) {
    Log.error(e, { message: 'cannot update badge count', data: { messageCount } });
  }
};
