import { Alert, Linking, ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { ActivityIndicator, Button, IconButton, Portal, Text } from 'react-native-paper';
import React, { useEffect, useMemo, useState } from 'react';
import { getFullName, getInitials } from '../utils';
import {
  ChatRole,
  useCommonGroupChatRoomsQuery,
  useCreatePrivateChatRoomMutation,
  useGetUserProfileQuery,
  UserProfile,
} from '../../../../graphql/operations';
import { Log } from '../../../utils/Log';

import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { PublicProfileScreenProps } from './types';
import { SmallChatListItem } from '../../chat/components/SmallChatListItem';
import { themeProp } from '../../../types/main';
import { useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { useStore } from '../../../stores/store';
import { ProfileHeader } from '../components/ProfileHeader';
import { SafeAreaView } from 'react-native-safe-area-context';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { useBlockUser } from '../../../hooks/chats/useBlockUser';
import { useQueryClient } from '@tanstack/react-query';
import { maxContentAreaWidth } from '../../../router/navigators/ContentNavigator';
import { useAppTheme } from '../../../styles/theme';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { getChatRoomName } from '../../chat/utils/getChatRoomName';
import { NoDataRefresh } from '../../../stories/Loading/NoDataRefresh';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { DiscardDialog } from '../../../components/Dialog/DiscardDialog';

export const PublicProfileScreen = ({
  route: {
    params: { profileId, name, pressedBlock },
  },
}: PublicProfileScreenProps) => {
  const netInfo = useNetInfo();
  const profile = useGetUserProfileQuery(
    { userId: profileId! },
    {
      enabled: !!profileId && (netInfo.isConnected ?? false),
    },
  );
  const userProfile = useStore(s => s.userProfile);
  const navigation = useNavigation<PublicProfileScreenProps['navigation']>();
  const createChatMutation = useCreatePrivateChatRoomMutation();
  const { data: commonChats } = useCommonGroupChatRoomsQuery(
    { partnerUserProfileId: profileId ?? '' },
    {
      enabled: !!profileId && (netInfo.isConnected ?? false),
    },
  );
  const queryClient = useQueryClient();
  const theme = useAppTheme();

  const [menuOpen, setMenuOpen] = useState(false);
  const { height } = useWindowDimensions();

  const {
    isUserBlocked,
    onPressBlock,
    isUserBlockOperationMutationLoading,
    isUserBlockConfirmModalVisible,
    setIsUserBlockConfirmModalVisible,
    onCancelBlockUser,
    onConfirmBlockUser,
  } = useBlockUser({
    queryClient: queryClient,
    setMenuOpen: setMenuOpen,
    currentUser: userProfile,
    viewedUser: profile.data?.userProfile,
  });

  useEffect(() => {
    if (pressedBlock) {
      setIsUserBlockConfirmModalVisible(true);
    }
  }, [pressedBlock]);

  const styles = createStyles(theme, isUserBlocked, height);

  const handlePress = (user?: UserProfile) => {
    if (userProfile?.userId && user?.userId) {
      const fallbackName = user.firstname && user.lastname && `${user.firstname} ${user.lastname}`;
      createChatMutation.mutate(
        {
          input: {
            partnerUserProfileId: user.userId,
          },
        },
        {
          onSuccess: response => {
            navigation.navigate('ChatRoom', {
              id: response.createPrivateChatRoom.id,
              name: getChatRoomName(name ?? fallbackName),
              imageUri: user.pictureOTC ?? undefined,
              members: [
                {
                  firstname: userProfile.firstname,
                  lastname: userProfile.lastname,
                  userId: userProfile.userId,
                  picture: userProfile.picture,
                  pictureOTC: userProfile.pictureOTC,
                },
                {
                  firstname: user.firstname!,
                  lastname: user.lastname!,
                  userId: user.userId,
                  picture: 'deprecated',
                  pictureOTC: user.pictureOTC,
                },
              ],
              role: ChatRole.Member,
            });
          },
        },
      );
    } else {
      Alert.alert('Es ist ein unerwarteter Fehler aufgetreten!');
    }
  };

  useEffect(() => {
    if (profile.data) {
      navigation.setOptions({
        title: `${profile.data.userProfile.firstname} ${profile.data.userProfile.lastname}`,
      });
    } else if (profile.isError) {
      navigation.goBack();
    }
  }, [profile.data]);

  const renderItem = (
    idx: number,
    nameArg: string,
    value?: string | null,
    phone?: boolean,
    mail?: boolean,
    dense?: boolean,
  ) => {
    if (!value) {
      return null;
    }
    return (
      <View
        key={idx}
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: '5%',
          paddingVertical: 14,
          borderColor: theme.customColors.borders,
          borderTopWidth: 2,
          borderBottomWidth: 2,
          marginTop: -2,
        }}
      >
        <View style={{ justifyContent: 'center', maxWidth: (maxContentAreaWidth * 7) / 8 }}>
          <Text style={styles.subHeader}>{nameArg}</Text>
          <Text
            style={{
              fontSize: dense ? 17 : 17, // revert, everything should have the same font size
              color: theme.customColors.text,
            }}
          >
            {value}
          </Text>
        </View>
        <View />
        {phone && (
          <IconButton
            icon="phone"
            iconColor="#fff"
            style={{ backgroundColor: theme.customColors.gray70 }}
            onPress={() => {
              /* @todo must be awaited */
              void Linking.openURL(`tel:${value}`);
            }}
          />
        )}
        {mail && (
          <IconButton
            icon="email-outline"
            iconColor="#fff"
            style={{ backgroundColor: theme.customColors.gray70 }}
            onPress={() => {
              /* @todo must be awaited */
              void Linking.openURL(`mailto:${value}`);
            }}
          />
        )}
      </View>
    );
  };

  const listItems = useMemo(() => {
    if (!profile.data) {
      return;
    }
    return [
      { name: 'Standort', value: profile.data.userProfile.location },
      { name: 'Geschäftseinheit', value: profile.data.userProfile.businessUnit },
      { name: 'Abteilung', value: profile.data.userProfile.department },
      {
        name: 'Telefon (Geschäftlich)',
        value: profile.data.userProfile.businessPhone,
        phone: true,
      },
      { name: 'E-Mail (Geschäftlich)', value: profile.data.userProfile.businessEmail, mail: true },
      { name: 'Über mich', value: profile.data.userProfile.profileTextAbout, dense: true },
      {
        name: 'Hobbies/Interessen',
        value: profile.data.userProfile.profileTextInterest,
        dense: true,
      },
      {
        name: 'Sprachen',
        value: profile.data.userProfile.profileTextLanguages,
        dense: true,
      },
    ];
  }, [profile.data]);

  return (
    <View style={{ height: '100%', width: '100%', backgroundColor: theme.colors.background }}>
      <OfflineErrorModal>
        {onOnline => (
          <>
            <SafeAreaView testID="PublicProfileScreen" style={{ flex: 1 }}>
              <ProfileHeader
                name={name ?? getFullName(profile.data?.userProfile)}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                isUserBlocked={isUserBlocked}
                onPressBlock={onPressBlock}
              />
              {!profile.data ? (
                profile.isLoading ? (
                  <ActivityIndicator
                    size="large"
                    animating={true}
                    color={theme.customColors.primary}
                    style={styles.activityIndicator}
                  />
                ) : (
                  <NoDataRefresh
                    text="Ups, da ist ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal."
                    onPress={async () => {
                      try {
                        await profile.refetch();
                      } catch (e) {
                        const err = e as Error;
                        Log.error(err, {
                          message:
                            'an error occurred while refetching user profile for the public profile screen',
                        });
                      }
                    }}
                    isLoading={profile.isFetching}
                  />
                )
              ) : (
                <>
                  <ScrollView contentContainerStyle={styles.wrapper}>
                    <AvatarDisplay
                      size={160}
                      type={UploadType.ProfilePicture}
                      avatar={{
                        initials: getInitials(profile.data.userProfile),
                        otcPath: profile.data.userProfile.pictureOTC ?? undefined,
                      }}
                    />
                    <Text style={styles.name}>{getFullName(profile.data.userProfile)}</Text>
                    {profile.data.userProfile.employeeFunction ? (
                      <Text style={styles.description}>
                        {profile.data.userProfile.employeeFunction}
                      </Text>
                    ) : (
                      <></>
                    )}
                    <View style={{ marginTop: 18, marginBottom: 36 }}>
                      {profile.data.userProfile.userId !== userProfile?.userId && (
                        <Button
                          icon={isUserBlocked ? undefined : 'chat-outline'}
                          mode={isUserBlocked ? 'contained' : 'outlined'}
                          onPress={() => onOnline(() => handlePress(profile.data.userProfile))}
                          disabled={isUserBlocked}
                          loading={isUserBlockOperationMutationLoading}
                          style={styles.sendMessageButton}
                          labelStyle={styles.sendMessageButtonLabel}
                        >
                          {isUserBlocked ? 'Blockiert' : 'Nachricht senden'}
                        </Button>
                      )}
                    </View>
                    {commonChats && commonChats?.commonGroupChatRooms.length > 0 && (
                      <View style={styles.contentContainer}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottomWidth: 1,
                            borderColor: theme.customColors.borders,
                            paddingBottom: 5,
                          }}
                        >
                          <Text style={styles.subHeader}>Gemeinsame Chatgruppen</Text>
                          <Text style={styles.subHeader}>
                            {commonChats?.commonGroupChatRooms.length}
                          </Text>
                        </View>
                        {commonChats.commonGroupChatRooms.map(chatRoom => (
                          <SmallChatListItem
                            key={chatRoom.id}
                            id={chatRoom.id}
                            name={chatRoom.name ?? ''}
                            imageUri={chatRoom.groupImagePath ?? undefined}
                            members={chatRoom.members ?? []}
                          />
                        ))}
                      </View>
                    )}
                    {listItems?.map((item, i) =>
                      renderItem(i, item.name, item.value, item.phone, item.mail, item.dense),
                    )}
                  </ScrollView>
                  <DiscardDialog
                    isOpen={isUserBlockConfirmModalVisible}
                    title="Sind sie Sicher?"
                    content="Wenn Sie diesen Kontakt blockieren, können Sie keine Nachrichten und keine News mehr von diesem Kontakt erhalten."
                    onCancel={() => onCancelBlockUser()}
                    onDiscard={() => onConfirmBlockUser(profile.data.userProfile.userId)}
                    discardButtonLabel="Blockieren"
                  />
                </>
              )}
            </SafeAreaView>
            <Portal>
              <LoadingBlocker
                visible={isUserBlockOperationMutationLoading}
                spinnerColor={theme.customColors.accent}
              />
            </Portal>
          </>
        )}
      </OfflineErrorModal>
    </View>
  );
};

const createStyles = (theme: themeProp, isUserBlocked: boolean, height: number) =>
  StyleSheet.create({
    wrapper: {
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      minHeight: height,
      paddingBottom: 110,
      paddingTop: 32,
    },
    name: {
      marginTop: 20,
      fontWeight: '500',
      fontSize: 20,
    },
    description: {
      color: theme.customColors.textDark,
      fontSize: 14,
      marginBottom: 12,
      marginHorizontal: 20,
    },
    subHeader: {
      fontSize: 14,
      color: theme.customColors.textGray,
    },
    content: {
      textAlign: 'left',
      alignSelf: 'center',
    },
    button: {
      borderRadius: 5,
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center',
    },
    actionContainer: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      marginVertical: 10,
    },
    avatar: {
      backgroundColor: theme.customColors.avatar,
      marginTop: 20,
    },
    contentContainer: {
      width: '100%',
      paddingHorizontal: '5%',
      paddingVertical: 14,
      borderColor: theme.customColors.borders,
      borderTopWidth: 2,
      borderBottomWidth: 2,
      marginTop: -2,
    },
    sendMessageButton: isUserBlocked
      ? {
          backgroundColor: 'red',
          borderColor: 'white',
          borderRadius: 5,
        }
      : {
          borderRadius: 5,
        },
    sendMessageButtonLabel: isUserBlocked
      ? { color: theme.customColors.textWhite, fontWeight: 'bold' }
      : {},
    activityIndicator: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
    },
  });
