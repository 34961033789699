import React from 'react';

import { StackScreenProps } from '@react-navigation/stack';
import { NewPasswordAbstract } from './NewPasswordAbstract';
import { AppNavigatorParamList } from '../../router/AppNavigator';

type NewPasswordProps = StackScreenProps<AppNavigatorParamList, 'NewPassword'>;

export const NewPassword = (props: NewPasswordProps) => {
  const handleSubmit = (password: string) => {
    props.navigation.navigate('CheckInbox', {
      ...props.route.params,
      newPassword: password,
    });
  };

  return <NewPasswordAbstract onSubmitForm={handleSubmit} />;
};
