import React, { RefObject, useMemo, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  RefreshControl,
  ActivityIndicator,
  TextInput as RNTextInput,
  ViewStyle,
} from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import { TextInput, Text } from 'react-native-paper';
import { useDebouncedCallback } from 'use-debounce';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { DropdownItem } from './DropdownItem';

export type Item = {
  label?: string;
  value: string;
  icon?: React.ReactNode;
};

export type Props = {
  isLoading?: boolean;
  isSearchable?: boolean;
  items: Item[];
  placeholder?: string;
  searchPlaceholder?: string;
  onLoadMore?: () => void;
  onRefresh?: () => void;
  emptyPlaceholder?: string;
  onSearchText?: (text: string) => void;
  onChangeValue?: (value: string) => void;
  disabled?: boolean;
};

const RenderEmpty = ({
  emptyPlaceholder,
  containerStyles,
}: Pick<Props, 'emptyPlaceholder'> & { containerStyles: ViewStyle }) => {
  return (
    <View style={containerStyles}>
      <Text>{emptyPlaceholder ?? 'Keine Ergebnisse!'}</Text>
    </View>
  );
};

const RenderFooter = ({
  isLoading,
  containerStyles,
}: Pick<Props, 'isLoading'> & { containerStyles: ViewStyle }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <View style={containerStyles}>
      <ActivityIndicator color={'gray'} size={'large'} />
    </View>
  );
};

export const ElementDropdown = ({
  searchPlaceholder = 'Suche...',
  placeholder,
  onLoadMore,
  onRefresh,
  emptyPlaceholder,
  onSearchText,
  items,
  isSearchable = false,
  disabled = false,
  isLoading,
  onChangeValue,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [value, setValue] = useState<Item>();
  const inputRef = useRef<RNTextInput>(null);
  const ref: RefObject<any> = useRef(null);
  const theme = useAppTheme();

  const styles = useMemo(() => createStyles(theme, disabled), [theme, disabled]);

  const debouncedSearch = useDebouncedCallback(
    // function
    (searchText: string) => {
      onSearchText?.(searchText);
    },
    // delay in ms
    300,
  );

  const onFocus = () => {
    if (isSearchable) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  };

  const onChange = (item: Item) => {
    setValue(item);
    onChangeValue?.(item.value);
  };

  const renderInputSearch = () => (
    <TextInput
      ref={inputRef}
      value={searchValue}
      placeholder={searchPlaceholder}
      onChangeText={text => {
        setSearchValue(text);
        debouncedSearch(text);
      }}
      right={<TextInput.Icon icon="close" onPress={() => setSearchValue('')} />}
    />
  );

  return (
    <Dropdown
      ref={ref}
      disable={disabled}
      style={styles.dropdown}
      containerStyle={styles.container}
      backgroundColor={'rgba(0,0,0,.7)'}
      placeholderStyle={styles.placeholder}
      selectedTextStyle={styles.selectedText}
      data={items ?? []}
      value={value}
      inverted={false}
      labelField="label"
      valueField="value"
      placeholder={placeholder}
      onFocus={onFocus}
      search={isSearchable}
      renderItem={(item, selected) => <DropdownItem item={item} selected={selected} />}
      renderInputSearch={renderInputSearch}
      maxHeight={250}
      onChange={onChange}
      flatListProps={{
        ListEmptyComponent: (
          <RenderEmpty
            emptyPlaceholder={emptyPlaceholder}
            containerStyles={styles.emptyContainer}
          />
        ),
        ListFooterComponent: (
          <RenderFooter isLoading={isLoading} containerStyles={styles.footerContainer} />
        ),
        refreshControl: <RefreshControl refreshing={false} onRefresh={onRefresh} />,
        onEndReachedThreshold: 0.5,
        onEndReached: onLoadMore ?? undefined,
      }}
    />
  );
};

const createStyles = (theme: AppTheme, disabled: boolean) =>
  StyleSheet.create({
    dropdown: {
      backgroundColor: theme.customColors.background3,
      color: theme.colors.onSurface,
      paddingHorizontal: 16,
      paddingVertical: 8,
      borderRadius: 8,
      opacity: disabled ? 0.5 : 1,
    },
    container: {
      borderWidth: 0,
    },
    emptyContainer: {
      backgroundColor: theme.customColors.background2,
      padding: 16,
      alignItems: 'center',
    },
    footerContainer: {
      padding: 16,
      alignItems: 'center',
    },
    placeholder: { color: theme.colors.onSurface },
    selectedText: { color: theme.colors.onSurface },
  });
