import React from 'react';
import { SafeAreaView } from 'react-native';
import { InformationScreenContent } from './InformationScreenContent';
import { AppBar } from '../AppBar/AppBar';
import { Appbar } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';

export const InformationScreen = () => {
  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParamList, 'InformationScreen'>['navigation']>();
  return (
    <>
      <AppBar
        title="Informationen"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <SafeAreaView style={{ height: '100%' }}>
        <InformationScreenContent />
      </SafeAreaView>
    </>
  );
};
