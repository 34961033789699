import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppTheme, useAppTheme } from '../../styles/theme';

export const NewsSeparator = () => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  return <View style={styles.seperator} />;
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    seperator: {
      width: '100%',
      height: 8,
      backgroundColor: theme.customColors.borders,
    },
  });
