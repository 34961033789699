import React from 'react';
import { Appbar } from 'react-native-paper';
import { useAppTheme } from '../../styles/theme';

type NewsFilterAppBarActionPropTypes = {
  isSomeFilterApplied: boolean;
  isDisabled?: boolean;
  onPress: () => void;
};

export const NewsFilterAppBarAction = ({
  isSomeFilterApplied,
  isDisabled,
  onPress,
}: NewsFilterAppBarActionPropTypes) => {
  const theme = useAppTheme();
  return (
    <Appbar.Action
      testID="appbar-filter-button"
      icon={isSomeFilterApplied ? 'filter' : 'filter-outline'}
      size={26}
      color={theme.customColors.gray50}
      onPress={onPress}
      disabled={!!isDisabled}
    />
  );
};
