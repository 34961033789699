import { Button, Text, useTheme } from 'react-native-paper';
import React, { useMemo } from 'react';
import {
  GetUserPreferenceQuery,
  useListTranslationLanguagesQuery,
} from '../../../../graphql/operations';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { themeProp } from '../../../types/main';

type LanguageSelectionButtonPropTypes = {
  text: string;
  permission: boolean;
  preference: GetUserPreferenceQuery['getUserPreference'];
  onOnline: (fn: () => void) => void;
  disabled: boolean;
};

export const LanguageSelectionButton = ({
  text,
  permission,
  preference,
  onOnline,
  disabled,
}: LanguageSelectionButtonPropTypes) => {
  const theme = useTheme() as themeProp;
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const languages = useListTranslationLanguagesQuery();
  const label = useMemo(() => {
    if (languages.data && preference.translationLanguage) {
      const selectedLanguageName = languages.data.listTranslationLanguages.find(
        language => language?.languageKey === preference.translationLanguage,
      )?.languageName;
      return selectedLanguageName ?? 'Nicht eingestellt';
    } else {
      return 'Nicht eingestellt';
    }
  }, [preference.translationLanguage, languages.data]);
  const isDisabled =
    !permission || languages.isLoading || !preference.translationEnabled || disabled;
  const styles = useMemo(
    () => createStyles(theme, isDisabled),
    [preference.translationEnabled, theme, isDisabled],
  );

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{text}</Text>
      <Button
        disabled={isDisabled}
        mode="text"
        onPress={() =>
          onOnline(() =>
            navigation.navigate('LanguageSelection', {
              language: preference.translationLanguage,
            }),
          )
        }
        loading={languages.isLoading}
        icon="chevron-right"
        contentStyle={styles.buttonContent}
        style={styles.button}
        uppercase={false}
      >
        {label}
      </Button>
    </View>
  );
};

const createStyles = (theme: themeProp, disabled: boolean) =>
  StyleSheet.create({
    container: {
      marginVertical: 4,
      marginBottom: 32,
      flexDirection: 'row',
      justifyContent: 'space-between',
      opacity: disabled ? 0.5 : 1,
    },
    button: { marginVertical: -5, borderRadius: 5 },
    buttonContent: { flexDirection: 'row-reverse' },
    label: { marginTop: 4 },
  });
