import { Button } from 'react-native-paper';
import { View } from 'react-native';

import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { authStyles as styles } from '../../styles/authStyles';
import { useAppTheme } from '../../styles/theme';

type CheckInboxScreenAbstractProps = {
  onPressContinue(): any;
  channel?: string;
};

export const CheckInboxScreenAbstract = ({
  onPressContinue,
  channel,
}: CheckInboxScreenAbstractProps) => {
  const theme = useAppTheme();

  const contactType = channel || 'E-Mail bzw. SMS';
  const icon: { [index: string]: keyof typeof MaterialCommunityIcons.glyphMap } = {
    SMS: 'message-text-lock-outline',
    'E-Mail': 'email-open-outline',
    'E-Mail bzw. SMS': 'email-open-outline',
  };

  return (
    <BaseAuthScreen
      renderTop={() => (
        <View style={styles(theme).iconBox}>
          <MaterialCommunityIcons
            size={90}
            color={theme.customColors.primary}
            name={icon[contactType]}
          />
        </View>
      )}
      justify
      paddingHorizonal={45}
      headline={`${contactType} Eingang überprüfen`}
      description={`Wir haben Ihnen einen 6-stelligen Bestätigungscode per ${contactType} zugesendet. Bitte geben Sie diesen im nächsten Schritt ein.`}
    >
      <Button
        style={styles(theme).button}
        mode="contained"
        onPress={onPressContinue}
        textColor={theme.customColors.textWhite}
      >
        Verstanden und Weiter
      </Button>
    </BaseAuthScreen>
  );
};
