import { compressImage } from '../compressImage';
import { Log } from '../Log';
import { RequestImageType, requestImages } from '../requestImages/requestImages';

type GetCompressedFilesParams = {
  type: RequestImageType;
  includeVideosAmongPhotos?: boolean;
};

export const getCompressedFiles = async ({
  type,
  includeVideosAmongPhotos,
}: GetCompressedFilesParams) => {
  try {
    const pickedFiles = await requestImages(type, includeVideosAmongPhotos);
    if (!pickedFiles || !pickedFiles.assets) {
      return;
    }
    return await Promise.all(
      (pickedFiles.assets ?? []).map(async file => {
        if (file.type === 'image') {
          return { ...(await compressImage(file)), type: 'image' };
        }
        return file;
      }),
    );
  } catch (error) {
    const err = error as Error;
    const message = `could not compress files: ${err.message}`;
    Log.error(err, { message });
    throw new Error(message);
  }
};
