import { Keyboard, Platform } from 'react-native';

export const waitForKeyboardDismiss = (): Promise<void> =>
  new Promise(resolve => {
    if (Platform.OS !== 'web' && Keyboard.isVisible()) {
      Keyboard.dismiss();
      const fallbackTimeout = setTimeout(resolve, 3000);
      const unsubscribe = Keyboard.addListener('keyboardDidHide', () => {
        clearTimeout(fallbackTimeout);
        resolve();
        unsubscribe.remove();
      });
    } else {
      resolve();
    }
  });
