import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { StyleSheet, View } from 'react-native';
import { GetInquiryDetailsQuery, InquiryStatus } from '../../../../graphql/operations';
import { Subheading, Text } from 'react-native-paper';
import { getName } from '../getName';
import { constructTruncatedFullName } from '../../../utils';

type InquiryDetailsProps = {
  inquiry: GetInquiryDetailsQuery['inquiryDetails'];
};

export const InquiryDetails = ({ inquiry }: InquiryDetailsProps) => {
  const { title, createdBy, createdAt, team, status, members } = inquiry;
  const styles = useMemo(() => createStyles(), []);
  const membersExceptInquiryCreator = members.filter(
    member => member.userProfile.userId !== createdBy.userId,
  );

  const generateAddressedToFieldValue = () => {
    return membersExceptInquiryCreator
      .map(member => {
        return constructTruncatedFullName({
          firstname: member.userProfile.firstname,
          lastname: member.userProfile.lastname,
          titleBefore: member.userProfile.titleBefore,
          titleAfter: member.userProfile.titleAfter,
        });
      })
      .join(', ');
  };

  return (
    <View style={styles.container}>
      <Subheading numberOfLines={1} style={styles.heading}>
        {title}
      </Subheading>
      <View style={styles.mt}>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>Erstellt von</Text>
          <Text numberOfLines={1} style={styles.bold}>
            {getName({
              firstname: createdBy.firstname,
              lastname: createdBy.lastname,
            })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>Erstellt am</Text>
          <Text numberOfLines={1} style={styles.bold}>
            {dayjs(createdAt).format('DD.MM.YYYY')}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>Adressiert an</Text>
          <Text numberOfLines={1} style={styles.bold}>
            {generateAddressedToFieldValue()}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>Team</Text>
          <Text numberOfLines={1} style={styles.bold}>
            {team.title}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>BearbeiterIn</Text>
          <Text numberOfLines={1} style={styles.bold}>
            {getName({
              firstname: team.users[0].userProfile.firstname,
              lastname: team.users[0].userProfile.lastname,
            })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={(styles.bold, styles.halfWidth)}>Status</Text>
          <Text style={styles.bold}>{status === InquiryStatus.Open ? 'offen' : 'geschlossen'}</Text>
        </View>
      </View>
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      padding: 5,
    },
    heading: {
      padding: 3,
      fontWeight: 'bold',
    },
    row: {
      flexDirection: 'row',
      padding: 3,
    },
    bold: {
      fontWeight: 'bold',
    },
    halfWidth: {
      width: '50%',
    },
    mt: {
      marginTop: 5,
    },
  });
