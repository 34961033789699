import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../../styles/theme';

type AddMediaFABGroupProps = {
  handleGalleryPick: () => void;
  handleCameraPhoto: () => void;
  handleCameraVideo?: () => void;
  handleStockImageLibrary: () => void;
};

export const AddMediaFABGroup = ({
  handleGalleryPick,
  handleCameraPhoto,
  // handleCameraVideo,
  handleStockImageLibrary,
}: AddMediaFABGroupProps) => {
  const [state, setState] = useState({ open: false });
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  const onStateChange = ({ open }: { open: boolean }) => setState({ open });

  const { open } = state;

  return (
    <FAB.Group
      open={open}
      visible
      fabStyle={styles.floatingActionButton}
      icon={open ? 'close' : 'plus'}
      color="white"
      actions={[
        {
          color: theme.customColors.icon,
          style: styles.groupFab,
          icon: 'image-outline',
          label: 'Galerie',
          onPress: handleGalleryPick,
        },
        {
          color: theme.customColors.icon,
          style: styles.groupFab,
          icon: 'image',
          label: 'Stockfoto-Bibliothek',
          onPress: handleStockImageLibrary,
        },
        {
          color: theme.customColors.icon,
          style: styles.groupFab,
          icon: 'camera',
          label: 'Camera',
          onPress: handleCameraPhoto,
        },
        /* @todo add support adding video recordings
        {
          color: theme.customColors.icon,
          style: styles.groupFab,
          icon: 'video-plus',
          label: 'Videos',
          onPress: handleCameraVideo,
        }, 
        */
      ]}
      onStateChange={onStateChange}
    />
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    floatingActionButton: {
      height: 40,
      width: 40,
      right: 8,
      bottom: 8,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.customColors.primary,
      borderRadius: 20,
      elevation: 6,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.5,
      shadowRadius: 3,
    },
    groupFab: {
      borderRadius: 20,
    },
  });
