import { useEffect } from 'react';
import { useChatStore } from '../Store';

export const useQuotedMessage = () => {
  const { message: quotedMessage, setMessage: setQuotedMessage } = useChatStore(state => state);

  useEffect(() => {
    setQuotedMessage(undefined);
  }, []);

  return { quotedMessage, setQuotedMessage };
};
