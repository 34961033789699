import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';

type TextChipPropTypes = {
  label: string;
};

export const TextChip = ({ label }: TextChipPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{label}</Text>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      marginHorizontal: 3,
      backgroundColor: theme.customColors.userGroupChipBackground,
      borderRadius: 20,
      paddingVertical: 3,
      paddingHorizontal: 6,
      marginBottom: 2,
    },
    text: { fontSize: 12, color: theme.customColors.text },
  });
