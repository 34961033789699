import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

export interface ContainerProps {
  children: React.ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  const styles = useMemo(() => createStyles(), []);
  return <View style={styles.contentContainer}>{children}</View>;
};

const createStyles = () =>
  StyleSheet.create({
    contentContainer: {
      flex: 1,
      justifyContent: 'space-between',
    },
  });
