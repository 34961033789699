import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect } from 'react';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { checkIfUpdateRequired } from '../../utils/tenancyUtils';
import { useTenantContext } from '../tenant';
import { versionCode } from '../../../app.config';

export const useNewAppVersionRequired = () => {
  const { tenant } = useTenantContext();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();

  useEffect(() => {
    if (tenant && checkIfUpdateRequired(versionCode, tenant.minAppVersion)) {
      navigation.navigate('UpdateRequiredScreen');
    }
  }, [tenant]);
};
