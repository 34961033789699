import { StorageAccessFramework } from 'expo-file-system';

/**
 * Unwrapped because jest cannot spyon on a function if it is inside a namespace (StorageAccessFramework) in a module (expo-file-system)
 * @description Only works on android
 */
export const createFileAsync: typeof StorageAccessFramework.createFileAsync = async (
  parentUri: string,
  fileName: string,
  mimeType: string,
) => {
  return StorageAccessFramework.createFileAsync(parentUri, fileName, mimeType);
};
