import * as Notifications from 'expo-notifications';
import { defineTask, isTaskDefined, TaskManagerTaskExecutor } from 'expo-task-manager';
import { Platform } from 'react-native';

const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';

export const registerPushNotificationBackgroundTask = (taskExecutor: TaskManagerTaskExecutor) => {
  if (isTaskDefined(BACKGROUND_NOTIFICATION_TASK) || Platform.OS === 'web') {
    return;
  }
  defineTask(BACKGROUND_NOTIFICATION_TASK, taskExecutor);
  /* @todo must be awaited */
  void Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
};
