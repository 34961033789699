import React, { useMemo } from 'react';
import Dialog from 'react-native-dialog';
import { TextInputProps, StyleSheet, View } from 'react-native';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';

type LinkInputDialogPropTypes = {
  isOpen: boolean;
  isConfirmButtonDisabled?: boolean;
  title: string;
  content: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onCancel: () => void;
  onConfirm: () => void;
  urlInputProps: TextInputProps;
  labelInputProps: TextInputProps;
};

export const LinkInputDialog = ({
  isOpen,
  title,
  content,
  cancelButtonLabel = 'Abbrechen',
  confirmButtonLabel = 'Weiter',
  isConfirmButtonDisabled = false,
  onCancel,
  onConfirm,
  labelInputProps,
  urlInputProps,
}: LinkInputDialogPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Dialog.Container
      visible={isOpen}
      contentStyle={styles.background}
      blurComponentIOS={<View />}
      buttonSeparatorStyle={styles.buttonSeperator}
      footerStyle={styles.footer}
    >
      <Dialog.Title style={styles.text}>{title}</Dialog.Title>
      <Dialog.Description style={styles.preview}>{content}</Dialog.Description>
      <Dialog.Input
        {...labelInputProps}
        style={styles.input}
        placeholderTextColor={theme.customColors.textGray}
        wrapperStyle={styles.inputWrapper}
      />
      <Dialog.Input
        {...urlInputProps}
        style={styles.input}
        placeholderTextColor={theme.customColors.textGray}
        wrapperStyle={styles.inputWrapper}
      />
      <Dialog.Button
        label={cancelButtonLabel}
        onPress={onCancel}
        color={theme.customColors.signalBlue}
      />
      <Dialog.Button
        label={confirmButtonLabel}
        onPress={onConfirm}
        color={theme.customColors.signalBlue}
        bold
        disabled={isConfirmButtonDisabled}
      />
    </Dialog.Container>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    dialog: {
      width: (maxContentAreaWidth / 4) * 3,
      alignSelf: 'center',
    },
    background: {
      backgroundColor: theme.customColors.background3,
    },
    text: {
      color: theme.customColors.text,
      textAlign: 'center',
    },
    preview: {
      color: theme.customColors.text,
      textAlign: 'center',
      textDecorationLine: 'underline',
    },
    input: {
      backgroundColor: theme.customColors.textInputBackground,
      color: theme.customColors.text,
    },
    inputWrapper: {
      backgroundColor: theme.customColors.textInputBackground,
      borderColor: theme.customColors.borders,
    },
    confirmButtonLabel: { fontWeight: 'bold' },
    actions: {
      justifyContent: 'space-between',
    },
    buttonSeperator: { borderColor: theme.customColors.borders, borderWidth: 1 },
    footer: { borderTopColor: theme.customColors.borders, borderTopWidth: 1 },
  });
