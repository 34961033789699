import { Log } from '../../../utils/Log';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { View, Keyboard, StyleSheet, Platform } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Appbar, Portal, Text } from 'react-native-paper';
import {
  FormQuestionAnswer,
  FormQuestionType,
  FormReplyAttachment,
  FormReplyAttachmentType,
  useFormReplyDetailsQuery,
  useMarkFormReplyAsProcessedMutation,
} from '../../../../graphql/operations';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { FileGallery } from '../../../components/FileGallery/FileGallery';
import { FormHeader } from '../components/FormHeader/FormHeader';
import { FormQuestionAnswerPair } from '../components/FormQuestionAnswerPair/FormQuestionAnswerPair';
import { FormReplyDetailScreenProps } from '../types';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { useStore } from '../../../stores/store';
import { usePopup, useSignedOtcUrl } from '../../../hooks';
import { NoDataRefresh } from '../../../stories/Loading/NoDataRefresh';
import { useFormStatusActionSheet } from '../hooks/useFormStatusActionSheet';
import { ImageViewerModal } from '../../../components/Common/Modals/ImageViewerModal';
import { File } from '../../../components/FileGallery/types';
import { useImageViewerModal } from '../../../components/Common/Modals/hooks/useImageViewerModal';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { FormReplyStatus as FormReplyStatusType } from '../../../../graphql/operations';
import { determineWhoFilledOutFormReply } from '../utils/determineWhoFilledOutFormReply';
import { Popup } from '../../../components/Popup';
import { invalidateFormReplyQuery } from '../utils/invalidateFormReplyQuery';
import { useQueryClient } from '@tanstack/react-query';
import { invalidateInfiniteChatRoomMessagesQuery } from '../../chat/utils/invalidateInfiniteChatRoomMessagesQuery';
import { invalidateChatRoomsQuery } from '../../chat/utils/invalidateChatRoomsQuery';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { mapFormReplyFileTypeToFileType, mapFileTypeToFormReplyFileType } from '../utils';
import { AppBar } from '../../../components/AppBar/AppBar';

export const FormReplyDetailScreen = ({ navigation, route }: FormReplyDetailScreenProps) => {
  const formReplyId = route.params.formReplyId;
  const formTitle = route.params.formTitle;
  const partnerChatId = route.params.chatId;
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const userProfile = useStore(s => s.userProfile);
  const queryClient = useQueryClient();
  const formReplyDetailsQuery = useFormReplyDetailsQuery({ id: formReplyId });
  const markFormReplyMutation = useMarkFormReplyAsProcessedMutation();
  const { fetchSignedUrl } = useSignedOtcUrl();
  const errorPopup = usePopup(
    'Unbekannter Fehler',
    `Leider ist ein unbekannter Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.`,
  );
  const [initialValues, setInitialValues] = useState<{
    [id: string]: { questionText: string; answerText: string };
  }>({});
  const [files, setFiles] = useState<File<unknown>[]>([]);
  const [isLoading, setLoading] = useState(false);

  const downloadAttachments = async (attachments: Array<FormReplyAttachment>) => {
    const promises = [];
    for (const attachment of attachments) {
      promises.push(
        fetchSignedUrl({
          storageFilename: attachment.otcPath,
          type: UploadType.FormReply,
        }),
      );
    }
    const uris = await Promise.all(promises);
    setFiles(
      uris.map((uri, index) => ({
        id: attachments[index].id,
        type: mapFormReplyFileTypeToFileType(attachments[index].type),
        uri: uri,
        name: attachments[index].fileName ?? 'Datei',
      })),
    );
  };

  useEffect(() => {
    if (!formReplyDetailsQuery.data) {
      return;
    }
    void downloadAttachments(formReplyDetailsQuery.data.formReply.attachments);
    const formAnswers = formReplyDetailsQuery.data?.formReply.answers;
    const questionToValueMap: {
      [questionId: string]: { questionText: string; answerText: string };
    } = {};
    formAnswers.forEach(answer => {
      if (answer?.id) {
        questionToValueMap[answer.id] = {
          answerText: answer.answer ?? 'Keine Antwort gegeben',
          questionText: answer.question,
        };
      }
    });
    setInitialValues(questionToValueMap);
  }, [formReplyDetailsQuery.data]);

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: () => {},
  });

  const handleFormReplyStatusChange = async (status: FormReplyStatusType) => {
    const noMutationNeeded =
      formReplyDetailsQuery.data && formReplyDetailsQuery.data.formReply.status === status;
    if (noMutationNeeded) {
      return;
    }
    setLoading(true);
    try {
      await markFormReplyMutation.mutateAsync({ formReplyId, customStatus: status });
      await invalidateFormReplyQuery({
        queryClient,
        formReplyId,
      });
      if (partnerChatId) {
        await invalidateInfiniteChatRoomMessagesQuery({
          queryClient,
          chatRoomId: partnerChatId,
        });
      }
      await invalidateChatRoomsQuery({ queryClient });
    } catch (error) {
      const e = error as Error;
      const msg = `an error occurred while updating the status of a form reply: ${e.message}`;
      Log.error(msg);
      errorPopup.toggleOpen();
    } finally {
      setLoading(false);
    }
  };

  const { showActionSheet: showFormReplyStatusActionSheet } = useFormStatusActionSheet({
    setStatus: handleFormReplyStatusChange,
  });

  if (formReplyDetailsQuery.isLoading) {
    return (
      <LoadingBlocker
        visible={formReplyDetailsQuery.isLoading}
        spinnerColor={theme.customColors.primary}
        opacity="0.2"
      />
    );
  }

  if (!formReplyDetailsQuery.data) {
    return (
      <NoDataRefresh
        text="Keine Formularantwort gefunden"
        isLoading={formReplyDetailsQuery.isLoading}
      />
    );
  }

  const formReply = formReplyDetailsQuery.data.formReply;
  const answers: FormQuestionAnswer[] =
    formReplyDetailsQuery.data?.formReply.answers.map(({ id, question, answer }) => ({
      id,
      question,
      formReplyId,
      answer: answer ? answer : 'Keine Antwort gegeben',
    })) ?? [];
  const includesAttachments = formReply.attachments.length !== 0;
  const filledOutBy = determineWhoFilledOutFormReply(formReply.author, userProfile?.userId);
  const isUserAssigneeOfForm = formReply.author?.userId !== userProfile?.userId;

  const onFileGalleryTilePress = (file: File<unknown>) => {
    const fileType = mapFileTypeToFormReplyFileType(file.type);
    if (fileType === FormReplyAttachmentType.Image) {
      setImageViewerModalImages([{ url: file.uri }]);
      return setImageViewerModalVisible(true);
    }
    if (fileType === FormReplyAttachmentType.Document) {
      if (Platform.OS === 'web') {
        return window.open(file.uri);
      }
      return navigation.navigate('PdfReader', {
        fileName: file.name ?? 'Document',
        url: file.uri,
      });
    }
  };

  return (
    <>
      <AppBar
        title={'Formular Antwort'}
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <OfflineErrorModal>
        {onOnline => (
          <View style={styles.container}>
            <KeyboardAwareScrollView
              style={styles.formContainer}
              keyboardShouldPersistTaps="handled"
            >
              <FormHeader
                title={formTitle}
                status={formReplyDetailsQuery.data?.formReply.status}
                filledOutBy={filledOutBy}
                onPressFormReplyStatus={
                  isUserAssigneeOfForm ? () => onOnline(showFormReplyStatusActionSheet) : undefined
                }
              />
              <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <FormikProvider value={formik}>
                  <>
                    {answers.map((answer, index) => (
                      <FormQuestionAnswerPair
                        key={answer.id}
                        question={{
                          id: answer.id,
                          label: answer.question,
                          type: FormQuestionType.Text,
                          required: false,
                        }}
                        answer={answers[index].answer}
                        answerIsEditable={false}
                      />
                    ))}
                    {includesAttachments && (
                      <View style={styles.fileGalleryContainer}>
                        <Text style={styles.titleTxt}>Anhänge</Text>
                        <FileGallery
                          files={files}
                          allowsDeletion={false}
                          onTilePress={onFileGalleryTilePress}
                        />
                      </View>
                    )}
                  </>
                </FormikProvider>
              </TouchableWithoutFeedback>
            </KeyboardAwareScrollView>
            <ImageViewerModal
              visible={imageViewerModalVisible}
              setVisible={setImageViewerModalVisible}
              activeIndex={0}
              imageUrls={imageViewerModalImages}
            />
          </View>
        )}
      </OfflineErrorModal>
      <Portal>
        <Popup {...errorPopup.popup} />
        <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent} />
      </Portal>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      flex: 1,
      alignItems: 'center',
      paddingHorizontal: 10,
    },
    titleTxt: {
      fontWeight: '500',
      color: theme.customColors.text,
      fontSize: 20,
      marginBottom: 10,
      textAlign: 'center',
    },
    descriptionTxt: {
      fontWeight: '400',
      color: theme.customColors.text,
      fontSize: 16,
      marginBottom: 10,
    },
    formContainer: {
      width: '100%',
    },
    fileGalleryContainer: {
      marginTop: 32,
      marginBottom: 128,
    },
    buttonColumn: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 50,
    },
    button: {
      borderRadius: 5,
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center',
      alignSelf: 'center',
      flex: 1,
      width: '50%',
    },
  });
