import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';

export interface SectionTitleProps {
  title: string;
}

export const SectionTitle = ({ title }: SectionTitleProps) => {
  const styles = useMemo(() => createStyles(), []);
  return (
    <Text variant="titleLarge" style={styles.title}>
      {title}
    </Text>
  );
};

const createStyles = () =>
  StyleSheet.create({
    title: {
      textAlign: 'center',
      marginBottom: 15,
    },
  });
