import React, { useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { PaginationItem } from './PaginationItem';

export type Props = {
  count: number;
  activeIndex: number;
  onPress?(index: number): void;
  styles?: ViewStyle;
};

export const CarouselPagination = ({
  count,
  activeIndex,
  onPress,
  styles: additionalStyles,
}: Props) => {
  const items = useMemo(() => Array(count).fill(0), [count]);
  const styles = useMemo(() => createStyles(), []);

  return (
    <View style={[styles.container, additionalStyles]}>
      {items.map((_value, index) => {
        return (
          <PaginationItem
            index={index}
            key={index}
            active={activeIndex === index}
            onPress={() => onPress?.(index)}
          />
        );
      })}
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignSelf: 'center',
      height: 12,
      width: '100%',
    },
  });
