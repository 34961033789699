import React, { memo } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Snackbar } from 'react-native-paper';
import { ViewStyle } from 'react-native';
import { useAppTheme } from '../../../styles/theme';
import { SnackbarContent } from '../../../components/SnackbarContent/SnackbarContent';

interface UnverifiedContactDetailsBarProps {
  isVisible: boolean;
  onPress: () => void;
  snackStyle?: ViewStyle;
}

export const UnverifiedContactDetailsBar = memo(
  ({ isVisible, onPress, snackStyle }: UnverifiedContactDetailsBarProps) => {
    const theme = useAppTheme();
    return (
      <Snackbar
        visible={isVisible}
        onDismiss={() => {}}
        style={[
          {
            backgroundColor: theme.customColors.snackbarBackground,
            alignItems: 'center',
          },
          snackStyle,
        ]}
        onTouchEnd={onPress}
        action={{
          label: '',
          icon: () => (
            <Icon name={'cog'} size={18} color={theme.colors.primary} onPress={onPress} />
          ),
          onPress,
        }}
      >
        <SnackbarContent
          icon="alert-octagon-outline"
          text="Unverifizierte oder keine Kontaktinformationen."
        />
      </Snackbar>
    );
  },
);
