import { NoDataRefresh } from '../../components/Common/Loading/NoDataRefresh';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  RefreshControl,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Appbar, Text, useTheme } from 'react-native-paper';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Comment,
  useCommentNewsMutation,
  useInfiniteCommentsQuery,
  useReplyToNewsCommentMutation,
  useSingleNewsToReadQuery,
} from '../../../graphql/operations';
import { CommentInput } from '../../components/News/CommentInput';
import { FlatList } from 'react-native';
import { NewsComment } from '../../components/News/NewsComment';
import { NewsDetailScreenProps } from './types';
import { OfflineErrorModal } from '../../components/Common/Modals/OfflineErrorModal';
import { themeProp } from '../../types/main';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../stores/store';
import Animated, { SlideInLeft } from 'react-native-reanimated';
import { NewsContent } from '../../components/News/NewsContent';
import { initUseHandleAddComment } from './hooks/initUseHandleAddComment';
import { useFleetingComment } from './hooks/useFleetingComment';
import { TranslationSetupPopUp } from '../../components/Common/Modals/TranslationSetupPopUp';
import { useAppStatus } from '../../hooks/common/appStatus';
import { useRefreshControlWithTimeout } from '../../hooks/list/useRefreshControlWithTimeout';
import { AppBar } from '../../components/AppBar/AppBar';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export const NewsDetail = (props: NewsDetailScreenProps) => {
  const theme = useTheme() as themeProp;
  const styles = createStyles(theme);
  const queryClient = useQueryClient();
  const newsQuery = useSingleNewsToReadQuery({ newsId: props.route.params.newsId });
  const news = useMemo(() => newsQuery.data?.news, [newsQuery.data]);
  const userProfile = useStore(s => s.userProfile);
  const [messageToCopy, setMessageToCopy] = useState('');
  const commentNewsMutation = useCommentNewsMutation();
  const insets = useSafeAreaInsets();
  const replyCommentMutation = useReplyToNewsCommentMutation();
  const commentQuery = useInfiniteCommentsQuery(
    'pageInput',
    { pageInput: { pageSize: 8 }, newsId: props.route.params.newsId },
    {
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.comments.comments.length,
          0,
        );
        if (alreadyFetched === lastPage.comments.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 8,
            token: lastPage.comments?.token,
          },
          newsId: props.route.params.newsId,
        };
      },
    },
  );

  const [inReplyTo, setInReplyTo] = useState<{
    commentId: string;
    parentAuthorId: string;
    name: string;
  } | null>(null);

  const refetchAll = async () => {
    await newsQuery.refetch();
    await commentQuery.refetch();
  };

  const { appMovedToView } = useAppStatus();

  useEffect(() => {
    /* @todo must be awaited */
    void onRefresh();
  }, []);

  useEffect(() => {
    if (appMovedToView) {
      /* @todo must be awaited */
      void onRefresh();
    }
  }, [appMovedToView]);

  const { fleetingComment, setFleetingComment } = useFleetingComment();

  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    if (commentQuery.data) {
      setComments(commentQuery.data?.pages.flatMap(page => page.comments.comments));
    }
  }, [commentQuery.data]);

  const handleComment = initUseHandleAddComment({
    inReplyTo: inReplyTo,
    setInReplyTo: setInReplyTo,
    comments: comments,
    setComments: setComments,
    setFleetingComment: setFleetingComment,
    replyCommentMutation: replyCommentMutation,
    commentNewsMutation: commentNewsMutation,
    queryClient: queryClient,
  });

  const translationEnabled = props.route.params.translationEnabled;
  const translationLanguage = props.route.params.translationLanguage;

  const [isTranslationSetupPopUpShown, setTranslationSetupPopUpShown] = useState(false);

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => refetchAll(),
    errorMessageToLog: `failed to refetch data for the news detail screen`,
  });

  if (newsQuery.isLoading) {
    return (
      <NoDataRefresh
        icon="plus-circle-outline"
        text="News wird geladen..."
        isLoading={newsQuery.isLoading}
      />
    );
  }

  if (!news) {
    return <Text>Keine News gefunden</Text>; // @todo add a proper component here
  }

  return (
    <View style={[styles.screen, { paddingBottom: insets.bottom }]}>
      <OfflineErrorModal messageToCopy={messageToCopy}>
        {onOnline => (
          <KeyboardAvoidingView
            enabled={Platform.OS === 'ios'}
            style={styles.keyboardAvoidingView}
            behavior="padding"
            testID="NewsComponent"
          >
            <AppBar
              title={'News'}
              renderActionsLeftOfTitle={() => (
                <Appbar.BackAction onPress={() => props.navigation.goBack()} />
              )}
            />
            <TranslationSetupPopUp
              visible={isTranslationSetupPopUpShown}
              setVisible={setTranslationSetupPopUpShown}
            />
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={styles.touchable}>
              <FlatList
                ListHeaderComponent={
                  <NewsContent
                    news={news}
                    userGroupLabels={props.route.params.userGroupLabels}
                    isInDetailedView
                    setMessageToCopy={setMessageToCopy}
                    onOnline={onOnline}
                    translationEnabled={translationEnabled}
                    translationLanguage={translationLanguage}
                    setTranslationSetupPopUpShown={setTranslationSetupPopUpShown}
                    groupIdsInNewsTimelineQuery={news.userGroupIds}
                  />
                }
                data={comments}
                renderItem={({ item: comment }) => (
                  <Animated.View
                    style={styles.commentContainer}
                    entering={
                      fleetingComment.displayed && !fleetingComment.inReplyTo.commentId
                        ? SlideInLeft
                        : undefined
                    }
                  >
                    <NewsComment
                      key={comment.id}
                      item={comment}
                      highlighted={inReplyTo?.commentId === comment?.id}
                      viewMore={false}
                      shouldRenderReplies
                      onReply={(val: {
                        commentId: string;
                        name: string;
                        parentAuthorId: string;
                      }) => {
                        setInReplyTo(val);
                      }}
                      newsId={news.id}
                      onPress={() => {}}
                      userGroupIds={news.userGroupIds ?? news.userGroupId}
                      fleetingComment={fleetingComment}
                    />
                  </Animated.View>
                )}
                ListEmptyComponent={
                  news.settings.commentsEnabled ? (
                    <Text style={styles.listEmptyContainer}>Noch keine Kommentare</Text>
                  ) : (
                    <></>
                  )
                }
                onEndReachedThreshold={2}
                /* @todo must be awaited */
                onEndReached={() => void commentQuery.fetchNextPage()}
                refreshControl={
                  /* @todo must be awaited */
                  <RefreshControl
                    refreshing={isRefreshing}
                    onRefresh={() => void onRefresh()}
                    tintColor={theme.customColors.refreshControlSpinner}
                  />
                }
              />
            </TouchableWithoutFeedback>
            {news.settings.commentsEnabled && (
              <View>
                <CommentInput
                  isLoading={commentNewsMutation.isLoading || replyCommentMutation.isLoading}
                  onChange={content => {
                    setMessageToCopy(content);
                    onOnline(() => handleComment(news, userProfile, content));
                  }}
                  onClearReplyTo={() => setInReplyTo(null)}
                  inReplyTo={inReplyTo}
                />
              </View>
            )}
          </KeyboardAvoidingView>
        )}
      </OfflineErrorModal>
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    screen: { height: '100%', backgroundColor: theme.colors.background, flex: 1 },
    keyboardAvoidingView: {
      height: '100%',
    },
    touchable: { flex: 1 },
    commentContainer: { paddingHorizontal: 10 },
    listEmptyContainer: {
      color: theme.customColors.textGray,
      alignSelf: 'center',
      textAlign: 'center',
    },
  });
