import { View } from 'react-native';
import { Text, Button, Portal, TextInput } from 'react-native-paper';
import React, { useReducer, useState } from 'react';

import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { StackScreenProps } from '@react-navigation/stack';
import { authStyles as styles } from '../../styles/authStyles';
import { useForgotPasswordMutation, UserType } from '../../../graphql/operations';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { userInputValidationSchema } from '../../utils/authUtils';
import { GenericPopUp } from '../../stories/Modal/GenericPopUp';
import { useAppTheme } from '../../styles/theme';
import { useStore } from '../../stores/store';
import { getCognitoError } from '../../utils';
import { AppNavigatorParamList } from '../../router/AppNavigator';

type ResetPasswordProps = StackScreenProps<AppNavigatorParamList, 'ResetPassword'>;

export const ResetPassword = (props: ResetPasswordProps) => {
  const theme = useAppTheme();
  const tenantName = useStore(s => s.tenantName);
  const [errorPopupOpen, toggleErrorPopupOpen] = useReducer(previous => !previous, false);
  const [errorPopup, setErrorPopup] = useState({
    title: 'Fehler',
    text: 'Es ist ein unbekannter Fehler aufgetreten',
  });

  const validationSchema = Yup.object().shape(userInputValidationSchema);

  const handleCognitoError = (error: string) => {
    const { title, text } = getCognitoError(error);
    setErrorPopup({ title, text });
    toggleErrorPopupOpen();
  };

  const onSubmit = async (values: { user: string }) => {
    try {
      const fetcher = await useForgotPasswordMutation.fetcher({
        input: {
          loginUsername: values.user,
          userType: UserType.Employee,
        },
        tenantName,
      })();

      void fetcher.forgotPassword; // this can be used to read the delivery medium and communicate it to the user

      props.navigation.navigate('NewPassword', {
        action: 'FORCE_CHANGE_PASSWORD',
        username: values.user,
        channel: 'E-Mail bzw. SMS',
      });
    } catch (err) {
      const error = err as unknown as Error;
      handleCognitoError(error.message);
    }
  };

  return (
    <BaseAuthScreen
      headline="Passwort zurücksetzen"
      description="Geben Sie Ihren Benutzernamen, E-Mail Adresse oder Telefonnummer mit Länderkennung ein (z. B. +49 1608348859), damit wir Ihr
    Passwort zurücksetzen können."
      justify
    >
      <View style={{ width: '100%', maxWidth: theme.content.maxWidth }}>
        <Formik
          initialValues={{ user: props.route.params.username ?? '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange
        >
          {({ errors, handleSubmit, handleChange, handleBlur, values, isValid, isSubmitting }) => (
            <View>
              <TextInput
                mode="flat"
                style={styles(theme).textInput}
                error={!!errors.user}
                value={values.user}
                onChangeText={handleChange('user')}
                onBlur={handleBlur('user')}
                label="Benutzername, E-Mail oder Telefonnummer"
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="off"
                returnKeyType="send"
                onSubmitEditing={() => isValid && handleSubmit()}
              />
              {errors.user && <Text style={styles(theme).error}>{errors.user}</Text>}
              <Button
                style={{ marginTop: 25, borderRadius: 5 }}
                mode="contained"
                disabled={!isValid || isSubmitting}
                onPress={() => handleSubmit()}
                textColor={theme.customColors.textWhite}
              >
                Bestätigungscode anfordern
              </Button>
            </View>
          )}
        </Formik>
      </View>
      <Portal>
        <GenericPopUp
          headlineTitle={errorPopup.title}
          visible={errorPopupOpen}
          onDismiss={toggleErrorPopupOpen}
        >
          <Text>{errorPopup.text}</Text>
        </GenericPopUp>
      </Portal>
    </BaseAuthScreen>
  );
};
