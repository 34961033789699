import { useEffect, useState } from 'react';
import * as LocalAuthentication from 'expo-local-authentication';
import { useShouldShowBiometricsPopup } from '../useShouldShowBiometricsPopup/useShouldShowBiometricsPopup';
import { Log } from '../../utils/Log';
import { alert } from '../../utils/alert';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Constants, storeBiometrics } from '../../utils';
import { useStore } from '../../stores/store';
import { toast } from '../../utils/toast/toast';

export type RequestBiometricsPopupPropTypes = {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const useRequestBiometricsPopup = (
  userMustAgreeToTermsOfService: boolean,
): RequestBiometricsPopupPropTypes => {
  const shouldShowBiometricPopup = useShouldShowBiometricsPopup(userMustAgreeToTermsOfService);
  const [isVisible, setIsVisible] = useState(shouldShowBiometricPopup);
  const { userProfile, getUserPassword, unsetUserPassword } = useStore();
  const userPassword = getUserPassword();

  const onConfirm = async () => {
    try {
      if (!userProfile?.user?.loginUsername) {
        throw new Error('no login username');
      }
      if (!userPassword) {
        throw new Error('password not found');
      }
      const result = await LocalAuthentication.authenticateAsync({
        cancelLabel: 'Abbrechen',
        promptMessage: 'Bitte bestätigen Sie die Aktivierung des biometrischen Logins.',
        disableDeviceFallback: true,
      });
      if (result.success) {
        await storeBiometrics(userProfile.user.loginUsername, userPassword);
        toast('Zur Authentifizierung können Sie von nun an den biometrischen Login nutzen.');
      }
    } catch (e) {
      alert(
        'Authentifizierung fehlgeschlagen',
        'Sie können in den App Einstellungen versuchen den biometrischen Login nochmal zu aktivieren.',
      );
      Log.error(e, { message: 'cannot confirm biometric authentication' });
    } finally {
      await AsyncStorage.setItem(Constants.BIOMETRICS_HAS_USER_RECEIVED_POPUP, '1');
      setIsVisible(false);
      unsetUserPassword();
    }
  };

  const onCancel = async () => {
    await AsyncStorage.setItem(Constants.BIOMETRICS_HAS_USER_RECEIVED_POPUP, '1');
    unsetUserPassword();
    setIsVisible(false);
  };

  useEffect(() => {
    if (shouldShowBiometricPopup) {
      setIsVisible(true);
    }
  }, [shouldShowBiometricPopup]);

  return {
    isVisible,
    onConfirm,
    onCancel,
  };
};
