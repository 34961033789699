import * as FileSystem from 'expo-file-system';
import { getUriPathOnDevice } from './getUriPathOnDevice';
import { Log } from '../../../utils/Log';

export const getCachedImageUri = async (uri: string) => {
  try {
    const uriPath = getUriPathOnDevice(uri);

    const fileInfoForHashNameWithExtension = await FileSystem.getInfoAsync(uriPath);

    if (fileInfoForHashNameWithExtension.exists) {
      return fileInfoForHashNameWithExtension.uri;
    }
  } catch (e) {
    Log.error(e, { message: 'cannot get cached image' });
  }
};
