import { Appbar, Button, Headline, Subheading, TextInput } from 'react-native-paper';
import { Keyboard, Platform, TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import React, { useCallback, useMemo, useState } from 'react';
import Constants from 'expo-constants';
import { OfflineErrorModal } from '../../components/Common/Modals/OfflineErrorModal';
import { useSendFeedbackMutation } from '../../../graphql/operations';
import { useStore } from '../../stores/store';
import { KeyboardAvoidingView } from '../../components/HigherOrder';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { AppBar } from '../../components/AppBar/AppBar';
import { StackScreenProps } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { toast } from '../../utils/toast/toast';

type ReportAbuseScreenPropTypes = StackScreenProps<AppNavigatorParamList, 'ReportAbuse'>;

export const ReportAbuseScreen = ({ navigation }: ReportAbuseScreenPropTypes) => {
  const sendFeedback = useSendFeedbackMutation();
  const userProfile = useStore(s => s.userProfile);
  const theme = useAppTheme();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const styles = useMemo(() => createStyles(theme), [theme]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = useCallback(async () => {
    setIsLoading(true);
    const formattedDescription =
      `${'# App Report' + '\n>>>\n'}${content}\n>>>\n## Error\n\`\`\`json\n${JSON.stringify(
        { error: 'no errors' },
        null,
        2,
      )}\n\`\`\`` +
      `\n## UserProfile\n\`\`\`json\n${JSON.stringify(
        userProfile || { attributes: 'no user logged in' },
        null,
        2,
      )}\n\`\`\`\n## Platform\n\`\`\`json\n${JSON.stringify(
        Platform,
        null,
        2,
      )}\n\`\`\`\n## Constants\n\`\`\`json\n${JSON.stringify(Constants, null, 2)}\n\`\`\``;

    sendFeedback.mutate(
      {
        input: {
          title,
          content: formattedDescription,
        },
      },
      {
        onSuccess: () => {
          setTitle('');
          setContent('');
          setIsLoading(false);
          toast(
            'Vielen Dank für Ihre Meldung! Wir werden die Angelegenheit so schnell wie möglich untersuchen.',
          );
          navigation.goBack();
        },
        onError: () => {
          toast('Missbrauch melden fehlgeschlagen. Bitte versuchen Sie es erneut.');
          setIsLoading(false);
          navigation.goBack();
        },
      },
    );
  }, [userProfile, title, content]);

  return (
    <>
      <AppBar
        title="Melden"
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <OfflineErrorModal>
        {onOnline => (
          <View style={styles.container}>
            <KeyboardAvoidingView style={styles.inputContainer} behavior="padding">
              <TouchableWithoutFeedback
                onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}
              >
                <View style={styles.inputItems}>
                  <Headline style={styles.headline}>Missbrauch melden</Headline>
                  <View style={styles.subheadingContainer}>
                    <Subheading style={styles.subheadingContainer}>
                      Wo ist der Missbrauch aufgetreten?
                    </Subheading>
                    <TextInput
                      value={title}
                      onChangeText={setTitle}
                      style={styles.textInput}
                      onFocus={() => onOnline(() => {})}
                    />
                  </View>
                  <View style={styles.subheadingContainer}>
                    <Subheading style={styles.subheadingContainer}>
                      Weitere Informationen
                    </Subheading>
                    <TextInput
                      value={content}
                      onChangeText={setContent}
                      multiline
                      numberOfLines={5}
                      style={styles.textInput}
                      onFocus={() => onOnline(() => {})}
                    />
                    <Button
                      loading={isLoading}
                      mode="contained"
                      disabled={isLoading || !title || title.trim() === ''}
                      onPress={() => onOnline(async () => await handleSend())}
                      style={styles.sendButton}
                      textColor={theme.customColors.textWhite}
                    >
                      Absenden
                    </Button>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
          </View>
        )}
      </OfflineErrorModal>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 25,
      backgroundColor: theme.colors.background,
    },
    inputContainer: {
      flex: 1,
      maxHeight: 350,
    },
    inputItems: {
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    headline: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    subheadingContainer: {
      width: '90%',
    },
    subheading: { marginBottom: 14 },
    textInput: { backgroundColor: theme.customColors.textInputBackground },
    sendButton: { alignSelf: 'center', marginTop: 20, borderRadius: 5 },
    skipButtonText: { fontSize: 11 },
  });
