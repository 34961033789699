import React from 'react';
import { Linking, Platform, View } from 'react-native';
import { Button } from 'react-native-paper';
import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { baseTheme as theme } from '../../styles/theme';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { androidStoreUrl, iosStoreUrl } from '../../../app.config';

export const UpdateRequiredScreen = () => (
  <BaseAuthScreen
    renderTop={() => (
      <View
        style={{
          marginTop: 100,
          padding: 25,
          borderRadius: 20,
          marginBottom: 40,
          backgroundColor: theme.customColors.gray96,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.2,
          shadowRadius: 1.41,
          elevation: 2,
        }}
      >
        <Icon size={90} color={theme.customColors.primary} name="alert-circle-outline" />
      </View>
    )}
    justify
    paddingHorizonal={45}
    headline="Update benötigt"
    description={`Sie benötigen eine neuere Version der App, um sie weiter nutzen zu können.`}
  >
    <Button
      style={{ borderRadius: 5 }}
      mode="contained"
      onPress={async () => {
        await Linking.openURL(
          Platform.select({
            ios: iosStoreUrl,
            android: androidStoreUrl,
            // android: 'market://details?id=com.likwidcare.luci'
          }) as string,
        );
      }}
      textColor={theme.customColors.textWhite}
    >
      {Platform.select({ ios: 'App Store öffnen', android: 'Google Play öffnen' })}
    </Button>
    {/* <Text style={{ marginTop: 40 }}>
      installiert: {currentVersion}, erwartet: {expectedVersion}
    </Text> */}
  </BaseAuthScreen>
);
