import * as LocalAuthentication from 'expo-local-authentication';
import { Log } from '../../../utils/Log';
import { Divider, Headline, Portal, Subheading, useTheme } from 'react-native-paper';
import { ModalInputFieldType, themeProp } from '../../../types/main';
import React, { useState } from 'react';
import { InputModal } from '../../../components/Common/Modals/InputModal';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { useBiometrics } from '../../../hooks/preferences/biometrics';
import { useStore } from '../../../stores/store';
import { Constants, storeBiometrics } from '../../../utils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { deleteItemAsync } from 'expo-secure-store';
import { SwitchSetting } from '../../../components/SwitchSetting/SwitchSetting';
import { toast } from '../../../utils/toast/toast';

export const BiometricSetting = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [inputModalVisible, setInputModalVisible] = useState(false);
  const userProfile = useStore(s => s.userProfile);
  const { biometricEnabled, updateBiometricEnabled } = useBiometrics();

  const theme = useTheme() as themeProp;

  const handleBiometricSetting = async (value: boolean) => {
    setIsUpdating(true);
    if (value === true) {
      const deviceHasBio = await LocalAuthentication.isEnrolledAsync();
      if (deviceHasBio) {
        const authenticated = await LocalAuthentication.authenticateAsync({
          promptMessage: 'Biometrische Daten bestätigen',
          cancelLabel: 'Abbrechen',
          disableDeviceFallback: true,
        });
        if (authenticated.success) {
          setInputModalVisible(true);
        } else {
          setIsUpdating(false);
        }
      } else {
        toast('Bitte konfigurieren Sie die Biometrischen Daten in den Geräteeinstellungen.');
        setIsUpdating(false);
      }
    } else {
      await AsyncStorage.removeItem(Constants.BIOMETRICS_ENABLED);
      await deleteItemAsync(Constants.BIOMETRICS_STORAGE_USERNAME_KEY);
      await deleteItemAsync(Constants.BIOMETRICS_STORAGE_PASSWORD_KEY);
      /* @todo must be awaited */
      void updateBiometricEnabled(value);
      setIsUpdating(false);
    }
  };

  const handlePasswordVerify = async (input: Array<ModalInputFieldType>) => {
    setIsAuthenticating(true);
    if (userProfile?.user?.loginUsername && input[0].value) {
      try {
        await useStore.getState().login({
          username: userProfile.user.loginUsername,
          password: input[0].value,
        });
        await storeBiometrics(userProfile.user.loginUsername, input[0].value);
        setIsAuthenticating(false);
        setInputModalVisible(false);
        /* @todo must be awaited */
        void updateBiometricEnabled(true);
        setIsUpdating(false);
      } catch (error) {
        Log.warning(error);
        setIsAuthenticating(false);
        toast('Falsches Passwort.');
      }
    } else {
      setIsAuthenticating(false);
      toast('Bitte geben Sie ein Passwort ein.');
    }
  };

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <Subheading style={{ marginTop: 25 }}>Login Optionen</Subheading>
          <Divider style={{ marginVertical: 10 }} />
          <SwitchSetting
            label="Biometrischer Login"
            testID="switch"
            value={biometricEnabled}
            onValueChange={val => onOnline(() => void handleBiometricSetting(val))}
            disabled={isUpdating}
            color={theme.customColors.primary}
          />
          <Portal>
            <InputModal
              visible={inputModalVisible}
              title={'Bitte bestätigen Sie Ihr Passwort'}
              onDismiss={() => {
                setIsUpdating(false);
                setInputModalVisible(false);
              }}
              onCancel={() => {
                setIsUpdating(false);
                setInputModalVisible(false);
              }}
              onConfirm={(responseArray: Array<ModalInputFieldType>) =>
                /* @todo must be awaited */
                void handlePasswordVerify(responseArray)
              }
              inputFields={[
                { label: 'Passwort', placeholder: 'Passwort', secure: true, capitalize: false },
              ]}
              iosKeyboardOffset={0}
              disabled={isAuthenticating}
            />
          </Portal>
          <Portal>
            <LoadingBlocker visible={isAuthenticating} spinnerColor={theme.customColors.accent}>
              <Headline
                style={{
                  color: '#fff',
                }}
              >
                Einen Moment bitte.
              </Headline>
            </LoadingBlocker>
          </Portal>
        </>
      )}
    </OfflineErrorModal>
  );
};
