import { IconButton, Menu, Text } from 'react-native-paper';
import React, { useMemo, useState } from 'react';
import { ColorValue, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { getFullName, getInitials, getJob } from '../utils';
import { useGetUserProfileQuery } from '../../../../graphql/operations';
import { useNetInfo } from '@react-native-community/netinfo';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { AvatarDisplayShimmer } from '../../../components/Common/AvatarDisplay/AvatarDisplayShimmer';
import { LineOfTextShimmer } from '../../../components/Shimmer/LineOfTextShimmer';
import { WithLoadingIndicator } from '../../../components/WithLoadingIndicator/WithLoadingIndicator';

export const UserCard = (props: {
  userId?: string;
  date?: string | number;
  menuItems?: React.ReactNode;
  style?: ViewStyle;
  descriptionOverride?: string;
  onPress?: () => void;
  callToAction?: { text: string; onPress: () => void };
  touchable?: boolean;
  hasChevron?: boolean;
  rightAligned?: boolean;
}): JSX.Element => {
  const theme = useAppTheme();
  const styles = useMemo(
    () => createStyles(theme, props.style?.backgroundColor),
    [theme, props.style],
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const netInfo = useNetInfo();
  const userItem = useGetUserProfileQuery(
    {
      userId: props.userId ?? '',
    },
    {
      enabled: !!props.userId && (netInfo.isConnected ?? false),
    },
  );

  const renderContent = () => (
    <>
      <View style={props.rightAligned ? styles.userContainerRightAligned : styles.userContainer}>
        <View style={props.rightAligned ? styles.avatarRightAligned : styles.avatar}>
          <WithLoadingIndicator
            on={!!userItem.data}
            render={() => (
              <AvatarDisplay
                size={50}
                avatar={{
                  otcPath: userItem.data!.userProfile.pictureOTC ?? undefined,
                  initials: getInitials(userItem.data!.userProfile),
                }}
                type={UploadType.ProfilePicture}
              />
            )}
            renderWhileLoading={() => <AvatarDisplayShimmer size={50} />}
          />
        </View>
        <View style={styles.content}>
          <WithLoadingIndicator
            on={!!userItem.data}
            render={() => (
              <Text style={styles.name}>{getFullName(userItem.data!.userProfile)}</Text>
            )}
            renderWhileLoading={() => (
              <LineOfTextShimmer fontSize={styles.name.fontSize} width={96} />
            )}
          />
          {props?.descriptionOverride ? (
            <Text style={styles.description}>{props.descriptionOverride}</Text>
          ) : (
            <>
              <WithLoadingIndicator
                on={!!userItem.data}
                render={() => (
                  <Text style={styles.description}>{getJob(userItem.data!.userProfile)}</Text>
                )}
                renderWhileLoading={() => <LineOfTextShimmer width={128} />}
              />
              {props.date && (
                <Text style={styles.date}>{new Date(props.date).toLocaleDateString()}</Text>
              )}
            </>
          )}
          {props.callToAction && (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => props?.callToAction?.onPress()}
              style={styles.ctaButton}
            >
              <Text style={styles.ctaButtonText}>{props.callToAction.text}</Text>
            </TouchableOpacity>
          )}
        </View>
        {props.hasChevron && (
          <IconButton
            style={styles.chevron}
            icon={props.rightAligned ? 'chevron-left' : 'chevron-right'}
            size={30}
            iconColor={theme.customColors.icon}
            onPress={props.onPress}
          />
        )}
      </View>
      {props.menuItems && (
        <Menu
          style={styles.menuContainer}
          onDismiss={() => setMenuOpen(false)}
          visible={menuOpen}
          contentStyle={{ marginTop: 42 }}
          anchor={
            <IconButton
              style={styles.menu}
              icon="dots-vertical"
              size={24}
              onPress={() => {
                setMenuOpen(true);
              }}
            />
          }
        >
          {props.menuItems}
        </Menu>
      )}
    </>
  );

  if (props.touchable) {
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        style={[styles.container, props.style]}
        onPress={props.onPress}
      >
        {renderContent()}
      </TouchableOpacity>
    );
  }

  return <View style={[styles.container, props.style]}>{renderContent()}</View>;
};

const createStyles = (theme: AppTheme, backgroundColor?: ColorValue) =>
  StyleSheet.create({
    container: {
      backgroundColor: backgroundColor ?? theme.customColors.pureWhite,
      borderColor: theme.customColors.borders,
      borderWidth: 1,
      borderRadius: theme.roundness,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingHorizontal: 12,
      paddingVertical: 10,
    },
    content: {
      marginLeft: 10,
      flex: 1,
    },
    userContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    userContainerRightAligned: {
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuContainer: {
      flex: 1,
    },
    name: {
      fontWeight: 'bold',
      fontSize: 15,
    },
    description: {
      color: theme.customColors.textGray,
    },
    date: {
      color: theme.customColors.textGray,
    },
    menu: {
      alignSelf: 'flex-end',
      marginRight: -4,
    },
    avatar: {
      alignSelf: 'flex-start',
      marginRight: 10,
    },
    avatarRightAligned: {
      alignSelf: 'flex-end',
      marginLeft: 32,
    },
    ctaButton: {
      backgroundColor: theme.customColors.primary,
      borderRadius: 5,
      paddingVertical: 5,
      paddingHorizontal: 12,
      marginTop: 10,
      marginBottom: 4,
      alignSelf: 'flex-start',
    },
    ctaButtonText: {
      color: theme.customColors.pureWhite,
      fontSize: 15,
      fontWeight: '600',
    },
    chevron: { margin: 0, padding: 0 },
  });
