import { Button, Caption, TextInput } from 'react-native-paper';
import React, { useState } from 'react';
import { Text, TextInputProps, View } from 'react-native';

import { BaseAuthScreen } from '../../components/Auth/BaseAuthScreen';
import { authStyles as styles } from '../../styles/authStyles';
import { useFocusFields } from '../../hooks';
import { useAppTheme } from '../../styles/theme';

interface IPasswdInput {
  label: string;
  errorNotice?: string;
  invalid?: boolean;
  onChangeText: (val: string) => void;
  refFunction?: (ref: unknown | null) => void;
  returnKeyType: TextInputProps['returnKeyType'];
  onSubmitEditing: TextInputProps['onSubmitEditing'];
}

const PasswdInput = ({
  label,
  errorNotice,
  invalid,
  onChangeText,
  refFunction,
  returnKeyType,
  onSubmitEditing,
}: IPasswdInput) => {
  const theme = useAppTheme();
  return (
    <View style={{ width: '100%', marginBottom: 14 }}>
      <Text>{label}</Text>
      <TextInput
        ref={refFunction}
        secureTextEntry
        dense
        style={{ backgroundColor: theme.customColors.textInputBackground }}
        mode="outlined"
        onChangeText={onChangeText}
        autoCapitalize="none"
        autoCorrect={false}
        returnKeyType={returnKeyType}
        onSubmitEditing={onSubmitEditing}
      />
      <View style={{ height: 20 }}>
        {errorNotice && invalid && <Caption style={{ color: '#d00' }}>{errorNotice}</Caption>}
      </View>
    </View>
  );
};

interface NewPasswordProps {
  onSubmitForm: (password: string) => void;
}

export const NewPasswordAbstract = ({ onSubmitForm }: NewPasswordProps) => {
  const { collectFocusRef, focusNext } = useFocusFields();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const theme = useAppTheme();

  const passwordContainsWhitespace = (input: string) => {
    return input.includes(' ');
  };

  const isSubmitDisabled =
    !password ||
    !confirmPassword ||
    password.trim().length < 8 ||
    passwordContainsWhitespace(password.trim()) ||
    password !== confirmPassword;

  return (
    <BaseAuthScreen
      headline="Neues Passwort vergeben"
      description={`Ihr neues Passwort muss aus mindestens 8 Zeichen bestehen und darf keine Leerzeichen enthalten.`}
      justify
    >
      <View style={{ width: '100%', maxWidth: theme.content.maxWidth }}>
        <PasswdInput
          label="Passwort"
          onChangeText={setPassword}
          errorNotice="Mindestlänge 8 Zeichen und keine Leerzeichen"
          invalid={
            (!!password && password.trim().length < 8) ||
            passwordContainsWhitespace(password.trim())
          }
          returnKeyType="next"
          onSubmitEditing={() => focusNext('password2')}
        />
        <PasswdInput
          label="Passwort wiederholen"
          onChangeText={setConfirmPassword}
          errorNotice="Die Passwörter müssen übereinstimmen"
          invalid={
            !!password &&
            !!confirmPassword &&
            (!password || !confirmPassword || password !== confirmPassword)
          }
          refFunction={collectFocusRef('password2')}
          returnKeyType="send"
          onSubmitEditing={() => !isSubmitDisabled && onSubmitForm(password)}
        />
        <Button
          style={[styles(theme).button, { marginTop: 20, width: '100%' }]}
          mode="contained"
          disabled={isSubmitDisabled}
          onPress={() => onSubmitForm(password)}
        >
          <Text style={styles(theme).buttonText}>Neues Passwort bestätigen</Text>
        </Button>
      </View>
    </BaseAuthScreen>
  );
};
