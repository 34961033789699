import { DrawerContentScrollView, DrawerContentComponentProps } from '@react-navigation/drawer';
import React, { useMemo } from 'react';
import { useWindowDimensions, View, StyleSheet } from 'react-native';
import { MenuScreenContent } from '../../components/Menu/MenuScreenContent';
import { themeProp } from '../../types/main';
import { DrawerBar } from '../bar/DrawerBar';
import { maxContentAreaWidth } from '../navigators/ContentNavigator';
import { useResponsiveDrawer } from './hooks/useResponsiveDrawer';
import { useAppTheme } from '../../styles/theme';

export const RightDrawerContent = (props: DrawerContentComponentProps) => {
  const theme = useAppTheme();
  const { isRightDrawerOpen } = useResponsiveDrawer({
    ...props,
    revealedAt: 'lg',
    drawerPosition: 'RightDrawer',
  });
  const { height } = useWindowDimensions();
  const styles = useMemo(() => createStyles(theme, height), [theme, height]);

  if (!isRightDrawerOpen) {
    return <></>;
  }

  return (
    <View style={{ height: '100%' }}>
      <View style={styles.topContainer}>
        <View style={styles.topContainerItems}>
          <DrawerBar {...props} headerStyle={styles.drawerHeader} />
          <View style={styles.bottomContainer}>
            <DrawerContentScrollView
              {...props}
              showsVerticalScrollIndicator={false}
              style={styles.bottomContainerItems}
            >
              <MenuScreenContent isRenderedInDrawer />
            </DrawerContentScrollView>
          </View>
        </View>
      </View>
    </View>
  );
};

const createStyles = (theme: themeProp, height: number) =>
  StyleSheet.create({
    topContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-start',
      backgroundColor: theme.colors.background,
    },
    topContainerItems: {
      flex: 1,
      maxWidth: maxContentAreaWidth / 2,
    },
    bottomContainer: {
      flex: 64,
      backgroundColor: theme.colors.background,
    },
    bottomContainerItems: { flex: 1, marginHorizontal: 6, maxHeight: height },
    drawerHeader: {
      flex: 1,
      backgroundColor: theme.colors.surface,
      borderBottomColor: theme.customColors.borders,
      borderBottomWidth: 1,
    },
  });
