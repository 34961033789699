import { FileType } from '../types';

export const mapGaleryReturnToFileType = (type: string | undefined) => {
  switch (type) {
    case 'image':
      return FileType.Image;
    case 'video':
      return FileType.Video;
    default:
      return FileType.Image;
  }
};
