import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { Constants } from '../../utils';
import { Log } from '../../utils/Log';

export const useBiometrics = () => {
  const [biometricEnabled, setBiometricEnabled] = useState(false);

  useEffect(() => {
    const checkBiometricEnabled = async () => {
      try {
        const isEnabled = await AsyncStorage.getItem(Constants.BIOMETRICS_ENABLED);
        setBiometricEnabled(isEnabled !== null);
      } catch (error) {
        Log.error(error);
      }
    };
    /* @todo must be awaited */
    void checkBiometricEnabled();
  }, []);

  const updateBiometricEnabled = async (isEnabled: boolean) => {
    try {
      if (!isEnabled) {
        await AsyncStorage.removeItem(Constants.BIOMETRICS_ENABLED);
      } else {
        await AsyncStorage.setItem(Constants.BIOMETRICS_ENABLED, '1');
      }
      setBiometricEnabled(isEnabled);
    } catch (error) {
      Log.error(error);
    }
  };

  return {
    biometricEnabled,
    updateBiometricEnabled,
  };
};
