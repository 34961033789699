import { invalidateChatRoomsQuery } from '../utils/invalidateChatRoomsQuery';
import {
  ChatRoomMessage,
  NewsAttachmentType,
  useCreateChatRoomMessageMutation,
} from '../../../../graphql/operations';
import { useQueryClient } from '@tanstack/react-query';
import { AttachmentPickerResultItem } from '../../../components/Common/Media/types';
import { sendPickedAttachments } from '../utils/sendPickedAttachments';
import { alert } from '../../../utils/alert';
import { sendChatRoomMessageErrorHandler } from '../utils/sendChatRoomMessageErrorHandler';
import { useCallback, useState } from 'react';
import { Log } from '../../../utils/Log';
import { useStore } from '../../../stores/store';
import { createPendingChatRoomMessage } from '../utils/createPendingChatRoomMessage';

interface IUseSendAttachments {
  chatRoomId: string;
  endPendingStateOfMessage: (messageId: ChatRoomMessage['id']) => void;
  addToMessagesAsPending: (chatRoomMessage: ChatRoomMessage) => void;
}

export const useSendAttachments = ({
  chatRoomId,
  endPendingStateOfMessage,
  addToMessagesAsPending,
}: IUseSendAttachments) => {
  const queryClient = useQueryClient();
  const appUser = useStore(s => s.userProfile);
  const createChatRoomMessageMutation = useCreateChatRoomMessageMutation();
  const [isSending, setSending] = useState(false);

  const sendAttachments = useCallback(
    async (attachmentPickerResultItems: AttachmentPickerResultItem[]) => {
      if (!appUser) {
        return;
      }
      try {
        attachmentPickerResultItems.forEach(attachment => {
          const pendingMessage = createPendingChatRoomMessage({
            id: attachment.id,
            appUser,
            chatRoomId,
            content: attachment.fallbackText,
            attachment: {
              otcPath: attachment.otcPath,
              fileName: attachment.fileName,
              type: attachment.type as NewsAttachmentType,
            },
          });
          addToMessagesAsPending(pendingMessage);
        });

        const chatRoomMessages = await sendPickedAttachments({
          chatRoomId,
          attachmentPickerResultItems,
          createChatRoomMessageMutation,
          onError: e =>
            sendChatRoomMessageErrorHandler({
              e: e as Error,
              onNotSentDueToBlock: () => {
                alert(
                  'Nachricht wurde nicht gesendet',
                  'Sie können keine Nachrichten von diesem Benutzer empfangen oder an ihn senden.',
                );
              },
            }),
        });
        chatRoomMessages.forEach(message => {
          endPendingStateOfMessage(message.createChatRoomMessage.id);
        });
        setSending(false);
        await invalidateChatRoomsQuery({ queryClient });
      } catch (e) {
        const err = e as Error;
        Log.error(err, {
          message: `an error occurred while sending chat room message containing attachment(s): ${err.message}`,
        });
      } finally {
        setSending(false);
      }
    },
    [appUser, chatRoomId, queryClient],
  );

  return { sendAttachments, isSending };
};
