import {
  ChatRoomMessage as ChatRoomMessageGql,
  ChatRole,
  ChatMessageReadReceiptsQuery,
  ChatRoomMessagesQuery,
} from '../../../graphql/operations';
import { SelectedForm, SelectedInfoPage } from '../../components/Common/Media/types';

interface User {
  _id: string | number;
  name?: string;
  avatar?: string;
  role?: ChatRole;
}

interface Reply {
  title: string;
  value: string;
  messageId?: any;
}

interface QuickReplies {
  type: 'radio' | 'checkbox';
  values: Reply[];
  keepIt?: boolean;
}

export interface IMessage {
  _id: string | number;
  text: string;
  createdAt: Date | number;
  user: User;
  image?: string;
  video?: string;
  audio?: string;
  system?: boolean;
  sent?: boolean;
  received?: boolean;
  pending?: boolean;
  quickReplies?: QuickReplies;
}

export type ExtendedMessage = IMessage & {
  documentUri?: string;
  quote?: ChatRoomMessagesQuery['messageList']['messages'][number]['quote'];
  forms?: SelectedForm[];
  infoPages?: SelectedInfoPage[];
  formReply?: ChatRoomMessageGql['formReply'];
  readByCurrentUser: boolean;
  deletedAt?: Date;
  isForwarded?: boolean;
};

export enum OptionType {
  copyTexts,
  quote,
  forward,
  delete,
  moderativeDelete,
  reportAbuse,
  cancel,
  seeReadReceipts,
}

export type ChatPartner = {
  firstname: string;
  lastname: string;
  userId: string;
  picture?: string | null;
  pictureOTC?: string | null;
  role?: ChatRole;
};

export type GetAvatarForChatRoomInput = {
  isGroupChat?: boolean;
  chatPartners?: ChatPartner[] | null;
  userId?: string;
  chatImageUri?: string | null;
  name?: string | null;
};

export type ChatMessageReadReceipt =
  ChatMessageReadReceiptsQuery['chatMessageReadReceipts'][number];

export type ChatMessageReader = ChatMessageReadReceipt['user'];
