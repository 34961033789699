export const DELETED_BY_AUTHOR = 'Diese Nachricht wurde gelöscht';
export const DELETED_BY_MOD = 'Diese Nachricht wurde von einem/einer Moderator*in gelöscht';
export const DELETED_ATTACHMENT_BY_AUTHOR = 'Dieser Anhang wurde gelöscht';
export const DELETED_ATTACHMENT_BY_MOD =
  'Dieser Anhang wurde von einem/einer Moderator*in gelöscht';

export const determineDeletedContentText = (
  deletedByAuthor: boolean,
  deletedMessageHadAttachments: boolean,
) => {
  if (deletedByAuthor && deletedMessageHadAttachments) {
    return DELETED_ATTACHMENT_BY_AUTHOR;
  }
  if (!deletedByAuthor && deletedMessageHadAttachments) {
    return DELETED_ATTACHMENT_BY_MOD;
  }
  if (deletedByAuthor) {
    return DELETED_BY_AUTHOR;
  }
  return DELETED_BY_MOD;
};
