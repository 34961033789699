import parsePhoneNumberFromString from 'libphonenumber-js';
import * as Yup from 'yup';

export const getFullNameContacts = (props?: {
  titleBefore?: string | null;
  firstname?: string;
  lastname?: string;
  titleAfter?: string | null;
}) => {
  let result = '';
  if (props?.titleBefore) {
    result += `${props?.titleBefore} `;
  }
  result += `${props?.lastname}, ${props?.firstname}`;
  if (props?.titleAfter) {
    result += `, ${props?.titleAfter}`;
  }
  return result;
};

export const getJob = (props?: {
  employeeFunction?: string | null;
  department?: string | null;
}) => {
  let result = '';
  if (props?.employeeFunction) {
    result += props.employeeFunction;
  }
  if (props?.employeeFunction && props.department) {
    result += ', ';
  }
  if (props?.department) {
    result += props.department;
  }
  if (result.length === 0) {
    return null;
  }
  return result;
};

export const getInitials = (props?: { firstname?: string | null; lastname?: string | null }) => {
  let result = '';
  if (props?.firstname && props.lastname) {
    result = props?.firstname?.substr(0, 1) + props?.lastname?.substr(0, 1);
  }
  return result;
};

export const getInitialsFromString = (name: string) => {
  let res = '';
  if (!name) {
    return res;
  }
  const parts = name.split(' ');
  if (parts.length > 0) {
    res += parts[0].substr(0, 1);
  }
  if (parts.length > 1) {
    res += parts[1].substr(0, 1);
  }
  return res;
};

export const getFullName = (props?: {
  titleBefore?: string | null;
  firstname: string;
  lastname: string;
  titleAfter?: string | null;
}) => {
  if (!props) {
    return 'Ehemaliger Benutzer';
  }
  let result = '';
  if (props?.titleBefore) {
    result += `${props?.titleBefore} `;
  }
  result += `${props?.firstname} ${props?.lastname}`;
  if (props?.titleAfter) {
    result += `, ${props?.titleAfter}`;
  }
  return result;
};

export const updateProfileValidationSchema = {
  profileTextAbout: Yup.string().trim().max(800, 'Maximal 800 Zeichen'),
  profileTextInterest: Yup.string().trim().max(250, 'Maximal 250 Zeichen'),
  profileTextLanguages: Yup.string().trim().max(100, 'Maximal 100 Zeichen'),
  businessPhone: Yup.string().trim(),
  businessEmail: Yup.string().trim().email('Ungültige E-Mail-Adresse'),
  user: Yup.object({
    loginPhoneNumber: Yup.string()
      .trim()
      .test('isValidPhone', 'Ungültige Telefonnummer', value => {
        if (!value) {
          return true;
        }
        if (typeof value !== 'string') {
          return false;
        }
        const phone = parsePhoneNumberFromString(value);
        return !!phone && phone.isValid();
      })
      .test(
        'isValidPhone',
        'Länderkennung erforderlich (z.B. +49)',
        value => !value || value?.trim()?.substring(0, 1) === '+',
      ),
    phoneNumberVerified: Yup.boolean().required(),
    loginEmail: Yup.string().trim().email('Ungültige E-Mail-Adresse'),
    emailVerified: Yup.boolean().required(),
  }),
};
