import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Text, Switch, SwitchProps } from 'react-native-paper';
import { RoundedSquareIcon } from '../Common/Icon/RoundedSquareIcon';

type SwitchSettingPropTypes = SwitchProps & {
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  label: string;
};

export const SwitchSetting = ({ label, icon, ...switchProps }: SwitchSettingPropTypes) => {
  return (
    <View style={styles.container}>
      <View style={styles.innerLeftContainer}>
        {icon && <RoundedSquareIcon icon={icon} marginRight={6} />}
        <Text style={styles.label} numberOfLines={1}>
          {label}
        </Text>
      </View>
      <View style={styles.innerRightContainer}>
        <Switch testID="switch" {...switchProps} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  innerLeftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  innerRightContainer: {
    marginTop: Platform.OS === 'android' ? -8 : 0,
    flexDirection: 'row',
  },
  label: { marginTop: 4, fontSize: 14 },
});
