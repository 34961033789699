export const NETWORK_TIMEOUT_MILLISECONDS = 10000;

export const autoRejectAfterTimeout = async (fn: () => Promise<any>, timeoutDuration?: number) => {
  return Promise.race([
    fn(),
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error('Timeout')),
        timeoutDuration ?? NETWORK_TIMEOUT_MILLISECONDS,
      ),
    ),
  ]);
};
