import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { ElementRef, useRef, useState } from 'react';
import { Portal } from 'react-native-paper';
import { useConfirmForgotPasswordMutation, UserType } from '../../../graphql/operations';
import { Popup } from '../../components/Popup';
import { usePopup } from '../../hooks';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { useStore } from '../../stores/store';
import { Constants, storeBiometrics } from '../../utils';
import { getConfirmationCodeErrorDetails } from '../../utils/getConfirmationCodeErrorDetails';
import { useReloginStore } from '../../utils/ReloginStore';
import { ConfirmationCodeAbstract } from '../Auth/ConfirmationCodeAbstract';
import { useRequestConfirmationCode } from '../ReloginNewPassword/useRequestConfirmationCode';

export const ReloginConfirmationCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
  const { password, clear: clearReloginStore } = useReloginStore();
  const { userProfile, login, tenantName } = useStore();
  const confirmationCodeRef = useRef<ElementRef<typeof ConfirmationCodeAbstract> | null>(null);
  const { mutateAsync: confirmForgotPassword } = useConfirmForgotPasswordMutation();
  const errorPopup = usePopup('Fehler', 'Ein unbekannter Fehler ist aufgetreten');
  const successPopup = usePopup('Erfolgreich!', 'Ihr Passwurd wurde erfolgreich erneuert.', () => {
    successPopup.toggleOpen();
    clearReloginStore();
    navigation.getParent()?.goBack();
  });
  const { requestConfirmationCode } = useRequestConfirmationCode(errorPopup);

  const onCodeSend = async (code: string) => {
    const loginUsername = userProfile?.user?.loginUsername;
    if (!loginUsername || !password) {
      return errorPopup
        .setPopupDetails({
          title: 'Ein Fehler ist aufgetreten.',
          text: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.',
        })
        .toggleOpen();
    }
    setIsLoading(true);

    try {
      await confirmForgotPassword({
        input: {
          userType: UserType.Employee,
          confirmationCode: code,
          newPassword: password,
          loginUsername,
        },
        tenantName,
      });
      await login({
        username: loginUsername,
        password,
      });
      const isBiometricsEnabled = await AsyncStorage.getItem(Constants.BIOMETRICS_ENABLED);
      if (isBiometricsEnabled) {
        await storeBiometrics(loginUsername, password);
      }
      successPopup.toggleOpen();
    } catch (e) {
      const errorDetails = getConfirmationCodeErrorDetails(e as Error);
      errorPopup.setPopupDetails({
        ...errorDetails,
      });
      errorPopup.toggleOpen();
      confirmationCodeRef.current?.setCodeValue('');
    } finally {
      setIsLoading(false);
    }
  };

  const onPressResend = async () => {
    try {
      await requestConfirmationCode();
    } catch (e) {
      return;
    }
    navigation.navigate('ReloginCheckInbox');
  };

  return (
    <>
      <ConfirmationCodeAbstract
        ref={confirmationCodeRef}
        isLoading={isLoading}
        onPressResend={onPressResend}
        onSend={onCodeSend}
        showResendMessage
        channel="SMS"
      />
      <Portal>
        <Popup {...errorPopup.popup} />
        <Popup {...successPopup.popup} />
      </Portal>
    </>
  );
};
