import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { TileProps } from '../Tiles/TileFactoryProps';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Text } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';

export function DocumentTile<P>({ file, width }: TileProps<P>) {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(width, theme), [width, theme]);
  return (
    <View style={styles.container}>
      <MaterialCommunityIcons name="file-document" size={80} color={theme.colors.onSurface} />
      <Text numberOfLines={1} ellipsizeMode="middle">
        {file.name}
      </Text>
    </View>
  );
}

const createStyles = (width: TileProps<void>['width'], theme: AppTheme) =>
  StyleSheet.create({
    container: {
      height: width,
      width,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      backgroundColor: theme.customColors.background2,
      borderColor: theme.customColors.borders,
      borderWidth: 1,
    },
  });
