import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import {
  Linking,
  StyleSheet,
  View,
  TextProps,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import { Log } from '../../../utils/Log';

// @ts-ignore
import ParsedText from 'react-native-parsed-text';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ExtendedMessage } from '../types';
import { AppTheme, useAppTheme } from '../../../styles/theme';

interface LeftRightStyle<T> {
  left?: StyleProp<T>;
  right?: StyleProp<T>;
}

export const StylePropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.number,
  PropTypes.bool,
]);

const WWW_URL_PATTERN = /^www\./i;

const { textStyle } = StyleSheet.create({
  textStyle: {
    fontSize: 16,
    lineHeight: 20,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 10,
  },
});

const DEFAULT_OPTION_TITLES = ['Call', 'Text', 'Cancel'];

export interface ChatTextMessageProps<TMessage extends ExtendedMessage> {
  position?: 'left' | 'right';
  optionTitles?: string[];
  currentMessage?: TMessage;
  containerStyle?: LeftRightStyle<ViewStyle>;
  textStyle?: LeftRightStyle<TextStyle>;
  linkStyle?: LeftRightStyle<TextStyle>;
  textProps?: TextProps;
  customTextStyle?: StyleProp<TextStyle>;
  quote?: JSX.Element;
}

export const ChatTextMessage = memo(
  <TMessage extends ExtendedMessage = ExtendedMessage>({
    currentMessage = {} as TMessage,
    optionTitles = DEFAULT_OPTION_TITLES,
    position = 'left',
    containerStyle,
    // eslint-disable-next-line
    textStyle,
    linkStyle: linkStyleProp,
    customTextStyle,
    textProps,
    quote,
  }: ChatTextMessageProps<TMessage>) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    const onUrlPress = useCallback((url: string) => {
      // When someone sends a message that includes a website address beginning with "www." (omitting the scheme),
      // react-native-parsed-text recognizes it as a valid url, but Linking fails to open due to the missing scheme.
      if (WWW_URL_PATTERN.test(url)) {
        onUrlPress(`https://${url}`);
      } else {
        Linking.openURL(url).catch(e => {
          Log.error(e, { message: `No handler for URL: ${url}` });
        });
      }
    }, []);

    const onPhonePress = useCallback(
      (phone: string) => {
        const options =
          optionTitles && optionTitles.length > 0
            ? optionTitles.slice(0, 3)
            : DEFAULT_OPTION_TITLES;
        const cancelButtonIndex = options.length - 1;
        showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
          },
          (buttonIndex?: number) => {
            switch (buttonIndex) {
              case 0:
                Linking.openURL(`tel:${phone}`).catch(e => {
                  Log.error(e, { message: `No handler for telephone: ${phone}` });
                });
                break;
              case 1:
                Linking.openURL(`sms:${phone}`).catch(e => {
                  Log.error(e, { message: `No handler for text: ${phone}` });
                });
                break;
              default:
                break;
            }
          },
        );
      },
      [optionTitles],
    );

    const onEmailPress = useCallback(
      (email: string) =>
        Linking.openURL(`mailto:${email}`).catch(e =>
          Log.error(e, { message: `No handler for text: ${email}` }),
        ),
      [],
    );

    const linkStyle = useMemo(
      () => [styles[position].link, linkStyleProp && linkStyleProp[position]],
      [styles, position, linkStyleProp],
    );

    return (
      <View style={[styles[position].container, containerStyle && containerStyle[position]]}>
        {quote}
        <ParsedText
          style={[
            styles[position].text,
            textStyle ? textStyle[position] : { color: theme.customColors.text },
            customTextStyle,
            { alignSelf: 'stretch' },
          ]}
          parse={[
            { type: 'url', style: linkStyle, onPress: onUrlPress },
            { type: 'phone', style: linkStyle, onPress: onPhonePress },
            { type: 'email', style: linkStyle, onPress: onEmailPress },
          ]}
          childrenProps={{ ...textProps }}
        >
          {currentMessage!.text}
        </ParsedText>
      </View>
    );
  },
);

const createStyles = (theme: AppTheme) => ({
  left: StyleSheet.create({
    container: {},
    text: { ...textStyle, color: theme.customColors.accent },
    link: {
      color: theme.customColors.accent,
      textDecorationLine: 'underline',
    },
  }),
  right: StyleSheet.create({
    container: {},
    text: { ...textStyle, color: theme.customColors.accent },
    link: {
      color: theme.customColors.accent,
      textDecorationLine: 'underline',
    },
  }),
});
