export type WithRequiredId<T extends { id?: string | null }> = Omit<T, 'id'> & {
  id: NonNullable<T['id']>;
};

const has = <K extends string>(x: {}, key: K): x is { [k in K]: unknown } => key in x; //eslint-disable-line

export const isObjectWithProperty = <T extends string>(
  o: unknown,
  key: T,
): o is { [k in T]: unknown } =>
  typeof o === 'object' && o !== null && o !== undefined && has(o, key);
