import React, { useMemo, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { TileProps } from './TileFactoryProps';
import { useAppTheme } from '../../styles/theme';
import { WithLoadingIndicator } from '../WithLoadingIndicator/WithLoadingIndicator';
import { Shimmer } from '../Shimmer/Shimmer';
import { ResizeMode, Video } from 'expo-av';

export function VideoTile<P>({ file, width }: TileProps<P>) {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(width), [width]);
  const video = useRef<Video | null>(null);

  return (
    <View style={styles.container}>
      <WithLoadingIndicator
        on={!!file.uri}
        render={() => (
          <View style={styles.container}>
            <Video
              ref={video}
              style={styles.video}
              source={{ uri: file.uri }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              shouldPlay={true}
              onLoad={async () => {
                await video.current?.pauseAsync();
              }}
              usePoster
            />
          </View>
        )}
        renderWhileLoading={() => (
          <Shimmer width={width} height={width} color={theme.customColors.background3} />
        )}
      />
    </View>
  );
}

const createStyles = (width: number) =>
  StyleSheet.create({
    container: {
      width,
      height: width,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'black',
      borderRadius: 10,
    },
    video: {
      width: width,
      height: width,
      zIndex: -1,
      borderRadius: 10,
    },
  });
