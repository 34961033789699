import React, { memo, useMemo } from 'react';
import { AppTheme, useAppTheme } from '../../styles/theme';
import Dialog from 'react-native-dialog';
import { Platform, View } from 'react-native';
import { Button, Dialog as DialogPaper, Portal, Text } from 'react-native-paper';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';
import { StyleSheet } from 'react-native';

type DiscardDialogPropTypes = {
  isOpen: boolean;
  title: string;
  content: string;
  discardButtonLabel?: string;
  cancelButtonLabel?: string;
  onCancel: () => void;
  onDiscard: () => void;
};

export const DiscardDialog = memo(
  ({
    isOpen,
    title,
    content,
    onCancel,
    onDiscard,
    cancelButtonLabel = 'Abbrechen',
    discardButtonLabel = 'Verwerfen',
  }: DiscardDialogPropTypes) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    if (Platform.OS === 'web') {
      return (
        <Portal>
          <DialogPaper visible={isOpen} onDismiss={onCancel} style={styles.dialog}>
            <DialogPaper.Title>{title}</DialogPaper.Title>
            <DialogPaper.Content>
              <Text variant="bodyMedium">{content}</Text>
            </DialogPaper.Content>
            <DialogPaper.Actions style={styles.actions}>
              <Button onPress={onCancel} textColor={theme.customColors.signalBlue}>
                {cancelButtonLabel}
              </Button>
              <Button
                onPress={onDiscard}
                textColor={theme.customColors.signalRed}
                labelStyle={styles.discardButtonLabel}
              >
                {discardButtonLabel}
              </Button>
            </DialogPaper.Actions>
          </DialogPaper>
        </Portal>
      );
    }

    return (
      <Dialog.Container
        visible={isOpen}
        contentStyle={styles.background}
        blurComponentIOS={<View />}
        buttonSeparatorStyle={styles.buttonSeperator}
        footerStyle={styles.footer}
      >
        <Dialog.Title style={styles.text}>{title}</Dialog.Title>
        <Dialog.Description style={styles.text}>{content}</Dialog.Description>
        <Dialog.Button
          label={cancelButtonLabel}
          onPress={onCancel}
          color={theme.customColors.signalBlue}
        />
        <Dialog.Button
          label={discardButtonLabel}
          onPress={onDiscard}
          bold
          color={theme.customColors.signalRed}
        />
      </Dialog.Container>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    dialog: {
      width: (maxContentAreaWidth / 4) * 3,
      alignSelf: 'center',
    },
    background: {
      backgroundColor: theme.customColors.background3,
    },
    text: {
      color: theme.customColors.text,
      textAlign: 'center',
    },
    discardButtonLabel: { fontWeight: 'bold' },
    actions: {
      justifyContent: 'space-between',
    },
    buttonSeperator: { borderColor: theme.customColors.borders, borderWidth: 0.5 },
    footer: { borderTopColor: theme.customColors.borders, borderTopWidth: 0.5 },
  });
