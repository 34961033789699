export enum ForwardeeType {
  CHATROOM,
  CONTACT,
}

export type Forwardee = {
  id: string;
  type: ForwardeeType;
  otcPath?: string | null;
  extra?: {
    user?: {
      userId: string;
      firstname: string;
      lastname: string;
      titleAfter?: string;
      titleBefore?: string;
    };
    chatRoom?: {
      name: string;
    };
  };
};
