import { Platform } from 'react-native';
import { useStore } from '../../stores/store';
import { getPermissionsAsync } from 'expo-notifications';
import { requestPushNotificationPermissions } from '../util/requestPushNotificationPermissions';
import { useCallback, useEffect } from 'react';
import { Log } from '../../utils/Log';
import { usePushNotificationStatusStore } from '../../utils/PushNotificationStatusStore/PushNotificationStatusStore';

export const useRequestPushNotificationPermission = (userMustAgreeToTermsOfService: boolean) => {
  const userProfile = useStore(s => s.userProfile);
  const { hasRequestedNotificationPermission, setHasRequestedNotificationPermission } =
    usePushNotificationStatusStore();
  const userId = userProfile?.userId;
  const shouldRequestPermissions =
    !hasRequestedNotificationPermission &&
    !userMustAgreeToTermsOfService &&
    Boolean(userId) &&
    Platform.OS !== 'web';

  const requestPermissions = useCallback(async () => {
    try {
      const { status } = await getPermissionsAsync();
      if (status === 'granted') {
        setHasRequestedNotificationPermission();
        return;
      }
      await requestPushNotificationPermissions();
      setHasRequestedNotificationPermission();
    } catch (e) {
      Log.error(e);
      Log.error('cannot request push tokens');
    }
  }, [userId]);

  useEffect(() => {
    if (shouldRequestPermissions) {
      void requestPermissions();
    }
  }, [shouldRequestPermissions]);
};
