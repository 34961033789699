import { SlideInDown } from 'react-native-reanimated';

interface IDetermineChatRoomMessageBubbleAnimation {
  justReceived?: boolean;
  justSent?: boolean;
}

export const determineChatRoomMessageBubbleAnimation = ({
  justReceived,
  justSent,
}: IDetermineChatRoomMessageBubbleAnimation) => {
  if (justReceived || justSent) {
    return SlideInDown.duration(500).springify().mass(0.4);
  }
  return undefined;
};
