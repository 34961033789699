import { ActivityIndicator, useTheme } from 'react-native-paper';
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native';
import React, { useEffect } from 'react';

import { ChatHomeScreenProps } from '../screens/types';
import { ChatListItem } from '../components/ChatListItem';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import { themeProp } from '../../../types/main';
import {
  useInfiniteChatRoomsQuery,
  useUnreadMessageCountQuery,
} from '../../../../graphql/operations';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { useQueryClient } from '@tanstack/react-query';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { EmptyCard } from '../../../components/Common/Empty/EmptyCard';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { Popup } from '../../../components/Popup';
import { usePopup, useUploadPushNotificationToken } from '../../../hooks';
import { texts } from '../../../utils';
import { FloatingActionButton } from '../../../stories/Button/FloatingActionButton';
import { useAppBar } from '../../news/hooks/useAppBar';
import { AppBar } from '../../../components/AppBar/AppBar';
import { NewChatAppBarAction } from './NewChatAppBarAction';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import { ChatHome } from '../../../../test/testIds';

const UNREAD_MESSAGES_ERROR = 'chat has unread messages';

type ChatHomeScreenContentPropTypes = {
  isRenderedInDrawer?: boolean;
};

export const ChatHomeScreenContent = ({ isRenderedInDrawer }: ChatHomeScreenContentPropTypes) => {
  const navigation = useNavigation<ChatHomeScreenProps['navigation']>();
  const queryClient = useQueryClient();
  const theme = useTheme() as themeProp;
  const style = createStyles(theme);
  const netInfo = useNetInfo();
  const isFocused = useIsFocused();
  const { isLeftDrawerOpen } = useResponsiveDrawer({});
  const notVisible = isRenderedInDrawer && !isLeftDrawerOpen;
  const errorPopup = usePopup(texts.unknownErrorTitle, texts.unknownErrorText);
  const {
    onEmptySearchPress,
    searchInputValue,
    debouncedSearchValue,
    setSearchInputValue,
    setShowSearch,
    showSearch,
  } = useAppBar({ shouldSearchBarBeOpen: true });
  const chats = useInfiniteChatRoomsQuery(
    'pageInput',
    { pageInput: { pageSize: 20 }, searchText: debouncedSearchValue },
    {
      enabled: (netInfo.isConnected && !notVisible) ?? false,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.chatRoomsListForTheCurrentUser.chatRooms.length,
          0,
        );
        if (alreadyFetched === lastPage.chatRoomsListForTheCurrentUser.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 20,
            token: lastPage.chatRoomsListForTheCurrentUser?.token,
          },
        };
      },
      staleTime: Infinity,
      // refetchInterval: 30 * 1000,
    },
  );

  const onHideChatRoomError = (e: unknown) => {
    const error = e as Error;
    if (!error || !error.message) {
      return;
    }

    if (error.message === UNREAD_MESSAGES_ERROR) {
      return errorPopup
        .setPopupDetails({
          title: 'Ungelesene Nachrichten',
          text: 'Dieser Chat hat ungelesene Nachrichten und kann erst ausgeblendet werden, wenn Sie die Nachrichten gelesen haben.',
        })
        .toggleOpen();
    }
    return errorPopup
      .setPopupDetails({
        title: texts.unknownErrorTitle,
        text: texts.unknownErrorText,
      })
      .toggleOpen();
  };

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => chats.refetch(),
    errorMessageToLog: `failed to refetch data for the chat home screen`,
  });

  useEffect(() => {
    if (isFocused) {
      /* @todo must be awaited */
      void onRefresh();
    }
  }, [isFocused]);

  useUploadPushNotificationToken();

  useEffect(() => {
    /* @todo must be awaited */
    void queryClient.invalidateQueries(useUnreadMessageCountQuery.getKey());
  }, []);

  if (!isRenderedInDrawer && isLeftDrawerOpen) {
    return (
      <EmptyCard
        iconName="chat"
        headlineText="Fang an zu chatten!"
        messageText="Verwenden Sie den Abschnitt auf der linken Seite, um einen Benutzer oder eine Chat-Gruppe auszuwählen, mit der Sie den Chat beginnen möchten."
      />
    );
  }

  const chatRooms = chats.data?.pages.flatMap(
    page => page.chatRoomsListForTheCurrentUser.chatRooms,
  );

  const chatRoomsWithMessages = chatRooms?.filter(
    chatRoom => chatRoom.lastMessage || chatRoom.name,
  );

  return (
    <View style={style.container}>
      <AppBar
        searchNeverCloses={isLeftDrawerOpen}
        transparentBackground={isRenderedInDrawer}
        title={isLeftDrawerOpen ? undefined : 'Chats'}
        showSearch={showSearch}
        onPressSearch={() => setShowSearch(true)}
        renderActionsRightOfSearch={
          isLeftDrawerOpen
            ? () => <NewChatAppBarAction onPress={() => navigation.navigate('NewDirectChat')} />
            : undefined
        }
        renderSearchBar={() => (
          <SearchBar
            searchNeverCloses={isLeftDrawerOpen}
            onChangeSearchValue={setSearchInputValue}
            onPressCloseSearch={onEmptySearchPress}
            placeholder={isLeftDrawerOpen ? 'Suche in Chats' : undefined}
            searchInputValue={searchInputValue}
          />
        )}
      />
      {chats.isLoading ? (
        <View style={[style.activityIndicatorContainer, style.activityIndicatorHorizontal]}>
          <ActivityIndicator size="large" color={theme.customColors.primary} />
        </View>
      ) : (
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={false}
              /* @todo must be awaited */
              onRefresh={() => void onRefresh()}
              tintColor={theme.customColors.refreshControlSpinner}
            />
          }
          data={chatRoomsWithMessages}
          renderItem={({ item }) => (
            <ChatListItem
              item={item}
              searchValue={debouncedSearchValue}
              onHideChatRoomError={onHideChatRoomError}
            />
          )}
          keyExtractor={chat => chat.id}
          ListEmptyComponent={
            <>
              {!!chats.data && (
                <NoDataRefresh
                  text="Es konnten leider keine Chats gefunden werden"
                  /* @todo must be awaited */
                  onPress={() => void onRefresh()}
                  isLoading={isRefreshing || chats.isLoading}
                />
              )}
            </>
          }
          onEndReachedThreshold={2}
          /* @todo must be awaited */
          onEndReached={() => void chats.fetchNextPage()}
          extraData={searchInputValue}
        />
      )}
      {!isLeftDrawerOpen && (
        <FloatingActionButton
          testId={ChatHome.createNewButton}
          onPress={() => {
            navigation.navigate('NewDirectChat');
          }}
        />
      )}
      <Popup {...errorPopup.popup} />
    </View>
  );
};

const createStyles = (theme: themeProp) =>
  StyleSheet.create({
    container: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    activityIndicatorContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    activityIndicatorHorizontal: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: 10,
    },
    appbarContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  });
