import React, { useMemo } from 'react';
import { View, Text, StyleProp, ViewStyle, TextStyle, Platform, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

type TransformButtonPropTypes = {
  containerStyle: StyleProp<ViewStyle>;
  buttonTextStyle: StyleProp<TextStyle>;
  buttonText: string;
  onPress: () => void;
  isDisabled: boolean;
  testID: string;
};

export const TransformButton = ({
  containerStyle,
  buttonTextStyle,
  buttonText,
  onPress,
  isDisabled,
  testID,
}: TransformButtonPropTypes) => {
  const styles = useMemo(() => createStyles(), []);
  return (
    <View style={containerStyle}>
      <Button
        mode={Platform.OS === 'web' ? 'outlined' : 'text'}
        onPress={onPress}
        style={styles.button}
        compact={true}
        uppercase={false}
        disabled={isDisabled}
        testID={testID}
      >
        <Text style={buttonTextStyle}>{buttonText}</Text>
      </Button>
    </View>
  );
};

const createStyles = () =>
  StyleSheet.create({
    button: {
      borderRadius: 5,
    },
  });
