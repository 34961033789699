import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import React, { memo, useMemo } from 'react';
import { ChatFormReplyMessageItem } from './ChatFormReplyMessageItem';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { FormReplyStatus } from '../../forms/components/FormReplyStatus/FormReplyStatus';
import { FormReplyOnChatRoomMessage } from '../../../../graphql/operations';

type ChatFormReplyMessageProps = {
  text: string;
  onLongPress?: () => void;
  formReply: FormReplyOnChatRoomMessage;
  chatRoomId?: string;
};

export const ChatFormReplyMessage = memo(
  ({ text, formReply, onLongPress, chatRoomId }: ChatFormReplyMessageProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);

    return (
      <View>
        <Text style={styles.textStyle}>{text}</Text>
        <ChatFormReplyMessageItem
          formReply={formReply}
          onLongPress={onLongPress}
          chatRoomId={chatRoomId}
        />
        <View style={styles.formReplyStatusContainer}>
          <FormReplyStatus status={formReply.status} />
        </View>
      </View>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    textStyle: {
      marginTop: 4,
      color: theme.customColors.text,
      fontSize: 16,
      lineHeight: 20,
    },
    formReplyStatusContainer: {
      position: 'absolute',
      bottom: 0,
      left: 13,
    },
  });
