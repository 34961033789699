import React, { memo, useMemo } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { ChatRoomMessage, Feature } from '../../../../graphql/operations';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { LinkButton } from '../../../components/LinkButton/LinkButton';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';

type ChatFormReplyMessageItemPropTypes = {
  formReply: ChatRoomMessage['formReply'];
  onLongPress?: () => void;
  chatRoomId?: string;
};

export const ChatFormReplyMessageItem = memo(
  ({ formReply, onLongPress, chatRoomId }: ChatFormReplyMessageItemPropTypes) => {
    const theme = useAppTheme();
    const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
    const styles = useMemo(() => createStyles(theme), [theme]);

    if (!formReply) {
      return <></>;
    }

    return (
      <TouchableOpacity key={formReply.id} onLongPress={onLongPress} style={styles.buttonContainer}>
        <LinkButton
          textStyle={styles.text}
          key={formReply.id}
          icon="format-list-checks"
          text={formReply.form.title}
          buttonStyle={styles.link}
          feature={Feature.Formreply}
          id={formReply.id}
          chatId={chatRoomId}
          navigation={navigation}
        />
      </TouchableOpacity>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    buttonContainer: { marginBottom: 1, paddingTop: 6, paddingBottom: 42 },
    link: {
      marginVertical: 1,
      marginHorizontal: 15,
      borderColor: theme.customColors.itemBorder,
      borderWidth: 1,
      borderRadius: 5,
    },
    label: {
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    contentStyle: { justifyContent: 'flex-start', alignItems: 'center', borderRadius: 5 },
    text: { marginLeft: 24 },
  });
