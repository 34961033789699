import { FormReplyAttachmentType } from '../../../../graphql/operations';
import { FileType } from '../../../components/FileGallery/types';

export const mapFileTypeToFormReplyFileType = (type: FileType) => {
  switch (type) {
    case FileType.Image:
      return FormReplyAttachmentType.Image;
    case FileType.Document:
      return FormReplyAttachmentType.Document;
    default:
      return FormReplyAttachmentType.Image;
  }
};
