import { Theme } from '@react-navigation/native';
import { AppTheme } from './theme';

export const createNavigatorTheme = ({ colors, customColors }: AppTheme, dark: boolean): Theme => ({
  dark,
  colors: {
    primary: colors.primary,
    background: colors.background,
    card: colors.background,
    text: colors.onSurface,
    border: customColors.borders,
    notification: colors.primary,
  },
});
