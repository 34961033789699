import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';

type SectionHeaderPropTypes = {
  text: string;
  renderToLeftOfText?: () => JSX.Element;
};

export const SectionHeader = ({ text, renderToLeftOfText }: SectionHeaderPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {renderToLeftOfText?.()}
        <Text variant="titleSmall">{text.toUpperCase()}</Text>
      </View>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: { flexDirection: 'row' },
    headerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 4,
      borderRadius: 25,
      backgroundColor: theme.customColors.lightRipple,
      paddingHorizontal: 10,
    },
    chip: {
      marginVertical: 6,
      borderRadius: 25,
      backgroundColor: theme.customColors.lightRipple,
      paddingHorizontal: 4,
    },
  });
