import { StyleSheet } from 'react-native';
import React, { useMemo } from 'react';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { LinkItem } from './LinkItem';
import {
  InfoPageLinkListAbstract,
  InfoPageLinkListAbstractProps,
} from './InfoPageLinkListAbstract';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { SelectedInfoPage } from '../../../components/Common/Media/types';

type InfoPageLinkListPropTypes = {
  append: (infoPage: SelectedInfoPage) => void;
  remove: (infoPageId: string) => void;
  selectedInfoPageIds?: SelectedInfoPage['id'][];
};

export const InfoPageLinkList = ({
  append,
  remove,
  selectedInfoPageIds,
}: InfoPageLinkListPropTypes) => {
  const theme = useAppTheme();
  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();
  const styles = useMemo(() => createStyles(theme, width), [theme, width]);

  const renderAccordionItem: InfoPageLinkListAbstractProps['renderAccordionItem'] = infoPage => (
    <LinkItem
      title={infoPage.title}
      id={infoPage.id}
      key={infoPage.id}
      append={append}
      remove={remove}
      isSelected={selectedInfoPageIds?.includes(infoPage.id)}
    />
  );

  return (
    <InfoPageLinkListAbstract
      renderAccordionItem={renderAccordionItem}
      scrollViewContainerStyles={styles.scrollViewContainer}
      scrollViewStyles={styles.scrollView}
    />
  );
};

const createStyles = (theme: AppTheme, width: number) =>
  StyleSheet.create({
    scrollView: {
      width: width,
    },
    scrollViewContainer: {
      width: width,
    },
  });
