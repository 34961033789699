import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Modal, Button, withTheme } from 'react-native-paper';
import { themeProp } from '../../../types/main';
import HeadlineContainer from '../../Containers/HeadlineContainer';

interface PopUpProps {
  children: React.ReactNode;
  visible: boolean;
  onDismiss: () => void;
  theme: themeProp;
}

const MailPopUp = (props: PopUpProps): JSX.Element => {
  const { theme } = props;

  return (
    <Modal visible={props.visible} onDismiss={props.onDismiss}>
      <View style={styles.container}>
        <HeadlineContainer title="E-Mail Bestätigen">
          <Image
            source={{
              uri: 'http://via.placeholder.com/400x300.png',
            }}
            style={{
              marginVertical: 0,
              paddingVertical: 0,
              width: 816 * 0.35,
              height: 654 * 0.35,
            }}
          />
          {props.children}
          <Button
            style={styles.button}
            mode="outlined"
            onPress={props.onDismiss}
            textColor={theme.customColors.primary}
          >
            Schließen
          </Button>
        </HeadlineContainer>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  container: {
    margin: 14,
  },
  button: {
    borderRadius: 5,
    marginTop: 14,
  },
});

// eslint-disable-next-line no-restricted-syntax
export default withTheme(MailPopUp);
