import { NewsAttachmentType } from '../../../../graphql/operations';
import { ExtendedMessage } from '../types';

export const getAttachmentOfChatMessage = (message?: ExtendedMessage) => {
  if (message?.audio) {
    return { type: NewsAttachmentType.Audio, otcPath: message.audio };
  }
  if (message?.image) {
    return { type: NewsAttachmentType.Image, otcPath: message.image };
  }
  if (message?.video) {
    return { type: NewsAttachmentType.Video, otcPath: message.video };
  }
  return undefined;
};
