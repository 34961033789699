import { useEffect, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

type Params = { window: ScaledSize; screen: ScaledSize };
type ListenerFunction = (params: Params, orientation: Orientation) => void;
enum Orientation {
  Portrait,
  Landscape,
}

export const useOrientationChange = (onDimensionChange?: ListenerFunction) => {
  const { width, height } = Dimensions.get('window');
  const [orientation, setOrientation] = useState<Orientation>(
    width > height ? Orientation.Landscape : Orientation.Portrait,
  );
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', (params: Params) => {
      const newOrientation =
        params.window.width > params.window.height ? Orientation.Landscape : Orientation.Portrait;
      setOrientation(newOrientation);
      onDimensionChange?.(params, newOrientation);
    });
    return () => subscription.remove();
  }, []);
  return orientation;
};
