import {
  Feature,
  GetFormIdIfUserHasPermissionToReadFormOrThrowQuery,
  GetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery,
  InfoPageAttachmentType,
  useGetFormIdIfUserHasPermissionToReadFormOrThrowQuery,
  useGetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery,
} from '../../../../graphql/operations';
import { useUnavailableFeatureModalStore } from '../../../hooks';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { UploadType } from '../../Common/FileUpload/types';
import { Linking } from 'react-native';

interface IUseLinkButton {
  id: string;
  feature: Feature.Infopage | Feature.Form | Feature.Formreply;
  label: string;
  chatId?: string;
  navigation?: StackNavigationProp<AppNavigatorParamList>;
}

export const useLinkButton = ({ id, feature, label, chatId, navigation }: IUseLinkButton) => {
  const {
    setUnavailableFeatureModalAsNoPermissionToViewForm,
    setUnavailableFeatureModalAsNoPermissionToViewInfoPage,
    setUnavailableFeatureModalAsUnknownError,
  } = useUnavailableFeatureModalStore();

  const navigateToInfoPage = async (
    infoPage: GetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery['getInfoPageIfUserHasPermissionToReadInfoPageOrThrow'],
  ) => {
    if (infoPage && infoPage.attachment) {
      const { downloadable, attachment } = infoPage;
      if (attachment.type === InfoPageAttachmentType.ExternalUrl) {
        await Linking.openURL(attachment.externalUrl!);
        return;
      }
      navigation?.navigate('OtcPdfReader', {
        downloadable,
        source: attachment.s3Path!,
        fileName: attachment.fileName!,
        type: UploadType.InfoPage,
      });
    }
  };
  const navigateToForm = (
    formId: GetFormIdIfUserHasPermissionToReadFormOrThrowQuery['getFormIdIfUserHasPermissionToReadFormOrThrow'],
  ) => {
    navigation?.navigate('FormDetail', { formId });
  };

  const navigateToFormReply = (formReplyId: string) => {
    navigation?.navigate('FormReplyDetail', { formReplyId, formTitle: label, chatId: chatId });
  };

  const showNotAllowed = () => {
    if (feature === Feature.Infopage) {
      setUnavailableFeatureModalAsNoPermissionToViewInfoPage();
    } else {
      setUnavailableFeatureModalAsNoPermissionToViewForm();
    }
  };

  const navigateToFeature = async () => {
    try {
      if (feature === Feature.Infopage) {
        const infoPage = await useGetInfoPageIfUserHasPermissionToReadInfoPageOrThrowQuery.fetcher({
          infoPageId: id,
        })();
        return navigateToInfoPage(infoPage.getInfoPageIfUserHasPermissionToReadInfoPageOrThrow);
      }
      if (feature === Feature.Form) {
        const form = await useGetFormIdIfUserHasPermissionToReadFormOrThrowQuery.fetcher({
          formId: id,
        })();
        return navigateToForm(form.getFormIdIfUserHasPermissionToReadFormOrThrow);
      }
      if (feature === Feature.Formreply) {
        return navigateToFormReply(id);
      }
      throw new Error('unknown feature ' + feature);
    } catch (e) {
      const err = e as Error;
      if (err.message.includes('Not allowed')) {
        return showNotAllowed();
      }
      setUnavailableFeatureModalAsUnknownError();
    }
  };

  return { navigateToFeature };
};
