type GetNameProps = {
  firstname?: string;
  lastname?: string;
};

export const getName = ({ firstname, lastname }: GetNameProps): string => {
  if (!firstname && !lastname) {
    return 'Ehemaliger Benutzer';
  }
  return `${firstname ?? ''} ${lastname ?? ''}`.trim();
};
