import { FormQuestionType } from '../../../../graphql/operations';

export const getQuestionLabelSuffix = (
  type: FormQuestionType,
  required: boolean | null | undefined,
) => {
  if (type === FormQuestionType.Text) {
    return '';
  }

  return required === false ? ' (optional)' : '';
};
