import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ChatRoomMessage } from '../../../../graphql/operations';
import { useSocketStore } from '../../../stores/SocketStore';

export const useDeleteMessageSubscription = (chatRoomId: string) => {
  const queryClient = useQueryClient();
  const chatSocket = useSocketStore(s => s.chatSocket);
  const [deletedMessages, setDeletedMessages] = useState<ChatRoomMessage[]>([]);

  const resetDeletedMessages = useCallback(() => {
    setDeletedMessages([]);
  }, []);

  useEffect(() => {
    resetDeletedMessages();
    return () => resetDeletedMessages();
  }, [chatRoomId]);

  const onDeleteMessage = useCallback(
    async (message: ChatRoomMessage) => {
      const messageAsDeleted = {
        ...message,
        deletedAt: new Date(),
      };
      setDeletedMessages([...deletedMessages, messageAsDeleted]);
    },
    [deletedMessages],
  );

  useEffect(() => {
    if (!queryClient || !chatSocket?.connected) {
      return;
    }

    chatSocket?.on('deleteMessage', onDeleteMessage);

    return () => {
      chatSocket?.off('deleteMessage', onDeleteMessage);
    };
  }, [queryClient, chatSocket, deletedMessages]);

  return { deletedMessages };
};
