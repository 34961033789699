import React, { useEffect, useMemo, useState } from 'react';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Divider, Subheading, Switch } from 'react-native-paper';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import * as Notifications from 'expo-notifications';
import { PushNotificationChannel } from './PushNotificationChannel';
import { GetUserPreferenceQuery } from '../../../../graphql/operations';
import { useAppStatus } from '../../../hooks/common/appStatus';
import { Row } from './Row';
import { useUploadPushNotificationToken } from '../../../hooks';
import { ConfirmDialog } from '../../../components/Dialog/ConfirmDialog';
import { useAppTheme } from '../../../styles/theme';

export const PushNotificationSettings = (props: {
  preference: GetUserPreferenceQuery['getUserPreference'];
  userId: string;
}) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(), []);
  const [notificationPermission, setNotificationPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { appMovedToView } = useAppStatus();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    /* @todo must be awaited */
    void getPermission();
  }, []);

  useEffect(() => {
    if (appMovedToView) {
      setIsLoading(true);
      /* @todo must be awaited */
      void getPermission();
    }
  }, [appMovedToView]);

  const getPermission = async () => {
    const permissions = await Notifications.getPermissionsAsync();
    setNotificationPermission(permissions.granted);
    setIsLoading(false);
  };

  useUploadPushNotificationToken();

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <Row>
            <Subheading style={styles.subheading}>Push-Benachrichtigungen</Subheading>
            <Switch
              value={notificationPermission}
              onValueChange={() => onOnline(() => setConfirmModalVisible(true))}
              disabled={isLoading}
              color={theme.customColors.primary}
            />
          </Row>
          <Divider style={{ marginVertical: 10 }} />
          <PushNotificationChannel
            text="Bei neuem News-Beitrag"
            id="News"
            permission={notificationPermission}
            preference={props.preference}
            userId={props.userId}
          />
          <PushNotificationChannel
            text="Bei neuer Chat Nachricht"
            id="ChatMessage"
            permission={notificationPermission}
            preference={props.preference}
            userId={props.userId}
          />
          <PushNotificationChannel
            text="Bei neuen Anfragen"
            id="Inquiry"
            permission={notificationPermission}
            preference={props.preference}
            userId={props.userId}
          />
          <ConfirmDialog
            isOpen={confirmModalVisible}
            title="Weiterleitung"
            content="Die Berechtigungen für Push-Benachrichtigungen können nur in den Einstellungen Ihres
            Gerätes geändert werden. Mit einem Druck auf Weiter werden Sie zu den
            Einstellungen weitergeleitet. Bitte ändern Sie dort Ihre Berechtigung für
            Push-Benachrichtigungen."
            onCancel={() => setConfirmModalVisible(false)}
            onConfirm={() => {
              setConfirmModalVisible(false);
              void Linking.openSettings();
            }}
            confirmButtonLabel="Weiter"
          />
        </>
      )}
    </OfflineErrorModal>
  );
};

const createStyles = () =>
  StyleSheet.create({
    subheading: { marginTop: Platform.OS === 'android' ? 8 : 0 },
  });
