import { useCallback, useEffect } from 'react';
import { useChatStore } from '../Store';
import { useIsFocused } from '@react-navigation/core';

interface IUseCurrentChatRoomId {
  chatRoomId: string;
}

export const useCurrentChatRoomId = ({ chatRoomId }: IUseCurrentChatRoomId) => {
  const setCurrentChatRoomId = useChatStore(s => s.setCurrentChatRoomId);
  const isFocused = useIsFocused();

  const syncChatRoomId = useCallback(() => {
    if (!isFocused) {
      setCurrentChatRoomId(undefined);
    }
    if (chatRoomId) {
      return setCurrentChatRoomId(chatRoomId);
    }
  }, [chatRoomId, isFocused]);

  useEffect(() => {
    syncChatRoomId();
    return () => setCurrentChatRoomId(undefined);
  }, [chatRoomId]);
};
