import AsyncStorage from '@react-native-async-storage/async-storage';
import { isEnrolledAsync } from 'expo-local-authentication';
import { getItemAsync } from 'expo-secure-store';
import { Constants } from './Constants';

export const getBiometricData = async () => {
  const isEnabledOnDevice = await isEnrolledAsync();
  const isEnabled = await AsyncStorage.getItem(Constants.BIOMETRICS_ENABLED);
  const username = await getItemAsync(Constants.BIOMETRICS_STORAGE_USERNAME_KEY);
  const password = await getItemAsync(Constants.BIOMETRICS_STORAGE_PASSWORD_KEY);
  const hasUserReceivedPopup = await AsyncStorage.getItem(
    Constants.BIOMETRICS_HAS_USER_RECEIVED_POPUP,
  );
  return {
    isEnabled: Boolean(isEnabled),
    isEnabledOnDevice,
    hasUserReceivedPopup: Boolean(hasUserReceivedPopup),
    username,
    password,
  };
};
