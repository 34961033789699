import { useCallback, useEffect } from 'react';
import { getPermissionsAsync } from 'expo-notifications';
import { useStore } from '../../stores/store';
import { usePushNotificationStatusStore } from '../../utils/PushNotificationStatusStore/PushNotificationStatusStore';
import { updateUserNotificationStatus } from '../util/updateUserNotificationStatus';
import { requestPushToken } from '../util/requestPushToken';
import { uploadPushToken } from '../util/uploadPushToken';
import { Platform } from 'react-native';
import { MobileOperatingSystem } from '../../../graphql/operations';
import { Log } from '../../utils/Log';

export const useUploadPushNotificationToken = () => {
  const userId = useStore(s => s.userProfile?.userId);
  const pushTokens = useStore(s => s.pushTokens);
  const addPushNotificationToken = useStore(s => s.addPushNotificationToken);
  const {
    pushNotificationStatus,
    setPushNotificationStatus,
    hasRequestedPushNotificationToken,
    setHasRequestedPushNotificationToken,
    devicePushToken,
    setDevicePushToken,
    hasRequestedNotificationPermission,
  } = usePushNotificationStatusStore();

  const shouldUploadToken =
    Boolean(userId) && hasRequestedNotificationPermission && Platform.OS !== 'web';

  const uploadToken = useCallback(async () => {
    try {
      const { status } = await getPermissionsAsync();

      if (pushNotificationStatus !== status) {
        await updateUserNotificationStatus(status === 'granted', userId!);
        setPushNotificationStatus(status === 'granted' ? 'granted' : 'denied');
      }
      if (status !== 'granted') {
        return;
      }
      if (devicePushToken && hasRequestedPushNotificationToken) {
        return;
      }
      const token = await requestPushToken();
      if (!token) {
        throw new Error('an error occurred while requesting a push token');
      }
      setHasRequestedPushNotificationToken();
      setDevicePushToken(token.data);
      if (pushTokens?.includes(token.data)) {
        return;
      }
      addPushNotificationToken(token.data);
      await uploadPushToken(
        token.data,
        Platform.OS === 'ios' ? MobileOperatingSystem.Ios : MobileOperatingSystem.Android,
      );
    } catch (e) {
      Log.error(e, { message: 'cannot upload push token' });
    }
  }, [
    userId,
    pushNotificationStatus,
    pushTokens,
    devicePushToken,
    hasRequestedPushNotificationToken,
  ]);

  useEffect(() => {
    if (shouldUploadToken) {
      void uploadToken();
    }
  }, [shouldUploadToken]);
};
