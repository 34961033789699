import { NewsAttachmentType } from '../../../../../graphql/operations';
import * as DocumentPicker from 'expo-document-picker';
import uuid from 'react-native-uuid';

export type SuccessfulDocumentResult = {
  type: 'success';
  name: string;
  size?: number | undefined;
  uri: string;
  mimeType?: string | undefined;
  lastModified?: number | undefined;
  file?: File | undefined;
  output?: FileList | null | undefined;
};

export const uploadedDocumentToDatabase = (
  item: any,
  asset: DocumentPicker.DocumentPickerAsset,
) => ({
  id: uuid.v4().toString(),
  otcPath: item.newFileNames[0][1],
  fileName: item.newFileNames[0][0],
  type: NewsAttachmentType.Document,
  fallbackText: asset.name,
  uri: asset.uri,
  fileSize: asset.size ?? 0,
});
