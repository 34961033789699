import React, { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import { IconButton } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../../styles/theme';

export type ChatToolbarProps = {
  onSend(messageText: string): void;
  disabled?: boolean;
  onTypingStart?: TextInputProps['onChangeText'];
  textInputTestId?: TextInputProps['testID'];
  submitButtonTestId?: string;
  renderActions?: () => JSX.Element;
  renderOnTopOfToolbar?: () => JSX.Element;
  renderOnBottomOfToolbar?: () => JSX.Element;
};

export const ChatToolbar = memo(
  ({
    onSend,
    disabled,
    onTypingStart,
    renderActions,
    renderOnTopOfToolbar,
    renderOnBottomOfToolbar,
    textInputTestId,
    submitButtonTestId,
  }: ChatToolbarProps) => {
    const theme = useAppTheme();
    const styles = useMemo(() => createStyles(theme), [theme]);
    const [message, setMessage] = useState('');

    const onChangeMessageLocal = useCallback(
      (text: string) => {
        const isTyping = text !== message && text.length > message.length;
        if (isTyping) {
          onTypingStart?.(text);
        }
        setMessage(text);
      },
      [message, onTypingStart],
    );

    const onSendLocal = useCallback(() => {
      onSend(message);
      startTransition(() => setMessage(''));
    }, [message]);

    return (
      <>
        {renderOnTopOfToolbar?.()}
        <View style={styles.container}>
          {renderActions?.()}
          <TextInput onChangeText={onChangeMessageLocal} value={message} testID={textInputTestId} />
          <IconButton
            testID={submitButtonTestId}
            icon="send"
            onPress={onSendLocal}
            disabled={disabled || message.trim().length === 0}
          />
        </View>
        {renderOnBottomOfToolbar?.()}
      </>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      columnGap: 5,
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 5,
      paddingTop: 5,
      borderTopColor: theme.customColors.borders,
      borderTopWidth: 1,
    },
  });
