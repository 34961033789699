import {
  ChatRole,
  ChatRoomMessage,
  Form,
  InfoPage,
  NewsAttachmentType,
} from '../../../../graphql/operations';
import { ExtendedMessage } from '../types';

interface IExtendChatRoomMessage {
  message: ChatRoomMessage;
  roleOfAuthorInChatRoom?: ChatRole;
}

export const extendChatRoomMessage = ({
  message,
  roleOfAuthorInChatRoom = ChatRole.Member,
}: IExtendChatRoomMessage): ExtendedMessage => ({
  _id: message.id,
  text: message.content,
  audio:
    message.attachment?.type === NewsAttachmentType.Audio && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  image:
    message.attachment?.type === NewsAttachmentType.Image && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  documentUri:
    message.attachment?.type === NewsAttachmentType.Document && message.attachment.otcPath
      ? message.attachment?.otcPath
      : undefined,
  pending: message.pending ?? undefined,
  user: {
    _id: message.author?.userId ?? '',
    name: `${message.author?.firstname ?? 'Ehemaliger'} ${message.author?.lastname ?? 'Benutzer'}`,
    avatar: message.author?.pictureOTC ?? undefined,
    role: roleOfAuthorInChatRoom,
  },
  createdAt: message.createdAt,
  deletedAt: message.deletedAt ?? undefined,
  received: message.status === 'READ',
  readByCurrentUser: message.readByCurrentUser ?? false,
  quote: message.quote,
  forms:
    message.forms?.map(
      form => ({ id: form.id, title: form.title }) as Pick<Form, 'id' | 'title'>,
    ) ?? [],
  infoPages:
    message.infoPages?.map(
      infoPage => ({ id: infoPage.id, title: infoPage.title }) as Pick<InfoPage, 'id' | 'title'>,
    ) ?? [],
  formReply: message.formReply,
  isForwarded: message.isForwarded ?? false,
});
