import { Log } from '../../utils/Log';

import { Appbar, Portal } from 'react-native-paper';
import {
  LoginAttribute,
  useConfirmForgotPasswordMutation,
  useForgotPasswordMutation,
  UserType,
  useVerifyLoginAttributeMutation,
} from '../../../graphql/operations';
import React, { ElementRef, useRef, useState } from 'react';

import { AppNavigatorParamList } from '../../router/AppNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { isObjectWithProperty } from '../../utils/typeUtils';
import { useNavigation } from '@react-navigation/native';
import { ConfirmationCodeAbstract } from './ConfirmationCodeAbstract';
import {
  ErrorType,
  getConfirmationCodeErrorDetails,
} from '../../utils/getConfirmationCodeErrorDetails';
import { usePopup } from '../../hooks';
import { Popup } from '../../components/Popup';
import { getBiometricData, storeBiometrics } from '../../utils';
import { Platform } from 'react-native';
import { useStore } from '../../stores/store';
import { AppBar } from '../../components/AppBar/AppBar';

type ProfileEditProps = StackScreenProps<AppNavigatorParamList, 'ConfirmationCode'>;

export const ConfirmationCode = (props: ProfileEditProps) => {
  const action = props.route.params?.action;
  const type = props.route.params?.type; // just for verifying attributes

  const [isLoading, setIsLoading] = useState(false);
  const tenantName = useStore(s => s.tenantName);
  const errorPopup = usePopup('Fehler', 'Es ist ein unbekannter Fehler aufgetreten');
  const successPopup = usePopup('Erfolgreich', '', () => {
    successPopup.toggleOpen();
    void handlePasswordChangeSuccess({ refresh: true });
  });
  const confirmationCodeRef = useRef<ElementRef<typeof ConfirmationCodeAbstract> | null>(null);

  const navigation = useNavigation<ProfileEditProps['navigation']>();

  const isUserLoggedIn = useStore(s => s.isUserLoggedIn);

  const handlePasswordChangeSuccess = async ({ refresh }: { refresh?: boolean }) => {
    setIsLoading(false);
    if (refresh) {
      /* @todo must be awaited */
      await useStore.getState().refresh();
    }
    navigation.navigate(isUserLoggedIn() ? 'ProfileEdit' : 'Login');
  };

  const handleSubmit = async (value: string) => {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    if (type === 'email' || type === 'phone_number') {
      try {
        await useVerifyLoginAttributeMutation.fetcher({
          input: {
            attributeName: type === 'email' ? LoginAttribute.Email : LoginAttribute.PhoneNumber,
            confirmationCode: value,
          },
        })();
        successPopup.setPopupDetails({
          text: `Ihre ${
            type === 'email' ? 'E-Mail Adresse' : 'Telefonnummer'
          } wurde erfolgreich verifiziert`,
        });
        successPopup.toggleOpen();
      } catch (err) {
        const errorDetails = getConfirmationCodeErrorDetails(err as Error);
        errorPopup
          .setPopupDetails({
            ...errorDetails,
            onDismiss: () => {
              if (errorDetails.errorType === ErrorType.VerificationCode) {
                navigation.navigate('ProfileEdit');
              }
              if (errorDetails.errorType === ErrorType.AttemptLimitExceeded) {
                navigation.pop(navigation.getState().index);
              }
              errorPopup.toggleOpen();
            },
          })
          .toggleOpen();
        setIsLoading(false);
      }
    } else if (
      (action === 'RESET_REQUIRED' || action === 'FORCE_CHANGE_PASSWORD') &&
      props.route.params?.newPassword &&
      props.route.params?.username
    ) {
      try {
        await useConfirmForgotPasswordMutation.fetcher({
          input: {
            userType: UserType.Employee,
            loginUsername: props.route.params.username,
            confirmationCode: value,
            newPassword: props.route.params.newPassword,
          },
          tenantName,
        })();
        if (Platform.OS !== 'web') {
          const { isEnabled } = await getBiometricData();
          if (isEnabled) {
            await storeBiometrics(props.route.params.username, props.route.params.newPassword);
          }
        }
        successPopup.setPopupDetails({
          text: 'Ihr Passwort wurde erfolgreich zurück gesetzt. Bitte probieren Sie sich mit dem neuen Passwort einzuloggen.',
        });
        successPopup.toggleOpen();
      } catch (err) {
        if (
          isObjectWithProperty(err, 'message') &&
          typeof err.message === 'string' &&
          err.message.includes('Invalid verification code provided, please try again')
        ) {
          Log.info(`failed pass confirm: ${err}}`);
          confirmationCodeRef?.current?.setCodeValue('');
          errorPopup
            .setPopupDetails({
              title: 'Bestätigungscode abgelaufen',
              text: 'Der von Ihnen eingegebene Bestätigungscode ist nicht mehr gültig, wir senden Ihnen einen neuen Code zu.',
              onDismiss: () => {
                /* @todo must be awaited */
                void handleResend();
                errorPopup.toggleOpen();
              },
            })
            .toggleOpen();
          setIsLoading(false);
        }
        if (
          isObjectWithProperty(err, 'message') &&
          typeof err.message === 'string' &&
          err.message.includes('Attempt limit exceeded')
        ) {
          errorPopup
            .setPopupDetails({
              title: 'Zu viele Versuche',
              text: 'Bitte versuchen Sie es in ein paar Minuten erneut.',
              onDismiss: () => {
                navigation.pop(navigation.getState().index);
                errorPopup.toggleOpen();
              },
            })
            .toggleOpen();
          setIsLoading(false);
        }
      }
    }
    setIsLoading(false);
  };

  const handleResend = async () => {
    Log.info('Resend');
    if (props.route.params?.username && action) {
      try {
        const fetcher = await useForgotPasswordMutation.fetcher({
          input: {
            loginUsername: props.route.params.username,
            userType: UserType.Employee,
          },
          tenantName,
        })();

        void fetcher.forgotPassword; // this can be used to read the delivery medium and communicate it to the user

        navigation.navigate('CheckInbox', props.route.params);
      } catch (err) {
        const errorDetails = getConfirmationCodeErrorDetails(err as Error);
        errorPopup
          .setPopupDetails({
            ...errorDetails,
            onDismiss: () => {
              navigation.pop(navigation.getState().index);
              errorPopup.toggleOpen();
            },
          })
          .toggleOpen();
        Log.error(`code sending failed: ${err}`);
        return;
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <AppBar
        title={'Bestätigungscode'}
        renderActionsLeftOfTitle={() => <Appbar.BackAction onPress={() => navigation.goBack()} />}
      />
      <ConfirmationCodeAbstract
        channel={props.route.params?.channel}
        onPressResend={handleResend}
        onSend={handleSubmit}
        showResendMessage={!props.route.params?.type}
        isLoading={isLoading}
      />
      <Portal>
        <Popup {...successPopup.popup} />
        <Popup {...errorPopup.popup} />
      </Portal>
    </>
  );
};
