import React from 'react';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { Keyboard } from 'react-native';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useAppTheme } from '../../../../styles/theme';
import { UseActionSheetDocsImagesProps } from './UseActionSheetDocsImagesProps';

export const useActionSheetDocsImages = ({
  handleImagePick,
  handleCameraPhoto,
  handleDocumentPick,
}: UseActionSheetDocsImagesProps) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const theme = useAppTheme();

  const showActionSheetDocsImages = () => {
    Keyboard.dismiss();
    showActionSheetWithOptions(
      {
        options: ['Aus Galerie', 'Kamera', 'Dokument', 'Abbrechen'],
        cancelButtonIndex: 3,
        icons: [
          <Icon name="image-outline" size={25} key="0" color={theme.customColors.text} />,
          <Icon name="camera" size={25} key="1" color={theme.customColors.text} />,
          <Icon name="file-document-outline" size={25} key="2" color={theme.customColors.text} />,
          <Icon
            name="close"
            size={25}
            key="5"
            testID="cancelIcon"
            color={theme.customColors.text}
          />,
        ],
        containerStyle: {
          backgroundColor: theme.colors.background,
        },
        textStyle: {
          color: theme.customColors.text,
        },
      },
      async buttonIndex => {
        switch (buttonIndex) {
          case 0:
            return handleImagePick();
          case 1:
            return handleCameraPhoto();
          case 2:
            return handleDocumentPick();
          default:
            return;
        }
      },
    );
  };

  return { showActionSheetDocsImages };
};
