import React, { memo, useMemo } from 'react';
import { Appbar } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { PlaceholderAppBarAction } from '../Common/AppBar/PlaceholderAppBarAction';
import { StyleSheet } from 'react-native';

type AppBarPropTypes = {
  title?: string;
  showSearch?: boolean;
  isSearchDisabled?: boolean;
  onPressSearch?: () => void;
  onTitlePress?: () => void;
  searchNeverCloses?: boolean;
  transparentBackground?: boolean;
  renderActionsRightOfSearch?: () => JSX.Element;
  renderActionsLeftOfTitle?: () => JSX.Element;
  renderActionsRightOfTitle?: () => JSX.Element;
  renderContent?: () => JSX.Element;
  renderSearchBar?: () => JSX.Element;
};

export const AppBar = memo(function AppBar({
  title,
  showSearch,
  isSearchDisabled,
  searchNeverCloses,
  renderActionsRightOfSearch,
  renderActionsLeftOfTitle,
  renderActionsRightOfTitle,
  renderSearchBar,
  renderContent,
  onPressSearch,
  onTitlePress,
  transparentBackground,
}: AppBarPropTypes) {
  const theme = useAppTheme();
  const styles = useMemo(
    () => createStyles(theme, transparentBackground),
    [theme, transparentBackground],
  );
  const isSearchOpen = Boolean(showSearch || searchNeverCloses);

  return (
    <Appbar.Header
      testID="app-bar-header"
      style={isSearchOpen ? styles.appBarWithSearch : styles.appBarWithoutSearch}
    >
      {isSearchOpen ? (
        <>
          {showSearch && renderSearchBar?.()}
          {renderActionsRightOfSearch?.()}
        </>
      ) : (
        <>
          {renderActionsLeftOfTitle ? renderActionsLeftOfTitle() : <PlaceholderAppBarAction />}
          {renderActionsRightOfSearch && <PlaceholderAppBarAction />}
          {renderContent ? (
            <>{renderContent()}</>
          ) : (
            <Appbar.Content
              title={title ?? ''}
              color={theme.customColors.primary}
              titleStyle={styles.headerTitle}
              onPress={onTitlePress}
            />
          )}
          {renderSearchBar && !searchNeverCloses ? (
            <Appbar.Action
              testID="appbar-search-button"
              icon="magnify"
              size={26}
              color={theme.customColors.gray50}
              onPress={onPressSearch}
              disabled={!!isSearchDisabled}
            />
          ) : renderActionsRightOfTitle ? (
            renderActionsRightOfTitle()
          ) : (
            <PlaceholderAppBarAction />
          )}
          {renderActionsRightOfSearch?.()}
        </>
      )}
    </Appbar.Header>
  );
});

const createStyles = (theme: AppTheme, transparentBackground?: boolean) =>
  StyleSheet.create({
    appBarWithSearch: {
      backgroundColor: transparentBackground ? 'transparent' : undefined,
      display: 'flex',
      justifyContent: 'flex-end',
      borderBottomWidth: 0.5,
      borderBottomColor: theme.customColors.borders,
    },
    appBarWithoutSearch: {
      backgroundColor: transparentBackground ? 'transparent' : undefined,
      justifyContent: 'space-between',
      flexDirection: 'row',
      borderBottomWidth: 0.5,
      borderBottomColor: theme.customColors.borders,
    },
    headerTitle: {
      fontSize: 25,
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });
