import { requestPermissionsAsync, getPermissionsAsync } from 'expo-media-library';

export const requestLibraryPermissions = async () => {
  const permissions = await getPermissionsAsync();
  if (permissions.granted) {
    return { isGranted: permissions.granted };
  }
  if (permissions.canAskAgain) {
    const requestedPermission = await requestPermissionsAsync();
    return { isGranted: requestedPermission.granted };
  }
  return { isGranted: false };
};
