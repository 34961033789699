export const Constants = {
  BIOMETRICS_STORAGE_USERNAME_KEY: 'username',
  BIOMETRICS_STORAGE_PASSWORD_KEY: 'password',
  BIOMETRICS_ENABLED: 'biometricLoginEnabled',
  BIOMETRICS_HAS_USER_RECEIVED_POPUP: 'BIOMETRICS_HAS_USER_RECEIVED_POPUP',
  SESSION_EXPIRED_ERROR: 'session is expired',
  ANDROID_DOCUMENT_STORAGE_FOLDER_NAME: 'Documents',
  INFOPAGE_CATEGORY_CHAR_LIMIT: 26,
  FILE_UPLOAD_FIELD_LIMIT: 1024 * 1024 * 10,
  MS_AFTER_CHAT_ROOM_MESSAGES_ARE_ASSUMED_TO_BE_READ: 10000,
  IS_LOADING_TEXT: 'Wird geladen...',
  /**
   * in order for user to reach the end of the list and trigger fetch next page,
   * the user has to scroll about 3.2 screen heights past the start of the chat room shimmer component
   */
  CHAT_ROOM_ENDREACHED_THRESHOLD: 3.2,
  CC_CREATE_NEWS_LINK: 'https://webadmin.luciapp.de/#/news/',
  DISABLED_FEATURE_NOTICE:
    'Diese Funktion ist derzeit deaktiviert. Bitte bitten Sie einen Administrator, sie im Kontrollzentrum zu aktivieren.',
  TOAST_POSITION_ANDROID: -58,
  TOAST_POSITION_IOS: -88,
  FORWARDED_MESSAGE_COUNT_LIMIT: 250,
  DISMISS_NOTIFICATIONS_TIMEOUT: 5000,
};
