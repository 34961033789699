import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppNavigatorParamList } from '../AppNavigator';
import { HelpScreen } from '../../features/helpPage/screens/HelpScreen';
import { HelpScreenExternal } from '../../features/helpPage/screens/HelpScreenExternal';
import { HelpFeedbackScreen } from '../../features/helpPage/screens/HelpFeedbackScreen';
import { HelpSuccessScreen } from '../../features/helpPage/screens/HelpSuccessScreen';
import { useAppTheme } from '../../styles/theme';

const HelpModalStack =
  createStackNavigator<
    Pick<AppNavigatorParamList, 'HelpHome' | 'HelpExternal' | 'HelpForm' | 'HelpSuccess'>
  >();

export const HelpModalNavigator = () => {
  const theme = useAppTheme();
  return (
    <HelpModalStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: theme.customColors.background3,
          borderBottomColor: theme.customColors.background3,
        },
        headerTintColor: theme.customColors.text,
      }}
    >
      <HelpModalStack.Screen
        name="HelpHome"
        options={{
          headerShown: false,
          presentation: 'modal',
        }}
        component={HelpScreen}
      />
      <HelpModalStack.Screen
        name="HelpExternal"
        options={{
          headerShown: false,
          presentation: 'card',
        }}
        component={HelpScreenExternal}
      />
      <HelpModalStack.Screen
        name="HelpForm"
        options={{
          headerShown: false,
          presentation: 'card',
        }}
        component={HelpFeedbackScreen}
      />
      <HelpModalStack.Screen
        name="HelpSuccess"
        options={{
          headerShown: false,
          presentation: 'card',
        }}
        component={HelpSuccessScreen}
      />
    </HelpModalStack.Navigator>
  );
};
