import {
  ActivityIndicator,
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { BinaryAnswerType, FormDetailScreenProps } from '../types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { FormikProvider, useFormik } from 'formik';
import { Appbar, Button, Headline, Modal, Portal, Text } from 'react-native-paper';
import {
  CreateFormQuestionAnswerInput,
  CreateFormReplyAttachmentInput,
  FormOperation,
  FormReplyAttachmentType,
  useCreateFormReplyMutation,
  useFormDetailsQuery,
} from '../../../../graphql/operations';
import { useStore } from '../../../stores/store';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { NoDataRefresh } from '../../../stories/Loading/NoDataRefresh';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { LoadingBlocker } from '../../../components/Common/Loading/LoadingBlocker';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { FileGallery } from '../../../components/FileGallery/FileGallery';
import { File, FileOptionalParams } from '../../../components/FileGallery/types';
import { Log } from '../../../utils/Log';
import { usePopup } from '../../../hooks';
import { Popup } from '../../../components/Popup';
import { uploadFilesOfUploadType } from '../../../components/Common/FileUpload/uploadFilesOfUploadType';
import { EncodingType } from 'expo-file-system';
import { FormQuestionAnswerPair } from '../components/FormQuestionAnswerPair/FormQuestionAnswerPair';
import { FormHeader } from '../components/FormHeader/FormHeader';
import { ImageViewerModal } from '../../../components/Common/Modals/ImageViewerModal';
import { useImageViewerModal } from '../../../components/Common/Modals/hooks/useImageViewerModal';
import { SectionTitle } from '../../../components/SectionTitle/SectionTitle';
import { useActionSheetDocsImages } from '../../../components/Common/Media/hooks/useActionSheetDocsImages';
import { useFileGalleryPicker } from '../../../components/FileGallery/hooks';
import { mapFileTypeToFormReplyFileType } from '../utils';
import { AppBar } from '../../../components/AppBar/AppBar';

const getFileNameAndOtcPath = (
  uploadResults: Array<[oldFilename: string, newFilename: string]>,
  index: number,
): [oldFilename: string, newFilename: string] => {
  const fileNames = uploadResults[index];
  if (!fileNames) {
    throw new Error('uploaded file not found by index');
  }
  return fileNames;
};

const fileToFormReplyAttachment = (
  { optionalParams, type }: File<FileOptionalParams>,
  fileName: string,
  otcPath: string,
): CreateFormReplyAttachmentInput => ({
  fileName,
  otcPath,
  type: mapFileTypeToFormReplyFileType(type),
  fileSize: optionalParams?.fileSize ?? 0,
  height: optionalParams?.height,
  width: optionalParams?.width,
});

export const FormDetailScreen = ({ navigation, route }: FormDetailScreenProps) => {
  const formId = route.params.formId;
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const createFormReplyMutation = useCreateFormReplyMutation();
  const userProfile = useStore(s => s.userProfile);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const errorPopup = usePopup(
    'Unbekannter Fehler',
    `Leider ist ein unbekannter Fehler aufgetreten.\nBitte versuchen Sie es später noch einmal.`,
  );
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<{ [key: string]: string }>({});
  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();
  const {
    removeFile,
    files,
    noSelectedFiles,
    handleCameraPhoto,
    handleDocumentPick,
    handleGalleryPick,
    isLoading,
  } = useFileGalleryPicker({ includeVideosAmongPhotos: false });

  const formDetailsQuery = useFormDetailsQuery(
    { formId, operation: FormOperation.Read },
    { enabled: !!formId },
  );

  const getSuccessMessage = useCallback(
    (assigneeId?: string | null) =>
      Boolean(assigneeId)
        ? 'Das Formular wurde erfolgreich gesendet und an die verantwortliche Person weitergeleitet.'
        : 'Das Formular wurde erfolgreich gesendet.',
    [],
  );

  const uploadFiles = async (fileUris: string[]) => {
    try {
      return await uploadFilesOfUploadType({
        fileUris,
        uploadType: UploadType.FormReply,
        encoding: Platform.OS === 'web' ? EncodingType.Base64 : undefined,
      });
    } catch (e) {
      Log.error(e);
      Log.error('cannot upload form reply files');
      throw new Error('unable to upload files');
    }
  };

  useEffect(() => {
    if (formDetailsQuery.data?.form.questions) {
      const formQuestions = formDetailsQuery.data?.form.questions;
      const questionToValueMap: { [key: string]: string } = {};
      formQuestions.forEach(question => {
        if (question?.id) {
          questionToValueMap[question.id] = '';
        }
      });
      setInitialValues(questionToValueMap);
    }
  }, [formDetailsQuery.data]);

  const { showActionSheetDocsImages } = useActionSheetDocsImages({
    handleCameraPhoto,
    handleDocumentPick,
    handleImagePick: handleGalleryPick,
  });

  const onSubmit = async (values: { [key: string]: string }) => {
    setIsFormSubmitting(true);
    try {
      const answerArray: CreateFormQuestionAnswerInput[] = [];
      const uploadFilesResult = await uploadFiles(files.map(({ uri }) => uri));
      const attachments = files.map((file, i) =>
        fileToFormReplyAttachment(
          file,
          ...getFileNameAndOtcPath(uploadFilesResult.newFileNames, i),
        ),
      );
      questions.forEach(question => {
        if (!question) {
          return;
        }
        answerArray.push({
          question: question.label,
          answer: values[question.id],
        });
      });
      if (!userProfile) {
        throw new Error('user profile not found, cannot submit form');
      }
      await createFormReplyMutation.mutateAsync({
        input: {
          formId,
          answers: answerArray,
          attachments,
        },
      });
    } catch (e) {
      Log.error(e);
      Log.error('cannot submit form');
      errorPopup.toggleOpen();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const isSaveButtonDisabled = (values: { [key: string]: string }) => {
    const isDisabled =
      (questions || [])
        // filter required questions
        .filter(question => question?.required === true)
        // check if required question values are empty
        .filter(question => values[question!.id] === '' || values[question!.id] === undefined)
        .length > 0;
    return isDisabled;
  };

  const onFileGalleryTilePress = (file: File<unknown>) => {
    const fileType = mapFileTypeToFormReplyFileType(file.type);
    if (fileType === FormReplyAttachmentType.Image) {
      setImageViewerModalImages([{ url: file.uri }]);
      return setImageViewerModalVisible(true);
    }
    if (fileType === FormReplyAttachmentType.Document) {
      if (Platform.OS === 'web') {
        return window.open(file.uri);
      }
      return navigation.navigate('PdfReader', {
        fileName: file.name ?? 'Document',
        url: file.uri,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  if (formDetailsQuery.isLoading) {
    return (
      <LoadingBlocker
        visible={formDetailsQuery.isLoading}
        spinnerColor={theme.customColors.primary}
        opacity="0.2"
      />
    );
  }

  if (!formDetailsQuery.data) {
    return <NoDataRefresh text="Keine Formular gefunden" isLoading={formDetailsQuery.isLoading} />;
  }

  const {
    description: formDescription,
    title: formTitle,
    acceptsAttachments,
    requiresAttachments,
    attachmentDescription,
    questions,
    assigneeId,
  } = formDetailsQuery.data.form;

  const { values, setFieldValue, handleSubmit } = formik;

  const atLeastOneAttachmentisNeeded = noSelectedFiles && requiresAttachments;

  return (
    <>
      <OfflineErrorModal>
        {onOnline => (
          <View style={styles.viewContainer}>
            <AppBar
              // @ts-ignore
              title={route.params?.form?.title ?? 'Formular'}
              renderActionsLeftOfTitle={() => (
                <Appbar.BackAction
                  onPress={() => navigation.goBack()}
                  color={theme.customColors.icon}
                />
              )}
            />
            <KeyboardAwareScrollView
              style={styles.scrollView}
              contentContainerStyle={styles.container}
              keyboardShouldPersistTaps="handled"
            >
              <FormHeader
                title={formTitle}
                description={formDescription}
                userIdOfFormAssignee={assigneeId}
                onPressUserCard={() => {
                  if (assigneeId) {
                    navigation.navigate('Profile', { profileId: assigneeId });
                  }
                }}
              />
              <TouchableWithoutFeedback onPress={() => Platform.OS !== 'web' && Keyboard.dismiss()}>
                <FormikProvider value={formik}>
                  <>
                    {questions.map(question => (
                      <FormQuestionAnswerPair
                        key={question.id}
                        question={question}
                        answer={null}
                        questionIdFormValue={values[question.id] as BinaryAnswerType}
                        setQuestionIdFormValue={yesOrNo => setFieldValue(question.id, yesOrNo)}
                      />
                    ))}
                    {acceptsAttachments && (
                      <>
                        <SectionTitle
                          title={`Anhänge${requiresAttachments ? '' : ' (optional)'}`}
                        />
                        <Text style={styles.descriptionTxt}>{attachmentDescription}</Text>
                      </>
                    )}
                    {!noSelectedFiles && (
                      <View style={styles.container}>
                        <FileGallery
                          files={files}
                          allowsDeletion
                          onTilePress={onFileGalleryTilePress}
                          onDeletePress={file => removeFile(file.id)}
                        />
                      </View>
                    )}
                    {acceptsAttachments && (
                      <View style={styles.buttonColumn}>
                        <Button
                          uppercase={false}
                          style={styles.button}
                          mode="contained"
                          onPress={() => showActionSheetDocsImages()}
                          textColor={theme.customColors.textWhite}
                          icon="paperclip"
                        >
                          Datei anhängen
                        </Button>
                      </View>
                    )}
                    <View style={[styles.buttonRow, styles.endMargin]}>
                      <Button
                        uppercase={false}
                        style={styles.button}
                        mode="outlined"
                        onPress={() => {
                          Keyboard.dismiss();
                          setCancelModalOpen(true);
                        }}
                      >
                        Abbrechen
                      </Button>
                      <Button
                        testID="formSaveButton"
                        uppercase={false}
                        style={styles.button}
                        mode="contained"
                        disabled={isSaveButtonDisabled(values) || atLeastOneAttachmentisNeeded}
                        onPress={() => {
                          Keyboard.dismiss();
                          setConfirmModalOpen(true);
                        }}
                        textColor={theme.customColors.textWhite}
                      >
                        Senden
                      </Button>
                    </View>
                    <Portal>
                      <Modal
                        visible={confirmModalOpen}
                        onDismiss={() => setConfirmModalOpen(false)}
                        dismissable
                        contentContainerStyle={styles.modalContentStyle}
                      >
                        <Text style={styles.modalTitle}>
                          {createFormReplyMutation.isSuccess
                            ? 'Formular gesendet!'
                            : createFormReplyMutation.isError
                            ? 'Senden fehlgeschlagen!'
                            : 'Formular senden?'}
                        </Text>
                        <View>
                          <Text style={styles.itemTitle}>
                            {createFormReplyMutation.isSuccess
                              ? getSuccessMessage(assigneeId)
                              : createFormReplyMutation.isError
                              ? 'Beim Senden des Formulars ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
                              : 'Möchten Sie dieses Formular wirklich an die zuständige Person senden?'}
                          </Text>
                        </View>
                        <View style={styles.buttonRow}>
                          <Button
                            mode="text"
                            uppercase={false}
                            onPress={() => {
                              createFormReplyMutation.reset();
                              setConfirmModalOpen(false);
                            }}
                            disabled={createFormReplyMutation.isSuccess}
                            style={
                              createFormReplyMutation.isSuccess
                                ? [styles.textButton, { display: 'none' }]
                                : styles.textButton
                            }
                            textColor={theme.colors.onSurface}
                          >
                            Abbrechen
                          </Button>
                          <Button
                            style={styles.textButton}
                            mode="text"
                            uppercase={false}
                            onPress={() => {
                              if (createFormReplyMutation.isSuccess) {
                                setConfirmModalOpen(false);
                                if (navigation.canGoBack()) {
                                  return navigation.goBack();
                                }
                                navigation.navigate('FormsHomeScreen');
                              } else {
                                onOnline(handleSubmit);
                              }
                            }}
                            disabled={createFormReplyMutation.isLoading || isFormSubmitting}
                          >
                            {createFormReplyMutation.isSuccess ? (
                              'Fertig'
                            ) : createFormReplyMutation.isError ? (
                              'Erneut versuchen'
                            ) : createFormReplyMutation.isLoading || isFormSubmitting ? (
                              <View>
                                <ActivityIndicator
                                  size="small"
                                  animating={true}
                                  color={theme.customColors.primary}
                                />
                              </View>
                            ) : (
                              'Senden'
                            )}
                          </Button>
                        </View>
                      </Modal>
                      <Modal
                        visible={cancelModalOpen}
                        onDismiss={() => setCancelModalOpen(false)}
                        dismissable
                        contentContainerStyle={styles.modalContentStyle}
                      >
                        <Text style={styles.modalTitle}>Sind Sie sicher?</Text>
                        <View>
                          <Text style={styles.itemTitle}>
                            Möchten Sie wirklich alle Änderungen im Formular verwerfen und die Seite
                            verlassen?
                          </Text>
                        </View>
                        <View style={styles.buttonRow}>
                          <Button
                            style={styles.textButton}
                            mode="text"
                            uppercase={false}
                            onPress={() => setCancelModalOpen(false)}
                            textColor={theme.colors.onSurface}
                          >
                            Nein
                          </Button>
                          <Button
                            style={styles.textButton}
                            mode="text"
                            uppercase={false}
                            onPress={() => {
                              navigation.goBack();
                              setCancelModalOpen(false);
                            }}
                          >
                            Ja
                          </Button>
                        </View>
                      </Modal>
                    </Portal>
                  </>
                </FormikProvider>
              </TouchableWithoutFeedback>
            </KeyboardAwareScrollView>
            <ImageViewerModal
              visible={imageViewerModalVisible}
              setVisible={setImageViewerModalVisible}
              activeIndex={0}
              imageUrls={imageViewerModalImages}
            />
          </View>
        )}
      </OfflineErrorModal>
      <Portal>
        <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
          <Headline
            style={{
              color: '#fff',
            }}
          >
            Laden...
          </Headline>
        </LoadingBlocker>
        <Popup {...errorPopup.popup} />
      </Portal>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    viewContainer: { display: 'flex', height: '100%' },
    scrollView: {
      backgroundColor: theme.colors.background,
      flex: 1,
    },
    container: {
      backgroundColor: theme.colors.background,
      paddingHorizontal: 10,
    },
    titleTxt: {
      fontWeight: '500',
      color: theme.customColors.text,
      fontSize: 20,
      marginBottom: 10,
      textAlign: 'center',
    },
    descriptionTxt: {
      fontWeight: '400',
      color: theme.customColors.text,
      fontSize: 16,
      marginBottom: 10,
    },
    formContainer: {
      width: '100%',
    },
    itemTitle: {
      fontSize: 14,
      lineHeight: 18,
      marginRight: 12,
    },
    button: {
      borderRadius: 5,
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center',
      alignSelf: 'center',
      flex: 1,
      width: '50%',
    },
    modalContentStyle: {
      alignSelf: 'center',
      padding: 20,
      maxWidth: '90%',
      justifyContent: 'space-around',
      backgroundColor: theme.colors.background,
      shadowOpacity: 0.12,
      shadowRadius: 25,
      borderRadius: 12,
    },
    modalTitle: {
      lineHeight: 24,
      fontSize: 18,
      color: theme.colors.onSurface,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    buttonRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: -6,
      marginTop: 10,
    },
    endMargin: {
      marginBottom: 50,
    },
    buttonColumn: {
      marginTop: 15,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    textButton: {
      borderRadius: 5,
    },
  });
